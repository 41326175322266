import {
  Button,
  ButtonModes,
  ButtonSizes,
  ButtonVariants,
  Dropdown,
  DropdownButton,
  Menu,
  MenuItem,
} from '@aircall/tractor-v2';
import { LimitationModal } from '@components/LimitationModal/LimitationModal';
import { NUMBER_PURCHASE_ROUTE } from '@constants/routes.constants';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useNumberLimitation } from '@hooks/useNumberLimitation/useNumberLimitation';
import { useToggle } from '@hooks/useToggle/useToggle';
import { useTracker } from '@hooks/useTracker';
import { useTranslation } from 'react-i18next';

export interface CreateNumberDropdownProps {
  mode?: ButtonModes;
  size?: ButtonSizes;
  variant?: ButtonVariants;
  buttonOnly?: boolean;
}

function CreateNumberDropdown({
  mode,
  size,
  variant = 'primary',
  buttonOnly = false,
}: CreateNumberDropdownProps) {
  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();
  const [open, _, setOpen, setClosed] = useToggle();
  const { isNumbersLimitReached } = useNumberLimitation();
  const { track } = useTracker();

  const onCreateButtonClick = () => {
    isNumbersLimitReached ? setOpen() : navigate(NUMBER_PURCHASE_ROUTE);
  };

  const handleMenuItemLink = (url: string) => () => {
    track({
      event: 'external_link_opened',
      payload: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        link_destination_domain: url,
      },
    });

    window.open(url, '_blank');
  };

  return (
    <>
      {buttonOnly ? (
        <Button
          data-test='create-number-button'
          data-tracking='number-creation-process-started'
          onClick={onCreateButtonClick}
          size='small'
        >
          {t('numbers.create.button')}
        </Button>
      ) : (
        <Dropdown
          trigger={
            <DropdownButton
              size={size}
              mode={mode}
              variant={variant}
              data-test='create-number-dropdown-button'
            >
              {t('numbers.create.dropdown_button')}
            </DropdownButton>
          }
          side='bottom'
          align='end'
        >
          <Menu>
            <MenuItem
              data-test='numbers-dropdown-create-number'
              data-tracking='number-creation-process-started'
              onClick={isNumbersLimitReached === undefined ? undefined : onCreateButtonClick}
            >
              {t('numbers.dropdown.create_number')}
            </MenuItem>
            <MenuItem
              onClick={handleMenuItemLink(t('numbers.dropdown.port_number_link'))}
              data-test='numbers-dropdown-port-number'
            >
              {t('numbers.dropdown.port_number')}
            </MenuItem>
          </Menu>
        </Dropdown>
      )}
      <LimitationModal show={open} onHide={setClosed} title={t('limitation_modal.number_title')} />
    </>
  );
}

export default CreateNumberDropdown;
