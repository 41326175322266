import { TickOutlined } from '@aircall/icons';
import { Typography, Icon } from '@aircall/tractor-v2';

export function RenderProgressContent({
  isComplete,
  completedTask,
  totalTask,
}: Readonly<{
  isComplete: boolean;
  completedTask: number;
  totalTask: number;
}>) {
  if (!isComplete) {
    return (
      <Typography
        variant='supportingSemiboldXS'
        color='text-contrast-light'
        data-test='progress-bar-content'
      >
        {completedTask}/{totalTask}
      </Typography>
    );
  }
  return (
    <Icon component={TickOutlined} color='#fff' size={18} data-test='progress-bar-tick-icon' />
  );
}
