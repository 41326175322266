/* eslint-disable @typescript-eslint/naming-convention */

import {
  AVAILABLE_COUNTRY_CODES,
  CustomData,
  GLOBAL_COUNTRY_CODE,
  SignupCountry,
} from '@components/TermsOfUse';
import { IRONCLAD_ACCESS_ID } from '@constants/environment.constants';
import { doRequest } from '@dashboard/library';
import snakecaseKeys from 'snakecase-keys';

export function isCountryAvailable(countryCode: string): boolean {
  return AVAILABLE_COUNTRY_CODES.some(
    (availableCountryCode) => availableCountryCode.toLowerCase() === countryCode.toLowerCase()
  );
}

export function getAvailableCountryCode(signupCountry: SignupCountry): string {
  // If countryCode is null or country doesn't have any localised contract,
  // we display the global one
  return signupCountry && isCountryAvailable(signupCountry)
    ? signupCountry.toUpperCase()
    : GLOBAL_COUNTRY_CODE;
}

export function sendAcceptance(
  companyId: number,
  versionIds: string,
  contractIds: string
): Promise<number> {
  return doRequest(
    `https://pactsafe.io/send?site_id=${IRONCLAD_ACCESS_ID}&signer_id=${encodeURIComponent(
      companyId
    )}&event_type=agreed&vid=${encodeURIComponent(versionIds)}&cid=${encodeURIComponent(
      contractIds
    )}`,
    {
      method: 'POST',
      headers: { Accept: 'application/json' },
    }
  );
}

export function setSignerCustomData({ companyId, ...customData }: CustomData): Promise<number> {
  return doRequest(
    `https://pactsafe.io/send?site_id=${IRONCLAD_ACCESS_ID}&signer_id=${encodeURIComponent(
      companyId
    )}&event_type=updated&custom_data=${encodeURIComponent(
      JSON.stringify(snakecaseKeys(customData))
    )}`,
    {
      method: 'POST',
      headers: { Accept: 'application/json' },
    }
  );
}

function getLatestContractsSigned(
  contractIds: string,
  companyId: number
): Promise<{ [key: string]: boolean }> {
  return doRequest(
    `https://pactsafe.io/latest?sid=${IRONCLAD_ACCESS_ID}&cid=${contractIds}&sig=${encodeURIComponent(
      companyId
    )}`,
    {
      method: 'POST',
      headers: { Accept: 'application/json' },
    }
  );
}

export async function hasUserSignedLatestContracts(
  contractIds: string,
  companyId: number
): Promise<boolean> {
  const contracts = await getLatestContractsSigned(contractIds, companyId);
  return Object.keys(contracts).every((id) => contracts[id] === true);
}
