import { ToggleFieldProps } from './ToggleField.decl';

import { Grid, Toggle, Typography } from '@aircall/tractor-v2';
import { useField } from 'react-final-form';

export function ToggleField({ name, label, size }: ToggleFieldProps) {
  const { input } = useField(name);

  return (
    <Grid columnGap='xxs' gridTemplateColumns='auto 1fr' alignItems='center'>
      <Toggle checked={input.value} onChange={input.onChange} size={size} name={name} />
      <Typography variant='bodyRegularS'>{label}</Typography>
    </Grid>
  );
}
