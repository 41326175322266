import { useEffect } from 'react';

import { useTracker } from '../useTracker';

import camelCase from 'lodash-es/camelCase';
import startCase from 'lodash-es/startCase';
import { useLocation } from 'react-router';

export function useTrackPage(): void {
  const { page } = useTracker();
  const location = useLocation();

  useEffect(() => {
    const pageName = location.pathname.split('/')[1];
    const pascalCaseName = startCase(camelCase(pageName)).replace(/ /g, '');
    page({ name: pascalCaseName, title: pascalCaseName });
  }, [location.pathname, page]);
}
