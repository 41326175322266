import * as RoutesConstants from '../../constants/routes.constants';

import { LinkAddonsConfig } from './useGetLinkAddonsConfig.decl';

import { CircleFilled } from '@aircall/icons';
import { ReactComponent as AircallAIIcon } from '@assets/icons/aircall_ai_icon.svg';
import { ReactComponent as AnalyticsPlusIcon } from '@assets/icons/analytics_plus_icon.svg';
import { ADDONS } from '@constants/addons.constants';

enum EXTENSIONS_SIDEBAR_LINK_URL {
  CONVERSATIONS = '/conversations',
}

export const LINK_ADDONS_CONFIG: Record<string | number, LinkAddonsConfig> = {
  [RoutesConstants.STATS_ROUTE]: {
    addonName: ADDONS.ANALYTICS_PLUS,
    tag: {
      text: 'addons.status.trial',
      icon: AnalyticsPlusIcon,
      variant: 'blue',
    },
    indicator: {
      icon: CircleFilled,
      color: 'blue',
    },
    includedChildren: [
      RoutesConstants.STATS_CHILD_ROUTE.ANALYTICS_PLUS,
      RoutesConstants.STATS_CHILD_ROUTE.USERS_ACTIVITY,
      RoutesConstants.STATS_CHILD_ROUTE.INBOUND_ACTIVITY,
      RoutesConstants.STATS_CHILD_ROUTE.OUTBOUND_ACTIVITY,
      RoutesConstants.STATS_CHILD_ROUTE.UNANSWERED_CALLS,
      RoutesConstants.STATS_CHILD_ROUTE.MONITORING,
      RoutesConstants.STATS_CHILD_ROUTE.AVAILABILITIES,
      RoutesConstants.STATS_CHILD_ROUTE.CALL_QUALITY,
    ],
  },
  [EXTENSIONS_SIDEBAR_LINK_URL.CONVERSATIONS]: {
    addonName: ADDONS.AIRCALL_AI,
    tag: {
      text: 'addons.status.trial',
      icon: AircallAIIcon,
      variant: 'darkSecondary',
      color: '#5bf1b2',
      gap: '2px',
    },
  },
};
