import React, { useEffect } from 'react';

import { AudioPlaybackRowProps } from './AudioSetting.decl';
import { AudioSettingModalContainer } from './AudioSettingModalContainer';

import { EditOutlined } from '@aircall/icons';
import { Flex, IconButton, Typography, Grid, Tag, Box } from '@aircall/tractor-v2';
import { AudioPlayer } from '@components/AudioPlayer';
import { TooltipOnTruncate } from '@components/TooltipOnTruncateV2';
import { useToggle } from '@hooks/useToggle/useToggle';
import { useTranslation } from 'react-i18next';

export const AudioPlaybackRow = React.memo(
  ({
    name,
    title,
    url,
    disabled = false,
    showModal = false,
    'data-test': dataTest,
  }: AudioPlaybackRowProps) => {
    const { t } = useTranslation();
    const [open, _, openModal, closeModal] = useToggle(showModal);

    useEffect(() => {
      if (showModal) {
        openModal();
      }
    }, [openModal, showModal]);

    return (
      <Grid
        gridTemplateColumns='auto 1fr auto'
        columnGap='xxs'
        data-test={dataTest}
        alignItems='center'
        p='xxxs'
        pr='xxs'
        aria-disabled={disabled}
      >
        <AudioPlayer
          iconColor='text-base'
          url={url}
          displayTimer={false}
          displayTimeline={false}
          disabled={disabled}
        />
        <Box overflow='hidden'>
          <TooltipOnTruncate title={title}>
            <Typography color={disabled ? 'text-disabled' : undefined} ellipsis={1}>
              {title}
            </Typography>
          </TooltipOnTruncate>
        </Box>
        <Flex alignItems='center'>
          {disabled && (
            <Box mr='xxs'>
              <Tag size='small' mode='outline' variant='grey'>
                {t('number_details.settings.call_distribution_section.audio_playback_row.inactive')}
              </Tag>
            </Box>
          )}
          <AudioSettingModalContainer show={open} onHide={closeModal} name={name} title={title} />

          <IconButton
            data-test='edit-icon-button'
            onClick={openModal}
            size='m'
            component={EditOutlined}
            color='neutral-400'
          />
        </Flex>
      </Grid>
    );
  }
);
