import { AUTHORIZE_CHANNELS_MUTATION } from '../graphql/mutations/authorizeChannelsMutation';

import {
  AuthorizeChannelsInput,
  AuthorizeChannelsResponse,
  ChannelPayload,
} from './authorizeChannels.decl';

import { ApolloClient } from '@apollo/client';

export const authorizeChannelsFactory =
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  (client: ApolloClient<object>) => (cluster: string, channelPayloads: ChannelPayload[]) =>
    client.mutate<AuthorizeChannelsResponse, AuthorizeChannelsInput>({
      mutation: AUTHORIZE_CHANNELS_MUTATION,
      variables: {
        input: {
          cluster,
          channelPayloads,
        },
      },
    });
