export function cloneHeaders(resHeaders: Headers): Headers {
  const headers = new Headers();

  for (const header of resHeaders.entries()) {
    headers.append(header[0], header[1]);
  }

  return headers;
}

/**
 * Takes a response object and a custom function to mutate the headers
 *
 * @param res - Response object
 * @param headerFn - Function provided to mutate the headers in the response
 * @returns Promise with the formatted response
 */
export async function setResponseWithHeaders(
  res: Response,
  headerFn: (headers: Headers) => Headers
): Promise<Response> {
  const headers = headerFn(cloneHeaders(res.headers));
  const blob = await res.blob();

  return new Response(blob, {
    status: res.status,
    statusText: res.statusText,
    headers,
  });
}
