import { FiltersProvider } from './FiltersProvider';
import { Looker } from './Looker';
import { LookerPageProps } from './Looker.decl';
import { LookerPageProvider } from './LookerPageProvider';

import { SearchProvider } from '@components/SearchProvider/SearchProvider';

export function LookerPage({
  maxDateRangeInDays,
  minDate,
  onTruncated,
  pageHeaderTitle,
  type,
  pageHeaderProps,
  ...otherProps
}: LookerPageProps) {
  return (
    <SearchProvider>
      <LookerPageProvider
        maxDateRangeInDays={maxDateRangeInDays}
        minDate={minDate}
        onTruncated={onTruncated}
      >
        <FiltersProvider
          dashboardType={type}
          maxDateRangeInDays={maxDateRangeInDays}
          minDate={minDate}
          pageHeaderTitle={pageHeaderTitle}
          pageHeaderProps={pageHeaderProps}
        >
          <Looker type={type} {...otherProps} />
        </FiltersProvider>
      </LookerPageProvider>
    </SearchProvider>
  );
}
