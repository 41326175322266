import { useEffect, useState } from 'react';

import { LineStatusFilterProps } from './LineStatusFilter.decl';

import { MultiSelect, MultiSelectOption, capitalizeFirstLetter } from '@dashboard/library';
import { LineStatus } from '@generated/globalTypes';
import { useTranslation } from 'react-i18next';

export function LineStatusFilter({ onSelect, initialStatuses }: LineStatusFilterProps) {
  const { t } = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<LineStatus[]>([]);
  const options = [
    { label: t('numbers.filters.status.open'), value: LineStatus.OPENED },
    { label: t('numbers.filters.status.always_open'), value: LineStatus.ALWAYS_OPENED },
    { label: t('numbers.filters.status.closed'), value: LineStatus.CLOSED },
    { label: t('numbers.filters.status.always_closed'), value: LineStatus.ALWAYS_CLOSED },
    { label: t('numbers.filters.status.action_needed'), value: LineStatus.ACTION_NEEDED },
  ];

  function handleSelect(keys: LineStatus[]) {
    setSelectedKeys(keys);
    onSelect(keys);
  }

  useEffect(() => {
    setSelectedKeys(initialStatuses ?? []);
  }, [initialStatuses]);

  return (
    <MultiSelect<MultiSelectOption<LineStatus>>
      data-test='line-status-filter'
      selectedKeys={selectedKeys}
      defaultSelectedKeys={initialStatuses || undefined}
      minWidth='160px'
      options={options}
      onSelect={handleSelect}
      texts={{
        placeholder: capitalizeFirstLetter(t('generic.status')),
        item: t('generic.status'),
        items: t('generic.statuses'),
      }}
    />
  );
}
