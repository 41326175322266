import { DateLimits } from '../Looker.decl';

import { URLSearchObject } from '@components/SearchProvider/usePersistSearchParams';
import { formatSearchParamsToObject } from '@components/SearchProvider/utils';
import {
  DEFAULT_VALUE as DEFAULT_DATES_VALUE,
  getSafeDates,
} from '@pages/looker-v2/Filters/DateFilter/DateFilter.helpers';
import { AnalyticsState } from '@pages/looker-v2/Filters/Filters.decl';
import {
  DEFAULT_VALUE as DEFAULT_TIMEZONE_VALUE,
  getSafeTimezone,
} from '@pages/looker-v2/Filters/TimezoneFilter/TimezoneFilter.helpers';
import { getLookerDateStr } from '@pages/looker-v2/Looker.helpers';

export const DEFAULT_FILTER_VALUES: AnalyticsState = {
  ...DEFAULT_DATES_VALUE,
  ...DEFAULT_TIMEZONE_VALUE,
};

export const getSafeURLSearchObject = (
  filters: URLSearchObject,
  dateLimits: DateLimits = {}
): URLSearchObject => {
  const safeFilterValues = { ...filters };

  if (filters.date) {
    const { rangeValue, startDate, endDate } = getSafeDates(filters.date, dateLimits);
    safeFilterValues.date =
      rangeValue ?? `${getLookerDateStr(startDate)} to ${getLookerDateStr(endDate)}`;
  }

  if (filters.timezone) {
    safeFilterValues.timezone = getSafeTimezone(filters.timezone).timezone;
  }

  return safeFilterValues;
};

export const getSafeFilterValues = (
  searchParams: URLSearchParams,
  dateLimits: DateLimits = {}
): AnalyticsState => {
  const searchParamsObj = formatSearchParamsToObject(searchParams);
  const safeDates = getSafeDates(searchParamsObj.date, dateLimits);
  const safeTimezone = getSafeTimezone(searchParamsObj.timezone);

  return { ...safeDates, ...safeTimezone };
};
