import { SelectField, Option } from '@dashboard/library';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

interface AudioTypeSelectFieldProps {
  name: string;
  label: string;
  options: Option[];
}

export function AudioTypeSelectField({ name, label, options }: AudioTypeSelectFieldProps) {
  const form = useForm();
  const { t } = useTranslation();

  const handleChange = (value: string | number) => {
    form.change(name, value);
  };

  return (
    <SelectField
      t={t}
      name={name}
      label={label}
      options={options}
      onChange={handleChange}
      data-test='audio-type-select-field'
    />
  );
}
