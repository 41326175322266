/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect } from 'react';

import { ClientException, handleApolloError } from '../../helpers/errors.helpers';

import { CustomMutate, UseGraphMutationReturn } from './useGraphMutation.decl';

import {
  ApolloError,
  DocumentNode,
  MutationHookOptions,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';

/**
 * Hook to execute a GraphQL mutations.
 * @param mutation - The mutation to execute.
 * @param options - Option that apply to the mutation.
 * @returns A mutate function that can be called at any time to execute the mutation and an object with fields that represent the current status of the mutation's execution.
 */
export const useGraphMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>
): UseGraphMutationReturn<TData, TVariables> => {
  const [mutate, { error, ...mutationResult }] = useMutation(mutation, options);

  const customMutate: CustomMutate<TData, TVariables> = useCallback(
    async (mutateOptions) => {
      try {
        return await mutate(mutateOptions);
      } catch (e) {
        const err = e as ApolloError;

        throw new ClientException(handleApolloError(err), err);
      }
    },
    [mutate]
  );

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [error]);

  return [customMutate, { ...mutationResult, error: error ? handleApolloError(error) : undefined }];
};
