import { gql } from '@apollo/client';

export const APPLICATIONS_LIST_QUERY = gql`
  query ApplicationsListQuery {
    getApplications {
      comingSoon
      id
      logo
      name
      shortName
      source
    }
  }
`;
