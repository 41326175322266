import { useContext } from 'react';

import {
  ModuleFederationContextType,
  ModuleFederationContext,
} from '@state/app/module-federation/ModuleFederationContext.decl';

export const ERROR_MESSAGE =
  'useModuleFederationContext should be used within a ModuleFederationProvider';

export function useModuleFederationContext(): ModuleFederationContextType {
  const context = useContext(ModuleFederationContext);

  if (!context) {
    throw new Error(ERROR_MESSAGE);
  }

  return context;
}
