import { useContext } from 'react';

import { UseReseller } from './useReseller.decl';

import { RESELLER_PLANS } from '@constants/plans.constants';
import { GlobalDataContext } from '@state/app/global/GlobalProvider';

export function useReseller(): UseReseller {
  const globalDataContext = useContext(GlobalDataContext);
  const planName = globalDataContext?.currentCompany.plan!;

  const isReseller = RESELLER_PLANS.includes(planName);
  return { isReseller };
}
