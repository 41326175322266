/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, useMemo } from 'react';

import { PermissionContextType, PermissionsProviderProps } from './Permissions.decl';

import { RESOURCE } from '@constants/permissions.constants';
import { Loading } from '@dashboard/library';
import { GetCurrentUserQuery } from '@generated/GetCurrentUserQuery';
import { GET_CURRENT_USER_QUERY } from '@graphql/queries/GetCurrentUserQuery';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useGraphQuery } from '@hooks/useQuery';

export const PermissionsContext = createContext<PermissionContextType>({} as PermissionContextType);

export function PermissionsProvider({ children }: PermissionsProviderProps) {
  const {
    authState: { decodedToken },
  } = useAuthenticationState();

  const { data, loading } = useGraphQuery<GetCurrentUserQuery>(GET_CURRENT_USER_QUERY, {
    throwError: true,
  });

  const value = useMemo(() => {
    if (!decodedToken || loading || !data) {
      return null;
    }

    const { 'custom:permissions': customPermissions } = decodedToken;

    // User has access to dashboard if he has 1 or more of those resources
    const dashboardResources = [
      RESOURCE.NUMBERS,
      RESOURCE.TEAMS,
      RESOURCE.USERS,
      RESOURCE.STATS,
      RESOURCE.ACTIVITY_FEED,
      RESOURCE.CALL_SETTINGS,
      RESOURCE.INTEGRATIONS,
      RESOURCE.COMPANY_GENERAL,
      RESOURCE.COMPANY_PLAN,
      RESOURCE.COMPANY_BILLING,
      RESOURCE.COMPANY_SECURITY,
      RESOURCE.COMPANY_ROLES,
    ];
    const hasDashboardAccess = Object.keys(customPermissions).some((p) =>
      dashboardResources.includes(p as RESOURCE)
    );

    return {
      roles: data.getAgentV2.roles,
      permissions: customPermissions,
      // The user has access to the dashboard if he has any of the dashboard permissions
      hasDashboardAccess,
    };
  }, [decodedToken, loading, data]);

  if (!value) {
    return <Loading data-test='permissions-loading' />;
  }

  return (
    <PermissionsContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </PermissionsContext.Provider>
  );
}
