import { useCallback } from 'react';

import DEFAULT_INTEGRATION_LOGO from '../../assets/images/integration_logo.svg';
import { CardContainer } from '../CardContainerV2';

import { IntegrationCardProps } from './IntegrationCard.decl';

import { TickCircleFilled } from '@aircall/icons';
import { Avatar, AvatarImg, Icon, Flex, Typography, Box } from '@aircall/tractor-v2';

export function IntegrationCard({ item, onClick, checked }: IntegrationCardProps) {
  const { logo, customName, name } = item.attributes;
  const displayName = customName || name;

  const onClickHandler = useCallback(() => {
    onClick(!checked, item.id);
  }, [onClick, item, checked]);

  return (
    <CardContainer
      onClick={onClickHandler}
      margin='0 auto'
      w='100%'
      data-test='integration-card'
      cursor='pointer'
    >
      {checked && (
        <Box position='absolute' top={-10} right={-10} data-test='tick-icon'>
          <Icon component={TickCircleFilled} size={24} color='icon-primary' />
        </Box>
      )}
      <Flex flexDirection='column' alignItems='center'>
        <Avatar size='large'>
          <AvatarImg src={logo || DEFAULT_INTEGRATION_LOGO} />
        </Avatar>
        <Typography marginTop={20} ellipsis>
          {displayName}
        </Typography>
      </Flex>
    </CardContainer>
  );
}
