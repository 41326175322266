import { LinePositionProps } from './TrialChecklistPopup.decl';

import { ArrowSendOutlined, TickOutlined } from '@aircall/icons';
import { Box, Flex, Icon } from '@aircall/tractor-v2';

export function LinePositions({
  indexPosition,
  skipTopLine, // to skip the top line position
  skipBottomLine, // to skip the bottom line position
  isActive,
  isComplete,
}: Readonly<LinePositionProps>) {
  const hasIcon = isActive || isComplete;
  const iconType = isActive ? ArrowSendOutlined : TickOutlined;
  return (
    <>
      {!skipTopLine && (
        <Box
          data-test='line-position-top'
          h='50%'
          position='absolute'
          w={3}
          backgroundColor='icon-disabled'
          top={0}
          zIndex={-1}
          left={10.6}
        />
      )}
      <Box
        data-test={`line-position-${indexPosition}`}
        h='100%'
        position='relative'
        zIndex={1}
        opacity={isComplete ? 0.8 : 1}
      >
        <Flex
          w={12}
          h={12}
          borderRadius='100%'
          backgroundColor='icon-disabled'
          justifyContent='center'
          alignItems='center'
          data-test='line-position-wrapper'
        >
          {hasIcon && (
            <Flex
              w={24}
              minWidth={24}
              h={24}
              justifyContent='center'
              alignItems='center'
              borderRadius='100%'
              borderWidth={4}
              borderStyle='solid'
              backgroundColor='surface-inverted'
              borderColor='surface-interactive-default'
              data-test='line-position-iconWrapper'
            >
              <Icon
                component={iconType}
                size={12}
                color='surface-default'
                data-test='line-position-icon'
              />
            </Flex>
          )}
        </Flex>
      </Box>
      {!skipBottomLine && (
        <Box
          data-test='line-position-bottom'
          h='50%'
          position='absolute'
          w={3}
          backgroundColor='icon-disabled'
          bottom={0}
          zIndex={-1}
          left={10.6}
        />
      )}
    </>
  );
}
