import { gql } from '@apollo/client';

export const GET_WORKATO_CONNECTION_URL_QUERY = gql`
  query GetWorkatoConnectionUrlQuery($solutionInstanceId: String!) {
    getWorkatoConnectionUrl(solutionInstanceId: $solutionInstanceId) {
      url
      isConnectionRemaining
      title
      description
      note
      applicationName
    }
  }
`;
