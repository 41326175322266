import { gql } from '@apollo/client';

export const COMPANY_ADDONS_QUERY = gql`
  query CompanyAddonsQuery {
    companyFeatures {
      id
      addons {
        name
        status
        trials {
          id
          active
          startDate
          endDate
          remainingDays
        }
      }
    }
  }
`;
