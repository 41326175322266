import {
  LINE_ACTIVATION_STATE,
  LINE_CAPABILITY,
  LINE_PROVIDER,
} from '@constants/numbers.constants';
import { SearchLineByIDQuery_searchLines_items as Line } from '@generated/SearchLineByIDQuery';
import { CountryCode, NumberType, parsePhoneNumber } from 'libphonenumber-js';

export function isNumberActive(activationState: string | null): boolean {
  return activationState === null || activationState === LINE_ACTIVATION_STATE.ACTIVE;
}

export function getNumberType(number: string, countryCode: string): NumberType | null {
  try {
    return parsePhoneNumber(number, countryCode as CountryCode).getType();
  } catch {
    return null;
  }
}

export function isRegistrationRequiredForNumber(
  line: Line | undefined,
  dashboardEnableA2P10DlcRegistrationStatus: boolean
): boolean {
  // Query is loading
  if (!line) {
    return false;
  }

  const { capabilities, countryISOCode, phoneNumber, provider } = line;
  const numberType = getNumberType(phoneNumber, countryISOCode);

  // FF is disabled or `getNumberType` failed to retrieve type
  if (!dashboardEnableA2P10DlcRegistrationStatus || !numberType) {
    return false;
  }

  // Number is neither Canadian nor American
  if (!['CA', 'US'].includes(countryISOCode)) {
    return false;
  }

  // Number is Canadian but is not toll free
  if (countryISOCode === 'CA' && numberType !== 'TOLL_FREE') {
    return false;
  }

  // Number is American but doesn't have SMS capability
  if (countryISOCode === 'US' && !capabilities?.includes(LINE_CAPABILITY.SMS)) {
    return false;
  }

  // Number provider is neither Bandwidth or Twilio
  if (![LINE_PROVIDER.BANDWIDTH, LINE_PROVIDER.TWILIO].includes(provider as LINE_PROVIDER)) {
    return false;
  }

  return true;
}
