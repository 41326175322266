/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';

import { UpdateNumberInputDataAttributes } from '@generated/globalTypes';
import { NumberDetailQuery_numberDetail } from '@generated/NumberDetailQuery';
import {
  UpdateNumberMutation,
  UpdateNumberMutationVariables,
} from '@generated/UpdateNumberMutation';
import { UPDATE_NUMBER_MUTATION } from '@graphql/mutations/UpdateNumberMutation';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useNumberDetail } from '@hooks/useNumberDetail';
import { useTracker } from '@hooks/useTracker/useTracker';

interface UpdateNumberTogglesFnOptions {
  successMessage?: string;
  disableOptimisticResponse?: boolean;
  trackingOptions?: TrackingOptions;
}

export type UpdateNumberTogglesFn = (
  values: UpdateNumberInputDataAttributes,
  options?: UpdateNumberTogglesFnOptions
) => Promise<void>;
interface UseUpdateNumberReturnType {
  updateNumberToggles: UpdateNumberTogglesFn;
  number: NumberDetailQuery_numberDetail | null | undefined;
  loading: boolean;
}

interface UseUpdateNumberTogglesOptions {
  onCompleted?: () => void;
}

interface TrackingOptions {
  section: string;
  setting: string;
}

export function useUpdateNumberToggles({
  onCompleted,
}: UseUpdateNumberTogglesOptions): UseUpdateNumberReturnType {
  const { number } = useNumberDetail();
  const { track } = useTracker();
  const [updateNumberMutation, { loading }] = useGraphMutationWithFeedback<
    UpdateNumberMutation,
    UpdateNumberMutationVariables
  >(UPDATE_NUMBER_MUTATION, { type: 'snackbar' }, { onCompleted });

  const updateNumberToggles = useCallback(
    async (values, { successMessage, disableOptimisticResponse, trackingOptions } = {}) => {
      await updateNumberMutation(
        {
          variables: {
            input: {
              data: {
                id: parseInt(number!.id),
                type: 'number',
                attributes: {
                  ...values,
                },
              },
            },
            numberId: parseInt(number!.id),
          },
          refetchQueries: ['GetNumberDetails'],
          optimisticResponse: disableOptimisticResponse
            ? undefined
            : {
                updateNumber: {
                  ...number,
                  ...values,
                },
              },
        },
        { successMessage }
      );

      trackingOptions &&
        track({
          event: 'number_settings_edited',
          payload: {
            number_id: number?.id,
            settings_section: trackingOptions.section,
            setting: trackingOptions.setting,
            action: 'edited',
          },
        });
    },
    [number, track, updateNumberMutation]
  );

  return { updateNumberToggles, number, loading };
}
