import { useCallback, useState } from 'react';

import styled from 'styled-components';

import { Flex, Spacer, Typography, getColor } from '@aircall/tractor-v2';
import rolesSvg from '@assets/images/roles-modal.svg';
import { AnnouncementModal } from '@components/AnnouncementModal';
import { PERMISSIONS_MODAL_LS_KEY } from '@constants/permissions.constants';
import { ACCOUNT_ROUTE_TAB, ACCOUNT_TABS } from '@constants/routes.constants';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { usePermissions } from '@hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';

const Image = styled(Flex)`
  height: 180px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  background-color: ${getColor('decorative2-600')};
  background-position: center;
  background-size: cover;
  gap: ${({ theme }) => theme.space.xs}px;
`;

export function PermissionsModal() {
  const { t } = useTranslation();
  const { enablePermissionsNotifications } = usePermissions();
  const [open, setOpen] = useState(true);
  const navigate = useNavigateWithParamsReplace();
  const [dismissed, setDismissed] = useLocalStorage(PERMISSIONS_MODAL_LS_KEY, false);

  const handleClose = useCallback(() => {
    setOpen(false);
    setDismissed(true);
  }, [setDismissed]);

  const handleClickLearnMore = useCallback(() => {
    handleClose();
    navigate(ACCOUNT_ROUTE_TAB, { tab: ACCOUNT_TABS.PERMISSIONS });
  }, [handleClose, navigate]);

  if (dismissed || !enablePermissionsNotifications) {
    return null;
  }

  return (
    <AnnouncementModal
      isOpen={open}
      renderImage={() => (
        <Image>
          <img src={rolesSvg} alt='roles' />
          <Spacer space='m'>
            <Typography position='absolute' left='52px' variant='bodySemiboldM' color='text.dark'>
              {t('permissions.modal.image_role_1')}
            </Typography>
            <Typography variant='bodySemiboldM' color='text.dark'>
              {t('permissions.modal.image_role_2')}
            </Typography>
            <Typography position='absolute' right='33px' variant='bodySemiboldM' color='text.dark'>
              {t('permissions.modal.image_role_3')}
            </Typography>
          </Spacer>
        </Image>
      )}
      tagText={t('permissions.modal.tag')}
      title={t('permissions.modal.title')}
      text={t('permissions.modal.text')}
      secondaryButtonText={t('permissions.modal.button')}
      primaryButtonText={t('generic.learn_more')}
      onClose={handleClose}
      onClickPrimary={handleClickLearnMore}
      onClickSecondary={handleClose}
    />
  );
}
