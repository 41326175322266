import { Box, TextInput } from '@aircall/tractor-v2';
import { AudioPlayer } from '@components/AudioPlayer';
import styled, { css, getColor } from '@xstyled/styled-components';

export const TRANSLATION_PREFIX =
  'number_details.settings.call_distribution_section.audio_setting_modal.file';

export const CustomButton = styled.button<{ isActive: string | undefined }>`
  padding: 10px;
  border: none;
  border-radius: 8px 8px 0 0;
  background-color: surface-default;
  color: text-secondary;
  cursor: pointer;
  font-weight: 600;

  ${({ isActive }) => css`
    border-width: 1px;
    border-style: dashed;
    border-radius: ${isActive ? '8px 8px 0 0' : null};
    border-top: none;
    border-right-color: ${isActive === 'right' ? getColor('neutral-400') : 'transparent'};
    border-bottom-color: ${!isActive ? getColor('neutral-400') : 'transparent'};
    border-left-color: ${isActive === 'left' ? getColor('neutral-400') : 'transparent'};
  `};
`;

export const CustomTextField = styled(TextInput)`
  width: 190px;
  border: none;
`;

export const CustomPlayer = styled(AudioPlayer)`
  height: 48px;
  padding-right: 16px;
`;

export const AudioPlayerContainer = styled(Box)`
  border: 1px solid ${getColor('neutral-200')};
  border-radius: sm;
  background-color: surface-default;
`;
