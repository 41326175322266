import { useCallback } from 'react';

import styled from 'styled-components';

import { DaysPicker } from '../DaysPicker/DaysPicker';
import { TimeSelect } from '../TimeSelect/TimeSelect';

import { TimeSlotFieldProps } from './TimeSlotsField.decl';

import { MenuVerticalFilled } from '@aircall/icons';
import { FormItem, Spacer, ActionMenu, Button, Box } from '@aircall/tractor-v2';
import { GridLayout, GridItem } from '@dashboard/library';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export const TimeSlotContainer = styled(GridLayout)`
  position: relative;
`;

export function TimeSlotField({
  from,
  to,
  name,
  hasIcon,
  onRemove,
  'data-test': dataTest,
  ...otherProps
}: TimeSlotFieldProps) {
  const form = useForm();
  const { t } = useTranslation();
  const handleChangeFrom = useCallback(
    (value: number) => {
      form.change(`${name}.from`, value);
      form.change(`${name}.to`, Math.max(value + 30, to));
    },
    [form, name, to]
  );

  return (
    <TimeSlotContainer
      gridTemplateColumns='repeat(12, minmax(0, 1fr))'
      w='100%'
      p='s'
      pr='xl'
      data-test={dataTest}
      {...otherProps}
    >
      <GridItem xs={6}>
        <FormItem label={t('number_details.business_hours.business_hours_section.available_on')}>
          <Field name={`${name}.days`}>
            {({ input: { value, onChange } }) => (
              <DaysPicker mt={12} selectedDays={value} onChange={onChange} />
            )}
          </Field>
        </FormItem>
      </GridItem>
      <GridItem xs={6}>
        <Spacer space='s'>
          <FormItem label={t('number_details.business_hours.business_hours_section.from')}>
            <Field name={`${name}.from`}>
              {({ input: { value } }) => (
                <TimeSelect
                  data-test={`${dataTest}-from-time-select`}
                  value={value}
                  from={0}
                  to={1410}
                  onChange={handleChangeFrom}
                />
              )}
            </Field>
          </FormItem>
          <FormItem label={t('number_details.business_hours.business_hours_section.to')}>
            <Field name={`${name}.to`}>
              {({ input: { value, onChange } }) => (
                <TimeSelect
                  data-test={`${dataTest}-to-time-select`}
                  value={value}
                  from={from + 30}
                  onChange={onChange}
                />
              )}
            </Field>
          </FormItem>
        </Spacer>
      </GridItem>
      {hasIcon && (
        <Box position='absolute' top='2px' right='2px'>
          <ActionMenu
            trigger={
              <Button
                mode='ghost'
                shape='circle'
                variant='secondary'
                size='xSmall'
                data-test={`${dataTest}-action-button`}
              >
                <MenuVerticalFilled />
              </Button>
            }
            items={[
              {
                name: t('timeslots_form.timeslots.remove'),
                onClick: onRemove,
              },
            ]}
          />
        </Box>
      )}
    </TimeSlotContainer>
  );
}
