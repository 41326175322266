import { ResourceLinkCard } from './ResourceLinkCard';

import { ShareScreenFilled, DocumentationFilled, OnboardingFilled } from '@aircall/icons';
import { Flex, Typography, Box, Divider, Spacer } from '@aircall/tractor-v2';
import { getResellerConfig } from '@config/resellers.config';
import { useTranslation } from 'react-i18next';

export function ResourceLinks() {
  const { t } = useTranslation();
  const {
    featureSet: { shouldShowOnboardingPortal, shouldShowOnboardingWebinars },
  } = getResellerConfig();

  return (
    <Flex flexDirection='column' data-test='resource-links-section'>
      <Spacer direction='vertical' space='s' fluid>
        <Typography variant='headingBoldS'>{t('onboarding.resource.title')}</Typography>
        <Spacer direction='vertical' space='s' fluid>
          <Box
            borderColor='neutral-500'
            borderStyle='solid'
            borderWidth={1}
            borderRadius={8}
            backgroundColor='white'
            overflow='hidden'
          >
            {shouldShowOnboardingPortal && (
              <>
                <ResourceLinkCard resourceName='onboarding_portal' icon={OnboardingFilled} />
                <Divider orientation='horizontal' bg='neutral-500' size='xSmall' />
              </>
            )}
            <ResourceLinkCard resourceName='knowledge_base' icon={DocumentationFilled} />
            {shouldShowOnboardingWebinars && (
              <>
                <Divider orientation='horizontal' bg='neutral-500' size='xSmall' />
                <ResourceLinkCard resourceName='aircall_webinars' icon={ShareScreenFilled} />
              </>
            )}
          </Box>
        </Spacer>
      </Spacer>
    </Flex>
  );
}
