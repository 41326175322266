import { useCallback, useMemo } from 'react';

import { ApplicationPageLayout } from '@components/ApplicationPageLayout/ApplicationPageLayout';
import { INTEGRATION_FULL_ROUTES } from '@constants/routes.constants';
import { capitalizeFirstLetter } from '@dashboard/library';
import { GetWebhookConfigurationsQuery } from '@generated/GetWebhookConfigurationsQuery';
import { WEBHOOKS_CONFIGURATION_QUERY } from '@graphql/queries/WebhooksQuery';
import { NotFoundError } from '@helpers/errors.helpers';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useGraphQuery } from '@hooks/useQuery/useGraphQuery';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export function ApplicationPage() {
  const { t } = useTranslation();
  const { applicationName, applicationType, applicationId } = useParams();
  const navigate = useNavigateWithParamsReplace();
  const { data, loading } = useGraphQuery<GetWebhookConfigurationsQuery>(
    WEBHOOKS_CONFIGURATION_QUERY
  );

  const integration = useMemo(
    () =>
      data?.getWebhookConfigurations.find((app) => app.shortName === applicationName.toLowerCase()),
    [data?.getWebhookConfigurations, applicationName]
  );

  const description = t(`integrations.install.${applicationName}.desc`);
  const subtitle = t(`integrations.install.${applicationName}.subtitle`);
  const features: string[] = t(`integrations.install.${applicationName}.features_array`, {
    returnObjects: true,
  });

  const handleInstallButtonClick = useCallback(
    () =>
      navigate(INTEGRATION_FULL_ROUTES.FLOW_INSTALL, {
        applicationName,
        applicationType,
        applicationId,
      }),
    [applicationName, navigate, applicationId, applicationType]
  );

  if (!integration && !loading) {
    throw new NotFoundError(`Integration not found`, {
      applicationName,
      applicationType,
      applicationId,
    });
  }

  return (
    <ApplicationPageLayout
      subtitle={subtitle}
      description={description}
      features={features}
      shouldShowInstallButton
      name={capitalizeFirstLetter(applicationName)}
      logo={integration?.logo}
      loading={loading}
      onInstallButtonClick={handleInstallButtonClick}
    />
  );
}
