import { UseAddIvrSettingProps, UseAddIvrSettingReturn } from './useAddIvrSetting.decl';

import { NUMBER_PURCHASE_ROUTE_AT_IVR_CREATION } from '@constants/routes.constants';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { AddIvrSettingRest, AddIvrSettingRestVariables } from '@generated/AddIvrSettingRest';
import { IvrSettingInputFields } from '@generated/globalTypes';
import { ADD_IVR_SETTING_MUTATION } from '@graphql/mutations/AddIvrSettingMutation';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { IvrSettingFormValues } from '@pages/number-detail-call-distribution/IvrSettingModal/IvrSettingModal.decl';
import { useTranslation } from 'react-i18next';

/**
 * The hook handles adding a ivr setting by numberId.
 * @returns A callback function to add ivr setting with number id and payload provided by user
 */
export function useAddIvrSetting({
  numberId,
  onCompleted,
}: UseAddIvrSettingProps): UseAddIvrSettingReturn {
  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();
  const [mutate] = useGraphMutationWithFeedback<AddIvrSettingRest, AddIvrSettingRestVariables>(
    ADD_IVR_SETTING_MUTATION,
    { type: 'form', validationErrorAsFormError: true },
    { onCompleted }
  );
  const addIvrSetting = async ({
    type,
    key,
    internalNumber,
    externalNumber,
  }: IvrSettingFormValues) => {
    if (type === 'new') {
      navigate(NUMBER_PURCHASE_ROUTE_AT_IVR_CREATION, {
        numberId,
        key,
      });
      return null;
    }

    const inputFields: IvrSettingInputFields =
      type === 'internal'
        ? { key, redirectingNumberId: internalNumber!.id, redirectingExternalNumber: '' }
        : { key, redirectingExternalNumber: externalNumber!, redirectingNumberId: null };
    const displayName =
      type === 'internal'
        ? internalNumber!.name
        : t(
            'number_details.settings.call_distribution_section.ivr_setting_modal.success_message.external_number'
          );

    const { error } = await mutate(
      {
        variables: { numberId, input: { ivrSetting: inputFields } },
        update: (cache, { data }) => {
          /* istanbul ignore if */
          if (!data) {
            return;
          }
          const { id } = data.addIvrSettingRest;
          cache.modify({
            id: cache.identify({ __typename: 'Number', id: numberId }),
            fields: {
              ivrSettings: (existingIvrSettings) => [
                ...existingIvrSettings,
                {
                  __typename: 'IvrSettingRest',
                  id: id.toString(),
                  key,
                  branchType: type,
                  redirectingNumber: internalNumber || null,
                  redirectingExternalNumber: externalNumber || null,
                },
              ],
            },
          });
        },
      },
      {
        successMessage: t(SUCCESS_FEEDBACK_MESSAGES.ADD_SUCCESS, {
          name: displayName,
        }),
      }
    );

    return error;
  };

  return { addIvrSetting };
}
