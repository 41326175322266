import { gql } from '@apollo/client';

export const WEBHOOKS_QUERY = gql`
  query WebhooksQuery {
    webhooks @rest(type: "RestWebhook", path: "v1/integrations.json") {
      allowedRetries
      id
      customName
      friendlyName
      type
      logo
      url
      events
      isActive
      clientSecret
    }
  }
`;

export const WEBHOOKS_QUERY_IA = gql`
  query GetWebhooksQueryIA {
    getWebhooks {
      allowedRetries
      id
      customName
      type
      logo
      url
      events
      isActive
      clientSecret
    }
  }
`;

export const WEBHOOKS_CONFIGURATION_QUERY = gql`
  query GetWebhookConfigurationsQuery {
    getWebhookConfigurations {
      events
      fields
      helpLink
      logo
      name
      sectionEvents {
        call
        contact
        number
        user
        message
        conversationIntelligence
      }
      shortName
      urlFormat
    }
  }
`;
