import { useCallback, useState } from 'react';

import { UseSaveOnTimeoutParams, UseSaveOnTimeoutReturn } from './useSaveUserRingTimeout.decl';

import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { UpdateAgentMutation, UpdateAgentMutationVariables } from '@generated/UpdateAgentMutation';
import { UPDATE_AGENT_MUTATION } from '@graphql/mutations/UpdateAgentMutation';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useTranslation } from 'react-i18next';

/**
 * Hook to update ring timeout of an user
 * @param UseSaveOnTimeoutParams - destructured param
 * @returns an object holding ringTimeout value and mutate function
 */
export const useSaveUserRingTimeout = ({
  user,
}: UseSaveOnTimeoutParams): UseSaveOnTimeoutReturn => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(user.ringTimeout.toString());

  const rollbackQueingTimeout = useCallback(
    ({ graphQLErrors }) => {
      setValue(user.ringTimeout.toString());
      throw graphQLErrors;
    },
    [user]
  );

  const [updateAgent] = useGraphMutationWithFeedback<
    UpdateAgentMutation,
    UpdateAgentMutationVariables
  >(UPDATE_AGENT_MUTATION, { type: 'snackbar' }, { onError: rollbackQueingTimeout });

  const onChange = useCallback(
    async (newValue: string) => {
      const ringTimeout = parseInt(newValue);

      setValue(newValue);

      await updateAgent(
        {
          variables: {
            input: { ID: user.id.toString(), ringTimeout },
          },
          update: (cache) => {
            cache.modify({
              id: cache.identify({ __typename: 'User', id: user.id }),
              fields: {
                ringTimeout: () => ringTimeout,
              },
            });
          },
        },
        {
          successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
            name: t('number_details.tabs.call_distribution'),
          }),
        }
      );
    },
    [t, updateAgent, user.id]
  );

  return {
    onChange,
    value,
  };
};
