import {
  Authorizations,
  PresenceChannel,
  PrivateChannel,
  PrivateEncryptedChannel,
  PublicChannel,
} from '@services/authorizeChannels.decl';

const makeIsAuthorizerResponse =
  <T extends Authorizations>(type: T['__typename']) =>
  (channel: Authorizations): channel is T =>
    channel.__typename === type;

export const isPrivateAuthorizer = makeIsAuthorizerResponse<PrivateChannel>('PrivateChannel');

export const isPrivateEncryptedAuthorizer =
  makeIsAuthorizerResponse<PrivateEncryptedChannel>('PrivateEncryptedChannel');

export const isPresenceAuthorizer = makeIsAuthorizerResponse<PresenceChannel>('PresenceChannel');

export const isPublicAuthorizer = makeIsAuthorizerResponse<PublicChannel>('PublicChannel');
