import { useEffect, useRef, useState } from 'react';

import { AnalyticsPlusAdModal } from '@components/AnalyticsPlusAdModal';
import { ANALYTICS_PLUS_AD_MODAL_TRACK_EVENTS } from '@components/AnalyticsPlusAdModal/AnalyticsPlusAdModal.decl';
import { NavItem } from '@components/NavItem';
import { SidebarItem } from '@components/Sidebar/sidebarItems';
import { generateUniqId } from '@dashboard/library';
import { useTracker } from '@hooks/useTracker';
import { useTranslation } from 'react-i18next';

export function AnalyticsPlusAdNavItem({ dataTest, icon, title, to }: Readonly<SidebarItem>) {
  const { t } = useTranslation();
  const { track } = useTracker();

  const [isAnalyticsPlusAdVisible, setIsAnalyticsPlusAdVisible] = useState(false);
  const funnelId = useRef<string>();

  const hideAnalyticsPlusAd = () => setIsAnalyticsPlusAdVisible(false);

  const showAnalyticsPlusAd = () => {
    setIsAnalyticsPlusAdVisible(true);
  };

  const handleClick = () => {
    funnelId.current = generateUniqId();

    showAnalyticsPlusAd();

    track({
      event: ANALYTICS_PLUS_AD_MODAL_TRACK_EVENTS.BUTTON_CLICKED,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      payload: { funnel_id: funnelId.current },
    });
  };

  useEffect(() => {
    track({ event: ANALYTICS_PLUS_AD_MODAL_TRACK_EVENTS.BUTTON_SHOWN });
  }, [track]);

  return (
    <>
      <NavItem
        data-test={dataTest}
        hasParent
        iconComponent={icon}
        isAd
        onClick={handleClick}
        selectedBgColor='neutral-100'
        text={t(title)}
        to={to}
      />
      <AnalyticsPlusAdModal
        funnelId={funnelId.current}
        onHide={hideAnalyticsPlusAd}
        show={isAnalyticsPlusAdVisible}
      />
    </>
  );
}
