import { useState, useMemo, Children, createContext, useCallback, useRef } from 'react';

import styled from 'styled-components';

import { FormFlowContextType, FormFlowProps, NextCallback } from './FormFlow.decl';
import { FormFlowPage } from './FormFlowPage/FormFlowPage';
import { FormFlowPageErrorBanner } from './FormFlowPage/FormFlowPageErrorBanner';
import { FormFlowPageSuccess } from './FormFlowPageSuccess';

import { Flex } from '@aircall/tractor-v2';
import noop from 'lodash-es/noop';

export const FormFlowContext = createContext<FormFlowContextType<any> | undefined>(undefined);

const Container = styled(Flex)`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;

export function FormFlow<DataType>({
  children,
  onSuccess = noop,
  onClose = noop,
  onCancel = noop,
  'data-test': dataTest,
  initialData = {} as DataType,
}: FormFlowProps<DataType>) {
  const [pageIndex, setPageIndex] = useState(0);
  const [data, setData] = useState<DataType>(initialData);
  const numberOfPages = Children.count(children);
  const [errorBanner, setErrorBanner] = useState<string>();
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const nextCallback = useRef<NextCallback<DataType>>();
  const childrenArr = Children.toArray(children);

  const resetBeforeNavigate = useCallback(() => {
    nextCallback.current = undefined; // Reset current next callback
    setErrorBanner(undefined); // Reset error banner
    setButtonLoading(false);
  }, []);

  const navigateToNextPage = useCallback(() => {
    resetBeforeNavigate();

    // If we are on last page already
    if (pageIndex === childrenArr.length - 1) {
      onSuccess(data);
      return;
    }
    // This wont be run if we are on last page already
    setPageIndex((index) => index + 1);
  }, [childrenArr.length, data, onSuccess, pageIndex, resetBeforeNavigate]);

  const context: FormFlowContextType<DataType> = useMemo(
    () => ({
      data,
      pageIndex,
      numberOfPages,
      setData: (newData: Record<string, unknown>) => setData({ ...data, ...newData }),
      navigateToNextPage,
      navigateToPrevPage: () => {
        resetBeforeNavigate();
        setPageIndex((index) => (index >= 1 ? index - 1 : 0));
      },
      registerNextCallback: (callback: NextCallback<DataType>) => {
        nextCallback.current = callback;
      },
      onClickNext: () => {
        if (nextCallback.current) {
          return nextCallback.current(context);
        }
        return navigateToNextPage();
      },
      onSuccess: () => onSuccess(data),
      onCancel,
      onClose,
      errorBanner,
      setPageIndex: (index) => {
        resetBeforeNavigate();
        setPageIndex(index);
      },
      setErrorBanner,
      buttonLoading,
      setButtonLoading,
      nextDisabled,
      setNextDisabled,
      isLastStep: pageIndex === childrenArr.length - 1,
    }),
    [
      data,
      pageIndex,
      numberOfPages,
      navigateToNextPage,
      onCancel,
      onClose,
      errorBanner,
      buttonLoading,
      nextDisabled,
      childrenArr.length,
      resetBeforeNavigate,
      onSuccess,
    ]
  );

  return (
    <FormFlowContext.Provider value={context}>
      <Container data-test={dataTest}>{childrenArr[pageIndex]}</Container>
    </FormFlowContext.Provider>
  );
}

FormFlow.Page = FormFlowPage;
FormFlow.PageSuccess = FormFlowPageSuccess;
FormFlow.ErrorBanner = FormFlowPageErrorBanner;
