import { useCallback, useEffect } from 'react';

import { useCreateWebhookIntegration } from './hooks/useCreateWebhookIntegration';
import { WebhookSetupSections } from './WebhookSetupSections';

import { Form } from '@aircall/tractor-v2';
import { DataLayout } from '@components/DataLayout/DataLayout';
import { APPLICATIONS_NAMES } from '@constants/integrations.constants';
import { GetWebhookConfigurationsQuery } from '@generated/GetWebhookConfigurationsQuery';
import { WEBHOOKS_CONFIGURATION_QUERY } from '@graphql/queries/WebhooksQuery';
import { NotFoundError } from '@helpers/errors.helpers';
import { useTracker } from '@hooks/useTracker/useTracker';
import { Form as RFForm } from 'react-final-form';
import { useParams } from 'react-router';

export function IntegrationFlowInstallWebhook() {
  const { applicationName } = useParams();
  const { onSubmit } = useCreateWebhookIntegration({ integrationName: applicationName });
  const { track } = useTracker();

  useEffect(() => {
    track({
      event: 'integration_installation_funnel_started',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      payload: { application_name: APPLICATIONS_NAMES.WEBHOOK },
    });
  }, [applicationName, track]);

  const toggleEvents = useCallback(
    (onChange: (value: string[]) => void, events: string[]) => (event: string, checked: boolean) => {
      onChange(
        checked
          ? [...new Set([...events, event])]
          : events.filter((webhookEvent) => webhookEvent !== event)
      );
    },
    []
  );

  return (
    <DataLayout<GetWebhookConfigurationsQuery> query={WEBHOOKS_CONFIGURATION_QUERY}>
      {({ data: { getWebhookConfigurations } }) => {
        const webhookConfig = getWebhookConfigurations.find(
          (application) => application.shortName === applicationName.toLowerCase()
        );

        if (!webhookConfig) {
          throw new NotFoundError('Webhook config not found', { applicationName });
        }

        const { events, urlFormat } = webhookConfig;

        return (
          <RFForm
            onSubmit={onSubmit}
            initialValues={{
              events,
              type: webhookConfig.name,
            }}
            keepDirtyOnReinitialize
          >
            {({ handleSubmit, hasValidationErrors, submitting }) => (
              <Form onSubmit={handleSubmit}>
                <WebhookSetupSections
                  integrationName={applicationName}
                  onSubmit={handleSubmit}
                  toggleEvents={toggleEvents}
                  urlFormat={urlFormat}
                  webhookConfig={webhookConfig}
                  shouldDisableSubmit={hasValidationErrors || submitting}
                />
              </Form>
            )}
          </RFForm>
        );
      }}
    </DataLayout>
  );
}
