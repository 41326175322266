import { parsePhoneNumber } from 'libphonenumber-js/max';

/**
 * Normalize an e164 phone number
 * The case appears on current_user.numbers
 * (ported from the legacy dashboard)
 * @param phoneNumber - Phone number in invalid e164 format (ex: 336123456789)
 * @param prefix - Optional prefix to pass to the normalizer. If used, it adds prefix to number.
 * @returns Phone number in valid e164 format (ex: +33123456789)
 */
export const normalizeNumber = (phoneNumber: string, prefix = ''): string =>
  phoneNumber.startsWith('+') || phoneNumber === 'anonymous'
    ? phoneNumber
    : `+${prefix}${phoneNumber}`;

/**
 * Format an e164 phone number to the international format
 * (ported from the legacy dashboard)
 * @param phoneNumber - Phone number in e164 format (ex: 33176420302)
 * @returns Phone number in international format (ex: +33 1 76 42 03 02)
 */
export const formatInternational = (phoneNumber: string): string => {
  try {
    return parsePhoneNumber(normalizeNumber(phoneNumber)).formatInternational();
  } catch (_) {
    // invalid phone number; fallback to the original format
    return phoneNumber;
  }
};

/**
 * Format an phone number to the e164 format
 * @param phoneNumber - Phone number (ex: +33 01 76 42 03 02)
 * @returns Phone number in e164 format (ex: +33176420302)
 */
export const formatToE164 = (number: string): string => {
  try {
    const phoneNumber = parsePhoneNumber(number);
    return phoneNumber.number;
  } catch (e) {
    return number;
  }
};

export const removeAllCharactersButNumbers = (number: string): string => number.replace(/\D/g, '');
