/* istanbul ignore file */
import { gql } from '@apollo/client';

export const GET_ANALYTICS_DASHBOARD_URL_QUERY = gql`
  query GetAnalyticsDashboardUrlQuery($input: AnalyticsDashboardUrlInput!) {
    getAnalyticsDashboardUrl(input: $input) {
      url
    }
  }
`;
