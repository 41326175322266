import styled from 'styled-components';

import { MacFilled, WindowsFilled } from '@aircall/icons';
import { Button, Icon } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { ResellerConfig } from '@config/resellers.config';
import { osType } from '@dashboard/library/build/helpers/browser/browser.helpers.decl';
import { useTranslation } from 'react-i18next';

const StyledLink = styled(ExternalLink)`
  &:hover {
    text-decoration: none;
  }
`;

const Icons = {
  mac: MacFilled,
  windows: WindowsFilled,
};

export function DownloadAppButton({ os }: { os: osType }) {
  const { t } = useTranslation();

  return (
    <StyledLink
      href={t(`resellers.${ResellerConfig.identifier}.onboarding.application.download_link_${os}`)}
      target='_blank'
      rel='noreferrer noopener'
      data-test='download-app-button-link'
    >
      <Button variant='secondary' mode='outline'>
        <Icon component={Icons[os]} />
        {t(`onboarding.application.download_for_${os}`)}
      </Button>
    </StyledLink>
  );
}
