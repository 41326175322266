import styled from 'styled-components';

import { PopupProps } from './Popup.decl';

import { Button, Flex, Spacer } from '@aircall/tractor-v2';

const FOOTER_HEIGHT = 72;

export const FooterBar = styled(Flex)`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: ${FOOTER_HEIGHT}px;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  padding-right: 16px;
  background: white;
  box-shadow: 0 -5px 20px rgba(0 0 0 10%);
`;

export function Popup({
  children,
  onSubmit,
  submitText,
  onCancel,
  cancelText,
  'data-test': dataTest = 'popup',
}: PopupProps) {
  return (
    <Flex
      h='100%'
      alignItems='center'
      flexDirection='column'
      backgroundColor='white'
      data-test={dataTest}
    >
      <Flex
        justifyContent='center'
        alignItems='center'
        w='100%'
        overflow='auto'
        h={`calc(100vh - ${FOOTER_HEIGHT}px)`}
      >
        {children}
      </Flex>

      <FooterBar>
        <Spacer space='xs' alignItems='center'>
          <Button
            onClick={onCancel}
            variant='secondary'
            mode='link'
            data-test={`${dataTest}-cancel-button`}
          >
            {cancelText}
          </Button>
          <Button onClick={onSubmit} data-test={`${dataTest}-submit-button`}>
            {submitText}
          </Button>
        </Spacer>
      </FooterBar>
    </Flex>
  );
}
