import { Dispatch, RefObject, SetStateAction } from 'react';

import { URLSearchObject } from '@components/SearchProvider/usePersistSearchParams';

export enum LOOKER_FILTER_KEY {
  DATE = 'Date',
  LINES = 'Lines',
  NUMBERS = 'Numbers',
  TAGS = 'Tags',
  TEAMS = 'Teams',
  USERS = 'Users',
  AIRCALL_NUMBER = 'Aircall number',
  BUSINESS_HOURS = 'Business Hours',
  HOURS = 'Hours',
  CALL_ID = 'Call ID',
  CALL_TYPE = 'Call type',
  CUSTOMER_NUMBER = 'Customer number',
  DATE_BREAKDOWN = 'Date Breakdown',
  INBOUND_SLA = 'Inbound SLA (in seconds)',
  MISSED_REASONS = 'Missed Reasons',
  SLA = 'SLA (in sec)',
  SLA_GOAL = 'SLA goal (%)',
  TIMEZONE = 'Timezone',
  NEXT_STATUS = 'Next status',
  STATUS = 'Status',
  OVERVIEW_METRICS = 'Show Overview metrics by',
  EXCLUDE_FROM_SLA = 'Exclude from SLA (select "is not")',
  MIN_CONNECTED = 'Min connected outbound in call time (sec)',
  OUTBOUND_CONNECTED = 'Min Outbound In Call Time (sec)',
  UNANSWERED_REASON = 'Unanswered Call Reason',
  IVR_BRANCH = 'IVR Branch',
  DURATION = 'Duration',
  CALL_ROUTED_TO_TEAM = 'Call routed to team',
  USERS_BELONGING_TO_TEAM = 'Users belonging to team',
}

export interface ReportPageProviderProps {
  children: React.ReactNode;
  onTruncated?: (truncated: boolean) => void;
}

export interface ReportPageContextProps {
  height: string;
  iFrameRef: RefObject<HTMLIFrameElement>;
  updateLookerFilter: (filters: URLSearchObject) => void;
  url: string | undefined;
  setUrl: Dispatch<SetStateAction<string | undefined>>;
  error: boolean;
}

/**
 * State machine to help track if a tile error happen after using stored data or after using fresh data.
 * - STORED_DATA, is set to know that Looker's dashboard is using stored data, default value
 * - FRESH_DATA, is set to know that Looker's dashboard is using its default data
 * - DATA_ERROR, is set when a tile error happen, DATA_ERROR can only happen after STORED_DATA
 * - OTHER_ERROR, is set when a tile error happen, OTHER_ERROR can only happen after FRESH_DATA
 */
export enum LOOKER_STATUS {
  STORED_DATA = 'stored data',
  FRESH_DATA = 'fresh data',
  DATA_ERROR = 'data error',
  OTHER_ERROR = 'other error',
}

export type LOOKER_DATA = 'searched-data' | 'stored-data' | 'default-data';
