import { APPLICATIONS_NAMES } from '../constants/integrations.constants';

import { pipe } from './composition.helpers';

import { replace, toLower } from '@dashboard/library';
import capitalize from 'lodash-es/capitalize';

/**
 * Checks if the integration is a webhook.
 * @param integrationName - the integration name
 * @returns a predicate
 */
export function isWebhookIntegration(integrationName: string): boolean {
  return [
    APPLICATIONS_NAMES.FRONT,
    APPLICATIONS_NAMES.ZAPIER,
    APPLICATIONS_NAMES.SLACK,
    APPLICATIONS_NAMES.WEBHOOK,
  ].includes(integrationName.toLowerCase() as APPLICATIONS_NAMES);
}

export type FormatFunc = (v: string) => string;

/**
 * Replaces spaces, special characters with underscores, and lowercases the name.
 * @param name - the name of the application.
 * @returns formatted string with underscores.
 */
export const formatApplicationName: FormatFunc = pipe(
  toLower,
  replace({ regex: /\s/g, replacement: '_' })
);

/**
 * Replaces underscores with space and then convert string to capital case.
 * example - 'zendesk_v3' will be formatted as 'Zendesk v3'
 * @param input - any string.
 * @returns formatted string with capital casing.
 */
export const convertSnakeToTitle = (input: string): string =>
  input ? capitalize(input.split('_').join(' ')) : '';
