import { useEffect, useRef } from 'react';

/**
 * Tracks previous state of a value.
 * @param value Props, state or any other calculated value.
 * @returns Value from the previous render of the enclosing component.
 *
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
}
