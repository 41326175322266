import { useLocation } from 'react-router';

export interface UseRoutePath {
  pathname: string;
  topLevelPath: string;
}
/**
 * Get the pathname and topLavelPath from current location
 * @returns pathname and topLavelPath
 */
export function useRoutePath(): UseRoutePath {
  const { pathname } = useLocation();
  const splitedPath = pathname.split('/');
  const topLevelPath = `/${splitedPath[1]}`;

  return {
    pathname,
    topLevelPath,
  };
}
