import { useCallback } from 'react';

import { BusinessHoursSectionProps } from './BusinessHoursSection.decl';

import { DataLayout } from '@components/DataLayout/DataLayout';
import { TimeSlotsForm, TimeSlotsFormValues } from '@components/TimeSlotsForm';
import { BUSINESS_HOURS_TYPE } from '@constants/generic.constants';
import {
  LineBusinessHoursQuery,
  LineBusinessHoursQueryVariables,
} from '@generated/LineBusinessHoursQuery';
import { LINE_BUSINESS_HOURS_QUERY } from '@graphql/queries/LineBusinessHoursQuery';
import { useUpdateBusinessHours } from '@hooks/useUpdateBusinessHours';
import { useTranslation } from 'react-i18next';

export function BusinessHoursSection({ numberId }: BusinessHoursSectionProps) {
  const { t } = useTranslation();
  const updateBusinessHours = useUpdateBusinessHours();

  const handleSubmit = useCallback(
    (businessHours: TimeSlotsFormValues) => updateBusinessHours(numberId, businessHours),
    [numberId, updateBusinessHours]
  );

  return (
    <DataLayout<LineBusinessHoursQuery, LineBusinessHoursQueryVariables>
      query={LINE_BUSINESS_HOURS_QUERY}
      queryOptions={{ variables: { lineID: numberId }, fetchPolicy: 'cache-and-network' }}
    >
      {({ data: { getLineBusinessHours } }) => (
        <TimeSlotsForm
          title={t('number_details.tabs.business_hours')}
          initialValues={getLineBusinessHours!}
          onSubmit={handleSubmit}
          businessHoursType={BUSINESS_HOURS_TYPE.LINES}
        />
      )}
    </DataLayout>
  );
}
