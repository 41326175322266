import { useLayoutEffect, useRef, useState } from 'react';

import { useThrottledCallback } from './useThrottledCallback';

interface UseInfiniteLoaderParams {
  /**
   * Ref of the container to listen scroll event to
   */
  containerRef?: React.RefObject<HTMLElement>;

  /**
   * Distance from the bottom from which we'd like to trigger
   * load of new data
   */
  threshold: number;

  /**
   * Handler to call when new data needs to be loaded
   */
  onLoad: () => void | Promise<void>;

  /**
   * Prevents from triggering the load of new data
   * Useful when the new data is still loading and the user
   * is still scrolling
   */
  skip: boolean;
}

export interface IUseInfiniteLoaderReturn {
  scrollAtBottom: boolean;
}

/**
 * Allows to be notified when the user reaches the bottom
 * of the given element to trigger the load of new data.
 * @param useInfiniteLoaderParams - destructured parameter
 * @returns an object holding the scrollAtBottom predicate
 */
export function useInfiniteLoader({
  containerRef,
  threshold,
  onLoad,
  skip,
}: UseInfiniteLoaderParams): IUseInfiniteLoaderReturn {
  const prevScrollTop = useRef(containerRef?.current?.scrollTop || 0);
  const [scrollAtBottom, setScrollAtBottom] = useState(false);

  // Computes the distance from the bottom and the direction (UP or DOWN)
  const [handleScroll] = useThrottledCallback(
    () => {
      const element = containerRef?.current;
      if (!element) {
        return;
      }

      const distanceFromThreshold =
        element.scrollHeight - element.clientHeight - element.scrollTop - threshold;

      const isPastThreshold = distanceFromThreshold <= 0;

      const isScrollingDown = element.scrollTop > prevScrollTop.current;

      if (isScrollingDown && isPastThreshold && !skip) {
        setScrollAtBottom(true);
        onLoad();
      } else {
        setScrollAtBottom(false);
      }

      prevScrollTop.current = element.scrollTop;
    },
    500,
    [containerRef, threshold, skip, onLoad]
  );

  // Listens to scroll events on the container
  useLayoutEffect(() => {
    const element = containerRef?.current;
    element?.addEventListener('scroll', handleScroll);
    return () => {
      element?.removeEventListener('scroll', handleScroll);
    };
  });

  return { scrollAtBottom };
}
