import { DeleteNumberConfirmationModalProps } from './DeleteNumberConfirmationModal.decl';

import { Typography, FlagIcon } from '@aircall/tractor-v2';
import { DeleteConfirmationModal } from '@components/DeleteConfirmationModalV2/DeleteConfirmationModal';
import { ExternalLink } from '@components/Link';
import { ModalCard } from '@components/ModalCardV2/ModalCard';
import { Trans, useTranslation } from 'react-i18next';

function BannerText() {
  const { t } = useTranslation();

  return (
    <Trans i18nKey='number_details.settings.delete.modal_banner'>
      Remember that once this action has been performed all corresponding data will be lost,
      including call recordings and voicemails related to this number. The data cannot be restored
      after the number has been deleted. If you wish to save any data or recordings prior to
      deletion, please visit
      <ExternalLink href={t('number_details.settings.delete.link_url')} target='_blank'>
        this guide
      </ExternalLink>
      for more information.
    </Trans>
  );
}

export function DeleteNumberConfirmationModal({
  isModalOpen,
  isDeleteDisabled,
  number,
  name,
  countryCode,
  onCancelClick,
  onDeleteNumberClick,
  error,
}: DeleteNumberConfirmationModalProps) {
  const { t } = useTranslation();

  return (
    <DeleteConfirmationModal
      isModalOpen={isModalOpen}
      onCancelClick={onCancelClick}
      onDeleteClick={onDeleteNumberClick}
      isDeleteDisabled={isDeleteDisabled}
      error={error}
      confirmationText={t('number_details.settings.delete.modal_confirmation_text')}
      bannerText={<BannerText />}
      card={
        <ModalCard
          avatar={<FlagIcon countryCode={countryCode} size={48} />}
          title={name}
          tag={<Typography variant='bodyMediumS'>{number}</Typography>}
        />
      }
    />
  );
}
