import { useCallback } from 'react';

import {
  UseDeleteOutboundCallUserParams,
  UseDeleteOutboundCallUserReturn,
} from './useDeleteOutboundCallUser.decl';

import { ApolloCache, Reference, StoreObject } from '@apollo/client';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import {
  DeleteOutboundCallerMutation,
  DeleteOutboundCallerMutationVariables,
} from '@generated/DeleteOutboundCallerMutation';
import { DELETE_OUTBOUND_CALLER_MUTATION } from '@graphql/mutations/DeleteOutboundCallerMutation';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useTranslation } from 'react-i18next';

export function useDeleteOutboundCallUser({
  numberId,
}: UseDeleteOutboundCallUserParams): UseDeleteOutboundCallUserReturn {
  const { t } = useTranslation();
  const [mutate] = useGraphMutationWithFeedback<
    DeleteOutboundCallerMutation,
    DeleteOutboundCallerMutationVariables
  >(DELETE_OUTBOUND_CALLER_MUTATION, {
    type: 'snackbar',
  });

  const deleteOutboundCallUser = useCallback(
    async ({ userId, name }: { userId: string; name: string }) => {
      const { error } = await mutate(
        {
          variables: {
            supervisionId: userId,
            numberId,
          },
          update(cache: ApolloCache<DeleteOutboundCallerMutation>) {
            cache.modify({
              id: `Number:${numberId}`,
              fields: {
                supervisions(supervisionsRefs, { readField }) {
                  return supervisionsRefs.filter(
                    (supervisionRef: StoreObject | Reference | undefined) =>
                      userId !== readField('id', supervisionRef)
                  );
                },
              },
            });
          },
          optimisticResponse: {
            deleteOutboundCaller: {
              _: null,
              __typename: 'Void',
            },
          },
        },
        { successMessage: t(SUCCESS_FEEDBACK_MESSAGES.DELETION_SUCCESS, { name }) }
      );

      return error;
    },
    [mutate, numberId, t]
  );

  return { deleteOutboundCallUser };
}
