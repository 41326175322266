import { useCallback } from 'react';

import { NumberDetailHeaderProps } from '../NumberDetail.decl';

import { RegistrationStatusTag } from './RegistrationStatusTagContainer/RegistrationStatusTag.container';

import { FlagIcon, Tag } from '@aircall/tractor-v2';
import { LineCapabilitiesTag } from '@components/LineCapabilitiesTag/LineCapabilitiesTag';
import { NumberVerificationStateTag } from '@components/NumberVerificationStateTag/NumberVerificationStateTag';
import { RealtimeBusinessHourState } from '@components/RealtimeBusinessHoursState/RealtimeBusinessHoursState';
import { NUMBERS_ROUTE } from '@constants/routes.constants';
import { Gap, PageHeader } from '@dashboard/library';
import { formatInternational } from '@helpers/phoneNumber.helpers';
import { useTranslation } from 'react-i18next';

export function NumberDetailHeader({
  line,
  numberDetail: {
    id,
    iso3166Alpha2,
    name,
    ivr,
    activationState,
    verificationState,
    state,
    flowEditorEnabled,
    number,
    numberUuid,
  },
  activeTabId,
  onTabChange,
  shouldShowRegistrationStatus,
  children,
}: NumberDetailHeaderProps) {
  const { t } = useTranslation();

  function renderSubLeft() {
    return (
      <Gap gap='xxs' flexDirection='row' alignItems='center'>
        {shouldShowRegistrationStatus && <RegistrationStatusTag />}
        <LineCapabilitiesTag capabilities={line?.capabilities} />
        {ivr && !flowEditorEnabled && (
          <Tag size='small' variant='white' mode='outline'>
            {t('numbers.card.ivr_tag')}
          </Tag>
        )}
      </Gap>
    );
  }

  const renderSubRight = useCallback(() => {
    // display verification state for unverified numbers
    if (activationState === 'inactive') {
      return (
        <NumberVerificationStateTag
          number={number}
          id={numberUuid}
          activationState={activationState}
          verificationState={verificationState}
        />
      );
    }

    return (
      <Tag size='small' variant='white' mode='outline'>
        <RealtimeBusinessHourState
          lineID={id}
          state={state}
          flowEditorEnabled={flowEditorEnabled}
        />
      </Tag>
    );
  }, [activationState, id, state, number, numberUuid, verificationState, flowEditorEnabled]);

  return (
    <PageHeader
      title={name}
      subtitle={formatInternational(number)}
      renderAvatar={() => <FlagIcon countryCode={iso3166Alpha2} size={32} />}
      activeTabId={activeTabId}
      onTabChange={onTabChange}
      gobackLinkRoute={NUMBERS_ROUTE}
      gobackLinkText={t('number_details.header.back_to_numbers')}
      renderSubLeft={renderSubLeft}
      renderSubRight={renderSubRight}
    >
      {children}
    </PageHeader>
  );
}
