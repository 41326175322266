import { AnalyticsDashboardType } from '@generated/globalTypes';
import { AnalyticsPlusAddonPage } from '@pages/analytics-plus-addon';
import { useTranslation } from 'react-i18next';

export function StatsUsersActivityPage() {
  const { t } = useTranslation();

  return (
    <AnalyticsPlusAddonPage
      feedbackButtonEvent='users_activity_feedback_button_clicked'
      pageHeaderTitle={t('stats.tabs.users_activity')}
      title='users_activity'
      type={AnalyticsDashboardType.USERS_ACTIVITY}
    />
  );
}
