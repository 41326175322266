import { gql } from '@apollo/client';

export const CREATE_WEBHOOK_MUTATION_IA = gql`
  mutation CreateWebhookMutationIA($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      id
      customName
      url
      events
    }
  }
`;
