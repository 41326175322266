import { TickCircleFilled } from '@aircall/icons';
import { Flex, Typography } from '@aircall/tractor-v2';
import { ReactComponent as OnboardingProgress0 } from '@assets/icons/onboarding_progress/progress_0.svg';
import { ReactComponent as OnboardingProgress11 } from '@assets/icons/onboarding_progress/progress_1.svg';
import { ReactComponent as OnboardingProgress22 } from '@assets/icons/onboarding_progress/progress_2.svg';
import { RouterLink } from '@components/Link';
import { useOnboarding } from '@pages/onboarding/hooks/useOnboarding';
import { useTranslation } from 'react-i18next';

export function HeaderOnboardingProgressIcon() {
  const { stepsComplete, isOnboardingPeriod, isOnboardingEnabled } = useOnboarding();
  const { t } = useTranslation();

  if (!isOnboardingEnabled || !isOnboardingPeriod) {
    return null;
  }

  if (stepsComplete >= 3) {
    return (
      <RouterLink to='/onboarding' data-test='header-onboarding-link'>
        {t('header.onboarding.all_set_up')}
        <TickCircleFilled color='blue.base' />
      </RouterLink>
    );
  }

  return (
    <RouterLink to='/onboarding' data-test='header-onboarding-link'>
      <Flex alignItems='center' data-test='header-onboarding-link-progress'>
        <Typography variant='bodyMediumS' mr='10px'>
          {t('header.onboarding.get_started')}
        </Typography>
        <svg width='32' height='33' viewBox='0 0 32 33'>
          <OnboardingProgress0 data-test='onboarding-progress-0' />
          {stepsComplete >= 1 && <OnboardingProgress11 data-test='onboarding-progress-1' />}
          {stepsComplete >= 2 && <OnboardingProgress22 data-test='onboarding-progress-2' />}
          <text
            y='50%'
            x='50%'
            dominantBaseline='central'
            textAnchor='middle'
            fontSize='10px'
            data-test='onboarding-progress-counter'
          >
            {stepsComplete}/3
          </text>
        </svg>
      </Flex>
    </RouterLink>
  );
}
