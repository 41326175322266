import { useCallback, useMemo } from 'react';

import { CustomTimezone, UseCustomTimezoneStateReturn } from './useCustomTimezoneState.decl';

import { useLookerPage } from '@pages/looker-v2/useLookerPage';
import { useField } from 'react-final-form';

export const useCustomTimezoneState = (): UseCustomTimezoneStateReturn => {
  const { input: timezoneInput } = useField('timezone');
  const { handleCustomFilterChange } = useLookerPage();

  const setCustomTimezone = useCallback(
    (timezone: CustomTimezone) => {
      if (timezone) {
        handleCustomFilterChange({ timezone });
      }

      timezoneInput.onChange(timezone);
    },
    [timezoneInput, handleCustomFilterChange]
  );

  const customTimezone = useMemo(() => timezoneInput.value, [timezoneInput]);

  return { customTimezone, setCustomTimezone };
};
