import React from 'react';

import { STATS_TABS } from './Stats.decl';

import { STATS_ROUTE_TAB } from '@constants/routes.constants';
import { routeReplacer } from '@dashboard/library';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { Navigate } from 'react-router-dom';

export function StatsPage() {
  const { dashboardNewOverviewEnabled } = useFeatures();

  return (
    <Navigate
      to={routeReplacer(STATS_ROUTE_TAB, {
        tab: dashboardNewOverviewEnabled ? STATS_TABS.OVERVIEW : STATS_TABS.OVERVIEW_BETA,
      })}
      replace
    />
  );
}
