import styled from 'styled-components';

import { LinkOutlined } from '@aircall/icons';
import { Flex, Typography, Icon, getColor, getSpace } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';

export type ResourceButtonProps = {
  heading: string;
  subHeading: string;
  link?: string;
};

const StyledLink = styled(ExternalLink)`
  &:hover,
  &:focus {
    border-color: ${getColor('text-base')};
    color: ${getColor('text-base')};
    text-decoration: none;
  }
  width: 100%;
  padding: ${getSpace('xs')};
  border: 1px solid ${getColor('graphic-default')};
  border-radius: 8px;
  color: ${getColor('text-base')};
`;

export function ResourceButton({ heading, subHeading, link }: Readonly<ResourceButtonProps>) {
  return (
    <StyledLink data-test='resource-card-link' target='_blank' href={link}>
      <Flex flexDirection='row' justifyContent='space-between' w='100%'>
        <Flex flexDirection='column'>
          <Typography data-test='discover-card-heading' variant='bodySemiboldM' mb='5px'>
            {heading}
          </Typography>
          <Typography
            data-test='discover-card-subheading'
            variant='bodyRegularS'
            color='text-secondary'
          >
            {subHeading}
          </Typography>
        </Flex>
        <Icon ml='auto' my='auto' data-test='arrow-icon' component={LinkOutlined} size={24} />
      </Flex>
    </StyledLink>
  );
}
