export enum LOGGING_FEATURE_NAMES {
  AUTHENTICATION = 'AUTHENTICATION',
  LEGACY_DASHBOARD = 'LEGACY_DASHBOARD',
  LOOKER = 'LOOKER',
}

export enum LOGGING_LEGACY_DASHBOARD_EVENTS {
  LOADING = 'LOADING',
}

export enum LOGGING_AUTHENTICATION_EVENTS {
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
}

export enum LOGGING_LOOKER_EVENTS {
  DASHBOARD_TILE_COMPLETE = 'DASHBOARD_TILE_COMPLETE',
}

export type LOGGING_EVENTS =
  | LOGGING_AUTHENTICATION_EVENTS
  | LOGGING_LEGACY_DASHBOARD_EVENTS
  | LOGGING_LOOKER_EVENTS;

export enum LOGGING_STATUS {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
}
