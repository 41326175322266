import { useCallback, useMemo } from 'react';

import {
  CardsProps,
  DataProps,
  MultiSelectModalFormValues,
  MultiSelectModalProps,
} from './MultiSelectModal.decl';

import { Box, Spacer, Button, Grid } from '@aircall/tractor-v2';
import { SearchBar } from '@components/SearchBar/SearchBar';
import { GridItem, GridLayout, Loading, ModalForm } from '@dashboard/library';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

function SelectAllButton<TItem, TId>({ data }: DataProps<TItem, TId>) {
  const { t } = useTranslation();
  const form = useForm();
  const {
    values: { selectedItems },
  } = form.getState();
  const hasSelected = !!selectedItems.length;

  function selectAll() {
    form.change('selectedItems', data || []);
  }

  function deselectAll() {
    form.change('selectedItems', []);
  }

  return (
    <Button mode='link' onClick={hasSelected ? deselectAll : selectAll} data-test='select-all'>
      {t(`multi_select_modal.${hasSelected ? 'deselect_all' : 'select_all'}`)}
    </Button>
  );
}

function Cards<TItem, TId>({ data, getDatumId, renderCard }: CardsProps<TItem, TId>) {
  const form = useForm<MultiSelectModalFormValues<TItem>>();

  const setChecked = useCallback(
    (selectedItem: TItem) => {
      const {
        values: { selectedItems },
      } = form.getState();

      const newSelectedIds = selectedItems.some(
        (item) => getDatumId(item) === getDatumId(selectedItem)
      )
        ? selectedItems.filter((item) => getDatumId(item) !== getDatumId(selectedItem))
        : [...selectedItems, selectedItem];

      form.change('selectedItems', newSelectedIds);
    },
    [form, getDatumId]
  );

  const list = data?.map((datum: TItem) => {
    const {
      values: { selectedItems },
    } = form.getState();
    const isChecked = selectedItems.some((item) => getDatumId(item) === getDatumId(datum));
    return renderCard(datum, isChecked, setChecked);
  }) as unknown as JSX.Element;

  // Grid throws an error when we render undefined so we use null
  return list || null;
}

export function MultiSelectModal<TItem, TId>({
  title,
  isOpen,
  searchText,
  data,
  loading,
  hasSelectAll = false,
  onSubmit,
  onClose,
  onSearch,
  onClearSearch,
  getDatumId,
  renderCard,
}: MultiSelectModalProps<TItem, TId>) {
  // If we do not memoize initialValues, any re-render causes the form to reset values
  const initialValues = useMemo<MultiSelectModalFormValues<TItem>>(
    () => ({ selectedItems: [] }),
    []
  );

  return (
    <ModalForm
      show={isOpen}
      onHide={onClose}
      title={title}
      formProps={{
        initialValues,
        onSubmit,
      }}
    >
      {() => (
        <Box m='l' mb={0}>
          {/* This is necessary to "register" the field otherwise form.change will not alter the state of the form (pristine, dirty, etc.) */}
          {/* https://github.com/final-form/final-form/issues/169#issuecomment-430939734 */}
          <Field name='selectedItems'>{() => null}</Field>

          <Spacer direction='vertical' space='s' w='100%'>
            <GridLayout>
              <GridItem xs={hasSelectAll ? 11 : 12}>
                <SearchBar
                  name='multi-select-modal-search'
                  value={searchText}
                  placeholder='Search'
                  onSearch={onSearch}
                  onClear={onClearSearch}
                />
              </GridItem>
              {hasSelectAll && (
                <GridItem xs={1}>
                  <SelectAllButton<TItem, TId> data={data} getDatumId={getDatumId} />
                </GridItem>
              )}
            </GridLayout>

            <Box overflowY='auto' h='50vh' margin='6px -20px 0' padding='10px 20px'>
              {/* ^ Negative margin and padding to avoid hiding the checked icon. */}
              {loading ? (
                <Loading data-test='multi-select-modal-loading' />
              ) : (
                <Grid
                  data-test='multi-select-modal-grid'
                  gridTemplateColumns='repeat(2, minmax(0, 1fr))'
                  columnGap='s'
                  gap='s'
                  mb={100}
                >
                  <Cards<TItem, TId> data={data} getDatumId={getDatumId} renderCard={renderCard} />
                </Grid>
              )}
            </Box>
          </Spacer>
        </Box>
      )}
    </ModalForm>
  );
}
