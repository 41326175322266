import { useMemo } from 'react';

import { SearchTotalQuery } from '@generated/SearchTotalQuery';
import { SEARCH_TOTAL_QUERY } from '@graphql/queries/SearchTotalQuery';
import { useGraphQuery } from '@hooks/useQuery/useGraphQuery';

export interface UseEntitiesCountReturnType {
  totalNumbers: number;
  totalTeams: number;
  totalUsers: number;
  loading: boolean;
}

interface UseEntitiesCountArgs {
  skip?: boolean;
}

/**
 * Hook providing the total number of each entities.
 * @returns an object holding entity counts
 */
export function useEntitiesCount({
  skip = false,
}: UseEntitiesCountArgs = {}): UseEntitiesCountReturnType {
  const { data, loading } = useGraphQuery<SearchTotalQuery>(SEARCH_TOTAL_QUERY, {
    throwError: true,
    skip,
  });

  return useMemo(
    () => ({
      totalNumbers: data?.searchLines?.total || 0,
      totalTeams: data?.searchTeams?.total || 0,
      totalUsers: data?.searchAgents?.total || 0,
      loading,
    }),
    [data, loading]
  );
}
