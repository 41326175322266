import { useCallback, useEffect, useRef } from 'react';

import { checklistData } from './TrialChecklistData';
import { TrialChecklistPopupProps } from './TrialChecklistPopup.decl';
import { ButtonIcon, FullPageButton, UpgradeLinkButton } from './TrialChecklistPopup.style';
import { TrialChecklistPopupItem } from './TrialChecklistPopupItem';
import { matchPath } from './util';

import { CloseOutlined, StarFilled } from '@aircall/icons';
import { Box, Flex, Icon, Typography } from '@aircall/tractor-v2';
import { ProgressCircle } from '@components/TrialWidget/ProgressCircle';
import { RenderProgressContent } from '@components/TrialWidget/RenderProgressContent';
import { ACCOUNT_ROUTE, ACCOUNT_TABS, ONBOARDING_ROUTE } from '@constants/routes.constants';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

const BACKGROUND_GRADIENT = 'linear-gradient(119.12deg, #195A75 2.16%, #052E3F 101.53%)';

export function TrialChecklistPopup({
  isPopupOpen,
  onClose,
  completedTask,
  totalTask,
  onboardingData,
}: Readonly<TrialChecklistPopupProps>) {
  const widgetPopupRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const isOnboardingPage = matchPath(location.pathname, ['/onboarding']);

  const handleOnboardingButtonClick = () => {
    navigate(ONBOARDING_ROUTE);
    onClose();
  };

  const handleOnOutsideClick = useCallback(
    (event: MouseEvent): void => {
      if (!isPopupOpen) {
        return;
      }
      if (
        event.target &&
        widgetPopupRef.current &&
        !widgetPopupRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    },
    [isPopupOpen, onClose]
  );

  useEffect(() => {
    window.addEventListener('click', handleOnOutsideClick, true);
    return () => {
      window.removeEventListener('click', handleOnOutsideClick, true);
    };
  }, [handleOnOutsideClick]);

  if (!isPopupOpen) {
    return null;
  }

  return (
    <Box
      background={BACKGROUND_GRADIENT}
      data-test='trial-checklist-popup'
      w={344}
      borderRadius={8}
      padding='16px 12px'
      spaceY='s'
      position='fixed'
      bottom={60}
      left={270}
      zIndex={9999}
      ref={widgetPopupRef}
    >
      <Flex data-test='trial-checklist-popup-header' justifyContent='space-between'>
        <FullPageButton
          size='xSmall'
          variant='primary'
          mode='fill'
          onClick={handleOnboardingButtonClick}
          data-test='trial-checklist-home-button'
          $visible={isOnboardingPage}
        >
          <Typography variant='bodySemiboldM'>
            {t('user_profile.task_checklist.trial_home')}
          </Typography>
        </FullPageButton>
        <Flex>
          <ProgressCircle
            completedTask={completedTask}
            totalTask={totalTask}
            stroke='icon-primary'
            strokeWidth={5}
            circleRadius={15}
          >
            <RenderProgressContent
              isComplete={false}
              completedTask={completedTask}
              totalTask={totalTask}
            />
          </ProgressCircle>
          <ButtonIcon
            data-test='trial-checklist-close-button'
            onClick={() => {
              onClose();
            }}
            component={CloseOutlined}
            color='white'
            marginLeft={12}
          />
        </Flex>
      </Flex>
      <Flex flexDirection='column' position='relative' data-test='trial-checklist-items'>
        {checklistData.map((item, index) => (
          <TrialChecklistPopupItem key={item.stepType} {...item} index={index} />
        ))}
      </Flex>
      <UpgradeLinkButton
        data-test='trial-checklist-plan-link'
        variant='warning'
        block
        as='a'
        href={onboardingData?.ctaButtonUrl ?? `${ACCOUNT_ROUTE}/${ACCOUNT_TABS.YOUR_PLAN}`}
      >
        <Icon component={StarFilled} color='warning-500' />
        <Typography color='warning-500' variant='bodySemiboldM'>
          {onboardingData?.ctaButtonTitle ?? t('user_profile.task_checklist.upgrade')}
        </Typography>
      </UpgradeLinkButton>
    </Box>
  );
}
