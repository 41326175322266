import { cloneElement, ReactElement, useCallback, useRef, useState } from 'react';

import { TooltipOnTruncateProps } from './TooltipOnTruncate.decl';
import { isElementTruncated } from './utils';

import { Box, Tooltip } from '@aircall/tractor-v2';

export function TooltipOnTruncate({
  children,
  side = 'bottom',
  align = 'center',
  ...restProps
}: TooltipOnTruncateProps) {
  const [visible, setVisible] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  const handleMouseEnter = useCallback(() => {
    const isVisible = !!(textRef.current && isElementTruncated(textRef.current));
    setVisible(isVisible);
  }, [setVisible]);

  const handleMouseLeave = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-test='truncated-tooltip'
    >
      {visible ? (
        <Tooltip side={side} align={align} visible {...restProps}>
          {cloneElement(children as ReactElement, { ref: textRef })}
        </Tooltip>
      ) : (
        <>{cloneElement(children as ReactElement, { ref: textRef })}</>
      )}
    </Box>
  );
}
