/* eslint-disable @typescript-eslint/naming-convention */

import { FILTER_KEY_MAPPER } from '../looker/Report.constants';

import { RANGES } from './Filters/DateFilter/DateFilter.helpers';
import { DateRange, FILTER_DIRECTION, LOOKER_DATE_PERIODS, LOOKER_FILTER_KEY } from './Looker.decl';

import { URLSearchObject } from '@components/SearchProvider/usePersistSearchParams';
import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
} from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import invert from 'lodash-es/invert';

export const getLookerDateStr = (date: Date): string => format(date, 'yyyy/MM/dd');

export function getDateRangeForDate(date: Date, range: LOOKER_DATE_PERIODS): DateRange {
  switch (range) {
    case LOOKER_DATE_PERIODS.YESTERDAY: {
      return {
        startDate: subDays(startOfDay(date), 1),
        endDate: subDays(endOfDay(date), 1),
      };
    }
    case LOOKER_DATE_PERIODS.THIS_WEEK: {
      return {
        startDate: startOfWeek(date, { weekStartsOn: 1 }),
        endDate: endOfDay(date),
      };
    }
    case LOOKER_DATE_PERIODS.PREVIOUS_WEEK: {
      return {
        startDate: startOfWeek(subDays(date, 7), { weekStartsOn: 1 }),
        endDate: endOfWeek(subDays(date, 7), { weekStartsOn: 1 }),
      };
    }
    case LOOKER_DATE_PERIODS.THIS_MONTH: {
      return {
        startDate: startOfMonth(date),
        endDate: endOfDay(date),
      };
    }
    case LOOKER_DATE_PERIODS.PREVIOUS_MONTH: {
      return {
        startDate: startOfMonth(subMonths(date, 1)),
        endDate: endOfMonth(subMonths(date, 1)),
      };
    }
    case LOOKER_DATE_PERIODS.THIS_YEAR: {
      return {
        startDate: startOfYear(date),
        endDate: endOfDay(date),
      };
    }
    case LOOKER_DATE_PERIODS.TODAY:
    default: {
      return {
        startDate: startOfDay(date),
        endDate: endOfDay(date),
      };
    }
  }
}

/** Looker's date picker format exclude the endDate from the selected time frame while we want to include it */
export function formatDatesString(
  date: string,
  timezone: string,
  direction: FILTER_DIRECTION
): string {
  if (!date.includes(' to ')) {
    if (direction === 'toLooker') {
      const range = RANGES.find((r) => r.rangeValue === date)!;

      const { startDate, endDate } = getDateRangeForDate(
        utcToZonedTime(
          formatInTimeZone(new Date(), 'Etc/UTC', 'yyyy-MM-dd HH:mm:ss zzz'),
          timezone
        ),
        range.rangeValue
      );

      return `${getLookerDateStr(startDate)} to ${getLookerDateStr(addDays(endDate, 1))}`;
    }

    return date;
  }

  const newDates = date.split(' to ');
  const endDate =
    direction === 'toLooker'
      ? addDays(new Date(newDates[1]), 1)
      : subDays(new Date(newDates[1]), 1);

  return `${getLookerDateStr(new Date(newDates[0]))} to ${getLookerDateStr(endDate)}`;
}

/**
 * Timezones containing _ such as "America/New_York" will be sent from Looker with a ^ preceding the _.
 * To have a correct matching with Aircall's timezones we need to remove the occurrences of ^
 */
export function formatTimezoneString(timezone: string, direction: FILTER_DIRECTION): string {
  return direction === 'toLooker' ? timezone.replace(/_/g, '^_') : timezone.replace(/\^/g, '');
}

function formatFilterValue(
  filters: URLSearchObject,
  key: string,
  direction: FILTER_DIRECTION
): string {
  const parsedKey = key.toLowerCase();

  switch (parsedKey) {
    case 'date': {
      return formatDatesString(filters[key], filters.timezone || 'Etc/UTC', direction);
    }
    case 'timezone': {
      return formatTimezoneString(filters[key], direction);
    }
    default: {
      return filters[key];
    }
  }
}

export function formatFilters(
  filters: URLSearchObject,
  direction: FILTER_DIRECTION
): URLSearchObject {
  const mapper = direction === 'toDashboard' ? FILTER_KEY_MAPPER : invert(FILTER_KEY_MAPPER);

  return Object.keys(filters).reduce(
    (acc, key) => ({
      ...acc,
      ...(mapper[key] && {
        [mapper[key]]: formatFilterValue(filters, key, direction),
      }),
    }),
    {} as URLSearchObject
  );
}

export function safeMergeWithCustomFilters(
  customFilters: URLSearchObject,
  filters: URLSearchObject
): URLSearchObject {
  const keysToIgnore = [
    FILTER_KEY_MAPPER[LOOKER_FILTER_KEY.DATE],
    FILTER_KEY_MAPPER[LOOKER_FILTER_KEY.TIMEZONE],
  ];

  const parsedFilters = Object.keys(filters)
    .filter((key) => !keysToIgnore.includes(key))
    .reduce((acc: URLSearchObject, curr) => {
      acc[curr] = filters[curr];
      return acc;
    }, {});

  return { ...customFilters, ...parsedFilters };
}
