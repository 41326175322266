import { ReactComponent as CircleSVG } from '../../../assets/icons/circleSVG.svg';
import { ProgressCircleProps } from '../TrialWidget.decl';

import { InnerCircleWrapper, ProgressCircleWrapper } from './ProgressCircle.style';
import { computeCircleProgress } from './utils';

import { Flex } from '@aircall/tractor-v2';

export function ProgressCircle({
  children,
  completedTask,
  totalTask,
  circleRadius,
  stroke,
  strokeWidth,
}: ProgressCircleProps) {
  // calculate how the progress bar moves
  const { circumference, offset } = computeCircleProgress({
    completedTask,
    totalTask,
    circleRadius,
  });

  return (
    <ProgressCircleWrapper
      data-test='progress-circle'
      $radius={circleRadius}
      $stroke={stroke}
      $strokeWidth={strokeWidth}
      $strokeDashArray={circumference}
      $strokeDashOffSet={offset}
      position='relative'
      w={35}
      minWidth={35}
      h={35}
    >
      <Flex
        display='flex'
        w='100%'
        h='100%'
        alignItems='center'
        justifyContent='center'
        borderRadius='50%'
      >
        <InnerCircleWrapper
          display='flex'
          w='100%'
          h='100%'
          alignItems='center'
          justifyContent='center'
          borderRadius='50%'
        >
          {children}
        </InnerCircleWrapper>
      </Flex>
      <CircleSVG data-test='progress-circle-icon' />
    </ProgressCircleWrapper>
  );
}
