import { AnalyticsDashboardType } from '@generated/globalTypes';
import { AnalyticsPlusAddonPage } from '@pages/analytics-plus-addon';
import { useTranslation } from 'react-i18next';

export function StatsOutboundActivityPage() {
  const { t } = useTranslation();

  return (
    <AnalyticsPlusAddonPage
      feedbackButtonEvent='outbound_activity_feedback_button_clicked'
      pageHeaderTitle={t('stats.tabs.outbound_activity')}
      title='outbound_activity'
      type={AnalyticsDashboardType.OUTBOUND_ACTIVITY}
    />
  );
}
