import { gql } from '@apollo/client';

export const NUMBER_INTEGRATIONS_QUERY = gql`
  query NumberIntegrationsQuery($numberId: Int!) {
    numberIntegrations(numberId: $numberId)
      @rest(
        type: "CompanyIntegrations"
        path: "numbers/{args.numberId}/integrations"
        endpoint: "v4"
      ) {
      data {
        id
        type
        attributes {
          active
          customName
          logo
          name
          nameUnderscored
          numbersConnected
          service
        }
      }
    }
  }
`;
