import { useCallback, useMemo } from 'react';

import { CustomDate, UseCustomDateStateReturn } from './useCustomDateState.decl';

import { getLookerDateStr } from '@pages/looker-v2/Looker.helpers';
import { useLookerPage } from '@pages/looker-v2/useLookerPage';
import { useField } from 'react-final-form';

export const useCustomDateState = (): UseCustomDateStateReturn => {
  const { input: startDateInput } = useField('startDate');
  const { input: endDateInput } = useField('endDate');
  const { input: rangeValueInput } = useField('rangeValue');
  const { handleCustomFilterChange } = useLookerPage();

  const setCustomDate = useCallback(
    ({ rangeValue, from, to }: CustomDate) => {
      if (rangeValue) {
        handleCustomFilterChange({ date: rangeValue });
      } else if (from && to) {
        handleCustomFilterChange({ date: `${getLookerDateStr(from)} to ${getLookerDateStr(to)}` });
      }

      rangeValueInput.onChange(rangeValue);
      startDateInput.onChange(from);
      endDateInput.onChange(to);
    },
    [rangeValueInput, startDateInput, endDateInput, handleCustomFilterChange]
  );

  const customDate = useMemo(() => {
    const rangeValue = rangeValueInput.value;
    const from = startDateInput.value;
    const to = endDateInput.value;

    return { rangeValue, from, to };
  }, [rangeValueInput, startDateInput, endDateInput]);

  return { customDate, setCustomDate };
};
