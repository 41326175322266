import { useCallback } from 'react';

import { DocumentNode, MutationFunctionOptions } from '@apollo/client';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { getExternalErrorTranslationStrings } from '@helpers/errors.helpers';
import { UseGraphMutationReturn, useGraphMutationWithFeedback } from '@hooks/useMutation';
import { CustomMutateError } from '@hooks/useMutation/useGraphMutationWithFeedback.decl';
import noop from 'lodash-es/noop';
import { useTranslation } from 'react-i18next';

export interface UseDeleteEntity {
  __typename: string;
  mutation: DocumentNode;
  mutationName?: string;
  idKey?: string;
  onCompleted?: () => void;
  options?: MutationFunctionOptions;
}

export type UseDeleteEntityReturn<TMutation, TVariables> = [
  (
    id: string | number,
    variables: TVariables,
    /* Name used in snackbar success message */
    name?: string,
    voidResponse?: boolean
  ) => Promise<CustomMutateError | undefined>,
  UseGraphMutationReturn<TMutation, TVariables>[1] & {
    errorMessage: string | undefined;
  }
];

/**
 * Hook to delete any given entity.
 * @param useDeleteEntity - destructured parameters
 * @returns a tuple holding the delete helper function and the result of the mutation
 */
export function useDeleteEntity<TMutation, TMutationVariables>({
  mutation,
  mutationName,
  __typename,
  idKey = 'id',
  onCompleted,
  options = {},
}: UseDeleteEntity): UseDeleteEntityReturn<TMutation, TMutationVariables> {
  const mutationNameKey = mutationName || `delete${__typename}`;

  const { t } = useTranslation();
  const [mutate, mutationResult] = useGraphMutationWithFeedback<TMutation, TMutationVariables>(
    mutation,
    {
      type: 'snackbar',
    },
    {
      onCompleted: onCompleted || noop,
    }
  );

  const deleteEntity = useCallback(
    async (
      id: string | number,
      variables: TMutationVariables,
      name?: string,
      voidResponse = false
    ) => {
      const { error } = await mutate(
        {
          ...options,
          variables,
          update: (cache) => {
            cache.modify({
              id: cache.identify({ __typename, [idKey]: id }),
              fields: (_, { DELETE }) => DELETE,
            });
          },
          optimisticResponse: {
            [mutationNameKey]: voidResponse
              ? {
                  _: null,
                  __typename: 'Void',
                }
              : {
                  id,
                  __typename,
                },
          } as unknown as TMutation | ((vars: TMutationVariables) => TMutation) | undefined,
        },
        {
          successMessage: t(SUCCESS_FEEDBACK_MESSAGES.DELETION_SUCCESS, { name }),
        }
      );
      return error;
    },
    [mutate, __typename, t, idKey, mutationNameKey, options]
  );

  return [
    deleteEntity,
    {
      ...mutationResult,
      errorMessage: mutationResult.error
        ? getExternalErrorTranslationStrings(mutationResult.error)
        : undefined,
    },
  ];
}
