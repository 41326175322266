import { useCallback } from 'react';

import { UseAddTagReturnType } from './useAddTag.decl';

import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { CreateTagMutation, CreateTagMutationVariables } from '@generated/CreateTagMutation';
import { TagsListQuery, TagsListQuery_getCompany_tags } from '@generated/TagsListQuery';
import { CREATE_TAG_MUTATION } from '@graphql/mutations/CreateTagMutation';
import { TAGS_LIST_QUERY } from '@graphql/queries/TagsListQuery';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useTracker } from '@hooks/useTracker';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to add new number to the blocked number company list.
 * @returns a mutation to add a new tag.
 */
export function useAddTag(): UseAddTagReturnType {
  const { t } = useTranslation();
  const { track } = useTracker();

  const [mutate, { loading }] = useGraphMutationWithFeedback<
    CreateTagMutation,
    CreateTagMutationVariables
  >(CREATE_TAG_MUTATION, { type: 'snackbar' });

  /**
   * Function add a call tag.
   * @param name - new tag name.
   * @param color - new tag color.
   */
  const addTag = useCallback(
    async (name: string, color: string) => {
      const { error } = await mutate(
        {
          variables: {
            input: {
              color,
              name,
            },
          },
          update: (cache, { data }) => {
            const queryData = cache.readQuery<TagsListQuery>({ query: TAGS_LIST_QUERY })!;
            const newTag = {
              ...data!.createTag,
              __typename: 'Tag',
              id: data!.createTag.id.toString(),
              name,
              color,
              description: data!.createTag.description,
            };
            const newTags = [...(queryData.getCompany.tags ?? []), newTag];

            cache.writeQuery<TagsListQuery>({
              query: TAGS_LIST_QUERY,
              data: {
                getCompany: {
                  __typename: 'Company',
                  ID: queryData.getCompany.ID,
                  tags: newTags as TagsListQuery_getCompany_tags[],
                },
              },
            });
          },
        },
        { successMessage: t(SUCCESS_FEEDBACK_MESSAGES.ADD_SUCCESS, { name }) }
      );

      if (!error) {
        track({ event: 'tag_created' });
      }

      return error;
    },
    [mutate, t, track]
  );

  return { addTag, loading };
}
