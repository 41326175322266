/* eslint-disable @typescript-eslint/no-explicit-any */

import { ApolloLink, FetchResult, NextLink, Observable, Operation } from '@apollo/client';
import { logger } from '@config/logger.config';
import { LOGGING_STATUS } from '@constants/logging.constants';
import { OperationDefinitionNode } from 'graphql';

export type LoggingCallback = (
  operation: Operation,
  forward: NextLink,
  status?: LOGGING_STATUS,
  data?: any
) => void;

export const Handler = (callback: LoggingCallback): ApolloLink =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        const sub = forward(operation).subscribe({
          next: (result) => {
            callback(operation, forward, LOGGING_STATUS.SUCCESS, result);
            observer.next(result);
          },
          error: (networkError) => {
            callback(operation, forward, LOGGING_STATUS.FAILED, networkError);
            observer.error(networkError);
          },
          complete: () => {
            observer.complete();
          },
        });

        return () => {
          /* istanbul ignore else  */
          if (sub) {
            sub.unsubscribe();
          }
        };
      })
  );

export class LoggingLink extends ApolloLink {
  private link: ApolloLink;

  constructor(callback: LoggingCallback) {
    super();
    this.link = Handler(callback);
  }

  public request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    return this.link.request(operation, forward);
  }
}

const createApolloLog = (operation: Operation, status: LOGGING_STATUS, data?: any): void => {
  const { operationName, query, variables } = operation;
  const operationType = (query.definitions[0] as OperationDefinitionNode).operation;
  const message = `${operationType} | ${status} | ${operationName} `;
  if (status === 'FAILED') {
    logger.error(message, { operationName, variables, error: data });
  } else {
    const context = operation.getContext();
    /* istanbul ignore next */
    if (context.exposeLoggingData) {
      logger.info(message, { operationName, variables, payload: data });
    } /* istanbul ignore next */ else {
      logger.info(message, { operationName, variables });
    }
  }
};

export const loggingLink = new LoggingLink((operation, forward, status, error) => {
  /* istanbul ignore next */
  if (status) {
    createApolloLog(operation, status, error);
  }
  return forward(operation);
});
