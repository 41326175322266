import { BlacklistedNumbersQuery_blacklistedNumbers as BlacklistedNumbers } from '@generated/BlacklistedNumbersQuery';

export const blacklistedNumbersTypePatcher =
  () =>
  (blacklistedNumbers: BlacklistedNumbers): BlacklistedNumbers => ({
    ...blacklistedNumbers,
    blacklistedPhoneNumbers: blacklistedNumbers.blacklistedPhoneNumbers.map((number) => ({
      ...number,
      __typename: 'BlacklistedNumber',
    })),
  });
