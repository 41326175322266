import { useMemo } from 'react';

import { UseToastResult } from './useToast.decl';

import { useToast as useToastTractor, ToastItem } from '@aircall/tractor-v2';

const TOAST_DEFAULT_DISMISS_IN_MILLISECONDS = 4000;

export function useToast(): UseToastResult {
  const toast = useToastTractor();

  return useMemo(
    () => ({
      ...toast,
      showToast: (options: Partial<ToastItem>) => {
        toast.showToast({
          ...options,
          message: options.message,
          dismissIn: options.dismissIn ?? TOAST_DEFAULT_DISMISS_IN_MILLISECONDS,
          variant: options.variant ?? 'success',
          icon: options.icon ?? true,
        });
        return undefined;
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
