type SidebarItemProps = {
  to?: string;
  children?: JSX.Element;
  props?: SidebarItemProps;
};

function getRoutePropertyFromComponent(sidebarItem: SidebarItemProps): string | undefined {
  if (sidebarItem?.to) {
    return sidebarItem.to;
  }

  const deeperLevel = sidebarItem.children || sidebarItem.props;
  return deeperLevel && getRoutePropertyFromComponent(deeperLevel);
}

export const findIndexByRoute = (items: SidebarItemProps[], routeToSearch: string): number =>
  items.findIndex((item) => {
    const route = getRoutePropertyFromComponent(item);

    return route === routeToSearch;
  });

type Item = {
  position?: number;
};
type ItemWithPos = Item & Required<Pick<Item, 'position'>>;
type ItemWithoutPos = Omit<Item, 'position'>;

export function sortByPosition<T extends Item>(items: T[]): T[] {
  const totalLength = items.length;
  const merged = new Array(totalLength);

  const seenPos = new Set<number>();
  const itemsWithPos = (items.filter((item) => item.position !== undefined) as ItemWithPos[])
    .sort((a, b) => a.position - b.position)
    // Fix duplicate positions
    .map((item) => {
      let newPosition = item.position;
      if (seenPos.has(item.position)) {
        // If position already exists shift position by 1
        // eslint-disable-next-line no-param-reassign
        newPosition += 1;
      }
      seenPos.add(newPosition);
      return { ...item, position: newPosition };
    });

  const itemsWithoutPos = items.filter((item) => !item.position) as ItemWithoutPos[];

  // Place items with positions
  itemsWithPos.forEach((item) => {
    const i = item.position - 1;
    merged[i] = item;
  });

  // Place items without positions in the gaps and at the end
  let j = 0;
  for (let i = 0; i < totalLength; i += 1) {
    if (merged[i] === undefined) {
      merged[i] = itemsWithoutPos[j];
      j += 1;
    }
  }

  // We remove the undefined items in case items were pushed at the end
  return merged.filter((item) => item !== undefined);
}
