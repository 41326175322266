import { useCallback } from 'react';

import { TRANSLATION_PREFIX } from './AudioSettingFile.constants';
import { AudioSettingFileProps } from './AudioSettingFile.decl';

import { Spacer, Typography } from '@aircall/tractor-v2';
import { Dropzone } from '@components/DropzoneV2';
import { ExternalLink } from '@components/Link';
import { MAX_AUDIO_SIZE } from '@constants/files.constants';
import { RECORD_AUDIO_FILE_KNOWLEDGE_BASE_URL } from '@constants/urls.constants';
import { useTracker } from '@hooks/useTracker/useTracker';
import capitalize from 'lodash-es/capitalize';
import { useTranslation } from 'react-i18next';

export function AudioSettingFileDropzone({ onChange }: Pick<AudioSettingFileProps, 'onChange'>) {
  const { t } = useTranslation();
  const { track } = useTracker();

  const handleDrop = useCallback(
    ([acceptedFile]) => {
      track({ event: 'recording_upload_started' });
      onChange(acceptedFile);
    },
    [onChange, track]
  );
  const handleError = useCallback(() => track({ event: 'recording_upload_error' }), [track]);

  return (
    <Spacer direction='vertical' space='s' fluid>
      <Dropzone
        dropzoneOptions={{
          onDrop: handleDrop,
          onDropRejected: handleError,
          maxFiles: 1,
          accept: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'audio/mpeg': ['.mp3'],
          },
          maxSize: MAX_AUDIO_SIZE,
        }}
        errorText={capitalize(t(`${TRANSLATION_PREFIX}.dropzone.secondary_text`))}
        data-test='audio-setting-dropzone'
        variant='secondary'
      >
        <Typography variant='bodySemiboldM' color='text-secondary' mb='s'>
          {t(`${TRANSLATION_PREFIX}.dropzone.primary_text`)}
        </Typography>
        <Typography variant='bodyMediumS' color='text-secondary'>
          {t(`${TRANSLATION_PREFIX}.dropzone.secondary_text`)}
        </Typography>
      </Dropzone>
      <ExternalLink href={RECORD_AUDIO_FILE_KNOWLEDGE_BASE_URL} target='_blank'>
        {t(`${TRANSLATION_PREFIX}.knowledge_base_link`)}
      </ExternalLink>
    </Spacer>
  );
}
