/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { postIframeMessage } from './postIframeMessage';
import { LOOKER_PAGES_FILTERS } from './Report.constants';
import { LOOKER_STATUS, ReportPageContextProps, ReportPageProviderProps } from './Report.decl';
import {
  checkDataToLoad,
  checkTileEventError,
  formatFilters,
  getLookerStatusError,
} from './Report.helpers';

import {
  URLSearchObject,
  usePersistSearchParams,
} from '@components/SearchProvider/usePersistSearchParams';
import { formatSearchParamsToObject } from '@components/SearchProvider/utils';
import { isDev } from '@helpers/env.helpers';
import { useTracker } from '@hooks/useTracker/useTracker';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

export const ReportPageContext = createContext<ReportPageContextProps | undefined>(undefined);

export function ReportPageProvider({ onTruncated, ...props }: ReportPageProviderProps) {
  const { searchParams, setSearchParams } = usePersistSearchParams();
  const { pathname } = useLocation();
  const { track } = useTracker();
  const [height, setHeight] = useState('100%');
  const [url, setUrl] = useState<string | undefined>();
  const [lookerStatus, setLookerStatus] = useState(LOOKER_STATUS.STORED_DATA);
  const [lookerFilters, setLookerFilters] = useLocalStorage<Record<string, URLSearchObject>>(
    LOOKER_PAGES_FILTERS,
    {}
  );
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  // Ref used to track the number of time looker's filter were changed.
  const lookerReloadCounter = useRef(0);
  // Ref used to track if filter were changed before a reload actions.
  const lookerFiltersUpdated = useRef(false);
  const handleTracking = (filterConfig: Record<string, string>, isUpdated: boolean) => {
    const formatedFilters = formatFilters(filterConfig, 'toDashboard');

    track({
      event: 'looker_analytics_reloaded',
      payload: {
        filters_config: formatedFilters,
        is_updated: isUpdated,
      },
    });
  };

  const dataError = useMemo(() => lookerStatus === LOOKER_STATUS.DATA_ERROR, [lookerStatus]);

  // update looker filter based on a URLSearchObject and run the filter change
  const updateLookerFilter = useCallback(
    (filters: URLSearchObject) => {
      const formatedFilters = formatFilters(filters, 'toLooker');

      if (url) {
        postIframeMessage(
          iFrameRef.current!.contentWindow!,
          JSON.stringify({
            type: 'dashboard:filters:update',
            filters: formatedFilters,
          }),
          url
        );
        // forces Looker to refresh: https://developers.looker.com/embed/advanced-embedding/making-changes-to-the-iframe
        postIframeMessage(
          iFrameRef.current!.contentWindow!,
          JSON.stringify({ type: 'dashboard:run' }),
          url
        );
      }
    },
    [url]
  );

  // listen to looker event
  useEffect(() => {
    const onNewMsg = (event: { data: string }) => {
      try {
        const evtInfo = JSON.parse(event.data);
        const dataToUse = checkDataToLoad(
          searchParams.toString(),
          lookerFilters[pathname],
          lookerStatus
        );

        // eslint-disable-next-line default-case
        switch (evtInfo.type) {
          // On mount, load the existing filter. Only time where we read localStorage
          case 'dashboard:loaded':
            // read localstorage if no searchparams is found store one or set params
            if (dataToUse === 'searched-data') {
              updateLookerFilter(formatSearchParamsToObject(searchParams));
            } else if (dataToUse === 'stored-data') {
              updateLookerFilter(lookerFilters[pathname]);
            } else {
              const formatedOnLoad = formatFilters(
                evtInfo.dashboard.dashboard_filters,
                'toDashboard'
              );
              setSearchParams(formatedOnLoad);
              setLookerFilters((prev) => ({
                ...prev,
                [pathname]: formatedOnLoad,
              }));
              // when the iframe load without data set looker status to fresh start
              setLookerStatus(LOOKER_STATUS.FRESH_DATA);
            }
            break;

          // On tile complete, used to render a banner for call_history page and track if an error happened
          case 'dashboard:tile:complete':
            if (checkTileEventError(evtInfo.status, lookerStatus)) {
              // the error value is determined by the data value. stored data -> data error / fresh data -> other error
              setLookerStatus(getLookerStatusError);
            }
            onTruncated?.(evtInfo.truncated);
            break;

          // On change, get the filter from looker
          case 'dashboard:filters:changed': {
            const formatedOnChange = formatFilters(
              evtInfo.dashboard.dashboard_filters,
              'toDashboard'
            );
            setSearchParams(formatedOnChange);
            setLookerFilters((prev) => ({
              ...prev,
              [pathname]: formatedOnChange,
            }));
            lookerFiltersUpdated.current = true;
            break;
          }

          /**
           * On looker update :
           * increment the filter update counter by one.
           * - if no data are to be load (lookerStatus === FRESH_DATA) then we can start to track update at counter === 2
           * - if data are to be load (lookerStatus === STORED_DATA) then we can start to track update at counter === 3
           */
          case 'dashboard:run:start':
            lookerReloadCounter.current += 1;
            if (
              (lookerStatus === LOOKER_STATUS.FRESH_DATA && lookerReloadCounter.current > 1) ||
              lookerReloadCounter.current > 2
            ) {
              handleTracking(evtInfo.dashboard.dashboard_filters, lookerFiltersUpdated.current);
            }
            lookerFiltersUpdated.current = false;
            break;

          // On change, adapt embed height after looker dashboard loaded
          case 'page:properties:changed':
            setHeight(evtInfo.height + 2);
        }
      } catch (err) {
        // Ignore for testing and coverage (only dev)
        /* istanbul ignore if  */
        if (isDev()) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }
    };

    window.addEventListener('message', onNewMsg);
    return () => window.removeEventListener('message', onNewMsg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLookerFilter, lookerStatus]);

  const memoizedContextValue = useMemo(
    () => ({ height, iFrameRef, updateLookerFilter, url, setUrl, error: dataError }),
    [height, updateLookerFilter, url, dataError]
  );

  return <ReportPageContext.Provider value={memoizedContextValue} {...props} />;
}
