import { LineCapabilitiesTagProps } from './LineCapabilitiesTag.decl';

import { Tag } from '@aircall/tractor-v2';
import { LINE_CAPABILITY } from '@constants/numbers.constants';

export function LineCapabilitiesTag({ capabilities }: LineCapabilitiesTagProps) {
  const hasSMS = capabilities?.includes(LINE_CAPABILITY.SMS);
  const hasMMS = capabilities?.includes(LINE_CAPABILITY.MMS);

  if (!hasSMS && !hasMMS) {
    return null;
  }

  return (
    <Tag data-test='line-capabilities-tag' size='small' variant={hasMMS ? 'blue' : 'green'}>
      {hasMMS ? 'SMS / MMS' : 'SMS'}
    </Tag>
  );
}
