import { useCallback } from 'react';

import {
  AddNumberIntegration,
  AddNumberIntegrationVariables,
} from '@generated/AddNumberIntegration';
import { ADD_NUMBER_INTEGRATIONS } from '@graphql/mutations/AddNewNumberIntegrationsMutation';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { CustomMutateError } from '@hooks/useMutation/useGraphMutationWithFeedback.decl';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

export type FormValues = {
  bridgeIntegrationsId: string[];
  hubIntegrationsId: string[];
};
interface UseAddNumberIntegrationsReturnType {
  submitNewIntegrations: (values: FormValues) => Promise<CustomMutateError>;
}

interface UseAddNumberIntegrations {
  onCompleted: () => void;
}

/**
 * Hook to add integration to a number
 * @param useAddNumberIntegrations Object holding a onCompleted callback
 * @returns Object holding a function to add a new integration
 */
export const useAddNumberIntegrations = ({
  onCompleted,
}: UseAddNumberIntegrations): UseAddNumberIntegrationsReturnType => {
  const { numberId } = useParams();
  const { currentCompany } = useGlobalData();
  const { t } = useTranslation();
  const [mutate] = useGraphMutationWithFeedback<
    AddNumberIntegration,
    AddNumberIntegrationVariables
  >(ADD_NUMBER_INTEGRATIONS, { type: 'form', validationErrorAsFormError: true }, { onCompleted });

  const submitNewIntegrations = useCallback(
    async ({ hubIntegrationsId, bridgeIntegrationsId }: FormValues) => {
      const { error } = await mutate(
        {
          variables: {
            companyId: currentCompany.id,
            numberId: parseInt(numberId),
            bridgeIntegrationsId: { integrationIds: bridgeIntegrationsId },
            hubIntegrationsId: { integrationIds: hubIntegrationsId },
            skipBridgeIntegrations: bridgeIntegrationsId.length === 0,
            skipHubIntegrations: hubIntegrationsId.length === 0,
          },
          update: (cache) => {
            cache.modify({
              fields: {
                numberIntegrations(numberIntegrationRefs) {
                  const integrations = [...hubIntegrationsId, ...bridgeIntegrationsId].reduce(
                    (acc, integrationId) => [
                      ...acc,
                      { __ref: `CompanyIntegration:${integrationId}` },
                    ],
                    [...numberIntegrationRefs.data]
                  );
                  return { ...numberIntegrationRefs, data: integrations };
                },
              },
            });
          },
        },
        {
          successMessage: t(
            'number_details.integrations.connected_integrations.add_integration_success_message'
          ),
        }
      );
      return error;
    },
    [currentCompany.id, mutate, numberId, t]
  );

  return {
    submitNewIntegrations,
  };
};
