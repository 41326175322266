import { useCallback, useRef, useEffect, useMemo } from 'react';

import { SearchHistory, UseSearchHistoryRet } from '../SearchBar.decl';

import noop from 'lodash-es/noop';
import { useLocalStorage } from 'usehooks-ts';

/** If user doesn't change the search in 5 sec, then save it */
const SAVE_DELAY_TIME = 5000;

const MAX_ROWS = 5;

/**
 * The custom hook to set the search history to the local storage,
 * and retrieve it when the component is mounted.
 */
export function useSearchHistory({
  name,
  search,
  maxRows = MAX_ROWS,
  saveDelayTime = SAVE_DELAY_TIME,
}: SearchHistory): UseSearchHistoryRet {
  const timerIdRef = useRef<number>();

  const localStorageField = useMemo(() => `${name}-history`, [name]);

  const [recentSearches, setRencentSearches] = useLocalStorage<string[]>(localStorageField, []);

  /** Adds a search string to the history */
  const addSearchTerm = useCallback(
    (searchStr: string) => {
      let updatedHistory = recentSearches;
      const duplicateIndex = recentSearches.indexOf(searchStr);

      // moves the duplicated one to the beginning of the array
      if (duplicateIndex >= 0) {
        updatedHistory = [
          searchStr,
          ...recentSearches.slice(0, duplicateIndex),
          ...recentSearches.slice(duplicateIndex + 1, recentSearches.length),
        ];
      } else {
        updatedHistory.unshift(searchStr);
      }

      // always keeps the limited number of history
      if (updatedHistory.length > maxRows) {
        updatedHistory.pop();
      }

      setRencentSearches(updatedHistory);
    },
    [maxRows, recentSearches, setRencentSearches]
  );

  /** Adds the search term immediately */
  const addSearch = useCallback(
    (searchStr: string) => {
      timerIdRef.current && clearTimeout(timerIdRef.current);
      addSearchTerm(searchStr);
    },
    [addSearchTerm]
  );

  /** Clears all search history */
  const clearAllSearch = useCallback(() => {
    setRencentSearches([]);
  }, [setRencentSearches]);

  // adds the search term to the set if users haven't typed anything in the timeframe
  useEffect(() => {
    if (!search) {
      return noop;
    }

    timerIdRef.current && clearTimeout(timerIdRef.current);
    timerIdRef.current = window.setTimeout(() => {
      addSearchTerm(search);
    }, saveDelayTime);

    return () => {
      clearTimeout(timerIdRef.current);
    };
  }, [addSearch, addSearchTerm, saveDelayTime, search]);

  return {
    recentSearches,
    addSearch,
    clearAllSearch,
  };
}
