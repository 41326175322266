import { useCallback } from 'react';

import { useGraphLazyQuery } from '@dashboard/library';
import { GetRegionFromE164Query } from '@generated/GetRegionFromE164Query';
import { GetSignupInformation_getSignupInformation } from '@generated/GetSignupInformation';
import { GET_REGION_FROM_E164_QUERY } from '@graphql/queries/GetRegionFromE164Query';

// Region will be retrieved from a query in the future
export const useRegionProvider = (
  signupInfo: GetSignupInformation_getSignupInformation | undefined
): {
  getRegionId: () => Promise<string | null | undefined>;
} => {
  const [getRegionFromE164] = useGraphLazyQuery<GetRegionFromE164Query>(GET_REGION_FROM_E164_QUERY);

  const getRegionId = useCallback(async () => {
    const { data } = await getRegionFromE164({
      variables: {
        e164: signupInfo?.phoneNumber,
      },
    });
    return data?.getRegionFromE164?.regionUUID;
  }, [getRegionFromE164, signupInfo]);

  return { getRegionId };
};
