import { gql } from '@apollo/client';

export const CREATE_NUMBER_LINE_MUTATION = gql`
  mutation CreateLine($input: CreateLineInput!) {
    createLine(input: $input) {
      ID
      newPurchasingFlowEnabled
    }
  }
`;
