import { useCallback } from 'react';

import { BridgeQueryParams } from '../IntegrationFlowCreateBridge';

import {
  APPLICATIONS_NAMES,
  SELECT_ALL_NUMBERS_PARAM_VALUE,
} from '@constants/integrations.constants';
import {
  CreateBridgeIntegrationMutation,
  CreateBridgeIntegrationMutationVariables,
} from '@generated/CreateBridgeIntegrationMutation';
import { CreateBridgeIntegrationMutationInput } from '@generated/globalTypes';
import { CREATE_BRIDGE_INTEGRATION_MUTATION } from '@graphql/mutations/CreateBridgeIntegrationMutation';
import { addBridgeIntegrationToListCache } from '@helpers/cache.helpers';
import { ClientError } from '@helpers/errors.helpers';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import capitalize from 'lodash-es/capitalize';

export type GetBridgeIntegrationInputParams = BridgeQueryParams & {
  applicationName: APPLICATIONS_NAMES;
  currentUserId: string;
};

export type CreateBridgeIntegrationParams = BridgeQueryParams & {
  applicationName: APPLICATIONS_NAMES;
  logo: string | null;
};

export interface UseCreateBridgeIntegrationReturnType {
  createRestBridgeIntegration: (params: CreateBridgeIntegrationParams) => void;
  data?: CreateBridgeIntegrationMutation | null;
  loading: boolean;
  error: ClientError | undefined;
}

/**
 * Get the right input given the bridge application name.
 * @param getBridgeIntegrationInputParams - destructured parameter
 * @returns the right input
 */
export function getBridgeIntegrationInput({
  applicationName,
  currentUserId,
  numberIds,
  accessToken,
  refreshToken,
  url,
  id,
  secret,
  instanceUrl,
  site,
}: GetBridgeIntegrationInputParams): CreateBridgeIntegrationMutationInput | null {
  let numberIdsInput: string | string[] = numberIds || [];

  if (typeof numberIdsInput === 'string' && numberIdsInput !== SELECT_ALL_NUMBERS_PARAM_VALUE) {
    numberIdsInput = numberIdsInput.split(',');
  }

  const input: CreateBridgeIntegrationMutationInput = {
    resourceType: `${capitalize(applicationName)}::Integration`,
    data: {
      authorId: currentUserId,
      authorType: 'Aircall::User',
    },
    numberIds: numberIdsInput as unknown as string,
    credentials: {},
  };

  switch (applicationName) {
    case APPLICATIONS_NAMES.INTERCOM:
      return {
        ...input,
        credentials: { accessToken },
      };
    case APPLICATIONS_NAMES.PIPEDRIVE:
      return {
        ...input,
        credentials: { accessToken, refreshToken },
      };
    case APPLICATIONS_NAMES.ZENDESK:
      return {
        ...input,
        credentials: {
          url,
          id,
          secret,
        },
      };
    case APPLICATIONS_NAMES.SALESFORCE:
      return {
        ...input,
        credentials: { accessToken, refreshToken, instanceUrl, host: site },
      };
    default:
      return null;
  }
}

/**
 * Hook to create a bridge integration.
 * @returns an object holding states and the handler to create a bridge integration
 */
export function useCreateBridgeIntegration(): UseCreateBridgeIntegrationReturnType {
  const { currentUser } = useGlobalData();
  const [mutate, { data, loading, error }] = useGraphMutationWithFeedback<
    CreateBridgeIntegrationMutation,
    CreateBridgeIntegrationMutationVariables
  >(CREATE_BRIDGE_INTEGRATION_MUTATION, { type: 'snackbar' });

  const createRestBridgeIntegration = useCallback(
    ({
      applicationName,
      numberIds,
      accessToken,
      refreshToken,
      logo,
      url,
      id,
      secret,
      instanceUrl,
      site,
    }: CreateBridgeIntegrationParams) => {
      const input = getBridgeIntegrationInput({
        applicationName,
        numberIds,
        currentUserId: currentUser.ID,
        accessToken,
        refreshToken,
        url,
        id,
        secret,
        instanceUrl,
        site,
      });

      if (!input) {
        return;
      }

      mutate(
        {
          variables: { input },
          update: addBridgeIntegrationToListCache<CreateBridgeIntegrationMutation>(logo),
        },
        {
          trackerProperties: {
            event: 'integration_created',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            payload: { application_name: applicationName },
          },
        }
      );
    },
    [mutate, currentUser.ID]
  );

  return { createRestBridgeIntegration, data, loading, error };
}
