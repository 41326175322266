import { CompanyIntegrationsQuery_companyIntegrations as CompanyIntegrations } from '@generated/CompanyIntegrationsQuery';

export const companyIntegrationsTypePatcher =
  () =>
  (companyIntegrations: CompanyIntegrations): CompanyIntegrations => ({
    ...companyIntegrations,
    data: companyIntegrations.data.map((item) => ({
      ...item,
      __typename: 'CompanyIntegration',
      attributes: {
        ...item.attributes,
        __typename: 'RestIntegration',
      },
    })),
  });
