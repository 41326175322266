import { NotFoundError } from '@helpers/errors.helpers';
import { usePermissions } from '@hooks/usePermissions';
import { Navigate } from 'react-router-dom';

export function NavigateToAccessibleRoute() {
  const { getAccessibleRoute } = usePermissions();
  const route = getAccessibleRoute();

  if (!route) {
    throw new NotFoundError('Not found');
  }

  return <Navigate to={route} replace />;
}
