/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  AIRCALL_API_URL,
  AIRCALL_API_VERSIONS,
  AIRCALL_AUTHENTICATION_API_URL,
  AIRCALL_ADVANCED_ANALYTICS,
  AIRCALL_CALLS_ASSET,
  INTEGRATIONS_MANAGER_URL,
} from '../../constants/environment.constants';

import { customFetch } from './customFetch/customFetch';
import companySettingsResponseTransformer from './responseTransformers/companySettingsTransformer';
import deleteNumberTransformer from './responseTransformers/deleteNumberTransformer';
import textToSpeechOptionsTransformer from './responseTransformers/textToSpeechOptionsTransformer';
import updateDispatchGroupTransformer from './responseTransformers/updateDispatchGroupTransformer';
import { detailTransformer, listTransformer } from './responseTransformers/v4NumberTransformer';
import { blacklistedNumbersTypePatcher } from './typePatchers/blacklistedNumbersTypePatcher';
import { companyIntegrationsTypePatcher } from './typePatchers/companyIntegrationsTypePatcher';

import { RestLink } from 'apollo-link-rest';
import camelCase from 'camelcase';
import camelcaseKeys from 'camelcase-keys';
import { snakeCase } from 'snake-case';

interface BodySerializerFnReturn {
  body: string;
  headers: Headers;
}

/**
 * Serialize body to camel case
 * @param data - Body data
 * @param headers - Headers
 * @returns An object with the headers and body serialized to camel case
 */
export function camelCaseBodySerializer(
  data: Record<string, any>,
  headers: Headers
): BodySerializerFnReturn {
  headers.set('content-type', 'application/json');
  const transformedData = camelcaseKeys(data, { deep: true });
  return { body: JSON.stringify(transformedData), headers };
}

export function authBodySerializer(
  data: Record<string, any>,
  headers: Headers
): BodySerializerFnReturn {
  headers.set('Aircall-Platform', 'dashboard-v4');
  return camelCaseBodySerializer(data, headers);
}

/**
 * Uses apollo-link-rest to help us transform
 * the RESTful API as GraphQL API.
 */
export const restLink = new RestLink({
  uri: AIRCALL_API_URL,
  endpoints: {
    permissions: AIRCALL_API_URL + AIRCALL_API_VERSIONS.v1,
    v3: AIRCALL_API_URL + AIRCALL_API_VERSIONS.v3,
    v4: AIRCALL_API_URL + AIRCALL_API_VERSIONS.v4,
    analytics: AIRCALL_API_URL + AIRCALL_ADVANCED_ANALYTICS,
    authentication: AIRCALL_AUTHENTICATION_API_URL,
    assets: AIRCALL_API_URL + AIRCALL_CALLS_ASSET,
    merlin: AIRCALL_API_URL + AIRCALL_API_VERSIONS.merlin,
    integrationsManager: INTEGRATIONS_MANAGER_URL,
    v4NumberDetail: {
      uri: `${AIRCALL_API_URL}v4/numbers/`,
      responseTransformer: async (response) => response.json().then(detailTransformer),
    },
    v4NumbersList: {
      uri: `${AIRCALL_API_URL}v4/numbers`,
      responseTransformer: async (response) => response.json().then(listTransformer),
    },
    v4UpdateDispatchGroup: {
      uri: AIRCALL_API_URL + AIRCALL_API_VERSIONS.v4,
      responseTransformer: updateDispatchGroupTransformer,
    },
    v4DeleteNumber: {
      uri: AIRCALL_API_URL + AIRCALL_API_VERSIONS.v4,
      responseTransformer: deleteNumberTransformer,
    },
    v4TtsOptions: {
      uri: AIRCALL_API_URL + AIRCALL_API_VERSIONS.v4,
      responseTransformer: textToSpeechOptionsTransformer,
    },
    v3companySettings: {
      uri: AIRCALL_API_URL + AIRCALL_API_VERSIONS.v3,
      responseTransformer: companySettingsResponseTransformer,
    },
  },
  customFetch,
  typePatcher: {
    CompanyIntegrations: companyIntegrationsTypePatcher(),
    BlacklistedNumbers: blacklistedNumbersTypePatcher(),
  },
  fieldNameNormalizer: (fieldName) => (fieldName === 'ID' ? fieldName : camelCase(fieldName)),
  fieldNameDenormalizer: (fieldName) => snakeCase(fieldName),
  bodySerializers: {
    auth: authBodySerializer,
    camelCase: camelCaseBodySerializer,
  },
});
