import { AnalyticsDashboardType } from '@generated/globalTypes';
import { AnalyticsPlusAddonPage } from '@pages/analytics-plus-addon';
import { useTranslation } from 'react-i18next';

export function StatsInboundActivityPage() {
  const { t } = useTranslation();

  return (
    <AnalyticsPlusAddonPage
      feedbackButtonEvent='inbound_activity_feedback_button_clicked'
      pageHeaderTitle={t('stats.tabs.inbound_activity')}
      title='inbound_activity'
      type={AnalyticsDashboardType.INBOUND_ACTIVITY}
    />
  );
}
