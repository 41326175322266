import { UseCompanyBillingSummaryReturn } from './useCompanyBillingSummary.decl';

import {
  GetCompanyBillingSummaryQuery,
  GetCompanyBillingSummaryQueryVariables,
} from '@generated/GetCompanyBillingSummaryQuery';
import { GET_COMPANY_BILLING_SUMMARY_QUERY } from '@graphql/queries/GetCompanyBillingSummaryQuery';
import { useGraphQuery } from '@hooks/useQuery';

export function useCompanyBillingSummary(): UseCompanyBillingSummaryReturn {
  const { data, loading, refetch } = useGraphQuery<
    GetCompanyBillingSummaryQuery,
    GetCompanyBillingSummaryQueryVariables
  >(GET_COMPANY_BILLING_SUMMARY_QUERY, {
    variables: { includeCalculatedProperties: false },
  });

  return {
    loading,
    refetch,
    data,
  };
}
