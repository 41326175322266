import { gql } from '@apollo/client';

export const GET_NUMBER_REGISTRATION_STATUS = gql`
  query GetNumberRegistrationStatusQuery($lineID: ID!) {
    getNumberRegistrationStatus(lineID: $lineID) {
      numberRegistration {
        status
        updatedAt
      }
      error {
        message
        type
      }
    }
  }
`;
