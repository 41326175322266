import { NavigateToAccessibleRoute } from './NavigateToAccessibleRoute';
import { HasAccessProps } from './Permissions.decl';

import { PERMISSIONS_ACTIONS } from '@constants/permissions.constants';
import { usePermissions } from '@hooks/usePermissions';

export function HasAccess({
  action = PERMISSIONS_ACTIONS.READ,
  children,
  shouldThrow = false,
  shouldNavigate = false,
  resource,
  oneOf,
}: HasAccessProps) {
  const { checkAccess } = usePermissions();

  const hasAccess = oneOf
    ? oneOf.some((res) => checkAccess(action, res))
    : checkAccess(action, resource);

  if (!hasAccess) {
    if (shouldNavigate) {
      return <NavigateToAccessibleRoute />;
    }
    if (shouldThrow) {
      throw new Error('You do not have access to this page.');
    }
    return null;
  }

  return children;
}
