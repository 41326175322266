export enum LINE_PROVIDER {
  BANDWIDTH = 'bandwidth',
  TWILIO = 'twilio',
  VOXBONE = 'voxbone',
}

export enum LINE_ACTIVATION_STATE {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum LINE_CAPABILITY {
  SMS = 'sms',
  MMS = 'mms',
  VOICE = 'voice',
}

export enum NUMBER_TYPE {
  IVR = 'IVR',
  CLASSIC = 'CLASSIC',
}
