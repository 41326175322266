import { gql } from '@apollo/client';
import { USER_FULL_FRAGMENT } from '@graphql/fragments/UserFragment';

export const UPDATE_AGENT_MUTATION = gql`
  mutation UpdateAgentMutation($input: UpdateAgentInput!) {
    updateAgent(input: $input) {
      ...UserFullFragment
    }
  }
  ${USER_FULL_FRAGMENT}
`;
