import React, { useCallback, useMemo } from 'react';

import { ProofOfIdBannerProps } from '../NumberDetail.decl';

import { getProofOfIDLink } from './ProofOfId.helpers';

import { Banner, BannerButton, BannerHeading, BannerSuffix } from '@aircall/tractor-v2';
import { VERIFICATION_STATES } from '@components/NumberVerificationStateTag/NumberVerificationStateTag';
import { isNumberActive } from '@helpers/numbers.helpers';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const errorText: Record<string, string> = {
  [VERIFICATION_STATES.MISSING_INFO]: 'number_details.banners.proof_of_id.missing_information',
  [VERIFICATION_STATES.REJECTED]: 'number_details.banners.proof_of_id.rejected',
};

export const ProofOfIdBanner = React.memo(
  ({ numberDetail: { activationState, verificationState, numberUuid } }: ProofOfIdBannerProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const active = isNumberActive(activationState);

    const proofOfIDLink = useMemo(() => getProofOfIDLink(numberUuid), [numberUuid]);

    const onSubmit = useCallback(() => {
      proofOfIDLink && navigate(proofOfIDLink);
    }, [navigate, proofOfIDLink]);

    if (active || !verificationState || !errorText[verificationState] || proofOfIDLink === null) {
      return null;
    }

    return (
      <Banner variant='critical' data-test='proof-of-id-banner'>
        <BannerHeading>{t(errorText[verificationState])}</BannerHeading>
        <BannerSuffix>
          <BannerButton onClick={onSubmit} data-test='proof-of-id-button'>
            {t('number_details.banners.proof_of_id.submit')}
          </BannerButton>
        </BannerSuffix>
      </Banner>
    );
  }
);
