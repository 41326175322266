import { useCallback, useState } from 'react';

import { MultiSelectModal } from '../MultiSelectModal/MultiSelectModal';

import { MultiSelectLinesModalProps } from './MultiSelectLinesModal.decl';

import { MultiSelectModalFormValues } from '@components/MultiSelectModal/MultiSelectModal.decl';
import { NumberCard } from '@components/NumberCard/NumberCard';
import { SearchLinesQuery_searchLines_items as Line } from '@generated/SearchLinesQuery';
import { useNumbersList } from '@hooks/useNumbersList';

export function MultiSelectLinesModal({
  title,
  isOpen,
  onSubmit,
  linesBlacklist = [],
  onClose,
}: MultiSelectLinesModalProps) {
  const [searchTerm, setSearchTerm] = useState<string>('');

  // For the "Select all" feature to work, we need to fetch every numbers at once
  // otherwise when the user clicks "Select all" some of the numbers could be not fetched yet
  const { lines, loading } = useNumbersList({
    searchTerm,
    limit: searchTerm.length ? 30 : 999999,
  });
  const handleSubmit = useCallback(
    ({ selectedItems }: MultiSelectModalFormValues<Line>) => {
      onSubmit(selectedItems);
    },
    [onSubmit]
  );
  const renderCard = useCallback(
    (line: Line, isChecked: boolean, setChecked: (line: Line) => void) => {
      const {
        ID,
        activationState,
        agentsCounter,
        capabilities,
        flowEditorEnabled,
        countryISOCode,
        isIVR,
        name,
        phoneNumber,
        state,
        verificationState,
      } = line;
      return (
        <NumberCard
          key={ID}
          data-test={`line-card-${ID}`}
          isChecked={isChecked}
          onClick={() => setChecked(line)}
          id={ID}
          activationState={activationState}
          usersCount={agentsCounter!}
          countryCode={countryISOCode}
          isIVR={isIVR}
          hideState
          name={name}
          phoneNumber={phoneNumber}
          state={state}
          verificationState={verificationState}
          capabilities={capabilities}
          flowEditorEnabled={flowEditorEnabled}
        />
      );
    },
    []
  );

  // Remove blacklisted lines from lines data
  const _lines = lines?.items?.filter(
    (line) => !linesBlacklist.find((_line) => _line.ID === line.ID)
  );

  return (
    <MultiSelectModal<Line, string>
      title={title}
      isOpen={isOpen}
      searchText={searchTerm}
      loading={loading}
      data={_lines}
      getDatumId={(line: Line) => line.ID}
      renderCard={renderCard}
      onSearch={setSearchTerm}
      onClearSearch={() => setSearchTerm('')}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
}
