/* eslint-disable @typescript-eslint/naming-convention */

import styled from 'styled-components';

import { RouterLinkProps } from './Link.decl';

import { getColor, getSpace } from '@aircall/tractor-v2';
import { Link as RRLink } from 'react-router-dom';

const StyledRRLink = styled(RRLink)<{ isUnderlined?: boolean }>`
  display: inline-flex;
  gap: ${getSpace('xxxs')};
  color: ${({ color }) => getColor(color ?? 'primary-500')};
  text-decoration: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  ${({ isUnderlined }) => (isUnderlined ? 'text-decoration: underline;' : '')};

  &:active,
  &:hover {
    color: ${({ color }) => getColor(color ?? 'primary-700')};
    text-decoration: underline;
  }
`;

export function RouterLink({ to, ...rest }: RouterLinkProps) {
  return <StyledRRLink data-test='react-router-link' to={to} {...rest} />;
}
