import { useState } from 'react';

import { TrialChecklistItemActionButtonsProps } from './TrialChecklistPopup.decl';
import { ButtonIcon } from './TrialChecklistPopup.style';

import { CloseCircleOutlined, MenuVerticalFilled, TickCircleOutlined } from '@aircall/icons';
import { Flex } from '@aircall/tractor-v2';
import { Loading } from '@dashboard/library';
import { StepStatus } from '@generated/globalTypes';
import { useOnboardingData } from '@hooks/useOnboardingData';
import { useTranslation } from 'react-i18next';

export function TrialChecklistItemActionButtons({
  isComplete,
  stepType,
}: Readonly<TrialChecklistItemActionButtonsProps>) {
  const [showActionButtons, setShowActionButtons] = useState(false);
  const { updateOnboardingStepStatus, updateStepLoading } = useOnboardingData();
  const { t } = useTranslation();

  if (isComplete) {
    return null;
  }
  if (!showActionButtons) {
    return (
      <ButtonIcon
        data-test='checklist-item-right-icon'
        onClick={() => {
          setShowActionButtons(true);
        }}
        component={MenuVerticalFilled}
        size={20}
        color='surface-inverted'
        type='button'
        padding='12px 0'
        aria-label={t('user_profile.task_checklist.menu_button_label')}
      />
    );
  }
  return (
    <Flex padding='12px 0'>
      {updateStepLoading ? (
        <Loading size={20} marginRight={10} data-test='checklist-item-loading' />
      ) : (
        <ButtonIcon
          onClick={() => {
            updateOnboardingStepStatus(stepType, StepStatus.COMPLETED);
          }}
          component={TickCircleOutlined}
          size={20}
          marginRight={10}
          color='surface-inverted'
          data-test='checklist-item-dismiss-button'
          type='button'
          aria-label={t('user_profile.task_checklist.dismiss_button_label')}
        />
      )}
      <ButtonIcon
        onClick={() => {
          setShowActionButtons(false);
        }}
        component={CloseCircleOutlined}
        size={20}
        color='surface-inverted'
        data-test='checklist-item-close-button'
        type='button'
        aria-label={t('user_profile.task_checklist.close_menu_button_label')}
      />
    </Flex>
  );
}
