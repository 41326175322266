import { UserCountTagWrapper } from './UserCountTagWrapper';

import { UserOutlined } from '@aircall/icons';
import { Spacer, Icon } from '@aircall/tractor-v2';

interface UserCountTagProps {
  size: number;
  id: string;
}

export function UserCountTag({ size, id }: UserCountTagProps) {
  return (
    <UserCountTagWrapper disabled={size === 0} data-test={`user-count-${id}`}>
      <Spacer space='xxs' alignItems='center'>
        <Icon data-test={`team-card-icon-${id}`} component={UserOutlined} size={16} />
        {size.toString()}
      </Spacer>
    </UserCountTagWrapper>
  );
}
