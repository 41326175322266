export enum ADDONS {
  AIRCALL_AI = 'aircall_ai',
  ANALYTICS_PLUS = 'analytics_plus',
  ADVANCED_MESSAGING = 'advanced_messaging',
  AIRCALL_SURVEYS = 'aircall_surveys',
}

export enum ADDON_STATUS {
  ACTIVATED = 'activated',
  TRIAL = 'trial',
  TRIAL_EXHAUSTED = 'trial_exhausted',
  TRIAL_AVAILABLE = 'trial_available',
}
