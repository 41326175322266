import { useContext } from 'react';

import { LookerPageContextProps } from './Looker.decl';
import { LookerPageContext } from './LookerPageProvider';

export function useLookerPage(): LookerPageContextProps {
  const context = useContext(LookerPageContext);

  if (!context) {
    throw new Error('useLookerPage should be used within a LookerPageProvider');
  }

  return context;
}
