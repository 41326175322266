import styled from 'styled-components';

import { ClickWrapProps } from './TermsOfUse.decl';

import { IRONCLAD_ACCESS_ID } from '@constants/environment.constants';
import { getAvailableCountryCode } from '@helpers/ironclad.helpers';
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import { useTranslation } from 'react-i18next';

const ClickWrapContainer = styled.div.attrs({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'data-test': 'clickwrap-container',
  id: 'ps-clickwrap',
})`
  font-weight: 500;
`;

export function ClickWrap({
  companyId,
  signupCountry,
  groupKeys,
  ...clickWrapProps
}: ClickWrapProps) {
  const { t } = useTranslation();
  const countryCode = getAvailableCountryCode(signupCountry);

  return (
    <ClickWrapContainer>
      <PSClickWrap
        accessId={IRONCLAD_ACCESS_ID}
        signerId={companyId.toString()}
        filter={`countries==${countryCode} and tags==${groupKeys.join(',')}`}
        // We need to pass the `renderData` prop to use `dynamic` and `acceptanceLanguage`
        renderData={{}}
        acceptanceLanguage={t('terms_acceptance_message')}
        dynamic
        {...clickWrapProps}
      />
    </ClickWrapContainer>
  );
}
