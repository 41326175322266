import { gql } from '@apollo/client';

export const AUTHORIZED_APPLICATION_QUERY = gql`
  query AuthorizedApplicationQuery(
    $clientId: String!
    $redirectUri: String!
    $responseType: String!
    $scope: String!
    $state: String
    # We need to create two booleans here because we can't do "not equal" condition in skip directive
    $skipWithState: Boolean!
    $skipWithoutState: Boolean!
  ) {
    getRestAuthorizedApplication(
      clientId: $clientId
      redirectUri: $redirectUri
      responseType: $responseType
      scope: $scope
      state: $state
      skipWithState: $skipWithState
      skipWithoutState: $skipWithoutState
    )
      @skip(if: $skipWithState)
      @rest(
        type: "RestAuthorizedApplication"
        path: "applications/authorize.json?client_id={args.clientId}&redirect_uri={args.redirectUri}&response_type={args.responseType}&scope={args.scope}&state={args.state}"
        endpoint: "merlin"
      ) {
      clientId
      logo
      name
      redirectUri
      responseType
      scope
      source
      state
    }
    getRestAuthorizedApplication(
      clientId: $clientId
      redirectUri: $redirectUri
      responseType: $responseType
      scope: $scope
      state: $state
      skipWithState: $skipWithState
      skipWithoutState: $skipWithoutState
    )
      @skip(if: $skipWithoutState)
      @rest(
        type: "RestAuthorizedApplication"
        path: "applications/authorize.json?client_id={args.clientId}&redirect_uri={args.redirectUri}&response_type={args.responseType}&scope={args.scope}"
        endpoint: "merlin"
      ) {
      clientId
      logo
      name
      redirectUri
      responseType
      scope
      source
      state
    }
  }
`;
