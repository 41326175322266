export enum APPLICATIONS_NAMES {
  INTERCOM = 'intercom',
  HUBSPOT = 'hubspot',
  HUBSPOT_V2 = 'hubspot_v2',
  HUBSPOT_V2_ALPHA = 'hubspot_v2_alpha',
  HUBSPOT_V2_DEV = 'hubspot_v2_dev',
  ZENDESK = 'zendesk',
  PIPEDRIVE = 'pipedrive',
  SALESFORCE = 'salesforce',
  SALESFORCE_V3 = 'salesforce_v3',
  SALESFORCE_V3_ALPHA = 'salesforce_v3_alpha',
  SALESFORCE_V3_DEV = 'salesforce_v3_dev',
  SHOPIFY = 'shopify',
  SLACK = 'slack',
  FRONT = 'front',
  ZAPIER = 'zapier',
  WEBHOOK = 'webhook',
  TRAY = 'tray',
  DESK = 'desk',
  FRESHDESK = 'freshdesk',

  // Source Tray
  TRAY_TEST_APP = 'tray_test_app',
  COPPER = 'copper',
  MICROSOFT_DYNAMICS = 'microsoft_dynamics',
  AIRCALL_SANDBOX = 'aircall_sandbox',

  // Source Workato
  WORKATO_TEST_APP = 'workato_test_app',
}

export enum APPLICATIONS_TYPES {
  BRIDGE = 'bridge',
  HUB = 'hub',
  WEBHOOK = 'webhook',
}

export enum APPLICATIONS_SOURCES {
  TRAY = 'tray',
  IN_HOUSE = 'in_house',
  PARTNER = 'partner',
  WORKATO = 'workato',
}

export enum WORKATO_CONNECTION_TYPES {
  SOLUTION_ID = 'solution_id',
  AIRCALL_CONNECTION = 'aircall_connection_url',
  INTEGRATION_CONNECTION = 'integration_connection_url',
}

export enum WORKATO_MESSAGE_TYPES {
  CONNECTION_STATUS_CHANGE = 'connectionStatusChange',
  CONNECTION_CONNECTED = 'Connected',
  CONNECTION_DISCONNECTED = 'Disconnected',
  ERROR = 'error',
}

export enum SALESFORCE_ENVS {
  SANDBOX = 'SANDBOX',
  PRODUCTION = 'PRODUCTION',
}

export const SALESFORCE_ENV_URLS = {
  [SALESFORCE_ENVS.SANDBOX]: 'https://test.salesforce.com',
  [SALESFORCE_ENVS.PRODUCTION]: 'https://login.salesforce.com',
};

export enum WORKATO_CONNECTION_APPLICATION_NAME {
  OUTREACH_V2 = 'Outreach V2',
  AIRCALL = 'Aircall',
  SALESFORCE = 'Salesforce',
  SALESLOFT_V2 = 'Salesloft V2',
}

export const SELECT_ALL_NUMBERS_PARAM_VALUE = 'all';

export const NUMBER_OF_DAYS_BEFORE_DEADLINE_TO_FORCE_UPDATE = 14;
