import { Button, ButtonProps, Flex, Box, Spacer, getColor } from '@aircall/tractor-v2';
import styled from '@xstyled/styled-components';

export const WidgetContainer = styled(Box)`
  width: 100%;
  margin-bottom: 20px;
  padding-inline: 10px;
`;

export const WidgetWrapper = styled(Spacer)`
  background: linear-gradient(119.12deg, #195a75 2.16%, #052e3f 101.53%);
`;

export const GreetingsWrapper = styled(Flex)`
  & svg {
    width: 32px;
    height: 32px;
  }
`;

export const ToggleButton = styled(Button)<ButtonProps>`
  width: 100%;
  max-height: 40px;
  margin-block: 8px;
`;

export const LinkButton = styled(Button)<ButtonProps>`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1.5px solid;
  border-radius: 8px;
  outline: none;
`;

export const AppLinkButton = styled(LinkButton)`
  border-color: ${getColor('surface-interactive-default')};
  color: ${getColor('text-contrast-light')};

  :hover {
    border-color: ${getColor('surface-interactive-default')};
    background-color: ${getColor('surface-interactive-default')};
    color: ${getColor('text-interactive-neutral-hover')};
  }

  :focus-visible {
    outline: auto;
  }
`;
