import { NUMBER_DETAIL_FRAGMENT } from '../fragments/NumberDetailFragment';

import { gql } from '@apollo/client';

export const NUMBER_DETAIL_QUERY = gql`
  query NumberDetailQuery($id: String!) {
    numberDetail(id: $id)
      @rest(
        type: "Number"
        path: "{args.id}?include=dispatch_groups,dispatch_groups.dispatchable,ivr_settings,ivr_settings.redirecting_number,tts_messages"
        endpoint: "v4NumberDetail"
        bodySerializer: "auth"
      ) {
      ...NumberDetailFragment
      ttsMessages {
        id
        category
        track
        language
        message
        voice
        url
        defaultUrl
      }
      ivrSettings {
        id
        key
        branchType
        redirectingNumber {
          name
          activationState
          verificationState
          number
          id
        }
        redirectingExternalNumber
      }
      dispatchGroups {
        id
        priority
        queuingTimeout
        redirectionStrategy
        dispatchable {
          ... on Team {
            ID
            imageName
            name
            agentsIds
          }
          ... on User {
            id
            firstName
            lastName
            pictureUrl
            ringTimeout
          }
        }
      }
    }
  }
  ${NUMBER_DETAIL_FRAGMENT}
`;
