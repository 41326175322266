import { AnalyticsDateState, PredefinedDateOption } from '@pages/looker-v2/Filters/DateFilter';
import { DateLimits, LOOKER_DATE_PERIODS } from '@pages/looker-v2/Looker.decl';
import {
  differenceInDays,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfToday,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  isAfter,
  isBefore,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subDays,
  subQuarters,
  subYears,
} from 'date-fns';

export const getPredefinedOptions = (): PredefinedDateOption[] => {
  const today = startOfDay(new Date());
  const endOfLastMonth = subDays(startOfMonth(today), 1);
  const endOfLastWeek = subDays(startOfWeek(today, { weekStartsOn: 1 }), 7);

  return [
    {
      label: 'today',
      rangeValue: LOOKER_DATE_PERIODS.TODAY,
      startDate: today,
      endDate: endOfToday(),
    },
    {
      label: 'yesterday',
      rangeValue: LOOKER_DATE_PERIODS.YESTERDAY,
      startDate: startOfYesterday(),
      endDate: endOfYesterday(),
    },
    {
      label: 'this_week',
      rangeValue: LOOKER_DATE_PERIODS.THIS_WEEK,
      startDate: startOfWeek(today, { weekStartsOn: 1 }),
      endDate: endOfToday(),
    },
    {
      label: 'last_week',
      rangeValue: LOOKER_DATE_PERIODS.PREVIOUS_WEEK,
      startDate: startOfWeek(endOfLastWeek, { weekStartsOn: 1 }),
      endDate: endOfWeek(endOfLastWeek, { weekStartsOn: 1 }),
    },
    {
      label: 'this_month',
      rangeValue: LOOKER_DATE_PERIODS.THIS_MONTH,
      startDate: startOfMonth(today),
      endDate: endOfToday(),
    },
    {
      label: 'last_month',
      rangeValue: LOOKER_DATE_PERIODS.PREVIOUS_MONTH,
      startDate: startOfMonth(endOfLastMonth),
      endDate: endOfMonth(endOfLastMonth),
    },
    {
      label: 'this_year',
      rangeValue: LOOKER_DATE_PERIODS.THIS_YEAR,
      startDate: startOfYear(today),
      endDate: endOfToday(),
    },
  ];
};

export const RANGES = getPredefinedOptions();

export const DEFAULT_VALUE: AnalyticsDateState = {
  rangeValue: RANGES[0].rangeValue,
  endDate: RANGES[0].endDate,
  startDate: RANGES[0].startDate,
};

export const isDateRangeWithinLimits = (
  startDate: Date,
  endDate: Date,
  maxDateRangeInDays?: number,
  minDate?: Date
): boolean => {
  if (minDate && isBefore(startDate, minDate)) {
    return false;
  }
  if (maxDateRangeInDays && differenceInDays(endDate, startDate) >= maxDateRangeInDays) {
    return false;
  }
  return true;
};

export const mapLookerPeriodToDateRange = (period: LOOKER_DATE_PERIODS): AnalyticsDateState => {
  const today = startOfDay(new Date());

  switch (period) {
    case LOOKER_DATE_PERIODS.TODAY:
    case LOOKER_DATE_PERIODS.YESTERDAY:
    case LOOKER_DATE_PERIODS.THIS_WEEK:
    case LOOKER_DATE_PERIODS.THIS_MONTH:
    case LOOKER_DATE_PERIODS.THIS_YEAR:
    case LOOKER_DATE_PERIODS.PREVIOUS_WEEK:
    case LOOKER_DATE_PERIODS.PREVIOUS_MONTH: {
      const { rangeValue, startDate, endDate } = RANGES.find((r) => r.rangeValue === period)!;
      return { rangeValue, startDate, endDate };
    }
    case LOOKER_DATE_PERIODS.LAST_7_DAYS:
    case LOOKER_DATE_PERIODS.LAST_14_DAYS:
    case LOOKER_DATE_PERIODS.LAST_28_DAYS:
    case LOOKER_DATE_PERIODS.LAST_30_DAYS:
    case LOOKER_DATE_PERIODS.LAST_90_DAYS:
    case LOOKER_DATE_PERIODS.LAST_180_DAYS:
    case LOOKER_DATE_PERIODS.LAST_365_DAYS: {
      const periodDayMapping = {
        [LOOKER_DATE_PERIODS.LAST_7_DAYS]: 6,
        [LOOKER_DATE_PERIODS.LAST_14_DAYS]: 13,
        [LOOKER_DATE_PERIODS.LAST_28_DAYS]: 27,
        [LOOKER_DATE_PERIODS.LAST_30_DAYS]: 29,
        [LOOKER_DATE_PERIODS.LAST_90_DAYS]: 89,
        [LOOKER_DATE_PERIODS.LAST_180_DAYS]: 179,
        [LOOKER_DATE_PERIODS.LAST_365_DAYS]: 364,
      };
      return {
        rangeValue: null,
        startDate: subDays(today, periodDayMapping[period]),
        endDate: endOfDay(today),
      };
    }
    case LOOKER_DATE_PERIODS.THIS_QUARTER: {
      return { rangeValue: null, startDate: startOfQuarter(today), endDate: endOfDay(today) };
    }
    case LOOKER_DATE_PERIODS.PREVIOUS_QUARTER: {
      const previousQuarter = subQuarters(today, 1);
      return {
        rangeValue: null,
        startDate: startOfQuarter(previousQuarter),
        endDate: endOfQuarter(previousQuarter),
      };
    }
    case LOOKER_DATE_PERIODS.PREVIOUS_YEAR: {
      const previousYear = subYears(today, 1);
      return {
        rangeValue: null,
        startDate: startOfYear(previousYear),
        endDate: endOfYear(previousYear),
      };
    }
    case LOOKER_DATE_PERIODS.YEAR_TO_DATE:
    default: {
      return DEFAULT_VALUE;
    }
  }
};

export const getSafeDates = (
  date: string | null,
  { maxDateRangeInDays, minDate }: DateLimits
): AnalyticsDateState => {
  if (!date) {
    return DEFAULT_VALUE;
  }

  // Look for date ranges
  if (date.includes(' to ')) {
    const newDates = date.split(' to ');
    const startDate = new Date(newDates[0]);
    const endDate = new Date(newDates[1]);

    if (
      isAfter(startDate, endDate) ||
      !isDateRangeWithinLimits(startDate, endDate, maxDateRangeInDays, minDate)
    ) {
      return DEFAULT_VALUE;
    }
    return { rangeValue: null, startDate, endDate };
  }

  // If there is no range it might be because of a predefined value being used
  // so try to map the predefined value to a supported predefined value or equivalent date range
  const dateRange = mapLookerPeriodToDateRange(date as LOOKER_DATE_PERIODS);

  if (
    !isDateRangeWithinLimits(dateRange.startDate, dateRange.endDate, maxDateRangeInDays, minDate)
  ) {
    return DEFAULT_VALUE;
  }
  return dateRange;
};
