import { StatsLegacyOverviewContainer } from './StatsLegacyOverview.container';
import { StatsOverviewContainer } from './StatsOverview.container';

import { useFeatures } from '@hooks/useFeatures/useFeatures';

export function StatsOverviewPage() {
  const { dashboardNewOverviewEnabled } = useFeatures();

  if (dashboardNewOverviewEnabled) {
    return <StatsOverviewContainer />;
  }

  return <StatsLegacyOverviewContainer />;
}
