import { useMemo } from 'react';

import DEFAULT_INTEGRATION_LOGO from '../../assets/images/integration_logo.svg';

import { ApplicationPageLayoutProps } from './ApplicationPageLayout.decl';

import { ExternalOutlined, InformationOutlined } from '@aircall/icons';
import { Box, Button, Flex, getColor, Skeleton, Spacer, Typography } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { INTEGRATIONS_TABS } from '@constants/routes.constants';
import { MORE_INTEGRATIONS_URL } from '@constants/urls.constants';
import {
  GridItem,
  GridLayout,
  INTEGRATIONS_ROUTE,
  INTEGRATIONS_ROUTE_TAB,
  isValidUrl,
  PageHeader,
  Paper,
  stringContainsHtmlTags,
} from '@dashboard/library';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

export function ApplicationPageLayout({
  subtitle,
  name,
  customName,
  logo,
  shouldShowInstallButton,
  learnMoreUri,
  features,
  description,
  loading,
  onInstallButtonClick,
}: ApplicationPageLayoutProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const logoUrl = logo && isValidUrl(logo) ? logo : DEFAULT_INTEGRATION_LOGO;
  const title = name + (customName ? ` (${customName})` : '');

  const goBackLinkRoute = useMemo(
    () =>
      location.pathname.includes('/app')
        ? INTEGRATIONS_ROUTE_TAB.replace(':tab', INTEGRATIONS_TABS.APPLICATIONS)
        : INTEGRATIONS_ROUTE,
    [location]
  );

  const Description = stringContainsHtmlTags(description) ? (
    /*  eslint-disable-next-line react/no-danger */
    <div dangerouslySetInnerHTML={{ __html: description }} />
  ) : (
    <Typography>{description}</Typography>
  );

  return (
    <>
      <PageHeader
        data-test='application-page-header'
        title={title}
        avatarSrc={logoUrl}
        gobackLinkRoute={goBackLinkRoute}
        gobackLinkText={t('integrations.settings.header.back_to_integrations')}
      />
      <GridLayout padding='xl'>
        <GridItem lg={8} md={12} xl={8} xs={12} sm={12}>
          {loading ? (
            <Skeleton
              w='100%'
              h='300px'
              data-test='integrations-install-description-loading-block'
            />
          ) : (
            <Paper
              data-test='install-integration-description-block'
              title={t('integrations.install.description_header')}
              subtitle={subtitle}
            >
              {Description}
              {features && Array.isArray(features) && (
                <Spacer direction='vertical' space='xxxs' pt='m'>
                  {features.map((item, index) => (
                    <Typography key={item} data-test={`install-integration-feature-${index}`}>
                      {item}
                    </Typography>
                  ))}
                </Spacer>
              )}
            </Paper>
          )}
        </GridItem>
        <GridItem lg={4} md={12} xl={4} xs={12} sm={12}>
          {loading ? (
            <Skeleton w='100%' h='300px' data-test='integrations-install-install-loading-block' />
          ) : (
            <Box
              data-test='install-button-container'
              borderRadius='8px'
              border={`1px solid ${getColor('neutral-200')}`}
              backgroundColor='neutral-0'
              overflow='hidden'
            >
              {shouldShowInstallButton && (
                <Flex justifyContent='center' padding='m' backgroundColor='primary-100'>
                  <Button
                    onClick={onInstallButtonClick}
                    data-test='install-integration-button'
                    data-tracking='integration-install-process-started'
                  >
                    {t('integrations.install.instalation_button')}
                  </Button>
                </Flex>
              )}

              <Box paddingTop='m' paddingLeft='s' paddingRight='m' paddingBottom='m'>
                <Box paddingBottom='s'>
                  <Typography>{t('integrations.install.aditional_info')}</Typography>
                </Box>
                <Flex flexDirection='column' alignItems='flex-start'>
                  <ExternalLink
                    data-test='link-button-marketplace'
                    href={MORE_INTEGRATIONS_URL}
                    target='_blank'
                  >
                    <Spacer space='xxs' alignItems='center'>
                      <ExternalOutlined />
                      {t('integrations.install.market_place')}
                    </Spacer>
                  </ExternalLink>

                  {learnMoreUri && (
                    <ExternalLink
                      data-test='link-button-learn-more'
                      href={learnMoreUri}
                      target='_blank'
                    >
                      <Spacer space='xxs' alignItems='center'>
                        <InformationOutlined />
                        {t('integrations.install.learn_more')}
                      </Spacer>
                    </ExternalLink>
                  )}
                </Flex>
              </Box>
            </Box>
          )}
        </GridItem>
      </GridLayout>
    </>
  );
}
