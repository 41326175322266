import styled from 'styled-components';

import { Flex, Typography, getSpace } from '@aircall/tractor-v2';

import { LeanHeaderProps } from '.';

const LogoWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  padding-left: ${getSpace('m')};

  > img {
    width: ${getSpace('m')};
  }
`;

export function LeanHeader({
  logoUrl,
  altText,
  companyName,
  'data-test': dataTest,
}: LeanHeaderProps) {
  return (
    <Flex
      position='fixed'
      alignItems='center'
      justifyContent='flex-end'
      pr='s'
      backgroundColor='surface-default'
      zIndex={1}
      top={0}
      w='100%'
      h={64}
      data-test={`${dataTest}-app-header`}
    >
      <LogoWrapper>
        <img src={logoUrl} alt={`${altText} logo`} data-test={`${dataTest}-app-logo`} />
        <Typography
          variant='headingBoldS'
          fontWeight={700}
          ml='xxs'
          data-test={`${dataTest}-app-name`}
        >
          {companyName}
        </Typography>
      </LogoWrapper>
    </Flex>
  );
}
