import { useMemo } from 'react';

import { TracksListQuery } from '@generated/TracksListQuery';
import { TRACKS_LIST_QUERY } from '@graphql/queries/TracksListQuery';
import { ClientError } from '@helpers/errors.helpers';
import { useGraphQuery } from '@hooks/useQuery/useGraphQuery';

export interface Track {
  /** Unique name used to identify the track (attributes.id) */
  name: string;

  /** Name to use for display purposes (attributes.name) */
  label: string;

  /** URL to the audio file (attributes.url) */
  url: string;
}

export interface UseAudioLibraryReturn {
  tracks?: Track[];
  selectedTrackURL?: string;
  loading: boolean;
  error?: ClientError;
}

/**
 * Hook to get tracks and a specific given track.
 * @param url - URL of the audio
 * @returns object holding fetched audio tracks and selected track if the track is found else will return the first of the list
 */
export function useAudioLibrary(url?: string): UseAudioLibraryReturn {
  const { data, loading } = useGraphQuery<TracksListQuery>(TRACKS_LIST_QUERY, {
    throwError: true,
  });

  const tracks = useMemo(
    () =>
      data?.listTracks?.data.map((track) => ({
        name: track.attributes.id,
        label: track.attributes.name,
        url: track.attributes.url,
      })),
    [data?.listTracks?.data]
  );

  const isTrackFound = tracks?.some((track) => track.url === url);
  const selectedTrackURL = isTrackFound ? url : tracks?.[0].url;

  return { tracks, selectedTrackURL, loading };
}
