import styled from 'styled-components';

import { Flex, Typography, getSpace } from '@aircall/tractor-v2';
import HeaderUserProfileDropdown from '@components/Header/components/HeaderUserProfileDropdown/HeaderUserProfileDropdown';
import { ResellerConfig } from '@config/resellers.config';

const CompanyLogoWrapper = styled(Flex)`
  > img {
    width: ${getSpace('m')};
  }
`;

const CompanyTitle = styled(Typography)`
  text-transform: lowercase;
`;

export function AgentHeader() {
  const { logoUrl, name } = ResellerConfig;

  return (
    <Flex
      h='100%'
      alignItems='center'
      justifyContent='space-between'
      pl='m'
      pr='s'
      backgroundColor='base.white'
      borderBottomWidth='1px'
      borderBottomStyle='solid'
      borderBottomColor='grey.base'
      data-test='agent-header'
    >
      <CompanyLogoWrapper alignItems='center'>
        <img src={logoUrl} alt={`${name} logo`} data-test='agent-header-logo' />
        <CompanyTitle
          variant='headingBoldS'
          fontWeight={600}
          ml='xxs'
          data-test='agent-header-name'
        >
          {name}
        </CompanyTitle>
      </CompanyLogoWrapper>
      <HeaderUserProfileDropdown showEditProfile={false} />
    </Flex>
  );
}
