import type { NormalizedCompanyAddon } from './useCompanyAddons.decl';
import type { ActiveAddonTrial } from './utils.decl';

import { ADDONS } from '@constants/addons.constants';

export function getActiveAddonTrials(
  addons: NormalizedCompanyAddon[],
  isAddonEnabled: (addonName: ADDONS) => boolean
): ActiveAddonTrial[] {
  return addons.reduce<ActiveAddonTrial[]>((acc, curr) => {
    if (!curr.name || !isAddonEnabled(curr.name)) {
      return [...acc];
    }

    const activeTrial = curr.trials.find((trial) => trial.active);

    if (!activeTrial) {
      return [...acc];
    }

    return [...acc, { name: curr.name, ...activeTrial }];
  }, []);
}

export function orderAddonTrialsByAscendingRemainingDays(
  activeAddonTrials: ActiveAddonTrial[]
): ActiveAddonTrial[] {
  return activeAddonTrials.sort((a, b) => a.remainingDays - b.remainingDays);
}

export function getActiveAddonTrialByName(
  addonName: ADDONS,
  addons: NormalizedCompanyAddon[],
  isAddonEnabled: (addonName: ADDONS) => boolean
): ActiveAddonTrial | undefined {
  return getActiveAddonTrials(addons, isAddonEnabled).find((addon) => addon.name === addonName);
}
