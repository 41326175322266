import { WarningOutlined } from '@aircall/icons';
import { FlagIcon, Icon } from '@aircall/tractor-v2';
import { PHONE_NUMBER_ICON_DATA_TEST } from '@components/PhoneNumber/components/PhoneNumberIcon/PhoneNumberIcon.constants';
import { PhoneNumberProps } from '@components/PhoneNumber/components/PhoneNumberIcon/PhoneNumberIcon.decl';

export function PhoneNumberIcon({
  country,
  highlightInvalidFormattablePhoneNumber,
  isValidNumber,
}: PhoneNumberProps) {
  if (highlightInvalidFormattablePhoneNumber && !isValidNumber) {
    return (
      <Icon component={WarningOutlined} data-test={PHONE_NUMBER_ICON_DATA_TEST.INVALID_NUMBER} />
    );
  }

  if (!country) {
    return null;
  }

  return <FlagIcon countryCode={country} size={24} data-test={PHONE_NUMBER_ICON_DATA_TEST.FLAG} />;
}
