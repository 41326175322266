import { SearchableSelectField } from '@components/SearchableSelectField/SearchableSelectField';
import { ModalForm, MultiSelectOption } from '@dashboard/library';
import { Field } from 'react-final-form';

import { SearchAddModalFormProps } from '.';

export function SearchAddModalForm<
  FormValues,
  TOption extends MultiSelectOption = MultiSelectOption
>({
  isOpen,
  onClose,
  onSubmit,
  label,
  name,
  placeholder,
  titleText,
  submitButtonText,
  ...rest
}: SearchAddModalFormProps<FormValues, TOption>) {
  return (
    <ModalForm
      show={isOpen}
      onHide={onClose}
      title={titleText}
      size='small'
      submitButtonText={submitButtonText}
      formProps={{ onSubmit }}
    >
      {() => (
        <Field name={name}>
          {({ input: { onChange, value } }) => (
            <SearchableSelectField
              placeholder={placeholder}
              value={value}
              label={label}
              onChange={onChange}
              w='100%'
              {...rest}
            />
          )}
        </Field>
      )}
    </ModalForm>
  );
}
