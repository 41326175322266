import React from 'react';

import { WebhookSetupSectionsProps } from './Webhook.decl';
import { WebhookBoundEventsContent } from './WebhookBoundEventsContent';

import { Spacer, Typography } from '@aircall/tractor-v2';
import { IntegrationFlowPage } from '@components/IntegrationFlowPage/IntegrationFlowPage';
import { ExternalLink } from '@components/Link';
import { INTEGRATION_FULL_ROUTES } from '@constants/routes.constants';
import { DEVELOPER_DOC_URL } from '@constants/urls.constants';
import { Paper } from '@dashboard/library';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { WebhookGeneralSettingsFormContent } from '@pages/integration-flow-install/Webhook/WebhookGeneralSettingsFormContent';
import { Field } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const WebhookSetupSections = React.memo(
  ({
    integrationName,
    onSubmit,
    shouldDisableSubmit,
    webhookConfig,
    urlFormat,
    toggleEvents,
  }: WebhookSetupSectionsProps) => {
    const { t } = useTranslation();
    const { applicationType, applicationId } = useParams();
    const navigate = useNavigateWithParamsReplace();
    const { logo, name, sectionEvents, fields, helpLink } = webhookConfig;

    return (
      <IntegrationFlowPage
        title={name}
        avatarSrc={logo}
        hasFooter
        onCancel={() =>
          navigate(INTEGRATION_FULL_ROUTES.APPLICATION, {
            applicationName: integrationName,
            applicationType,
            applicationId,
          })
        }
        cancelButtonText={t('integration_flow.install.buttons.cancel')}
        onSubmit={onSubmit}
        shouldDisableSubmit={shouldDisableSubmit}
        submitButtonText={t(
          `integration_flow.install.buttons.add_${
            integrationName === 'webhook' ? 'webhook' : 'integration'
          }`
        )}
      >
        <Spacer direction='vertical' space='xxs'>
          <Typography variant='headingBoldS'>
            {t('integration_flow.install.integration_setup')}
          </Typography>
          <Typography variant='bodyMediumS'>
            <Trans i18nKey='integrations.settings.general_settings.subtitle'>
              <ExternalLink href={helpLink || DEVELOPER_DOC_URL} target='_blank'>
                Read more
              </ExternalLink>
              on how to setup webhooks.
            </Trans>
          </Typography>
        </Spacer>
        {fields && (
          <Paper fluid>
            <WebhookGeneralSettingsFormContent fields={fields} urlFormat={urlFormat} />
          </Paper>
        )}
        {sectionEvents && (
          <Paper fluid mb='xxxl'>
            <Field name='events'>
              {({ input: { value, onChange } }) => (
                <WebhookBoundEventsContent
                  events={sectionEvents}
                  toggleWebhookEvents={toggleEvents(onChange, value)}
                  webhook={{ events: value }}
                />
              )}
            </Field>
          </Paper>
        )}
      </IntegrationFlowPage>
    );
  }
);
