import React, { useMemo, useCallback } from 'react';

import {
  AudioSetting,
  AudioSettingFormValues,
  AudioSettingModalProps,
  AUDIO_SETTING_TYPE,
} from './AudioSetting.decl';
import { AudioSettingFileField } from './AudioSettingFile/AudioSettingFileField';
import { AudioSettingLibraryField } from './AudioSettingLibrary/AudioSettingLibraryField';

import { Box, Spacer } from '@aircall/tractor-v2';
import { TextToSpeechFields } from '@components/TextToSpeechFields/TextToSpeechFields';
import { ToggleField } from '@components/ToggleField';
import {
  GridItem,
  GridLayout,
  Loading,
  SelectField,
  ModalForm,
  validateRequired,
} from '@dashboard/library';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export const validateAudioSettingFormValues = ({ audioSetting }: AudioSettingFormValues) => {
  if (!audioSetting) {
    return undefined;
  }
  if (audioSetting.type === AUDIO_SETTING_TYPE.FILE) {
    return { audioSetting: { file: validateRequired(audioSetting.file) } };
  }
  if (audioSetting.type === AUDIO_SETTING_TYPE.TEXT_TO_SPEECH) {
    return {
      audioSetting: {
        message: validateRequired(audioSetting.message),
        language: validateRequired(audioSetting.language),
      },
    };
  }
  return undefined;
};

export const AudioSettingModal = React.memo(
  ({
    show,
    onHide,
    title,
    intro,
    toggleLabel,
    availableOptions,
    optionSelectLabel,
    onSubmit,
    values,
    numberId,
    defaultTts,
  }: AudioSettingModalProps) => {
    const { t } = useTranslation();
    const selectOptions = useMemo(
      () =>
        availableOptions.map((type) => ({
          label: t(
            `number_details.settings.call_distribution_section.audio_setting_modal.select.${type.toLowerCase()}`
          ),
          value: type,
        })),
      [availableOptions, t]
    );

    const renderAudioSettings = useCallback(
      (audioSetting: AudioSetting | undefined) => {
        if (!audioSetting) {
          return <Loading data-test='audio-setting-loading' />;
        }
        if (audioSetting.type === AUDIO_SETTING_TYPE.LIBRARY) {
          return <AudioSettingLibraryField name='audioSetting.url' />;
        }

        if (audioSetting.type === AUDIO_SETTING_TYPE.FILE) {
          return <AudioSettingFileField name='audioSetting.file' />;
        }

        if (audioSetting.type === AUDIO_SETTING_TYPE.TEXT_TO_SPEECH) {
          return <TextToSpeechFields numberId={numberId} audioSetting={audioSetting} />;
        }

        return null;
      },
      [numberId]
    );

    function TypeField() {
      const form = useForm();
      const handleChange = (selected: string) => {
        if (selected === AUDIO_SETTING_TYPE.TEXT_TO_SPEECH) {
          const { __typename, ...tts } = defaultTts;
          for (const [key, value] of Object.entries(tts)) {
            form.change(`audioSetting.${key}`, value);
          }
        }
        form.change('audioSetting.type', selected);
      };

      return (
        <SelectField
          t={t}
          name='audioSetting.type'
          label={optionSelectLabel}
          options={selectOptions}
          onChange={handleChange}
        />
      );
    }

    return (
      <ModalForm<AudioSettingFormValues>
        show={show}
        formProps={{ onSubmit, initialValues: values, validate: validateAudioSettingFormValues }}
        onHide={onHide}
        title={title}
        submitButtonText={t(
          'number_details.settings.call_distribution_section.diagram.add_dispatch_group_section.modal.submit'
        )}
      >
        {({ values: { audioSetting } }) => (
          <Box>
            {intro}
            <Spacer direction='vertical' space='m' fluid>
              <GridLayout pt='m' rowGap='m'>
                {toggleLabel && (
                  <GridItem xs={12} data-test='toggle-field'>
                    <ToggleField name='enabled' label={toggleLabel} size='small' />
                  </GridItem>
                )}
                <GridItem xs={6}>
                  <TypeField />
                </GridItem>
              </GridLayout>
              {renderAudioSettings(audioSetting)}
            </Spacer>
          </Box>
        )}
      </ModalForm>
    );
  }
);
