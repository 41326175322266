import { gql } from '@apollo/client';

export const SEARCH_LINE_BY_ID_QUERY = gql`
  query SearchLineByIDQuery($ID: Int!) {
    searchLines(filter: { ID: { eq: $ID } }) {
      items {
        ID
        capabilities
        countryISOCode
        numberUUID
        phoneNumber
        provider
      }
    }
  }
`;
