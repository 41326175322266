import { AdminRoutesWrapperProps } from './AdminRoutesWrapper.decl';

import { TermsOfUseConditionalAccess } from '@components/TermsOfUse';
import { ASSETS_PAGE_ROUTE } from '@constants/regex.constants';
import { Loading } from '@dashboard/library';
import { GetSignupInformation } from '@generated/GetSignupInformation';
import { SIGNUP_INFORMATION_QUERY } from '@graphql/queries/SignupInformationQuery';
import { useInitGainsight } from '@hooks/useInitGainsight/useInitGainsight';
import { useInitTracker } from '@hooks/useInitTracker/useInitTracker';
import { useLanguageUpdater } from '@hooks/useLanguageUpdater';
import { useOnboardingRedirect } from '@hooks/useOnboardingRedirect/useOnboardingRedirect';
import { usePathnameMatch } from '@hooks/usePathnameMatch';
import { useGraphQuery } from '@hooks/useQuery';
import { useTrackPage } from '@hooks/useTrackPage/useTrackPage';

export function AdminRoutesWrapper({ children: routes }: AdminRoutesWrapperProps) {
  const skip = usePathnameMatch([ASSETS_PAGE_ROUTE]);
  const { data, loading } = useGraphQuery<GetSignupInformation>(SIGNUP_INFORMATION_QUERY, {
    /* We silent errors since we have a fallback if the company doesn't have any signup information */
    throwError: false,
    skip,
  });

  useInitTracker();
  useLanguageUpdater();
  useTrackPage();
  useInitGainsight();
  useOnboardingRedirect();

  if (loading) {
    return <Loading data-test='routes-container-loading' />;
  }

  return (
    <TermsOfUseConditionalAccess signupCountry={data?.getSignupInformation.country}>
      {routes}
    </TermsOfUseConditionalAccess>
  );
}
