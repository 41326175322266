import { OutboundCallUsers } from './OutboundCallUsers';

import { PaperBoundary } from '@components/PaperBoundary/PaperBoundary';
import { useTranslation } from 'react-i18next';

export function OutboundCallUsersSection() {
  const { t } = useTranslation();

  return (
    <PaperBoundary
      title={t('number_details.teams_users.outbound_call_users.title')}
      subtitle={t('number_details.teams_users.outbound_call_users.subtitle')}
    >
      <OutboundCallUsers />
    </PaperBoundary>
  );
}
