import { useMemo } from 'react';

import { TimeSelectProps } from './TimeSelect.decl';

import { Select } from '@aircall/tractor-v2';

export function TimeSelect({
  'data-test': dataTest = 'time-select',
  value = 0,
  from = 0, // 00:00
  to = 1440, // 24:00
  onChange,
}: TimeSelectProps) {
  const onTimeSelected = (keys: number[]) => {
    onChange(keys?.[0]);
  };
  const options = useMemo(
    () =>
      [...new Array(49)].reduce((acc, _, i) => {
        const totalMinutes = from + i * 30;

        if (totalMinutes > to) {
          return acc;
        }

        const minutes = (totalMinutes % 60).toString().padStart(2, '0');
        const hours = Math.floor(totalMinutes / 60)
          .toString()
          .padStart(2, '0');
        const label = `${hours}:${minutes}`;
        return [...acc, { value: totalMinutes, label }];
      }, []),
    [from, to]
  );

  return (
    <Select
      data-test={dataTest}
      selectedKeys={[value]}
      options={options}
      onSelectionChange={onTimeSelected}
    />
  );
}
