import { gql } from '@apollo/client';

export const TAGS_LIST_QUERY = gql`
  query TagsListQuery {
    getCompany {
      ID
      tags {
        id
        name
        color
        description
      }
    }
  }
`;
