import { useCallback } from 'react';

import { UseAddBlockedNumberReturn } from './useAddBlockedNumber.decl';

import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import {
  AddBlacklistedNumberMutation,
  AddBlacklistedNumberMutationVariables,
} from '@generated/AddBlacklistedNumberMutation';
import { BlacklistedNumbersQuery } from '@generated/BlacklistedNumbersQuery';
import { BlacklistedNumberInput } from '@generated/globalTypes';
import { ADD_BLACKLISTED_NUMBER_MUTATION } from '@graphql/mutations/AddBlacklistedNumberMutation';
import { BLACKLISTED_NUMBERS_QUERY } from '@graphql/queries/BlacklistedNumbersQuery';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useTracker } from '@hooks/useTracker';
import { BLACKLIST_NUMBERS_ORDER } from '@pages/calls-blocked-numbers/BlockedNumbersSection/BlockedNumbers.section';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to add new number to the blocked number company list.
 * @returns an object with the mutation to add a new blocked number.
 */
export function useAddBlockedNumber(): UseAddBlockedNumberReturn {
  const { t } = useTranslation();
  const { track } = useTracker();

  const [addBlacklistedNumber] = useGraphMutationWithFeedback<
    AddBlacklistedNumberMutation,
    AddBlacklistedNumberMutationVariables
  >(ADD_BLACKLISTED_NUMBER_MUTATION, {
    type: 'snackbar',
  });

  /**
   * Function add a new blocked number.
   * @param phoneNumber - phone number to block.
   */
  const addBlockedNumber = useCallback(
    async (phoneNumber: BlacklistedNumberInput) => {
      const { error } = await addBlacklistedNumber(
        {
          variables: {
            input: {
              blacklistedPhoneNumber: phoneNumber,
            },
          },
          update(cache, result) {
            const blackListedNumbersCache = cache.readQuery<BlacklistedNumbersQuery>({
              query: BLACKLISTED_NUMBERS_QUERY,
              variables: {
                order: BLACKLIST_NUMBERS_ORDER,
              },
            });

            // Safeguard, it should never happen
            /* istanbul ignore next */
            if (!blackListedNumbersCache) {
              return;
            }

            const { blacklistedNumbers } = blackListedNumbersCache;

            const newBlacklistedNumber = result.data?.addBlacklistedNumber;

            // Safeguard, it should never happen
            /* istanbul ignore next */
            if (!newBlacklistedNumber) {
              return;
            }

            cache.writeQuery<BlacklistedNumbersQuery>({
              query: BLACKLISTED_NUMBERS_QUERY,
              variables: {
                order: 'desc',
              },
              data: {
                blacklistedNumbers: {
                  ...blacklistedNumbers,
                  blacklistedPhoneNumbers: [
                    newBlacklistedNumber,
                    ...blacklistedNumbers.blacklistedPhoneNumbers,
                  ],
                },
              },
            });
          },
        },
        {
          successMessage: t(SUCCESS_FEEDBACK_MESSAGES.CREATION_SUCCESS, {
            name: t('calls.pages.blocked_numbers.number'),
          }),
        }
      );

      if (!error) {
        track({ event: 'number_blocked' });
      }

      return error;
    },
    [addBlacklistedNumber, t, track]
  );

  return { addBlockedNumber };
}
