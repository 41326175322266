export const VALIDATE_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALIDATE_PASSWORD_LENGTH_REGEX = /(?=.{8,})/;
// eslint-disable-next-line prefer-regex-literals
export const VALIDATE_PASSWORD_STRENGTH_REGEX =
  /^(?!\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()+?\-"!@#%&/,><':;|_~`\\"'])[\^$*.[\]{}()+?\-"!@#%&/,><':;|_~`\u0020a-zA-Z0-9\\"']{7,98}[^-\s]$/;
export const VALIDATE_URL_FORMAT_REGEX =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w~:/?#[\]@!$&'()*+,;=.]+$/;
export const VALIDATE_NUMBERS_ONLY_REGEX = /^\d*$/;
export const VALIDATE_PERCENTAGE_ONLY_REGEX = /^\d*%$/;
export const MATCH_NON_DIGITS_REGEX = /\D+/g;

export const STRIP_COUNTRY_FROM_LANGUAGE = /[-_][a-z]+$/i;
export const MATCH_HTML_ELEMENTS = /<\/?[a-z][\s\S]*>/i;
export const HTTPS_URL_PREFIX = /^https?:\/\/?/;

export const ASSETS_PAGE_ROUTE = /\/calls\/\d+\/\w+/;
