import { Typography } from '@aircall/tractor-v2';
import { IntegrationFlowPage } from '@components/IntegrationFlowPage/IntegrationFlowPage';
import { ExternalLink } from '@components/Link';
import { SALESFORCE_ENVS } from '@constants/integrations.constants';
import {
  GridItem,
  GridLayout,
  Paper,
  SelectField,
  capitalizeFirstLetter,
} from '@dashboard/library';
import { ApplicationsListQuery_getApplications } from '@generated/ApplicationsListQuery';
import { Form as RFForm } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';

export interface EnvironmentFormValues {
  environment: string;
}

export interface IntegrationFlowSelectEnvironmentProps {
  application: ApplicationsListQuery_getApplications;
  onSubmit: (values: EnvironmentFormValues) => void;
  onCancel: () => void;
}

export function IntegrationFlowSelectEnvironment({
  application,
  onSubmit,
  onCancel,
}: IntegrationFlowSelectEnvironmentProps) {
  const { t } = useTranslation();
  const { name, logo, shortName: applicationName } = application;
  const capApplicationName = capitalizeFirstLetter(name);

  const environmentsOptions = [
    {
      label: t('integration_flow.select_environment.production'),
      value: SALESFORCE_ENVS.PRODUCTION,
    },
    {
      label: t('integration_flow.select_environment.sandbox'),
      value: SALESFORCE_ENVS.SANDBOX,
    },
  ];

  return (
    <RFForm<EnvironmentFormValues>
      onSubmit={onSubmit}
      initialValues={{
        environment: SALESFORCE_ENVS.PRODUCTION,
      }}
    >
      {({ handleSubmit }) => (
        <IntegrationFlowPage
          data-test='integration-flow-select-environment'
          title={capitalizeFirstLetter(name)}
          paperTitle={t('integration_flow.select_environment.choose_environment')}
          paperSubtitle={
            <Typography variant='bodyMediumS'>
              <Trans
                i18nKey='integration_flow.credentials.how_to'
                values={{
                  applicationName: capApplicationName,
                }}
              >
                <ExternalLink
                  href={t(`integration_flow.help_links.how_to_url_${applicationName}`)}
                  target='_blank'
                />
              </Trans>
            </Typography>
          }
          avatarSrc={logo}
          hasFooter
          submitButtonText={t('integration_flow.select_environment.connect_to', {
            applicationName: name,
          })}
          onSubmit={handleSubmit}
          cancelButtonText={t('integration_flow.select_environment.back')}
          onCancel={onCancel}
        >
          <Paper fluid>
            <GridLayout rowGap='m'>
              <GridItem gridRow={1} xs={6}>
                <SelectField
                  t={t}
                  name='environment'
                  label={t('integration_flow.select_environment.environment')}
                  size='regular'
                  options={environmentsOptions}
                  data-test='environment-select'
                />
              </GridItem>
            </GridLayout>
          </Paper>
        </IntegrationFlowPage>
      )}
    </RFForm>
  );
}
