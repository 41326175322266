import { gql } from '@apollo/client';

export const UPDATE_ONBOARDING_METADATA_MUTATION = gql`
  mutation SetMetadataMutation($key: MetadataTypeBoolean!, $value: Boolean!) {
    setMetadata(metadataInput: { key: $key, value: $value }) {
      ... on Onboarding {
        __typename
        metadata {
          ... on MetadataKeyValuePairBoolean {
            __typename
            key
            value
          }
        }
      }
    }
  }
`;
