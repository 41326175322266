import { useContext } from 'react';

import { ReportPageContextProps } from './Report.decl';
import { ReportPageContext } from './ReportPageProvider';

export function useReportPage(): ReportPageContextProps {
  const context = useContext(ReportPageContext);

  if (!context) {
    throw new Error('useReportPage should be used within a ReportPageProvider');
  }

  return context;
}
