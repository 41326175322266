import { gql } from '@apollo/client';

export const SEARCH_LINES_QUERY = gql`
  query SearchLinesQuery(
    $from: Int
    $limit: Int
    $filter: SearchableLinesFilterInput
    $sort: [SearchableLinesSortInput]
  ) {
    searchLines(from: $from, limit: $limit, filter: $filter, sort: $sort) {
      items {
        activationState
        agentsCounter
        capabilities
        countryISOCode
        flowEditorEnabled
        ID
        isIVR
        name
        numberUUID
        phoneNumber
        state
        verificationState
      }
      total
    }
  }
`;
