import { gql } from '@apollo/client';

export const CREATE_SLACK_INTEGRATION_MUTATION = gql`
  mutation CreateSlackIntegrationMutation(
    $input: CreateSlackIntegrationMutationInput!
    $fieldNameDenormalizer: FunctionType
  ) {
    createRestSlackIntegration(input: $input)
      @rest(
        type: "CreateSlackIntegrationMutationResponse"
        path: "v3/oauth_installs/SlackWebhook.json"
        method: "POST"
        fieldNameDenormalizer: $fieldNameDenormalizer
      ) {
      authorizeUri
    }
  }
`;
