import { gql } from '@apollo/client';

export const ADD_OUTBOUND_CALLER_MUTATION = gql`
  mutation AddOutboundCallerMutation($numberId: String!, $input: AddOutboundCallerMutationInput!) {
    addOutboundCaller(numberId: $numberId, input: $input)
      @rest(
        type: "Void"
        path: "numbers/{args.numberId}/supervisions"
        method: "POST"
        endpoint: "v4"
      ) {
      data {
        id
      }
    }
  }
`;
