import { gql } from '@apollo/client';

export const ADD_NUMBER_INTEGRATIONS = gql`
  mutation AddNumberIntegration(
    $companyId: Int!
    $numberId: Int!
    $bridgeIntegrationsId: AddNumberIntegrationInput!
    $hubIntegrationsId: AddNumberIntegrationInput!
    $skipBridgeIntegrations: Boolean!
    $skipHubIntegrations: Boolean!
  ) {
    addBridgeIntegrations(input: $bridgeIntegrationsId, companyId: $companyId, numberId: $numberId)
      @skip(if: $skipBridgeIntegrations)
      @rest(
        type: "AddBridgeIntegrationResponse"
        path: "bridge/v2/companies/{args.companyId}/numbers/{args.numberId}/integrations?no_version=true"
        method: "post"
        endpoint: "v3"
      ) {
      id
    }
    addHubIntegrations(input: $hubIntegrationsId, companyId: $companyId, numberId: $numberId)
      @skip(if: $skipHubIntegrations)
      @rest(
        type: "AddHubIntegrationResponse"
        path: "hub/companies/{args.companyId}/numbers/{args.numberId}/integrations"
        method: "post"
        endpoint: "v3"
      ) {
      integrationId
    }
  }
`;
