import React from 'react';

import { Avatar, AvatarProps } from '@dashboard/library';

type UserAvatarProps = AvatarProps & {
  id?: string;
};

export const UserAvatar = React.memo(({ id, ...avatarProps }: UserAvatarProps) => {
  const dataTest = id ? `user-avatar-${id}` : 'user-avatar';

  return <Avatar variant='default' data-test={dataTest} {...avatarProps} />;
});
