import React from 'react';

import { HeaderContent, HeaderContentV2 } from './components';

import { ONBOARDING_ROUTE } from '@constants/routes.constants';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { matchPath, useLocation } from 'react-router';

const Header = React.memo(() => {
  const { showOnboardingTrial } = useFeatures();
  const location = useLocation();
  return showOnboardingTrial && matchPath(location.pathname, ONBOARDING_ROUTE) ? (
    <HeaderContentV2 />
  ) : (
    <HeaderContent />
  );
});

export default Header;
