import { useEffect } from 'react';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

/**
 * These properties are used by GTM for tracking - DO NOT DELETE THEM
 * For more detail please refer to the data team
 */
export interface UseGTMProps {
  companyId: number | undefined;
  userId: string | undefined;
}

/**
 * This hook is a wrapper of useGTMDispatch hook.
 * The main goal of this wrapper is to ensure that we will not delete
 * mandatory properties in the future.
 * @param datas - datas used by GTM
 */
export const useGTM = ({ companyId, userId }: UseGTMProps): void => {
  const updateGTM = useGTMDispatch();

  useEffect(() => {
    updateGTM({
      companyId,
      userId,
    });
  }, [companyId, updateGTM, userId]);
};
