/* eslint-disable @typescript-eslint/no-explicit-any */
type Func<TArgs = any, KReturn = any | void> = (...args: TArgs[]) => KReturn;

/**
 * Chains functions from left to right.
 * @param fns - unlimited number of functions.
 * @returns a function receiving a value which will be wrapped by all the given functions.
 *
 * ## Example
 * ```typescript
 * const sum = (b: number) => (a: number): number => a + b;
 * const multiply = (b: number) =>(a: number): number => a * b;
 *
 * const piped = pipe(sum(3), multiply(2)) // -> (...args) => multiply(2)(sum(3)(...args))
 * piped(2) // -> 10
 * ```
 */
export function pipe(...fns: Func[]) {
  return (...args: any[]): any => fns.slice(1).reduce((acc, fn) => fn(acc), fns[0](...args));
}

/**
 * Chains functions from right to left.
 * @param fns - unlimited number of functions.
 * @returns a function receiving a value which will be wrapped by all the given functions.
 */
export function compose(...fns: Func[]): any {
  /** Needed to spread the copy, since reverse mutate the original array */
  return pipe(...[...fns].reverse());
}
