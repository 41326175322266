import React, { useCallback } from 'react';

import { AudioPlaybackRow } from '../AudioSetting/AudioPlaybackRow';
import { TRANSLATION_PREFIX } from '../config';
import { DiagramBranch, DiagramCard } from '../FlowDiagram';
import { GroupSectionTitle } from '../GroupSectionTitle';

import { HangUpFilled } from '@aircall/icons';
import { Grid, Toggle, Tooltip, Typography } from '@aircall/tractor-v2';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { NumberDetailQuery_numberDetail } from '@generated/NumberDetailQuery';
import { useUpdateNumberToggles } from '@pages/number-detail-settings/hooks/useUpdateNumberToggles';
import { useTranslation } from 'react-i18next';

export interface UnansweredGroupSectionProps {
  numberDetail: NumberDetailQuery_numberDetail;
}

export function UnansweredGroupSection({ numberDetail }: UnansweredGroupSectionProps) {
  const { t } = useTranslation();
  const { missedCallMessage, missedCallVoicemail } = numberDetail;
  const { updateNumberToggles } = useUpdateNumberToggles({});

  const handleChangeVoicemail = useCallback(() => {
    const newMissedCallVoicemail = !missedCallVoicemail;
    updateNumberToggles(
      { missedCallVoicemail: newMissedCallVoicemail },
      {
        successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
          name: t(`${TRANSLATION_PREFIX}.unanswer_group_section.voicemail_option`),
        }),
      }
    );
  }, [updateNumberToggles, missedCallVoicemail, t]);

  return (
    <>
      <DiagramBranch mt={48} mb='m' branchOffsetTop='56px'>
        <GroupSectionTitle
          title={t(`${TRANSLATION_PREFIX}.unanswer_group_section.title`)}
          icon={HangUpFilled}
        />
        <DiagramCard mt='xxs'>
          <AudioPlaybackRow
            name='missedCallMessage'
            title={t(`${TRANSLATION_PREFIX}.unanswer_group_section.unanswered_call_message`)}
            url={missedCallMessage}
            data-test='unanswered-call-message'
          />
        </DiagramCard>
      </DiagramBranch>
      <DiagramBranch>
        <DiagramCard>
          <Grid columnGap='s' gridTemplateColumns='auto 1fr' alignItems='center' padding='xs'>
            <Toggle
              data-test='unanswered-call-toggle'
              checked={missedCallVoicemail}
              onChange={handleChangeVoicemail}
              size='small'
            />
            <Tooltip
              title={t(`${TRANSLATION_PREFIX}.unanswer_group_section.voicemail_tooltip`)}
              position='bottom'
            >
              {/* The padding and width are used to increase the hoverable area  */}
              <Typography cursor='pointer' pl='6px' py='2px' w='96px'>
                {t(`${TRANSLATION_PREFIX}.unanswer_group_section.voicemail`)}
              </Typography>
            </Tooltip>
          </Grid>
        </DiagramCard>
      </DiagramBranch>
    </>
  );
}
