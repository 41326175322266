import { useCallback, useState } from 'react';

import { SearchMenuItemProps } from './SearchBar.decl';

import { HistoryOutlined } from '@aircall/icons';
import { Box, MenuItem, MenuItemSuffix, Spacer, Typography } from '@aircall/tractor-v2';
import { useTranslation } from 'react-i18next';

export function SearchMenuItem({ searchStr, onSelected, width }: SearchMenuItemProps) {
  const { t } = useTranslation();

  const [hovering, setHovering] = useState(false);
  const onMouseEnter = useCallback(() => {
    setHovering(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setHovering(false);
  }, []);

  const onClick = useCallback(() => {
    onSelected(searchStr);
  }, [onSelected, searchStr]);

  return (
    <Box onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <MenuItem onClick={onClick} itemKey={searchStr}>
        <Spacer space='xs' direction='horizontal' alignItems='center'>
          <HistoryOutlined />
          <Typography maxWidth={width} ellipsis>
            {searchStr}
          </Typography>
        </Spacer>
        <MenuItemSuffix>
          {hovering && <Box color='neutral-700'>{t('users.search.menu.go_to_page_button')}</Box>}
        </MenuItemSuffix>
      </MenuItem>
    </Box>
  );
}
