import React, { Suspense } from 'react';

import { DynamicDataLoaderProps } from './PhoneInput.decl';

import { useTranslation } from 'react-i18next';

const PhoneInput = React.lazy(() =>
  import('./DynamicDataLoader').then(({ DynamicDataLoader }) => ({ default: DynamicDataLoader }))
);

export function WrappedPhoneInput(props: DynamicDataLoaderProps) {
  const { t } = useTranslation();

  return (
    <Suspense fallback={<>{t('generic.loading')}...</>}>
      <PhoneInput {...props} />
    </Suspense>
  );
}
