import { useEffect, useMemo, useState } from 'react';

import { SearchableSelectTeamOption } from '@components/SearchableSelectTeamItem/SearchableSelectTeamItem.decl';
import { DEFAULT_TEAMS_SORT } from '@constants/generic.constants';
import { useGraphQuery, getInitials } from '@dashboard/library';
import {
  SearchTeamsQuery,
  SearchTeamsQuery_searchTeams_items,
  SearchTeamsQueryVariables,
} from '@generated/SearchTeamsQuery';
import { SEARCH_TEAMS_QUERY } from '@graphql/queries/SearchTeamsQuery';
import { getSearchTeamsFilters } from '@helpers/search.helpers';

function mapTeam({
  ID,
  name: label,
  imageName,
}: SearchTeamsQuery_searchTeams_items): SearchableSelectTeamOption {
  return { value: ID, label, imageName, initials: getInitials(label, '') };
}

export type UseSearchableSelectTeamParam = {
  idsBlacklist?: Set<string>;
  isSelectMounted?: boolean;
};
export function useSearchableSelectTeam({
  idsBlacklist,
  isSelectMounted = true,
}: UseSearchableSelectTeamParam) {
  const [query, setQuery] = useState('');

  const { data, loading } = useGraphQuery<SearchTeamsQuery, SearchTeamsQueryVariables>(
    SEARCH_TEAMS_QUERY,
    {
      variables: {
        filter: getSearchTeamsFilters(query),
        sort: DEFAULT_TEAMS_SORT,
      },
      skip: !isSelectMounted,
    }
  );
  useEffect(() => {
    if (!isSelectMounted) {
      // reset the query when the select is unmounted
      setQuery('');
    }
  }, [isSelectMounted]);

  const teams = useMemo(() => data?.searchTeams?.items ?? [], [data?.searchTeams?.items]);

  const options = useMemo(
    () => teams.filter((i) => !idsBlacklist?.has(i.ID)).map(mapTeam),
    [teams, idsBlacklist]
  );

  const resultsCount = options.length;

  return { teams, setQuery, loading, options, resultsCount };
}
