/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */

import { LOGGER_ENVIRONMENT, LOGGER_LEVEL } from '@aircall/logger';
import { logger } from '@config/logger.config';
import { APP_CONFIG } from '@constants/environment.constants';
import {
  LOGGING_EVENTS,
  LOGGING_FEATURE_NAMES,
  LOGGING_STATUS,
} from '@constants/logging.constants';
import { LogsEvent } from '@datadog/browser-logs';

const ALLOWED_ENVIRONMENTS = ['production', 'staging'];

/**
 * Callback function to configure each log before being logged
 * @param log - LogEvent defined by DataDog
 */
export function beforeSendListener(log: LogsEvent): void {
  log.version = APP_CONFIG.release;
  log.environment = APP_CONFIG.environment as LOGGER_ENVIRONMENT;
}

/**
 * Initialize the logger with the correct configuration.
 */
export function initLogger(): void {
  if (ALLOWED_ENVIRONMENTS.includes(APP_CONFIG.environment) && !logger.initialized) {
    logger.init({
      beforeSend: beforeSendListener,
    });
    logger.setLevel(LOGGER_LEVEL.info);
    logger.setContext({
      service: 'dashboard-v4',
      user_session: {
        device: 'dashboard',
        environment: APP_CONFIG.environment as LOGGER_ENVIRONMENT,
        release: APP_CONFIG.release,
      },
      user: {},
    });
  }
}

/**
 * Set the logger context with the current user session.
 * @param userSession - The user session containing the user id, company id, etc.
 */
export function setLoggerContext(userSession: object, extraContext?: object): void {
  if (logger.initialized) {
    logger.setContext({
      service: 'dashboard-v4',
      user_session: {
        ...userSession,
        ...APP_CONFIG,
      },
      ...extraContext,
    });
  }
}

/**
 * Remove the user session from the logger context.
 */
export function clearLoggerContext(): void {
  if (logger.initialized) {
    logger.setContext({
      service: 'dashboard-v4',
      user_session: {
        device: 'dashboard',
        environment: process.env.REACT_APP_BUILD_ENV as LOGGER_ENVIRONMENT,
        release: APP_CONFIG.release,
      },
    });
  }
}

export interface CreateLogParams {
  /** The logging status (SUCCESS | FAILED | INITIATED). */
  status: LOGGING_STATUS;
  /** The feature name (AUTHENTICATION). */
  featureName: LOGGING_FEATURE_NAMES;
  /** The logging event (SIGN_IN | SIGN_OUT | SESSION_EXPIRED | REFRESH_TOKEN). */
  event: LOGGING_EVENTS;
  /** The properties sent to the logger. */
  properties?: Record<string, unknown>;
}

const createLogMessage = (...params: string[]) => params.join(' | ');

/**
 * Create a log message and send it to the logger with the given parameters.
 * @param CreateLogParams - An object containing the parameters needed to create the log message.
 */
export function createLog({ featureName, event, status, properties = {} }: CreateLogParams): void {
  const logMessage = createLogMessage(featureName, event, status);

  if (status === LOGGING_STATUS.SUCCESS || status === LOGGING_STATUS.INITIATED) {
    logger.info(logMessage, properties);
  }

  if (status === LOGGING_STATUS.FAILED) {
    logger.error(logMessage, properties);
  }
}
