import { Report } from './Report';
import { ReportPageProvider } from './ReportPageProvider';

import { SearchProvider } from '@components/SearchProvider/SearchProvider';
import { AnalyticsDashboardType } from '@generated/globalTypes';

export interface ReportPageProps {
  type: AnalyticsDashboardType;
  title: string;
  onTruncated?: (truncated: boolean) => void;
}

export function ReportPage({ onTruncated, ...otherProps }: ReportPageProps) {
  return (
    <SearchProvider>
      <ReportPageProvider onTruncated={onTruncated}>
        <Report {...otherProps} />
      </ReportPageProvider>
    </SearchProvider>
  );
}
