import { AIRCALL_GRAPHQL_API, IS_DEVELOPMENT } from '../../constants/environment.constants';

import { authGraphqlLink, authRestLink } from './authLink.config';
import { createCache } from './cache.config';
import { errorLink } from './errorLink.config';
import { loggingLink } from './loggerlink.config';
import { restLink } from './restLink.config';
import { retryLink } from './retryLink.config';

import { ApolloClient, NormalizedCacheObject, createHttpLink, ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { COOKIE_KEYS } from '@constants/auth.constants';
import { RefreshToken } from '@state/app/authentication/authentication.decl';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { AppSyncRealTimeSubscriptionConfig } from 'aws-appsync-subscription-link/lib/types';
import Cookies from 'js-cookie';

interface OperationNode {
  kind: string;
  operation?: string;
}

const graphqlConfig: AppSyncRealTimeSubscriptionConfig = {
  url: AIRCALL_GRAPHQL_API,
  region: 'us-west-2',
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: () => Cookies.get(COOKIE_KEYS.TOKEN)!,
  },
};

const httpLink = createHttpLink({
  uri: AIRCALL_GRAPHQL_API,
});

/* istanbul ignore next */
const operationAsRequestParamMiddleware = new ApolloLink((operation, forward) => {
  const operationNode: OperationNode = getMainDefinition(operation.query);
  const operationType = operationNode ? operationNode.operation : 'unknownOperation';

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
    },
    uri: `${AIRCALL_GRAPHQL_API}?${operationType}=${operation.operationName}`,
  }));

  return forward(operation);
});

export const createClient = (
  logoutHandler: VoidFunction,
  refreshTokenHandler: RefreshToken
): ApolloClient<NormalizedCacheObject> => {
  /**
   *
   * Link order is important here!
   *
   * We are using multiple link types here, restLink should go before httpLink,
   * as httpLink will swallow any calls that should be routed through apollo-link-rest.
   *
   * Httplink and restlink, as terminating links, should go after context and error links
   * @see https://www.apollographql.com/docs/link/links/rest/#link-order
   *
   */

  const links: ApolloLink[] = [
    // In the following errorLink, we handle errors of both rest api and gql api
    operationAsRequestParamMiddleware,
    retryLink,
    errorLink(logoutHandler, refreshTokenHandler),
    loggingLink,
    authRestLink, // set auth header for rest api
    restLink,
    authGraphqlLink, // set auth header for gql api
    /**
     * Return a split link that enable us to use
     * - an httpLink for graphql queries and mutations.
     * - a wsLink for graphql subscriptions.
     */
    createSubscriptionHandshakeLink(graphqlConfig, httpLink),
  ];

  const link = ApolloLink.from(links);

  return new ApolloClient({
    cache: createCache(),
    link,
    connectToDevTools: IS_DEVELOPMENT,
  });
};
