import React from 'react';

import { OnboardingDownloadSection } from './OnboardingDownloadSection';

import { Box, Flex } from '@aircall/tractor-v2';

import { ResourceLinks } from '.';

export function ResourcesSection() {
  return (
    <Flex
      flexDirection={{
        _: 'column',
        lg: 'row',
        xl: 'column',
      }}
      w={{
        xs: '100%',
        xl: '360px',
      }}
      as='section'
      data-test='resource-section'
    >
      <Box
        w='100%'
        marginRight={{
          _: '0',
          lg: 'm',
          xl: '0',
        }}
      >
        <OnboardingDownloadSection />
      </Box>
      <Box w='100%' mt={{ _: '0', xl: 'm' }}>
        <ResourceLinks />
      </Box>
    </Flex>
  );
}
