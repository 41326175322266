import { useEffect } from 'react';

import { ResizeObserverOptions } from './useResizeObserver.decl';

import noop from 'lodash-es/noop';

/**
 * A hook to observe change of the size of a HTML element and trigger onResize event
 * @param ref - The ref of HTML Element to be observed
 * @param onResize - event triggered on window resize
 */
export const useResizeObserver = ({ ref, onResize }: ResizeObserverOptions): void => {
  useEffect(() => {
    const element = ref?.current;

    if (!element) {
      return noop;
    }

    // checks if the browser support ResizeObserver
    if (typeof window.ResizeObserver === 'undefined') {
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }

    const resizeObserverInstance = new window.ResizeObserver((entries: unknown[]) => {
      if (!entries.length) {
        return;
      }

      onResize();
    });
    resizeObserverInstance.observe(element);

    return () => {
      resizeObserverInstance.unobserve(element);
    };
  }, [onResize, ref]);
};
