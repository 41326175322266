/* stylelint-disable */

import { MouseEventHandler } from 'react';

import styled, { css } from 'styled-components';

import { NavItemProps, NavLinkProps } from './NavItem.decl';

import { LockedOutlined, LockedFilled } from '@aircall/icons';
import {
  Box,
  Tag,
  TagLabel,
  Icon,
  Typography,
  useTheme,
  Flex,
  Tooltip,
  getColor,
  getSpace,
} from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { useTracker } from '@hooks/useTracker/useTracker';
import noop from 'lodash-es/noop';
import { useTranslation } from 'react-i18next';
import { NavLink as RRNavLink, useLocation } from 'react-router-dom';

const NavActiveBlock = styled.div`
  position: absolute;
  top: 9px;
  right: 0;
  bottom: 9px;
  width: 3px;
  border-radius: 100px 0 0 100px;
  background: ${getColor('neutral-1000')};
  opacity: 0;
  transition: all 0.25s ease;
`;

function CustomNavItem({
  exact,
  hasParent: _hasParent,
  bgColor: _bgColor,
  selectedBgColor: _selectedBgColor,
  className,
  style: _style,
  isAd: _isAd,
  disabled: _disabled,
  onClick: _onClick,
  ...params
}: NavLinkProps) {
  /**
   * Links don't have a disabled attribute, unlike buttons.
   * Therefore, we need to manually prevent the click event from being processed.
   */
  const preventClick: MouseEventHandler = (event) => {
    if (_disabled) {
      event.preventDefault();
    }
  };

  if (params.type === 'Anchor') {
    return <ExternalLink href={params.to || ''} className={className} {...params} />;
  }

  return (
    <RRNavLink
      className={({ isActive }) => `${className} ${isActive ? 'nav-item-is-active' : ''}`}
      end={exact}
      onClick={(event) => {
        preventClick(event);
        _onClick?.(event);
      }}
      {...params}
    />
  );
}

const NavLink = styled(CustomNavItem)<NavLinkProps>`
  position: relative;
  display: flex;
  width: 100%;
  height: 48px;
  flex-direction: row;
  align-items: center;
  padding: 0;
  padding-right: ${getSpace('xs')};
  padding-left: ${({ hasParent }) => (hasParent ? getSpace('xl') : getSpace('m'))};
  background-color: ${({ bgColor }) => getColor(bgColor)};
  text-decoration: none;
  transition: all 0.25s ease;

  &:hover,
  &:active,
  &:focus,
  &.nav-item-is-active {
    ${NavActiveBlock} {
      opacity: ${({ disabled }) => (disabled ? 0 : 1)};
    }

    > ${Typography}, svg {
      color: ${({ disabled }) => (disabled ? getColor('neutral-200') : getColor('text-base'))};
    }
    background-color: ${({ disabled, selectedBgColor }) =>
      getColor(disabled ? 'neutral-0' : selectedBgColor)};
    text-decoration: none;
  }

  &:active,
  &:focus {
    outline: none;

    > ${Typography}, svg {
      color: ${getColor('neutral-800')};
    }
  }

  ${({ disabled, isAd }) =>
    disabled &&
    !isAd &&
    css`
      cursor: default;
      pointer-events: none;
    `}
`;

export function NavItem({
  iconComponent,
  text,
  to,
  trackerProperties,
  type = 'RRNavLink',
  disabled = false,
  'data-test': dataTest = 'nav-item',
  hasChildren = false,
  hasParent = false,
  isAd = false,
  isBeta = false,
  isNew = false,
  isLocked = false,
  bgColor = 'neutral-0',
  selectedBgColor = 'neutral-200',
  tag,
  indicator,
  onClick,
}: NavItemProps) {
  const { t } = useTranslation();
  const { space } = useTheme();
  const { track } = useTracker();
  const location = useLocation();
  const active = to === location.pathname;
  const isAdOrDisabled = isAd || disabled;

  const handleTrack = () => (trackerProperties ? track(trackerProperties) : noop());

  const handleClick: MouseEventHandler = (event) => {
    handleTrack();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <NavLink
      to={to}
      disabled={isAdOrDisabled}
      data-test={dataTest}
      type={type}
      onClick={handleClick}
      hasParent={hasParent}
      exact={hasParent}
      bgColor={bgColor}
      selectedBgColor={selectedBgColor}
      isAd={isAd}
    >
      {indicator && (
        <Icon
          component={indicator.icon}
          size='6px'
          mr='xxs'
          color={indicator?.color}
          data-test={`${dataTest}-indicator-icon`}
        />
      )}
      <Icon
        component={iconComponent}
        size={space.m}
        color={isAdOrDisabled ? 'text-disabled' : 'text-base'}
        data-test={`${dataTest}-icon`}
      />
      <Typography
        variant='bodyMediumM'
        color={isAdOrDisabled ? 'text-disabled' : 'text-base'}
        ml='xs'
        data-test={`${dataTest}-text`}
      >
        {text}
      </Typography>
      {isBeta && (
        <Box ml='auto'>
          <Tag size='small' variant='green'>
            Beta
          </Tag>
        </Box>
      )}
      {tag?.text && (
        <Box ml='xxs'>
          <Tag size='small' variant={tag?.variant} data-test={`${dataTest}-tag`}>
            <Flex gap={tag?.gap} alignItems='center'>
              {tag?.icon && (
                <Icon component={tag?.icon} size={space.s} data-test={`${dataTest}-tag-icon`} />
              )}
              <TagLabel color={tag?.color}>{t(tag.text)}</TagLabel>
            </Flex>
          </Tag>
        </Box>
      )}

      {isNew && (
        <Box ml='auto'>
          <Tag data-test='nav-item-new-tag' variant='darkPrimary' size='small'>
            {t('generic.new')}
          </Tag>
        </Box>
      )}

      {isLocked ? (
        <Tooltip title={t('permissions.unauthorized_screen.title')} position='right'>
          <Flex data-test='nav-item-lock' marginLeft='auto' marginRight='m'>
            {active ? (
              <LockedFilled data-test='nav-item-lock-filled' color='secondary.base' size={20} />
            ) : (
              <LockedOutlined
                data-test='nav-item-lock-outlined'
                color='secondary.light'
                size={20}
              />
            )}
          </Flex>
        </Tooltip>
      ) : null}

      {isAd ? (
        <Flex data-test='nav-item-lock' marginLeft='auto' marginRight='m'>
          <LockedFilled data-test='nav-item-lock-filled' color='text-disabled' size={20} />
        </Flex>
      ) : null}

      {!hasChildren && !isAd ? <NavActiveBlock data-test={`${dataTest}-active`} /> : null}
    </NavLink>
  );
}
