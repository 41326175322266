/* eslint-disable @typescript-eslint/naming-convention */
import { TrialChecklistPopupItemType } from './TrialChecklistPopup.decl';

import { CallFilled, UserFilled } from '@aircall/icons';
import { ReactComponent as Hubspot } from '@assets/icons/hubspot_icon.svg';
import { StepType } from '@generated/globalTypes';

export const STEP_TYPE_REDIRECT_MAP: Record<StepType, string> = {
  [StepType.LAUNCH_PHONE]: 'https://phone.aircall.io',
  [StepType.MAKE_CALL]: 'https://phone.aircall.io',
  [StepType.SEND_MESSAGE]: 'https://phone.aircall.io',
  [StepType.TEST_INTEGRATION]: '/generic-integration',
};

export const checklistData: TrialChecklistPopupItemType[] = [
  {
    titleKey: 'user_profile.task_checklist.launch_app',
    stepType: StepType.LAUNCH_PHONE,
    icon: UserFilled,
    descriptionKey: 'user_profile.task_checklist.launch_app_desc',
  },
  {
    titleKey: 'user_profile.task_checklist.make_call',
    stepType: StepType.MAKE_CALL,
    icon: CallFilled,
    descriptionKey: 'user_profile.task_checklist.make_call_desc',
  },
  {
    titleKey: 'user_profile.task_checklist.test_integrations',
    stepType: StepType.TEST_INTEGRATION,
    icon: Hubspot,
    descriptionKey: 'user_profile.task_checklist.test_integrations_desc',
  },
];
