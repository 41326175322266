import { useCallback } from 'react';

import { AboutSectionProps } from './About.section.decl';

import { Banner, BannerHeading, BannerIcon, Button, Flex, Typography } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { Gap } from '@dashboard/library';
import { Trans, useTranslation } from 'react-i18next';

export function AboutSection({ isNumberTollFree, translationPrefix }: AboutSectionProps) {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    window.open(t(`${translationPrefix}.button_link`), '_blank');
  }, [t, translationPrefix]);

  return (
    <Gap flexDirection='column' gap='s'>
      <Typography variant='bodySemiboldM'>{t(`${translationPrefix}.title`)}</Typography>
      <Typography variant='bodyRegularS'>{t(`${translationPrefix}.first_paragraph`)}</Typography>
      {isNumberTollFree ? (
        <>
          <Typography variant='bodyRegularS'>
            <Trans i18nKey={`${translationPrefix}.second_paragraph`}>
              <ExternalLink
                href={t(`${translationPrefix}.second_paragraph_link`)}
                target='_blank'
              />
            </Trans>
          </Typography>
          <Banner inline variant='info'>
            <BannerIcon />
            <BannerHeading>{t(`${translationPrefix}.banner`)}</BannerHeading>
          </Banner>
        </>
      ) : (
        <Typography variant='bodyRegularS'>{t(`${translationPrefix}.second_paragraph`)}</Typography>
      )}
      <Flex justifyContent='flex-end'>
        <Button
          mode='outline'
          variant='secondary'
          size='xSmall'
          onClick={handleClick}
          data-test='about-section-button'
        >
          {t(`${translationPrefix}.button_text`)}
        </Button>
      </Flex>
    </Gap>
  );
}
