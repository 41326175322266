import { gql } from '@apollo/client';

export const CREATE_BRIDGE_INTEGRATION_MUTATION = gql`
  mutation CreateBridgeIntegrationMutation($input: CreateBridgeIntegrationMutationInput!) {
    createRestBridgeIntegration(input: $input)
      @rest(type: "RestIntegration", endpoint: "v3", path: "bridge/integrations", method: "POST") {
      id
      active
      customName
      serviceName
      serviceNameUnderscored
      configurations {
        numbers {
          id
        }
      }
    }
  }
`;
