import { useRef, useMemo, useCallback } from 'react';

import styled from 'styled-components';

import { arraymove, findIndex, Position } from '../../helpers/draggable.helper';

import { DraggableItem } from './DraggableItem';
import { DraggableListProps } from './Draggble.decl';

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export function DraggableList<T extends { id: string | number }>({
  items,
  renderItem,
  onChange,
  onDragStart,
  onDragEnd,
  draggable = true,
}: DraggableListProps<T>) {
  const positions = useRef<Position[]>([]).current;
  const setPosition = useCallback(
    (i: number, offset: Position) => {
      positions[i] = offset;
    },
    [positions]
  );

  // Find the ideal index for a dragging item based on its position in the array, and its
  // current drag offset. If it's different to its current index, we swap this item with that
  // sibling.
  // Also reset the itemTranslateY asap when the item is getting moved.
  /* istanbul ignore next */
  const moveItem = useCallback(
    (i: number, dragOffset: number, resetItemTranslateY: () => void) => {
      const targetIndex = findIndex(i, dragOffset, positions);
      if (targetIndex !== i) {
        resetItemTranslateY();
        onChange(arraymove(items, i, targetIndex));
      }
    },
    [items, onChange, positions]
  );
  const total = items.length;

  const itemslist = useMemo(
    () =>
      items.map((item, currentIdx) => (
        <DraggableItem
          draggable={draggable}
          key={item.id}
          index={currentIdx}
          onPositionChange={setPosition}
          onDrag={moveItem}
          onDragStart={onDragStart}
          onDragEnd={/* istanbul ignore next */ (index) => onDragEnd && onDragEnd(item, index)}
        >
          {renderItem({ item, innerProps: { currentIdx, total } })}
        </DraggableItem>
      )),
    [draggable, items, moveItem, onDragStart, onDragEnd, renderItem, setPosition, total]
  );

  return <StyledList>{itemslist}</StyledList>;
}
