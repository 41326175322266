import { gql } from '@apollo/client';

export const INTEGRATION_INSTALL_URI_QUERY = gql`
  query IntegrationInstallUriQuery($applicationId: String!, $hubOldIntegrationId: String) {
    integrationInstallUri(applicationId: $applicationId, hubOldIntegrationId: $hubOldIntegrationId)
      @rest(
        type: "IntegrationInstallUri"
        path: "applications/{args.applicationId}/install_uri?hubOldIntegrationId={args.hubOldIntegrationId}"
        endpoint: "merlin"
      ) {
      current {
        installUri
      }
    }
  }
`;
