import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AudioSettingFormValues,
  AudioSettingModalContainerProps,
  AUDIO_NAME,
  AUDIO_SETTING_TYPE,
  AUDIO_TYPE,
} from './AudioSetting.decl';
import { getNumberAudioSetting, getTtsByName } from './helpers';

import { Typography, Banner, BannerIcon, BannerHeading } from '@aircall/tractor-v2';
import { NumberDetailQuery_numberDetail } from '@generated/NumberDetailQuery';
import { useAudioLibrary } from '@hooks/useAudioLibrary/useAudioLibrary';
import { useNumberDetail } from '@hooks/useNumberDetail';
import { AudioSettingModal } from '@pages/number-detail-call-distribution/AudioSetting/AudioSettingModal';
import { useUpdateAudio } from '@pages/number-detail-call-distribution/hooks/useUpdateAudio';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const translationPrefix = 'number_details.settings.call_distribution_section.audio_setting_modal';

function getAudioSettingIntro(t: TFunction, name: AUDIO_NAME) {
  const intro = t(`${translationPrefix}.intro.${name}`);
  if (name === 'welcomeMessage') {
    return (
      <Banner data-test='intro-banner' variant='info' inline>
        <BannerIcon />
        <BannerHeading>{intro}</BannerHeading>
      </Banner>
    );
  }
  return (
    <Typography variant='bodyMediumS' data-test='intro-text'>
      {intro}
    </Typography>
  );
}

export const availableAudioSettingTypes = {
  music: [AUDIO_SETTING_TYPE.LIBRARY, AUDIO_SETTING_TYPE.FILE],
  message: [AUDIO_SETTING_TYPE.TEXT_TO_SPEECH, AUDIO_SETTING_TYPE.FILE],
};

function getAudioType(name: AUDIO_NAME): AUDIO_TYPE {
  return name === 'ringingTone' || name === 'musicOnHold' ? 'music' : 'message';
}

function getToggleField(
  number: NumberDetailQuery_numberDetail,
  name: AUDIO_NAME,
  t: TFunction
): { value: boolean; label: string } | undefined {
  if (name === 'welcomeMessage') {
    return {
      value: number.welcomeMessageEnabled,
      label: t(`${translationPrefix}.toggle_label.welcomeMessage`),
    };
  }
  if (name === 'missedCallMessage') {
    return {
      value: number.missedCallVoicemail,
      label: t(`${translationPrefix}.toggle_label.missedCallMessage`),
    };
  }
  if (name === 'afterHoursMessage') {
    return {
      value: number.afterHoursVoicemail,
      label: t(`${translationPrefix}.toggle_label.afterHoursMessage`),
    };
  }
  return undefined;
}

/**
 * A container component that loads the audio setting by audio name and pass them to the AudioSettingModal
 */
export const AudioSettingModalContainer = React.memo(
  ({ show, onHide, title, name }: AudioSettingModalContainerProps) => {
    const { t } = useTranslation();
    const { number } = useNumberDetail();
    const { tracks } = useAudioLibrary();

    const audioType = getAudioType(name);
    const intro = getAudioSettingIntro(t, name);
    const availableTypes = availableAudioSettingTypes[audioType];
    const selectLabel = t(`${translationPrefix}.select.${audioType}_label`);

    // check if we need to display a toggle by audio's name
    const toggleField = useMemo(() => getToggleField(number!, name, t), [name, number, t]);

    const [audioValues, setAudioValues] = useState<AudioSettingFormValues>();

    const numberId = number!.id;
    const { updateAudio: onSubmitAudioSettingForm, submitted } = useUpdateAudio({
      numberId,
      name,
      title,
      onCompleted: onHide,
    });

    const defaultTts = useMemo(() => getTtsByName(number!.ttsMessages, name), [name, number]);

    const updateFormInitialValue = useCallback(() => {
      getNumberAudioSetting(number!, name, tracks).then((audioSetting) => {
        setAudioValues({ audioSetting, enabled: toggleField?.value });
      });
    }, [name, number, toggleField?.value, tracks]);

    // initialize audioValues
    useEffect(() => {
      updateFormInitialValue();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      // update the initialValue only after having submitted the form,
      // cause we send 2 requests at submission, and don't want to let user see the intermediate number state
      if (submitted) {
        updateFormInitialValue();
      }
    }, [submitted, updateFormInitialValue]);

    return (
      <AudioSettingModal
        show={show}
        onHide={onHide}
        onSubmit={onSubmitAudioSettingForm}
        title={title}
        availableOptions={availableTypes}
        intro={intro}
        toggleLabel={toggleField?.label}
        optionSelectLabel={selectLabel}
        values={audioValues}
        numberId={number!.id}
        defaultTts={defaultTts}
      />
    );
  }
);
