import { useContext, useEffect, useMemo, useState } from 'react';

import { AudioPlayerContext } from '../components/AudioPlayer/AudioPlayerContext';

export interface UseAudioPlayerReturn {
  /** Start playing / resume sound */
  play: () => void;

  /** Pause sound */
  pause: () => void;

  /** Stop and rewind sound */
  stop: () => void;

  /** Update sound time */
  updateTime: (time: number) => void;

  /** Indicate whether or not the sound is loading */
  isLoading: boolean;

  /** Indicate whether or not the sound is being played */
  isPlaying: boolean;

  /** Provide the duration of the sound */
  duration: number;

  /** Current time of the sound */
  time: number;
}

/**
 * Provide audio player capabilities.
 * @param url - URL of the sound to be played
 * @returns object holding audio player capabilities
 */
export function useAudioPlayer(url: string): UseAudioPlayerReturn {
  const audioPlayerContext = useContext(AudioPlayerContext);
  const [id, setId] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(0);

  if (!audioPlayerContext) {
    throw new Error(
      'Your component must be wrapped by a `AudioPlayerProvider` to be able to use the `useAudioPlayer` hook'
    );
  }

  const { register, unregister, play, pause, stop, updateTime } = audioPlayerContext;

  useEffect(() => {
    setIsLoading(true);
    setTime(0);
    setDuration(0);

    const playerId = register(url, {
      canPlay: (audioDuration) => {
        setIsLoading(false);
        setDuration(audioDuration);
      },
      timeUpdate: (currentTime: number) => {
        setTime(currentTime);
      },
      pause: () => setIsPlaying(false),
    });

    setId(playerId);

    return () => unregister(playerId);
  }, [url, register, unregister, setIsPlaying]);

  const methods = useMemo(
    () => ({
      play: () => {
        play(id);
        setIsPlaying(true);
      },
      pause: () => pause(id),
      stop: () => stop(id),
      updateTime: (currentTime: number) => updateTime(id, currentTime),
    }),
    [play, pause, stop, updateTime, id]
  );

  return {
    ...methods,
    isLoading,
    isPlaying,
    duration,
    time,
  };
}
