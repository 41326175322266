import styled from 'styled-components';

import { Flex, Spacer, Typography, Button, Grid, getColor } from '@aircall/tractor-v2';
import { ReactComponent as NoSupportImage } from '@assets/images/no_support.svg';
import { ExternalLink } from '@components/Link';
import { GridItem } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

const StyledLink = styled(ExternalLink)`
  display: block;
  background-color: ${getColor('primary-500')};

  &:hover {
    text-decoration: none;
  }
`;

const StyledFlex = styled(Flex)`
  height: 100%;
  overflow-y: auto;
`;

const ImageWrapper = styled.div`
  max-width: 512px;

  > svg {
    max-width: 100%;
  }
`;

export function BrowserNotSupported() {
  const { t } = useTranslation();

  return (
    <StyledFlex
      flexDirection='column'
      alignItems='center'
      minHeight='100vh'
      w='100vw'
      backgroundColor='grey.lighter'
      data-test='browser-blocker-wrapper'
    >
      <Spacer direction='vertical' space='l' alignItems='center' m='auto'>
        <ImageWrapper>
          <NoSupportImage />
        </ImageWrapper>

        <Typography variant='headingBoldL' textAlign='center' maxWidth={675}>
          {t('browser_not_supported.title')}
        </Typography>

        <Typography variant='bodyMediumS' textAlign='center' maxWidth={732}>
          {t('browser_not_supported.body')}
        </Typography>

        <Grid gridTemplateColumns='repeat(12, 1fr)' gridAutoFlow='row' alignItems='center'>
          <GridItem xs={12} sm={6}>
            <Button
              as={StyledLink}
              href={t('browser_not_supported.chrome_link')}
              data-test='browser-not-supported-chrome-link'
              target='_blank'
            >
              {t('browser_not_supported.download_chrome')}
            </Button>
          </GridItem>
        </Grid>
      </Spacer>
    </StyledFlex>
  );
}
