import { UseQueryLocalisedContractsReturn } from './useQueryLocalisedContracts.decl';

import { SignupCountry } from '@components/TermsOfUse';
import { IRONCLAD_TC_GROUP_KEY, IRONCLAD_TOU_GROUP_KEY } from '@constants/environment.constants';
import { useGraphQuery } from '@dashboard/library';
import {
  GetLocalisedContractsQuery,
  GetLocalisedContractsQueryVariables,
} from '@generated/GetLocalisedContractsQuery';
import { GET_LOCALISED_CONTRACTS_QUERY } from '@graphql/queries/GetLocalisedContractsQuery';
import { getAvailableCountryCode } from '@helpers/ironclad.helpers';
/**
 * Custom hook to get a localised contracts.
 * @returns an object with the contractIds and versionIds from iron clad.
 */
export const useQueryLocalisedContracts = (
  signupCountry: SignupCountry,
  skip: boolean = false
): UseQueryLocalisedContractsReturn => {
  const country = getAvailableCountryCode(signupCountry);
  const groupKeys = [IRONCLAD_TC_GROUP_KEY, IRONCLAD_TOU_GROUP_KEY];
  const { data, refetch, loading, error } = useGraphQuery<
    GetLocalisedContractsQuery,
    GetLocalisedContractsQueryVariables
  >(GET_LOCALISED_CONTRACTS_QUERY, {
    variables: { country, groupKeys },
    skip,
  });

  const contractIds = data?.getLocalisedContracts?.map(({ ID }) => ID).join(',') || '';
  const versionIds =
    data?.getLocalisedContracts?.map(({ publishedVersion }) => publishedVersion).join(',') || '';

  return {
    data: {
      contractIds,
      versionIds,
    },
    refetch,
    loading,
    error,
  };
};
