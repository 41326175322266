import { ResourceButton } from './ResourceButton';

import { Box, Flex, Spacer, Typography } from '@aircall/tractor-v2';
import { useTranslation } from 'react-i18next';

export function ResourceLinkV2() {
  const { t } = useTranslation();

  return (
    <Spacer direction='vertical' space='s' fluid>
      <Typography variant='headingBoldS'>{t('onboarding.resource.title')}</Typography>
      <Box
        w='100%'
        borderWidth='1px'
        borderStyle='solid'
        borderColor='neutral-200'
        bg='white'
        borderRadius='sm'
        p='s'
        h='auto'
      >
        <Flex flexDirection='column' data-test='resource-links-section' gap='s'>
          <ResourceButton
            heading={t('onboarding.resource.onboarding_portal_name')}
            subHeading={t('onboarding.resource.onboarding_portal_caption')}
            link={t('onboarding.resource.onboarding_portal_link')}
          />
          <ResourceButton
            heading={t('onboarding.resource.knowledge_base_name')}
            subHeading={t('onboarding.resource.knowledge_base_caption')}
            link={t('onboarding.resource.knowledge_base_link')}
          />
          <ResourceButton
            heading={t('onboarding.resource.aircall_webinars_name')}
            subHeading={t('onboarding.resource.aircall_webinars_caption')}
            link={t('onboarding.resource.aircall_webinars_link')}
          />
        </Flex>
      </Box>
    </Spacer>
  );
}
