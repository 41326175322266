import { useCallback } from 'react';

import {
  UseAddOutboundCallUserParams,
  UseAddOutboundCallUserReturn,
} from './useAddOutboundCallUser.decl';

import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { getFullName } from '@dashboard/library';
import {
  AddOutboundCallerMutation,
  AddOutboundCallerMutationVariables,
} from '@generated/AddOutboundCallerMutation';
import { UserMinimalFragment } from '@generated/UserMinimalFragment';
import { ADD_OUTBOUND_CALLER_MUTATION } from '@graphql/mutations/AddOutboundCallerMutation';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useTranslation } from 'react-i18next';

export function useAddOutboundCallUser({
  numberId,
}: UseAddOutboundCallUserParams): UseAddOutboundCallUserReturn {
  const { t } = useTranslation();
  const [mutate] = useGraphMutationWithFeedback<
    AddOutboundCallerMutation,
    AddOutboundCallerMutationVariables
  >(ADD_OUTBOUND_CALLER_MUTATION, {
    type: 'snackbar',
  });

  const addOutboundCallUser = useCallback(
    async ({ userId, agents }: { userId: string; agents: UserMinimalFragment[] }) => {
      const {
        ID,
        pictureURL: pictureUrl,
        email,
        firstName,
        lastName,
      } = agents.find((a) => a.ID === userId)!;

      const { error } = await mutate(
        {
          variables: {
            input: {
              data: {
                type: 'supervision',
                relationships: {
                  user: {
                    data: {
                      type: 'user',
                      id: userId,
                    },
                  },
                },
              },
            },
            numberId,
          },
          update(cache, { data }) {
            const {
              addOutboundCaller: {
                data: { id: supervisionId },
              },
            } = data!;

            cache.modify({
              id: `Number:${numberId}`,
              fields: {
                supervisions(supervisionsCache) {
                  return [
                    ...supervisionsCache,
                    {
                      id: supervisionId,
                      user: {
                        id: ID,
                        email,
                        firstName,
                        lastName,
                        pictureUrl,
                      },
                    },
                  ];
                },
              },
            });
          },
          optimisticResponse: {
            addOutboundCaller: {
              __typename: 'AddOutboundCallerMutationResponse',
              data: {
                __typename: 'AddOutboundCallerData',
                id: '',
              },
            },
          },
        },
        {
          successMessage: t(SUCCESS_FEEDBACK_MESSAGES.ADD_SUCCESS, {
            name: getFullName(firstName, lastName),
          }),
        }
      );

      return error;
    },
    [mutate, numberId, t]
  );

  return { addOutboundCallUser };
}
