export interface WorkatoEventData {
  type: string;
  payload: {
    connected: boolean;
    message: string;
  };
}

export interface WorkatoIframeInjestStyle {
  scrolling: IFRAME_SCROLL_TYPES;
  style: IframeStyle;
}

export interface IframeStyle {
  border?: number;
  marginTop?: string;
  height?: number;
  width?: string;
}

export enum IFRAME_SCROLL_TYPES {
  NO = 'no',
  AUTO = 'auto',
}
