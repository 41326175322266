import { CheckNumberLimitQuery } from '@generated/CheckNumberLimitQuery';
import { CHECK_NUMBER_LIMIT_QUERY } from '@graphql/queries/CheckNumberLimitQuery';
import { useGraphQuery } from '@hooks/useQuery';

/**
 * Custom hook to query data from number limitation
 * @returns  Object containing isNumbersLimitReached and the loading state.
 */
export const useNumberLimitation = () => {
  const { data: checkNumberLimitData, loading } =
    useGraphQuery<CheckNumberLimitQuery>(CHECK_NUMBER_LIMIT_QUERY);

  const isNumbersLimitReached = checkNumberLimitData?.checkNumberLimit?.isNumbersLimitReached;

  return { isNumbersLimitReached, loading };
};
