import { useCallback } from 'react';

import { UseUpdateTagReturnType } from './useUpdateTag.decl';

import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { TagsListQuery_getCompany_tags } from '@generated/TagsListQuery';
import { UpdateTagMutation, UpdateTagMutationVariables } from '@generated/UpdateTagMutation';
import { UPDATE_TAG_MUTATION } from '@graphql/mutations/UpdateTagMutation';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useTranslation } from 'react-i18next';

/**
 * Hook to update a call tag.
 * @returns a function that takes the tagId and the tag and returns a promise
 */
export function useUpdateTag(): UseUpdateTagReturnType {
  const { t } = useTranslation();

  const [mutate] = useGraphMutationWithFeedback<UpdateTagMutation, UpdateTagMutationVariables>(
    UPDATE_TAG_MUTATION,
    { type: 'snackbar' }
  );

  const updateTag = useCallback(
    async ({ color, name, id }: TagsListQuery_getCompany_tags) => {
      await mutate(
        {
          variables: {
            input: {
              id,
              color,
              name,
            },
          },
          optimisticResponse: {
            updateTag: {
              __typename: 'Tag',
              id,
              color,
              name,
              description: null,
            },
          },
        },
        { successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, { name }) }
      );
    },
    [mutate, t]
  );

  return { updateTag };
}
