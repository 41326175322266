export type UseRecorderProps = (blob: Blob) => void;

export interface UseRecorderReturn {
  audioURL: string;
  isRecording: boolean;
  startRecording: () => void;
  stopRecording: () => void;
  closeRecording: () => void;
  error?: RECORDER_ERROR;
}

export enum RECORDER_ERROR {
  'INIT_RECORDER' = 'init-recorder',
  'STOP_RECORDER' = 'stop-recorder',
}
