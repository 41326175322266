import { CUSTOM_FILE_FLOW, UseAudioFileStatusReturn } from './AudioSettingFile.decl';

import { useField } from 'react-final-form';

export function useAudioFileStatus(file: File | undefined): UseAudioFileStatusReturn {
  const {
    input: { value: audioStatus, onChange: setAudioStatus },
  } = useField<CUSTOM_FILE_FLOW>('customFileFlow', {
    initialValue: file ? CUSTOM_FILE_FLOW.DONE : CUSTOM_FILE_FLOW.UPLOAD,
  });

  const isUploading = audioStatus === CUSTOM_FILE_FLOW.UPLOAD;
  const isRecording =
    audioStatus === CUSTOM_FILE_FLOW.RECORD_START || audioStatus === CUSTOM_FILE_FLOW.RECORD_DONE;
  const isDone = audioStatus === CUSTOM_FILE_FLOW.DONE;
  const shouldDisplaySwitch =
    audioStatus === CUSTOM_FILE_FLOW.UPLOAD || audioStatus === CUSTOM_FILE_FLOW.RECORD_START;

  return {
    isUploading,
    isRecording,
    isDone,
    shouldDisplaySwitch,
    audioStatus,
    setAudioStatus,
  };
}
