import { useEffect } from 'react';

import { getFullName } from '@dashboard/library';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useIntercom as useIntercomLib } from 'react-use-intercom';

/**
 * Hook to setup Intercom on the dashboard.
 */
export function useIntercomSetup(): void {
  const { currentCompany, currentUser } = useGlobalData();
  const { boot, shutdown } = useIntercomLib();

  useEffect(() => {
    boot({
      hideDefaultLauncher: true,
      actionColor: 'primary-500',
      backgroundColor: 'primary-500',
      userId: currentUser.ID,
      languageOverride: currentUser.language,
      email: currentUser.email,
      name: getFullName(currentUser.firstName, currentUser.lastName),
      userHash: currentUser.intercomHash!,
      company: {
        companyId: currentCompany.id.toString(),
        name: currentCompany.name,
        plan: currentCompany.plan,
      },
    });

    return () => shutdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
