import { useEffect, useRef } from 'react';

/**
 * This hook is used to poll a query.
 * It will call the callback every `interval` milliseconds.
 * It will stop polling after `limit` times.
 * If `limit` is not provided, it will poll forever.
 * If `interval` is not provided, it will not poll.
 * @param callback - callback to be called every `interval` milliseconds
 * @param interval - interval in milliseconds
 * @param limit - limit of polling
 * @returns void
 */
export function usePolling({
  callback,
  interval,
  limit,
}: {
  callback: () => void;
  interval?: number;
  limit?: number;
}) {
  const pollingCountRef = useRef(0);

  useEffect(() => {
    if (interval) {
      const intervalId = setInterval(() => {
        if (limit && pollingCountRef.current >= limit) {
          clearInterval(intervalId);
        } else {
          callback();
          pollingCountRef.current += 1;
        }
      }, interval);

      return () => clearInterval(intervalId);
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, interval, limit]);
}
