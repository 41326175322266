/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';

import { Resource, RouteAccessProps } from '@components/Permissions';
import { PERMISSIONS_ACTIONS } from '@constants/permissions.constants';
import { RestrictedAccessScreen } from '@dashboard/library';
import { usePermissions } from '@hooks/usePermissions';
import { useTracker } from '@hooks/useTracker/useTracker';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

export function RouteAccess({
  resource,
  action = PERMISSIONS_ACTIONS.READ,
  children,
  onClick,
  buttonText,
}: RouteAccessProps) {
  const { t } = useTranslation();
  const { track } = useTracker();
  const navigate = useNavigate();
  const { checkAccess, getAccessibleRoute, roles } = usePermissions();
  const resources = Array.isArray(resource) ? resource : [resource];
  const hasAccess = resources.every((r) => checkAccess(action, r));
  const accessibleRoute = getAccessibleRoute();
  useEffect(() => {
    if (!hasAccess) {
      track({
        event: 'user_wrong_permission_visit',
        payload: {
          permission_set: roles.map(({ name }) => name.toLowerCase()).join(';'),
          permission_set_id: roles.map(({ ID }) => ID).join(';'),
          external_link: window.location.href,
        },
      });
    }
  }, [hasAccess, roles, track]);

  function handleClick() {
    // if accessibleRoute was null, handleClick would not have been called
    /* istanbul ignore else */
    if (accessibleRoute) {
      navigate(accessibleRoute);
    }
  }

  if (!hasAccess) {
    return (
      <RestrictedAccessScreen
        title={t('permissions.unauthorized_screen.title')}
        subtitle={t('permissions.unauthorized_screen.sub_title')}
        buttonText={
          buttonText ||
          (accessibleRoute
            ? t(`permissions.unauthorized_screen.button.${accessibleRoute.replace('/', '')}`)
            : undefined)
        }
        onClick={onClick || (accessibleRoute ? handleClick : undefined)}
      />
    );
  }

  return <Resource name={resources[0]}>{children || <Outlet />}</Resource>;
}
