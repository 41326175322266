import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { Filters } from './Filters/Filters';
import { AnalyticsState } from './Filters/Filters.decl';
import { getSafeFilterValues } from './Filters/Filters.helpers';
import { FiltersContext } from './FiltersProvider';
import { LookerContainerProps, LookerProps } from './Looker.decl';
import { getLookerDateStr } from './Looker.helpers';
import { useLookerPage } from './useLookerPage';

import { Flex, Form } from '@aircall/tractor-v2';
import { DataLayout } from '@components/DataLayout/DataLayout';
import { usePersistSearchParams } from '@components/SearchProvider/usePersistSearchParams';
import { Loading, PageHeader } from '@dashboard/library';
import {
  GetAnalyticsDashboardUrlQuery,
  GetAnalyticsDashboardUrlQueryVariables,
} from '@generated/GetAnalyticsDashboardUrlQuery';
import { GET_ANALYTICS_DASHBOARD_URL_QUERY } from '@graphql/queries/GetAnalyticsDashboardUrlQuery';
import { Form as RRForm } from 'react-final-form';

const LookerIframe = styled.iframe`
  border: 0;
`;

const StyledForm = styled(Form)`
  display: flex;
  overflow: hidden;
  flex-grow: 1;
`;

function LookerContainer({ url, title }: LookerContainerProps) {
  const { height, iFrameRef, iFrameLoading, setIFrameLoading, setUrl } = useLookerPage();

  useEffect(() => {
    setUrl(url);
  }, [setUrl, url]);

  return (
    <>
      {iFrameLoading && <Loading minH='100%' />}
      <LookerIframe
        ref={iFrameRef}
        id='looker-report'
        data-test='looker-report'
        title={title}
        width='100%'
        height={height}
        onLoad={() => setIFrameLoading(false)}
        src={url}
      />
    </>
  );
}

function LookerDataLayout({ title, type }: LookerProps) {
  const { error } = useLookerPage();
  const [lookerKey, setLookerKey] = useState(0);

  useEffect(() => {
    if (error) {
      // use react's key prop to force the component to remount
      setLookerKey((oldValue) => oldValue + 1);
    }
  }, [error]);

  return (
    <DataLayout<GetAnalyticsDashboardUrlQuery, GetAnalyticsDashboardUrlQueryVariables>
      key={`looker-key-${lookerKey}`}
      query={GET_ANALYTICS_DASHBOARD_URL_QUERY}
      queryOptions={{
        variables: {
          input: {
            dashboardType: type,
            embedDomain: `${window.location.protocol}//${window.location.host}`,
            sdkVersion: '1',
          },
        },
        fetchPolicy: 'no-cache',
      }}
    >
      {({
        data: {
          getAnalyticsDashboardUrl: { url },
        },
      }) => <LookerContainer title={title} url={url} />}
    </DataLayout>
  );
}

export function Looker({ type, ...otherProps }: LookerProps) {
  const { iFrameLoading, updateLookerFilter } = useLookerPage();
  const { maxDateRangeInDays, minDate, pageHeaderTitle, pageHeaderProps } =
    useContext(FiltersContext);
  const { searchParams } = usePersistSearchParams();

  const initialFormValue = useMemo(
    () => getSafeFilterValues(searchParams, { maxDateRangeInDays, minDate }),
    [searchParams, maxDateRangeInDays, minDate]
  );

  const onSubmit = useCallback(
    ({ rangeValue, startDate, endDate, timezone }: AnalyticsState) => {
      const date = rangeValue ?? `${getLookerDateStr(startDate)} to ${getLookerDateStr(endDate)}`;

      updateLookerFilter({ date, timezone }, true);
    },
    [updateLookerFilter]
  );

  const validate = useCallback((values: AnalyticsState) => {
    let errors = {};

    if (!values.rangeValue && values.startDate && !values.endDate) {
      errors = { ...errors, date: 'required' };
    }

    return errors;
  }, []);

  return (
    <Flex backgroundColor='neutral-100' flexDirection='column' h='inherit'>
      <RRForm<AnalyticsState>
        onSubmit={onSubmit}
        initialValues={initialFormValue}
        validate={validate}
      >
        {({ handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            <Flex flexDirection='column' flexGrow={1}>
              <PageHeader
                data-test='analytics-page-header'
                largeTitle={pageHeaderTitle}
                {...pageHeaderProps}
              />
              <Filters loading={iFrameLoading} />
              <Flex flexDirection='column' flexGrow={1} overflow='auto'>
                <LookerDataLayout type={type} {...otherProps} />
              </Flex>
            </Flex>
          </StyledForm>
        )}
      </RRForm>
    </Flex>
  );
}
