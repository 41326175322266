import styled from 'styled-components';

import { CreateResourceLink } from './CreateResourceLink';
import { ONBOARDING_STATUS } from './Onboarding.decl';

import { CallFilled, AddUserFilled, ConferenceFilled } from '@aircall/icons';
import { Typography, Box, getColor } from '@aircall/tractor-v2';
import { USERS_ROUTE, TEAMS_ROUTE, NUMBER_PURCHASE_ROUTE } from '@constants/routes.constants';
import { useTranslation } from 'react-i18next';

const SeparatorDashedLine = styled.div`
  width: 1px;
  height: 38px;
  border: 1px dashed ${getColor('neutral-200')};
  margin-left: 55px;
`;

export type CreateResourcesSectionProps = {
  numberStatus: ONBOARDING_STATUS;
  userStatus: ONBOARDING_STATUS;
  teamStatus: ONBOARDING_STATUS;
};

export function CreateResourcesSection({
  numberStatus,
  userStatus,
  teamStatus,
}: CreateResourcesSectionProps) {
  const { t } = useTranslation();

  return (
    <Box padding='xl' backgroundColor='base.white' data-test='onboarding-resources-section'>
      <Typography variant='headingBoldM' mb='32px'>
        {t('onboarding.get_started.title')}
      </Typography>
      <CreateResourceLink
        link={NUMBER_PURCHASE_ROUTE}
        title={t('onboarding.get_started.numbers.title')}
        text={t('onboarding.get_started.numbers.text')}
        icon={CallFilled}
        status={numberStatus}
      />
      <SeparatorDashedLine />
      <CreateResourceLink
        link={USERS_ROUTE}
        title={t('onboarding.get_started.users.title')}
        text={t('onboarding.get_started.users.text')}
        icon={AddUserFilled}
        status={userStatus}
      />
      <SeparatorDashedLine />
      <CreateResourceLink
        link={TEAMS_ROUTE}
        title={t('onboarding.get_started.teams.title')}
        text={t('onboarding.get_started.teams.text')}
        icon={ConferenceFilled}
        status={teamStatus}
      />
    </Box>
  );
}
