/**
 * Gets the duration with minutes:seconds format.
 * @param duration - duration in seconds
 * @returns formatted  string
 */
export function getDurationString(duration: number): string {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}
