import { TeamFragment } from '@generated/TeamFragment';
import { UserMinimalFragment } from '@generated/UserMinimalFragment';

export enum DISPATCH_GROUP_TYPE {
  TEAM = 'team',
  USER = 'user',
}
export interface UseAddDispatchGroup {
  numberId: string;
}
export interface UseAddDispatchGroupReturn {
  addDispatchGroup: (data: AddDispatchGroup) => void | Promise<void>;
  loading: boolean;
}
interface DispatchGroupAttributes {
  priority?: number;
  queuingTimeout?: number;
  redirectionStrategy?: string;
}
export interface AddDispatchGroup {
  user?: UserMinimalFragment;
  team?: TeamFragment;
  attributes: DispatchGroupAttributes;
  type: DISPATCH_GROUP_TYPE;
  successMessageName: string;
}
