import { UnknownErrorScreen } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function GenericErrorScreen() {
  const { t } = useTranslation();

  return (
    <UnknownErrorScreen
      title={t('error_screen.generic_error.title')}
      subtitle={t('error_screen.generic_error.message')}
      data-test='generic-error-screen'
    />
  );
}
