import React, { useState, useCallback, useEffect } from 'react';

import { TeamDispatchCard } from '../TeamDispatchCard/TeamDispatchCard';
import { UserDispatchCard } from '../UserDispatchCard/UserDispatchCard';

import { Box } from '@aircall/tractor-v2';
import { DraggableList } from '@components/Draggable/DraggableList';
import { DraggableItemRenderProps } from '@components/Draggable/Draggble.decl';
import {
  NumberDetailQuery_numberDetail,
  NumberDetailQuery_numberDetail_dispatchGroups,
} from '@generated/NumberDetailQuery';
import { UpdateDispatchGroupMutation_updateDispatchGroup } from '@generated/UpdateDispatchGroupMutation';
import { arraymove } from '@helpers/draggable.helper';
import { usePriorityUpdate } from '@hooks/usePriorityUpdate/usePriorityUpdate';

export interface DispatchGroupsProps {
  numberId: string;
  groups: NumberDetailQuery_numberDetail['dispatchGroups'];
}

function sortByPriority(groups: NumberDetailQuery_numberDetail_dispatchGroups[]) {
  return [...groups].sort((a, b) => a.priority - b.priority);
}

export const DispatchGroups = React.memo(({ numberId, groups }: DispatchGroupsProps) => {
  // sort groups by priority
  const [items, setItems] = useState(sortByPriority(groups));
  /** Rolls back to the previous results once the mutation fails */
  const onPriorityUpdateFails = useCallback(() => {
    // rollback the items
    setItems(sortByPriority(groups));
  }, [groups]);
  const { updatePriority, cancelFetch } = usePriorityUpdate(numberId, onPriorityUpdateFails);

  const onMoveUp = useCallback(
    (group: UpdateDispatchGroupMutation_updateDispatchGroup, currentIdx: number) => {
      const updatedIdx = currentIdx - 1;
      const updatedList = arraymove(items, currentIdx, updatedIdx);
      setItems(updatedList);

      // triggers api call
      updatePriority(group, updatedIdx + 1);
    },
    [items, updatePriority]
  );

  const onMoveDown = useCallback(
    (group: UpdateDispatchGroupMutation_updateDispatchGroup, currentIdx: number) => {
      const updatedIdx = currentIdx + 1;
      const updatedList = arraymove(items, currentIdx, updatedIdx);
      setItems(updatedList);

      // triggers api call
      updatePriority(group, updatedIdx + 1);
    },
    [items, updatePriority]
  );

  const onDragEnd = useCallback(
    (groupItem, updatedIdx) => {
      updatePriority(groupItem, updatedIdx + 1);
    },
    [updatePriority]
  );

  // reset the items when groups data updates
  useEffect(() => {
    setItems(sortByPriority(groups));
  }, [groups]);

  const renderItem = useCallback(
    ({
      item,
      innerProps: { currentIdx, total },
    }: DraggableItemRenderProps<NumberDetailQuery_numberDetail_dispatchGroups>) => {
      const { dispatchable } = item;
      if (dispatchable?.__typename === 'User') {
        return (
          <UserDispatchCard
            group={item}
            numberId={numberId}
            currentIdx={currentIdx}
            onMoveup={currentIdx > 0 ? onMoveUp : undefined}
            onMovedown={currentIdx < total - 1 ? onMoveDown : undefined}
          />
        );
      }

      /* istanbul ignore else */
      if (dispatchable?.__typename === 'Team') {
        return (
          <TeamDispatchCard
            group={item}
            numberId={numberId}
            currentIdx={currentIdx}
            onMoveup={currentIdx > 0 ? onMoveUp : undefined}
            onMovedown={currentIdx < total - 1 ? onMoveDown : undefined}
          />
        );
      }

      /* istanbul ignore next */
      return null;
    },
    [numberId, onMoveDown, onMoveUp]
  );
  return (
    <Box zIndex={999} position='relative'>
      <DraggableList
        items={items}
        renderItem={renderItem}
        onChange={setItems}
        // must cancel the on-going data fetch to avoid race condition of card sorting
        onDragStart={cancelFetch}
        onDragEnd={onDragEnd}
      />
    </Box>
  );
});
