import { useEffect } from 'react';

import { APPLICATIONS_NAMES, APPLICATIONS_TYPES } from '@constants/integrations.constants';
import { INTEGRATIONS_CHILD_ROUTE, INTEGRATION_FULL_ROUTES } from '@constants/routes.constants';
import { Loading } from '@dashboard/library';
import { ApplicationsListQuery } from '@generated/ApplicationsListQuery';
import { useIntegrations } from '@hooks/useIntegrations';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useQueryParams } from '@hooks/useQueryParams';
import { Navigate } from 'react-router-dom';

/**
 * Get the type of the given integration
 * @param applicationName - the application name
 * @param applications - the applications list
 * @returns the integration type
 */
export function getIntegrationType(
  applicationName: APPLICATIONS_NAMES,
  applications: ApplicationsListQuery
): APPLICATIONS_TYPES {
  const isBridgeApp = applications.getApplications.some((app) => app.shortName === applicationName);

  if (isBridgeApp) {
    return APPLICATIONS_TYPES.BRIDGE;
  }

  return APPLICATIONS_TYPES.HUB;
}

export function IntegrationFlowRedirect() {
  const { searchParams } = useQueryParams();
  const { applications } = useIntegrations({
    dataToFetch: ['applications'],
  });
  const hasIntegrationFlowParams = !!searchParams.get('integration');
  const navigate = useNavigateWithParamsReplace();

  useEffect(() => {
    if (hasIntegrationFlowParams && applications) {
      const applicationName = searchParams.get('integration') as APPLICATIONS_NAMES;
      const applicationType = getIntegrationType(applicationName, applications);
      navigate(`${INTEGRATION_FULL_ROUTES.FLOW_CREATE}?${searchParams.toString()}`, {
        applicationType,
        applicationName,
        applicationId: '0',
      });
    }
  }, [hasIntegrationFlowParams, navigate, searchParams, applications]);

  if (hasIntegrationFlowParams) {
    return null;
  }

  if (applications === undefined) {
    return <Loading data-test='integrations-flow-redirect-loading' />;
  }

  return <Navigate to={INTEGRATIONS_CHILD_ROUTE.CONNECTED_INTEGRATIONS} />;
}
