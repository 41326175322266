import { useCallback } from 'react';

import { ApolloError, MutationFunctionOptions } from '@apollo/client';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import {
  UpdateDispatchGroupMutation,
  UpdateDispatchGroupMutationVariables,
  UpdateDispatchGroupMutation_updateDispatchGroup,
} from '@generated/UpdateDispatchGroupMutation';
import { UPDATE_DISPATCH_GROUP_MUTATION } from '@graphql/mutations/UpdateDispatchGroupMutation';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useTranslation } from 'react-i18next';

export type DispatchMutateOptions =
  | MutationFunctionOptions<UpdateDispatchGroupMutation, UpdateDispatchGroupMutationVariables>
  | undefined;
interface UseUpdateDispatchGroup {
  numberId: string;
  group: UpdateDispatchGroupMutation_updateDispatchGroup;
  name: string | null;
  onError?: (error: ApolloError) => void;
}
interface UseUpdateDispatchGroupReturn {
  updateDispatchGroup: (
    attributeName: DISPATCH_GROUP_ATTRIBUTE,
    attributeValue: DispatchGroupAttributeValue
  ) => void | Promise<void>;
  loading: boolean;
}

export enum DISPATCH_GROUP_ATTRIBUTE {
  REDIRECTION_STRATEGY = 'redirectionStrategy',
  QUEUING_TIMEOUT = 'queuingTimeout',
  PRIORITY = 'priority',
}
export type DispatchGroupAttributeValue = string | number;

/**
 *  Hook to update dispatch group
 * @param useUpdateDispatchGroup Object holding necessary information to update a dispatch group
 * @returns Object holding loading state and a function responsible for mutation
 */
export function useUpdateDispatchGroup({
  numberId,
  group,
  name,
  onError,
}: UseUpdateDispatchGroup): UseUpdateDispatchGroupReturn {
  const { t } = useTranslation();
  const [mutate, { loading }] = useGraphMutationWithFeedback<
    UpdateDispatchGroupMutation,
    UpdateDispatchGroupMutationVariables
  >(
    UPDATE_DISPATCH_GROUP_MUTATION,
    {
      type: 'snackbar',
    },
    {
      onError,
    }
  );
  const updateDispatchGroup = useCallback(
    async (
      attributeName: DISPATCH_GROUP_ATTRIBUTE,
      attributeValue: DispatchGroupAttributeValue,
      mutateOptions?: DispatchMutateOptions
    ) => {
      const { id: groupId } = group;

      mutate(
        {
          ...mutateOptions,
          variables: {
            numberId,
            dispatchGroupId: groupId,
            input: {
              data: {
                type: 'dispatch_group',
                id: groupId,
                attributes: {
                  [attributeName]: attributeValue,
                },
              },
            },
          },
          optimisticResponse: {
            updateDispatchGroup: {
              ...group,
              [attributeName]: attributeValue,
            },
          },
        },
        { successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, { name }) }
      );
    },
    [group, mutate, numberId, t, name]
  );

  return { updateDispatchGroup, loading };
}
