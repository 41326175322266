import { gql } from '@apollo/client';

export const TEXT_TO_SPEECH_PREVIEW_MUTATION = gql`
  mutation TextToSpeechPreviewMutation(
    $numberId: String!
    $ttsId: String!
    $input: TextToSpeechPreviewInput!
  ) {
    textToSpeechPreview(numberId: $numberId, ttsId: $ttsId, input: $input)
      @rest(
        endpoint: "v4"
        path: "numbers/{args.numberId}/tts_messages/{args.ttsId}/preview"
        type: "TextToSpeechPreview"
        method: "POST"
      ) {
      data {
        id
        type
        attributes {
          category
          track
          language
          voice
          message
          url
          defaultUrl
          createdAt
          updatedAt
        }
      }
    }
  }
`;
