import { NumberDetailConnectedIntegrationsSection } from './NumberDetailConnectedIntegrationsSection/NumberDetailConnectedIntegrationsSection';

import { Spacer } from '@aircall/tractor-v2';

export function NumberDetailIntegrationsPage() {
  return (
    <Spacer fluid direction='vertical' space='m'>
      <NumberDetailConnectedIntegrationsSection />
    </Spacer>
  );
}
