import { IvrSettingFormValues } from '../IvrSettingModal';

import { NUMBER_PURCHASE_ROUTE_AT_IVR_EDITION } from '@constants/routes.constants';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import {
  UpdateIvrSettingRest,
  UpdateIvrSettingRestVariables,
} from '@generated/UpdateIvrSettingRest';
import { UPDATE_IVR_SETTING_MUTATION } from '@graphql/mutations/UpdateIvrSettingMutation';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useTranslation } from 'react-i18next';

interface UseUpdateIvrSettingProps {
  ivrSettingId: string;
  numberId: string;
  onCompleted: () => void;
}

interface UseUpdateIvrSettingReturn {
  updateIvrSetting: (values: IvrSettingFormValues) => void;
}

/**
 * Hook to update ivr setting
 * @param useUpdateIvrSettingProps Object holding all necessary params for the mutation and onCompleted callback
 * @returns Object holding a function for the mutation
 */
export const useUpdateIvrSetting = ({
  ivrSettingId,
  numberId,
  onCompleted,
}: UseUpdateIvrSettingProps): UseUpdateIvrSettingReturn => {
  const navigate = useNavigateWithParamsReplace();
  const { t } = useTranslation();
  const [mutate] = useGraphMutationWithFeedback<
    UpdateIvrSettingRest,
    UpdateIvrSettingRestVariables
  >(
    UPDATE_IVR_SETTING_MUTATION,
    { type: 'form', validationErrorAsFormError: true },
    { onCompleted }
  );
  const updateIvrSetting = ({
    type,
    key,
    internalNumber,
    externalNumber,
  }: IvrSettingFormValues) => {
    if (type === 'new') {
      navigate(NUMBER_PURCHASE_ROUTE_AT_IVR_EDITION, {
        numberId,
        key,
        settingId: ivrSettingId,
      });
      return null;
    }
    const inputFields =
      type === 'internal'
        ? { key, redirectingNumberId: internalNumber!.id, redirectingExternalNumber: '' }
        : { key, redirectingExternalNumber: externalNumber!, redirectingNumberId: null };

    return mutate(
      {
        variables: { numberId, ivrSettingId, input: { ivrSetting: inputFields } },
        update: (cache, { data }) => {
          const {
            key: numberKey,
            redirectingExternalNumber,
            redirectingNumberId,
          } = data!.updateIvrSettingRest;
          cache.modify({
            id: cache.identify({ __typename: 'IvrSettingRest', id: ivrSettingId }),
            fields: {
              key: () => numberKey,
              branchType: () => type,
              redirectingExternalNumber: () => redirectingExternalNumber,
              redirectingNumber: () =>
                redirectingNumberId && internalNumber
                  ? {
                      name: internalNumber.name,
                      activationState: internalNumber.activationState,
                      verificationState: internalNumber.verificationState
                        ? internalNumber.verificationState.toLowerCase()
                        : /* istanbul ignore next */ null,
                      number: internalNumber.number,
                      id: redirectingNumberId.toString(),
                    }
                  : null,
            },
          });
        },
      },
      {
        successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
          name: t(
            'number_details.settings.call_distribution_section.ivr_setting_modal.success_message.ivr_option'
          ),
        }),
      }
    );
  };

  return { updateIvrSetting };
};
