import { useState } from 'react';

import {
  MusicOnHoldAudioFile,
  MusicOnHoldFormValues,
  MUSIC_ON_HOLD_AUDIO_TYPE,
} from '../MusicOnHoldSettings/MusicOnHoldSettings.decl';

import { ApolloError } from '@apollo/client';
import {
  AudioFileUploadQuery,
  AudioFileUploadQueryVariables,
  AudioFileUploadQuery_uploadAudioFile_fields,
} from '@generated/AudioFileUploadQuery';
import { AUDIO_FILE_UPLOAD_QUERY } from '@graphql/queries/AudioFileUploadQuery';
import { ClientException, handleApolloError } from '@helpers/errors.helpers';
import { useImperativeQuery } from '@hooks/useImperativeQuery/useImperativeQuery';
import { uploadFile } from '@services/file';
import kebabCase from 'lodash-es/kebabCase';

type AudioFileField = Omit<AudioFileUploadQuery_uploadAudioFile_fields, '__typename'>;

interface UseCreateMusicOnHoldUrlType {
  generateUrl: (values: MusicOnHoldFormValues['audioSetting']) => Promise<string>;
  loading: boolean;
}

function buildFormData(fields: AudioFileField, file: File): FormData {
  const fd = new FormData();
  Object.keys(fields).forEach((key) => {
    // convert field name to kebab case: from ContentType to Content-Type
    if (key === 'successActionStatus') {
      fd.append('success_action_status', fields.successActionStatus);
    } else if (key === 'contentType') {
      fd.append('Content-Type', fields.contentType);
    } else if (key === 'expires') {
      fd.append('Expires', fields.expires);
    } else {
      fd.append(kebabCase(key), fields[key as keyof AudioFileField]);
    }
  });
  fd.append('file', file);
  return fd;
}

export function useCreateMusicOnHoldUrl(numberId: string): UseCreateMusicOnHoldUrlType {
  const [uploading, setUploading] = useState(false);

  const preUploadFile = useImperativeQuery<AudioFileUploadQuery, AudioFileUploadQueryVariables>({
    query: AUDIO_FILE_UPLOAD_QUERY,
  });

  const s3File = async (audioSetting: MusicOnHoldAudioFile): Promise<string> => {
    setUploading(true);
    try {
      const { name } = audioSetting.file;

      const {
        data: { uploadAudioFile },
      } = await preUploadFile({ numberId, name });

      const { __typename, ...fields } = uploadAudioFile.fields;
      const formData = buildFormData(fields, audioSetting.file);
      // the s3 server doesn't accept authorization in request header, so we set the third param as false here
      await uploadFile(uploadAudioFile.url, formData, false);

      return encodeURI(`${uploadAudioFile.readUrl}/${uploadAudioFile.id}`);
    } catch (e) {
      throw new ClientException(handleApolloError(e as ApolloError));
    } finally {
      setUploading(false);
    }
  };

  const generateUrl = async (audioSetting: MusicOnHoldFormValues['audioSetting']) =>
    audioSetting.type === MUSIC_ON_HOLD_AUDIO_TYPE.LIBRARY
      ? audioSetting.url
      : s3File(audioSetting);

  return {
    generateUrl,
    loading: uploading,
  };
}
