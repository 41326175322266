import { URLSearchObject } from './usePersistSearchParams';

export const formatObjectToSearchParams = (searchObject: URLSearchObject): URLSearchParams =>
  new URLSearchParams(searchObject);

export const formatSearchParamsToObject = (searchParams: URLSearchParams): URLSearchObject =>
  searchParams ? Object.fromEntries(searchParams) : {};

export const removeEmptyEntriesFromObject = (searchObject: URLSearchObject): URLSearchObject => {
  const newSearchObject = searchObject;

  Object.keys(newSearchObject).forEach(
    (k) => !newSearchObject[k] && searchObject[k] !== undefined && delete newSearchObject[k]
  );

  return newSearchObject;
};
