import { SVGProps } from 'react';

import styled from 'styled-components';

import { CircularProgressProps } from './CircularProgress.decl';

import { Box, getColor } from '@aircall/tractor-v2';

type CustomSVGPathElement = SVGProps<SVGElement> & { stroke: string };
type CustomSVGTextElement = SVGProps<SVGElement> & { fill: string };

const StyledCirclePath = styled.path<CustomSVGPathElement>`
  stroke: ${({ stroke }) => getColor(stroke)};
`;

const StyledCircleText = styled.text<CustomSVGTextElement>`
  fill: ${({ fill }) => getColor(fill)};
`;

export function CircularProgress({
  strokeWidth = 20,
  size = 48,
  value,
  total,
  color = 'primary',
  fontSize,
}: CircularProgressProps) {
  const percentage: number = Math.floor((value / total) * 100);
  // The inner radius of the circle
  const radius = 50 - strokeWidth / 2;
  // The inner area of the CircularProgress used to calculate the percentage
  const area = Math.PI * 2 * radius;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  return (
    <Box backgroundColor='neutral-0' borderRadius='50%' data-test='circular-progress-container'>
      <svg data-test='circular-progress-svg' viewBox='0 0 100 100' width={size} height={size}>
        <StyledCirclePath
          d={pathDescription}
          strokeWidth={strokeWidth}
          stroke={`${color}-100`}
          fillOpacity={0}
          data-test='circular-progress-path-lower-stroke'
        />
        <StyledCirclePath
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          strokeDashoffset={((100 - percentage) / 100) * area}
          stroke={`${color}-300`}
          strokeLinecap='round'
          data-test='circular-progress-path-main-stroke'
          strokeDasharray={`${area}px ${area}px`}
        />
        <StyledCircleText
          x={50}
          y={50}
          data-test='circular-progress-text'
          fill={`${color}-700`}
          fontSize={fontSize ?? 32}
          dominantBaseline='central'
          fontWeight={600}
          textAnchor='middle'
        >
          {value}
        </StyledCircleText>
      </svg>
    </Box>
  );
}
