import { gql } from '@apollo/client';

export const NUMBER_SUPERVISION_QUERY = gql`
  query NumberSupervisionQuery($id: String!) {
    numberSupervision(id: $id)
      @rest(
        type: "Number"
        path: "{args.id}?include=supervisions,supervisions.user&fields%5Buser%5D=first_name,last_name,email,picture_url"
        endpoint: "v4NumberDetail"
        bodySerializer: "auth"
      ) {
      id
      supervisions {
        id
        user {
          id
          email
          firstName
          lastName
          pictureUrl
        }
      }
    }
  }
`;
