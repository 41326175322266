import { useCallback, useMemo } from 'react';

import { UseQueryParams } from './useQueryParams.decl';

import { useLocation, useNavigate } from 'react-router-dom';

/**
 * The custom effect to get and update the search params
 */
export function useQueryParams(): UseQueryParams {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const setSearchParams = useCallback(
    (urlParams: URLSearchParams | null) => {
      if (urlParams && urlParams.toString() !== '') {
        navigate(`${pathname}?${urlParams.toString()}`, { replace: true });
      } else {
        navigate(`${pathname}`, { replace: true });
      }
    },
    [navigate, pathname]
  );

  return { searchParams, setSearchParams };
}
