import { useCallback, useMemo } from 'react';

import { useCustomTimezoneState } from '@pages/looker-v2/Filters/state/useCustomTimezoneState';
import { DEFAULT_VALUE } from '@pages/looker-v2/Filters/TimezoneFilter';
import { UseTimezoneFilterType } from '@pages/looker-v2/Filters/TimezoneFilter/TimezoneFilter.decl';

export const useTimezoneFilter = (): UseTimezoneFilterType => {
  const { customTimezone, setCustomTimezone } = useCustomTimezoneState();

  const onChange = useCallback(
    (value: string) => {
      setCustomTimezone(value || DEFAULT_VALUE.timezone);
    },
    [setCustomTimezone]
  );

  const value = useMemo(() => customTimezone ?? undefined, [customTimezone]);

  return { onChange, value };
};
