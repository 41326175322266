import { gql } from '@apollo/client';

export const GET_APPLICATION_INSTALL_URI_QUERY = gql`
  query getApplicationInstallUri($applicationID: ID!, $hubOldIntegrationId: ID) {
    getApplicationInstallUri(
      applicationID: $applicationID
      hubOldIntegrationId: $hubOldIntegrationId
    ) {
      installUri
    }
  }
`;
