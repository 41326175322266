import { useContext } from 'react';

import { ResourceContext } from '@components/Permissions/Resource';
import { RESOURCE } from '@constants/permissions.constants';
import { getResourceFromPath } from '@helpers/permissions.helpers';
import { useLocation } from 'react-router-dom';

export function useResource(): RESOURCE | null {
  const location = useLocation();
  const contextResource = useContext(ResourceContext);
  // Try getting the context resource (from parent <Resource />) otherwise, we get it from the route mapping
  return contextResource || getResourceFromPath(location.pathname);
}
