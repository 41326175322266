import { LOGIN_ROUTE } from '../../constants/routes.constants';
import { useAuthenticationState } from '../../hooks/useAuthenticationState';

import { AUTHENTICATION_STATUS } from '@state/app/authentication/authentication.decl';
import { useLocation, Navigate } from 'react-router';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { authState } = useAuthenticationState();
  const { pathname, search } = useLocation();

  // if user is not authenticated then redirect to login page
  if (authState.status !== AUTHENTICATION_STATUS.AUTHENTICATED) {
    return <Navigate to={LOGIN_ROUTE} state={{ from: pathname, search }} />;
  }

  return children;
}
