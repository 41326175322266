import { FormFlowPageErrorBannerProps } from '../FormFlow.decl';
import { useFormFlow } from '../useFormFlow';

import { Banner, BannerHeading, BannerSuffix } from '@aircall/tractor-v2';
import { useTranslation } from 'react-i18next';

export function FormFlowPageErrorBanner({ component }: FormFlowPageErrorBannerProps) {
  const { t } = useTranslation();
  const { errorBanner } = useFormFlow();

  return errorBanner ? (
    <Banner inline data-test='form-flow-page-error' variant='critical'>
      <BannerHeading>{t(errorBanner)}</BannerHeading>
      <BannerSuffix>{component}</BannerSuffix>
    </Banner>
  ) : null;
}
