import styled from 'styled-components';

import { FormFlowPageFooterProps } from '../FormFlow.decl';

import { FormFlowPageErrorBanner } from './FormFlowPageErrorBanner';

import { Flex, Spacer, Button } from '@aircall/tractor-v2';
import { Loading } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

const Footer = styled(Flex)`
  display: flex;
  flex-direction: column;
  grid-area: footer;
`;

const Bar = styled(Flex)`
  height: 64px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: ${({ theme }) => theme.space.s}px;
  padding-right: ${({ theme }) => theme.space.s}px;
  background: white;
  box-shadow: 0 8px 20px rgb(16 24 32 / 0.1);
`;

export function FormFlowPageFooter({
  showErrorBanner,
  onCancel,
  nextLoading,
  onNext,
  nextDisabled,
  showCancel,
  showNext,
  nextText,
  cancelText,
  secondaryText,
  secondaryDisabled = false,
  onSecondary,
  'data-test': dataTest = 'form-flow-page-footer',
  errorBannerComponent,
}: FormFlowPageFooterProps) {
  const { t } = useTranslation();

  return (
    <Footer data-test={dataTest}>
      {showErrorBanner && <FormFlowPageErrorBanner component={errorBannerComponent} />}
      <Bar>
        <Spacer space='s' alignItems='center'>
          {showCancel && (
            <Button
              data-test={`${dataTest}-cancel`}
              onClick={onCancel}
              variant='secondary'
              size='small'
              mode='link'
              disabled={nextLoading}
            >
              {cancelText || t('form_flow.cancel')}
            </Button>
          )}
          {secondaryText && (
            <Button
              data-test={`${dataTest}-secondary`}
              onClick={onSecondary}
              variant='secondary'
              size='small'
              mode='link'
              disabled={secondaryDisabled}
            >
              {secondaryText}
            </Button>
          )}
          {showNext && (
            <Button
              data-test={`${dataTest}-next`}
              size='small'
              onClick={onNext}
              disabled={nextLoading || nextDisabled}
            >
              {nextLoading ? <Loading /> : nextText || t('form_flow.continue')}
            </Button>
          )}
        </Spacer>
      </Bar>
    </Footer>
  );
}
