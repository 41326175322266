import { OrderDirection, OrderInput } from '@aircall/tractor-v2';
import {
  SearchableLinesSortableFields,
  SearchableLinesSortInput,
  SearchableSortDirection,
} from '@generated/globalTypes';

export function reverseOrderDirection(orderDirection: OrderDirection): OrderDirection {
  return orderDirection === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc;
}

export function getSearchLinesSort({
  direction,
  field,
}: {
  direction: OrderDirection;
  field: OrderInput['field'];
}): SearchableLinesSortInput[] {
  const sort: SearchableLinesSortInput[] = [];

  if (field === SearchableLinesSortableFields.agentsCounter) {
    /**
     * If we need to sort by `agentsCounter`, we should first sort by `isIVR` in reverse order
     * because IVR numbers should not show associated users (`agentsCounter`)
     */
    sort.push({
      field: SearchableLinesSortableFields.isIVR,
      direction: reverseOrderDirection(direction).toLowerCase() as SearchableSortDirection,
    });
  }

  sort.push({ field, direction: direction.toLowerCase() } as SearchableLinesSortInput);

  return sort;
}
