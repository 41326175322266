import { AudioSettingLibrary } from './AudioSettingLibrary';
import { AudioSettingLibraryFieldProps } from './AudioSettingLibrary.decl';

import { useField } from 'react-final-form';

export function AudioSettingLibraryField({ name }: AudioSettingLibraryFieldProps) {
  const {
    input: { onChange, value },
  } = useField(name);

  return <AudioSettingLibrary onChange={onChange} url={value} />;
}
