import { useCallback, useContext, useEffect } from 'react';

import { RoutesSearchParams, SearchContext } from './SearchProvider';
import {
  formatObjectToSearchParams,
  formatSearchParamsToObject,
  removeEmptyEntriesFromObject,
} from './utils';

import { useQueryParams } from '@hooks/useQueryParams';
import { useLocation } from 'react-router-dom';

export type URLSearchObject = Record<string, string>;

interface UsePersistSearchParamsReturn {
  routesSearchParams: RoutesSearchParams;
  searchParams: URLSearchParams;
  setSearchParams: (queryParams: URLSearchObject) => void;
}

export function usePersistSearchParams(): UsePersistSearchParamsReturn {
  const { pathname, search } = useLocation();
  const { routesSearchParams, setRoutesSearchParams } = useContext(SearchContext);
  const { searchParams, setSearchParams: setQueryParams } = useQueryParams();

  const setSearchParams = useCallback(
    (searchObject: URLSearchObject) => {
      // parse the search string to append or replace new entries and remove empty entries
      const oldSearchObject = formatSearchParamsToObject(routesSearchParams[pathname]);
      const newSearchObject = removeEmptyEntriesFromObject({ ...oldSearchObject, ...searchObject });
      const newSearchParams = formatObjectToSearchParams(newSearchObject);
      // set search params newSearchParams.toString());
      setQueryParams(newSearchParams);
      // Save route search params into context
      setRoutesSearchParams((prev) => ({
        ...prev,
        [pathname]: newSearchParams,
      }));
    },
    [pathname, routesSearchParams, setQueryParams, setRoutesSearchParams]
  );

  // Set the saved search to url on page change
  useEffect(() => {
    const hasSavedSearchParams = Array.from(routesSearchParams[pathname] || []).length > 0;
    if (search.length) {
      setRoutesSearchParams((prev) => ({
        ...prev,
        [pathname]: searchParams,
      }));
    }
    if (!search.length && hasSavedSearchParams) {
      setQueryParams(routesSearchParams[pathname]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return {
    routesSearchParams,
    searchParams,
    setSearchParams,
  };
}
