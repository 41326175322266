import { useMemo } from 'react';

import { useGetNumberConnectedIntegrations } from '../hooks/useGetNumberConnectedIntegrations';
import { useRemoveNumberConnectedIntegration } from '../hooks/useRemoveNumberConnectedIntegration';

import { NumberDetailIntegrationsModal } from './NumberDetailIntegrationsModal';

import { Avatar, AvatarImg, Spacer } from '@aircall/tractor-v2';
import { AddButton } from '@components/Button/AddButton';
import { ExternalLink } from '@components/Link';
import { INTEGRATION_ROUTE } from '@constants/routes.constants';
import { MORE_INTEGRATIONS_URL } from '@constants/urls.constants';
import { Loading, useNavigateWithParamsReplace, Paper, List, ListItem } from '@dashboard/library';
import { formatApplicationName } from '@helpers/integrations.helpers';
import { useToggle } from '@hooks/useToggle/useToggle';
import { Trans, useTranslation } from 'react-i18next';

export function NumberDetailConnectedIntegrationsSection() {
  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();

  const { connectedIntegrations, loading } = useGetNumberConnectedIntegrations();
  const { removeNumberConnectedIntegration } = useRemoveNumberConnectedIntegration();
  const [open, _, setOpen, setClose] = useToggle(false);

  const connectedIntegrationsId = useMemo(
    () => connectedIntegrations.map((item) => item.id),
    [connectedIntegrations]
  );

  if (loading) {
    return <Loading data-test='loading' />;
  }

  return (
    <Paper
      title={t('number_details.integrations.connected_integrations.title')}
      subtitle={
        <Trans i18nKey='number_details.integrations.connected_integrations.subtitle'>
          This number will be connected to the integrations you select.
          <ExternalLink
            href={MORE_INTEGRATIONS_URL}
            target='_blank'
            data-test='view-all-integrations-link'
          >
            Go and see the full list of integrations
          </ExternalLink>
        </Trans>
      }
    >
      <Spacer fluid direction='vertical' space='xxxs'>
        {!!connectedIntegrations.length && (
          <List>
            {connectedIntegrations.map((item, index) => (
              <ListItem
                key={item.id}
                avatar={
                  <Avatar size='small'>
                    <AvatarImg src={item.attributes.logo ?? ''} />
                  </Avatar>
                }
                primaryText={item.attributes.customName || item.attributes.name}
                dropdownOptions={[
                  {
                    title: t('number_details.integrations.connected_integrations.edit_settings'),
                    callback: () =>
                      navigate(INTEGRATION_ROUTE, {
                        applicationType: item.attributes.service,
                        integrationId: item.id,
                        // Some integrations do not have a `nameUnderscored` so we should use `name` instead
                        integrationName:
                          item.attributes.nameUnderscored ||
                          formatApplicationName(item.attributes.name),
                      }),
                  },
                  {
                    title: t(
                      'number_details.integrations.connected_integrations.remove_integration'
                    ),
                    callback: () => removeNumberConnectedIntegration(item),
                  },
                ]}
                data-test={`number-connected-integrations-list-item-${index}`}
              />
            ))}
          </List>
        )}
        <AddButton
          data-test='number-detail-add-integration'
          hasData={!!connectedIntegrations.length}
          onClick={setOpen}
          translationKey='number_details.integrations.connected_integrations.add_integration'
        />
      </Spacer>
      <NumberDetailIntegrationsModal
        show={open}
        onHide={setClose}
        connectedIntegrationsId={connectedIntegrationsId}
      />
    </Paper>
  );
}
