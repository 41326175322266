import { useEffect } from 'react';

import { getExternalErrorTranslationStrings } from '../../helpers/errors.helpers';

import { useGraphQuery } from './useGraphQuery';
import { ErrorHandlingOptions, QueryOptions, QueryReturnType } from './useQuery.decl';

import { DocumentNode, OperationVariables, TypedDocumentNode } from '@apollo/client';
import { useToast } from '@hooks/useToast';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook based on useGraphQuery, using snackbar to display errors.
 * @param query - The query to execute.
 * @param options - Option that apply to the query.
 * @returns An object holding the error retrieved and other fields returned by useGraphQuery.
 */
export function useGraphQueryWithFeedback<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  errorHandlingOptions: ErrorHandlingOptions,
  options?: QueryOptions<TData, TVariables>
): QueryReturnType<TData, TVariables> {
  const toast = useToast();
  const { t } = useTranslation();
  const { error, ...rest } = useGraphQuery(query, options);

  useEffect(() => {
    if (error?.type) {
      /* istanbul ignore else */
      if (errorHandlingOptions.type === 'snackbar') {
        toast.showToast({
          variant: 'critical',
          message: t(getExternalErrorTranslationStrings(error)),
        });
      }
    }
  }, [error, errorHandlingOptions.type, t, toast]);

  return {
    error,
    ...rest,
  };
}
