import { FRONTEND_VALIDATION_ERRORS_TRANSLATION_KEYS } from '@constants/errors.constants';

export const validateNumberName = (value: string): string | undefined => {
  const specialCharacterRegex = /[,%_^"]/;

  if (specialCharacterRegex.test(value)) {
    return FRONTEND_VALIDATION_ERRORS_TRANSLATION_KEYS.INVALID_NUMBER_NAME;
  }

  return undefined;
};
