import { ResellerConfig } from '@config/resellers.config';
import { capitalizeFirstLetter } from '@dashboard/library';
import { Helmet } from 'react-helmet';

export function Head() {
  const { faviconUrl, appName } = ResellerConfig;

  return (
    <Helmet defer={false}>
      <title>{capitalizeFirstLetter(appName)} Dashboard</title>
      <link rel='icon' href={faviconUrl} />
    </Helmet>
  );
}
