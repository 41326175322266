import { COMPANY_FRAGMENT } from '../fragments/CompanyFragment';

import { gql } from '@apollo/client';
import { USER_FULL_FRAGMENT } from '@graphql/fragments/UserFragment';

export const GLOBAL_DATA_QUERY = gql`
  query GlobalDataQuery {
    getAgentV2 {
      ...UserFullFragment
    }
    currentCompany @rest(type: "RestCompany", path: "company", endpoint: "v3") {
      ...CompanyFragment
    }
    companySettings
      @rest(type: "CompanySettings", path: "settings", endpoint: "v3companySettings") {
      plans {
        name
        features {
          pricePerUser {
            annually {
              usd
              eur
              gbp
              aud
            }
            monthly {
              usd
              eur
              gbp
              aud
            }
          }
          nbUsersMax
          nbUsersIncluded
          nbTeamsMax
          nbLinesMax
          nbLinesIncluded
          featureLevel
          featuresIncluded
        }
      }
    }
  }
  ${COMPANY_FRAGMENT}
  ${USER_FULL_FRAGMENT}
`;
