import React from 'react';

import { Typography, Flex } from '@aircall/tractor-v2';
import { Avatar, getInitials } from '@dashboard/library';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';

export const CompanyLogo = React.memo(() => {
  const { currentCompany } = useGlobalData();

  return (
    <Flex alignItems='center' my={20} px='m'>
      <Avatar
        initials={getInitials(currentCompany.name)}
        src={currentCompany.logoUrl}
        size='small'
        data-test='logo'
      />
      <Typography variant='headingBoldXS' fontWeight={600} ml='6px' data-test='name' ellipsis>
        {currentCompany.name}
      </Typography>
    </Flex>
  );
});
