import { useEffect } from 'react';

import { IntegrationFlowCreateTrayProps } from './IntegrationFlowCreateTray.decl';

import { INTEGRATION_FULL_ROUTES } from '@constants/routes.constants';
import { Loading } from '@dashboard/library';
import {
  EnableIntegrationMutation,
  EnableIntegrationMutationVariables,
} from '@generated/EnableIntegrationMutation';
import { ENABLE_INTEGRATION_MUTATION } from '@graphql/mutations/EnableIntegrationMutation';
import { useChannel, useEvent } from '@harelpls/use-pusher';
import { UnknownError } from '@helpers/errors.helpers';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useGraphMutation } from '@hooks/useMutation';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { PusherHubIntegrationEvent } from '@pages/integration-flow-install/Partner/IntegrationFlowInstallPartner';
import { useParams } from 'react-router-dom';

export function IntegrationFlowCreateTray({ solutionInstanceId }: IntegrationFlowCreateTrayProps) {
  const { applicationType, applicationName, applicationId } = useParams();
  const navigate = useNavigateWithParamsReplace();
  const [mutate, { error }] = useGraphMutation<
    EnableIntegrationMutation,
    EnableIntegrationMutationVariables
  >(ENABLE_INTEGRATION_MUTATION);

  const { currentCompany } = useGlobalData();
  const pusherChannelName = `private-dashboard-company-${currentCompany.id}`;
  const channel = useChannel(pusherChannelName);

  // Wait for creation of integration and then redirect to complete screen
  useEvent(channel, 'created_hub_integration', (event: PusherHubIntegrationEvent | undefined) => {
    const eventApplicationId = event?.application_id?.toString();

    if (eventApplicationId !== applicationId) {
      return;
    }

    const integrationId = event?.id;

    navigate(
      `${INTEGRATION_FULL_ROUTES.FLOW_COMPLETE}?integration_id=${integrationId}`,
      {
        applicationName,
        applicationType,
        applicationId,
      },
      { replace: true }
    );
  });

  useEffect(
    () => {
      async function enableIntegration() {
        try {
          await mutate({
            variables: {
              applicationId,
              input: {
                solutionInstanceId: solutionInstanceId!,
              },
            },
          });
        } catch (e) {
          // We already display an error
        }
      }

      enableIntegration();
    },
    /* We really want the creation of the integration to happen only once */
    [] // eslint-disable-line
  );

  if (error) {
    throw new UnknownError('Integration install error', {
      params: {
        variables: {
          applicationId,
          input: {
            solutionInstanceId: solutionInstanceId!,
          },
        },
      },
    });
  }

  return <Loading data-test='create-tray-loading' />;
}
