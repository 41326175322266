import { Suspense } from 'react';

import { RequireAuth } from '../components/RequireAuth/RequireAuth';
import {
  ACTION_RESULT_ROUTE,
  CHECK_HEALTH,
  CONFIRM_INVITATION_ROUTE,
  CONFIRM_USER_CREATION_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_ROUTE,
  SET_NEW_PASSWORD_ROUTE,
} from '../constants/routes.constants';
import reactLazyPreload from '../helpers/lazyPreload.helpers';

import { PrivateRoutes } from './Private.routes';

import { PublicOnly } from '@components/PublicOnly/PublicOnly';
import { Loading } from '@dashboard/library';
import { CheckHealth } from '@pages/check-health/CheckHealth';
import { Route, Routes } from 'react-router-dom';

const Login = reactLazyPreload(
  () => import(/* webpackChunkName: "Login", webpackPrefetch: true */ '../pages/login/Login.page')
);

const SetNewPasswordPage = reactLazyPreload(
  () =>
    import(
      /* webpackChunkName: "ResetPasswordPage" */ '../pages/set-new-password/SetNewPassword.page'
    )
);

const ForgottenPassword = reactLazyPreload(
  () =>
    import(
      /* webpackChunkName: "ForgottenPassword" */ '../pages/forgotten-password/ForgottenPassword.page'
    )
);

const ResetPasswordPage = reactLazyPreload(
  () =>
    import(/* webpackChunkName: "ResetPasswordPage" */ '../pages/reset-password/ResetPassword.page')
);

const ConfirmInvitation = reactLazyPreload(
  () =>
    import(
      /* webpackChunkName: "ConfirmInvitation" */ '../pages/confirm/confirm-invitation/ConfirmInvitation.page'
    )
);

const ConfirmUserCreation = reactLazyPreload(
  () =>
    import(
      /* webpackChunkName: "ConfirmUserCreation" */ '../pages/confirm/confirm-user-creation/ConfirmUserCreation.page'
    )
);

const ActionResult = reactLazyPreload(
  () => import(/* webpackChunkName: "ActionResult" */ '../pages/action-result/ActionResult.page')
);

// Preload the bundles that likely to be used
Login.preload();

export function AppRoutes() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path={`${LOGIN_ROUTE}/*`} element={<Login />} />
        <Route path={CHECK_HEALTH} element={<CheckHealth />} />
        <Route path={SET_NEW_PASSWORD_ROUTE} element={<SetNewPasswordPage />} />
        <Route
          path={FORGOT_PASSWORD_ROUTE}
          element={
            <PublicOnly>
              <ForgottenPassword />
            </PublicOnly>
          }
        />
        <Route path={RESET_PASSWORD_ROUTE} element={<ResetPasswordPage />} />
        <Route
          path={CONFIRM_INVITATION_ROUTE}
          element={
            <PublicOnly>
              <ConfirmInvitation />
            </PublicOnly>
          }
        />
        <Route path={CONFIRM_USER_CREATION_ROUTE} element={<ConfirmUserCreation />} />
        <Route path={ACTION_RESULT_ROUTE} element={<ActionResult />} />
        <Route
          path='*'
          element={
            <RequireAuth>
              <PrivateRoutes />
            </RequireAuth>
          }
        />
      </Routes>
    </Suspense>
  );
}
