import { Typography, Flex } from '@aircall/tractor-v2';
import { DynamicAssetComponent } from '@components/DynamicAssetComponent';
import { ResellerConfig } from '@config/resellers.config';
import { getPeriodOfDay } from '@dashboard/library';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useTranslation } from 'react-i18next';

export function OnboardingHeader() {
  const {
    currentUser: { firstName },
  } = useGlobalData();
  const { t } = useTranslation();
  const period = getPeriodOfDay(new Date());

  return (
    <Flex
      backgroundColor='base.white'
      alignItems='center'
      justifyContent='space-between'
      pl='40px'
      pr='40px'
      data-test='onboarding-header'
    >
      <Typography variant='headingLightM' data-test='welcome-message'>
        {t('onboarding.welcome_message', { name: firstName })}
      </Typography>
      {period === 'morning' && (
        <DynamicAssetComponent
          name={ResellerConfig.getAssetPath('illustration_morning')}
          height={145}
          data-test='onboarding-period-of-day-image'
        />
      )}
      {period === 'afternoon' && (
        <DynamicAssetComponent
          name={ResellerConfig.getAssetPath('illustration_afternoon')}
          height={145}
          data-test='onboarding-period-of-day-image'
        />
      )}
      {period === 'evening' && (
        <DynamicAssetComponent
          name={ResellerConfig.getAssetPath('illustration_evening')}
          height={145}
          data-test='onboarding-period-of-day-image'
        />
      )}
    </Flex>
  );
}
