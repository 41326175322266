import { IvrSettingModalProps, IvrSettingFormValues } from './IvrSettingModal.decl';

import { PhoneInputField } from '@components/PhoneInputField/PhoneInputField';
import { SearchableNumberSelectField } from '@components/SearchableNumberSelectField/SearchableNumberSelectField';
import {
  GridLayout,
  GridItem,
  SelectField,
  pipeValidators,
  validatePhoneNumberFormat,
  validateRequired,
  ModalForm,
} from '@dashboard/library';
import { formatToE164 } from '@helpers/phoneNumber.helpers';
import { useTranslation } from 'react-i18next';

export const IVR_SETTING_MODAL_TRANS_PREFIX =
  'number_details.settings.call_distribution_section.ivr_setting_modal';

export function IvrSettingModal({
  show,
  onHide,
  values,
  title,
  countryCode,
  keyOptions,
  onSubmit,
  numberId,
}: IvrSettingModalProps) {
  const { t } = useTranslation();

  const isFallback = values.key === 'fallback';

  const optionsList = [
    {
      value: 'internal',
      label: t(`${IVR_SETTING_MODAL_TRANS_PREFIX}.type_options.internal`),
    },
    {
      value: 'external',
      label: t(`${IVR_SETTING_MODAL_TRANS_PREFIX}.type_options.external`),
    },
    {
      value: 'new',
      label: t(`${IVR_SETTING_MODAL_TRANS_PREFIX}.type_options.new`),
    },
  ];
  const typeOptions = optionsList.filter((option) => !(isFallback && option.value === 'new'));

  return (
    <ModalForm<IvrSettingFormValues>
      show={show}
      formProps={{ onSubmit, initialValues: values }}
      onHide={onHide}
      title={title}
    >
      {({ values: { type } }) => (
        <GridLayout>
          {!isFallback && (
            <GridItem xs={12} lg={3}>
              <SelectField
                t={t}
                name='key'
                label={t(`${IVR_SETTING_MODAL_TRANS_PREFIX}.key_label`)}
                options={keyOptions}
              />
            </GridItem>
          )}
          <GridItem xs={12} lg={isFallback ? 12 : 9}>
            <SelectField
              t={t}
              name='type'
              label={t(`${IVR_SETTING_MODAL_TRANS_PREFIX}.type_label`)}
              options={typeOptions}
            />
          </GridItem>

          <GridItem xs={12} sm={12}>
            {type === 'internal' && (
              <SearchableNumberSelectField
                name='internalNumber'
                label={t(`${IVR_SETTING_MODAL_TRANS_PREFIX}.number_label`)}
                idsBlacklist={[numberId]}
              />
            )}
            {type === 'external' && (
              <PhoneInputField
                parse={formatToE164}
                validate={pipeValidators(validateRequired, validatePhoneNumberFormat)}
                name='externalNumber'
                label={t(`${IVR_SETTING_MODAL_TRANS_PREFIX}.number_label`)}
                defaultFlag={countryCode}
                autoFocus={false}
              />
            )}
          </GridItem>
        </GridLayout>
      )}
    </ModalForm>
  );
}
