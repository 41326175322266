import { TrialBannerWithoutCallLimitsProps } from '../TrialBanner.decl';

import { TrialBannerButton } from './TrialBannerButton';

import { Banner, BannerHeading, BannerIcon, BannerSuffix } from '@aircall/tractor-v2';
import { Trans } from 'react-i18next';

export function TrialBannerWithoutCallLimits({
  daysLeftInTrial,
}: TrialBannerWithoutCallLimitsProps) {
  return (
    <Banner data-test='trial-banner-without-call-limits' py='m' variant='warning'>
      <BannerIcon />
      <BannerHeading>
        <Trans i18nKey='trial_banner.days_left' values={{ daysLeftInTrial }}>
          <strong />
        </Trans>
      </BannerHeading>
      <BannerSuffix>
        <TrialBannerButton />
      </BannerSuffix>
    </Banner>
  );
}
