import {
  MusicOnHoldAudioFile,
  MusicOnHoldAudioLibrary,
  MusicOnHoldFormValues,
  MUSIC_ON_HOLD_AUDIO_TYPE,
} from './MusicOnHoldSettings.decl';

import { validateRequired } from '@dashboard/library';

export function getFileNameFromUrl(url: string): string {
  return decodeURI(url.slice(url.lastIndexOf('/') + 1));
}

export function getMusicOnHoldAudioSettingType(url: string) {
  return url.indexOf('/companies/') > 0
    ? MUSIC_ON_HOLD_AUDIO_TYPE.FILE
    : MUSIC_ON_HOLD_AUDIO_TYPE.LIBRARY;
}

export async function getMusicOnHoldAudioSetting(url: string) {
  const type = getMusicOnHoldAudioSettingType(url);
  if (type === MUSIC_ON_HOLD_AUDIO_TYPE.FILE) {
    const data = await fetch(url).then((res) => res.blob());
    return {
      type,
      file: new File([data], getFileNameFromUrl(url)),
    } as MusicOnHoldAudioFile;
  }

  return {
    type,
    url,
  } as MusicOnHoldAudioLibrary;
}

export function validateMusicOnHoldFormValues({ audioSetting }: MusicOnHoldFormValues) {
  return audioSetting.type === MUSIC_ON_HOLD_AUDIO_TYPE.FILE
    ? {
        audioSetting: {
          file: validateRequired(audioSetting.file),
        },
      }
    : {
        audioSetting: {
          url: validateRequired(audioSetting.url),
        },
      };
}

export function getMusicOnHoldAudioFileTrackingType(url: string) {
  return getMusicOnHoldAudioSettingType(url) === MUSIC_ON_HOLD_AUDIO_TYPE.FILE
    ? 'uploaded file'
    : 'library file';
}
