import { NUMBER_PROOF_OF_ID_ROUTE, routeReplacer } from '@dashboard/library';

/**
 * genarate proof of ID link according to FF value
 * @param numberUUID - number UUID of number
 */
export function getProofOfIDLink(numberUUID: string | null): string | null {
  if (numberUUID) {
    return routeReplacer(NUMBER_PROOF_OF_ID_ROUTE, {
      number: numberUUID,
    });
  }
  return null;
}
