/* eslint-disable @typescript-eslint/naming-convention */
import { MouseEvent } from 'react';

import styled from 'styled-components';

import { ExternalLinkProps } from './Link.decl';

import { Link as TractorLink } from '@aircall/tractor-v2';
import { useTracker } from '@hooks/useTracker/useTracker';

export function ExternalLink({ href, extra, onClick, ...rest }: ExternalLinkProps) {
  const { track } = useTracker();

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (href) {
      track({
        event: 'external_link_opened',
        payload: {
          link_destination_domain: href,
          ...(extra || {}),
        },
      });
    }

    onClick?.(event);
  };

  return <TractorLink href={href} {...rest} onClick={handleClick} />;
}

export const UnderlinedExternalLink = styled(ExternalLink)`
  text-decoration: underline;
`;
