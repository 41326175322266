import { useState } from 'react';

import { DownloadDataModal } from '../DownloadDataModal';

import { DataLimitBannerProps } from './DataLimitBanner.decl';

import { Banner, BannerButton, BannerHeading, BannerIcon, BannerSuffix } from '@aircall/tractor-v2';
import { useTranslation } from 'react-i18next';

export function DataLimitBanner({
  imageSrc,
  text,
  translationPrefix,
  visible,
}: DataLimitBannerProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  const handleToggleModal = () => {
    setModalVisible((prev) => !prev);
  };

  return (
    <>
      <DownloadDataModal
        imageSrc={imageSrc}
        onHide={handleToggleModal}
        show={modalVisible}
        translationPrefix={translationPrefix}
      />
      {visible && (
        <Banner data-test='data-limit-banner' variant='info'>
          <BannerIcon />
          <BannerHeading>{text}</BannerHeading>
          <BannerSuffix>
            <BannerButton
              data-test='download-data-modal-trigger'
              mode='link'
              onClick={handleToggleModal}
            >
              {t('calls.pages.call_history.download_modal.link')}
            </BannerButton>
          </BannerSuffix>
        </Banner>
      )}
    </>
  );
}
