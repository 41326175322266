import { useCallback, useMemo, ComponentType, SVGProps } from 'react';

import styled from 'styled-components';

import { ExternalOutlined } from '@aircall/icons';
import { Avatar, AvatarIcon, Flex, Spacer, Typography } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { ResellerConfig } from '@config/resellers.config';
import { useTranslation } from 'react-i18next';

type ResourceLinkCardProps = {
  resourceName: string;
  onClick?: () => void;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
};

const StyledLink = styled(ExternalLink)`
  &:hover {
    text-decoration: none;
  }
`;

export function ResourceLinkCard({ resourceName, icon, onClick }: ResourceLinkCardProps) {
  const { t } = useTranslation();
  const onClickHandler = useCallback(
    (e) => {
      if (onClick) {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }
    },
    [onClick]
  );
  const href = useMemo(() => {
    if (onClick) {
      return '';
    }

    return t(`resellers.${ResellerConfig.identifier}.onboarding.resource.${resourceName}_link`);
  }, [onClick, resourceName, t]);

  const hasCaption = !!icon;
  const textColor = icon ? 'text-base' : 'interaction-primary';

  return (
    <StyledLink onClick={onClickHandler} data-test='resource-card-link' target='_blank' href={href}>
      <Flex
        className='resource-card-link-container'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        px='20px'
        h='80px'
      >
        <Spacer direction='horizontal' space='xxxs' alignItems='center'>
          {icon && (
            <Avatar size='large' variant='default' data-test='resource-link-icon'>
              <AvatarIcon icon={icon} />
            </Avatar>
          )}
          <Spacer direction='vertical' marginLeft='8px'>
            <Typography variant='bodyMediumS' color={textColor}>
              {t(`onboarding.resource.${resourceName}_name`)}
            </Typography>
            {hasCaption && (
              <Typography variant='supportingRegularS' color='black'>
                {t(`onboarding.resource.${resourceName}_caption`)}
              </Typography>
            )}
          </Spacer>
        </Spacer>
        <ExternalOutlined width='18px' height='18px' color='neutral-700' />
      </Flex>
    </StyledLink>
  );
}
