import { gql } from '@apollo/client';

export const ADD_DISPATCH_GROUP_MUTATION = gql`
  mutation AddDispatchGroupMutation($numberId: String!, $input: UpdateDispatchGroupInput!) {
    addDispatchGroup(numberId: $numberId, input: $input)
      @rest(
        endpoint: "v4UpdateDispatchGroup"
        path: "numbers/{args.numberId}/dispatch_groups?include=dispatchable"
        type: "DispatchGroup"
        method: "POST"
      ) {
      id
      priority
      queuingTimeout
      redirectionStrategy
      dispatchable {
        ... on User {
          id
        }
        ... on Team {
          ID
          agentsIds
        }
      }
    }
  }
`;
