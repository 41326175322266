import { useCallback, useMemo, memo } from 'react';

import styled from 'styled-components';

import { UsersCountTag } from './UsersCountTag.container';

import { InformationFilled, TickCircleFilled } from '@aircall/icons';
import { Box, Flex, Icon, Spacer, Tag, Tooltip, Typography, FlagIcon } from '@aircall/tractor-v2';
import { Card } from '@components/Card';
import { LineCapabilitiesTag } from '@components/LineCapabilitiesTag/LineCapabilitiesTag';
import { NumberVerificationStateTag } from '@components/NumberVerificationStateTag';
import { RealtimeBusinessHourState } from '@components/RealtimeBusinessHoursState/RealtimeBusinessHoursState';
import { TooltipOnTruncate } from '@components/TooltipOnTruncateV2';
import { LINE_ACTIVATION_STATE } from '@constants/numbers.constants';
import { LineState } from '@generated/globalTypes';
import { SearchLineByIDQuery_searchLines_items as Line } from '@generated/SearchLineByIDQuery';
import { formatInternational } from '@helpers/phoneNumber.helpers';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useTranslation } from 'react-i18next';

interface NumberCardProps {
  id: string;
  activationState: string | null;
  usersCount: number | null;
  countryCode: string;
  isIVR: boolean;
  name: string;
  phoneNumber: string;
  state: LineState | null;
  verificationState: string | null;
  capabilities: Line['capabilities'];
  'data-test'?: string;
  isChecked?: boolean;
  onClick?: (lineID: string) => void;
  hideState?: boolean;
  numberUUID?: string | null;
  flowEditorEnabled: boolean | null;
}

const CheckIcon = styled(Icon).attrs({
  component: TickCircleFilled,
  size: 34,
  color: 'interaction-primary',
})`
  position: relative;
  z-index: 2;
`;

const CheckIconBackground = styled(Box)`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  display: block;
  width: 15px;
  height: 15px;
  background-color: white;
  transform: translate(-50%, -50%);
`;

export const NumberCard = memo(
  ({
    id,
    activationState,
    capabilities,
    usersCount,
    countryCode,
    isIVR,
    state,
    name,
    phoneNumber,
    verificationState,
    isChecked = false,
    hideState = false,
    'data-test': dataTest,
    onClick,
    numberUUID,
    flowEditorEnabled,
  }: NumberCardProps) => {
    const { t } = useTranslation();
    const { smartflowMigrationEnabled } = useFeatures();

    const handleClick = useCallback(() => {
      onClick && onClick(id);
    }, [id, onClick]);

    const footer = useMemo(
      () => (
        <Flex px='s' alignItems='center' h='48px' justifyContent='space-between'>
          {activationState === LINE_ACTIVATION_STATE.INACTIVE ? (
            <NumberVerificationStateTag
              number={phoneNumber}
              data-test='inactive-number-card-footer'
              activationState={activationState}
              verificationState={verificationState}
              id={numberUUID}
            />
          ) : (
            !hideState && (
              <RealtimeBusinessHourState
                state={state}
                lineID={id}
                flowEditorEnabled={flowEditorEnabled}
              />
            )
          )}
          {!isIVR && <UsersCountTag usersCount={usersCount || 0} data-test='users-count-tag' />}
        </Flex>
      ),
      [
        activationState,
        phoneNumber,
        verificationState,
        hideState,
        state,
        id,
        isIVR,
        usersCount,
        numberUUID,
        flowEditorEnabled,
      ]
    );

    const subtitle = useMemo(
      () => (
        <Spacer space='xxs' alignItems='center'>
          <LineCapabilitiesTag capabilities={capabilities} />
          {isIVR && (
            <Tag size='small' mode='outline' variant='white'>
              {t('numbers.card.ivr_tag')}
            </Tag>
          )}
          <Typography variant='bodyMediumS' color='text-base'>
            {formatInternational(phoneNumber)}
          </Typography>
        </Spacer>
      ),
      [capabilities, isIVR, t, phoneNumber]
    );

    return (
      <Card
        avatar={<FlagIcon countryCode={countryCode} size={48} />}
        footer={footer}
        onClick={handleClick}
        h='100%'
        data-test={dataTest}
        hasShadow={isChecked}
      >
        <Box overflow='hidden'>
          <Flex w='100%'>
            <Box overflow='hidden' flex={1}>
              <TooltipOnTruncate title={name}>
                <Typography variant='headingBoldXS' ellipsis={1} mb='xxs' color='text-base'>
                  {name}
                </Typography>
              </TooltipOnTruncate>
            </Box>
            {smartflowMigrationEnabled && !flowEditorEnabled && (
              <Box w='24' data-test='smartflow-migration-tooltip'>
                <Tooltip title={t('numbers.card.smartflow_migration')}>
                  <Icon component={InformationFilled} color='informative-500' />
                </Tooltip>
              </Box>
            )}
          </Flex>
          {subtitle}
        </Box>
        {isChecked && (
          <Box position='absolute' top={-15} right={-15} data-test='tick-icon'>
            <CheckIconBackground />
            <CheckIcon />
          </Box>
        )}
      </Card>
    );
  }
);
