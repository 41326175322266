import { useCallback } from 'react';

import { TrashOutlined } from '@aircall/icons';
import { Box, Flex, Button, Spacer, Typography, useColor, getColor } from '@aircall/tractor-v2';
import { AudioPlayer } from '@components/AudioPlayer';
import { Dropzone } from '@components/DropzoneV2';
import { ExternalLink } from '@components/Link';
import { MAX_AUDIO_SIZE } from '@constants/files.constants';
import { RECORD_AUDIO_FILE_KNOWLEDGE_BASE_URL } from '@constants/urls.constants';
import { useTracker } from '@hooks/useTracker/useTracker';
import styled from '@xstyled/styled-components';
import capitalize from 'lodash-es/capitalize';
import { useField, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const TRANSLATION_PREFIX = 'number_details.settings.music_on_hold_section.file_uploader';

const AudioPlayerContainer = styled(Box)`
  border: 1px solid ${getColor('graphic-default')};
  border-radius: sm;
  background-color: 'surface-default';
`;

export function AudioSettingFileField({ name }: { name: string }) {
  const { t } = useTranslation();
  const borderColor = useColor('graphic-default');
  const { track } = useTracker();
  const {
    input: { value, onChange },
  } = useField(name);
  const form = useForm();

  const handleDrop = useCallback(
    ([acceptedFile]) => {
      track({ event: 'recording_upload_started' });
      onChange(acceptedFile);
    },
    [onChange, track]
  );
  const handleError = useCallback(() => {
    track({ event: 'recording_upload_error' });
  }, [track]);
  const handleFileReset = useCallback(() => {
    form.change(name, undefined);
  }, [form, name]);

  return (
    <Box data-test='audio-setting-file-field'>
      {value !== '' ? (
        <AudioPlayerContainer data-test='audio-setting-file-field-audio-player'>
          <Flex alignItems='center' justifyContent='space-between' pl='s' pr='xxs' py='xxs'>
            <Typography variant='bodyRegularS'>{value.name}</Typography>
            <Button
              variant='secondary'
              mode='ghost'
              type='button'
              onClick={handleFileReset}
              data-test='audio-setting-file-field-reset-button'
            >
              <TrashOutlined />
            </Button>
          </Flex>
          <Box pl={10} pr='s' py='xs' borderTop={`1px solid ${borderColor}`}>
            <AudioPlayer url={URL.createObjectURL(value)} download={value.name} />
          </Box>
        </AudioPlayerContainer>
      ) : (
        <Spacer direction='vertical' space='s' fluid data-test='audio-setting-file-field-dropzone'>
          <Dropzone
            dropzoneOptions={{
              onDrop: handleDrop,
              onDropRejected: handleError,
              maxFiles: 1,
              accept: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'audio/mpeg': ['.mp3'],
              },
              maxSize: MAX_AUDIO_SIZE,
            }}
            errorText={capitalize(t(`${TRANSLATION_PREFIX}.dropzone.secondary_text`))}
            variant='primary'
          >
            <Typography variant='bodySemiboldM' color='text-secondary' mb='s'>
              {t(`${TRANSLATION_PREFIX}.dropzone.primary_text`)}
            </Typography>
            <Typography variant='bodyMediumS' color='text-secondary'>
              {t(`${TRANSLATION_PREFIX}.dropzone.secondary_text`)}
            </Typography>
          </Dropzone>
          <ExternalLink href={RECORD_AUDIO_FILE_KNOWLEDGE_BASE_URL} target='_blank'>
            {t(`${TRANSLATION_PREFIX}.knowledge_base_link`)}
          </ExternalLink>
        </Spacer>
      )}
    </Box>
  );
}
