import {
  SearchableSortDirection,
  SearchableLinesSortInput,
  SearchableLinesSortableFields,
  SearchableAgentsSortInput,
  SearchableAgentsSortableFields,
  SearchableTeamsSortInput,
  SearchableTeamsSortableFields,
} from '@generated/globalTypes';

export const DEFAULT_AGENTS_SORT_DIRECTION = SearchableSortDirection.asc;
export const DEFAULT_AGENTS_SORT_FIELD = SearchableAgentsSortableFields.firstName;
export const DEFAULT_AGENTS_SORT: SearchableAgentsSortInput[] = [
  { direction: DEFAULT_AGENTS_SORT_DIRECTION, field: DEFAULT_AGENTS_SORT_FIELD },
];

export const DEFAULT_TEAMS_SORT_DIRECTION = SearchableSortDirection.asc;
export const DEFAULT_TEAMS_SORT_FIELD = SearchableTeamsSortableFields.name;
export const DEFAULT_TEAMS_SORT: SearchableTeamsSortInput[] = [
  { direction: DEFAULT_TEAMS_SORT_DIRECTION, field: DEFAULT_TEAMS_SORT_FIELD },
];

export const DEFAULT_LINES_SORT_DIRECTION = SearchableSortDirection.asc;
export const DEFAULT_LINES_SORT_FIELD = SearchableLinesSortableFields.name;
export const DEFAULT_LINES_SORT: SearchableLinesSortInput[] = [
  { direction: DEFAULT_LINES_SORT_DIRECTION, field: DEFAULT_LINES_SORT_FIELD },
];

export enum APPLICATIONS_SCOPES {
  PUBLIC_API = 'public_api',
}

export enum BUSINESS_HOURS_TYPE {
  AGENTS = 'agents',
  LINES = 'lines',
}
export const SEARCH_ITEMS_LIMIT = 50;
