import { useUpdateNumberToggles } from '../hooks/useUpdateNumberToggles';

import { ToggleRow } from '@components/ToggleRow';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { List, Paper, ListItem } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function CallDistributionOptionsSettings() {
  const { t } = useTranslation();
  const { updateNumberToggles, number } = useUpdateNumberToggles({});

  return (
    <Paper title={t('number_details.settings.call_distribution_options.title')}>
      <List hasScroll={false}>
        <ListItem h='auto'>
          <ToggleRow
            size='large'
            data-test='call-distribution-options-respect-queueing-time-settings-toggle'
            value={!!number?.respectQueueingTime}
            onChange={(isActivated) =>
              updateNumberToggles(
                {
                  respectQueueingTime: isActivated,
                },
                {
                  successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
                    name: t(
                      `number_details.settings.call_distribution_options.respect_queueing_time`
                    ),
                  }),
                  trackingOptions: {
                    section: 'Settings',
                    setting: 'Call distribution - respect queueing time',
                  },
                }
              )
            }
            title={t('number_details.settings.call_distribution_options.respect_queueing_time')}
            description={t('number_details.settings.call_distribution_options.description')}
          />
        </ListItem>
        <ListItem h='auto'>
          <ToggleRow
            size='large'
            data-test='call-distribution-options-priority-settings-toggle'
            value={!!number?.priority}
            onChange={(isActivated) =>
              updateNumberToggles(
                {
                  priority: isActivated ? 1 : null,
                },
                {
                  successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
                    name: t(`number_details.settings.call_distribution_options.priority`),
                  }),
                  trackingOptions: { section: 'Settings', setting: 'Call distribution - priority' },
                }
              )
            }
            title={t('number_details.settings.call_distribution_options.priority')}
            description={t(
              'number_details.settings.call_distribution_options.priority_description'
            )}
          />
        </ListItem>
      </List>
    </Paper>
  );
}
