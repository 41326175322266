import { LimitationModalProps } from './LimitationModal.decl';

import {
  Flex,
  ModalBody,
  ModalClose,
  ModalDialog,
  ModalFooter,
  Typography,
} from '@aircall/tractor-v2';
import { ReactComponent as WomanComputer } from '@assets/images/woman_computer.svg';
import { UpgradePlanButton } from '@components/UpgradePlanButton/UpgradePlanButton';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useTranslation } from 'react-i18next';

export function LimitationModal({ show, onHide, title }: LimitationModalProps) {
  const { isCompanyAccountAllowed } = useFeatures();
  const { t } = useTranslation();

  const description = isCompanyAccountAllowed
    ? t('limitation_modal.general_description')
    : t('limitation_modal.reseller_description');

  return (
    <ModalDialog show={show} onHide={onHide} size='small'>
      <ModalClose />
      <ModalBody px='m' pb={48}>
        <Flex flexDirection='column' alignItems='center'>
          <WomanComputer height='218px' />
          <Typography variant='headingBoldXS' pt='xl' textAlign='center'>
            {title}
          </Typography>
          <Typography pt='s'>{description}</Typography>
        </Flex>
      </ModalBody>
      {isCompanyAccountAllowed && (
        <ModalFooter>
          <UpgradePlanButton />
        </ModalFooter>
      )}
    </ModalDialog>
  );
}
