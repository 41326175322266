import { AudioPlaybackRow } from './AudioSetting/AudioPlaybackRow';
import { TRANSLATION_PREFIX } from './config';
import { DiagramBranch, DiagramCard } from './FlowDiagram';

import { NumberDetailQuery_numberDetail } from '@generated/NumberDetailQuery';
import { useTranslation } from 'react-i18next';

export interface WelcomeMusicCardProps {
  numberDetail: NumberDetailQuery_numberDetail;
}

export function IvrNumberAudiosCard({ numberDetail }: WelcomeMusicCardProps) {
  const { t } = useTranslation();

  return (
    <DiagramBranch mb={48}>
      <DiagramCard data-test='welcome-music-card'>
        <AudioPlaybackRow
          name='welcomeMessage'
          title={t(`${TRANSLATION_PREFIX}.audios_card.welcome_message`)}
          url={numberDetail.welcomeMessage}
          disabled={!numberDetail.welcomeMessageEnabled}
          data-test='welcome-message'
        />
        <AudioPlaybackRow
          name='ivrMessage'
          title={t(`${TRANSLATION_PREFIX}.audios_card.ivr_message`)}
          url={numberDetail.ivrMessage}
          data-test='ivr-message'
        />
      </DiagramCard>
    </DiagramBranch>
  );
}
