import { useContext } from 'react';

import { GlobalContextType } from '../../state/app/global/global.decl';
import { GlobalDataContext } from '../../state/app/global/GlobalProvider';

export function useGlobalData(): GlobalContextType {
  const globalDataContext = useContext(GlobalDataContext);

  if (!globalDataContext) {
    throw new Error(
      'Your component should be wrapped by a `GlobalDataProvider` to be able to use the `useGlobalData` hook'
    );
  }

  return globalDataContext;
}
