import React, { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';

import {
  AuthenticationActionType,
  AuthenticationContext,
  AuthenticationState,
  AUTHENTICATION_STATUS,
  AUTH_STATES,
  AUTH_STRATEGY,
  SetSignInSuccessParams,
  MFAFormValues,
  MfaErrorResponse,
} from './authentication.decl';

import { ResellerConfig } from '@config/resellers.config';
import { COOKIE_KEYS } from '@constants/auth.constants';
import { AIRCALL_DASHBOARD_URL } from '@constants/environment.constants';
import { BACKEND_ERRORS_TRANSLATION_KEYS } from '@constants/errors.constants';
import {
  LOGGING_FEATURE_NAMES,
  LOGGING_AUTHENTICATION_EVENTS,
  LOGGING_STATUS,
} from '@constants/logging.constants';
import { LOGIN_SSO_ROUTE } from '@constants/routes.constants';
import { Loading } from '@dashboard/library';
import {
  clearCookies,
  decodeToken,
  removeCookie,
  setConnectedAsCookies,
  setCookie,
  updateToken,
} from '@helpers/authentication.helpers';
import { NetworkError } from '@helpers/errors.helpers';
import { createLog } from '@helpers/logging.helpers';
import { LoginFormValues } from '@pages/login/PasswordLoginSection/PasswordLoginForm.container';
import { CredentialResponse, googleLogout } from '@react-oauth/google';
import {
  deleteCredentials,
  sessionFromGoogleToken,
  getCognitoTokenFromNotMigratedCompanies,
  requestRefreshToken,
  fetchSsoIdpCallback,
  sessionFromSsoCode,
  sessionFromCredentialsForMFA,
  sessionFromMFACode,
  resendMFACode,
  sessionFromCredentials,
} from '@services/auth';
import { CognitoMFATokenResponse, CognitoTokenResponse } from '@services/auth.decl';
import Cookies from 'js-cookie';

export function isMfaErrorObject(err: unknown): err is MfaErrorResponse {
  return (
    typeof err === 'object' && typeof (err as MfaErrorResponse)?.body?.error?.name === 'string'
  );
}

export const getErrorMessage = (errorResponse: unknown): string =>
  isMfaErrorObject(errorResponse)
    ? errorResponse.body.error.name
    : (errorResponse as Error)?.message;

export const authenticationState: AuthenticationState = {
  signingIn: false,
  status: AUTHENTICATION_STATUS.UNKNOWN,
  sessionExpired: false,
  signedInError: null,
  signedInStrategy: null,
  isConnectedAs: false,
  token: null,
  email: null,
  sessionTokenForMFA: null,
  mfaInitiated: false,
  legacyToken: null,
  isCognitoMigrated: false,
};

export function authenticationReducer(
  state: AuthenticationState,
  action: AuthenticationActionType
): AuthenticationState {
  switch (action.type) {
    case AUTH_STATES.SIGN_IN:
      return {
        ...state,
        signingIn: true,
        status: AUTHENTICATION_STATUS.NOT_AUTHENTICATED,
        sessionExpired: false,
        signedInError: null,
      };
    case AUTH_STATES.SIGN_IN_SUCCESS: {
      const { isConnectedAs, token, legacyToken, decodedToken, strategy } = action.payload;

      return {
        ...state,
        signingIn: false,
        signedInStrategy: strategy,
        status: AUTHENTICATION_STATUS.AUTHENTICATED,
        isConnectedAs,
        token,
        legacyToken,
        isCognitoMigrated: !legacyToken,
        decodedToken,
      };
    }
    case AUTH_STATES.SIGN_IN_ERROR:
      return {
        ...state,
        signingIn: false,
        status: AUTHENTICATION_STATUS.NOT_AUTHENTICATED,
        signedInError: action.payload.message,
        signedInStrategy: action.payload.strategy,
      };
    case AUTH_STATES.REFRESH_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case AUTH_STATES.MFA_SESSION_TOKEN:
      return {
        ...state,
        email: action.payload.email,
        sessionTokenForMFA: action.payload.session,
        mfaInitiated: action.payload.isMFAInitiated,
        signingIn: false,
        signedInError: null,
      };
    case AUTH_STATES.SESSION_EXPIRED:
      return {
        ...state,
        signingIn: false,
        status: AUTHENTICATION_STATUS.NOT_AUTHENTICATED,
        sessionExpired: true,
        signedInError: action.payload.message,
      };
    case AUTH_STATES.SIGN_OUT:
      return {
        ...authenticationState,
        status: AUTHENTICATION_STATUS.NOT_AUTHENTICATED,
      };
    /* istanbul ignore next */
    default:
      throw new Error('Unexpected authentication action type');
  }
}

export const AuthenticationStateContext = createContext<AuthenticationContext>(
  {} as AuthenticationContext
);

export function AuthenticationProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [authState, dispatch] = useReducer(authenticationReducer, authenticationState);

  const {
    featureSet: { useEmailMfa },
  } = ResellerConfig;

  const setSignInError = useCallback(
    (message: string | null, strategy = AUTH_STRATEGY.PASSWORD) => {
      dispatch({ type: AUTH_STATES.SIGN_IN_ERROR, payload: { message, strategy } });
    },
    []
  );

  const setMultiFactorAuthParams = useCallback(
    (email: string | null, session: string | null, isMFAInitiated: boolean = false) => {
      dispatch({
        type: AUTH_STATES.MFA_SESSION_TOKEN,
        payload: { email, session, isMFAInitiated },
      });
    },
    []
  );

  const setSignInSuccess = useCallback(
    ({
      response,
      legacyToken = null,
      isConnectedAs = false,
      strategy = AUTH_STRATEGY.PASSWORD,
    }: SetSignInSuccessParams) => {
      updateToken(response);

      if (legacyToken) {
        setCookie(COOKIE_KEYS.LEGACY_TOKEN, legacyToken);
      }

      if (isConnectedAs) {
        setCookie(COOKIE_KEYS.IS_CONNECTED_AS, 'true');
      }

      setCookie(COOKIE_KEYS.AUTH_STRATEGY, strategy);

      const decodedToken = decodeToken(response.idToken);
      dispatch({
        type: AUTH_STATES.SIGN_IN_SUCCESS,
        payload: { token: response.idToken, legacyToken, isConnectedAs, decodedToken, strategy },
      });

      createLog({
        featureName: LOGGING_FEATURE_NAMES.AUTHENTICATION,
        event: LOGGING_AUTHENTICATION_EVENTS.SIGN_IN,
        status: LOGGING_STATUS.SUCCESS,
      });
    },
    []
  );

  const signInAsNotMigrated = useCallback(
    async (legacyToken: string, isConnectedAs = false, strategy = AUTH_STRATEGY.PASSWORD) => {
      dispatch({ type: AUTH_STATES.SIGN_IN });

      try {
        const resultNotMigrated = await getCognitoTokenFromNotMigratedCompanies(legacyToken);
        if (!resultNotMigrated) {
          throw new Error('Error with authentication.');
        }
        return setSignInSuccess({
          response: resultNotMigrated,
          legacyToken,
          isConnectedAs,
          strategy,
        });
      } catch (error) {
        return setSignInError(BACKEND_ERRORS_TRANSLATION_KEYS.NETWORK_ERROR, strategy);
      }
    },
    [setSignInError, setSignInSuccess]
  );

  const signInSsoToIdp = useCallback(
    async (email: string) => {
      const strategy = AUTH_STRATEGY.SAML;

      dispatch({ type: AUTH_STATES.SIGN_IN });

      try {
        const result = await fetchSsoIdpCallback(email);

        if (!result) {
          throw new Error();
        }

        const redirectUrl = new URL(result.url);
        const redirectUri = AIRCALL_DASHBOARD_URL + LOGIN_SSO_ROUTE;
        setCookie(COOKIE_KEYS.SAML_LOGOUT_URL, result.logoutUrl);

        redirectUrl.searchParams.set('redirect_uri', redirectUri);

        window.location.href = redirectUrl.toString();
      } catch (e) {
        setSignInError(BACKEND_ERRORS_TRANSLATION_KEYS.CREDENTIALS_ERROR, strategy);
      }
    },
    [setSignInError]
  );

  const signInSsoWithCode = useCallback(
    async (code: string) => {
      const strategy = AUTH_STRATEGY.SAML;

      dispatch({ type: AUTH_STATES.SIGN_IN });

      try {
        const result = await sessionFromSsoCode(
          code,
          `${window.location.origin}${LOGIN_SSO_ROUTE}`
        );

        setSignInSuccess({ response: result, strategy });
      } catch (error) {
        setSignInError(BACKEND_ERRORS_TRANSLATION_KEYS.UNKNOWN_ERROR, strategy);
      }
    },
    [setSignInError, setSignInSuccess]
  );

  const validateMultiFactorAuthCode = useCallback(
    async (credentials: MFAFormValues) => {
      dispatch({ type: AUTH_STATES.SIGN_IN });
      const { email, code, session } = credentials;
      // mfa session token is used to issue user token if code is valid
      try {
        const result = await sessionFromMFACode({
          email,
          code,
          session,
        });

        setSignInSuccess({
          response: result as CognitoTokenResponse,
        });
      } catch (error) {
        setSignInError(getErrorMessage(error));
      }
    },
    [setSignInError, setSignInSuccess]
  );

  const resendMultiFactorAuthCode = useCallback(
    async (email: string): Promise<boolean> => {
      dispatch({ type: AUTH_STATES.SIGN_IN });

      try {
        await resendMFACode(email);
      } catch (error) {
        setSignInError(getErrorMessage(error));
        return false;
      }
      return true;
    },
    [setSignInError]
  );

  const signIn = useCallback(
    async (
      credentials: LoginFormValues | string,
      strategy = AUTH_STRATEGY.PASSWORD
    ): Promise<void> => {
      dispatch({ type: AUTH_STATES.SIGN_IN });
      try {
        let result: null | CognitoTokenResponse | CognitoMFATokenResponse = null;

        if (strategy === AUTH_STRATEGY.PASSWORD) {
          const loginCredentials = credentials as LoginFormValues;
          if (useEmailMfa) {
            result = await sessionFromCredentialsForMFA(loginCredentials);

            // if user's company has MFA enabled, only MFA-session token is issued
            if (result?.mfa) {
              // initiate MFA verification, security code (aka One Time Password) sent on user email
              setMultiFactorAuthParams(loginCredentials.email, result.mfa.session, true);
              return;
            }
            // user when company's Email MFA is found to be disabled
            result = result?.basic!;
          } else {
            result = await sessionFromCredentials(loginCredentials);
          }
        } else if (strategy === AUTH_STRATEGY.GOOGLE) {
          result = await sessionFromGoogleToken(credentials as string);
        }

        if (!result) {
          throw new Error('Error with authentication.');
        }

        // We ignore the coverage of the else because it will never happen
        // as SAML signin will not go through this function
        /* istanbul ignore else */
        if (strategy !== AUTH_STRATEGY.SAML) {
          removeCookie(COOKIE_KEYS.SAML_LOGOUT_URL);
        }

        if (result.idToken) {
          setSignInSuccess({ response: result, strategy });
          return;
        }

        // if user succeeds to login without getting a idToken in response, this user is probably not migrated
        const legacyToken = result.accessToken;
        signInAsNotMigrated(legacyToken);
      } catch (error) {
        createLog({
          featureName: LOGGING_FEATURE_NAMES.AUTHENTICATION,
          event: LOGGING_AUTHENTICATION_EVENTS.SIGN_IN,
          status: LOGGING_STATUS.FAILED,
          properties: { error, variables: credentials },
        });
        if (error instanceof NetworkError) {
          setSignInError(BACKEND_ERRORS_TRANSLATION_KEYS.NETWORK_ERROR, strategy);
        } else {
          setSignInError(BACKEND_ERRORS_TRANSLATION_KEYS.CREDENTIALS_ERROR, strategy);
        }
      }
    },
    [setSignInError, setSignInSuccess, signInAsNotMigrated, setMultiFactorAuthParams, useEmailMfa]
  );

  const checkAuth = useCallback(async () => {
    let impersonateToken;
    if (window.location.hash.includes(COOKIE_KEYS.IMPERSONATE_TOKEN)) {
      /* Connect as 1st method = impersonate token from hash fragment */
      const url = new URL(window.location.href);
      const hashParams = new URLSearchParams(url.hash.slice(1));

      impersonateToken = hashParams.get(COOKIE_KEYS.IMPERSONATE_TOKEN);
    } else {
      /* Connect as 2nd method = impersonate token from cookies */
      impersonateToken = Cookies.get(COOKIE_KEYS.IMPERSONATE_TOKEN);
    }

    if (impersonateToken) {
      setConnectedAsCookies(impersonateToken);
    }

    /* Connect as 3rd method = url params */
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    const { connect_as: connectAsParam, access_token: legacyTokenParam } = urlParams;
    if (connectAsParam && legacyTokenParam) {
      return signInAsNotMigrated(legacyTokenParam, true);
    }

    /* Normal authentication with token cookie */
    const token = Cookies.get(COOKIE_KEYS.TOKEN) || null;
    const legacyToken = Cookies.get(COOKIE_KEYS.LEGACY_TOKEN) || null;
    const isConnectedAs = Cookies.get(COOKIE_KEYS.IS_CONNECTED_AS) === 'true';
    if (token) {
      const strategy = Cookies.get(COOKIE_KEYS.AUTH_STRATEGY) as AUTH_STRATEGY;
      const decodedToken = decodeToken(token);

      return dispatch({
        type: AUTH_STATES.SIGN_IN_SUCCESS,
        payload: {
          token,
          legacyToken,
          isConnectedAs,
          decodedToken,
          strategy,
        },
      });
    }

    return dispatch({
      type: AUTH_STATES.SIGN_OUT,
    });
  }, [signInAsNotMigrated]);

  const signInWithGoogle = useCallback(
    (credentialResponse: CredentialResponse) => {
      const strategy = AUTH_STRATEGY.GOOGLE;

      if (!credentialResponse.credential) {
        setSignInError(BACKEND_ERRORS_TRANSLATION_KEYS.CREDENTIALS_ERROR, strategy);
        return;
      }

      dispatch({ type: AUTH_STATES.SIGN_IN });
      signIn(credentialResponse.credential, strategy);
    },
    [setSignInError, signIn]
  );

  const signOut = useCallback(async () => {
    const authStrategy = Cookies.get(COOKIE_KEYS.AUTH_STRATEGY);
    if (authStrategy && authStrategy === AUTH_STRATEGY.GOOGLE) {
      googleLogout();
    }

    // make the API call to do the real sign out
    try {
      await deleteCredentials();
      createLog({
        featureName: LOGGING_FEATURE_NAMES.AUTHENTICATION,
        event: LOGGING_AUTHENTICATION_EVENTS.SIGN_OUT,
        status: LOGGING_STATUS.SUCCESS,
      });
    } catch (error) {
      /* istanbul ignore next */
      // eslint-disable-next-line no-console
      createLog({
        featureName: LOGGING_FEATURE_NAMES.AUTHENTICATION,
        event: LOGGING_AUTHENTICATION_EVENTS.SIGN_OUT,
        status: LOGGING_STATUS.FAILED,
        properties: { error },
      });
    }

    clearCookies();

    const logoutUrl = Cookies.get(COOKIE_KEYS.SAML_LOGOUT_URL);
    if (logoutUrl) {
      return window.location.replace(logoutUrl);
    }

    dispatch({ type: AUTH_STATES.SIGN_OUT });
    return undefined;
  }, []);

  const refreshToken = useCallback(async () => {
    const token = Cookies.get('token');
    const rToken = Cookies.get('refreshToken');

    if (!token || !rToken) {
      throw new Error('No token or refresh token.');
    }

    const res = await requestRefreshToken({
      isMigrated: authState.isCognitoMigrated,
      token,
      refreshToken: rToken,
    });

    updateToken(res);

    return dispatch({ type: AUTH_STATES.REFRESH_TOKEN, payload: { token: res.idToken } });
  }, [authState.isCognitoMigrated]);

  const onSessionExpired = useCallback(() => {
    dispatch({
      type: AUTH_STATES.SESSION_EXPIRED,
      payload: { message: 'login.message.session_expired' },
    });
    createLog({
      featureName: LOGGING_FEATURE_NAMES.AUTHENTICATION,
      event: LOGGING_AUTHENTICATION_EVENTS.SESSION_EXPIRED,
      status: LOGGING_STATUS.SUCCESS,
    });
    clearCookies();
  }, []);

  const value = useMemo(
    () => ({
      authState,
      actions: {
        signIn,
        signInSsoToIdp,
        signInSsoWithCode,
        signOut,
        setSignInSuccess,
        validateMultiFactorAuthCode,
        resendMultiFactorAuthCode,
        setMultiFactorAuthParams,
        onSessionExpired,
        refreshToken,
        signInWithGoogle,
        setSignInError,
      },
    }),
    [
      authState,
      signIn,
      signInSsoToIdp,
      signInSsoWithCode,
      signOut,
      setSignInSuccess,
      validateMultiFactorAuthCode,
      resendMultiFactorAuthCode,
      setMultiFactorAuthParams,
      onSessionExpired,
      refreshToken,
      signInWithGoogle,
      setSignInError,
    ]
  );

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  // We need this loading otherwise this provider re-renders
  // when loaded is true and refetch global state
  if (authState.status === AUTHENTICATION_STATUS.UNKNOWN) {
    return <Loading data-test='auth-loading' />;
  }

  return (
    <AuthenticationStateContext.Provider value={value}>
      {children}
    </AuthenticationStateContext.Provider>
  );
}
