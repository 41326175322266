import { gql } from '@apollo/client';

export const USER_MINIMAL_FRAGMENT = gql`
  fragment UserMinimalFragment on AgentV2 {
    ID
    businessHours {
      availability
      slots {
        days
        from
        to
      }
      timezone
    }
    companyID
    email
    extension
    firstName
    lastName
    fullName
    isActive
    isAvailable
    pictureURL
    ringTimeout
    role
    roles {
      ID
      description
      name
    }
    state
  }
`;

export const USER_FULL_FRAGMENT = gql`
  fragment UserFullFragment on AgentV2 {
    ...UserMinimalFragment
    activeDialerCampaignID
    authyRegistration
    companyName
    createdAt
    currentRingtone
    customMaxAverageBitrate
    customTwilioEdge
    customTwilioRegion
    defaultOutPhoneNumberID
    defaultPrefix
    deletedAt
    emailNotifications {
      ID
      frequency
      name
    }
    enabledDevices
    extension
    forceAggressiveIceNomination
    intercomHash
    isOnboardedForTeamsInPhoneNumbers
    isOnboardedOnApp
    language
    migratedOnAuthenticationManager
    phoneNumbers {
      ID
      digitConfirmationDisabled
      isEnabled
      phoneNumber
    }
    sipSettings {
      ID
      domain
      isEnabled
      password
      type
      username
    }
    teams {
      ID
      agentsCounter
      agentsIds
      createdAt
      imageName
      name
    }
    timeZone
    unavailableUntil
    unconfirmedEmail
    useAircallPhoneNumberAsCallerID
    wrapUpTime
  }
  ${USER_MINIMAL_FRAGMENT}
`;
