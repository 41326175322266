import {
  NormalizeCompanyAddonsReturn,
  NormalizedCompanyAddon,
  NormalizedCompanyAddonTrial,
} from './useCompanyAddons.decl';

import { ADDONS, ADDON_STATUS } from '@constants/addons.constants';
import {
  CompanyAddonsQuery,
  CompanyAddonsQuery_companyFeatures_addons,
} from '@generated/CompanyAddonsQuery';
import { Maybe } from 'graphql/jsutils/Maybe';

function normalizeCompanyAddonTrials(
  trials?: CompanyAddonsQuery_companyFeatures_addons['trials']
): NormalizedCompanyAddonTrial[] {
  if (!trials) {
    return [];
  }

  return trials.reduce<NormalizedCompanyAddonTrial[]>((acc, curr) => {
    if (!curr) {
      return [...acc];
    }

    return [
      ...acc,
      {
        __typename: curr.__typename ?? undefined,
        id: curr.id ?? undefined,
        active: curr.active ?? false,
        startDate: curr.startDate ?? undefined,
        endDate: curr.endDate ?? undefined,
        remainingDays: curr.remainingDays ?? 0,
      },
    ];
  }, []);
}

function isValidAddonName(
  addon?: CompanyAddonsQuery_companyFeatures_addons['name']
): addon is ADDONS {
  return Object.values(ADDONS).includes(addon as ADDONS);
}

function isValidStatus(
  status?: CompanyAddonsQuery_companyFeatures_addons['status']
): status is ADDON_STATUS {
  return Object.values(ADDON_STATUS).includes(status as ADDON_STATUS);
}

function normalizeCompanyAddon(
  data?: CompanyAddonsQuery_companyFeatures_addons | null
): NormalizedCompanyAddon | undefined {
  if (!data) {
    return undefined;
  }

  return {
    name: isValidAddonName(data.name) ? data.name : undefined,
    status: isValidStatus(data.status) ? data.status : undefined,
    trials: normalizeCompanyAddonTrials(data.trials),
  };
}

function normalizeCompanyAddons(
  addons?: Maybe<CompanyAddonsQuery_companyFeatures_addons>[] | null | undefined
): NormalizedCompanyAddon[] {
  return (addons ?? []).reduce<NormalizedCompanyAddon[]>((acc, curr) => {
    const parsedCompanyAddon = normalizeCompanyAddon(curr);

    if (!parsedCompanyAddon) {
      return [...acc];
    }

    return [...acc, parsedCompanyAddon];
  }, []);
}

/**
 * Normalizes company addons data
 * by applying sensible defaults
 */
export function normalizeCompanyAddonsData(
  data?: CompanyAddonsQuery
): NormalizeCompanyAddonsReturn {
  const addons = normalizeCompanyAddons(data?.companyFeatures?.addons);

  return addons;
}
