import { FeaturesFlagsContextType } from '@components/FeaturesFlagsProvider/FeaturesFlagsProvider.decl';
import {
  APP_CONFIG,
  GAINSIGHT_SCRIPT_SDK_LINK,
  GAINSIGHT_TAG_KEY,
} from '@constants/environment.constants';
import { GAINSIGHT_FEATURE_FLAGS } from '@constants/gainsight.constants';
import { GlobalDataQuery_currentCompany } from '@generated/GlobalDataQuery';
import { GlobalCurrentUser } from '@state/app/global/global.decl';

export const generateIdByEnv = (id: string): string =>
  APP_CONFIG.environment !== 'production' ? `${id}_${APP_CONFIG.environment}` : id;

/**
 * Filters feature flags based on the GAINSIGHT_FEATURE_FLAGS constant
 */
export const flattenFeatureFlags = (
  featureFlags: Partial<FeaturesFlagsContextType>
): Record<string, boolean | null> =>
  Object.entries(featureFlags).reduce((acc: Record<string, boolean | null>, [key, value]) => {
    if (GAINSIGHT_FEATURE_FLAGS.includes(key)) {
      acc[`ff_${key}`] = value;
    }
    return acc;
  }, {});

export const loadGainsightScript = (): Promise<() => void> =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = GAINSIGHT_SCRIPT_SDK_LINK;
    script.async = true;
    document.body.appendChild(script);

    // Initialization of the Gainsight PX script
    window.aptrinsic =
      window.aptrinsic ||
      /* istanbul ignore next */
      // eslint-disable-next-line func-names
      function () {
        /* istanbul ignore next */
        window.aptrinsic.q = window.aptrinsic.q || [];
        /* istanbul ignore next */
        // eslint-disable-next-line prefer-rest-params
        window.aptrinsic.q.push(arguments);
      };
    window.aptrinsic.p = GAINSIGHT_TAG_KEY;

    const cleanup = () => {
      document.body.removeChild(script);
    };
    resolve(cleanup);
  });

export const identify = (
  user: GlobalCurrentUser,
  company: GlobalDataQuery_currentCompany,
  isTrial: boolean,
  featureFlags: Partial<FeaturesFlagsContextType>
): void => {
  const flattenedFeatureFlags = flattenFeatureFlags(featureFlags);
  window?.aptrinsic?.(
    'identify',
    {
      // User Fields
      id: generateIdByEnv(user.ID), // Required for logged in app users
      userId: user.ID, // Not required but this one is needed
      name: user.fullName,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roles: user.roles.map(({ name }) => name),
      trial: isTrial,
      plan: company.plan,
      createOn: user.createdAt,
      city: company.address?.city,
      country: company.address?.country,
      state: company.address?.state,
      zipCode: company.address?.zip,
      language: user.language,
    },
    {
      // Account Fields
      id: generateIdByEnv(company.id.toString()), // Required for logged in app users
      companyId: generateIdByEnv(company.id.toString()),
      companyIdNumber: company.id, // Used for numerical comparisons
      ...flattenedFeatureFlags,
    }
  );
};

export const track = (eventName: string, properties: object = {}): void => {
  window?.aptrinsic?.('track', eventName, properties);
};

export const setGlobalContext = (context: object): void => {
  window?.aptrinsic?.('set', 'globalContext', context);
};
