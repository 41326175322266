import { gql } from '@apollo/client';

export const UPDATE_PUSHER_CONFIG_SUBSCRIPTION = gql`
  subscription UpdatePusherConfigSubscription {
    onNotificationsConfigurationChange {
      appKey
      cluster
    }
  }
`;
