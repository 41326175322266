import { useState } from 'react';

import { Flex } from '@aircall/tractor-v2';
import AVAILABILITIES_DOWNLOAD_IMAGE from '@assets/images/availabilities_download.png';
import { DataLimitBanner } from '@components/AnalyticsDashboard/DataLimitBanner';
import { AnalyticsDashboardType } from '@generated/globalTypes';
import { ANALYTICS_LIMITS } from '@helpers/analytics.helpers';
import { AnalyticsPlusAddonPage } from '@pages/analytics-plus-addon';
import { useTranslation } from 'react-i18next';

export function StatsAvailabilitiesPage() {
  const [bannerVisible, setBannerVisible] = useState(false);
  const { t } = useTranslation();

  const handleTruncated = (truncated: boolean) => {
    setBannerVisible(truncated);
  };

  return (
    <Flex flexDirection='column' h='100%' justifyContent='center'>
      <DataLimitBanner
        imageSrc={AVAILABILITIES_DOWNLOAD_IMAGE}
        text={t('stats.availabilities.banner')}
        translationPrefix='stats.availabilities'
        visible={bannerVisible}
      />
      <Flex flexDirection='column' h='100%' overflow='auto'>
        <AnalyticsPlusAddonPage
          feedbackButtonEvent='user_status_history_feedback_button_clicked'
          maxDateRangeInDays={ANALYTICS_LIMITS.maxDateRangeInDays}
          onTruncated={handleTruncated}
          pageHeaderTitle={t('stats.tabs.availabilities')}
          title='availability'
          type={AnalyticsDashboardType.AVAILABILITY}
        />
      </Flex>
    </Flex>
  );
}
