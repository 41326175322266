import { gql } from '@apollo/client';

export const TEXT_TO_SPEECH_OPTIONS_QUERY = gql`
  query TextToSpeechOptionsQuery($locale: String! = "en-US") {
    textToSpeechOptions(locale: $locale)
      @rest(
        type: "TtsOptions"
        path: "numbers/tts_messages/voices?locale={args.locale}"
        endpoint: "v4TtsOptions"
      ) {
      languages {
        id
        locale
        label
        voices {
          id
          name
          label
        }
      }
    }
  }
`;
