import { Box } from '@aircall/tractor-v2';
import { NumberVerificationStateTag } from '@components/NumberVerificationStateTag/NumberVerificationStateTag';
import { PhoneNumber } from '@components/PhoneNumber';
import { NumberDetailQuery_numberDetail_ivrSettings_redirectingNumber } from '@generated/NumberDetailQuery';

interface IvrNumberContainerProps {
  redirectingExternalNumber: string | null;
  redirectingNumber: NumberDetailQuery_numberDetail_ivrSettings_redirectingNumber | null;
}

export function IvrNumberContainer({
  redirectingExternalNumber,
  redirectingNumber,
}: IvrNumberContainerProps) {
  if (redirectingExternalNumber) {
    return <PhoneNumber number={redirectingExternalNumber} pl='s' />;
  }

  /* istanbul ignore else */
  if (redirectingNumber) {
    const { activationState, number, name, verificationState } = redirectingNumber;
    return (
      <>
        <Box flexGrow={1}>
          <PhoneNumber pl='s' number={number} displayName={name} />
        </Box>
        {activationState === 'inactive' && (
          <Box mr='xxs'>
            <NumberVerificationStateTag
              number={redirectingNumber.number}
              activationState={activationState}
              verificationState={verificationState}
              withText={false}
            />
          </Box>
        )}
      </>
    );
  }

  /* istanbul ignore next */
  return null;
}
