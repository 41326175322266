import { BusinessHoursSection } from './BusinessHoursSection/BusinessHoursSection';
import { NumberDetailBusinessHoursProps } from './NumberDetailBusinessHours.decl';

import { Spacer } from '@aircall/tractor-v2';

export function NumberDetailBusinessHours({ numberDetail }: NumberDetailBusinessHoursProps) {
  return (
    <Spacer fluid direction='vertical' space='m' data-test='number-detail-settings-page'>
      <BusinessHoursSection numberId={numberDetail.id} />
    </Spacer>
  );
}
