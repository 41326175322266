import { ListCallTagItemProps } from './ListCallTagItem.decl';

import { Tag } from '@aircall/tractor-v2';
import { ListItem } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function ListCallTagItem({ tag, onDelete, onEdit }: ListCallTagItemProps) {
  const { t } = useTranslation();

  return (
    <ListItem
      key={tag?.id}
      data-test={`call-tag-list-item-${tag.id}`}
      dropdownOptions={
        !tag.description
          ? [
              {
                title: t('calls.pages.tags.list.menu.edit_tag'),
                callback: () => onEdit(tag),
              },
              {
                title: t('calls.pages.tags.list.menu.delete_tag'),
                callback: () => onDelete(tag),
              },
            ]
          : undefined
      }
      px='xxs'
    >
      <Tag size='small' bg={tag.color}>
        {tag.name}
      </Tag>
    </ListItem>
  );
}
