import { useMemo } from 'react';

import { ONBOARDING_STATUS } from '../components/Onboarding.decl';

import { UseOnboardingReturnType } from './useOnboarding.decl';

import { PERMISSIONS_ACTIONS, RESOURCE } from '@constants/permissions.constants';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useHasAccess } from '@hooks/useHasAccess';

export function useOnboarding(): UseOnboardingReturnType {
  const {
    isTrial,
    currentCompany,
    entitiesCount: { totalNumbers, totalTeams, totalUsers },
  } = useGlobalData();

  const [hasNumbersPermissions] = useHasAccess([PERMISSIONS_ACTIONS.READ], RESOURCE.NUMBERS);
  const [hasUsersPermissions] = useHasAccess([PERMISSIONS_ACTIONS.READ], RESOURCE.USERS);
  const [hasTeamsPermissions] = useHasAccess([PERMISSIONS_ACTIONS.READ], RESOURCE.TEAMS);
  const hasOnboardingPermissions =
    hasNumbersPermissions && hasUsersPermissions && hasTeamsPermissions;

  const numbersComplete = totalNumbers !== 0;
  const usersComplete = totalUsers > 1;
  const teamsComplete = totalTeams !== 0;

  const numberStatus = numbersComplete ? ONBOARDING_STATUS.COMPLETE : ONBOARDING_STATUS.ACTIVE;
  let userStatus = usersComplete ? ONBOARDING_STATUS.COMPLETE : ONBOARDING_STATUS.DEFAULT;
  let teamStatus = teamsComplete ? ONBOARDING_STATUS.COMPLETE : ONBOARDING_STATUS.DEFAULT;

  if (numbersComplete && !usersComplete) {
    userStatus = ONBOARDING_STATUS.ACTIVE;
  }
  if (numbersComplete && usersComplete && !teamsComplete) {
    teamStatus = ONBOARDING_STATUS.ACTIVE;
  }

  const stepsComplete = useMemo(
    () =>
      [userStatus, numberStatus, teamStatus].reduce(
        (acc: number, item) => acc + (item === ONBOARDING_STATUS.COMPLETE ? 1 : 0),
        0
      ),
    [numberStatus, teamStatus, userStatus]
  );

  const isOnboardingComplete = stepsComplete === 3;

  const isOnboardingPeriod = useMemo(() => {
    const startDate = currentCompany.createdAt;
    const endDate = new Date();
    const diffInMs = endDate.getTime() - new Date(startDate).getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    const daysActive = isTrial ? 30 : 60;

    return diffInDays < daysActive;
  }, [currentCompany.createdAt, isTrial]);

  const isOnboardingRedirectActive = useMemo(() => {
    const numberStatusComplete = numberStatus === ONBOARDING_STATUS.COMPLETE;
    return hasOnboardingPermissions && !numberStatusComplete && isOnboardingPeriod;
  }, [numberStatus, hasOnboardingPermissions, isOnboardingPeriod]);

  return {
    numberStatus,
    userStatus,
    teamStatus,
    isOnboardingEnabled: hasOnboardingPermissions,
    stepsComplete,
    isOnboardingComplete,
    isOnboardingRedirectActive,
    isOnboardingPeriod,
  };
}
