import { FileAudioSetting } from '../AudioSetting.decl';

type Value = Partial<Omit<FileAudioSetting, 'type'>>;

export interface AudioSettingFileProps extends Value {
  onChange: (value: Required<Value>) => void;
}

export interface AudioSettingFileFieldProps {
  name: string;
}

export interface AudioSettingFilePlayerProps {
  file: File;
}

export enum CUSTOM_FILE_FLOW {
  UPLOAD = 'UPLOAD_START',
  RECORD_START = 'RECORD_START',
  RECORD_DONE = 'RECORD_DONE',
  DONE = 'DONE',
}

export interface AudioSettingFileRecordProps {
  onRecordChange: (status: CUSTOM_FILE_FLOW) => void;
  recordStatus: CUSTOM_FILE_FLOW;
}

export interface UseAudioFileStatusReturn {
  isUploading: boolean;
  isRecording: boolean;
  isDone: boolean;
  shouldDisplaySwitch: boolean;
  audioStatus: CUSTOM_FILE_FLOW;
  setAudioStatus: (event: CUSTOM_FILE_FLOW) => void;
}
