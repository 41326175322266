import { useEffect, useMemo } from 'react';

import { AudioPlayerProvider } from './components/AudioPlayer';
import { createClient } from './config/graphql/apolloClient.config';
import { Head } from './Head';
import { useAuthenticationState } from './hooks/useAuthenticationState';
import { AppRoutes } from './routes/App.routes';
import { AuthenticationProvider } from './state/app/authentication/AuthenticationProvider';
import { GlobalStyle } from './styles/global.styles';

import { Tractor } from '@aircall/tractor-v2';
import { ApolloProvider } from '@apollo/client';
import { BrowserBlocker } from '@components/BrowserBlocker';
import { GOOGLE_CLIENT_ID, GTM_APP_ID, INTERCOM_APP_ID } from '@constants/environment.constants';
import { NavigationBlockerDialog, NavigationBlockerProvider } from '@dashboard/library';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { removeOldCookie } from '@helpers/authentication.helpers';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AUTHENTICATION_STATUS } from '@state/app/authentication/authentication.decl';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

export function Main() {
  const {
    authState: { status: authStatus },
    actions: { onSessionExpired, refreshToken },
  } = useAuthenticationState();
  const { t } = useTranslation();

  // Memoize apollo client so that ApolloProvider does not re-init when <Main /> is re-rendered (ex: when token is refreshed)
  const apolloClient = useMemo(
    () => createClient(onSessionExpired, refreshToken),
    [refreshToken, onSessionExpired]
  );

  useEffect(() => {
    if (authStatus !== AUTHENTICATION_STATUS.AUTHENTICATED) {
      apolloClient.clearStore();
    }
  }, [apolloClient, authStatus]);

  return (
    <AudioPlayerProvider>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <GTMProvider state={{ id: GTM_APP_ID }}>
          <Router>
            <ApolloProvider client={apolloClient}>
              <BrowserBlocker>
                <NavigationBlockerProvider>
                  <AppRoutes />
                  <NavigationBlockerDialog
                    descriptionText={t('form_flow.navigation_blocker.description')}
                    cancelText={t('form_flow.navigation_blocker.cancel')}
                    discardText={t('form_flow.navigation_blocker.discard')}
                  />
                </NavigationBlockerProvider>
              </BrowserBlocker>
            </ApolloProvider>
          </Router>
        </GTMProvider>
      </IntercomProvider>
    </AudioPlayerProvider>
  );
}

export default function App() {
  useEffect(() => {
    removeOldCookie();
  }, []);
  return (
    <Tractor>
      <Head />
      <GlobalStyle />
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <AuthenticationProvider>
          <Main />
        </AuthenticationProvider>
      </GoogleOAuthProvider>
    </Tractor>
  );
}
