import { PhoneInput } from '../PhoneInput';

import { PhoneInputFieldProps } from './PhoneInputField.decl';

import { FormItem } from '@aircall/tractor-v2';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export function PhoneInputField({
  validate,
  name,
  defaultValue,
  label,
  defaultFlag,
  autoFocus,
  size,
  parse,
}: PhoneInputFieldProps) {
  const { t } = useTranslation();
  const {
    input,
    meta: { error, submitError, touched, dirty },
  } = useField(name, { validate, defaultValue, parse });
  const validationErrors = error || submitError;
  const shouldShowError = touched && dirty && validationErrors;
  const validationStatus = shouldShowError && 'error';

  return (
    <FormItem
      name={name}
      label={label}
      helpText={shouldShowError && t(validationErrors)}
      validationStatus={validationStatus} // will decide the color of helpText
    >
      <PhoneInput
        name={name}
        onBlur={input.onBlur}
        onChange={input.onChange}
        status={validationStatus}
        defaultValue={input.value}
        defaultFlag={defaultFlag}
        autoFocus={autoFocus}
        size={size}
      />
    </FormItem>
  );
}
