import { createContext } from 'react';

import noop from 'lodash-es/noop';

export interface AppLayoutStateContext {
  /** Display the UI without the sidebar and header */
  fullScreen: boolean;

  /** Enter/exit the full-screen mode */
  toggleFullScreen: (fullScreen: boolean) => void;
}

export const initialFullScreen = false;

export const AppLayoutContext = createContext<AppLayoutStateContext>({
  fullScreen: initialFullScreen,
  toggleFullScreen: noop,
});
