import { gql } from '@apollo/client';

export const UPDATE_TAG_MUTATION = gql`
  mutation UpdateTagMutation($input: UpdateTagInput!) {
    updateTag(input: $input) {
      id
      name
      color
      description
    }
  }
`;
