import { useState } from 'react';

import { TrialChecklistPopup } from '@components/TrialChecklistPopup';
import { TrialWidget } from '@components/TrialWidget';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useOnboardingData } from '@hooks/useOnboardingData';

export function TrialWidgetSection() {
  const { showOnboardingTrial } = useFeatures();
  const { currentUser } = useGlobalData();
  const { completedSteps, loading, phoneUrl, totalSteps, onboardingData } = useOnboardingData();

  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  if (!showOnboardingTrial || loading || !onboardingData) {
    return null;
  }

  return (
    <>
      <TrialWidget
        completedTask={completedSteps}
        totalTask={totalSteps}
        isWidgetOpen={isWidgetOpen}
        phoneAppUrl={phoneUrl}
        userName={currentUser.firstName}
        openWidget={setIsWidgetOpen}
        ctaButtonUrl={onboardingData.ctaButtonUrl}
        ctaButtonTitle={onboardingData.ctaButtonTitle}
      />
      <TrialChecklistPopup
        isPopupOpen={isWidgetOpen}
        completedTask={completedSteps}
        totalTask={totalSteps}
        onClose={() => {
          setIsWidgetOpen(false);
        }}
        onboardingData={onboardingData}
      />
    </>
  );
}
