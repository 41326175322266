import { gql } from '@apollo/client';

export const DELETE_DISPATCH_GROUPE_MUTATION = gql`
  mutation DeleteDispatchGroupMutation($numberId: String!, $dispatchGroupId: String!) {
    deleteDispatchGroupREST(numberId: $numberId, dispatchGroupId: $dispatchGroupId)
      @rest(
        endpoint: "v4"
        path: "numbers/{args.numberId}/dispatch_groups/{args.dispatchGroupId}"
        type: "Void"
        method: "DELETE"
      ) {
      _
    }
  }
`;
