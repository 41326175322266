import { useCallback } from 'react';

import { UseIntegrationsParams, UseIntegrationsReturn } from './useIntegrations.decl';

import { APPLICATIONS_NAMES } from '@constants/integrations.constants';
import { ApplicationsListQuery } from '@generated/ApplicationsListQuery';
import { IntegrationsListQuery } from '@generated/IntegrationsListQuery';
import { APPLICATIONS_LIST_QUERY } from '@graphql/queries/ApplicationsListQuery';
import { INTEGRATIONS_LIST_QUERY } from '@graphql/queries/IntegrationsListQuery';
import { formatApplicationName } from '@helpers/integrations.helpers';
import { useGraphQuery } from '@hooks/useQuery/useGraphQuery';

/**
 * Hook to fetch integrations
 * @param queryOptions - Options to execute the query
 * @param dataToFetch - Type of data to fetch. It'll fetch 2 queries to all kinds of integrations by default.
 * @returns Integrations list, loading state and error state
 */
export function useIntegrations({
  queryOptions,
  dataToFetch = ['integrations', 'applications'],
}: UseIntegrationsParams = {}): UseIntegrationsReturn {
  const loadsIntegrations = !dataToFetch.includes('integrations');
  const loadsApplications = !dataToFetch.includes('applications');
  const { data: integrations, loading: integrationsLoading } = useGraphQuery<IntegrationsListQuery>(
    INTEGRATIONS_LIST_QUERY,
    {
      ...queryOptions,
      skip: loadsIntegrations,
      throwError: true,
    }
  );

  const { data, loading: applicationsLoading } = useGraphQuery<ApplicationsListQuery>(
    APPLICATIONS_LIST_QUERY,
    {
      skip: loadsApplications,
      throwError: true,
    }
  );

  const applications = data?.getApplications;

  const getApplicationByName = useCallback(
    (applicationName: APPLICATIONS_NAMES) =>
      [...(applications || [])].find((application) => {
        const { name, shortName } = application;
        return [formatApplicationName(name), shortName].some((appName) =>
          [formatApplicationName(applicationName), applicationName].includes(appName as string)
        );
      }),
    [applications]
  );

  return {
    isLoading: integrationsLoading || applicationsLoading,
    hasData: (!loadsIntegrations || !!integrations) && (!loadsApplications || !!applications),
    integrations,
    applications: applications ? { getApplications: applications } : undefined,
    getApplicationByName,
  };
}
