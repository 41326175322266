/* eslint-disable no-await-in-loop */
import { useCallback, useState } from 'react';

import { IS_TEST } from '@constants/environment.constants';
import { useGraphLazyQuery } from '@dashboard/library';
import { GetLineByUUID } from '@generated/GetLineByUUID';
import { GET_LINE_BY_UUID } from '@graphql/queries/GetLineByUuid';

type UseFetchLineIdAndRetryReturn = {
  fetchLineIdAndRetry: (numberId: string) => Promise<string | undefined>;
  loading: boolean;
};

const waitTime = (t: number) =>
  new Promise((r) => {
    setTimeout(r, t);
  });

/**
 * Hook to fetch a number new ID based on the old ID
 * @returns \{fetchLineIdAndRetry, loading\} - method to get the mew ID and its loading state
 */
export function useFetchLineIdAndRetry(): UseFetchLineIdAndRetryReturn {
  const maxRetry = 5;

  const waitingTime = IS_TEST ? 1 : /* istanbul ignore next */ 1000;

  const [loading, setLoading] = useState(false);
  const [getLineByUUId] = useGraphLazyQuery<GetLineByUUID>(GET_LINE_BY_UUID);
  // promise to execute the query
  const getLineIdfromUUID = useCallback(
    async (numberId: string) => {
      let lineId;
      try {
        const { data } = await getLineByUUId({ variables: { numberUUID: numberId } });
        lineId = data?.getLineByUUID?.ID;
      } catch (e) {
        // it should throw 404 error when the webId is not there yet
        // do nothing
      }
      return lineId;
    },
    [getLineByUUId]
  );

  /**
   * promise to execute query for maximum maxRetry times
   * wait waitingTime (1ms in test, 1000ms otherwise) before each call
   */
  const fetchLineIdAndRetry = useCallback(
    async (numberId: string) => {
      setLoading(true);
      let lineWebId;
      for (let index = 0; index < maxRetry; index += 1) {
        await waitTime(waitingTime);
        lineWebId = await getLineIdfromUUID(numberId);
        if (lineWebId !== undefined) {
          break;
        }
      }
      setLoading(false);
      return lineWebId;
    },
    [getLineIdfromUUID, waitingTime]
  );

  return { fetchLineIdAndRetry, loading };
}
