import { AvatarVariants } from '@aircall/tractor-v2';

export const TAGS_DARK_COLORS = ['#000000', '#003D4C', '#BB2100', '#F92C00', '#008f6c', '#0662B5'];

export const NEW_TAGS_COLORS = [
  '#003D4C',
  '#BB2100',
  '#F92C00',
  '#0662B5',
  '#008f6c',
  '#29C8FF',
  '#B285D1',
  '#FCBB26',
  '#FF9D88',
  '#FF854C',
  '#ACCCF3',
  '#48A0FF',
  '#65D289',
];

export const BRAND_COLORS = {
  BLUE: {
    LIGHT: '#EDF3FA',
    DARK: '#005ABB',
  },
};

export const FONT_SIZE_BASE_VALUE = 14;

// V2
export const AVATAR_VARIANTS: AvatarVariants[] = [
  'default',
  'blue',
  'green',
  'yellow',
  'purple',
  'peach',
];

export const ILLUSTRATION_BACKGROUND_COLORS = {
  LIGHT_BLUE: '#E1F4FF',
  LIGHT_YELLOW: '#FFFBF0',
  LIGHT_RED: '#FFF5F3',
  LIGHT_GREEN: '#F1FFFC',
  GREEN: '#D0ECE4',
};
