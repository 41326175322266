import { AnalyticsPlusAddonPageProps } from './AnalyticsPlusAddon.decl';

import { ReactComponent as AnalyticsPlusIcon } from '@assets/icons/analytics_plus_icon.svg';
import { AnalyticsDashboard } from '@components/AnalyticsDashboard/AnalyticsDashboard';
import { TrialAddonButton } from '@components/TrialAddonButton';
import { ADDONS } from '@constants/addons.constants';
import { LookerPageHeaderProps } from '@pages/looker-v2';

export function AnalyticsPlusAddonPage({
  ...analyticsDashboardProps
}: Readonly<AnalyticsPlusAddonPageProps>) {
  const pageHeaderProps: LookerPageHeaderProps = {
    titleProps: { justifyContent: 'start', gap: 's' },
    renderTitleRight: () => (
      <TrialAddonButton
        buttonName='button-trial-header'
        color='#005abb'
        backgroundColor='#d6e7fc'
        addonName={ADDONS.ANALYTICS_PLUS}
        icon={AnalyticsPlusIcon}
      />
    ),
  };
  const props = {
    ...analyticsDashboardProps,
    pageHeaderProps,
  };

  return <AnalyticsDashboard {...props} />;
}
