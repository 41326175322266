import React, { MouseEvent, MouseEventHandler, ReactNode, useState } from 'react';

import styled, { css } from 'styled-components';

import { getMouseScale } from './utils';

import { Box, BoxProps, Divider, Grid, getColor, getRadii, getShadow } from '@aircall/tractor-v2';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import noop from 'lodash-es/noop';
import { Link } from 'react-router-dom';

interface CardProps extends BoxProps {
  /** The main content of the card */
  children: React.ReactNode;
  /** The avatar of the card */
  avatar: React.ReactNode;
  /** The footer of the card  */
  footer?: React.ReactNode;
  /** Display shadow behind card  */
  hasShadow?: boolean;
  /** The mouse event handler */
  onClick?: MouseEventHandler;
  /** Enable animation onClick */
  disableOnClickAnimation?: boolean;
  /** Enable hover effect */
  interactive?: boolean;
  /** The link to go to */
  to?: string;
}

interface LinkWrapperProps {
  to: CardProps['to'];
  children: ReactNode;
}

interface CardContainerProps extends BoxProps {
  hasShadow: boolean;
  interactive?: boolean;
}

const CardContainer = styled(Box)<CardContainerProps>`
  position: relative;
  min-width: 100px;
  border: 1px solid ${getColor('neutral-200')};
  border-radius: ${getRadii(8)};
  background-color: ${getColor('neutral-0')};
  ${({ hasShadow }) => hasShadow && `box-shadow: ${getShadow(4)};`}
  ${({ interactive }) =>
    interactive &&
    css`
      cursor: pointer;

      &:hover {
        border-color: ${getColor('neutral-300')};
        box-shadow: ${getShadow(4)};
      }

      &:focus,
      &:active,
      &.card-is-active {
        border-color: ${getColor('black')};
        box-shadow: ${getShadow(4)};
      }
    `};
`;

const CircleAnimation = styled(motion.div)`
  z-index: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${getColor('neutral-300')};
  transform-origin: center;
`;

const animationVariants: Variants = {
  initial: ({ x, y }) => ({
    x,
    y,
    scale: 0,
    opacity: 0.25,
  }),
  animate: ({ x, y, scale }) => ({
    x,
    y,
    scale,
    opacity: 1,
    transition: {
      duration: 0.25,
      ease: 'easeInOut',
    },
  }),
  exit: {
    opacity: 0,
  },
};

function LinkWrapper({ to, children }: LinkWrapperProps) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return to ? <Link to={to}>{children}</Link> : <>{children}</>;
}

export default function Card({
  children,
  avatar,
  footer,
  onClick,
  interactive = onClick !== undefined,
  hasShadow = false,
  to,
  disableOnClickAnimation,
  ...boxProps
}: CardProps) {
  const [[x, y, scale, isClicked], setMouse] = useState([0, 0, 1, false]);

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    /** Subtract half of circle animation width to get centered cursor position */
    const mouseX = e.clientX - rect.left - 10;
    const mouseY = e.clientY - rect.top - 10;
    const mouseScale = getMouseScale(rect.width, rect.height);

    setMouse([mouseX, mouseY, mouseScale, true]);
  };

  return (
    <CardContainer
      onClick={onClick}
      onMouseDown={interactive ? handleMouseDown : noop}
      className={isClicked ? 'card-is-active' : undefined}
      data-test='card-container'
      hasShadow={hasShadow}
      interactive
      {...boxProps}
    >
      <Box
        position='absolute'
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflow='hidden'
        borderRadius='sm'
      >
        <AnimatePresence>
          {isClicked && !disableOnClickAnimation && (
            <CircleAnimation
              custom={{ x, y, scale }}
              variants={animationVariants}
              initial='initial'
              animate='animate'
              exit='exit'
              data-test='circle-animation'
              onAnimationComplete={() => setMouse([0, 0, 1, false])}
            />
          )}
        </AnimatePresence>
      </Box>
      <Box position='relative'>
        <LinkWrapper to={to}>
          <Grid
            alignItems='center'
            pr={footer ? 16 : 20}
            gridTemplateColumns='auto 1fr'
            gridAutoRows='auto'
          >
            <Box padding={footer ? 16 : 20}>{avatar}</Box>
            {children}
          </Grid>
        </LinkWrapper>
        {!!footer && (
          <>
            <Divider orientation='horizontal' size='xSmall' backgroundColor='neutral-200' />
            {footer}
          </>
        )}
      </Box>
    </CardContainer>
  );
}
