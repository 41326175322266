import { gql } from '@apollo/client';

export const COMPANY_FRAGMENT = gql`
  fragment CompanyFragment on RestCompany {
    id
    plan
    featuresAllowed {
      isTeamsAdvancedAllowed
      allow10NumbersInTrial
      isSipSettingsAllowed
      isSupportLinksAllowed
      isCompanyAccountAllowed
      isMandatoryTaggingAllowed
      isQueueCallbackAllowed
      isAdvancedAnalyticsAllowed
      isLiveFeedAllowed
    }
    logoUrl
    tierLevel
    provider
    invoiceEmails
    name
    isDelinquent
    website
    vatNumber
    address {
      line
      zip
      city
      state
      country
    }
    billingPeriod
    trial {
      callTotalSeconds
      hasTrialCallLimits
      trialEnd
    }
    createdAt
    lastTimePlanChanged
    currency
  }
`;
