export const IVR_SETTING_KEYS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

/**
 * Build available keys options and return them
 * @param existingKeys - an array of available keys
 * @returns An array of keys options
 */
export function getAvailableKeysOptions(
  existingKeys: string[]
): Array<{ value: string; label: string }> {
  return IVR_SETTING_KEYS.reduce<Array<{ value: string; label: string }>>((acc, key) => {
    if (!existingKeys.includes(key)) {
      return [...acc, { value: key, label: key }];
    }
    return acc;
  }, []);
}
