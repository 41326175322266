import styled from 'styled-components';

import { FormFlowPageHeaderProps } from '../FormFlow.decl';

import { Box, Flex, Divider, Typography } from '@aircall/tractor-v2';
import { CompanyLogo } from '@components/CompanyLogo/CompanyLogo';

const Container = styled(Box)`
  grid-area: header;
`;

export function FormFlowPageHeader({
  headerTitle,
  headerIcon,
  'data-test': dataTest,
}: FormFlowPageHeaderProps) {
  return (
    <Container>
      <Flex data-test={dataTest} backgroundColor='neutral-0' justifyContent='space-between'>
        <Flex alignItems='center'>
          <CompanyLogo />
          <Divider size='xSmall' />
          {headerTitle && (
            <Typography ml='21px' variant='headingBoldS'>
              {headerTitle}
            </Typography>
          )}
        </Flex>
        <Flex mr='l' alignItems='center'>
          {headerIcon}
        </Flex>
      </Flex>
      <Divider orientation='horizontal' size='xSmall' />
    </Container>
  );
}
