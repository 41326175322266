import { useCallback } from 'react';

import { AudioPlayerContainer } from './AudioSettingFile.constants';
import { AudioSettingFilePlayerProps } from './AudioSettingFile.decl';

import { TrashOutlined } from '@aircall/icons';
import { Box, Flex, IconButton, Typography } from '@aircall/tractor-v2';
import { AudioPlayer } from '@components/AudioPlayer';
import { useForm } from 'react-final-form';

export function AudioSettingFilePlayer({ file }: AudioSettingFilePlayerProps) {
  const form = useForm();

  const handleFileReset = useCallback(() => {
    form.change('audioSetting.file', undefined);
  }, [form]);

  return (
    <AudioPlayerContainer>
      <Flex alignItems='center' justifyContent='space-between' pl='s' pr='xxs' py='xxs'>
        <Typography variant='bodyRegularS'>{file.name}</Typography>
        <IconButton
          component={TrashOutlined}
          color='icon-default'
          discColor='transparent'
          type='button'
          onClick={handleFileReset}
          data-test='audio-file-clear-button'
        />
      </Flex>
      <Box
        pl={10}
        pr='s'
        py='xs'
        borderTopWidth='1px'
        borderTopStyle='solid'
        borderTopColor='neutral-200'
      >
        <AudioPlayer url={URL.createObjectURL(file)} download={file.name} />
      </Box>
    </AudioPlayerContainer>
  );
}
