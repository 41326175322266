import { AudioSettingLibraryProps } from './AudioSettingLibrary.decl';

import { Radio, RadioGroup, List, ListView, ListItem } from '@aircall/tractor-v2';
import { AudioPlayer } from '@components/AudioPlayer';
import { GridItem, GridLayout, Loading } from '@dashboard/library';
import { useAudioLibrary } from '@hooks/useAudioLibrary/useAudioLibrary';

export function AudioSettingLibrary({ onChange, url }: AudioSettingLibraryProps) {
  const { tracks, selectedTrackURL, loading } = useAudioLibrary(url);

  if (loading) {
    return <Loading data-test='audio-setting-library-loading' />;
  }

  return (
    <RadioGroup defaultValue={selectedTrackURL} onChange={onChange} direction='vertical' w='100%'>
      <List fluid>
        <ListView>
          {tracks?.map((track) => (
            <ListItem key={track.name} backgroundColor='surface-default'>
              <GridLayout px='s' py='xxxs' alignItems='center'>
                <GridItem xs={6}>
                  <Radio
                    size='small'
                    value={track.url}
                    data-test={`audio-setting-library-radio-button-${track.name}`}
                  >
                    {track.label}
                  </Radio>
                </GridItem>
                <GridItem xs={6}>
                  <AudioPlayer url={track.url} displayTimer displayTimeline />
                </GridItem>
              </GridLayout>
            </ListItem>
          ))}
        </ListView>
      </List>
    </RadioGroup>
  );
}
