import { clamp, distance } from '@popmotion/popcorn';

export interface Position {
  top: number;
  height: number;
}

/**
 * Finds the new index of an element given positions of all elements
 * and the number of traveled pixels on the "y offset".
 * @param i - index of the given element
 * @param yOffset - number of travaled pixels on the "y offset" of the element
 * @param positions - positions of all elements
 * @returns new index of the element
 */
export const findIndex = (i: number, yOffset: number, positions: Position[]): number => {
  // Prevent rapid reverse swapping
  const buffer = 5;

  let target = i;

  // Retrieve top position and height of the current element
  const { top, height } = positions[i];
  const bottom = top + height;

  /* istanbul ignore else */
  if (yOffset > 0) {
    // If moving down
    const nextItem = positions[i + 1];
    if (nextItem === undefined) {
      return i;
    }

    const swapOffset = distance(bottom, nextItem.top + nextItem.height / 2) + buffer;
    if (yOffset > swapOffset) {
      target = i + 1;
    }
  }
  // yOffset is either bigger than 0 or smaller than 0
  else if (yOffset < 0) {
    // If moving up
    const prevItem = positions[i - 1];
    if (prevItem === undefined) {
      return i;
    }

    const prevBottom = prevItem.top + prevItem.height;
    const swapOffset = distance(top, prevBottom - prevItem.height / 2) + buffer;
    if (yOffset < -swapOffset) {
      target = i - 1;
    }
  }

  return clamp(0, positions.length, target);
};

/**
 * Moves an element to a desired position in an array.
 * @param arr - arr in which we move elements
 * @param fromIndex - index of element to be moved
 * @param toIndex - desired end position of element
 * @returns the reordered array
 *
 * ## Example
 * ```typescript
 * arraymove([1, 2, 3, 4], 1, 2) // => [1, 3, 2, 4]
 * ```
 */
export const arraymove = <T>(arr: T[], fromIndex: number, toIndex: number): T[] => {
  const clone = [...arr];
  const element = clone[fromIndex];
  clone.splice(fromIndex, 1);
  clone.splice(toIndex, 0, element);
  return clone;
};
