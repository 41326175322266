import { useUpdateNumberToggles } from '../hooks/useUpdateNumberToggles';

import { CallbackRequestProps } from './CallbackRequest.decl';

import { Button, Spacer, Banner, BannerHeading } from '@aircall/tractor-v2';
import { ToggleRow } from '@components/ToggleRow';
import { UpgradePlanButton } from '@components/UpgradePlanButton/UpgradePlanButton';
import { NUMBER_DETAIL_ROUTE_TAB } from '@constants/routes.constants';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { List, ListItem, Paper, routeReplacer } from '@dashboard/library';
import { NUMBER_DETAIL_TABS } from '@pages/number-detail/NumberDetail.decl';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export function CallbackRequest({ disabled }: CallbackRequestProps) {
  const { t } = useTranslation();
  const { updateNumberToggles, number } = useUpdateNumberToggles({});

  return (
    <Paper
      title={t('number_details.settings.callback_request.title')}
      subtitle={t('number_details.settings.callback_request.description')}
      disabled={disabled}
    >
      <Spacer direction='vertical' space='m' fluid>
        <List>
          <ListItem h='auto'>
            <ToggleRow
              size='large'
              data-test='callback-request-activated-toggle'
              value={!!number?.queueCallbackActivated}
              onChange={(queueCallbackActivated) =>
                updateNumberToggles(
                  { queueCallbackActivated },
                  {
                    successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
                      name: t(`number_details.settings.callback_request.callback_request`),
                    }),
                    trackingOptions: { section: 'Settings', setting: 'Callback request' },
                  }
                )
              }
              title={t('number_details.settings.callback_request.callback_request')}
              disabled={disabled}
              description={
                !!number?.queueCallbackActivated && (
                  <>
                    <Banner variant='warning' inline>
                      <BannerHeading>
                        {t('number_details.settings.callback_request.warning_banner')}
                      </BannerHeading>
                    </Banner>
                    <Link
                      to={routeReplacer(`${NUMBER_DETAIL_ROUTE_TAB}`, {
                        numberId: number.id,
                        tab: NUMBER_DETAIL_TABS.CALL_DISTRIBUTION,
                      })}
                      state={{ showWelcomeMessageModal: true }}
                    >
                      <Button mode='link' size='small' data-test='edit-welcome-message-button'>
                        {t('number_details.settings.callback_request.edit_welcome_message_button')}
                      </Button>
                    </Link>
                  </>
                )
              }
            />
          </ListItem>
        </List>
        {disabled && <UpgradePlanButton mode='outline' variant='primary' />}
      </Spacer>
    </Paper>
  );
}
