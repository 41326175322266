import { getTimezoneOffset } from 'date-fns-tz';

const TIMEZONES = [
  {
    value: 'Etc/GMT+12',
    name: '(UTC%offset%) International Date Line West',
  },
  {
    value: 'Pacific/Pago_Pago',
    name: '(UTC%offset%) American Samoa',
  },
  {
    value: 'Pacific/Midway',
    name: '(UTC%offset%) Midway Island',
  },
  {
    value: 'Pacific/Honolulu',
    name: '(UTC%offset%) Hawaii',
  },
  {
    value: 'America/Juneau',
    name: '(UTC%offset%) Alaska',
  },
  {
    value: 'America/Los_Angeles',
    name: '(UTC%offset%) Pacific Time (US & Canada)',
  },
  {
    value: 'America/Tijuana',
    name: '(UTC%offset%) Tijuana',
  },
  {
    value: 'America/Phoenix',
    name: '(UTC%offset%) Arizona',
  },
  {
    value: 'America/Chihuahua',
    name: '(UTC%offset%) Chihuahua',
  },
  {
    value: 'America/Mazatlan',
    name: '(UTC%offset%) Mazatlan',
  },
  {
    value: 'America/Denver',
    name: '(UTC%offset%) Mountain Time (US & Canada)',
  },
  {
    value: 'America/Guatemala',
    name: '(UTC%offset%) Central America',
  },
  {
    value: 'America/Chicago',
    name: '(UTC%offset%) Central Time (US & Canada)',
  },
  {
    value: 'America/Mexico_City',
    name: '(UTC%offset%) Mexico City',
  },
  {
    value: 'America/Monterrey',
    name: '(UTC%offset%) Monterrey',
  },
  {
    value: 'America/Regina',
    name: '(UTC%offset%) Saskatchewan',
  },
  {
    value: 'America/Bogota',
    name: '(UTC%offset%) Bogota',
  },
  {
    value: 'America/New_York',
    name: '(UTC%offset%) Eastern Time (US & Canada)',
  },
  {
    value: 'America/Indiana/Indianapolis',
    name: '(UTC%offset%) Indiana (East)',
  },
  {
    value: 'America/Lima',
    name: '(UTC%offset%) Lima',
  },
  {
    value: 'America/Halifax',
    name: '(UTC%offset%) Atlantic Time (Canada)',
  },
  {
    value: 'America/Caracas',
    name: '(UTC%offset%) Caracas',
  },
  {
    value: 'America/Guyana',
    name: '(UTC%offset%) Georgetown',
  },
  {
    value: 'America/La_Paz',
    name: '(UTC%offset%) La Paz',
  },
  {
    value: 'America/Puerto_Rico',
    name: '(UTC%offset%) Puerto Rico',
  },
  {
    value: 'America/Santiago',
    name: '(UTC%offset%) Santiago',
  },
  {
    value: 'America/St_Johns',
    name: '(UTC%offset%) Newfoundland',
  },
  {
    value: 'America/Sao_Paulo',
    name: '(UTC%offset%) Brasilia',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    name: '(UTC%offset%) Buenos Aires',
  },
  {
    value: 'America/Godthab',
    name: '(UTC%offset%) Greenland',
  },
  {
    value: 'America/Montevideo',
    name: '(UTC%offset%) Montevideo',
  },
  {
    value: 'Atlantic/South_Georgia',
    name: '(UTC%offset%) Mid-Atlantic',
  },
  {
    value: 'Atlantic/Azores',
    name: '(UTC%offset%) Azores',
  },
  {
    value: 'Atlantic/Cape_Verde',
    name: '(UTC%offset%) Cape Verde Is.',
  },
  {
    value: 'Africa/Casablanca',
    name: '(UTC%offset%) Casablanca',
  },
  {
    value: 'Europe/Dublin',
    name: '(UTC%offset%) Dublin',
  },
  {
    value: 'Europe/Lisbon',
    name: '(UTC%offset%) Lisbon',
  },
  {
    value: 'Europe/London',
    name: '(UTC%offset%) London',
  },
  {
    value: 'Africa/Monrovia',
    name: '(UTC%offset%) Monrovia',
  },
  {
    value: 'Etc/UTC',
    name: '(UTC%offset%) UTC',
  },
  {
    value: 'Europe/Amsterdam',
    name: '(UTC%offset%) Amsterdam',
  },
  {
    value: 'Europe/Belgrade',
    name: '(UTC%offset%) Belgrade',
  },
  {
    value: 'Europe/Berlin',
    name: '(UTC%offset%) Berlin',
  },
  {
    value: 'Europe/Bratislava',
    name: '(UTC%offset%) Bratislava',
  },
  {
    value: 'Europe/Brussels',
    name: '(UTC%offset%) Brussels',
  },
  {
    value: 'Europe/Budapest',
    name: '(UTC%offset%) Budapest',
  },
  {
    value: 'Europe/Copenhagen',
    name: '(UTC%offset%) Copenhagen',
  },
  {
    value: 'Europe/Ljubljana',
    name: '(UTC%offset%) Ljubljana',
  },
  {
    value: 'Europe/Madrid',
    name: '(UTC%offset%) Madrid',
  },
  {
    value: 'Europe/Paris',
    name: '(UTC%offset%) Paris',
  },
  {
    value: 'Europe/Prague',
    name: '(UTC%offset%) Prague',
  },
  {
    value: 'Europe/Rome',
    name: '(UTC%offset%) Rome',
  },
  {
    value: 'Europe/Sarajevo',
    name: '(UTC%offset%) Sarajevo',
  },
  {
    value: 'Europe/Skopje',
    name: '(UTC%offset%) Skopje',
  },
  {
    value: 'Europe/Stockholm',
    name: '(UTC%offset%) Stockholm',
  },
  {
    value: 'Europe/Vienna',
    name: '(UTC%offset%) Vienna',
  },
  {
    value: 'Europe/Warsaw',
    name: '(UTC%offset%) Warsaw',
  },
  {
    value: 'Africa/Algiers',
    name: '(UTC%offset%) West Central Africa',
  },
  {
    value: 'Europe/Zagreb',
    name: '(UTC%offset%) Zagreb',
  },
  {
    value: 'Europe/Zurich',
    name: '(UTC%offset%) Zurich',
  },
  {
    value: 'Europe/Athens',
    name: '(UTC%offset%) Athens',
  },
  {
    value: 'Europe/Bucharest',
    name: '(UTC%offset%) Bucharest',
  },
  {
    value: 'Africa/Cairo',
    name: '(UTC%offset%) Cairo',
  },
  {
    value: 'Africa/Harare',
    name: '(UTC%offset%) Harare',
  },
  {
    value: 'Europe/Helsinki',
    name: '(UTC%offset%) Helsinki',
  },
  {
    value: 'Asia/Jerusalem',
    name: '(UTC%offset%) Jerusalem',
  },
  {
    value: 'Europe/Kaliningrad',
    name: '(UTC%offset%) Kaliningrad',
  },
  {
    value: 'Europe/Kiev',
    name: '(UTC%offset%) Kyiv',
  },
  {
    value: 'Africa/Johannesburg',
    name: '(UTC%offset%) Pretoria',
  },
  {
    value: 'Europe/Riga',
    name: '(UTC%offset%) Riga',
  },
  {
    value: 'Europe/Sofia',
    name: '(UTC%offset%) Sofia',
  },
  {
    value: 'Europe/Tallinn',
    name: '(UTC%offset%) Tallinn',
  },
  {
    value: 'Europe/Vilnius',
    name: '(UTC%offset%) Vilnius',
  },
  {
    value: 'Asia/Baghdad',
    name: '(UTC%offset%) Baghdad',
  },
  {
    value: 'Europe/Istanbul',
    name: '(UTC%offset%) Istanbul',
  },
  {
    value: 'Asia/Kuwait',
    name: '(UTC%offset%) Kuwait',
  },
  {
    value: 'Europe/Minsk',
    name: '(UTC%offset%) Minsk',
  },
  {
    value: 'Europe/Moscow',
    name: '(UTC%offset%) Moscow',
  },
  {
    value: 'Africa/Nairobi',
    name: '(UTC%offset%) Nairobi',
  },
  {
    value: 'Asia/Riyadh',
    name: '(UTC%offset%) Riyadh',
  },
  {
    value: 'Asia/Tehran',
    name: '(UTC%offset%) Tehran',
  },
  {
    value: 'Asia/Baku',
    name: '(UTC%offset%) Baku',
  },
  {
    value: 'Asia/Muscat',
    name: '(UTC%offset%) Muscat',
  },
  {
    value: 'Europe/Samara',
    name: '(UTC%offset%) Samara',
  },
  {
    value: 'Asia/Tbilisi',
    name: '(UTC%offset%) Tbilisi',
  },
  {
    value: 'Europe/Volgograd',
    name: '(UTC%offset%) Volgograd',
  },
  {
    value: 'Asia/Yerevan',
    name: '(UTC%offset%) Yerevan',
  },
  {
    value: 'Asia/Kabul',
    name: '(UTC%offset%) Kabul',
  },
  {
    value: 'Asia/Yekaterinburg',
    name: '(UTC%offset%) Ekaterinburg',
  },
  {
    value: 'Asia/Karachi',
    name: '(UTC%offset%) Karachi',
  },
  {
    value: 'Asia/Tashkent',
    name: '(UTC%offset%) Tashkent',
  },
  {
    value: 'Asia/Kolkata',
    name: '(UTC%offset%) Kolkata',
  },
  {
    value: 'Asia/Colombo',
    name: '(UTC%offset%) Sri Jayawardenepura',
  },
  {
    value: 'Asia/Kathmandu',
    name: '(UTC%offset%) Kathmandu',
  },
  {
    value: 'Asia/Almaty',
    name: '(UTC%offset%) Almaty',
  },
  {
    value: 'Asia/Dhaka',
    name: '(UTC%offset%) Dhaka',
  },
  {
    value: 'Asia/Urumqi',
    name: '(UTC%offset%) Urumqi',
  },
  {
    value: 'Asia/Rangoon',
    name: '(UTC%offset%) Rangoon',
  },
  {
    value: 'Asia/Bangkok',
    name: '(UTC%offset%) Bangkok',
  },
  {
    value: 'Asia/Jakarta',
    name: '(UTC%offset%) Jakarta',
  },
  {
    value: 'Asia/Krasnoyarsk',
    name: '(UTC%offset%) Krasnoyarsk',
  },
  {
    value: 'Asia/Novosibirsk',
    name: '(UTC%offset%) Novosibirsk',
  },
  {
    value: 'Asia/Shanghai',
    name: '(UTC%offset%) Beijing',
  },
  {
    value: 'Asia/Chongqing',
    name: '(UTC%offset%) Chongqing',
  },
  {
    value: 'Asia/Hong_Kong',
    name: '(UTC%offset%) Hong Kong',
  },
  {
    value: 'Asia/Irkutsk',
    name: '(UTC%offset%) Irkutsk',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    name: '(UTC%offset%) Kuala Lumpur',
  },
  {
    value: 'Australia/Perth',
    name: '(UTC%offset%) Perth',
  },
  {
    value: 'Asia/Singapore',
    name: '(UTC%offset%) Singapore',
  },
  {
    value: 'Asia/Taipei',
    name: '(UTC%offset%) Taipei',
  },
  {
    value: 'Asia/Ulaanbaatar',
    name: '(UTC%offset%) Ulaanbaatar',
  },
  {
    value: 'Asia/Seoul',
    name: '(UTC%offset%) Seoul',
  },
  {
    value: 'Asia/Tokyo',
    name: '(UTC%offset%) Tokyo',
  },
  {
    value: 'Asia/Yakutsk',
    name: '(UTC%offset%) Yakutsk',
  },
  {
    value: 'Australia/Adelaide',
    name: '(UTC%offset%) Adelaide',
  },
  {
    value: 'Australia/Darwin',
    name: '(UTC%offset%) Darwin',
  },
  {
    value: 'Australia/Brisbane',
    name: '(UTC%offset%) Brisbane',
  },
  {
    value: 'Pacific/Guam',
    name: '(UTC%offset%) Guam',
  },
  {
    value: 'Australia/Hobart',
    name: '(UTC%offset%) Hobart',
  },
  {
    value: 'Australia/Melbourne',
    name: '(UTC%offset%) Melbourne',
  },
  {
    value: 'Pacific/Port_Moresby',
    name: '(UTC%offset%) Port Moresby',
  },
  {
    value: 'Australia/Sydney',
    name: '(UTC%offset%) Sydney',
  },
  {
    value: 'Asia/Vladivostok',
    name: '(UTC%offset%) Vladivostok',
  },
  {
    value: 'Asia/Magadan',
    name: '(UTC%offset%) Magadan',
  },
  {
    value: 'Pacific/Noumea',
    name: '(UTC%offset%) New Caledonia',
  },
  {
    value: 'Pacific/Guadalcanal',
    name: '(UTC%offset%) Solomon Is.',
  },
  {
    value: 'Asia/Srednekolymsk',
    name: '(UTC%offset%) Srednekolymsk',
  },
  {
    value: 'Pacific/Auckland',
    name: '(UTC%offset%) Auckland',
  },
  {
    value: 'Pacific/Fiji',
    name: '(UTC%offset%) Fiji',
  },
  {
    value: 'Asia/Kamchatka',
    name: '(UTC%offset%) Kamchatka',
  },
  {
    value: 'Pacific/Majuro',
    name: '(UTC%offset%) Marshall Is.',
  },
  {
    value: 'Pacific/Chatham',
    name: '(UTC%offset%) Chatham Is.',
  },
  {
    value: 'Pacific/Tongatapu',
    name: "(UTC%offset%) Nuku'alofa",
  },
  {
    value: 'Pacific/Apia',
    name: '(UTC%offset%) Samoa',
  },
  {
    value: 'Pacific/Fakaofo',
    name: '(UTC%offset%) Tokelau Is.',
  },
];

export interface Timezone {
  value: string;
  name: string;
  sortKeyOffset?: number;
  sortKeyName?: string;
}

/**
 * Gets a formatted timezone offset.
 * @param timezoneValue - Timezone value
 * @returns formatted timezone offset
 *
 * ## Example
 * ```typescript
 * getFormattedTimezoneOffset('Europe/Paris') // => +02:00
 * ```
 */
export function getFormattedTimezoneOffset(timezoneValue: string): string {
  const offsetMs = getTimezoneOffset(timezoneValue);
  const sign = offsetMs >= 0 ? '+' : '-';
  let hours = Math.abs(Math.floor(offsetMs / 3600000));

  // Subtract one hour if time zone is "America/St_Johns" to work around a bug with the date-fns-tz library
  if (timezoneValue === 'America/St_Johns') {
    hours -= 1;
  }

  const formattedHours = sign + hours.toString().padStart(2, '0');
  const formattedMinutes = ((Math.abs(offsetMs) % 3600000) / 60000).toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

/**
 * Gets a list of timezones sorted by offset.
 * @returns a list of timezones
 */
export function getTimezones(): Timezone[] {
  function replaceUtcOffset(tz: Timezone): Timezone {
    const utcOffsetRegex = /^\(UTC([^\\)]+)\) /g;
    const offsetFormatted = getFormattedTimezoneOffset(tz.value);

    return {
      ...tz,
      sortKeyOffset: parseInt(offsetFormatted.replace(':', ''), 10),
      sortKeyName: tz.name.replace(utcOffsetRegex, ''),
      name: tz.name.replace(utcOffsetRegex, `(UTC${offsetFormatted}) `),
    };
  }

  function sortByUtcOffset(a: Timezone, b: Timezone): number {
    if (a.sortKeyOffset !== b.sortKeyOffset) {
      return a.sortKeyOffset! > b.sortKeyOffset! ? 1 : -1;
    }

    return a.sortKeyName! > b.sortKeyName! ? 1 : -1;
  }

  return TIMEZONES.map(replaceUtcOffset).sort(sortByUtcOffset);
}
