/* eslint-disable @typescript-eslint/naming-convention */
import { NOT_IN_A_TEAM_VALUE } from '@components/TeamsFilter';
import { LINE_TYPE_VALUES, LIST_VIEW_PARAMS, ROLE_NAME } from '@dashboard/library';
import {
  LineStatus,
  MultiMatchQueryType,
  SearchableAgentsFilterInput,
  SearchableLinesFilterInput,
  SearchableStringInput,
  SearchableTeamsFilterInput,
} from '@generated/globalTypes';
import { UseTrackerReturn } from '@hooks/useTracker';
import { NUMBER_ACTIVATION_STATE } from '@pages/numbers/Numbers.constants';

export function getSearchAgentsFilter({
  searchQuery,
  roles,
  teamsIds,
}: {
  searchQuery?: string | null;
  roles?: string[] | null;
  teamsIds?: string[] | null;
}): SearchableAgentsFilterInput | null {
  if (!teamsIds && !roles && !searchQuery) {
    return null;
  }

  let searchFilter: SearchableAgentsFilterInput | undefined;
  if (searchQuery?.length) {
    const query = searchQuery.trim();
    const hasOneWord = query.split(' ').length === 1;
    searchFilter = {
      or: [
        // We remove all spaces for email search
        { email: { matchPhrasePrefix: query.replace(/ /g, '') } },
        {
          fullName: hasOneWord
            ? { wildcard: `${query}*` }
            : {
                multiMatch: {
                  query,
                  type: MultiMatchQueryType.phrase_prefix,
                },
              },
        },
      ],
    };
  }

  let rolesFilter = null;
  if (roles) {
    if (roles.length === 1 && roles.includes(ROLE_NAME.AGENT)) {
      rolesFilter = { roles: { eq: ROLE_NAME.AGENT } };
    } else {
      rolesFilter = { roles: { match: roles.map((r) => r.toLowerCase()).join('|') } };
    }
  }

  let teamsIdsFilter = null;
  if (teamsIds?.length) {
    const ids = teamsIds.filter((id) => id !== NOT_IN_A_TEAM_VALUE);
    const hasNotInATeam = teamsIds.includes(NOT_IN_A_TEAM_VALUE);
    teamsIdsFilter = {
      or: [
        ...(ids.length ? [{ teamsIds: { terms: ids } }] : []),
        ...(hasNotInATeam ? [{ teamsIds: { terms: [] } }] : []),
      ],
    };
  }

  return {
    ...teamsIdsFilter,
    ...(roles && rolesFilter),
    ...searchFilter,
  };
}

/**
 * Create a GraphQL filter input specific to team search.
 * @param searchString - The string to search for.
 * @returns The built GraphQL filter input.
 */
export function getSearchTeamsFilters(
  searchString: string | null | undefined
): SearchableTeamsFilterInput | null | undefined {
  if (!searchString) {
    return null;
  }

  return {
    name: {
      matchPhrasePrefix: searchString,
    },
  };
}

/**
 * Create a GraphQL filter input specific to line search based on the activation state of the numbers.
 * @param numbersActivationState - The activation state of the numbers (active | inactive).
 * @returns The built GraphQL filter input.
 */
export function getLineStateFilter(
  numbersActivationState: NUMBER_ACTIVATION_STATE
): null | { activationState: SearchableStringInput } {
  if (numbersActivationState === NUMBER_ACTIVATION_STATE.INACTIVE) {
    return { activationState: { match: NUMBER_ACTIVATION_STATE.INACTIVE } };
  }
  // fetch the number whose activationState is "active" or "null"
  return { activationState: { ne: NUMBER_ACTIVATION_STATE.INACTIVE } };
}

export function getSearchLinesFilter({
  countries,
  types,
  searchQuery,
  statuses,
  activationState,
}: {
  countries?: string[] | null;
  types?: LINE_TYPE_VALUES[] | null;
  searchQuery?: string | null;
  statuses?: LineStatus[] | null;
  activationState?: NUMBER_ACTIVATION_STATE | null;
}): SearchableLinesFilterInput | null {
  let searchFilter: SearchableLinesFilterInput | undefined;
  if (searchQuery?.length) {
    // remove the "+" and white space, "+33 123" can be transformed to "33123"
    const phoneNumber = (
      searchQuery?.startsWith('+') ? searchQuery.substring(1) : searchQuery
    )?.replace(/\s/g, '');
    searchFilter = {
      or: [
        { phoneNumber: { wildcard: `*${phoneNumber}*` } },
        { name: { matchPhrasePrefix: searchQuery } },
      ],
    };
  }

  const filter = {
    ...(countries && { countryISOCode: { match: countries.join('|') } }),
    ...(types && { types: { match: types.join('|') } }),
    ...(activationState ? getLineStateFilter(activationState) : {}),
    ...(statuses?.length && { status: statuses }),
    ...searchFilter,
  };

  return filter;
}

export function trackFilterUsed(track: UseTrackerReturn['track']) {
  return (filter: string, value: string): void => {
    switch (filter) {
      case LIST_VIEW_PARAMS.SORT_DIRECTION:
        break;
      case LIST_VIEW_PARAMS.SORT_FIELD:
        track({
          event: 'list_view_sort_used',
          payload: {
            sorting_column: value,
          },
        });
        break;
      default:
        track({
          event: 'list_view_filters_updated',
          payload: {
            filter_used: filter,
            new_filter_value: value,
          },
        });
        break;
    }
  };
}
