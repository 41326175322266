import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getDurationString } from '../../helpers/time.helpers';
import { useAudioPlayer } from '../../hooks/useAudioPlayer';
import { ProgressBar } from '../ProgressBar';

import { AudioPlayerProps } from './AudioPlayer.decl';

import { PauseFilled, PlayFilled, MenuVerticalFilled } from '@aircall/icons';
import {
  Flex,
  Icon,
  Tag,
  Box,
  Dropdown,
  IconButton,
  Menu,
  MenuItem,
  MenuItemLabel,
  getColor,
} from '@aircall/tractor-v2';
import { getFile } from '@dashboard/library';
import styled from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';

const Time = styled.span`
  font-variant-numeric: tabular-nums;
`;

interface HandleProps {
  progress: number;
  disabled: boolean;
}

const Handle = styled.span.attrs<HandleProps>(({ progress }) => ({
  style: {
    left: `calc(${progress}% - 4px)`,
  },
}))<HandleProps>`
  position: absolute;
  top: -3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ disabled }) => getColor(disabled ? 'neutral-200' : 'neutral-400')};
`;

/**
 * Compute the sound time from mouse position
 * @param mouseXPos - Position of the mouse on the X axis
 * @param element - Element from which getting the dimensions
 * @param duration - Duration of the sound
 * @returns
 */
function computeTime(mouseXPos: number, element: HTMLElement, duration: number) {
  const { width, left } = element.getBoundingClientRect();
  return (duration * (mouseXPos - left)) / width;
}

/**
 * Compute the progress (in %) from the mouse position
 * @param mouseXPos - Position of the mouse on the X axis
 * @param element - Element from which getting the dimensions
 * @returns
 */
function computeProgress(mouseXPos: number, element: HTMLElement) {
  const { width, left } = element.getBoundingClientRect();
  const relativePos = mouseXPos - left;
  return Math.max(0, Math.min(100, (relativePos / width) * 100));
}

export function AudioPlayer({
  url,
  disabled,
  displayTimer = true,
  displayTimeline = true,
  download,
  iconColor,
  onPlay,
  ...otherProps
}: AudioPlayerProps) {
  const barRef = useRef<HTMLDivElement>(null);
  const {
    isLoading,
    isPlaying,
    time,
    duration,
    play: _play,
    pause,
    updateTime,
  } = useAudioPlayer(url);
  const [isMoving, setIsMoving] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);

  const { t } = useTranslation();

  const downloadEnable = !!download;
  const filename =
    downloadEnable && typeof download === 'string' && download.length > 0 ? download : 'audio.mp3';

  /**
   * Update sound time base on where the user has clicked on the
   * progress bar
   */
  const handleProgressBarClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      updateTime(computeTime(event.clientX, event.currentTarget, duration));
    },
    [duration, updateTime]
  );

  /**
   * Start holding the cursor
   */
  const holdCursor = useCallback(
    (event: React.MouseEvent) => {
      setCursorPosition(computeProgress(event.clientX, barRef.current!));
      setIsMoving(true);
    },
    [setIsMoving, barRef]
  );

  /**
   * Move the cursor depending on user gestures and regardless of
   * the current time of the sound
   */
  const moveCursor = useCallback(
    (event: MouseEvent) => {
      if (isMoving) {
        event.preventDefault();
        setCursorPosition(computeProgress(event.clientX, barRef.current!));
      }
    },
    [setCursorPosition, isMoving, barRef]
  );

  /**
   * Stop holding the cursor and update the sound time
   * based on where the cursor has been released on the
   * progress bar
   */
  const releaseCursor = useCallback(() => {
    if (isMoving) {
      updateTime((duration * cursorPosition) / 100);
      setIsMoving(false);
    }
  }, [setIsMoving, cursorPosition, updateTime, duration, isMoving]);

  /**
   * Download file function
   */
  const downloadFile = useCallback(() => {
    getFile(url, filename, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    });
  }, [url, filename]);

  /**
   * Handle play button
   */
  const play = useCallback(() => {
    onPlay?.();
    _play();
  }, [_play, onPlay]);

  useEffect(() => {
    document.addEventListener('mouseup', releaseCursor);
    return () => document.removeEventListener('mouseup', releaseCursor);
  }, [releaseCursor]);

  useEffect(() => {
    document.addEventListener('mousemove', moveCursor);
    return () => document.removeEventListener('mousemove', moveCursor);
  }, [moveCursor]);

  const progress = isMoving ? cursorPosition : Math.round((time / duration) * 100);
  const isDisabled = isLoading || disabled;
  const getEventHandler = <T,>(eh: T) => (isDisabled ? undefined : eh);

  return (
    <Flex w='100%' alignItems='center' aria-label={url} {...otherProps}>
      <Box
        mr={displayTimeline || displayTimer ? 'xxs' : '0'}
        p='xxs'
        onClick={getEventHandler(isPlaying ? pause : play)}
        cursor='pointer'
      >
        <Icon
          data-test={`audio-player-${isPlaying ? 'pause' : 'play'}`}
          component={isPlaying ? PauseFilled : PlayFilled}
          color={isDisabled ? 'icon-disabled' : iconColor || 'icon-default'}
        />
      </Box>
      {displayTimeline && (
        <Box
          data-test='audio-player-timeline'
          flexGrow={1}
          position='relative'
          mr={displayTimer ? 'xxs' : '0'}
          cursor={isDisabled ? 'default' : 'pointer'}
        >
          <ProgressBar
            progress={progress}
            onClick={getEventHandler(handleProgressBarClick)}
            ref={barRef}
            disabled={isDisabled}
          />
          <Handle
            data-test='audio-player-timeline-handle'
            progress={progress}
            onMouseDown={getEventHandler(holdCursor)}
            disabled={!!isDisabled}
          />
        </Box>
      )}
      {displayTimer && (
        <Tag data-test='audio-player-timer' size='small' mode='outline' variant='white'>
          <Time>{getDurationString(time || duration)}</Time>
        </Tag>
      )}
      {downloadEnable && (
        <Flex marginLeft='xxs' alignItems='center' data-test='audio-player-dropdown-menu-container'>
          <Dropdown
            trigger={
              <IconButton
                type='button'
                size={16}
                component={MenuVerticalFilled}
                color='icon-default'
                data-test='audio-player-dropdown-menu'
              />
            }
            align='start'
          >
            <Menu>
              <MenuItem onClick={downloadFile} data-test='audio-player-menu-item-download'>
                <MenuItemLabel>{t('audio_player.menu.download')}</MenuItemLabel>
              </MenuItem>
            </Menu>
          </Dropdown>
        </Flex>
      )}
    </Flex>
  );
}
