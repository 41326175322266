import { PhoneNumberFlagProps } from './PhoneNumberFlag.decl';

import { FlagIcon } from '@aircall/tractor-v2';
import { normalizeNumber } from '@helpers/phoneNumber.helpers';
import parsePhoneNumber from 'libphonenumber-js/min';

export function PhoneNumberFlag({ number }: PhoneNumberFlagProps) {
  const numberFormalized = normalizeNumber(number);
  const phoneNumber = parsePhoneNumber(numberFormalized);
  const country = phoneNumber?.country || 'US';

  return <FlagIcon countryCode={country} size={24} data-test={`flag-${country}`} />;
}
