import React, { ComponentType, createContext } from 'react';

import { MenuItem, Route, PlaceholderComponentProps, WrapperProps } from '@dashboard/extension';

export interface ModuleFederationContextType {
  adminRoutesDefault: Route[];
  adminRoutesEmpty: Route[];
  agentRoutesDefault: Route[];
  agentRoutesEmpty: Route[];
  menuItems: MenuItem[];
  wrappers: ComponentType<WrapperProps>[];
  placeholderComponents: Record<string, React.ComponentType<PlaceholderComponentProps>>;
  loading?: boolean;
}

export const ModuleFederationContext = createContext<ModuleFederationContextType | undefined>(
  undefined
);
