export enum ENVS {
  DEV = 'development',
  STAGING = 'staging',
  QA = 'qa',
  PROD = 'production',
  TEST = 'test',
}

/**
 * Retrieves the current env.
 * @returns the current env, if retrieved env does not comply with ENVS, returns the dev one
 */
export function getEnv(): ENVS {
  const env = process.env.NODE_ENV as ENVS;
  return Object.values(ENVS).includes(env) ? env : ENVS.DEV;
}

/**
 * Check if we are in development environment.
 * @returns a predicate
 */
export function isDev(): boolean {
  return getEnv() === ENVS.DEV;
}

export function isProd(): boolean {
  return getEnv() === ENVS.PROD;
}
