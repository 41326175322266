import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTheme } from '@aircall/tractor-v2';

/**
 * Get index of matching media.
 * @param medias - list of media
 * @returns index of matching media
 */
export function getMatchingMediaIndex(medias: MediaQueryList[]): number {
  const index = [...medias].reverse().findIndex((media) => media.matches);
  return medias.length - index - 1;
}

/**
 * Accepts an array of values for mobile-first repsponsive styling
 * and returns corresponding value of the matching media query
 * @param cases - array of values for mobile-first repsponsive styling
 * @returns - corresponding value of the matching media query
 *
 * ## Example
 * ```typescript
 * const screenSizeAlias = useBreakpointSwitch(["xSmall", "small", "medium", "large", "xLarge"])
 * // `screenSizeAlias` equals to 'xSmall' if screen size is less than 576px , equals to 'xLarge' if screen size is more than 1200px
 * ```
 */
export function useBreakpointSwitch<T>(cases: T[]): typeof cases[number] {
  const theme = useTheme();

  const medias = useMemo(
    () =>
      [
        theme.screens.xs,
        theme.screens.sm,
        theme.screens.md,
        theme.screens.lg,
        theme.screens.xl,
      ].map((screen) => window.matchMedia(`screen and (min-width: ${screen}px)`)),
    [theme.screens]
  );

  const [index, setIndex] = useState(getMatchingMediaIndex(medias));

  const onMediaQueryChange = useCallback(() => {
    setIndex(getMatchingMediaIndex(medias));
  }, [medias]);

  useEffect(() => {
    medias.forEach((media) => {
      try {
        media.addEventListener('change', onMediaQueryChange);
      } catch (err) {
        /* istanbul ignore next */
        media.addListener(onMediaQueryChange);
      }
    });

    return () => {
      medias.forEach((media) => {
        try {
          media?.removeEventListener('change', onMediaQueryChange);
        } catch (e) {
          /* istanbul ignore next */
          media.removeListener(onMediaQueryChange);
        }
      });
    };
  }, [medias, onMediaQueryChange]);

  return cases[index] ?? cases[cases.length - 1];
}
