import { InboundCallReceiversSectionProps } from './InboundCallReceivers.decl';

import { AddButton } from '@components/Button/AddButton';
import {
  NUMBER_DETAIL_ROUTE_TAB,
  USER_DETAIL_ROUTE,
  TEAM_DETAIL_ROUTE,
} from '@constants/routes.constants';
import {
  List,
  ListItem,
  Paper,
  Avatar,
  EmojiAvatar,
  getInitials,
  routeReplacer,
} from '@dashboard/library';
import {
  NumberDetailQuery_numberDetail_dispatchGroups_dispatchable_Team,
  NumberDetailQuery_numberDetail_dispatchGroups_dispatchable_User,
} from '@generated/NumberDetailQuery';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { NUMBER_DETAIL_TABS } from '@pages/number-detail/NumberDetail.decl';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

export function InboundCallReceiversSection({ dispatchGroups }: InboundCallReceiversSectionProps) {
  const { numberId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();
  const dispatchables = dispatchGroups
    .map((dispatchGroup) => dispatchGroup.dispatchable)
    .filter((dispatchable) => dispatchable);

  const userListItem = (
    dispatchableUser: NumberDetailQuery_numberDetail_dispatchGroups_dispatchable_User,
    index: number
  ) => {
    const initials = getInitials(dispatchableUser.firstName, dispatchableUser.lastName);

    return (
      <ListItem
        key={dispatchableUser.id}
        avatar={<Avatar src={dispatchableUser.pictureUrl} initials={initials} size='small' />}
        primaryText={`${dispatchableUser.firstName} ${dispatchableUser.lastName}`}
        dropdownOptions={[
          {
            title: t('number_details.teams_users.inbound_call_receivers.go_to_user'),
            callback: () => navigate(USER_DETAIL_ROUTE, { userId: dispatchableUser.id }),
          },
        ]}
        data-test={`inbound-call-receivers-user-${index}`}
      />
    );
  };

  const teamListItem = (
    dispatchableTeam: NumberDetailQuery_numberDetail_dispatchGroups_dispatchable_Team,
    index: number
  ) => (
    <ListItem
      key={dispatchableTeam.ID}
      avatar={<EmojiAvatar name={dispatchableTeam.imageName} size='small' />}
      primaryText={`${dispatchableTeam.name}`}
      dropdownOptions={[
        {
          title: t('number_details.teams_users.inbound_call_receivers.go_to_team'),
          callback: () => navigate(TEAM_DETAIL_ROUTE, { teamId: dispatchableTeam.ID }),
        },
      ]}
      data-test={`inbound-call-receivers-team-${index}`}
    />
  );

  return (
    <Paper
      title={t('number_details.teams_users.inbound_call_receivers.title')}
      subtitle={t('number_details.teams_users.inbound_call_receivers.subtitle')}
    >
      <List>
        {dispatchables.map((dispatchable, index) =>
          dispatchable?.__typename === 'User'
            ? userListItem(dispatchable, index)
            : teamListItem(dispatchable, index)
        )}
      </List>
      <Link
        to={routeReplacer(NUMBER_DETAIL_ROUTE_TAB, {
          numberId,
          tab: NUMBER_DETAIL_TABS.CALL_DISTRIBUTION,
        })}
      >
        <AddButton
          translationKey='number_details.teams_users.inbound_call_receivers.add_teams_users.button'
          hasData={!!dispatchables.length}
          data-test='inbound-call-receivers-add-teams-users-button'
        />
      </Link>
    </Paper>
  );
}
