import { useEffect } from 'react';

import {
  NumberIntegrationsQuery,
  NumberIntegrationsQueryVariables,
  NumberIntegrationsQuery_numberIntegrations_data,
} from '@generated/NumberIntegrationsQuery';
import { NUMBER_INTEGRATIONS_QUERY } from '@graphql/queries/NumberIntegrationsQuery';
import { getExternalErrorTranslationStrings, ClientError } from '@helpers/errors.helpers';
import { useGraphQuery } from '@hooks/useQuery/useGraphQuery';
import { useToast } from '@hooks/useToast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface UseGetNumberConnectedIntegrationsReturnType {
  connectedIntegrations: NumberIntegrationsQuery_numberIntegrations_data[];
  error?: ClientError;
  loading: boolean;
}

/**
 * Hook to get connected integration of a number
 * @returns Object holding fetched result, error and loading state
 */
export const useGetNumberConnectedIntegrations =
  (): UseGetNumberConnectedIntegrationsReturnType => {
    const { t } = useTranslation();
    const { numberId } = useParams();

    const toast = useToast();
    const { data, error, loading } = useGraphQuery<
      NumberIntegrationsQuery,
      NumberIntegrationsQueryVariables
    >(NUMBER_INTEGRATIONS_QUERY, {
      variables: { numberId: parseInt(numberId) },
      fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
      if (error) {
        toast.showToast({
          variant: 'critical',
          message: t(getExternalErrorTranslationStrings(error)),
        });
      }
    }, [error, t, toast]);

    return {
      connectedIntegrations: data?.numberIntegrations.data || [],
      loading: loading && !data,
    };
  };
