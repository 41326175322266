import {
  ACCOUNT_ROUTE,
  NUMBER_DETAIL_ROUTE,
  NUMBER_PROOF_OF_ID_ROUTE,
  NUMBER_PURCHASE_ROUTE,
  ACTIVITY_FEED_ROUTE,
  STATS_CHILD_ROUTE,
  USER_DETAIL_ROUTE,
  INTEGRATIONS_ROUTE,
  NUMBER_PURCHASE_ROUTE_AT_IVR_CREATION,
  NUMBER_PURCHASE_ROUTE_AT_IVR_EDITION,
  ACCOUNT_TABS,
  INTEGRATION_ROUTE,
} from '../../../constants/routes.constants';
import { ReduxAction } from '../LegacyDashboard.decl';

import { APPLICATIONS_TYPES } from '@constants/integrations.constants';
import { routeReplacer } from '@dashboard/library';
import { matchPath } from 'react-router';

const numberPurchaseBasedActions = [
  {
    type: 'set journey step',
    payload: {
      journeyName: 'numberCreation',
      step: 'lineType',
    },
  },
  {
    type: 'set journey open state',
    payload: {
      journeyName: 'numberCreation',
      open: true,
    },
  },
];

export interface LegacyNavigationPayload {
  name: string;
  params?: Record<string, string>;
  url?: string;
  actions?: ReduxAction[];
}

export type NewRouteBuilder = (nav: LegacyNavigationPayload) => string | undefined;

export const LEGACY_TO_NEW_ROUTE_MAPPING: NewRouteBuilder[] = [
  // Numbers detail
  ({ name, params }: LegacyNavigationPayload): string | undefined => {
    if (name !== 'app.menu.r') {
      return undefined;
    }

    const match = params?.path?.match(/^numbers\/(\d+)$/);
    if (!match) {
      return undefined;
    }

    return routeReplacer(NUMBER_DETAIL_ROUTE, { numberId: match[1] });
  },

  // User detail
  ({ name, params }: LegacyNavigationPayload): string | undefined => {
    if (name !== 'app.menu.users.detail') {
      return undefined;
    }

    const userId = params?.userId;
    if (!userId) {
      return undefined;
    }

    return routeReplacer(USER_DETAIL_ROUTE, { userId });
  },

  // Company page
  ({ name, params }: LegacyNavigationPayload): string | undefined => {
    if (name !== 'app.menu.company') {
      return undefined;
    }

    const isUpgrade = params?.['#']?.match(/^open_upgrade/);
    if (!isUpgrade) {
      return undefined;
    }

    // TODO: redirect to the upgrade section once it is finished developing
    return routeReplacer(`${ACCOUNT_ROUTE}/${ACCOUNT_TABS.YOUR_PLAN}`);
  },

  // Integrations page
  ({ name }: LegacyNavigationPayload): string | undefined => {
    if (name !== 'app.menu.integrations') {
      return undefined;
    }
    return routeReplacer(INTEGRATIONS_ROUTE);
  },
];

/**
 * Get the new route corresponding to a given legacy route
 * @param nav - Legacy route object
 * @returns Corresponding new route
 */
export function findNewRouteFromLegacyRoute(nav: LegacyNavigationPayload): string | undefined {
  for (let i = 0, { length } = LEGACY_TO_NEW_ROUTE_MAPPING; i < length; i += 1) {
    const res = LEGACY_TO_NEW_ROUTE_MAPPING[i](nav);

    if (res) {
      return res;
    }
  }

  return undefined;
}

export function mapNewToLegacyRoutes(
  _featureFlags: Record<string, boolean>
): Record<string, (params: Record<string, string>) => LegacyNavigationPayload> {
  return {
    [NUMBER_PURCHASE_ROUTE]: () => ({
      name: 'app.menu.r',
      params: { path: '' },
      actions: numberPurchaseBasedActions,
    }),
    [NUMBER_PURCHASE_ROUTE_AT_IVR_CREATION]: (params) => ({
      name: 'app.menu.r',
      params: { path: '' },
      actions: [
        ...numberPurchaseBasedActions,
        {
          type: 'update journey data',
          payload: {
            journeyName: 'numberCreation',
            data: {
              isFromIvrFlow: true,
              ivrFlowMode: 'create',
              ivrFlowNumberId: params.numberId,
              ivrFlowSettingId: '',
              ivrFlowChosenKey: params.key,
            },
          },
        },
      ],
    }),
    [NUMBER_PURCHASE_ROUTE_AT_IVR_EDITION]: (params) => ({
      name: 'app.menu.r',
      params: { path: '' },
      actions: [
        ...numberPurchaseBasedActions,
        {
          type: 'update journey data',
          payload: {
            journeyName: 'numberCreation',
            data: {
              isFromIvrFlow: true,
              ivrFlowMode: 'edit',
              ivrFlowNumberId: params.numberId,
              ivrFlowSettingId: params.settingId,
              ivrFlowChosenKey: params.key,
            },
          },
        },
      ],
    }),
    [NUMBER_PROOF_OF_ID_ROUTE]: (params) => ({
      name: 'app.menu.r',
      params: { path: '' },
      actions: [
        {
          type: 'set journey open state',
          payload: {
            journeyName: 'numberProofOfId',
            open: true,
          },
        },
        {
          type: 'update journey data',
          payload: {
            journeyName: 'numberProofOfId',
            data: {
              e164: params.number,
            },
          },
        },
      ],
    }),

    [INTEGRATION_ROUTE]: ({ applicationType, integrationId }) => ({
      name: 'app.menu.integrations.detail',
      params: {
        id: `${applicationType === APPLICATIONS_TYPES.HUB ? 'hub_' : ''}${integrationId}`,
      },
    }),
    [ACTIVITY_FEED_ROUTE]: () => ({
      name: 'app.menu.calls.activity_feed',
    }),
    [STATS_CHILD_ROUTE.OVERVIEW]: () => ({
      name: 'app.menu.calls',
    }),
    [STATS_CHILD_ROUTE.ANALYTICS]: () => ({
      name: 'app.menu.calls.advanced_analytics',
    }),
    [STATS_CHILD_ROUTE.ANALYTICS_PLUS]: () => ({
      name: 'app.menu.calls.looker_analytics',
    }),
    [STATS_CHILD_ROUTE.MONITORING]: () => ({
      name: 'app.menu.calls.looker_monitoring',
    }),
  };
}

/**
 * Get the legacy route corresponding to a given new route
 * @param url - URL of the new route
 * @param params - Params of the new route
 * @returns Corresponding legacy route
 */
export function findLegacyRouteFromNewRoute(
  url: string,
  params: Record<string, string>,
  featureFlags: Record<string, boolean> = {}
): LegacyNavigationPayload | undefined {
  const mapping = Object.entries(mapNewToLegacyRoutes(featureFlags));

  for (let i = 0, { length } = mapping; i < length; i += 1) {
    const [path, builder] = mapping[i];

    if (matchPath(path, url)) {
      return builder(params);
    }
  }

  return undefined;
}
