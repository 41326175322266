/* eslint-disable @typescript-eslint/naming-convention */

import { APP_CONFIG } from '../constants/environment.constants';

import { COOKIE_KEYS } from '@constants/auth.constants';
import { tracker } from '@constants/tracker.constants';
import { getOperatingSystem, isDefined } from '@dashboard/library';
import { TrackFunctionParams } from '@hooks/useTracker';
import Cookies from 'js-cookie';
import { snakeCase } from 'snake-case';

export interface CommonPayload {
  platform: string;
  release: string;
  screen_size: string;
  window_size: string;
  os_version: string;
}

export interface SignedInAsPayload {
  aircall_employee_signed_in_as: boolean;
}

export interface GetTrackSettingsPropertiesParams {
  event: string;
  settingsSection: string;
  setting: string;
  field?: string;
  action?: string;
  isOwnUser?: boolean;
}

/**
 * Gets the applocation context.
 * @returns object holding the current application's context
 */
export const getCommonPayload = (): CommonPayload => ({
  platform: APP_CONFIG.device,
  release: APP_CONFIG.release,
  screen_size: `${window.screen.width}x${window.screen.height}`,
  window_size: `${window.outerWidth}x${window.outerHeight}`,
  os_version: getOperatingSystem().osVersion,
});

/**
 * Gets the applocation context.
 * @returns object holding the current application context
 */
export const getSignedInAsPayload = (): SignedInAsPayload => ({
  aircall_employee_signed_in_as: Cookies.get(COOKIE_KEYS.IS_CONNECTED_AS) === 'true',
});

/**
 * Gets formatted setting properties to be tracked.
 * @param getTrackSettingsPropertiesParams - destructured parameters
 * @returns object holding tracking properties
 */
export const getTrackSettingsProperties = ({
  event,
  settingsSection,
  setting,
  action,
  isOwnUser,
  field,
}: GetTrackSettingsPropertiesParams): TrackFunctionParams => ({
  event,
  payload: {
    ...(isDefined(isOwnUser) && { target_user: isOwnUser ? 'self' : 'other_user' }),
    ...(isDefined(field) ? { action: `${snakeCase(field)}_${action || 'updated'}` } : { action }),
    settings_section: settingsSection,
    setting,
  },
});

/**
 * Sends tracker event.
 * @param eventName - event name.
 * @param payload - data payload.
 * @returns void
 */
export function sendEvent<PayloadType extends object>(
  eventName: string,
  payload: PayloadType
): void {
  return tracker.track(eventName, payload);
}
