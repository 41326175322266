const DEFAULT_PLANS = {
  starter: {
    annually: 19,
    monthly: 25,
  },
  essentials: {
    annually: 30,
    monthly: 40,
  },
  professional: {
    annually: 50,
    monthly: 70,
  },
};

export const pricing = [
  {
    id: 'area-1',
    name: 'North America, US & Canada',
    currency: '$',
    currencyName: 'USD',
    currencyPosition: false,
    plans: DEFAULT_PLANS,
  },
  {
    id: 'area-2',
    name: 'FR, DE, ES, BE, IT, NL, IE, PT',
    currency: '€',
    currencyName: 'EUR',
    currencyPosition: true,
    plans: DEFAULT_PLANS,
  },
  {
    id: 'area-3',
    name: 'UK',
    currency: '£',
    currencyName: 'GBP',
    currencyPosition: false,
    plans: {
      starter: {
        annually: 15,
        monthly: 20,
      },
      essentials: {
        annually: 25,
        monthly: 30,
      },
      professional: {
        annually: 40,
        monthly: 55,
      },
    },
  },
  {
    id: 'area-4',
    name: 'Australia / NZ',
    currency: 'A$',
    currencyName: 'AUD',
    currencyPosition: false,
    plans: {
      starter: {
        annually: 29,
        monthly: 39,
      },
      essentials: {
        annually: 45,
        monthly: 60,
      },
      professional: {
        annually: 75,
        monthly: 105,
      },
    },
  },
  {
    id: 'area-5',
    name: 'Singap',
    currency: '$',
    currencyName: 'USD',
    currencyPosition: false,
    plans: DEFAULT_PLANS,
  },
  {
    id: 'area-6',
    name: 'Rest of EMEA',
    currency: '€',
    currencyName: 'EUR',
    currencyPosition: true,
    plans: DEFAULT_PLANS,
  },
  {
    id: 'area-7',
    name: 'Rest of the World',
    currency: '$',
    currencyName: 'USD',
    currencyPosition: false,
    plans: DEFAULT_PLANS,
  },
];
