import { useUpdateNumberToggles } from '../hooks/useUpdateNumberToggles';

import { Spacer, Typography, Banner, BannerHeading, BannerIcon } from '@aircall/tractor-v2';
import { ExternalLink, RouterLink } from '@components/Link';
import { ToggleRow } from '@components/ToggleRow';
import { NUMBER_DETAIL_ROUTE_TAB } from '@constants/routes.constants';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { SUPPORT_URL } from '@constants/urls.constants';
import { List, ListItem, Paper, routeReplacer } from '@dashboard/library';
import { NUMBER_DETAIL_TABS } from '@pages/number-detail/NumberDetail.decl';
import { useTranslation } from 'react-i18next';

export function CallRecordingSettings() {
  const { t } = useTranslation();
  const { updateNumberToggles, number } = useUpdateNumberToggles({});
  return (
    <Paper
      title={t('number_details.settings.call_recording.title')}
      subtitle={t('number_details.settings.call_recording.description')}
    >
      <Spacer fluid direction='vertical' space='xs'>
        <Spacer fluid direction='vertical' space='xs'>
          <Typography>{t('number_details.settings.call_recording.incoming_calls')}</Typography>
          <List>
            <ListItem h='auto'>
              <ToggleRow
                size='large'
                data-test='call-recording-start-recording-toggle'
                value={!!number?.inboundRecordingActivated}
                onChange={(inboundRecordingActivated) =>
                  updateNumberToggles(
                    { inboundRecordingActivated },

                    {
                      successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
                        name: t(`number_details.settings.call_recording.auto_start_recording`),
                      }),
                      trackingOptions: {
                        section: 'Settings',
                        setting: 'Call recording - inbound - start automatically',
                      },
                    }
                  )
                }
                title={t('number_details.settings.call_recording.auto_start_recording')}
                description={
                  !!number?.inboundRecordingActivated && (
                    <Spacer fluid direction='vertical' space='xs'>
                      <Banner
                        inline
                        variant='warning'
                        data-test='call-recording-start-recording-banner'
                      >
                        <BannerIcon />
                        <BannerHeading>
                          {t('number_details.settings.call_recording.warning_banner')}
                        </BannerHeading>
                      </Banner>
                      <Spacer direction='horizontal' space='s'>
                        <RouterLink
                          to={routeReplacer(NUMBER_DETAIL_ROUTE_TAB, {
                            numberId: number.id,
                            tab: NUMBER_DETAIL_TABS.CALL_DISTRIBUTION,
                          })}
                        >
                          {t('number_details.settings.call_recording.edit_welcome_message_button')}
                        </RouterLink>
                        <ExternalLink target='_blank' rel='noreferrer' href={SUPPORT_URL}>
                          {t('number_details.settings.call_recording.contact_support_team_button')}
                        </ExternalLink>
                      </Spacer>
                    </Spacer>
                  )
                }
              />
            </ListItem>
            <ListItem h='auto'>
              <ToggleRow
                size='large'
                data-test='call-recording-inbound-recording-actions-toggle'
                value={!!number?.allowInboundRecordingActions}
                onChange={(allowInboundRecordingActions) =>
                  updateNumberToggles(
                    { allowInboundRecordingActions },

                    {
                      successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
                        name: t(`number_details.settings.call_recording.allow_user_recording`),
                      }),
                      trackingOptions: {
                        section: 'Settings',
                        setting: 'Call recording - inbound - allow user controls',
                      },
                    }
                  )
                }
                title={t('number_details.settings.call_recording.allow_user_recording')}
              />
            </ListItem>
          </List>
        </Spacer>
        <Spacer fluid direction='vertical' space='xs'>
          <Typography>{t('number_details.settings.call_recording.outgoing_calls')}</Typography>
          <List>
            <ListItem h='auto'>
              <ToggleRow
                size='large'
                data-test='call-recording-start-outbound-recording-toggle'
                value={!!number?.outboundRecordingActivated}
                onChange={(outboundRecordingActivated) =>
                  updateNumberToggles(
                    { outboundRecordingActivated },

                    {
                      successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
                        name: t(`number_details.settings.call_recording.auto_start_recording`),
                      }),
                      trackingOptions: {
                        section: 'Settings',
                        setting: 'Call recording - outbound - start automatically',
                      },
                    }
                  )
                }
                title={t('number_details.settings.call_recording.auto_start_recording')}
              />
            </ListItem>
            <ListItem h='auto'>
              <ToggleRow
                size='large'
                data-test='call-recording-outbound-recording-actions-toggle'
                value={!!number?.allowOutboundRecordingActions}
                onChange={(allowOutboundRecordingActions) =>
                  updateNumberToggles(
                    { allowOutboundRecordingActions },

                    {
                      successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
                        name: t(`number_details.settings.call_recording.allow_user_recording`),
                      }),
                      trackingOptions: {
                        section: 'Settings',
                        setting: 'Call recording - outbound - allow user controls',
                      },
                    }
                  )
                }
                title={t('number_details.settings.call_recording.allow_user_recording')}
              />
            </ListItem>
          </List>
        </Spacer>
      </Spacer>
    </Paper>
  );
}
