import { useCallback } from 'react';

import {
  UseDeleteDispatchGroup,
  UseDeleteDispatchGroupReturn,
} from './useDeleteDispatchGroup.decl';

import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import {
  DeleteDispatchGroupMutation,
  DeleteDispatchGroupMutationVariables,
} from '@generated/DeleteDispatchGroupMutation';
import { NumberDetailQuery } from '@generated/NumberDetailQuery';
import { DELETE_DISPATCH_GROUPE_MUTATION } from '@graphql/mutations/DeleteDispatchGroupMutation';
import { NUMBER_DETAIL_QUERY } from '@graphql/queries/NumberDetailQuery';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useTranslation } from 'react-i18next';

/**
 * The hook handles deleting a dispatch group by numberId and groupId, and showing a snackbar
 * @param UseDeleteDispatchGroup - Object that contains numberId, groupId and message name
 * @returns a callback to delete dispatch group
 */
export function useDeleteDispatchGroup({
  numberId,
  groupId,
  name,
}: UseDeleteDispatchGroup): UseDeleteDispatchGroupReturn {
  const { t } = useTranslation();
  const [mutate] = useGraphMutationWithFeedback<
    DeleteDispatchGroupMutation,
    DeleteDispatchGroupMutationVariables
  >(DELETE_DISPATCH_GROUPE_MUTATION, {
    type: 'snackbar',
  });

  const deleteDispatchGroup = useCallback(async () => {
    mutate(
      {
        variables: {
          numberId,
          dispatchGroupId: groupId,
        },
        update: (cache) => {
          cache.modify({
            id: cache.identify({ __typename: 'DispatchGroup', id: groupId }),
            fields: (_, { DELETE }) => DELETE,
          });

          // Reorder priority to synch with backend behaviour

          const { numberDetail } = cache.readQuery<NumberDetailQuery>({
            query: NUMBER_DETAIL_QUERY,
            variables: { id: numberId },
          })!;

          const newDispatchGroups = [...numberDetail.dispatchGroups]
            .sort((a, b) => a.priority - b.priority)
            .map((dispatchGroup, i) => ({
              ...dispatchGroup,
              priority: i + 1,
            }));

          cache.writeQuery<NumberDetailQuery>({
            query: NUMBER_DETAIL_QUERY,
            variables: { id: numberId },
            data: {
              numberDetail: {
                ...numberDetail,
                dispatchGroups: newDispatchGroups,
              },
            },
          });
        },
        optimisticResponse: {
          deleteDispatchGroupREST: {
            _: null,
            __typename: 'Void',
          },
        },
      },
      { successMessage: t(SUCCESS_FEEDBACK_MESSAGES.DELETION_SUCCESS, { name }) }
    );
  }, [numberId, groupId, mutate, t, name]);

  return { deleteDispatchGroup };
}
