import { ChangeEvent, useEffect, useRef } from 'react';

import { CustomPlayer, CustomTextField, TRANSLATION_PREFIX } from './AudioSettingFile.constants';
import { AudioSettingFileRecordProps, CUSTOM_FILE_FLOW } from './AudioSettingFile.decl';
import { getDefaultAudioFileName } from './AudioSettingFile.helpers';

import { MicOnFilled, PauseFilled, TrashOutlined } from '@aircall/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  IconButton,
  Spacer,
  Typography,
  Banner,
  BannerHeading,
  BannerIcon,
} from '@aircall/tractor-v2';
import { useRecorder } from '@hooks/useRecorder';
import { useTimer } from '@hooks/useTimer';
import { useTracker } from '@hooks/useTracker/useTracker';
import { format } from 'date-fns';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export function AudioSettingFileRecord({
  onRecordChange,
  recordStatus,
}: AudioSettingFileRecordProps) {
  const {
    input: { onChange: setAudioFile },
  } = useField('audioSetting.file');
  const {
    input: { onChange: setRecordName, value: recordName },
  } = useField('audioSetting.recordAudioName', { initialValue: getDefaultAudioFileName() });
  const { audioURL, error, isRecording, startRecording, stopRecording, closeRecording } =
    useRecorder(setAudioFile);
  const { timer } = useTimer(isRecording);

  const { t } = useTranslation();
  const { track } = useTracker();
  const nameInputRef = useRef<HTMLInputElement>(null);

  const handleRecordToStart = () => {
    track({ event: 'recording_deleted' });
    setAudioFile(undefined);
    onRecordChange(CUSTOM_FILE_FLOW.RECORD_START);
  };
  const handleRecordStop = () => {
    stopRecording();
    onRecordChange(CUSTOM_FILE_FLOW.RECORD_DONE);
  };
  const handleEditNameClick = () => nameInputRef.current && nameInputRef.current.focus();
  const handleEditNameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setRecordName(value);

  useEffect(() => {
    const onUnMount = () => {
      closeRecording();
    };

    return () => onUnMount();
  }, [closeRecording]);

  return (
    <Spacer direction='vertical' space='xxs' fluid>
      {recordStatus === CUSTOM_FILE_FLOW.RECORD_START && (
        <Flex
          alignItems='center'
          backgroundColor='surface-default'
          borderWidth='1px'
          borderStyle='dashed'
          borderColor='neutral-400'
          borderTop='none'
          borderRadius='0 0 8px 8px'
          flexDirection='column'
          justifyContent='center'
          p='xl'
          data-test='audio-setting-recordzone-initiation'
        >
          <Typography variant='headingBoldL' color='text-secondary' mb='s'>
            {format(timer, 'mm:ss')}
          </Typography>
          <Typography variant='bodySemiboldM' color='text-secondary' mb='s'>
            {t(`${TRANSLATION_PREFIX}.recordzone.primary_text`)}
          </Typography>
          <Typography
            variant='bodyMediumS'
            color='text-secondary'
            px={40}
            textAlign='center'
            mb='m'
          >
            {t(`${TRANSLATION_PREFIX}.recordzone.secondary_text`)}
          </Typography>
          {!isRecording && (
            <Button
              disabled={!!error}
              data-test='recordzone-start-button'
              mode='outline'
              onClick={startRecording}
            >
              <MicOnFilled />
              {t(`${TRANSLATION_PREFIX}.recordzone.start_button`)}
            </Button>
          )}
          {isRecording && (
            <Button data-test='recordzone-stop-button' mode='outline' onClick={handleRecordStop}>
              <PauseFilled />
              {t(`${TRANSLATION_PREFIX}.recordzone.stop_button`)}
            </Button>
          )}
        </Flex>
      )}
      {recordStatus === CUSTOM_FILE_FLOW.RECORD_DONE && (
        <Box
          border='1px solid rgb(217, 217, 214);'
          backgroundColor='surface-default'
          borderRadius='8px'
          data-test='audio-setting-recordzone-validation'
        >
          <Grid gridTemplateColumns='auto auto 1fr' pr='xxs' alignItems='center'>
            <CustomTextField
              ref={nameInputRef}
              data-test='recordzone-name-input'
              value={recordName}
              onChange={handleEditNameChange}
            />
            <Button onClick={handleEditNameClick} mode='link'>
              {t(`${TRANSLATION_PREFIX}.recordzone.edit_name_button`)}
            </Button>
            <IconButton
              ml='auto'
              component={TrashOutlined}
              color='neutral-400'
              discColor='transparent'
              type='button'
              onClick={handleRecordToStart}
              data-test='recordzone-clear-button'
            />
          </Grid>
          <Divider orientation='horizontal' size='xSmall' />
          <CustomPlayer
            url={audioURL}
            displayTimer
            displayTimeline
            disabled={isRecording}
            download={recordName}
          />
          <Divider orientation='horizontal' size='xSmall' />
        </Box>
      )}
      {error && (
        <Banner inline variant='critical' data-test='recordzone-error-banner'>
          <BannerIcon />
          <BannerHeading>{t(`${TRANSLATION_PREFIX}.recordzone.error_banner`)}</BannerHeading>
        </Banner>
      )}
    </Spacer>
  );
}
