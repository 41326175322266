import React, { useCallback, useEffect, useRef } from 'react';

import { getScrollableParent } from '../helpers/layout/getScrollableParent';

import noop from 'lodash-es/noop';

export interface CloseOnScrollOptions {
  /** Ref to the element we want to control */
  eleRef: React.RefObject<HTMLElement>;

  /** Ref to the inner scrollable area where we don't want to close while scrolling inside */
  innerScrollRef?: React.RefObject<HTMLElement>;

  /** Whether the element is currently open */
  open: boolean;

  /** Callback to close the element */
  onClose: () => void;
}

export interface CloseOnScroll {
  outerScrollableEle?: HTMLElement;
}

/**
 * Close the element when scrolling event is detected.
 * If scrolling inside the area of `innerScrollRef`, the element will not be closed.
 * @param closeOnScrollOptions - destructured parameter
 * @returns object holding the ref of the outer scrollable element
 */
export const useCloseOnScroll = ({
  eleRef,
  innerScrollRef,
  open,
  onClose,
}: CloseOnScrollOptions): CloseOnScroll => {
  const onScroll = useCallback(
    (scrollEvent: Event) => {
      // ignores the scroll event when scrolling inside the inner scrollable area
      if (
        innerScrollRef &&
        innerScrollRef.current &&
        !innerScrollRef.current?.contains(scrollEvent.target as Node)
      ) {
        onClose();
      }
    },
    [onClose, innerScrollRef]
  );

  /** The closest element to be scrollable */
  const outerScrollableRef = useRef<HTMLElement>();

  useEffect(() => {
    outerScrollableRef.current = getScrollableParent(eleRef.current);
    if (!open) {
      return noop;
    }

    const isBody = outerScrollableRef.current === document.body;
    const element = isBody ? window : outerScrollableRef.current;

    element.addEventListener('scroll', onScroll);
    // add the event listener to body to prevent users scroll on the window instead
    if (!isBody) {
      window.addEventListener('scroll', onScroll);
    }

    return () => {
      element.removeEventListener('scroll', onScroll);
      if (!isBody) {
        window.removeEventListener('scroll', onScroll);
      }
    };
  }, [eleRef, onScroll, open, outerScrollableRef]);

  return { outerScrollableEle: outerScrollableRef.current };
};
