import { DateFilterFooterMessageProps, DateFilterFooterProps } from './DateFilter.decl';
import { UpgradeButton } from './UpgradeButton/UpgradeButton';

import { InformationOutlined } from '@aircall/icons';
import { Flex, Icon, Typography } from '@aircall/tractor-v2';
import { AnalyticsDashboardType } from '@generated/globalTypes';
import { differenceInMonths } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';

const proDashboardTypes = [
  AnalyticsDashboardType.INBOUND_ACTIVITY_PRO,
  AnalyticsDashboardType.OUTBOUND_ACTIVITY_PRO,
  AnalyticsDashboardType.UNANSWERED_INBOUND_CALL_PRO,
  AnalyticsDashboardType.USERS_ACTIVITY_PRO,
  AnalyticsDashboardType.CALL_QUALITY_PRO,
];

function DateFilterFooterMessage({ children }: DateFilterFooterMessageProps) {
  return (
    <Flex
      alignItems='center'
      data-test='date-filter-footer'
      gap='xxs'
      justifyContent='center'
      minHeight={48}
      px='xxs'
    >
      <Icon color='neutral-400' component={InformationOutlined} size={24} />
      <Typography color='informative-800' lineHeight='normal' variant='bodyRegularS'>
        {children}
      </Typography>
    </Flex>
  );
}

export function DateFilterFooter({
  dashboardType,
  maxDateRangeInDays,
  minDate,
  pageHeaderTitle,
  value,
}: DateFilterFooterProps) {
  const { t } = useTranslation();

  if (maxDateRangeInDays && value.startDate && !value.endDate) {
    return (
      <DateFilterFooterMessage>
        {t('filters.date.date_range_max_limit', {
          dashboard: pageHeaderTitle,
          count: maxDateRangeInDays,
        })}
      </DateFilterFooterMessage>
    );
  }

  if (minDate) {
    const count = differenceInMonths(new Date(), minDate);
    const isProDashboard = proDashboardTypes.includes(dashboardType);

    return (
      <DateFilterFooterMessage>
        {isProDashboard ? (
          <Trans
            components={{ Link: <UpgradeButton /> }}
            count={count}
            i18nKey='filters.date.plan_history_limit'
          />
        ) : (
          t('filters.date.history_limit', { dashboard: pageHeaderTitle, count })
        )}
      </DateFilterFooterMessage>
    );
  }

  return null;
}
