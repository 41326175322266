import { CallTagModalProps } from './CallTagModal.decl';

import { FormItem, Box } from '@aircall/tractor-v2';
import { NEW_TAGS_COLORS } from '@constants/style.constants';
import {
  FormField,
  Gap,
  ModalForm,
  TagColorPicker,
  validateAlreadyExists,
} from '@dashboard/library';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export function CallTagModal({
  title,
  initialValues,
  isOpen,
  onClose,
  onSubmit,
  namesBlacklist = [],
}: CallTagModalProps) {
  const { t } = useTranslation();

  return (
    <ModalForm
      show={isOpen}
      onHide={onClose}
      title={title}
      size='small'
      submitButtonText={t('calls.pages.tags.add_modal.confirm')}
      formProps={{
        onSubmit,
        initialValues,
      }}
      onCancel={onClose}
    >
      {() => (
        <Gap space={0}>
          <Box flexGrow={1} w='100%' mr='s'>
            <FormField
              label={t('calls.pages.tags.add_modal.label')}
              name='name'
              validate={validateAlreadyExists(namesBlacklist)}
              required
              getErrorMessage={t}
              data-test='modal-tag-input'
            />
          </Box>
          <Box flexShrink={1} flexGrow={0}>
            <FormItem label={t('calls.pages.tags.add_modal.tag_color')}>
              <Field name='color' defaultValue={NEW_TAGS_COLORS[0]}>
                {({ input: { onChange, value } }) => (
                  <TagColorPicker
                    value={value}
                    onChange={onChange}
                    data-test='modal-tag-color-picker'
                  />
                )}
              </Field>
            </FormItem>
          </Box>
        </Gap>
      )}
    </ModalForm>
  );
}
