import { useEffect, useState } from 'react';

import { AudioPlaybackRow } from '../AudioSetting/AudioPlaybackRow';
import { TRANSLATION_PREFIX } from '../config';
import { DiagramBranch, DiagramCard } from '../FlowDiagram';

import { LocationWithState, WelcomeMusicCardProps } from './ClassicNumberAudiosCard.decl';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

export function ClassicNumberAudiosCard({ numberDetail }: WelcomeMusicCardProps) {
  const { t } = useTranslation();
  const { state } = useLocation() as LocationWithState;
  const [showWelcomeMessageModal, setShowWelcomeMessageModal] = useState(false);

  useEffect(() => {
    setShowWelcomeMessageModal(!!state?.showWelcomeMessageModal);
  }, [state?.showWelcomeMessageModal]);

  return (
    <DiagramBranch mb={48}>
      <DiagramCard data-test='welcome-music-card'>
        <AudioPlaybackRow
          name='welcomeMessage'
          title={t(`${TRANSLATION_PREFIX}.audios_card.welcome_message`)}
          url={numberDetail.welcomeMessage}
          disabled={!numberDetail.welcomeMessageEnabled}
          showModal={showWelcomeMessageModal}
          data-test='welcome-message'
        />
        <AudioPlaybackRow
          name='ringingTone'
          title={t(`${TRANSLATION_PREFIX}.audios_card.ringing_tone`)}
          url={numberDetail.ringingTone}
          data-test='welcome-music'
        />
        <AudioPlaybackRow
          name='musicOnHold'
          title={t(`${TRANSLATION_PREFIX}.audios_card.music_on_hold`)}
          url={numberDetail.musicOnHold}
          data-test='music-on-hold'
        />
      </DiagramCard>
    </DiagramBranch>
  );
}
