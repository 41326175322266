import { gql } from '@apollo/client';

export const TEAM_FRAGMENT = gql`
  fragment TeamFragment on Team {
    ID
    agentsCounter
    createdAt
    imageName
    name
    agentsIds
  }
`;
