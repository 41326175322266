import { Typography } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { ResellerConfig } from '@config/resellers.config';
import { osType } from '@dashboard/library/build/helpers/browser/browser.helpers.decl';
import { Trans, useTranslation } from 'react-i18next';

export function DownloadOtherAppLinks({ os }: { os: osType }) {
  const { t } = useTranslation();

  const openWebApp = () => {
    window.open(
      t(`resellers.${ResellerConfig.identifier}.onboarding.application.download_link_web_app`),
      'AircallPhoneWebApp',
      'width=376,height=659,popup'
    );
  };

  return (
    <Typography variant='supportingRegularS' color='neutral-800' m='xxs'>
      <Trans i18nKey={`onboarding.application.looking_for_${os}`}>
        <ExternalLink
          href={t(
            `resellers.${ResellerConfig.identifier}.onboarding.application.download_link_${os}`
          )}
          target='_blank'
          variant='supportingRegularS'
          data-test='download-other-app-link'
        />
        <ExternalLink
          onClick={openWebApp}
          target='_blank'
          variant='supportingRegularS'
          data-test='download-web-app-link'
        />
      </Trans>
    </Typography>
  );
}
