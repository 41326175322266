import { DefaultTheme } from '@aircall/tractor-v2';
import { SystemProp, Color } from '@xstyled/styled-components';

export type AudioPlayerCanPlayEventHandler = (duration: number) => void;
export type AudioPlayerTimeUpdateEventHandler = (currentTime: number) => void;
export type AudioPlayerPauseEventHandler = () => void;

export interface AudioPlayerEventHandlers {
  canPlay: AudioPlayerCanPlayEventHandler;
  timeUpdate: AudioPlayerTimeUpdateEventHandler;
  pause: AudioPlayerPauseEventHandler;
}

export interface IAudioPlayerContext {
  register(url: string, events?: Partial<AudioPlayerEventHandlers>): number;
  unregister(id: number): void;
  play(id: number): void;
  pause(id: number): void;
  stop(id: number): void;
  updateTime(id: number, time: number): void;
}

export type AudioPlayerState = {
  id: number;
  url: string;
}[];

export enum AUDIO_PLAYER_ACTION_TYPE {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export interface AudioPlayerAddAction {
  type: AUDIO_PLAYER_ACTION_TYPE.ADD;
  id: number;
  url: string;
}

export interface AudioPlayerRemoveAction {
  type: AUDIO_PLAYER_ACTION_TYPE.REMOVE;
  id: number;
}

export type AudioPlayerAction = AudioPlayerAddAction | AudioPlayerRemoveAction;

export interface AudioPlayerProps {
  /** URL of the sound to be played */
  url: string;

  /** Whether or not the player is disabled */
  disabled?: boolean;

  /** Whether or not the timer should be displayed */
  displayTimer?: boolean;

  /** Whether or not the timeline should be displayed */
  displayTimeline?: boolean;

  /**
   * Whether of not the download link should be displayed.
   * Optional value of the attribute is to specify the name of the downloaded file.
   * */
  download?: string | boolean;

  /** Color of the icon */
  iconColor?: SystemProp<Color<DefaultTheme>, DefaultTheme>;

  /** On play callback */
  onPlay?: () => void;
}
