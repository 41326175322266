import { Operation } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';

const ERROR_TO_RETRY = ['connection closed', 'timeout', 'handshake', 'unauthorizedexception'];

/**
 * The retry link is use to mainly handle to subscription. For other operations a maximum of 5 tries
 * has been set.
 */
export const onRequestRetry = (_count: number, operation: Operation, error: unknown): boolean => {
  if (!error) {
    return false;
  }

  const definition = getMainDefinition(operation.query);
  const isSubscriptionOperation =
    definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  const errorToRetry = ERROR_TO_RETRY.some((listedError) =>
    JSON.stringify(error).toLowerCase().includes(listedError)
  );
  const hasAConnectionClosedSubscriptionError = isSubscriptionOperation && errorToRetry;

  return hasAConnectionClosedSubscriptionError;
};

export const retryLink = new RetryLink({
  attempts: onRequestRetry,
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
});
