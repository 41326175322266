import { MultiSelectOption } from '@dashboard/library';

export interface TeamOption extends MultiSelectOption<string> {
  imageName: string | null;
}

export const NOT_IN_A_TEAM_VALUE = 'not_in_a_team';

export interface TeamsFilterProps {
  onSelect: (teamIds: string[]) => void;
  initialTeamsIds?: string[] | null;
}
