import React, { createContext, useMemo } from 'react';

import { AgentContextType } from './agentProvider.decl';

import { Loading } from '@dashboard/library';
import { AgentDataQuery } from '@generated/AgentDataQuery';
import { AGENT_DATA_QUERY } from '@graphql/queries/AgentDataQuery';
import { useGTM } from '@hooks/useGTM/useGTM';
import { useLogger } from '@hooks/useLogger';
import { useGraphQuery } from '@hooks/useQuery/useGraphQuery';

export const AgentDataContext = createContext<AgentContextType | undefined>(undefined);

export function AgentDataProvider({ children }: { children: React.ReactNode }) {
  const { data, loading } = useGraphQuery<AgentDataQuery>(AGENT_DATA_QUERY, { throwError: true });

  const AgentData = useMemo(() => {
    if (!data?.currentCompany || !data?.getAgentV2) {
      return null;
    }

    const { currentCompany, getAgentV2 } = data;

    const isTrial = !!data.currentCompany.trial;

    return {
      currentUser: getAgentV2,
      currentCompany,
      isTrial,
    };
  }, [data]);

  useLogger(AgentData?.currentUser, AgentData?.currentCompany);
  useGTM({
    companyId: AgentData?.currentCompany.id,
    userId: AgentData?.currentUser.ID,
  });

  if (loading || !AgentData) {
    return <Loading data-test='agent-provider-loading' />;
  }

  return <AgentDataContext.Provider value={AgentData}>{children}</AgentDataContext.Provider>;
}
