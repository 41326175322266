import { gql } from '@apollo/client';

export const CREATE_TAG_MUTATION = gql`
  mutation CreateTagMutation($input: CreateTagInput!) {
    createTag(input: $input) {
      id
      name
      color
      description
    }
  }
`;
