import {
  ERROR_TYPES,
  handleApolloError,
  NotFoundError,
  UnknownError,
} from '../../helpers/errors.helpers';

import { QueryOptions, QueryReturnType } from './useQuery.decl';

import { DocumentNode, OperationVariables, TypedDocumentNode, useQuery } from '@apollo/client';

/**
 * Custom hook that uses Apollo's useQuery with custom error handling.
 * @param query - The query to execute.
 * @param options - Option that apply to the query.
 * @returns An object holding query data, states and utilities.
 */
export function useGraphQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryOptions<TData, TVariables>
): QueryReturnType<TData, TVariables> {
  const { error, data, previousData, ...rest } = useQuery(query, options);
  let err;

  if (error) {
    err = handleApolloError(error);

    if (options?.throwError) {
      if (err?.type === ERROR_TYPES.NOT_FOUND) {
        throw new NotFoundError('Could not find resource', { options, apolloError: error });
      }

      throw new UnknownError('An unknown error has ocurred', { options, apolloError: error });
    }
  }

  return {
    error: err,
    data,
    previousData,
    staleData: data ?? previousData,
    ...rest,
  };
}
