import { PageProps, PageScrollProps } from './Page.decl';

import { Flex, Box } from '@aircall/tractor-v2';

export function Page({ children }: PageProps) {
  return (
    <Flex h='100%' flexDirection='column'>
      {children}
    </Flex>
  );
}

export function PageContent({ children, p = 'xl' }: PageScrollProps) {
  return (
    <Box p={p} overflow='auto' flex={1}>
      {children}
    </Box>
  );
}
