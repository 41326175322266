import { Flex, Spacer, Typography } from '@aircall/tractor-v2';
import { PhoneNumberIcon } from '@components/PhoneNumber/components/PhoneNumberIcon/PhoneNumberIcon';
import { PHONE_NUMBER_DATA_TEST } from '@components/PhoneNumber/PhoneNumber.constants';
import { normalizeNumber, removeAllCharactersButNumbers } from '@helpers/phoneNumber.helpers';
import parsePhoneNumber from 'libphonenumber-js/min';

import { PhoneNumberProps } from '.';

export function PhoneNumber({
  number,
  displayName,
  highlightInvalidFormattablePhoneNumber = false,
  ...styleProps
}: PhoneNumberProps) {
  const normalizedNumber = normalizeNumber(number);

  const phoneNumber = parsePhoneNumber(normalizedNumber);

  if (!phoneNumber) {
    return (
      <Flex alignItems='center'>
        <Typography mt={1} variant='bodyMediumS'>
          {number}
        </Typography>
      </Flex>
    );
  }

  const { country } = phoneNumber;
  const formattedPhoneNumber = phoneNumber.formatInternational();

  /**  sometimes we are storing invalid numbers in the database but libphonenumber-js is able to format them
   for example +1 1 248 280 1070 is not a valid number but libphonenumber-js is able to format it to +1 248 280 1070,
   and we are storing the original 112482801070, so in this case, we want to display the stored one in ddbb and show a warning icon.
   we make sure formatted clean number from libphonenumber-js is the same as the original number. Otherwise is a case of wrong number in ddbb  */
  const isValidNumber = removeAllCharactersButNumbers(formattedPhoneNumber) === number;
  const displayedNumber =
    !highlightInvalidFormattablePhoneNumber || isValidNumber
      ? formattedPhoneNumber
      : normalizedNumber;

  return (
    <Spacer
      direction='horizontal'
      alignItems='center'
      space='xxs'
      data-test={PHONE_NUMBER_DATA_TEST.MAIN}
      {...styleProps}
    >
      <PhoneNumberIcon
        country={country}
        isValidNumber={isValidNumber}
        highlightInvalidFormattablePhoneNumber={highlightInvalidFormattablePhoneNumber}
      />
      <Typography variant='bodyMediumS'>{displayName ?? displayedNumber}</Typography>
    </Spacer>
  );
}
