import React from 'react';

import { MenuItem } from '@aircall/tractor-v2';
import { TRANSLATION_PREFIX } from '@pages/number-detail-call-distribution/config';
import { useTranslation } from 'react-i18next';

import { MoveMenuItemsProps } from '.';

export function MoveMenuItems({ group, index, onMoveup, onMovedown }: MoveMenuItemsProps) {
  const { t } = useTranslation();
  return (
    <>
      {onMoveup && (
        <MenuItem
          onClick={() => {
            onMoveup(group, index);
          }}
        >
          {t(`${TRANSLATION_PREFIX}.dispatch_cards.actions.moveup`)}
        </MenuItem>
      )}
      {onMovedown && (
        <MenuItem
          onClick={() => {
            onMovedown(group, index);
          }}
        >
          {t(`${TRANSLATION_PREFIX}.dispatch_cards.actions.movedown`)}
        </MenuItem>
      )}
    </>
  );
}
