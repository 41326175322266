import { HeaderOnboardingProgressIcon } from './HeaderOnboardingProgressIcon/HeaderOnboardingProgressIcon';
import HeaderUserProfileDropdown from './HeaderUserProfileDropdown/HeaderUserProfileDropdown';

import { Flex, Spacer } from '@aircall/tractor-v2';
import { SupportLinksDropdown } from '@components/SupportLinksDropdown/SupportLinksDropdown';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useReseller } from '@hooks/useReseller/useReseller';

export function HeaderContent() {
  const {
    isSupportLinksAllowed,
    showReferAFriendButton,
    // Feature flag for no-touch conversion experiment. Progress indicator should not be shown if the experiment is enabled.
    showOnboardingTrial,
  } = useFeatures();

  const { isReseller } = useReseller();

  const displayReferAFriendButton = showReferAFriendButton && !isReseller;

  return (
    <Flex
      alignItems='center'
      justifyContent='flex-end'
      pr='s'
      backgroundColor='surface-default'
      h='32px'
      data-test='app-header'
    >
      <Spacer space='l' alignItems='center'>
        {!showOnboardingTrial && <HeaderOnboardingProgressIcon />}
        <Flex alignItems='center' gap='l'>
          {isSupportLinksAllowed && <SupportLinksDropdown />}
          <HeaderUserProfileDropdown showReferAFriendButton={displayReferAFriendButton} />
        </Flex>
      </Spacer>
    </Flex>
  );
}
