/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { BasePaperBoundaryState } from './PaperBoundary.decl';

import { Banner, BannerIcon, BannerHeading } from '@aircall/tractor-v2';
import { logger } from '@config/logger.config';
import { PaperProps, Paper } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

function SectionErrorBanner() {
  const { t } = useTranslation();

  return (
    <Banner data-test='section-error' inline variant='critical'>
      <BannerIcon />
      <BannerHeading>{t('error_screen.generic_error.title')}</BannerHeading>
    </Banner>
  );
}

class BasePaperBoundary extends React.Component<PaperProps, BasePaperBoundaryState> {
  constructor(props: PaperProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    logger.error(error.message, { error, errorInfo });
  }

  render() {
    const { error } = this.state;
    const { children, ...otherProps } = this.props;

    if (error) {
      return (
        <Paper {...otherProps}>
          <SectionErrorBanner />
        </Paper>
      );
    }

    return <Paper {...otherProps}>{children}</Paper>;
  }
}

export function PaperBoundary(props: PaperProps) {
  return <BasePaperBoundary {...props} />;
}
