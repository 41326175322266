import React from 'react';

import { Spacer, Tag, Typography } from '@aircall/tractor-v2';

interface TagBranchProps {
  disabled?: boolean;
  children: React.ReactNode;
  'data-test'?: string;
}

export function UserCountTagWrapper({
  disabled = false,
  children,
  'data-test': dataTest,
}: TagBranchProps) {
  return disabled ? (
    <Tag variant='red' data-test={dataTest || 'user-count-tag'}>
      {children}
    </Tag>
  ) : (
    <Typography variant='bodyMediumS' color='neutral-700' data-test={dataTest || 'user-count-text'}>
      <Spacer space='xxs'>{children}</Spacer>
    </Typography>
  );
}
