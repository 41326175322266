import { Flex, Box } from '@aircall/tractor-v2';
import { LegacyDashboard } from '@components/LegacyDashboard';
import { useTranslation } from 'react-i18next';

export function ActivityFeed() {
  const { t } = useTranslation();

  return (
    <Flex flexDirection='column' h='100%'>
      <Box flexGrow={1}>
        <LegacyDashboard componentName={t('embedded_dashboard.components.activity_feed')} />
      </Box>
    </Flex>
  );
}
