import { gql } from '@apollo/client';

export const AUTHORIZE_APPLICATION_MUTATION = gql`
  mutation AuthorizeApplicationMutation($input: AuthorizeApplicationMutationInput!) {
    authorizeApplicationMutation(input: $input)
      @rest(
        type: "AuthorizeApplicationResponse"
        path: "applications/authorize.json"
        method: "POST"
        endpoint: "merlin"
      ) {
      status
      redirectUri
    }
  }
`;
