import { gql } from '@apollo/client';

export const UPDATE_ONBOARDING_STEP_MUTATION = gql`
  mutation UpdateOnboardingStepMutation($stepType: StepType!, $stepStatus: StepStatus!) {
    updateOnboardingStep(onboardingStep: { stepStatus: $stepStatus, stepType: $stepType }) {
      ... on Onboarding {
        __typename
        steps {
          content
          redirectUrl
          stepStatus
          stepType
          stepTitle
          updatedAt
        }
      }
      ... on GenericException {
        __typename
        code
        message
      }
    }
  }
`;
