import { ArrowSendOutlined } from '@aircall/icons';
import { Box, Button, Flex, Icon, Typography, Spacer } from '@aircall/tractor-v2';
import pointingArrow from '@assets/images/pointing_arrow.svg';
import styled from '@xstyled/styled-components';

type OnboardingCardButton = {
  label: string;
  onClick: () => void;
};

export type OnboardingCardProps = {
  button?: OnboardingCardButton;
  className?: string;
  heading: string;
  image: string;
  showBottomImage?: boolean;
  subHeading: string;
};

export const Card = styled(Box)`
  box-shadow: 0 24px 48px 0 rgb(21 26 40 / 0.05);
`;

export function OnboardingCard({
  button,
  className,
  heading,
  image,
  showBottomImage,
  subHeading,
}: Readonly<OnboardingCardProps>) {
  return (
    <Card
      w='100%'
      maxW='377px'
      bg='white'
      borderRadius='default'
      p='s'
      as='article'
      className={className}
    >
      <Flex
        maxHeight='200px'
        minHeight='200px'
        borderRadius='sm'
        overflow='hidden'
        data-test='card-image'
      >
        <img src={image} width='100%' aria-label='Aircall' alt='' />
      </Flex>
      <Spacer space='xxxs' direction='vertical'>
        <Typography data-test='card-heading' variant='headingBoldS' mt='s'>
          {heading}
        </Typography>
        <Typography data-test='card-subHeading' variant='bodyRegularM' mb='s'>
          {subHeading}
        </Typography>
      </Spacer>
      {button && (
        <Button size='regular' onClick={button.onClick}>
          {button.label}
          <Icon component={ArrowSendOutlined} size={20} />
        </Button>
      )}
      {showBottomImage && (
        <Flex>
          <img
            src={pointingArrow}
            aria-label='pointingArrow'
            width='193.193px'
            height='66.543px'
            alt=''
          />
        </Flex>
      )}
    </Card>
  );
}
