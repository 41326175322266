import { ReactNode } from 'react';

import styled from 'styled-components';

import { getColor } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { useTranslation } from 'react-i18next';

const ErrorExternalLink = styled(ExternalLink)`
  color: ${getColor('text-critical')};
  text-decoration: underline;

  &:hover {
    color: ${getColor('text-interactive-critical-hover')};
  }

  &:active,
  &:focus {
    color: ${getColor('text-interactive-critical-active')};
  }
`;
export function ManagePaymentMethodLink({ children }: Readonly<{ children?: ReactNode }>) {
  const { t } = useTranslation();
  return (
    <ErrorExternalLink
      href={t('delinquent_banner.banner_manage_payment_method_link')}
      data-test='delinquent_banner.banner_manage_payment_method_link'
      target='_blank'
      color='text-interactive-critical'
    >
      {children}
    </ErrorExternalLink>
  );
}
