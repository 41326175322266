import { useCallback, useContext } from 'react';

import { UsePermissionsReturn } from './usePermissions.decl';

import { PermissionsContext } from '@components/Permissions/PermissionsProvider';
import { sidebarItems } from '@components/Sidebar/sidebarItems';
import { PERMISSIONS_ACTIONS, RESOURCE } from '@constants/permissions.constants';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useResource } from '@hooks/useResource/useResource';

export function usePermissions(): UsePermissionsReturn {
  const permissionsContext = useContext(PermissionsContext);
  const resource = useResource();
  const { enablePermissionsNotifications } = useFeaturesFlags();

  const checkAccess = useCallback(
    (action: PERMISSIONS_ACTIONS, _resource?: RESOURCE) => {
      const actualResource = _resource || resource;

      if (!actualResource) {
        throw new Error('No resource found.');
      }

      const permissions = permissionsContext.permissions[actualResource];

      // Resource permissions not existent in token
      if (!permissions) {
        return false;
      }

      return permissions.includes(action);
    },
    [permissionsContext.permissions, resource]
  );

  const getAccessibleRoute = useCallback(() => {
    // Find a route we can access from the sidebar
    // eslint-disable-next-line no-restricted-syntax
    for (const item of Object.values(sidebarItems)) {
      if (!item.resource) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const hasRouteAccess = checkAccess(PERMISSIONS_ACTIONS.READ, item.resource);
      if (hasRouteAccess) {
        return item.to;
      }
    }
    return null;
  }, [checkAccess]);

  return {
    ...permissionsContext,
    resource,
    checkAccess,
    getAccessibleRoute,
    enablePermissionsNotifications,
  };
}
