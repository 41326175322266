import { ModalCardProps } from './ModalCard.decl';

import { Flex, Box, Typography, useColor } from '@aircall/tractor-v2';

export function ModalCard({ avatar, tag, title }: ModalCardProps) {
  const borderColor = useColor('neutral-200');

  return (
    <Flex
      minWidth={160}
      px='xxs'
      flexDirection='column'
      alignItems='center'
      backgroundColor='white'
      border={`1px solid ${borderColor}`}
      borderRadius='sm'
    >
      <Box py='s'>{avatar}</Box>
      <Typography variant='bodySemiboldM' px='m' pb='s' ellipsis>
        {title}
      </Typography>
      {tag && <Box pb='s'>{tag}</Box>}
    </Flex>
  );
}
