import { Banner, BannerHeading } from '@aircall/tractor-v2';
import { LegacyDashboard } from '@components/LegacyDashboard';
import { ExternalLink } from '@components/Link';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { Trans, useTranslation } from 'react-i18next';

export function StatsLegacyOverviewContainer() {
  const { t } = useTranslation();
  const { oldOverviewReleaseBannerEnabled } = useFeatures();

  return (
    <>
      {oldOverviewReleaseBannerEnabled && (
        <Banner data-test='old-overview-banner' variant='info'>
          <BannerHeading>
            <Trans i18nKey='stats.old_overview_release_banner_text'>
              <ExternalLink fontSize='inherit' href={t('stats.knowledge_base')} target='_blank' />
            </Trans>
          </BannerHeading>
        </Banner>
      )}
      <LegacyDashboard componentName={t('embedded_dashboard.components.stats')} />;
    </>
  );
}
