import { gql } from '@apollo/client';

export const INTEGRATIONS_LIST_QUERY = gql`
  query IntegrationsListQuery {
    companyIntegrations
      @rest(type: "CompanyIntegrations", path: "companies/integrations", endpoint: "v4") {
      data {
        id
        type
        attributes {
          id
          active
          customName
          logo
          name
          nameUnderscored
          numbersConnected
          service
        }
      }
    }
  }
`;
