import { gql } from '@apollo/client';

export const REMOVE_NUMBER_CONNECTED_INTEGRATION = gql`
  mutation RemoveNumberConnectedIntegrationMutation(
    $companyId: Int!
    $numberId: Int!
    $input: RemoveConnectedIntegrationInput!
    $skipHub: Boolean!
    $skipBridge: Boolean!
  ) {
    removeNumberConnectedIntegrationHub(companyId: $companyId, numberId: $numberId, input: $input)
      @skip(if: $skipHub)
      @rest(
        endpoint: "v3"
        method: "delete"
        path: "hub/companies/{args.companyId}/numbers/{args.numberId}/integrations"
        type: "Void"
      ) {
      _
    }
    removeNumberConnectedIntegrationBridge(
      companyId: $companyId
      numberId: $numberId
      input: $input
    )
      @skip(if: $skipBridge)
      @rest(
        endpoint: "v3"
        method: "delete"
        path: "bridge/v2/companies/{args.companyId}/numbers/{args.numberId}/integrations?no_version=true"
        type: "Void"
      ) {
      _
    }
  }
`;
