/* eslint-disable @typescript-eslint/no-explicit-any */
import { handleApolloError } from '../../helpers/errors.helpers';

import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  useLazyQuery,
  LazyQueryHookOptions,
} from '@apollo/client';

import { UseGraphLazyQueryResponse } from '.';

/**
 * Hook to execute queries in response to events besides component rendering.
 * @param query - The query to execute.
 * @param options - Option that apply to the query.
 * @returns A query function that can be called at any time to execute the query and an object with fields that represent the current status of the mutation's execution.
 */
export const useGraphLazyQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>
): UseGraphLazyQueryResponse<TData, TVariables> => {
  const [queryFunction, { error, ...queryResult }] = useLazyQuery(query, options);

  return [queryFunction, { ...queryResult, error: error ? handleApolloError(error) : undefined }];
};
