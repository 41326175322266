import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { UseShowScrollableContainerShadowReturn } from './useShowScrollableContainerShadow.decl';

/**
 * This hook allows us to determine when we can show a bottom shadow
 * on a scrollable container, to indicate to users that there is more
 * content to scroll to.
 *
 * @example
 * ```tsx
 * const { onScrollHandler, scrollableContainerRef, showBottomShadow } =
    useShowScrollableContainerShadow<HTMLDivElement>();

   <Box ref={scrollableContainerRef} onScroll={onScrollHandler} style={{ overflowY: 'auto', boxShadow: showBottomShadow ? 'box-shadow: 10 10 10 black' : '' }}>
    {children}
   </Box>
 * ```
 */
export function useShowScrollableContainerShadow<
  T extends HTMLUnknownElement
>(): UseShowScrollableContainerShadowReturn<T> {
  const scrollableContainerRef = useRef<T>(null);
  const [showBottomShadow, setShowBottomShadow] = useState(false);

  const updateShadowVisibility = useCallback((element: T) => {
    const { clientHeight, scrollHeight, scrollTop } = element;

    const isAtTheTop = scrollTop === 0;
    const isInBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;
    const isAtTheBottom = clientHeight === scrollHeight - scrollTop;

    setShowBottomShadow((isAtTheTop || isInBetween) && !isAtTheBottom);
  }, []);

  const onScrollHandler = useCallback(
    (event: React.UIEvent<T>) => {
      updateShadowVisibility(event.currentTarget);
    },
    [updateShadowVisibility]
  );

  useEffect(() => {
    /**
     * jest deliberately does not support layout properties nor APIs that
     * rely on them, such as ResizeObserver.
     *
     * Therefore, there's no need to cover this line.
     */
    /* istanbul ignore next */
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        updateShadowVisibility(entry.target as T);
      });
    });

    if (scrollableContainerRef.current) {
      resizeObserver.observe(scrollableContainerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [updateShadowVisibility]);

  const useShowScrollbarShadowReturn = useMemo(
    () => ({
      showBottomShadow,
      scrollableContainerRef,
      onScrollHandler,
    }),
    [showBottomShadow, onScrollHandler]
  );

  return useShowScrollbarShadowReturn;
}
