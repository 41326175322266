import { TrialBannerWithCallLimitsProps } from '../TrialBanner.decl';

import { TrialBannerButton } from './TrialBannerButton';

import { Banner, BannerHeading, BannerSuffix, Box, useTheme } from '@aircall/tractor-v2';
import { CircularProgress } from '@components/CircularProgress/CircularProgress';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { Trans } from 'react-i18next';

export function TrialBannerWithCallLimits({
  callTotalSeconds,
  daysLeftInTrial,
}: TrialBannerWithCallLimitsProps) {
  const { dailyCallLimitInMinutes } = useFeatures();
  const { space } = useTheme();

  const minutesCurrentlyUsed = Math.min(dailyCallLimitInMinutes, Math.floor(callTotalSeconds / 60));
  const minutesLeft = Math.floor(dailyCallLimitInMinutes - minutesCurrentlyUsed);
  const colorVariant = minutesLeft > 10 ? 'warning' : 'critical';

  return (
    <Banner data-test='trial-banner-with-call-limits' variant={colorVariant}>
      <Box pr={space[1]}>
        <CircularProgress
          color={colorVariant}
          strokeWidth={15}
          total={dailyCallLimitInMinutes}
          value={minutesLeft}
        />
      </Box>
      <BannerHeading>
        <Trans
          i18nKey='trial_banner.calls_left'
          values={{ rest: minutesLeft, dailyCallLimitInMinutes, daysLeftInTrial }}
        >
          <strong />
          <strong />
        </Trans>
      </BannerHeading>
      <BannerSuffix>
        <TrialBannerButton />
      </BannerSuffix>
    </Banner>
  );
}
