import { Box, Flex, getColor } from '@aircall/tractor-v2';
import styled from '@xstyled/styled-components';

export const ProgressCircleWrapper = styled(Box)<
  Readonly<{
    $radius: number;
    $stroke: string;
    $strokeWidth?: number;
    $strokeDashArray?: number;
    $strokeDashOffSet?: number;
  }>
>`
  & svg:has(circle) {
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;

    & circle {
      cx: 17.5;
      cy: 17.5;
      fill: none;
      r: ${(props) => props.$radius};
      stroke: ${(props) => getColor(props.$stroke)};
      stroke-dasharray: ${(props) => props.$strokeDashArray};
      stroke-dashoffset: ${(props) => props.$strokeDashOffSet};
      stroke-linecap: round;
      stroke-width: ${(props) => props.$strokeWidth};
      transform: rotate(-90deg); /* Rotate to start from the top */
      transform-origin: center;
    }
  }
`;

export const InnerCircleWrapper = styled(Flex)`
  border: 5px solid #fff;
`;
