import { authorizeChannelsFactory } from './authorizeChannelsFactory';

import { ApolloClient } from '@apollo/client';
import { isPresenceAuthorizer, isPrivateAuthorizer } from '@helpers/authorizeChannels.helpers';
import { ChannelAuthorizationHandler } from 'pusher-js';

export const customHandlerFactory =
  (client: ApolloClient<object>, cluster: string): ChannelAuthorizationHandler =>
  async ({ channelName, socketId }, callback) => {
    const authorizeChannels = authorizeChannelsFactory(client);

    try {
      const response = await authorizeChannels(cluster, [{ channelName, socketId }]);

      const responseData =
        response.data?.authorizeChannels.authorizations.map(
          (payload) =>
            ((isPrivateAuthorizer(payload) || isPresenceAuthorizer(payload)) &&
              payload.authPayload) ||
            undefined
        ) ?? [];

      if (responseData[0]) {
        callback(null, responseData[0]);
      } else {
        callback(new Error('Unauthorized'), null);
      }
    } catch (error) {
      callback(error as Error, null);
    }
  };
