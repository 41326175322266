import React, { useCallback } from 'react';

import { EventTogglesBlockProps } from './Webhook.decl';

import { Typography } from '@aircall/tractor-v2';
import { ToggleRow } from '@components/ToggleRow';
import { GridItem, List } from '@dashboard/library';

export const EventTogglesBlock = React.memo(
  ({ sectionEvents, selectedEvents, title, onToggle }: EventTogglesBlockProps) => {
    const isToggleChecked = useCallback(
      (event: string) => {
        const webhookEvents = selectedEvents as string[];
        return !!webhookEvents?.length && webhookEvents.includes(event);
      },
      [selectedEvents]
    );

    if (!sectionEvents) {
      return null;
    }

    return (
      <GridItem xs={12} md={6} data-test={`toggles-block-${title}`}>
        <Typography variant='bodyMediumS' mb='s'>
          {title}
        </Typography>
        <List>
          {sectionEvents.map((event) => {
            const toggleName = `${event}-toggle`;
            const checked = isToggleChecked(event as string);

            return (
              <ToggleRow
                data-test={toggleName}
                value={checked}
                key={toggleName}
                title={event as string}
                onChange={() => onToggle(event as string, !checked)}
              />
            );
          })}
        </List>
      </GridItem>
    );
  }
);
