import { createContext, useContext, RefObject, useMemo } from 'react';

import { MotionValue, useElementScroll } from 'framer-motion';

export const ERROR_MESSAGE =
  'useScrollableContainer should be used within a ScrollableContainerProvider';

export interface UseScrollableContainer {
  containerRef: RefObject<HTMLDivElement>;
  containerScrollTop: MotionValue<number>;
}

export interface ScrollableContainerProviderProps {
  children?: React.ReactNode;
  containerRef: RefObject<HTMLDivElement>;
}

const ScrollableContainerContext = createContext<UseScrollableContainer | undefined>(undefined);

/**
 * Custom context to get the ref of the scrollable container.
 * @returns scrollable container context
 */
export function useScrollYContainer(): UseScrollableContainer {
  const context = useContext(ScrollableContainerContext);

  if (!context) {
    throw new Error(ERROR_MESSAGE);
  }

  return context;
}

/**
 * Provides scrollable container utilities.
 * @param scrollableContainerProviderProps - destructured parameter
 * @returns a scrollable container context holding memoized context values
 */
export function ScrollableContainerProvider({
  containerRef,
  ...props
}: ScrollableContainerProviderProps) {
  const { scrollY } = useElementScroll(containerRef);
  const memoizedContextValue = useMemo(
    () => ({ containerRef, containerScrollTop: scrollY }),
    [containerRef, scrollY]
  );

  return <ScrollableContainerContext.Provider value={memoizedContextValue} {...props} />;
}
