import { useCallback } from 'react';

import { useAddOutboundCallUser } from './hooks/useAddOutboundCallUser/useAddOutboundCallUser';
import { useDeleteOutboundCallUser } from './hooks/useDeleteOutboundCallUser/useDeleteOutboundCallUser';

import { AddButton } from '@components/Button/AddButton';
import { SearchableSelectUser } from '@components/SearchableSelectUser';
import { UserAvatar } from '@components/UserAvatarV2';
import { USER_DETAIL_ROUTE } from '@constants/routes.constants';
import { List, ListItem, InlineForm, getInitials, Loading } from '@dashboard/library';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useNumberSupervision } from '@hooks/useNumberSupervision';
import { useSearchableSelectUser } from '@hooks/useSearchableSelectUser/useSearchableSelectUser';
import { useToggle } from '@hooks/useToggle/useToggle';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

export function OutboundCallUsers() {
  const { t } = useTranslation();
  const { numberId } = useParams();
  const { supervisors, loading: supervisorsLoading } = useNumberSupervision(numberId);

  const navigate = useNavigateWithParamsReplace();
  const filteredIds = new Set(
    supervisors.map((supervisor) => supervisor?.user?.id.toString() ?? '')
  );
  const [shouldShowAddUserRow, _, showAddUserRow, onCloseClick] = useToggle();
  const { agents, options, loading, setQuery, resultsCount } = useSearchableSelectUser({
    filteredIds,
    isSelectMounted: shouldShowAddUserRow,
  });
  const { deleteOutboundCallUser } = useDeleteOutboundCallUser({ numberId });
  const { addOutboundCallUser } = useAddOutboundCallUser({ numberId });

  const handleDeleteOutboundCallUser = useCallback(
    (userId: string, name: string) => () => {
      deleteOutboundCallUser({ userId, name });
    },
    [deleteOutboundCallUser]
  );

  return (
    <>
      <List
        hasScroll
        stickyFooter={
          shouldShowAddUserRow && (
            <InlineForm
              py='xxs'
              formProps={{
                onSubmit: ({ userId }: { userId: string }) =>
                  addOutboundCallUser({ userId, agents }),
              }}
              onClose={onCloseClick}
              submitButtonText={t('app.form.buttons.add')}
              alignItems='center'
            >
              {() => (
                <Field name='userId'>
                  {({ input: { onChange, value } }) => (
                    <SearchableSelectUser
                      size='small'
                      value={value}
                      options={options}
                      onChange={onChange}
                      onSearch={setQuery}
                      resultsCount={resultsCount}
                      loading={loading}
                      w='100%'
                    />
                  )}
                </Field>
              )}
            </InlineForm>
          )
        }
      >
        {supervisors.map((supervisor, index) => {
          if (!supervisor?.user || !supervisor?.id) {
            return null;
          }

          const { user, id } = supervisor;
          const initials = getInitials(user.firstName, user.lastName);
          const userName = `${user.firstName} ${user.lastName}`;

          return (
            <ListItem
              key={user.id}
              avatar={
                <UserAvatar
                  id={user.id.toString()}
                  src={user.pictureUrl}
                  initials={initials}
                  size='small'
                />
              }
              primaryText={userName}
              secondaryText={user.email}
              dropdownOptions={[
                {
                  title: t('number_details.integrations.connected_integrations.remove_integration'),
                  callback: handleDeleteOutboundCallUser(id, userName),
                },
                {
                  title: t('number_details.teams_users.outbound_call_users.go_to_user'),
                  callback: () => navigate(USER_DETAIL_ROUTE, { userId: user.id }),
                },
              ]}
              data-test={`outbound-list-item-${index}`}
            />
          );
        })}
      </List>
      {supervisorsLoading && <Loading data-test='outbound-call-users-loading' />}
      {!shouldShowAddUserRow && !supervisorsLoading && (
        <AddButton
          onClick={showAddUserRow}
          translationKey='number_details.teams_users.outbound_call_users.add_user.button'
          hasData={!!supervisors.length}
          data-test='outbound-add'
        />
      )}
    </>
  );
}
