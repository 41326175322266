import { gql } from '@apollo/client';

export const AUTHORIZE_ZENDESK_QUERY = gql`
  query AuthorizeZendeskQuery($returnTo: String!) {
    authorizeZendesk(returnTo: $returnTo)
      @rest(
        type: "AuthorizeZendeskQueryResponse"
        path: "auth/v1/users/sessions/zendesk?{args.returnTo}"
        endpoint: "authentication"
        method: "GET"
      ) {
      redirectTo
    }
  }
`;
