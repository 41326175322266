import { DownloadDataModalProps } from './DownloadDataModal.decl';

import {
  Button,
  DrawerHeader,
  DrawerTitle,
  ModalBody,
  ModalDialog,
  ModalFooter,
  Typography,
  getColor,
} from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import styled from '@xstyled/styled-components';
import { Trans, useTranslation } from 'react-i18next';

const StyledModalBody = styled(ModalBody)`
  background-color: ${getColor('surface-default')};
`;

const StyledImage = styled.img`
  display: block;
  width: 48rem;
  max-width: 100%;
  margin: 0 auto;
`;

export function DownloadDataModal({
  imageSrc,
  onHide,
  show,
  translationPrefix,
}: DownloadDataModalProps) {
  const { t } = useTranslation();

  return (
    <ModalDialog data-test='download-data-modal' onHide={onHide} show={show}>
      <DrawerHeader>
        <DrawerTitle>{t(`${translationPrefix}.download_modal.title`)}</DrawerTitle>
      </DrawerHeader>
      <StyledModalBody>
        <Typography variant='bodyRegularM'>
          <p>{t(`${translationPrefix}.download_modal.line1`)}</p>
          <p>{t(`${translationPrefix}.download_modal.line2`)}</p>
          <ul>
            <li>{t(`${translationPrefix}.download_modal.line3`)}</li>
            <li>{t(`${translationPrefix}.download_modal.line4`)}</li>
          </ul>
          <StyledImage alt={t(`${translationPrefix}.download_modal.title`)} src={imageSrc} />
          <p>
            <Trans
              components={[
                <ExternalLink
                  href={t(`${translationPrefix}.download_modal.kblink.link`)}
                  key='knowledge-base'
                  rel='noreferrer'
                  target='_blank'
                  variant='bodyRegularM'
                />,
              ]}
              i18nKey={`${translationPrefix}.download_modal.line5`}
              values={{ caption: t(`${translationPrefix}.download_modal.kblink.caption`) }}
            />
          </p>
        </Typography>
      </StyledModalBody>
      <ModalFooter>
        <Button data-test='download-data-modal-close' onClick={onHide} variant='primary'>
          {t(`${translationPrefix}.download_modal.acknowledge`)}
        </Button>
      </ModalFooter>
    </ModalDialog>
  );
}
