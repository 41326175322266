import { useContext, useEffect } from 'react';

import { FormFlowContext } from './FormFlow';
import { FormFlowContextType, NextCallback } from './FormFlow.decl';

export function useFormFlow<DataType>(
  nextCallback?: NextCallback<DataType>
): FormFlowContextType<DataType> {
  const context = useContext(FormFlowContext);

  if (!context) {
    throw new Error('useFormFlow should be used within a FormFlow provider');
  }

  useEffect(() => {
    nextCallback && context.registerNextCallback(nextCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextCallback]);

  return context;
}
