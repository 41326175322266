import { Dispatch, RefObject, SetStateAction } from 'react';

import { URLSearchObject } from '@components/SearchProvider/usePersistSearchParams';
import { PageHeaderProps } from '@dashboard/library';
import { AnalyticsDashboardType } from '@generated/globalTypes';

export type FILTER_DIRECTION = 'toDashboard' | 'toLooker';
export type LookerPageHeaderProps = Omit<PageHeaderProps, 'largeTitle'>;

export type DateLimits = {
  maxDateRangeInDays?: number;
  minDate?: Date;
};

export interface FiltersContextProps extends DateLimits {
  dashboardType: AnalyticsDashboardType;
  pageHeaderTitle: string;
  pageHeaderProps: LookerPageHeaderProps;
}

export interface FiltersProviderProps extends DateLimits {
  children: React.ReactNode;
  dashboardType: AnalyticsDashboardType;
  pageHeaderTitle: string;
  pageHeaderProps?: LookerPageHeaderProps;
}

export interface DateRange {
  endDate: Date;
  startDate: Date;
}

export interface LookerContainerProps {
  url: string | undefined;
  title: string;
}

export interface LookerEventData {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface LookerPageProps extends DateLimits, LookerProps {
  pageHeaderTitle: string;
  pageHeaderProps?: LookerPageHeaderProps;
  onTruncated?: (truncated: boolean) => void;
}

export interface LookerProps {
  type: AnalyticsDashboardType;
  title: string;
}

export interface LookerRenderProps {
  iFrameRef: React.RefObject<HTMLIFrameElement>;
  url?: string;
}

export enum LOOKER_FILTER_KEY {
  DATE = 'Date',
  LINES = 'Lines',
  NUMBERS = 'Numbers',
  TAGS = 'Tags',
  TEAMS = 'Teams',
  USERS = 'Users',
  AIRCALL_NUMBER = 'Aircall number',
  BUSINESS_HOURS = 'Business Hours',
  HOURS = 'Hours',
  CALL_ID = 'Call ID',
  CALL_TYPE = 'Call type',
  CUSTOMER_NUMBER = 'Customer number',
  DATE_BREAKDOWN = 'Date Breakdown',
  INBOUND_SLA = 'Inbound SLA (in seconds)',
  MISSED_REASONS = 'Missed Reasons',
  SLA = 'SLA (in sec)',
  SLA_GOAL = 'SLA goal (%)',
  TIMEZONE = 'Timezone',
  NEXT_STATUS = 'Next status',
  STATUS = 'Status',
  OVERVIEW_METRICS = 'Show Overview metrics by',
  EXCLUDE_FROM_SLA = 'Exclude from SLA (select "is not")',
  MIN_CONNECTED = 'Min connected outbound in call time (sec)',
  OUTBOUND_CONNECTED = 'Min Outbound In Call Time (sec)',
  UNANSWERED_REASON = 'Unanswered Call Reason',
  IVR_BRANCH = 'IVR Branch',
  DURATION = 'Duration',
  CALL_ROUTED_TO_TEAM = 'Call routed to team',
  USERS_BELONGING_TO_TEAM = 'Users belonging to team',
}

export interface LookerPageProviderProps extends DateLimits {
  children: React.ReactNode;
  onTruncated?: (truncated: boolean) => void;
}

export interface LookerPageContextProps {
  height: string | number;
  iFrameLoading: boolean;
  iFrameRef: RefObject<HTMLIFrameElement>;
  handleCustomFilterChange: (filterValue: object) => void;
  handleFiltersReset: VoidFunction;
  updateLookerFilter: (filters: URLSearchObject, forceRefresh?: boolean) => void;
  url: string | undefined;
  setIFrameLoading: Dispatch<SetStateAction<boolean>>;
  setUrl: Dispatch<SetStateAction<string | undefined>>;
  error: boolean;
}

/**
 * State machine to help track if a tile error happen after using stored data or after using fresh data.
 * - STORED_DATA, is set to know that Looker's dashboard is using stored data, default value
 * - FRESH_DATA, is set to know that Looker's dashboard is using its default data
 * - DATA_ERROR, is set when a tile error happen, DATA_ERROR can only happen after STORED_DATA
 * - OTHER_ERROR, is set when a tile error happen, OTHER_ERROR can only happen after FRESH_DATA
 */
export enum LOOKER_STATUS {
  STORED_DATA = 'stored data',
  FRESH_DATA = 'fresh data',
  DATA_ERROR = 'data error',
  OTHER_ERROR = 'other error',
}

export type LOOKER_DATA = 'searched-data' | 'stored-data' | 'default-data';

export enum LOOKER_DATE_PERIODS {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = '7 day',
  LAST_14_DAYS = '14 day',
  LAST_28_DAYS = '28 day',
  LAST_30_DAYS = '30 day',
  LAST_90_DAYS = '90 day',
  LAST_180_DAYS = '180 day',
  LAST_365_DAYS = '365 day',
  THIS_WEEK = 'this week',
  THIS_MONTH = 'this month',
  THIS_QUARTER = 'this quarter',
  THIS_YEAR = 'this year',
  PREVIOUS_WEEK = 'last week',
  PREVIOUS_MONTH = 'last month',
  PREVIOUS_QUARTER = 'last quarter',
  PREVIOUS_YEAR = 'last year',
  YEAR_TO_DATE = '',
}

export enum LOOKER_ACTIONS {
  DASHBOARD_FILTERS_UPDATE = 'dashboard:filters:update',
  DASHBOARD_RUN = 'dashboard:run',
}

export enum LOOKER_EVENTS {
  DASHBOARD_FILTERS_CHANGED = 'dashboard:filters:changed',
  DASHBOARD_LOADED = 'dashboard:loaded',
  DASHBOARD_RUN_COMPLETE = 'dashboard:run:complete',
  DASHBOARD_RUN_START = 'dashboard:run:start',
  DASHBOARD_TILE_COMPLETE = 'dashboard:tile:complete',
  PAGE_PROPERTIES_CHANGED = 'page:properties:changed',
}
