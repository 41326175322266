/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { LANGUAGES_ISO_CODES } from '../constants/languages.constants';

import { STRIP_COUNTRY_FROM_LANGUAGE } from '@constants/regex.constants';

/**
 * Requires the right file given the language.
 * @param language - language of the file
 * @returns requires a file
 */
export const getTranslationFile = (language: string): any =>
  require(`../assets/locales/${language}.json`);

/**
 * Retrieves for each language the dedicated translation file.
 * @returns an object holding the translation files for each language
 */
export const getLanguageResources = (): Record<string, never> =>
  LANGUAGES_ISO_CODES.reduce(
    (acc, item) => ({
      ...acc,
      [item]: { translation: getTranslationFile(item) },
    }),
    {}
  );

/**
 * Format a language ISO of type `en-US` to `en`
 * or `fr-FR` to `fr`
 * @param language - `fr-FR`, `en_US` or `en-US`
 * @returns the formated ISO language
 */
export const formatLocaleName = (language: string): string =>
  language.replace(STRIP_COUNTRY_FROM_LANGUAGE, '');

/**
 * Translates alpha2 country code to localized country name
 * @param countryCode - alpha2 country code
 * @param lang - language to get the translated country name
 * @returns translated country namename
 */
export const formatCountry = (countryCode: string, lang: string): string => {
  try {
    const r = new Intl.DisplayNames([lang], { type: 'region' });
    const translatedName = r.of(countryCode);
    return translatedName as string;
  } catch (error) {
    // Intl.DisplayNames might throw an error if countrycode or lang are invalid
    return countryCode;
  }
};
