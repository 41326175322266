import { useMemo } from 'react';

import { Box } from '@aircall/tractor-v2';
import { AddonTrialBadge } from '@components/AddonTrialBadge';
import { useCompanyAddons } from '@hooks/useCompanyAddons/useCompanyAddons';
import {
  getActiveAddonTrials,
  orderAddonTrialsByAscendingRemainingDays,
} from '@hooks/useCompanyAddons/utils';
import { useGetEnableTrialAddonsFeatureFlags } from '@hooks/useGetEnableTrialAddonsFeatureFlags';

export function AddonTrialsSection() {
  const { isAddonEnabled } = useGetEnableTrialAddonsFeatureFlags();

  const { addons, loading } = useCompanyAddons();

  const orderedActiveAddonTrials = useMemo(() => {
    if (!addons.length) {
      return [];
    }

    const activeAddonTrials = getActiveAddonTrials(addons, isAddonEnabled);

    return orderAddonTrialsByAscendingRemainingDays(activeAddonTrials);
  }, [addons, isAddonEnabled]);

  if (loading || !orderedActiveAddonTrials.length) {
    return null;
  }

  return (
    <Box as='ol' margin='0' padding='0'>
      {orderedActiveAddonTrials.map((addon) => (
        <Box key={addon.name} as='li' display='contents'>
          <AddonTrialBadge name={addon.name} remainingDays={addon.remainingDays} />
        </Box>
      ))}
    </Box>
  );
}
