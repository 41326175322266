import { useCallback, useEffect, useState } from 'react';

import { MusicOnHoldFormValues } from '../MusicOnHoldSettings/MusicOnHoldSettings.decl';
import { getMusicOnHoldAudioSetting } from '../MusicOnHoldSettings/MusicOnHoldSettings.helpers';

import { useNumberDetail } from '@hooks/useNumberDetail';

interface UseMusicOnHoldAudioSettingsType {
  audioSettings?: MusicOnHoldFormValues['audioSetting'];
  updateAudioSetting: () => void;
}

export function useMusicOnHoldAudioSettings(): UseMusicOnHoldAudioSettingsType {
  const { number } = useNumberDetail();
  const [audioSettings, setAudioSettings] = useState<MusicOnHoldFormValues['audioSetting']>();

  const updateAudioSetting = useCallback(async () => {
    const audioSetting = await getMusicOnHoldAudioSetting(number!.musicOnHold);
    setAudioSettings(audioSetting);
  }, [number]);

  useEffect(() => {
    updateAudioSetting();
  }, []);

  return { audioSettings, updateAudioSetting };
}
