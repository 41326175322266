import { Key, useCallback, useMemo } from 'react';

import { TRANSLATION_PREFIX } from '../config';
import { DiagramBranch, DiagramCard } from '../FlowDiagram';
import {
  DispatchGroupAttributeValue,
  DISPATCH_GROUP_ATTRIBUTE,
  useUpdateDispatchGroup,
} from '../hooks/useUpdateDispatchGroup';
import { MoveItemHandler, MoveMenuItems } from '../MoveMenuItems';

import { MenuVerticalFilled } from '@aircall/icons';
import {
  Dropdown,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Grid,
  Box,
  Select,
} from '@aircall/tractor-v2';
import { TooltipOnTruncate } from '@components/TooltipOnTruncateV2';
import { UserCountTag } from '@components/UserCountTag/UserCountTag';
import { TEAM_DETAIL_ROUTE } from '@constants/routes.constants';
import { EmojiAvatar } from '@dashboard/library';
import {
  NumberDetailQuery_numberDetail_dispatchGroups,
  NumberDetailQuery_numberDetail_dispatchGroups_dispatchable_Team,
} from '@generated/NumberDetailQuery';
import { useDeleteDispatchGroup } from '@hooks/useDeleteDispatchGroup';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useTranslation } from 'react-i18next';

export interface DispatchGroupCardProps {
  numberId: string;
  group: NumberDetailQuery_numberDetail_dispatchGroups;
  currentIdx: number;
  onMoveup?: MoveItemHandler;
  onMovedown?: MoveItemHandler;
}

const queuingTimeoutArr = [10, 15, 20, 30, 40, 50, 60, 180, 300, 600, 900, 1800, 2400, 3600];
export function TeamDispatchCard({
  numberId,
  group,
  onMoveup,
  onMovedown,
  currentIdx,
}: DispatchGroupCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();

  const { dispatchable, queuingTimeout, redirectionStrategy } = group;

  const team = dispatchable as NumberDetailQuery_numberDetail_dispatchGroups_dispatchable_Team;
  const teamName = team && team.name;

  const { updateDispatchGroup } = useUpdateDispatchGroup({
    numberId,
    group,
    name: teamName,
  });
  const { deleteDispatchGroup } = useDeleteDispatchGroup({
    numberId,
    groupId: group.id,
    name: teamName,
  });

  const handleChange = useCallback(
    (attributeName: DISPATCH_GROUP_ATTRIBUTE) => (value: Key) =>
      updateDispatchGroup(attributeName, value as DispatchGroupAttributeValue),
    [updateDispatchGroup]
  );

  const durationUnitSec = t(
    `${TRANSLATION_PREFIX}.dispatch_cards.queuingTimeout.queuingTimeout_unit_seconds`
  );
  const durationUnitMin = t(
    `${TRANSLATION_PREFIX}.dispatch_cards.queuingTimeout.queuingTimeout_unit_minutes`
  );

  const queuingTimeoutOptions = useMemo(
    () =>
      queuingTimeoutArr.map((item) => ({
        value: item,
        label: item > 60 ? `${item / 60} ${durationUnitMin}` : `${item} ${durationUnitSec}`,
      })),
    [durationUnitMin, durationUnitSec]
  );

  const [queuingTimeoutPlaceholder, queuingTimeoutSelectedKeys] = useMemo(() => {
    if (queuingTimeout === 0) {
      return [t(`${TRANSLATION_PREFIX}.dispatch_cards.queuingTimeout.placeholder`), []];
    }
    if (!queuingTimeoutArr.includes(queuingTimeout)) {
      // if the value is not expected, display `${value} sec` as placeholder
      return [`${queuingTimeout} ${durationUnitSec}`, []];
    }
    return ['', [queuingTimeout]];
  }, [queuingTimeout, durationUnitSec, t]);

  if (!team) {
    return null;
  }

  const { ID, name, imageName, agentsIds } = team;

  const userCount = agentsIds?.length ?? 0;
  const teamId = ID;

  return (
    <DiagramBranch mb={24} isDraggable>
      <DiagramCard>
        {/* First row */}
        <Grid
          data-test='team-dispatch-card'
          gridTemplateColumns='auto 1fr auto auto'
          columnGap='xxs'
          alignItems='center'
          padding='xs'
          paddingRight='xxs'
        >
          <EmojiAvatar size='regular' name={imageName} />
          <Box overflow='hidden'>
            <TooltipOnTruncate title={name}>
              <Typography variant='bodySemiboldM' ellipsis={1}>
                {name}
              </Typography>
            </TooltipOnTruncate>
          </Box>
          <UserCountTag id={teamId} size={userCount} />
          <Dropdown
            trigger={
              <IconButton
                size='m'
                component={MenuVerticalFilled}
                color='neutral-400'
                data-test='team-dispatch-card-action-btn'
              />
            }
            side='bottom'
            align='start'
          >
            <Menu>
              <MenuItem onClick={() => navigate(TEAM_DETAIL_ROUTE, { teamId })}>
                {t(`${TRANSLATION_PREFIX}.dispatch_cards.actions.go_to_team`)}
              </MenuItem>
              <MenuItem onClick={deleteDispatchGroup}>
                {t(`${TRANSLATION_PREFIX}.dispatch_cards.actions.delete`)}
              </MenuItem>
              <MoveMenuItems
                onMoveup={onMoveup}
                onMovedown={onMovedown}
                group={group}
                index={currentIdx}
              />
            </Menu>
          </Dropdown>
        </Grid>
        {/* Second row: rings for n sec */}
        <Grid
          gridTemplateColumns='auto auto auto'
          columnGap='xxs'
          alignItems='center'
          padding='s'
          paddingRight='xxs'
        >
          <Typography>
            {t(`${TRANSLATION_PREFIX}.dispatch_cards.redirectionStrategy.title`)}
          </Typography>

          <Select
            size='small'
            options={queuingTimeoutOptions}
            onSelectionChange={(v) => {
              handleChange(DISPATCH_GROUP_ATTRIBUTE.QUEUING_TIMEOUT)(v?.[0]);
            }}
            placeholder={queuingTimeoutPlaceholder}
            selectedKeys={queuingTimeoutSelectedKeys as number[]}
            takeTriggerWidth={false}
            data-test='select-queuingTimeout'
          />
          <Select
            takeTriggerWidth={false}
            size='small'
            options={[
              {
                label: t(
                  `${TRANSLATION_PREFIX}.dispatch_cards.redirectionStrategy.options.simultaneously`
                ),
                value: 'concurrent',
              },
              {
                label: t(
                  `${TRANSLATION_PREFIX}.dispatch_cards.redirectionStrategy.options.randomly`
                ),
                value: 'random',
              },
              {
                label: t(
                  `${TRANSLATION_PREFIX}.dispatch_cards.redirectionStrategy.options.longest_idle`
                ),
                value: 'longest_idle',
              },
            ]}
            onSelectionChange={(v) => {
              handleChange(DISPATCH_GROUP_ATTRIBUTE.REDIRECTION_STRATEGY)(v?.[0]);
            }}
            placeholder={t(`${TRANSLATION_PREFIX}.dispatch_cards.redirectionStrategy.placeholder`)}
            selectedKeys={[redirectionStrategy]}
            data-test='select-redirectionStrategy'
          />
        </Grid>
      </DiagramCard>
    </DiagramBranch>
  );
}
