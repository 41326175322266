import { useCallback, useMemo, useState } from 'react';

import { ColumnFieldActions } from './ColumnFieldActions.container';
import { ColumnFieldCallDistribution } from './ColumnFieldCallDistribution.container';
import { ColumnFieldCountry } from './ColumnFieldCountry.container';
import { ColumnFieldName } from './ColumnFieldName.container';
import { ColumnFieldNumbers } from './ColumnFieldNumbers.container';
import { ColumnFieldStatus } from './ColumnFieldStatus.container';
import { ColumnFieldUsers } from './ColumnFieldUsers.container';
import { NumbersListBodyProps } from './NumbersBody.decl';

import { TableColumn, OrderDirection } from '@aircall/tractor-v2';
import { DeleteNumberConfirmationModal } from '@components/DeleteNumberConfirmationModal';
import { NUMBER_DETAIL_ROUTE } from '@constants/routes.constants';
import { LoadMoreTable } from '@dashboard/library';
import { SearchableLinesSortableFields } from '@generated/globalTypes';
import { SearchLinesQuery_searchLines_items } from '@generated/SearchLinesQuery';
import { formatInternational } from '@helpers/phoneNumber.helpers';
import { reverseOrderDirection } from '@helpers/sort.helpers';
import { useDeleteNumber } from '@hooks/useDeleteNumber';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useToggle } from '@hooks/useToggle/useToggle';
import { useTranslation } from 'react-i18next';

export function NumbersBodySection({
  hasMore,
  loading,
  loadingMore,
  lines,
  onLoadMore,
  onOrderChange,
  order,
}: NumbersListBodyProps) {
  const navigate = useNavigateWithParamsReplace();
  const { t } = useTranslation();

  const [idToDelete, setIdToDelete] = useState('');
  const [nameToDelete, setNameToDelete] = useState('');
  const [flagCodeToDelete, setFlagCodeToDelete] = useState('');
  const [phoneNumberToDelete, setPhoneNumberToDelete] = useState('');

  const [isModalOpen, _, onClick, onCancelClick] = useToggle();
  const { handleDeleteNumber, isDeleteLoading, deleteError } = useDeleteNumber({
    id: idToDelete,
    name: nameToDelete,
    onDelete: onCancelClick,
  });

  const columnOptions: TableColumn<SearchLinesQuery_searchLines_items>[] = useMemo(
    () => [
      {
        id: SearchableLinesSortableFields.countryISOCode,
        label: t('numbers.table.country.label'),
        renderer: (props) => <ColumnFieldCountry {...props} />,
      },
      {
        id: SearchableLinesSortableFields.name,
        label: t('numbers.table.name.label'),
        renderer: (props) => <ColumnFieldName {...props} />,
      },
      {
        id: SearchableLinesSortableFields.phoneNumber,
        label: t('numbers.table.number.label'),
        renderer: (props) => <ColumnFieldNumbers {...props} />,
      },
      {
        id: SearchableLinesSortableFields.agentsCounter,
        label: t('numbers.table.users.label'),
        renderer: (props) => <ColumnFieldUsers {...props} />,
      },
      {
        id: 'flowEditorEnabled',
        label: t('numbers.table.call_distribution.label'),
        renderer: (props) => <ColumnFieldCallDistribution {...props} />,
        sortable: false,
      },
      {
        id: 'status',
        label: t('numbers.table.status.label'),
        renderer: (props) => <ColumnFieldStatus {...props} />,
        sortable: false,
      },
      {
        id: 'link',
        label: '',
        renderer: (props) => <ColumnFieldActions {...props} />,
        sortable: false,
      },
    ],
    [t]
  );

  const handleEditNumber = useCallback(
    ({ ID }: SearchLinesQuery_searchLines_items) => navigate(NUMBER_DETAIL_ROUTE, { numberId: ID }),
    [navigate]
  );

  const handleDeleteModal = useCallback(
    ({ ID, name, countryISOCode, phoneNumber }: SearchLinesQuery_searchLines_items) => {
      setIdToDelete(ID);
      setNameToDelete(name);
      setFlagCodeToDelete(countryISOCode);
      setPhoneNumberToDelete(phoneNumber);
      onClick();
    },
    [onClick]
  );

  const rowActionOptions = useMemo(
    () => [
      { onExecute: handleEditNumber, label: t('numbers.table.row_action.edit') },
      { onExecute: handleDeleteModal, label: t('numbers.table.row_action.delete') },
    ],
    [handleEditNumber, handleDeleteModal, t]
  );

  const handleOrderChange = useCallback(
    (field) => {
      const isSameField = field === order.field;
      const direction = isSameField ? reverseOrderDirection(order.direction) : OrderDirection.Asc;

      onOrderChange(field, direction);
    },
    [onOrderChange, order.direction, order.field]
  );

  return (
    <>
      <LoadMoreTable<SearchLinesQuery_searchLines_items>
        data-test='numbers-list-view-load-more-table'
        recordAttributeIdentifier='ID'
        data={lines}
        columns={columnOptions}
        noDataMessage={t('numbers.search.no_result')}
        rowActions={rowActionOptions}
        onRowClick={handleEditNumber}
        hasMore={hasMore}
        loading={loading}
        loadingMore={loadingMore}
        onLoadMore={onLoadMore}
        onOrderChange={handleOrderChange}
        order={order}
      />
      <DeleteNumberConfirmationModal
        isModalOpen={isModalOpen}
        onCancelClick={onCancelClick}
        onDeleteNumberClick={handleDeleteNumber}
        isDeleteDisabled={isDeleteLoading}
        error={deleteError}
        countryCode={flagCodeToDelete}
        number={formatInternational(phoneNumberToDelete)}
        name={nameToDelete}
      />
    </>
  );
}
