import React from 'react';

import { Icon, Typography, Spacer } from '@aircall/tractor-v2';

export interface GroupSectionTitleProps {
  title: string;
  icon: React.ComponentProps<typeof Icon>['component'];
}
export function GroupSectionTitle({ title, icon }: GroupSectionTitleProps) {
  return (
    <Spacer space='xxs' alignItems='center'>
      <Icon component={icon} size={16} pl='3px' />
      <Typography variant='bodySemiboldM'>{title}</Typography>
    </Spacer>
  );
}
