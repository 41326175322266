/* eslint-disable no-param-reassign */

import {
  RESOURCE,
  RoleName,
  ROLE_NAME,
  ROUTES_RESOURCES_MAPPING,
} from '@constants/permissions.constants';
import { getRouteFromPath } from '@dashboard/library';
import { UserFullFragment_roles } from '@generated/UserFullFragment';

export function getResourceFromPath(path: string): RESOURCE | null {
  // Check if path is present in mapping
  if (path in ROUTES_RESOURCES_MAPPING) {
    return ROUTES_RESOURCES_MAPPING[path];
  }

  // Check if route is present in mapping
  const route = getRouteFromPath(path);

  if (!route) {
    return null;
  }

  if (route in ROUTES_RESOURCES_MAPPING) {
    return ROUTES_RESOURCES_MAPPING[route];
  }

  return null;
}

export function hasRole(roles: Partial<UserFullFragment_roles>[], roleName: RoleName): boolean {
  return roles.some((r) => r.name === roleName);
}

export function canDeleteUser(
  userId: string,
  userRoles: UserFullFragment_roles[],
  userToDeleteId: string,
  userToDeleteRoles: Partial<UserFullFragment_roles>[]
): boolean {
  const isCurrentUser = userId === userToDeleteId;

  if (isCurrentUser) {
    return false;
  }

  if (hasRole(userRoles, ROLE_NAME.ADMIN) && hasRole(userRoles, ROLE_NAME.OWNER)) {
    return true;
  }

  // If user is Owner, he can only delete Owner
  if (hasRole(userRoles, ROLE_NAME.OWNER)) {
    return hasRole(userToDeleteRoles, ROLE_NAME.OWNER);
  }

  // If user is Admin, he can delete every roles except Owner
  if (hasRole(userRoles, ROLE_NAME.ADMIN)) {
    return !hasRole(userToDeleteRoles, ROLE_NAME.OWNER);
  }

  return false;
}
