import { AnalyticsDashboard } from '@components/AnalyticsDashboard/AnalyticsDashboard';
import { AnalyticsDashboardType } from '@generated/globalTypes';
import { ANALYTICS_LIMITS } from '@helpers/analytics.helpers';
import { useTranslation } from 'react-i18next';

export function StatsOverviewContainer() {
  const { t } = useTranslation();

  return (
    <AnalyticsDashboard
      feedbackButtonEvent='feedback_analytics_button_clicked'
      minDate={ANALYTICS_LIMITS.sixMonthDate}
      type={AnalyticsDashboardType.OVERVIEW}
      pageHeaderTitle={t('stats.tabs.overview')}
      title='overview'
    />
  );
}
