import { DayOfWeek } from '@generated/globalTypes';
import { LineBusinessHoursQuery_getLineBusinessHours as BusinessHours } from '@generated/LineBusinessHoursQuery';

function changeTimezone(date: Date, timeZone: string): Date {
  const localDate = new Date(
    date.toLocaleString('en-US', {
      timeZone,
    })
  );
  const diff = date.getTime() - localDate.getTime();
  return new Date(date.getTime() - diff);
}

const daysOfWeek: DayOfWeek[] = [
  DayOfWeek.SUNDAY,
  DayOfWeek.MONDAY,
  DayOfWeek.TUESDAY,
  DayOfWeek.WEDNESDAY,
  DayOfWeek.THURSDAY,
  DayOfWeek.FRIDAY,
  DayOfWeek.SATURDAY,
];

export const computeIsOpen = ({ slots, timezone }: BusinessHours): boolean => {
  const timeOfTimezone = changeTimezone(new Date(), timezone);

  const dayOfWeek = daysOfWeek[timeOfTimezone.getDay()];

  const hour = timeOfTimezone.getHours();
  const minute = timeOfTimezone.getMinutes();
  const timeInMinutes = hour * 60 + minute;

  return slots.some(
    ({ days, from, to }) => days.includes(dayOfWeek) && from <= timeInMinutes && timeInMinutes <= to
  );
};
