import { UserOutlined } from '@aircall/icons';
import { Icon, Tag } from '@aircall/tractor-v2';

interface UsersCountTagProps {
  usersCount: number;
  'data-test'?: string;
}

export function UsersCountTag({ usersCount, 'data-test': dataTest }: UsersCountTagProps) {
  return usersCount === 0 ? (
    <Tag size='small' variant='red' data-test={dataTest}>
      <Icon component={UserOutlined} size={16} />
      {usersCount.toString()}
    </Tag>
  ) : (
    <Tag size='small' data-test={dataTest} bg='transparent' color='neutral-800'>
      <Icon component={UserOutlined} size={16} />
      {usersCount.toString()}
    </Tag>
  );
}
