import { useCallback, useLayoutEffect } from 'react';

import { UseContractCheckAndSignReturnType } from './useContractCheckAndSign.decl';

import { logger } from '@config/logger.config';
import { ASSETS_PAGE_ROUTE } from '@constants/regex.constants';
import { getDaysDifference } from '@dashboard/library';
import { GetSignupInformation } from '@generated/GetSignupInformation';
import { SIGNUP_INFORMATION_QUERY } from '@graphql/queries/SignupInformationQuery';
import {
  sendAcceptance,
  hasUserSignedLatestContracts,
  setSignerCustomData,
} from '@helpers/ironclad.helpers';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { usePathnameMatch } from '@hooks/usePathnameMatch';
import { useGraphQuery } from '@hooks/useQuery';
import { useQueryLocalisedContracts } from '@hooks/useQueryLocalisedContracts';
import { useNavigate } from 'react-router-dom';

export function useContractCheckAndSign(): UseContractCheckAndSignReturnType {
  const navigate = useNavigate();
  const { showOnboardingTrial } = useFeatures();
  const skip = usePathnameMatch([ASSETS_PAGE_ROUTE]);
  const { data } = useGraphQuery<GetSignupInformation>(SIGNUP_INFORMATION_QUERY, {
    throwError: false,
    skip,
  });

  const {
    data: { contractIds, versionIds },
  } = useQueryLocalisedContracts(data?.getSignupInformation.country, skip);

  const { currentCompany, isTrial, currentUser } = useGlobalData();
  const now = new Date();
  const trialEndDate = new Date(isTrial ? currentCompany.trial!.trialEnd : now);
  const daysLeftInTrial = getDaysDifference(trialEndDate, now);

  const {
    authState: { isConnectedAs },
  } = useAuthenticationState();

  const checkAndSendAcceptance = useCallback(async () => {
    if (!contractIds || !versionIds || isConnectedAs) {
      return;
    }

    try {
      await setSignerCustomData({
        companyId: currentCompany.id,
        companyName: currentCompany.name,
        email: currentUser.email,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        userId: currentUser.ID,
      });
      const hasSignedLatest = await hasUserSignedLatestContracts(contractIds, currentCompany.id);
      if (!hasSignedLatest) {
        await sendAcceptance(currentCompany.id, versionIds, contractIds);
      }
    } catch (error) {
      logger.error(`Error checking or sending acceptance: ${(error as Error).message}`);
    }
  }, [contractIds, isConnectedAs, currentCompany, currentUser, versionIds]);

  useLayoutEffect(() => {
    if (showOnboardingTrial) {
      checkAndSendAcceptance();
    } else {
      navigate('/', { replace: true });
    }
  }, [checkAndSendAcceptance, navigate, showOnboardingTrial]);

  return { daysLeftInTrial };
}
