import { useCallback, useContext, useMemo } from 'react';

import { PageFunctionParams, TrackFunctionParams, UseTrackerReturn } from './useTracker.decl';

import { FeaturesFlagsContext } from '@components/FeaturesFlagsProvider/FeaturesFlagsProvider';
import { gainsight } from '@constants/gainsight.constants';
import { tracker } from '@constants/tracker.constants';
import { getCommonPayload, getSignedInAsPayload } from '@helpers/tracker.helpers';
import noop from 'lodash-es/noop';

/**
 * Hook providing a track utility function using Aircall tracker library.
 * @returns an object holding track function utility
 */
export function useTracker(): UseTrackerReturn {
  const featureFlags = useContext(FeaturesFlagsContext);

  const track = useCallback(({ event, payload = {} }: TrackFunctionParams) => {
    tracker.track(event, {
      ...getCommonPayload(),
      ...getSignedInAsPayload(),
      ...payload,
    });
    gainsight.track(event, {
      ...getCommonPayload(),
      ...getSignedInAsPayload(),
      ...payload,
    });
  }, []);

  const page = useCallback((payload: PageFunctionParams) => {
    tracker.page({
      ...getSignedInAsPayload(),
      ...payload,
    });
  }, []);

  return useMemo(
    () => ({
      track: featureFlags.disableFrontendTracking ? noop : track,
      page: featureFlags.disableFrontendTracking ? noop : page,
    }),
    [featureFlags.disableFrontendTracking, page, track]
  );
}
