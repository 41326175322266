import { GetCurrentUserQuery } from '@generated/GetCurrentUserQuery';
import { UserFullFragment } from '@generated/UserFullFragment';
import { GET_CURRENT_USER_QUERY } from '@graphql/queries/GetCurrentUserQuery';
import { ClientError } from '@helpers/errors.helpers';
import { useGraphQuery } from '@hooks/useQuery/useGraphQuery';

export interface CurrentUserReturn {
  currentUser?: UserFullFragment;
  data?: GetCurrentUserQuery;
  error?: ClientError;
  loading: boolean;
}

/**
 * Hook to get the current user data.
 * @returns an object holding the query state and user's data
 */
export function useCurrentUser(): CurrentUserReturn {
  const { data, error, loading } = useGraphQuery<GetCurrentUserQuery>(GET_CURRENT_USER_QUERY);

  return {
    loading,
    error,
    data,
    currentUser: data?.getAgentV2,
  };
}
