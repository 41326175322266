import * as ROUTES from '@constants/routes.constants';
import { ValueOf } from '@dashboard/library';

export enum RESOURCE {
  NUMBERS = 'NUMBERS',
  TEAMS = 'TEAMS',
  USERS = 'USERS',
  STATS = 'STATS',
  ACTIVITY_FEED = 'ACTIVITY_FEED',
  CALL_SETTINGS = 'CALL_SETTINGS',
  INTEGRATIONS = 'INTEGRATIONS',
  COMPANY_GENERAL = 'COMPANY_GENERAL',
  COMPANY_PLAN = 'COMPANY_PLAN',
  COMPANY_BILLING = 'COMPANY_BILLING',
  COMPANY_SECURITY = 'COMPANY_SECURITY',
  COMPANY_ROLES = 'COMPANY_ROLES',
  ASSET = 'ASSET',
  DASHBOARD_V2 = 'DASHBOARD_V2',
  ROLE_MANAGEMENT = 'ROLE_MANAGEMENT',
  CONVERSATIONS_CENTER = 'CONVERSATIONS_CENTER',
}

export enum PERMISSIONS_ACTIONS {
  CREATE = 'c',
  READ = 'r',
  UPDATE = 'u',
  DELETE = 'd',
}

// Not an enum because Role.name in the schema is a string
export const ROLE_NAME = {
  OWNER: 'Owner',
  ADMIN: 'Admin',
  SUPERVISOR: 'Supervisor',
  AGENT: 'Agent',
};

export const ROLE_ORDER = {
  [ROLE_NAME.OWNER]: 1,
  [ROLE_NAME.ADMIN]: 2,
  [ROLE_NAME.SUPERVISOR]: 3,
  [ROLE_NAME.AGENT]: 4,
};

export type RoleName = ValueOf<typeof ROLE_NAME>;

export const ROUTES_RESOURCES_MAPPING: Record<string, RESOURCE> = {
  [ROUTES.USER_DETAIL_ROUTE]: RESOURCE.USERS,
  [ROUTES.USER_DETAIL_ROUTE_TAB]: RESOURCE.USERS,
  [`${ROUTES.ACCOUNT_ROUTE}/${ROUTES.ACCOUNT_TABS.GENERAL}`]: RESOURCE.COMPANY_GENERAL,
  [`${ROUTES.ACCOUNT_ROUTE}/${ROUTES.ACCOUNT_TABS.YOUR_PLAN}`]: RESOURCE.COMPANY_PLAN,
  [`${ROUTES.ACCOUNT_ROUTE}/${ROUTES.ACCOUNT_TABS.BILLING}`]: RESOURCE.COMPANY_BILLING,
  [`${ROUTES.ACCOUNT_ROUTE}/${ROUTES.ACCOUNT_TABS.SECURITY}`]: RESOURCE.COMPANY_SECURITY,

  // For tests purposes
  [ROUTES.TEST_ROUTE]: RESOURCE.NUMBERS,
};

export const PERMISSIONS_MODAL_LS_KEY = 'permissions_modal';
