import { useColor } from '@xstyled/styled-components';

export interface SVGVerticalArrowLineProps {
  /** Stroke width of the line */
  strokeWidth?: number;

  /** Height of the vertical line */
  height: number;
}

export function SVGVerticalArrowLine({ strokeWidth = 1, height }: SVGVerticalArrowLineProps) {
  const strokeColor = useColor('neutral-400') as string;
  return (
    <svg width={strokeWidth} height={height}>
      <defs>
        <marker
          id='arrow'
          viewBox='0 0 10 10'
          refX='5'
          refY='5'
          markerUnits='strokeWidth'
          markerWidth='10'
          markerHeight='10'
          orient='auto'
        >
          <path
            d='M0.452,9.552l4.555,-4.555m-0,0l-4.555,-4.555'
            strokeLinecap='round'
            style={{
              fill: 'none',
              fillRule: 'nonzero',
              stroke: strokeColor,
              strokeWidth: 1,
            }}
          />
        </marker>
      </defs>
      <line
        x1='0'
        y1='0'
        x2='0'
        y2={height}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        markerEnd='url(#arrow)'
      />
    </svg>
  );
}
