import { useState, useCallback } from 'react';

export type UseToggleReturnType = [boolean, () => void, () => void, () => void];

/**
 * Hook providing functions to ease the use of toggling a given state.
 * @param isOpen - default state
 * @returns a tupple of toggle utilities
 */
export function useToggle(isOpen = false): UseToggleReturnType {
  const [open, setOpenState] = useState<boolean>(isOpen);

  const toggle = useCallback(() => setOpenState((s) => !s), []);
  const setOpen = useCallback(() => setOpenState(true), []);
  const setClose = useCallback(() => setOpenState(false), []);

  return [open, toggle, setOpen, setClose];
}
