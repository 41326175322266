import { gql } from '@apollo/client';

export const ADD_BLACKLISTED_NUMBER_MUTATION = gql`
  mutation AddBlacklistedNumberMutation($input: AddBlacklistedNumberMutationInput!) {
    addBlacklistedNumber(input: $input)
      @rest(
        type: "BlacklistedNumber"
        path: "blacklisted_phone_numbers"
        method: "POST"
        endpoint: "v3"
      ) {
      id
      phoneNumber
    }
  }
`;
