import React, { useEffect } from 'react';

import { TextToSpeechAudioSetting } from '../../pages/number-detail-call-distribution/AudioSetting/AudioSetting.decl';

import { AudioPlayer } from '@components/AudioPlayer';
import { TextareaField } from '@components/TextareaField';
import { GridItem, GridLayout, SelectField } from '@dashboard/library';
import {
  TextToSpeechOptionsQuery_textToSpeechOptions_languages,
  TextToSpeechOptionsQuery_textToSpeechOptions_languages_voices,
} from '@generated/TextToSpeechOptionsQuery';
import { useTextToSpeech } from '@hooks/useTextToSpeech/useTextToSpeech';
import styled, { getColor } from '@xstyled/styled-components';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

interface TextToSpeechFieldsProps {
  numberId: string;
  audioSetting: TextToSpeechAudioSetting;
}

const AudioPlayerCustom = styled(AudioPlayer)`
  height: 48px;
  padding-right: s;
  padding-left: 2px;
  border: 1px solid ${getColor('neutral-200')};
  border-radius: sm;
  background-color: surface-default;
`;

function getVoicesOptionsFromLocale(
  languages: TextToSpeechOptionsQuery_textToSpeechOptions_languages[],
  locale: string
) {
  const languageSelected = languages.find((_language) => _language.locale === locale);

  if (!languageSelected) {
    return [];
  }

  return languageSelected.voices.map(
    (_voice: TextToSpeechOptionsQuery_textToSpeechOptions_languages_voices) => {
      const { label, name } = _voice;
      return {
        label,
        value: name,
      };
    }
  );
}

export const TextToSpeechFields = React.memo(
  ({ numberId, audioSetting }: TextToSpeechFieldsProps) => {
    const { t } = useTranslation();
    const form = useForm();
    const { languages, previewUrl, generatePreviewDebounced: generatePreview } = useTextToSpeech();

    const {
      values: {
        audioSetting: { id: ttsId, language, voice, message },
      },
    } = form.getState();

    useEffect(() => {
      if (!message) {
        return;
      }

      generatePreview(numberId, ttsId, language, voice, message);
    }, [numberId, ttsId, language, voice, message, generatePreview]);

    if (!languages) {
      return null;
    }

    const languagesOptions = languages.map((_language) => {
      const { label, locale } = _language;
      return {
        label,
        value: locale,
      };
    });

    const voicesOptions = getVoicesOptionsFromLocale(languages, audioSetting.language);

    // We update voice select value since it's depending on the chosen language
    const handleChange = (selected: string) => {
      form.change('audioSetting.language', selected);
      const vOptions = getVoicesOptionsFromLocale(languages, selected);
      form.change('audioSetting.voice', vOptions[0].value);
    };

    return (
      <GridLayout data-test='tts-fields'>
        <GridItem xs={12}>
          <TextareaField
            name='audioSetting.message'
            label={t(
              'number_details.settings.call_distribution_section.audio_setting_modal.textToSpeech.message_text'
            )}
          />
        </GridItem>
        <GridItem xs={6}>
          <SelectField
            t={t}
            name='audioSetting.language'
            label={t(
              'number_details.settings.call_distribution_section.audio_setting_modal.textToSpeech.language'
            )}
            size='regular'
            options={languagesOptions}
            onChange={handleChange}
            data-test='select-language'
          />
        </GridItem>
        {voicesOptions.length > 0 && (
          <GridItem xs={6}>
            <SelectField
              t={t}
              label={t(
                'number_details.settings.call_distribution_section.audio_setting_modal.textToSpeech.voice'
              )}
              name='audioSetting.voice'
              size='regular'
              options={voicesOptions}
              data-test='select-voice'
            />
          </GridItem>
        )}
        <GridItem xs={12}>
          <AudioPlayerCustom
            key={previewUrl}
            url={previewUrl || ''}
            disabled={!previewUrl || !audioSetting.message}
            data-test={`audio-player-${previewUrl}`}
          />
        </GridItem>
      </GridLayout>
    );
  }
);
