import styled from 'styled-components';

import { Box, BoxProps, getColor } from '@aircall/tractor-v2';

export interface ContentWrapperProps extends Omit<BoxProps, 'backgroundColor'> {
  backgroundColor?: string;
}

export const ContentWrapper = styled(Box)<ContentWrapperProps>`
  height: 100%;
  background: ${({ backgroundColor }) => backgroundColor || getColor('neutral-100')};
  overflow-x: hidden;
  overflow-y: auto;
`;
