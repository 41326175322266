import { TextareaFieldProps } from './TextareaField.decl';

import { FormItem, Textarea } from '@aircall/tractor-v2';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export function TextareaField({ name, label }: TextareaFieldProps) {
  const {
    input: { value, onChange, onBlur },
    meta: { error, touched },
  } = useField(name);
  const { t } = useTranslation();

  const showError = error && touched;
  const validationStatus = showError ? 'error' : undefined;
  return (
    <FormItem
      name={name}
      label={label}
      helpText={showError ? t(error) : undefined}
      validationStatus={validationStatus}
    >
      <Textarea
        onBlur={(event) => onBlur(event)}
        onChange={onChange}
        value={value}
        validationStatus={validationStatus}
      />
    </FormItem>
  );
}
