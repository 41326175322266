/* istanbul ignore file */
import { createGlobalStyle } from 'styled-components';

import { FONT_SIZE_BASE_VALUE } from '../constants/style.constants';

import { getColor } from '@aircall/tractor-v2';

// load the vendor CSS asynchronously
import(/* webpackPreload: true */ 'emoji-mart/css/emoji-mart.css');

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    color: ${getColor('text-base')};
    font-family: ${({ theme }) => theme.fonts.default};
    font-size: ${FONT_SIZE_BASE_VALUE}px;
  }

  #root {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .body-overflow-stop {
    overflow: hidden;
  }

  /* avoid having unexpected bigger height on svg when using icon in a button */

  svg {
    display: block;
    line-height: 0;
  }

  a {
    text-decoration: none;
  }
`;
