import { ApplicationPage } from './Application.page';

import { APPLICATIONS_TYPES } from '@constants/integrations.constants';
import { ApplicationHubPage } from '@pages/application/ApplicationHub.page';
import { useParams } from 'react-router-dom';

export function ApplicationContainerPage() {
  const { applicationType } = useParams();

  if (applicationType === APPLICATIONS_TYPES.HUB) {
    return <ApplicationHubPage />;
  }

  return <ApplicationPage />;
}
