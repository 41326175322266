import { gql } from '@apollo/client';

export const GET_PUSHER_CONFIG = gql`
  query GetPusherConfigQuery {
    getNotificationsConfiguration {
      cluster
      appKey
    }
  }
`;
