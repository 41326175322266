const SCROLL_ENABLING_OVERFLOW_VALUES = ['scroll', 'auto'];

/**
 * Returns whether the given scrollable element contains a scrollbar
 * @returns a predicate
 */
export function isScrollable(ele: HTMLElement): boolean {
  const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

  const overflowYStyle = window.getComputedStyle(ele).overflowY;
  const isScrollCapable = SCROLL_ENABLING_OVERFLOW_VALUES.includes(overflowYStyle);

  return hasScrollableContent && isScrollCapable;
}

/**
 * Returns the closest scrollable parent element from the current element `ele`.
 * @param ele - the starting point of the element for us to find the parent
 * @returns the closest scrollable parent element
 */
export function getScrollableParent(ele: HTMLElement | null): HTMLElement {
  if (!ele || ele === document.body) {
    return document.body;
  }

  if (isScrollable(ele)) {
    return ele;
  }

  return getScrollableParent(ele.parentNode as HTMLElement | null);
}
