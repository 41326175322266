import { SearchableNumberSelectFieldProps } from './SearchableNumberSelectField.decl';

import { FormItem } from '@aircall/tractor-v2';
import { SearchableNumberSelect } from '@components/SearchableNumberSelect';
import { InternalNumber } from '@components/SearchableNumberSelect/SearchableNumberSelect.decl';
import { useField, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export function SearchableNumberSelectField({
  name,
  label,
  idsBlacklist,
}: SearchableNumberSelectFieldProps) {
  const { t } = useTranslation();

  const { change } = useForm();
  const {
    input: { value },
    meta: { submitError },
  } = useField<InternalNumber>(name);

  return (
    <FormItem
      label={label}
      name={name}
      gridAutoRows='max-content'
      validationStatus={submitError ? 'error' : undefined}
      helpText={submitError ? t(submitError) : undefined}
    >
      <SearchableNumberSelect
        onChange={(internalNumberValue) => {
          // update the internal number object
          change(name, internalNumberValue);
        }}
        selectedNumber={value}
        idsBlacklist={idsBlacklist}
      />
    </FormItem>
  );
}
