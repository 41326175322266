import { useMemo } from 'react';

import { routeReplacer } from '@dashboard/library';
import { useNavigate } from 'react-router-dom';

import { NavFunc, NavFuncResult } from '.';

/**
 * Navigation Function will interpolate the keys in the route with the params.
 * @param route - String for the route Eg.: /teams/:teamId.
 * @param params - Object with the params, given that the route is /teams/:teamId this object should be `{ teamId: "value" }`.
 */
export const navigation: NavFunc =
  (nav) =>
  (route, params, options = {}) => {
    const { keepParams, ...optionsRest } = options;
    const queryParams = keepParams ? window.location.search : '';
    return nav(routeReplacer(route, params) + queryParams, optionsRest);
  };

/**
 * This hook will replace parameters in a given route and will navigate to it with the help of useNavigate.
 * @returns A custom navigation function.
 */
export function useNavigateWithParamsReplace(): NavFuncResult {
  const navigate = useNavigate();
  return useMemo(() => navigation(navigate), [navigate]);
}
