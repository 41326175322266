import { useEffect, useState } from 'react';

import { END_TRIAL_MODAL_TRACK_EVENTS } from './EndTrialModal.decl';

import { Button, ModalBody, ModalDialog, Spacer, Typography } from '@aircall/tractor-v2';
import endTrialImage from '@assets/images/end-trial.png';
import { ACCOUNT_ROUTE_TAB, ACCOUNT_TABS } from '@constants/routes.constants';
import { CONTACT_US_URL } from '@constants/urls.constants';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useTracker } from '@hooks/useTracker/useTracker';
import { useTranslation } from 'react-i18next';

export function EndTrialModal() {
  const [show, setShow] = useState(false);
  const [timeShown, setTimeShown] = useState(new Date());

  const { t } = useTranslation();
  const { track } = useTracker();
  const navigate = useNavigateWithParamsReplace();
  const { currentCompany } = useGlobalData();

  const onHideModal = () => {
    setShow(false);
    const duration = (new Date().getTime() - timeShown.getTime()) / 1000;
    track({
      event: END_TRIAL_MODAL_TRACK_EVENTS.MODAL_DISMISSED,
      payload: {
        duration,
      },
    });
  };
  const onContactUs = () => {
    track({
      event: END_TRIAL_MODAL_TRACK_EVENTS.CONTACT_US_CLICKED,
    });

    onHideModal();

    track({
      event: 'external_link_opened',
      payload: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        link_destination_domain: CONTACT_US_URL,
      },
    });
  };
  const onUpgradeNow = () => {
    track({
      event: END_TRIAL_MODAL_TRACK_EVENTS.UPGRADE_NOW_CLICKED,
    });

    onHideModal();

    navigate(ACCOUNT_ROUTE_TAB, { tab: ACCOUNT_TABS.YOUR_PLAN });
  };

  useEffect(() => {
    let defaultShow = false;
    if (
      currentCompany.plan === 'trial' &&
      currentCompany.trial &&
      new Date(currentCompany.trial.trialEnd) < new Date()
    ) {
      defaultShow = true;
      setTimeShown(new Date());

      track({
        event: END_TRIAL_MODAL_TRACK_EVENTS.MODAL_SHOWN,
      });
    }

    setShow(defaultShow);
  }, [currentCompany.plan, currentCompany.trial, track]);

  return (
    <ModalDialog data-test='end-trial-modal' show={show} onHide={onHideModal} size='regular'>
      <ModalBody>
        <Spacer direction='vertical' space='m' alignItems='center' minWidth='100%'>
          <Typography data-test='heading' variant='headingBoldS' textAlign='center'>
            {t('onboarding.end_trial.heading')}
          </Typography>

          <img
            src={endTrialImage}
            width='338px'
            height='348px'
            alt=''
            data-test='end-trial-image'
          />

          <Typography
            data-test='subheading'
            variant='bodyRegularM'
            textAlign='center'
            maxWidth='528px'
          >
            {t('onboarding.end_trial.subheading')}
          </Typography>

          <Spacer direction='horizontal' space='xxs'>
            <Button
              data-test='contact-us-btn'
              as='a'
              href={CONTACT_US_URL}
              target='_blank'
              rel='noopener noreferrer'
              mode='outline'
              onClick={onContactUs}
            >
              {t('onboarding.end_trial.contact_us')}
            </Button>

            <Button data-test='upgrade-now-btn' onClick={onUpgradeNow}>
              {t('onboarding.end_trial.upgrade_now')}
            </Button>
          </Spacer>
        </Spacer>
      </ModalBody>
    </ModalDialog>
  );
}
