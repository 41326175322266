import React from 'react';

import { StyledComponent } from 'styled-components';

import { getColor } from '@aircall/tractor-v2';
import { LookerFilterStyleProps } from '@pages/looker-v2/Filters/Filters.decl';
import styled, { Theme, css } from '@xstyled/styled-components';

export function withLookerFilterStyle<T>(
  WrappedComponent: React.FC<T>
): StyledComponent<React.FC<T>, Theme, LookerFilterStyleProps> {
  return styled(WrappedComponent)<LookerFilterStyleProps>`
    height: 36px;
    min-height: 36px;
    padding: 0 s;
    border: 1px solid rgb(190 190 190 / 1);
    border-radius: 4px;
    background: rgb(247 247 247 / 1);
    color: rgb(66 73 79 / 1);
    transition: none;

    &:hover {
      border-color: ${getColor('neutral-600')};
      background: rgb(237 237 237 / 1);
    }

    &:active {
      border-color: rgb(190 190 190 / 1);
      background: rgb(247 247 247 / 1);
    }

    & > div {
      font-weight: 400;
    }

    ${({ hasValue }) =>
      hasValue &&
      css`
        background: rgb(210 211 212 / 1);
        color: rgb(16 24 32 / 1);

        &:hover,
        &:active {
          border-color: ${getColor('neutral-600')};
          background: rgb(210 211 212 / 1);
        }
      `}
  `;
}
