import { gql } from '@apollo/client';

export const AUDIO_FILE_UPLOAD_QUERY = gql`
  query AudioFileUploadQuery($numberId: String!, $name: String!) {
    uploadAudioFile(numberId: $numberId, name: $name)
      @rest(
        type: "UploadAudioFilePayload"
        path: "{args.numberId}/tracks/upload?number_id={args.numberId}&name={args.name}"
        endpoint: "v4NumberDetail"
        method: "GET"
      ) {
      id
      name
      url
      readUrl
      fields {
        contentType
        expires
        acl
        key
        policy
        successActionStatus
        xAmzAlgorithm
        xAmzCredential
        xAmzDate
        xAmzSecurityToken
        xAmzSignature
      }
    }
  }
`;
