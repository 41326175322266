import { useCallback } from 'react';

import styled from 'styled-components';

import {
  ANALYTICS_PLUS_AD_MODAL_TRACK_EVENTS,
  AnalyticsPlusAdModalProps,
} from './AnalyticsPlusAdModal.decl';

import {
  Button,
  DrawerHeader,
  DrawerTitle,
  getColor,
  ModalBody,
  ModalDialog,
  ModalFooter,
  Typography,
} from '@aircall/tractor-v2';
import analitycsPlusAdImage from '@assets/images/stats_analytics_plus_ad.png';
import { ADDONS } from '@constants/addons.constants';
import { useNavigateToAddonPage } from '@dashboard/library';
import { useGetEnableTrialAddonsFeatureFlags } from '@hooks/useGetEnableTrialAddonsFeatureFlags';
import { useTracker } from '@hooks/useTracker';
import { useTranslation } from 'react-i18next';

const AdImage = styled.img`
  display: block;
  width: 48rem;
  max-width: 100%;
  margin: 0 auto;
`;

const StyledModalBody = styled(ModalBody)`
  background-color: ${getColor('surface-default')};
`;

export function AnalyticsPlusAdModal({
  funnelId,
  onHide,
  show,
}: Readonly<AnalyticsPlusAdModalProps>) {
  const { t } = useTranslation();
  const { track } = useTracker();

  const { isAddonEnabled } = useGetEnableTrialAddonsFeatureFlags();
  const trackAddonPageEvent = useCallback((event, payload) => track({ event, payload }), [track]);
  const navigate = useNavigateToAddonPage(trackAddonPageEvent);

  const areAnalyticsPlusTrialsEnabled = isAddonEnabled(ADDONS.ANALYTICS_PLUS);

  const redirectToDocumentation = () => {
    window.open(t('analytics.conversion_funnel.link'));
  };

  const trackPopUpClicked = () => {
    track({
      event: ANALYTICS_PLUS_AD_MODAL_TRACK_EVENTS.POPUP_CLICKED,
      payload: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        funnel_id: funnelId,
      },
    });
  };

  const handleDiscover = () => {
    redirectToDocumentation();

    trackPopUpClicked();

    onHide();
  };

  const onUpgradeNow = () => {
    navigate(ADDONS.ANALYTICS_PLUS, 'button-upgrade-now-modal');
  };

  return (
    <ModalDialog data-test='analytics-plus-ad-modal' onHide={onHide} show={show}>
      <DrawerHeader>
        <DrawerTitle>{t('analytics.conversion_funnel.title')}</DrawerTitle>
      </DrawerHeader>
      <StyledModalBody>
        <AdImage src={analitycsPlusAdImage} alt={t('stats.tabs.analytics_plus')} />
        <Typography my='s' variant='bodyRegularM'>
          {t('analytics.conversion_funnel.text')}
        </Typography>
      </StyledModalBody>
      <ModalFooter gap='xxs'>
        {!areAnalyticsPlusTrialsEnabled ? (
          <Button variant='primary' onClick={handleDiscover} size='regular'>
            {t('analytics.conversion_funnel.action')}
          </Button>
        ) : null}
        {areAnalyticsPlusTrialsEnabled ? (
          <>
            <Button variant='primary' mode='outline' onClick={handleDiscover} size='regular'>
              {t('analytics.conversion_funnel.learn_more')}
            </Button>
            <Button variant='primary' onClick={onUpgradeNow} size='regular'>
              {t('analytics.conversion_funnel.upgrade_now')}
            </Button>
          </>
        ) : null}
      </ModalFooter>
    </ModalDialog>
  );
}
