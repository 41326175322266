import { useContext, useEffect } from 'react';

import { APPLICATIONS_NAMES } from '@constants/integrations.constants';
import { INTEGRATION_FULL_ROUTES } from '@constants/routes.constants';
import { Loading } from '@dashboard/library';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useCreateBridgeIntegration } from '@pages/integration-flow-create/Bridge/hooks/useCreateBridgeIntegration';
import { IntegrationPageContext } from '@pages/integration-flow-create/IntegrationPageDataProvider';
import { useParams } from 'react-router-dom';

export interface BridgeQueryParams {
  accessToken?: string;
  refreshToken?: string;
  numberIds?: string;
  instanceUrl?: string;
  site?: string;
  secret?: string;
  url?: string;
  id?: string;
}

export function IntegrationFlowCreateBridge({
  accessToken,
  refreshToken,
  numberIds,
  instanceUrl,
  site,
}: BridgeQueryParams) {
  const {
    applications: { getApplications: applications },
  } = useContext(IntegrationPageContext);
  const { applicationName, applicationType, applicationId } = useParams();
  const navigate = useNavigateWithParamsReplace();
  const { createRestBridgeIntegration, data: bridgeIntegrationData } = useCreateBridgeIntegration();

  useEffect(
    () => {
      if (accessToken) {
        const { logo } = applications.find((app) => app.shortName === applicationName)!;

        createRestBridgeIntegration({
          applicationName: applicationName as APPLICATIONS_NAMES,
          numberIds,
          accessToken,
          refreshToken,
          logo,
          instanceUrl,
          site,
        });
      }
    },
    /* We really want the creation of the integration to happen only once */
    [] // eslint-disable-line
  );

  // Redirect to complete page
  useEffect(() => {
    const integrationId = bridgeIntegrationData?.createRestBridgeIntegration?.id;
    if (integrationId) {
      navigate(
        `${INTEGRATION_FULL_ROUTES.FLOW_COMPLETE}?integration_id=${integrationId}`,
        {
          applicationName,
          applicationType,
          applicationId,
        },
        { replace: true }
      );
    }
  }, [bridgeIntegrationData, navigate, applicationName, applicationType, applicationId]);

  return <Loading data-test='loading' />;
}
