/* eslint-disable @typescript-eslint/naming-convention */

import { Permissions } from '@components/Permissions';
import { LoginFormValues } from '@pages/login/PasswordLoginSection/PasswordLoginForm.container';
import { CredentialResponse } from '@react-oauth/google';
import { CognitoTokenResponse } from '@services/auth.decl';

export enum AUTHENTICATION_STATUS {
  AUTHENTICATED = 'AUTHENTICATED',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  UNKNOWN = 'UNKNOWN',
}

export enum AUTH_STATES {
  SIGN_IN = 'signIn',
  REFRESH_TOKEN = 'refreshToken',
  SIGN_IN_SUCCESS = 'signInSuccess',
  SIGN_IN_ERROR = 'signInError',
  SESSION_EXPIRED = 'sessionExpired',
  SIGN_OUT = 'signOut',
  MFA_SESSION_TOKEN = 'mfaSessionToken',
}

export enum AUTH_STRATEGY {
  PASSWORD = 'password',
  GOOGLE = 'google',
  SAML = 'saml',
}

export enum IDENTITY_PROVIDERS {
  OKTA = 'Okta',
  MS_AZURE_AD = 'MS Azure AD',
  OAUTH = 'OAuth',
  PIDC = 'PIDC',
  JUMP_CLOUD = 'JumpCloud',
  ONE_LOGIN = 'OneLogin',
}

export interface MfaErrorResponse {
  body: { error: { name: string } };
}

export interface AuthenticationState {
  /** Whether the user is waiting for the access token to be fetched */
  signingIn: boolean;

  /**
   * Authentication status of the user
   */
  status: AUTHENTICATION_STATUS;

  /** Whether the user is redirected to the login */
  sessionExpired: boolean;

  /** Error message the user get while signing in */
  signedInError: string | null;

  /** Strategy used to sign in */
  signedInStrategy: AUTH_STRATEGY | null;

  /** Whether the user is signed in using impersonate_token cookie */
  isConnectedAs: boolean;

  /** User auth token */
  token: string | null;

  /** User auth token */
  sessionTokenForMFA: string | null;

  /** User email */
  email: string | null;

  /** whether MFA process is started for user */
  mfaInitiated: boolean;

  /** User auth legacy token */
  legacyToken: string | null;

  /** User is cognito migrated or not */
  isCognitoMigrated: boolean;

  /** Decoded token */
  decodedToken?: ParsedDecodedToken;
}

export interface SetSignInSuccessParams {
  response: CognitoTokenResponse;
  legacyToken?: string | null;
  isConnectedAs?: boolean;
  strategy?: AUTH_STRATEGY;
}

export interface MFAFormValues {
  email: string | null;
  code: string;
  session: string | null;
}

export interface AuthenticationContext {
  authState: AuthenticationState;
  actions: {
    setSignInSuccess: (params: SetSignInSuccessParams) => void;
    signInSsoToIdp: (email: string) => Promise<void>;
    signInSsoWithCode: (code: string) => Promise<void>;
    signIn: (credentials: LoginFormValues | string, strategy?: AUTH_STRATEGY) => Promise<void>;
    validateMultiFactorAuthCode: (credentials: MFAFormValues) => Promise<void>;
    setMultiFactorAuthParams: (
      email: string | null,
      session: string | null,
      isMFAInitiated?: boolean
    ) => void;
    resendMultiFactorAuthCode: (email: string) => Promise<boolean>;
    signInWithGoogle: (credentialResponse: CredentialResponse) => void;
    setSignInError: (message: string | null) => void;
    signOut: () => Promise<void>;
    onSessionExpired: VoidFunction;
    refreshToken: RefreshToken;
  };
}

export interface GoogleLoginError {
  error: string;
  details?: string;
}

export interface DecodedToken {
  sub: string;
  email_verified: boolean;
  iss: string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: number;
  exp: number;
  iat: number;
  email: string;
  'cognito:username': string;
  'custom:verified_email': string;
  'custom:user_id': string;
  'custom:company_id': string;
  'custom:authentication_strategy': string;
  'custom:aircall_platform': string;
  'custom:is_admin'?: string;
  'custom:roles'?: string;
  'custom:permissions'?: string;
  'custom:admin_sso_id'?: string;
}

export type ParsedDecodedToken = Omit<
  DecodedToken,
  'custom:is_admin' | 'custom:roles' | 'custom:permissions'
> & {
  'custom:permissions': Permissions;
};

export type AuthenticationActionType =
  | { type: AUTH_STATES.SIGN_IN }
  | { type: AUTH_STATES.REFRESH_TOKEN; payload: { token: string } }
  | {
      type: AUTH_STATES.MFA_SESSION_TOKEN;
      payload: { email: string | null; session: string | null; isMFAInitiated: boolean };
    }
  | {
      type: AUTH_STATES.SIGN_IN_SUCCESS;
      payload: {
        token: string;
        legacyToken: string | null;
        isConnectedAs: boolean;
        decodedToken?: ParsedDecodedToken;
        strategy: AUTH_STRATEGY;
      };
    }
  | {
      type: AUTH_STATES.SIGN_IN_ERROR;
      payload: { message: string | null; strategy: AUTH_STRATEGY };
    }
  | { type: AUTH_STATES.SESSION_EXPIRED; payload: { message: string | null } }
  | { type: AUTH_STATES.SIGN_OUT };

export type RefreshToken = () => Promise<void>;
