import React, { useEffect, useRef } from 'react';

import { createPortal } from 'react-dom';

interface OverlayProps {
  /** The root element ID where the overlay will be appended */
  portalRootElement?: string;

  children: React.ReactNode;
}

/**
 * The component to display the children as the overlaying content inside the portal
 */
export const Overlay = ({ portalRootElement = 'portal-root', children }: OverlayProps) => {
  const overlayRef = useRef(document.createElement('div'));

  // creates a new div inside the portal root to place the children content
  useEffect(() => {
    const portalContainer = document.getElementById(portalRootElement);
    const { current: overlayEle } = overlayRef;
    portalContainer && portalContainer.appendChild(overlayEle);

    return () => {
      portalContainer && portalContainer.removeChild(overlayEle);
    };
  }, [portalRootElement]);

  return createPortal(children, overlayRef.current);
};
