import { Spacer, Button } from '@aircall/tractor-v2';
import { UpgradePlanButton } from '@components/UpgradePlanButton/UpgradePlanButton';
import { CONTACT_US_URL } from '@constants/urls.constants';
import { useTracker } from '@hooks/useTracker';
import { useTranslation } from 'react-i18next';

export function TrialBannerButton() {
  const { t } = useTranslation();
  const { track } = useTracker();

  const handleButtonLink = (url: string) => () => {
    track({
      event: 'external_link_opened',
      payload: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        link_destination_domain: url,
      },
    });

    window.open(url);
  };

  return (
    <Spacer direction='horizontal' space='m' alignItems='center'>
      <Button
        mode='link'
        size='xSmall'
        onClick={handleButtonLink(CONTACT_US_URL)}
        variant='secondary'
        data-test='trial-banner-contact-us-link'
      >
        {t('trial_banner.contact')}
      </Button>
      <UpgradePlanButton mode='outline' variant='secondary' />
    </Spacer>
  );
}
