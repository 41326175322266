import { ToggleRowProps } from './ToggleRow.decl';

import { Typography, Box, Toggle, Grid } from '@aircall/tractor-v2';

export function ToggleRow({
  value,
  title,
  description,
  onChange,
  'data-test': dataTest,
  size = 'regular',
  disabled,
}: ToggleRowProps) {
  return (
    <Grid
      display='inline-grid'
      columnGap={{ _: 's' }}
      p={size === 'regular' ? 'xs' : 's'}
      alignItems='center'
      gridAutoFlow='column'
      rowGap='xs'
      gridTemplateColumns='max-content auto'
      w='100%'
    >
      <Box gridRow={1}>
        <Toggle
          size='small'
          checked={value}
          onChange={onChange}
          data-test={dataTest}
          disabled={disabled}
        />
      </Box>
      <Box gridRow={1}>
        <Typography variant='bodyRegularM' color={disabled ? 'text-disabled' : 'text-base'}>
          {title}
        </Typography>
      </Box>
      {description && (
        <Typography variant='supportingRegularS' color='text-secondary' gridRow={2}>
          {description}
        </Typography>
      )}
    </Grid>
  );
}
