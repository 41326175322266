import React from 'react';

import {
  ApolloQueryResult,
  OperationVariables,
  QueryOptions,
  useApolloClient,
} from '@apollo/client';

export type UseImperativeQueryReturn<TVariables, TData> = (
  variables: TVariables
) => Promise<ApolloQueryResult<TData>>;

/**
 * Hook providing a function to use a query with the imperative API.
 * It allows to manually manage the call of the query.
 * @param options - options for the query
 * @returns a function receiving variables related to the query to trigger a GraphQL query
 */
export function useImperativeQuery<TData, TVariables = OperationVariables>(
  options: QueryOptions<TVariables, TData>
): UseImperativeQueryReturn<TVariables, TData> {
  const client = useApolloClient();

  return React.useCallback(
    (variables) => client.query<TData, TVariables>({ ...options, variables }),
    [client, options]
  );
}
