import { IntegrationFlowComplete } from '@components/IntegrationFlow/IntegrationFlowComplete/IntegrationFlowComplete';
import { INTEGRATIONS_ROUTE } from '@constants/routes.constants';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useTranslation } from 'react-i18next';

export function OauthAuthorizeSuccessPage() {
  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();

  return (
    <IntegrationFlowComplete
      submitButtonText={t('integration_flow.complete.go_back')}
      onSubmit={() => navigate(INTEGRATIONS_ROUTE)}
    />
  );
}
