import { gql } from '@apollo/client';

export const UPDATE_LINE_BUSINESS_HOURS_MUTATION = gql`
  mutation UpdateBusinessHours($lineID: ID!, $businessHours: BusinessHoursInput!) {
    updateLineBusinessHours(lineID: $lineID, businessHours: $businessHours) {
      availability
      timezone
      slots {
        days
        from
        to
      }
    }
  }
`;
