/* eslint-disable @typescript-eslint/no-explicit-any */
import { DependencyList, useCallback, useEffect, useRef } from 'react';

export type UseThrottledCallbackReturnType<T extends (...args: any[]) => any> = [
  (...args: Parameters<T>) => void,
  () => void
];

/**
 * Generates a callback that will be throttled
 * @param callback - Function to call
 * @param wait - Minimum delay between each call
 * @param deps - Dependencies of the callback
 */
export function useThrottledCallback<T extends (...args: any[]) => any>(
  callback: T,
  wait: number,
  deps: DependencyList
): UseThrottledCallbackReturnType<T> {
  const lastCall = useRef<null | Date>(null);
  const timeout = useRef<null | number>(null);
  const clear = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);
  // TODO: consider using lodash.throttle here
  // https://aircall-product.atlassian.net/browse/DAS-1159
  const throttledCallback = useCallback(
    (...args: unknown[]) => {
      clear();
      const now = new Date();
      // Elapsed time from the last call
      const elapsedTime = lastCall.current ? now.getTime() - lastCall.current.getTime() : wait;

      // If the callback has been called more than {wait}ms ago...
      if (elapsedTime >= wait) {
        // ...We call it immediately
        callback(...args);
        lastCall.current = now;
        // Otherwise, ...
      } else {
        // ...We postpone the call
        timeout.current = window.setTimeout(() => {
          callback(...args);
          lastCall.current = new Date();
        }, wait - elapsedTime);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, callback, wait, clear]
  );

  useEffect(() => () => clear(), [clear]);

  return [throttledCallback, clear];
}
