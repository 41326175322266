import { useMemo } from 'react';

import { TRANSLATION_PREFIX } from '../config';
import { DiagramBranch, DiagramCard } from '../FlowDiagram';
import { MoveItemHandler, MoveMenuItems } from '../MoveMenuItems';

import { MenuVerticalFilled } from '@aircall/icons';
import {
  Dropdown,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Grid,
  Box,
  Select,
} from '@aircall/tractor-v2';
import { TooltipOnTruncate } from '@components/TooltipOnTruncateV2';
import { UserAvatar } from '@components/UserAvatarV2';
import { USER_DETAIL_ROUTE } from '@constants/routes.constants';
import { getInitials } from '@dashboard/library';
import {
  NumberDetailQuery_numberDetail_dispatchGroups,
  NumberDetailQuery_numberDetail_dispatchGroups_dispatchable_User,
} from '@generated/NumberDetailQuery';
import { useDeleteDispatchGroup } from '@hooks/useDeleteDispatchGroup';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useSaveUserRingTimeout } from '@hooks/useSaveUserRingTimeout';
import { useTranslation } from 'react-i18next';

const userRingTimeoutArr = [10, 15, 20, 30, 40, 50, 60, 180, 300, 600, 900, 1800, 2400, 3600];

export interface DispatchGroupCardProps {
  numberId: string;
  group: NumberDetailQuery_numberDetail_dispatchGroups;
  currentIdx: number;
  onMoveup?: MoveItemHandler;
  onMovedown?: MoveItemHandler;
}

export function UserDispatchCard({
  numberId,
  group,
  onMoveup,
  onMovedown,
  currentIdx,
}: DispatchGroupCardProps) {
  const { t } = useTranslation();
  const user =
    group.dispatchable as NumberDetailQuery_numberDetail_dispatchGroups_dispatchable_User;
  const name = `${user?.firstName} ${user?.lastName}`;
  const initials = getInitials(user?.firstName, user?.lastName);
  const pictureUrl = user?.pictureUrl;

  const navigate = useNavigateWithParamsReplace();

  const { deleteDispatchGroup } = useDeleteDispatchGroup({
    numberId,
    groupId: group.id,
    name: t('number_details.tabs.call_distribution'),
  });

  const { value, onChange } = useSaveUserRingTimeout({
    user,
  });

  const durationUnitSec = t(`${TRANSLATION_PREFIX}.dispatch_cards.user.ring_unit`);
  const durationUnitMin = t(`${TRANSLATION_PREFIX}.dispatch_cards.user.ring_unit_minute`);

  const userRingTimeoutOptions = useMemo(
    () =>
      userRingTimeoutArr.map((item) => ({
        value: item.toString(),
        label: item > 60 ? `${item / 60} ${durationUnitMin}` : `${item} ${durationUnitSec}`,
      })),
    [durationUnitMin, durationUnitSec]
  );

  const [ringTimeoutSelectedKeys, ringTimeoutPlaceholder] = useMemo(() => {
    if (userRingTimeoutArr.includes(Number(value))) {
      return [[value], ''];
    }
    return [[], `${value} ${durationUnitSec}`];
  }, [durationUnitSec, value]);

  return (
    <DiagramBranch mb={24} isDraggable branchOffsetTop='28px'>
      <DiagramCard>
        <Grid
          data-test='user-dispatch-card'
          gridTemplateColumns='auto 2fr auto auto auto'
          columnGap='xxxs'
          alignItems='center'
          padding='xs'
          paddingRight='xxs'
        >
          <UserAvatar
            src={pictureUrl}
            disabled={false}
            id={`dispatch-group-${group.id}`}
            initials={initials}
            size='regular'
          />
          <Box overflow='hidden'>
            <TooltipOnTruncate title={name}>
              <Typography variant='bodySemiboldM' ellipsis={1}>
                {name}
              </Typography>
            </TooltipOnTruncate>
          </Box>
          <Typography>
            {t(`${TRANSLATION_PREFIX}.dispatch_cards.redirectionStrategy.title`)}
          </Typography>
          <Select
            takeTriggerWidth={false}
            size='small'
            options={userRingTimeoutOptions}
            onSelectionChange={(v) => {
              onChange(v[0] as string);
            }}
            selectionMode='single'
            selectedKeys={ringTimeoutSelectedKeys}
            placeholder={ringTimeoutPlaceholder}
          />
          <Dropdown
            trigger={
              <IconButton
                size='m'
                component={MenuVerticalFilled}
                color='neutral-400'
                data-test='user-dispatch-card-action-btn'
              />
            }
            side='bottom'
            align='start'
          >
            <Menu>
              {user && (
                <MenuItem onClick={() => navigate(USER_DETAIL_ROUTE, { userId: user?.id })}>
                  {t(`${TRANSLATION_PREFIX}.dispatch_cards.actions.go_to_user`)}
                </MenuItem>
              )}
              <MenuItem onClick={deleteDispatchGroup}>
                {t(`${TRANSLATION_PREFIX}.dispatch_cards.actions.delete`)}
              </MenuItem>
              <MoveMenuItems
                onMoveup={onMoveup}
                onMovedown={onMovedown}
                group={group}
                index={currentIdx}
              />
            </Menu>
          </Dropdown>
        </Grid>
      </DiagramCard>
    </DiagramBranch>
  );
}
