import { AnalyticsDashboard } from '@components/AnalyticsDashboard/AnalyticsDashboard';
import { AnalyticsDashboardType } from '@generated/globalTypes';
import { ANALYTICS_LIMITS } from '@helpers/analytics.helpers';
import { useTranslation } from 'react-i18next';

export function StatsInboundActivityProPage() {
  const { t } = useTranslation();

  return (
    <AnalyticsDashboard
      feedbackButtonEvent='inbound_activity_pro_feedback_button_clicked'
      minDate={ANALYTICS_LIMITS.sixMonthDate}
      pageHeaderTitle={t('stats.tabs.inbound_activity_pro')}
      title='inbound_activity_pro'
      type={AnalyticsDashboardType.INBOUND_ACTIVITY_PRO}
    />
  );
}
