import styled from 'styled-components';

import { Loading } from '@dashboard/library';
import { useAuthorizeZendesk } from '@hooks/useAuthorizeZendesk/useAuthorizeZendesk';

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export function ZendeskSSOPage() {
  useAuthorizeZendesk();

  return (
    <Container data-test='spinner-container'>
      <Loading />
    </Container>
  );
}
