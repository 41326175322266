import { useCallback } from 'react';

import { TimeSlotsFormProps, TimeSlotsFormValues } from './TimeSlotsForm.decl';

import { FormItem } from '@aircall/tractor-v2';
import { TimeSlotsField } from '@components/TimeSlotsField';
import { GridItem, GridLayout, PaperForm, SelectField, TimezoneSelect } from '@dashboard/library';
import { Availability } from '@generated/globalTypes';
import arrayMutators from 'final-form-arrays';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export function TimeSlotsForm({
  initialValues,
  onSubmit,
  title,
  businessHoursType,
  ...otherProps
}: TimeSlotsFormProps) {
  const { t } = useTranslation();

  const renderTimeSlots = useCallback((values: TimeSlotsFormValues) => {
    const { availability } = values;

    if (availability === Availability.CUSTOM) {
      return (
        <GridItem xs={12}>
          <TimeSlotsField slots={values.slots} />
        </GridItem>
      );
    }

    return null;
  }, []);

  const options = [
    {
      label: t(`business_hours.${businessHoursType}.${Availability.ALWAYS_OPENED}`),
      value: Availability.ALWAYS_OPENED,
    },
    {
      label: t(`business_hours.${businessHoursType}.${Availability.ALWAYS_CLOSED}`),
      value: Availability.ALWAYS_CLOSED,
    },
    {
      label: t(`business_hours.${businessHoursType}.custom`),
      value: Availability.CUSTOM,
    },
  ];

  return (
    <PaperForm
      title={title}
      shouldBlockNavigation
      formProps={{
        onSubmit,
        initialValues,
        mutators: { ...arrayMutators },
      }}
      {...otherProps}
    >
      {({ values }) => (
        <GridLayout>
          <GridItem xs={6}>
            <FormItem label={t('timeslots_form.timezone_label')}>
              <Field name='timezone'>
                {({ input: { value, onChange } }) => (
                  <TimezoneSelect
                    onChange={onChange}
                    shouldGuessTimezone
                    triggerStyle='select'
                    value={value}
                  />
                )}
              </Field>
            </FormItem>
          </GridItem>
          <GridItem xs={6}>
            <SelectField
              t={t}
              label={t('timeslots_form.availability_label')}
              name='availability'
              size='regular'
              options={options}
              data-test='availability-select'
            />
          </GridItem>
          {renderTimeSlots(values)}
        </GridLayout>
      )}
    </PaperForm>
  );
}
