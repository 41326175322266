import { gql } from '@apollo/client';

export const GET_COMPANY_BILLING_SUMMARY_QUERY = gql`
  query GetCompanyBillingSummaryQuery($includeCalculatedProperties: Boolean) {
    companyBillingSummary {
      ID
      paymentMethod
      isDelinquent
      plan
      subscriptionSummary(includeCalculatedProperties: $includeCalculatedProperties) {
        currency
      }
    }
  }
`;
