import React, { ComponentType } from 'react';

type ImportStatement<T> = () => Promise<{ default: T }>;

export interface ComponentWithPreload<T extends ComponentType<unknown>>
  extends React.LazyExoticComponent<T> {
  preload: () => Promise<{ default: T }>;
}

/**
 * Creates a lazy component that will be preloaded when it is rendered.
 * @param importStatement - The import statement to use to load the component.
 * @returns A lazy component that will be preloaded when it is rendered.
 */
const reactLazyPreload = <T extends ComponentType<unknown>>(
  importStatement: ImportStatement<T>
) => {
  const Component = React.lazy(importStatement) as ComponentWithPreload<T>;
  Component.preload = importStatement;
  return Component;
};

export default reactLazyPreload;
