/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

import {
  ClientException,
  getExternalErrorTranslationStrings,
  handleFormErrors,
} from '../../helpers/errors.helpers';

import { useGraphMutation } from './useGraphMutation';
import {
  FormErrorHandlingOptions,
  UseGraphMutationWithErrorFeedbackReturn,
  FormError,
  SnackbarErrorHandlingOptions,
  SnackbarError,
  ValidationSnackbarErrorHandlingOptions,
  ValidationSnackbarError,
  ErrorHandlingOptions,
  CustomMutateError,
  CustomMutate,
} from './useGraphMutationWithFeedback.decl';

import {
  DocumentNode,
  MutationHookOptions,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client';
import { useToast } from '@hooks/useToast';
import { useTracker } from '@hooks/useTracker';
import { FORM_ERROR } from 'final-form';
import { useTranslation } from 'react-i18next';

export function useGraphMutationWithFeedback<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  errorHandlingOptions: FormErrorHandlingOptions,
  mutationOptions?: MutationHookOptions<TData, TVariables>
): UseGraphMutationWithErrorFeedbackReturn<TData, TVariables, FormError>;

export function useGraphMutationWithFeedback<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  errorHandlingOptions: SnackbarErrorHandlingOptions,
  mutationOptions?: MutationHookOptions<TData, TVariables>
): UseGraphMutationWithErrorFeedbackReturn<TData, TVariables, SnackbarError>;

export function useGraphMutationWithFeedback<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  errorHandlingOptions: ValidationSnackbarErrorHandlingOptions,
  mutationOptions?: MutationHookOptions<TData, TVariables>
): UseGraphMutationWithErrorFeedbackReturn<TData, TVariables, ValidationSnackbarError>;

/**
 * Hook to execute a GraphQL mutations with feedback options for tracking purpose or to trigger a snackbar.
 * @param mutation - The mutation to execute.
 * @param errorHandlingOptions - An object that specify how the error should be handle.
 * @param mutationOptions - Option that apply to the mutation.
 * @returns A mutate function that can be called at any time to execute the mutation and an object with fields that represent the current status of the mutation's execution.
 */
export function useGraphMutationWithFeedback<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  errorHandlingOptions: ErrorHandlingOptions,
  mutationOptions?: MutationHookOptions<TData, TVariables>
): UseGraphMutationWithErrorFeedbackReturn<TData, TVariables, CustomMutateError> {
  const [mutate, mutationResult] = useGraphMutation(mutation, mutationOptions);
  const toast = useToast();
  const { track } = useTracker();
  const { t } = useTranslation();

  const customMutate: CustomMutate<TData, TVariables, CustomMutateError> = useCallback(
    async (mutateOptions, feedbackOptions = {}) => {
      const { successMessage, trackerProperties } = feedbackOptions;

      try {
        const data = await mutate(mutateOptions);

        if (successMessage) {
          toast.showToast({
            message: successMessage,
          });
        }

        if (trackerProperties) {
          track(trackerProperties);
        }

        return { data, error: undefined };
      } catch (e) {
        const err = e as ClientException;
        const { type, ...opts } = errorHandlingOptions;

        const error: CustomMutateError | undefined = handleFormErrors(err, opts);

        // eslint-disable-next-line default-case
        switch (type) {
          case 'snackbar':
            toast.showToast({
              message: t(getExternalErrorTranslationStrings(err.error)),
              variant: 'critical',
            });
            break;

          case 'form_snackbar': {
            if (error[FORM_ERROR]) {
              toast.showToast({
                message: t(error[FORM_ERROR]),
                variant: 'critical',
              });
            }
            break;
          }

          case 'form':
            break;
        }

        return { data: undefined, error };
      }
    },
    [mutate, toast, track, errorHandlingOptions, t]
  );

  return [customMutate, mutationResult];
}
