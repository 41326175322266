import { Box, getColor, getRadii } from '@aircall/tractor-v2';
import DraggableIcon from '@assets/icons/draggable.svg';
import { GridItem } from '@dashboard/library';
import styled, { css } from '@xstyled/styled-components';

export const DiagramCard = styled(Box)`
  position: relative;
  overflow: hidden;
  border: 1px solid ${getColor('neutral-200')};
  border-radius: ${getRadii(8)};
  background: white;

  > div {
    border-bottom: 1px solid ${getColor('neutral-200')};

    &:last-child {
      border-bottom: none;
    }
  }
`;
/* istanbul ignore next */
export const DiagramHeaderWrapper = styled(GridItem).attrs(() => ({
  xs: 12,
  md: 12,
  lg: 12,
  justifySelf: 'center',
}))`
  position: relative;
  margin-bottom: 20px;

  &::after {
    position: absolute;
    top: calc(100% - 8px);
    left: 50%;
    width: 0;
    height: 40px;
    border-left: 1px solid ${getColor('neutral-400')};
    content: '';
  }
`;

/* istanbul ignore next */
export const ColumnHeader = styled(Box)<{ isLeft: boolean }>`
  position: relative;
  display: grid;
  width: 100%;

  /* Draw a horizontal line on the header of the left column */

  &::before {
    position: absolute;
    top: 50%;
    left: ${({ isLeft }) => (isLeft ? '50%' : '0')};
    display: block;
    width: 50%;
    height: 80px;
    border-top: 1px solid ${getColor('neutral-400')};
    content: '';
  }
`;

export const ArrowWrapper = styled.div`
  > svg {
    overflow: visible;
    margin: 0 auto;
  }
  margin-bottom: 14.5px;
`;

const horizontalLineTop = '24px';
const verticalLineOffsetLeft = '12px';

export const DiagramBranch = styled(Box)<{ branchOffsetTop?: string; isDraggable?: boolean }>`
  position: relative;
  padding-left: 36px;

  /* Add a horizontal line */

  &::before {
    position: absolute;
    top: ${({ branchOffsetTop }) => branchOffsetTop || horizontalLineTop};
    left: ${verticalLineOffsetLeft};
    width: 16px;
    height: 1px;
    border-top: 1px solid ${getColor('neutral-400')};
    content: '';
  }

  ${({ isDraggable, branchOffsetTop }) =>
    isDraggable &&
    css`
      /* add boxShadow on the children div if draggable */

      > div {
        box-shadow: 0 1px 10px rgba(0 0 0 12%);
      }

      &:hover {
        /* add darker boxShadow on the children div when hover */

        > div {
          border: 1px solid ${getColor('neutral-500')};
          box-shadow: 0 8px 20px rgba(16 24 32 10%);
        }

        /* display draggable icon and darker borizontal line before the target when hover */

        &::before {
          width: 24px;
          border-top: 1px solid ${getColor('neutral-500')};
        }

        &::after {
          position: absolute;
          top: ${branchOffsetTop || horizontalLineTop};
          left: ${0};
          width: 24px;
          height: 24px;
          background-image: url('${DraggableIcon}');
          background-position: center;
          background-repeat: no-repeat;
          content: '';
          transform: translateY(-50%);
        }
      }
    `}
`;

/* istanbul ignore next */
export const DiagramContainer = styled(Box)<{ offsetTop: string; offsetBottom: string }>`
  position: relative;
  padding-right: 40px;

  /* Add a vertical line */

  &::before {
    position: absolute;
    top: ${({ offsetTop }) => offsetTop};
    left: ${verticalLineOffsetLeft};
    width: 1px;
    height: ${({ offsetTop, offsetBottom }) => `calc(100% - ${offsetTop} - ${offsetBottom} ) `};
    border-left: 1px solid ${getColor('neutral-400')};
    content: '';
  }
`;
