import { Component, ComponentType } from 'react';

import { logger } from '@config/logger.config';
import { WrapperProps } from '@dashboard/extension';
/**
 * Wraps an extension's wrapper that will bypass it if it
 * raises an exception
 * @param extensionName - name of the extension the wrapper comes from
 * @param wrapper - wrapper component
 */
export function applyWrapperErrorBoundary(
  extensionName: string,
  wrapper: ComponentType<WrapperProps>
) {
  const Wrapper = wrapper;
  return class WrapperErrorBoundary extends Component<
    { children: React.ReactNode },
    { hasError: boolean }
  > {
    constructor(props: any) {
      super(props);
      this.state = {
        hasError: false,
      };
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    componentDidCatch(error: Error) {
      logger.error(error.message, { error, extensionName });
    }

    render() {
      const { hasError } = this.state;
      const { children } = this.props;
      if (hasError) {
        return children;
      }

      return <Wrapper>{children}</Wrapper>;
    }
  };
}
