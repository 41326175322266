import { ModalDialogProps } from '@aircall/tractor-v2';

export interface AnalyticsPlusAdModalProps {
  funnelId?: string;
  onHide: VoidFunction;
  show: ModalDialogProps['show'];
}

export enum ANALYTICS_PLUS_AD_MODAL_TRACK_EVENTS {
  BUTTON_CLICKED = 'analytics_plus_funnel_button_clicked',
  BUTTON_SHOWN = 'analytics_plus_funnel_button_shown',
  POPUP_CLICKED = 'analytics_plus_funnel_popup_clicked',
}
