import { DeleteConfirmationModalProps } from './DeleteConfirmationModal.decl';

import {
  Banner,
  BannerHeading,
  BannerIcon,
  Button,
  Flex,
  ModalBody,
  ModalDialog,
  ModalFooter,
  Spacer,
  Typography,
} from '@aircall/tractor-v2';
import { useTranslation } from 'react-i18next';

export function DeleteConfirmationModal({
  card,
  confirmationText,
  isModalOpen,
  onDeleteClick,
  onCancelClick,
  error,
  isDeleteDisabled,
  bannerText,
}: DeleteConfirmationModalProps) {
  const { t } = useTranslation();

  return (
    <ModalDialog show={isModalOpen} onHide={onCancelClick} size='small'>
      <ModalBody p={0}>
        <Flex flexDirection='column' justifyContent='center' alignItems='center' py='l' px='m'>
          <Spacer direction='vertical' space='m' alignItems='center'>
            {card}
            <Typography variant='bodySemiboldM' textAlign='center' whiteSpace='break-spaces'>
              {confirmationText}
            </Typography>
            <Banner variant='warning' inline>
              <BannerIcon />
              <BannerHeading>
                {bannerText || t('app.delete_confirmation_modal.warning_banner')}
              </BannerHeading>
            </Banner>
          </Spacer>
        </Flex>
        {error && (
          <Banner variant='critical' inline>
            <BannerIcon />
            <BannerHeading>{t(error)}</BannerHeading>
          </Banner>
        )}
      </ModalBody>
      <ModalFooter>
        <Spacer space='xxs' alignItems='center'>
          <Button
            mode='ghost'
            variant='secondary'
            onClick={onCancelClick}
            data-test='modal-cancel-button'
          >
            {t('app.delete_confirmation_modal.buttons.cancel')}
          </Button>
          <Button
            disabled={isDeleteDisabled}
            onClick={onDeleteClick}
            data-test='modal-confirm-delete-button'
          >
            {t('app.delete_confirmation_modal.buttons.delete')}
          </Button>
        </Spacer>
      </ModalFooter>
    </ModalDialog>
  );
}
