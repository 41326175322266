import { useCallback, useMemo } from 'react';

import { LINK_ADDONS_CONFIG } from './linkAddonsConfig';
import type { LinkAddonsConfig, UseGetLinkAddonsConfigReturn } from './useGetLinkAddonsConfig.decl';

import { NormalizedCompanyAddon } from '@hooks/useCompanyAddons/useCompanyAddons.decl';
import { getActiveAddonTrials } from '@hooks/useCompanyAddons/utils';
import { useGetEnableTrialAddonsFeatureFlags } from '@hooks/useGetEnableTrialAddonsFeatureFlags';

export function useGetLinkAddonsConfig(): UseGetLinkAddonsConfigReturn {
  const { isAddonEnabled } = useGetEnableTrialAddonsFeatureFlags();

  const getConfig = useCallback(
    (dashboardSidebarLink: string, addons: NormalizedCompanyAddon[]) => {
      const linkAddonsConfig: LinkAddonsConfig | undefined =
        LINK_ADDONS_CONFIG[dashboardSidebarLink];

      const activeAddonTrials = getActiveAddonTrials(addons, isAddonEnabled);

      const addon = activeAddonTrials.find(
        (item) => item?.name?.toLowerCase() === linkAddonsConfig?.addonName?.toLowerCase()
      );

      if (linkAddonsConfig && addon) {
        return linkAddonsConfig;
      }
      return undefined;
    },
    [isAddonEnabled]
  );

  const isChildRouteIncluded = useCallback(
    (dashboardSidebarChildLink: string, config?: LinkAddonsConfig) =>
      Boolean(config?.includedChildren?.includes(dashboardSidebarChildLink)),
    []
  );

  const useGetLinkAddonsConfigReturn = useMemo(
    () => ({
      getConfig,
      isChildRouteIncluded,
    }),
    [getConfig, isChildRouteIncluded]
  );

  return useGetLinkAddonsConfigReturn;
}
