import { CUSTOM_FILE_FLOW } from './AudioSettingFile/AudioSettingFile.decl';

import { NumberDetailQuery_numberDetail_ttsMessages } from '@generated/NumberDetailQuery';
import { GenericFormError } from '@helpers/errors.helpers';
import { FormApi } from 'final-form';

export enum AUDIO_SETTING_TYPE {
  TEXT_TO_SPEECH = 'TEXT_TO_SPEECH',
  FILE = 'FILE',
  LIBRARY = 'LIBRARY',
}

export interface TextToSpeechAudioSetting {
  type: AUDIO_SETTING_TYPE.TEXT_TO_SPEECH;
  id: string; // TTS id
  category: string;
  track: string | null;
  language: string;
  message: string;
  voice: string;
  url: string;
}

export interface FileAudioSetting {
  type: AUDIO_SETTING_TYPE.FILE;
  file?: File;
  recordAudioName?: string;
}

export interface LibraryAudioSetting {
  type: AUDIO_SETTING_TYPE.LIBRARY;
  url: string;
}

export type AudioSetting = TextToSpeechAudioSetting | FileAudioSetting | LibraryAudioSetting;

export type AudioSettingFormValues = {
  audioSetting: AudioSetting;
  customFileFlow?: CUSTOM_FILE_FLOW;
  enabled?: boolean;
};

export interface AudioSettingModalProps {
  numberId: string;
  show: boolean;
  onHide: () => void;
  /*
   * Customize the title of the modal
   */
  title: string;
  /*
   * Customize the content displayed at the top of the modal
   */
  intro: JSX.Element;
  /*
   * Customize the text to display near a toggle
   */
  toggleLabel?: string;
  /*
   * Customize the label of the select
   */
  optionSelectLabel: string;

  /*
   * Indicate what options are available in the select
   */
  availableOptions: AUDIO_SETTING_TYPE[];

  /*
   * Allow to prefill the form with existing data
   */
  values?: AudioSettingFormValues;

  /*
   * Function to call when the confirm button is clicked
   */
  onSubmit: (
    values: AudioSettingFormValues,
    form: FormApi<AudioSettingFormValues, AudioSettingFormValues>
  ) => Promise<void | Record<string, string> | GenericFormError>;

  defaultTts: NumberDetailQuery_numberDetail_ttsMessages;
}

export type AUDIO_NAME =
  | 'ivrMessage'
  | 'ringingTone'
  | 'welcomeMessage'
  | 'missedCallMessage'
  | 'afterHoursMessage'
  | 'musicOnHold';

export interface AudioPlaybackRowProps {
  name: AUDIO_NAME;
  title: string;
  url: string;
  disabled?: boolean;
  showModal?: boolean;
  'data-test'?: string;
}

export interface AudioSettingModalContainerProps {
  show: boolean;
  onHide: () => void;
  title: string;
  name: AUDIO_NAME;
}

export type AUDIO_TYPE = 'music' | 'message';

export type AudioSettingFormData = {
  audioSettingFormValues: AudioSettingFormValues;
  audioType: AUDIO_TYPE;
};
