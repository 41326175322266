import { useLayoutEffect } from 'react';

import { CustomButton, TRANSLATION_PREFIX } from './AudioSettingFile.constants';
import { AudioSettingFileProps, CUSTOM_FILE_FLOW } from './AudioSettingFile.decl';
import { AudioSettingFileDropzone } from './AudioSettingFileDropzone';
import { AudioSettingFilePlayer } from './AudioSettingFilePlayer';
import { AudioSettingFileRecord } from './AudioSettingFileRecord';
import { useAudioFileStatus } from './useAudioFileStatus';

import { Grid } from '@aircall/tractor-v2';
import { useTranslation } from 'react-i18next';

export function AudioSettingFile({ onChange, file }: AudioSettingFileProps) {
  const { t } = useTranslation();
  const { audioStatus, isDone, isRecording, isUploading, setAudioStatus, shouldDisplaySwitch } =
    useAudioFileStatus(file);

  const handleStatusChange = (status: CUSTOM_FILE_FLOW) => setAudioStatus(status);

  useLayoutEffect(() => {
    if (!isRecording) {
      setAudioStatus(file ? CUSTOM_FILE_FLOW.DONE : CUSTOM_FILE_FLOW.UPLOAD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <>
      {shouldDisplaySwitch && (
        <Grid
          gridTemplateColumns='1fr 1fr'
          alignItems='center'
          backgroundColor='surface-default'
          borderWidth='1px'
          borderStyle='dashed'
          borderColor='neutral-400'
          borderBottom='none'
          borderRadius='8px 8px 0 0'
          data-test='audio-file-switch'
        >
          <CustomButton
            type='button'
            isActive={isUploading ? 'right' : undefined}
            onClick={() => setAudioStatus(CUSTOM_FILE_FLOW.UPLOAD)}
          >
            {t(`${TRANSLATION_PREFIX}.tabs.upload`)}
          </CustomButton>
          <CustomButton
            type='button'
            isActive={isRecording ? 'left' : undefined}
            onClick={() => setAudioStatus(CUSTOM_FILE_FLOW.RECORD_START)}
          >
            {t(`${TRANSLATION_PREFIX}.tabs.record`)}
          </CustomButton>
        </Grid>
      )}
      {isUploading && <AudioSettingFileDropzone onChange={onChange} />}
      {isRecording && (
        <AudioSettingFileRecord onRecordChange={handleStatusChange} recordStatus={audioStatus} />
      )}
      {isDone && file && <AudioSettingFilePlayer file={file} />}
    </>
  );
}
