import { SearchableSelectUserOption } from './SearchableSelectUserItem.decl';

import { Spacer, Typography } from '@aircall/tractor-v2';
import { UserAvatar } from '@components/UserAvatarV2';

export function SearchableSelectUserItem({
  value,
  label,
  initials,
  pictureURL,
  email,
}: SearchableSelectUserOption) {
  return (
    <Spacer space='xxs' alignItems='center' data-test={`searchable-select-user-item-${value}`}>
      <UserAvatar id={value.toString()} src={pictureURL} initials={initials} size='small' />
      <Typography variant='bodyRegularS'>{label}</Typography>
      {email && (
        <Typography variant='supportingMediumS' color='text-secondary'>
          {email}
        </Typography>
      )}
    </Spacer>
  );
}
