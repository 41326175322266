import React, { useContext, useMemo } from 'react';

import { AgentDataContext } from '../agent/AgentDataProvider';
import { GlobalDataContext } from '../global/GlobalProvider';

import { ModuleFederationProvider } from './ModuleFederationProvider';

import { FeaturesFlagsContext } from '@components/FeaturesFlagsProvider';
import { logger } from '@config/logger.config';
import {
  DashboardExtensionContextInterface,
  DashboardExtensionContextProvider,
} from '@dashboard/extension';
import { usePermissions } from '@hooks/usePermissions';
import { useReseller } from '@hooks/useReseller/useReseller';
import { useTracker } from '@hooks/useTracker/useTracker';

export function DashboardExtensionDataProvider({ children }: { children: React.ReactNode }) {
  const globalData = useContext(GlobalDataContext);
  const agentData = useContext(AgentDataContext);

  const { track } = useTracker();

  const availableData = globalData || agentData;
  const featureFlags = useContext(FeaturesFlagsContext);
  const { permissions, hasDashboardAccess } = usePermissions();
  const { isReseller } = useReseller();

  const extensionData: DashboardExtensionContextInterface | null = useMemo(() => {
    if (!availableData) {
      return null;
    }

    return {
      currentUser: {
        id: availableData.currentUser.ID,
        firstname: availableData.currentUser.firstName,
        lastname: availableData.currentUser.lastName,
        language: availableData.currentUser.language!,
        pictureUrl: availableData.currentUser.pictureURL ?? '',
      },
      currentCompany: {
        id: availableData.currentCompany.id.toString(),
        name: availableData.currentCompany.name,
        plan: availableData.currentCompany.plan,
        isTrial: availableData.isTrial,
        featuresAllowed: availableData.currentCompany.featuresAllowed,
        isReseller,
      },
      featureFlags: featureFlags as unknown as Record<string, boolean>,
      hasDashboardAccess,
      permissions,
      info: (message, payload) => logger.info(message, payload),
      error: (error, payload) => logger.error(error.message, payload),
      track: (event, payload) => track({ event, payload }),
    };
  }, [availableData, featureFlags, hasDashboardAccess, isReseller, permissions, track]);

  if (!extensionData) {
    // eslint-disable-next-line no-console
    console.error(
      'Global data and Agent Data not available. Make sure this Provider is has access to either GlobalProvider or AgentDataProvider'
    );
    return null;
  }
  return (
    <DashboardExtensionContextProvider value={extensionData}>
      <ModuleFederationProvider>{children}</ModuleFederationProvider>
    </DashboardExtensionContextProvider>
  );
}
