import { useCallback, useEffect, useState } from 'react';

import {
  IFRAME_SCROLL_TYPES,
  WorkatoEventData,
  WorkatoIframeInjestStyle,
} from './IntegrationFlowInstallWorkato.decl';

import { Button, Spacer, Typography, Flex } from '@aircall/tractor-v2';
import { useApolloClient } from '@apollo/client';
import { IntegrationFlowPage } from '@components/IntegrationFlowPage/IntegrationFlowPage';
import {
  APPLICATIONS_NAMES,
  WORKATO_CONNECTION_APPLICATION_NAME,
  WORKATO_MESSAGE_TYPES,
} from '@constants/integrations.constants';
import { INTEGRATION_FULL_ROUTES, INTEGRATIONS_ROUTE } from '@constants/routes.constants';
import { Loading } from '@dashboard/library';
import {
  CreateWorkatoCustomerMutation,
  CreateWorkatoCustomerMutationVariables,
} from '@generated/CreateWorkatoCustomerMutation';
import { getApplicationInstallUri } from '@generated/getApplicationInstallUri';
import { GetWorkatoConnectionUrlQuery } from '@generated/GetWorkatoConnectionUrlQuery';
import { CREATE_WORKATO_CUTOMER_MUTATION } from '@graphql/mutations/CreateWorkatoCustomerMutation';
import { COMPANY_INTEGRATIONS_QUERY } from '@graphql/queries/CompanyIntegrationsQuery';
import { GET_APPLICATION_INSTALL_URI_QUERY } from '@graphql/queries/getApplicationInstallUri';
import { GET_WORKATO_CONNECTION_URL_QUERY } from '@graphql/queries/GetWorkatoConnectionUrlQuery';
import { UnknownError } from '@helpers/errors.helpers';
import { safeJsonParse } from '@helpers/object.helpers';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useIntegrations } from '@hooks/useIntegrations';
import { useGraphLazyQuery } from '@hooks/useLazyQuery';
import { useGraphMutation } from '@hooks/useMutation';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useQueryParams } from '@hooks/useQueryParams';
import { useTracker } from '@hooks/useTracker/useTracker';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export function injectStyle(
  applicationName: WORKATO_CONNECTION_APPLICATION_NAME,
  workatoIframeShowOnlyConnectButton: boolean
): WorkatoIframeInjestStyle {
  const scrolling = IFRAME_SCROLL_TYPES.NO;
  const style = {
    border: 0,
    height: 250,
    width: '100%',
  };

  if (!workatoIframeShowOnlyConnectButton) {
    return { style, scrolling: IFRAME_SCROLL_TYPES.AUTO };
  }

  switch (applicationName) {
    case WORKATO_CONNECTION_APPLICATION_NAME.OUTREACH_V2:
      return {
        scrolling,
        style: {
          ...style,
          marginTop: '-1350px',
          height: 1500,
          width: '160px',
        },
      };
    case WORKATO_CONNECTION_APPLICATION_NAME.SALESFORCE:
      return {
        scrolling,
        style: {
          ...style,
          marginTop: '-490px',
          height: 630,
          width: '160px',
        },
      };
    case WORKATO_CONNECTION_APPLICATION_NAME.AIRCALL:
    case WORKATO_CONNECTION_APPLICATION_NAME.SALESLOFT_V2:
    default:
      return {
        scrolling,
        style: {
          ...style,
          height: 200,
          width: '160px',
        },
      };
  }
}

export function IntegrationFlowInstallWorkato() {
  const [error, setError] = useState<string | null>(null);
  const { applicationType, applicationName, applicationId } = useParams();
  const { searchParams } = useQueryParams();
  const hubOldIntegrationId = searchParams.get('hubOldIntegrationId');
  const { isLoading: applicationsLoading, getApplicationByName } = useIntegrations();
  const { t } = useTranslation();
  const { workatoIframeShowOnlyConnectButton } = useFeatures();
  const { currentCompany } = useGlobalData();

  const [mutate, { loading: createCustomerLoading }] = useGraphMutation<
    CreateWorkatoCustomerMutation,
    CreateWorkatoCustomerMutationVariables
  >(CREATE_WORKATO_CUTOMER_MUTATION, {
    onCompleted: () => {
      getWorkatoInstallUrl();
    },
  });

  const [getWorkatoInstallUrl, { data: installUrl, loading }] =
    useGraphLazyQuery<getApplicationInstallUri>(GET_APPLICATION_INSTALL_URI_QUERY, {
      fetchPolicy: 'network-only',
      variables: { applicationID: applicationId, hubOldIntegrationId },
      onCompleted: () => {
        getWorkatoConnectionUrl();
      },
    });

  const [getWorkatoConnectionUrl, { data: connectionUrl, loading: loadingConnectionUrl }] =
    useGraphLazyQuery<GetWorkatoConnectionUrlQuery>(GET_WORKATO_CONNECTION_URL_QUERY, {
      fetchPolicy: 'network-only',
      variables: {
        solutionInstanceId: installUrl?.getApplicationInstallUri?.installUri,
      },
    });

  const client = useApolloClient();
  const navigate = useNavigateWithParamsReplace();
  const { track } = useTracker();

  useEffect(() => {
    mutate({
      variables: {
        companyName: currentCompany.name,
      },
    });
  }, [currentCompany.name, mutate]);

  const workatoEventsHandler = useCallback(
    (event: MessageEvent) => {
      const eventData = safeJsonParse<WorkatoEventData>(event.data);

      if (!eventData) {
        return;
      }

      if (
        eventData.type === WORKATO_MESSAGE_TYPES.CONNECTION_STATUS_CHANGE &&
        eventData.payload.connected
      ) {
        if (connectionUrl?.getWorkatoConnectionUrl?.isConnectionRemaining) {
          getWorkatoConnectionUrl();
        } else {
          client.refetchQueries({ include: [COMPANY_INTEGRATIONS_QUERY] });

          track({
            event: 'integration_created',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            payload: { application_name: applicationName },
          });
          navigate(
            `${INTEGRATION_FULL_ROUTES.FLOW_CREATE}?solution_instance_id=${installUrl?.getApplicationInstallUri?.installUri}`,
            { applicationName, applicationType, applicationId },
            { replace: true }
          );
        }
      }

      if (eventData.type === WORKATO_MESSAGE_TYPES.ERROR) {
        setError(eventData.payload.message);
      }
    },
    [
      connectionUrl?.getWorkatoConnectionUrl?.isConnectionRemaining,
      getWorkatoConnectionUrl,
      client,
      track,
      applicationName,
      navigate,
      installUrl?.getApplicationInstallUri?.installUri,
      applicationType,
      applicationId,
    ]
  );

  useEffect(() => {
    window.addEventListener('message', workatoEventsHandler);
    return () => window.removeEventListener('message', workatoEventsHandler);
  }, [workatoEventsHandler]);

  const handleCancel = () => navigate(INTEGRATIONS_ROUTE);

  if (
    applicationsLoading ||
    loading ||
    loadingConnectionUrl ||
    createCustomerLoading ||
    !connectionUrl?.getWorkatoConnectionUrl
  ) {
    return <Loading data-test='data-layout-loading' />;
  }

  if (error) {
    throw new UnknownError('An error has happened while installing the integration', {
      error,
    });
  }

  const application = getApplicationByName(applicationName as APPLICATIONS_NAMES);

  const {
    url,
    note,
    description,
    title,
    applicationName: connectionApplicationName,
  } = connectionUrl.getWorkatoConnectionUrl;

  return (
    <IntegrationFlowPage
      title={application?.name}
      avatarSrc={application?.logo}
      paperTitle={title}
      data-test='workato-install'
      paperChildren={
        <Spacer space='s' direction='vertical' data-test='connection-details-container'>
          <Typography textAlign='center' data-test='connection-details-description'>
            {description}
          </Typography>
          {note && (
            <Typography whiteSpace='pre-wrap' data-test='connection-details-note'>
              {note}
            </Typography>
          )}
        </Spacer>
      }
    >
      <Flex justifyContent='center' padding='m' overflow='hidden'>
        <iframe
          data-test='workato-iframe'
          title='workato-iframe'
          src={url}
          seamless
          {...injectStyle(
            connectionApplicationName as WORKATO_CONNECTION_APPLICATION_NAME,
            workatoIframeShowOnlyConnectButton
          )}
        />
      </Flex>

      <Flex justifyContent='end' padding='s'>
        <Button
          onClick={handleCancel}
          data-test='install-integration-cancel-button'
          mode='outline'
          size='small'
        >
          {t('integration_flow.install.buttons.cancel')}
        </Button>
      </Flex>
    </IntegrationFlowPage>
  );
}
