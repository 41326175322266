import { DownloadExtractProps } from './DownloadExtract.decl';

import { useGraphMutation, LoadingButton } from '@dashboard/library';
import { useToast } from '@hooks/useToast';
import { useTranslation } from 'react-i18next';

const EXTRACT_TOAST_ID = 'extract-toast';

export function DownloadExtract<T>({ mutation, getUrl }: DownloadExtractProps<T>) {
  const { t } = useTranslation();
  const toasts = useToast();

  const [mutate, { loading }] = useGraphMutation<T>(mutation, {
    showErrorToast: t,
  });

  async function handleDownload() {
    toasts.showToast({
      id: EXTRACT_TOAST_ID,
      message: t('download_extract.toast_message'),
      dismissIn: undefined,
      variant: 'info',
      icon: true,
    });

    const res = await mutate();

    toasts.removeToast(EXTRACT_TOAST_ID);

    if (!res.data) {
      return;
    }

    const fileUrl = getUrl(res.data);

    if (!fileUrl) {
      return;
    }

    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = 'Download.csv';
    a.click();
    a.remove();
  }

  return (
    <LoadingButton
      data-test='download-extract'
      mode='outline'
      variant='secondary'
      size='small'
      onClick={handleDownload}
      loading={loading}
      loadingText=''
    >
      {t('download_extract.button')}
    </LoadingButton>
  );
}
