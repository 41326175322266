import { SearchableSelectUserItem } from '../SearchableSelectUserItem/SearchableSelectUserItem';

import { SearchableSelectUserProps } from './SearchableSelectUser.decl';

import { SearchableSelectUserOption } from '@components/SearchableSelectUserItem';
import { SingleSearchSelect } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function SearchableSelectUser<
  TOption extends SearchableSelectUserOption = SearchableSelectUserOption
>({ onChange, value, ...rest }: SearchableSelectUserProps<TOption>) {
  const { t } = useTranslation();

  const renderItem = (option: TOption) => <SearchableSelectUserItem {...option} />;
  const handleOnChange = (keys: TOption['value'][]) => {
    onChange(keys?.[0]);
  };
  return (
    <SingleSearchSelect<TOption>
      texts={{
        placeholder: t('app.roles.user'),
        result: t('generic.result'),
        results: t('generic.results'),
      }}
      isSearch
      triggerStyle='select'
      onSelect={handleOnChange}
      selectedKeys={value ? [value] : []}
      w='100%'
      renderItem={renderItem}
      {...rest}
    />
  );
}
