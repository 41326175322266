import { NUMBERS_TAB_URL_TYPE } from './Numbers.decl';

import {
  NUMBERS_ROUTE_TAB_ACTIVE,
  NUMBERS_ROUTE_TAB_UNVERIFIED,
} from '@constants/routes.constants';

export const initialPage = 1;

export const gridItemCounts = [1, 1, 2, 3, 4];

export const NUMBERS_TAB_URLS: NUMBERS_TAB_URL_TYPE = {
  active: NUMBERS_ROUTE_TAB_ACTIVE,
  unverified: NUMBERS_ROUTE_TAB_UNVERIFIED,
};

export enum NUMBER_ACTIVATION_STATE {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum NUMBERS_TABS {
  ACTIVE = 'active',
  UNVERIFIED = 'unverified',
}

export const availableTabs: string[] = Object.values(NUMBERS_TABS);
