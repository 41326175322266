import { useCallback, useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { DataLayout } from '@components/DataLayout/DataLayout';
import { IntegrationFlowPage } from '@components/IntegrationFlowPage/IntegrationFlowPage';
import { APPLICATIONS_NAMES } from '@constants/integrations.constants';
import { INTEGRATIONS_ROUTE, INTEGRATION_FULL_ROUTES } from '@constants/routes.constants';
import { Loading } from '@dashboard/library';
import { ApplicationsListQuery_getApplications } from '@generated/ApplicationsListQuery';
import { IntegrationInstallUriQuery } from '@generated/IntegrationInstallUriQuery';
import { INTEGRATION_INSTALL_URI_QUERY } from '@graphql/queries/IntegrationInstallUriQuery';
import { UnknownError } from '@helpers/errors.helpers';
import { useIntegrations } from '@hooks/useIntegrations';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useTracker } from '@hooks/useTracker/useTracker';
import { useParams } from 'react-router-dom';

export enum TRAY_MESSAGES_TYPES {
  ERROR = 'tray.configPopup.error',
  CANCEL = 'tray.configPopup.cancel',
  FINISH = 'tray.configPopup.finish',
}

export function IntegrationFlowInstallTray() {
  const [error, setError] = useState<string | null>(null);
  const { applicationType, applicationName, applicationId } = useParams();
  const { isLoading: applicationsLoading, getApplicationByName } = useIntegrations();
  const client = useApolloClient();
  const navigate = useNavigateWithParamsReplace();
  const { track } = useTracker();

  const trayEventsHandler = useCallback(
    (event: MessageEvent) => {
      if (event.data.type === TRAY_MESSAGES_TYPES.ERROR) {
        return setError(event.data.err);
      }

      if (event.data.type === TRAY_MESSAGES_TYPES.CANCEL) {
        return navigate(INTEGRATIONS_ROUTE);
      }

      if (event.data.type === TRAY_MESSAGES_TYPES.FINISH) {
        // We invalidate the cache for integrations to re-fetch and display the new tray integration we just installed
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'companyIntegrations' });

        const { solutionInstanceId } = event.data.data;

        track({
          event: 'integration_created',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          payload: { application_name: applicationName },
        });

        return navigate(
          `${INTEGRATION_FULL_ROUTES.FLOW_CREATE}?solution_instance_id=${solutionInstanceId}`,
          { applicationName, applicationType, applicationId },
          { replace: true }
        );
      }

      return undefined;
    },
    [navigate, applicationName, applicationType, applicationId, client.cache, track]
  );

  useEffect(() => {
    window.addEventListener('message', trayEventsHandler);
    return () => window.removeEventListener('message', trayEventsHandler);
  }, [trayEventsHandler]);

  if (applicationsLoading) {
    return <Loading data-test='data-layout-loading' />;
  }

  if (error) {
    throw new UnknownError('An error has happened while installing the integration', { error });
  }

  const application = getApplicationByName(applicationName as APPLICATIONS_NAMES);
  const hubApplication = application as ApplicationsListQuery_getApplications;

  return (
    <DataLayout<IntegrationInstallUriQuery>
      query={INTEGRATION_INSTALL_URI_QUERY}
      queryOptions={{ variables: { applicationId }, fetchPolicy: 'network-only' }}
    >
      {({ data }) => (
        <IntegrationFlowPage title={hubApplication.name} avatarSrc={application?.logo}>
          <iframe
            data-test='tray-iframe'
            title='tray-iframe'
            src={data?.integrationInstallUri.current?.installUri}
            frameBorder='0'
            width='100%'
            height={700}
          />
        </IntegrationFlowPage>
      )}
    </DataLayout>
  );
}
