import { useCallback } from 'react';

import { AudioPlaybackRow } from '../AudioSetting/AudioPlaybackRow';
import { TRANSLATION_PREFIX } from '../config';
import { DiagramToggleCard } from '../DiagramToggleCard/DiagramToggleCard';
import { DiagramBranch, DiagramCard } from '../FlowDiagram';
import { GroupSectionTitle } from '../GroupSectionTitle';

import { HangUpFilled } from '@aircall/icons';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { NumberDetailQuery_numberDetail } from '@generated/NumberDetailQuery';
import { useUpdateNumberToggles } from '@pages/number-detail-settings/hooks/useUpdateNumberToggles';
import { useTranslation } from 'react-i18next';

export interface AfterhourSectionProps {
  numberDetail: NumberDetailQuery_numberDetail;
}

export function AfterhourSection({ numberDetail }: AfterhourSectionProps) {
  const { t } = useTranslation();
  const { afterHoursMessage, afterHoursVoicemail } = numberDetail;
  const { updateNumberToggles } = useUpdateNumberToggles({});

  const handleChangeVoicemail = useCallback(() => {
    const newAfterHoursVoicemail = !afterHoursVoicemail;
    updateNumberToggles(
      { afterHoursVoicemail: newAfterHoursVoicemail },
      {
        successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
          name: t(`${TRANSLATION_PREFIX}.after_hours_section.voicemail_option`),
        }),
      }
    );
  }, [updateNumberToggles, afterHoursVoicemail, t]);

  return (
    <>
      <DiagramBranch mb='m'>
        <DiagramCard>
          <AudioPlaybackRow
            name='afterHoursMessage'
            title={t(`${TRANSLATION_PREFIX}.after_hours_section.title`)}
            url={afterHoursMessage}
            data-test='after_hours'
          />
        </DiagramCard>
      </DiagramBranch>
      <DiagramBranch mb='m'>
        <DiagramToggleCard
          data-test='afterhour-toggle'
          onChange={handleChangeVoicemail}
          title={t(`${TRANSLATION_PREFIX}.after_hours_section.voicemail`)}
          tooltip={t(`${TRANSLATION_PREFIX}.after_hours_section.voicemail_tooltip`)}
          toggleValue={afterHoursVoicemail}
        />
      </DiagramBranch>
      <DiagramBranch branchOffsetTop='10px'>
        <GroupSectionTitle
          title={t(`${TRANSLATION_PREFIX}.hangs_up_automatically`)}
          icon={HangUpFilled}
        />
      </DiagramBranch>
    </>
  );
}
