import { SearchableSelectTeamOption } from './SearchableSelectTeamItem.decl';

import { Spacer, Typography } from '@aircall/tractor-v2';
import { EmojiAvatar } from '@dashboard/library';

export function SearchableSelectTeamItem({ value, label, imageName }: SearchableSelectTeamOption) {
  return (
    <Spacer space='xxs' alignItems='center'>
      {imageName && (
        <EmojiAvatar size='regular' name={imageName} data-test={`emoji-${value}-${imageName}`} />
      )}
      <Typography variant='bodyRegularS'>{label}</Typography>
    </Spacer>
  );
}
