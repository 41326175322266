import { MenuVerticalFilled } from '@aircall/icons';
import { Dropdown, Flex, IconButton, Menu, MenuItem, Typography } from '@aircall/tractor-v2';
import { useTranslation } from 'react-i18next';

interface DeletedNumberBannerProps {
  id: string;
  onEdit: () => void;
  onDelete: () => void;
}

export function DeletedNumberBanner({ id, onEdit, onDelete }: DeletedNumberBannerProps) {
  const { t } = useTranslation();

  return (
    <Flex alignItems='center' justifyContent='space-between' px='xs'>
      <Typography>
        {t(
          'number_details.settings.call_distribution_section.diagram.ivr_settings.number_deleted_message'
        )}
      </Typography>
      <Dropdown
        trigger={<IconButton size='m' component={MenuVerticalFilled} color='neutral-400' />}
        side='bottom'
        align='start'
      >
        <Menu>
          <MenuItem onClick={onDelete}>
            {t(
              'number_details.settings.call_distribution_section.diagram.dispatch_cards.actions.delete'
            )}
          </MenuItem>
          <MenuItem onClick={onEdit} data-test={`ivr-option-menu-item-edit-${id}`}>
            {t(
              'number_details.settings.call_distribution_section.diagram.ivr_settings.actions.edit'
            )}
          </MenuItem>
        </Menu>
      </Dropdown>
    </Flex>
  );
}
