import { ProgressBarProps } from '@components/ProgressBar';

export interface OptimisticProgressBarProps
  extends Pick<ProgressBarProps, 'width' | 'progress' | 'height' | 'color'> {
  /** The speed we want the bar to progress when the actual progress is not updated (in percents/seconds) */
  speed: number;

  /**
   * The maximum increase from the actual progress we allow.
   * Example: if capping = 10 and actual progress = 40, the optimistic progress won't go beyond 50.
   */
  capping: number;

  /**
   * The fake progress will never go over maxCapping
   */
  maxCapping: number;
}

export enum PROGRESS_ACTION_TYPE {
  INCREASE = 'INCREASE',
  UPDATE = 'UPDATE',
}

export interface ProgressIncreaseAction {
  type: PROGRESS_ACTION_TYPE.INCREASE;
  value: number;
  actualProgress: number;
  capping: number;
  maxCapping: number;
}

export interface ProgressUpdateAction {
  type: PROGRESS_ACTION_TYPE.UPDATE;
  value: number;
}

export type ProgressAction = ProgressIncreaseAction | ProgressUpdateAction;
