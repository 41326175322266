import { useCallback, useMemo } from 'react';

import { DateSelectValue } from '@dashboard/library';
import {
  DEFAULT_VALUE,
  RANGES,
  UseDateFilter,
  UseDateFilterProps,
  isDateRangeWithinLimits,
} from '@pages/looker-v2/Filters/DateFilter';
import { useCustomDateState } from '@pages/looker-v2/Filters/state/useCustomDateState';
import { endOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const useDateFilter = ({
  maxDateRangeInDays,
  minDate,
}: UseDateFilterProps): UseDateFilter => {
  const { t } = useTranslation();
  const { customDate, setCustomDate } = useCustomDateState();

  const ranges = useMemo(
    () =>
      RANGES.filter((opt) =>
        isDateRangeWithinLimits(opt.startDate, opt.endDate, maxDateRangeInDays, minDate)
      ).map((opt) => ({
        ...opt,
        label: t(`filters.date.${opt.label}`),
      })),
    [maxDateRangeInDays, minDate, t]
  );

  const onChange = useCallback(
    (value: DateSelectValue) => {
      const rangeValue = value?.rangeValue ?? null;
      const from = value?.startDate ?? null;
      const to = value?.endDate ? endOfDay(value.endDate) : null;

      setCustomDate({ rangeValue, from, to });
    },
    [setCustomDate]
  );

  const onClear = useCallback(() => {
    setCustomDate({
      rangeValue: DEFAULT_VALUE.rangeValue,
      from: DEFAULT_VALUE.startDate,
      to: DEFAULT_VALUE.endDate,
    });
  }, [setCustomDate]);

  const value = useMemo(
    () => ({
      rangeValue: customDate.rangeValue ?? undefined,
      startDate: customDate.from,
      endDate: customDate.to,
    }),
    [customDate]
  );

  return { onChange, onClear, ranges, value };
};
