import { NUMBER_DETAIL_FRAGMENT } from '../fragments/NumberDetailFragment';

import { gql } from '@apollo/client';

export const UPDATE_NUMBER_MUTATION = gql`
  mutation UpdateNumberMutation($input: UpdateNumberInput!, $numberId: Int!) {
    updateNumber(numberId: $numberId, input: $input)
      @rest(type: "Number", path: "{args.numberId}", method: "PATCH", endpoint: "v4NumberDetail") {
      ...NumberDetailFragment
    }
  }
  ${NUMBER_DETAIL_FRAGMENT}
`;
