import { useUpdateNumberToggles } from '../hooks/useUpdateNumberToggles';

import { Spacer } from '@aircall/tractor-v2';
import { RouterLink } from '@components/Link';
import { ToggleRow } from '@components/ToggleRow';
import { UpgradePlanButton } from '@components/UpgradePlanButton/UpgradePlanButton';
import { CALLS_CHILD_ROUTE } from '@constants/routes.constants';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { List, ListItem, Paper } from '@dashboard/library';
import { Trans, useTranslation } from 'react-i18next';

interface CallTaggingProps {
  disabled: boolean;
}

export function CallTagging({ disabled }: CallTaggingProps) {
  const { t } = useTranslation();
  const { updateNumberToggles, number } = useUpdateNumberToggles({});
  return (
    <Paper
      title={t('number_details.settings.call_tagging.title')}
      subtitle={
        <Trans
          i18nKey={
            disabled
              ? 'number_details.settings.call_tagging.description_disabled'
              : 'number_details.settings.call_tagging.description_enabled'
          }
        >
          <RouterLink to={CALLS_CHILD_ROUTE.TAGS} data-test='see-all-tags-button' />
        </Trans>
      }
      disabled={disabled}
    >
      <Spacer direction='vertical' space='m' fluid>
        <List>
          <ListItem h='auto'>
            <ToggleRow
              size='large'
              data-test='mandatory-tagging-activated-toggle'
              value={!!number?.mandatoryTaggingActivated}
              onChange={(mandatoryTaggingActivated) =>
                updateNumberToggles(
                  { mandatoryTaggingActivated },
                  {
                    successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
                      name: t(`number_details.settings.call_tagging.title`),
                    }),
                    trackingOptions: { section: 'Settings', setting: 'Call Tagging' },
                  }
                )
              }
              title={t('number_details.settings.call_tagging.mandatory_call_tagging')}
              disabled={disabled}
            />
          </ListItem>
        </List>
        {disabled && <UpgradePlanButton mode='outline' variant='primary' />}
      </Spacer>
    </Paper>
  );
}
