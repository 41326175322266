import { DeleteNumberMutation_deleteNumber as DeleteNumberResponse } from '@generated/DeleteNumberMutation';

export default async function deleteNumberTransformer(
  res: Response
): Promise<DeleteNumberResponse> {
  const response = await res.json();

  return {
    __typename: 'Number',
    id: response.data.id,
  };
}
