import { useMemo } from 'react';

import { ManagePaymentMethodLink } from './ManagePaymentMethodLink';
import { PaymentAlertProps } from './PaymentAlert.decl';

import {
  Typography,
  Banner,
  BannerIcon,
  BannerHeading,
  BannerSuffix,
  Button,
} from '@aircall/tractor-v2';
import { PERMISSIONS_ACTIONS, RESOURCE } from '@constants/permissions.constants';
import { useCompanyBillingSummary } from '@hooks/useCompanyBillingSummary/useCompanyBillingSummary';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { usePermissions } from '@hooks/usePermissions';
import { Trans, useTranslation } from 'react-i18next';

export function PaymentAlert({ 'data-test': dataTest }: Readonly<PaymentAlertProps>) {
  const { checkAccess } = usePermissions();

  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();

  const isOwner = checkAccess(PERMISSIONS_ACTIONS.READ, RESOURCE.COMPANY_BILLING);
  const isAdmin = checkAccess(PERMISSIONS_ACTIONS.READ, RESOURCE.INTEGRATIONS);

  const { data } = useCompanyBillingSummary();
  const plan = data?.companyBillingSummary?.plan?.toLowerCase();
  const paymentMethod = data?.companyBillingSummary?.paymentMethod;
  const isDelinquent = data?.companyBillingSummary?.isDelinquent;

  /**
   * @returns True if the plan is Paid, otherwise false.
   */
  const isPaidPlan = useMemo(
    () =>
      Boolean(
        plan &&
          ![
            'friend',
            'trial',
            'trial_partner',
            'trial_transition',
            'partner',
            'custom_partner_essentials',
            'custom_partner_premium',
          ].includes(plan)
      ),
    [plan]
  );

  const showDelinquentBanner = isDelinquent && (isOwner || isAdmin);
  const showNoPaymentMethodConfiguredBanner = !paymentMethod && isPaidPlan;

  let bannerContent;

  if (showDelinquentBanner) {
    bannerContent = (
      <Trans
        i18nKey={isOwner ? 'delinquent_banner.banner_text' : 'delinquent_banner.banner_text_admin'}
        components={[
          <Typography key={0} variant='bodySemiboldS' fontWeight={700} as='span' />,
          <ManagePaymentMethodLink key={1} />,
        ]}
      />
    );
  } else if (showNoPaymentMethodConfiguredBanner) {
    bannerContent = isOwner ? (
      <Trans
        i18nKey='delinquent_banner.banner_text_no_payment_method'
        components={[
          <Typography key={0} variant='bodySemiboldS' fontWeight={700} as='span' />,
          <ManagePaymentMethodLink key={1} />,
        ]}
      />
    ) : (
      <Trans
        i18nKey='delinquent_banner.banner_text_no_payment_method_admin_supervisor'
        components={[<ManagePaymentMethodLink key={0} />]}
      />
    );
  }

  if (!bannerContent) {
    return null;
  }

  return (
    <Banner variant='critical' data-test={dataTest}>
      <BannerIcon />
      <BannerHeading>{bannerContent}</BannerHeading>
      {isOwner && (
        <BannerSuffix>
          <Button
            onClick={() => navigate('/account/billing')}
            mode='outline'
            shape='default'
            size='xSmall'
            variant='critical'
            data-test='manage-payment-method-button'
          >
            {t(
              isDelinquent
                ? 'delinquent_banner.banner_cta_delinquent'
                : 'delinquent_banner.banner_cta_no_payment_method'
            )}
          </Button>
        </BannerSuffix>
      )}
    </Banner>
  );
}
