/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useMemo, useState } from 'react';

import { PLANS_MODAL_TRACK_EVENTS, PlanInterface, PriceInterface } from './PlansModal.decl';
import countries from './pricing/countries.json';
import { pricing } from './pricing/pricing.constants';

import { PeopleFilled, TickOutlined } from '@aircall/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  ModalBody,
  ModalDialog,
  Spacer,
  Tag,
  Typography,
} from '@aircall/tractor-v2';
import { ACCOUNT_ROUTE_TAB, ACCOUNT_TABS } from '@constants/routes.constants';
import { formatLocalizedCurrency } from '@dashboard/library';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useTracker } from '@hooks/useTracker/useTracker';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';

export function generatePlans(
  t: TFunction<'translation', undefined>,
  {
    enableStarterPlan,
    oldTrialRest,
  }: {
    enableStarterPlan: boolean;
    oldTrialRest: boolean;
  }
): PlanInterface[] {
  const showTestVariant = enableStarterPlan;

  let plans = [
    {
      slug: 'essentials',
      color: 'text-info',
      name: t('onboarding.pricing.plans.essentials.name'),
      description: t('onboarding.pricing.plans.essentials.description'),
      featuresTitle: enableStarterPlan
        ? t('onboarding.pricing.plans.essentials.features_title')
        : t('onboarding.pricing.plans.starter.features_title'),
      features: [
        oldTrialRest
          ? t('onboarding.pricing.plans.essentials.features_rest.1')
          : t('onboarding.pricing.plans.essentials.features.1'),
        t('onboarding.pricing.plans.essentials.features.2'),
        t('onboarding.pricing.plans.essentials.features.3'),
        t('onboarding.pricing.plans.essentials.features.4'),
        t('onboarding.pricing.plans.essentials.features.5'),
        t('onboarding.pricing.plans.essentials.features.6'),
        t('onboarding.pricing.plans.essentials.features.7'),
      ],
      featuresDescription: '',
      featuresDescription2: '',
    },
    {
      slug: 'professional',
      color: 'decorative1-600',
      name: t('onboarding.pricing.plans.professional.name'),
      description: t('onboarding.pricing.plans.professional.description'),
      featuresTitle: t('onboarding.pricing.plans.professional.features_title'),
      features: [
        t('onboarding.pricing.plans.professional.features.1'),
        t('onboarding.pricing.plans.professional.features.2'),
        t('onboarding.pricing.plans.professional.features.3'),
        t('onboarding.pricing.plans.professional.features.4'),
        t('onboarding.pricing.plans.professional.features.5'),
        t('onboarding.pricing.plans.professional.features.6'),
        t('onboarding.pricing.plans.professional.features.7'),
        t('onboarding.pricing.plans.professional.features.8'),
      ],
      featuresDescription: '',
      featuresDescription2: '',
    },
  ];
  if (showTestVariant) {
    plans = [
      {
        slug: 'starter',
        color: 'text-interactive-primary',
        name: t('onboarding.pricing.plans.starter.name'),
        description: t('onboarding.pricing.plans.starter.description'),
        featuresTitle: t('onboarding.pricing.plans.starter.features_title'),
        features: [
          oldTrialRest
            ? t('onboarding.pricing.plans.starter.features_rest.1')
            : t('onboarding.pricing.plans.starter.features.1'),
          oldTrialRest
            ? ((
                <Trans
                  i18nKey='onboarding.pricing.plans.starter.features_rest.2'
                  components={{ sup: <sup /> }}
                />
              ) as unknown as string)
            : t('onboarding.pricing.plans.starter.features.2'),
          t('onboarding.pricing.plans.starter.features.3'),
          t('onboarding.pricing.plans.starter.features.4'),
          t('onboarding.pricing.plans.starter.features.5'),
          t('onboarding.pricing.plans.starter.features.6'),
        ],
        featuresDescription: t('onboarding.pricing.plans.starter.features_description'),
        featuresDescription2: (
          <Trans
            i18nKey='onboarding.pricing.plans.starter.features_description_2'
            components={{ sup: <sup /> }}
          />
        ) as unknown as string,
      },
      ...plans,
    ];
  }

  return plans;
}

function generatePrices(country: string): PriceInterface {
  const selectedCountry = Object.values(countries).find(
    (c: any) => c.code === country.toLowerCase()
  ) || {
    region: 0,
  };
  const areaIndex = selectedCountry.region;
  const area = pricing[areaIndex];

  return {
    starter: {
      annually: formatLocalizedCurrency(area.plans.starter.annually, area.currencyName),
      monthly: formatLocalizedCurrency(area.plans.starter.monthly, area.currencyName),
    },
    essentials: {
      annually: formatLocalizedCurrency(area.plans.essentials.annually, area.currencyName),
      monthly: formatLocalizedCurrency(area.plans.essentials.monthly, area.currencyName),
    },
    professional: {
      annually: formatLocalizedCurrency(area.plans.professional.annually, area.currencyName),
      monthly: formatLocalizedCurrency(area.plans.professional.monthly, area.currencyName),
    },
  };
}

export function PlansModal() {
  const [show, setShow] = useState(false);
  const [timeShown, setTimeShown] = useState(new Date());

  const { t } = useTranslation();
  const { dashboardShowStarterControl, enableStarterPlan, showOnboardingTrial } = useFeatures();
  const { currentCompany, currentUser } = useGlobalData();
  const { track } = useTracker();
  const navigate = useNavigateWithParamsReplace();
  const [pricingModalShown, setPricingModalShown] = useLocalStorage('pricingModalShown', false);

  const country = currentCompany.address?.country || 'US';
  const isUSOrCanada = country === 'US' || country === 'CA';
  const newTrialNAM = showOnboardingTrial && isUSOrCanada;
  const oldTrialNAM = !showOnboardingTrial && isUSOrCanada;
  const oldTrialRest = !showOnboardingTrial && !isUSOrCanada;
  let variant = 'old_trial_rest';
  if (newTrialNAM) {
    variant = 'new_trial_us';
  } else if (oldTrialNAM) {
    variant = 'old_trial_us';
  }
  const testVariant = enableStarterPlan;
  const controlVariant = !enableStarterPlan && dashboardShowStarterControl;

  const prices: any = useMemo(() => generatePrices(country), [country]);
  const plans = generatePlans(t, {
    enableStarterPlan,
    oldTrialRest,
  });

  const onHideModal = () => {
    setPricingModalShown(true);
    setShow(false);
    const duration = (new Date().getTime() - timeShown.getTime()) / 1000;
    track({
      event: PLANS_MODAL_TRACK_EVENTS.MODAL_DISMISSED,
      payload: {
        duration,
        variant,
        company_id: currentCompany.id,
        user_id: currentUser.ID,
        control_variant: controlVariant,
        test_variant: testVariant,
      },
    });
  };
  const onContinueTrialing = () => {
    track({
      event: PLANS_MODAL_TRACK_EVENTS.CONTINUE_TRIALING_CLICKED,
      payload: {
        variant,
        company_id: currentCompany.id,
        user_id: currentUser.ID,
        control_variant: controlVariant,
        test_variant: testVariant,
      },
    });

    onHideModal();
  };
  const onUpgradeNow = () => {
    track({
      event: PLANS_MODAL_TRACK_EVENTS.UPGRADE_NOW_CLICKED,
      payload: {
        variant,
        company_id: currentCompany.id,
        user_id: currentUser.ID,
        control_variant: controlVariant,
        test_variant: testVariant,
      },
    });

    onHideModal();

    navigate(ACCOUNT_ROUTE_TAB, { tab: ACCOUNT_TABS.YOUR_PLAN });
  };

  useEffect(() => {
    const alreadyShown = Boolean(pricingModalShown);

    let defaultShow = false;
    if ((testVariant && !alreadyShown) || (controlVariant && !alreadyShown)) {
      defaultShow = true;
      setTimeShown(new Date());

      track({
        event: PLANS_MODAL_TRACK_EVENTS.MODAL_SHOWN,
        payload: {
          country,
          variant,
          company_id: currentCompany.id,
          user_id: currentUser.ID,
          show_onboarding_trial: showOnboardingTrial,
          control_variant: controlVariant,
          test_variant: testVariant,
        },
      });
    }

    setShow(defaultShow);
  }, [
    currentCompany,
    currentUser,
    pricingModalShown,
    country,
    dashboardShowStarterControl,
    enableStarterPlan,
    showOnboardingTrial,
    track,
    variant,
    testVariant,
    controlVariant,
  ]);

  return (
    <ModalDialog
      data-test='plan-modal'
      show={show}
      onHide={onHideModal}
      rootClose={false}
      size='large'
    >
      <ModalBody padding='s'>
        <Flex
          backgroundColor='base.white'
          flexDirection='column'
          alignItems='center'
          px={{
            _: 's',
            '2xl': 'xl',
          }}
        >
          <Typography data-test='heading' variant='headingMediumM' textAlign='center'>
            {newTrialNAM
              ? t('onboarding.pricing.new_trial_us.heading')
              : t('onboarding.pricing.heading')}
          </Typography>
          <Spacer
            direction='vertical'
            space={{ _: 'xxxs', '2xl': 'xxs' }}
            mt={{ _: 'xxxs', '2xl': 'xxs' }}
          >
            <Typography data-test='subheading' variant='bodyRegularS' textAlign='center'>
              <Trans
                i18nKey={
                  oldTrialNAM
                    ? 'onboarding.pricing.old_trial_us.subheading'
                    : 'onboarding.pricing.subheading'
                }
                components={{ strong: <strong /> }}
              />
            </Typography>
            {oldTrialNAM ? (
              <Typography data-test='subheading-2' variant='bodyRegularS' textAlign='center'>
                <Trans
                  i18nKey='onboarding.pricing.subheading_2'
                  components={{ strong: <strong /> }}
                />
              </Typography>
            ) : undefined}
          </Spacer>

          <Box
            backgroundColor='white'
            borderColor='rgba(0, 0, 0, 0.20)'
            borderStyle='solid'
            borderWidth={1}
            borderRadius={8}
            mt={{ _: 'xxs', '2xl': 's' }}
          >
            <Flex flexDirection='row' gap='m' px='m'>
              {plans.map((plan) => (
                <Flex
                  data-test={`plan-${plan.slug}`}
                  key={plan.slug}
                  flexDirection='column'
                  py={{ _: 'xxs', '2xl': 's' }}
                >
                  <Flex flexDirection='row' justifyContent='space-between'>
                    <Typography
                      data-test={`${plan.slug}-name`}
                      variant='headingBoldS'
                      color={plan.color}
                    >
                      {plan.name}
                    </Typography>
                    {plan.slug === 'starter' ? (
                      <Tag variant='blue' data-test='early-access' size='small'>
                        {t('onboarding.pricing.plans.starter.early_access')}
                      </Tag>
                    ) : undefined}
                  </Flex>

                  <Typography variant='supportingSemiboldS' mt={{ _: 'xxxs', '2xl': 'xxs' }}>
                    {plan.description}
                  </Typography>

                  <Flex
                    flexDirection='row'
                    alignItems='center'
                    py={{ _: 'xxs', '2xl': 'xs' }}
                    gap='xxs'
                  >
                    <Typography
                      data-test={`${plan.slug}-price`}
                      variant='headingBoldL'
                      color={plan.color}
                    >
                      {prices[plan.slug].annually}
                    </Typography>
                    <Flex flexDirection='column'>
                      <Typography variant='bodyRegularS'>
                        {t('onboarding.pricing.billing.annually')}
                      </Typography>
                      <Typography variant='bodyRegularS' data-test={`${plan.slug}-monthly-price`}>
                        {t('onboarding.pricing.billing.monthly', {
                          price: prices[plan.slug].monthly,
                        })}
                      </Typography>
                    </Flex>
                  </Flex>

                  <Spacer direction='vertical' space={{ _: 'xxs', '2xl': 's' }}>
                    <Flex flexDirection='row' gap='xxs' alignItems='center'>
                      <PeopleFilled color={plan.color} />

                      <Typography variant='bodyRegularS'>
                        <Trans
                          i18nKey={`onboarding.pricing.plans.${plan.slug}.users_limit`}
                          components={{ strong: <strong /> }}
                        />
                      </Typography>
                    </Flex>

                    <Divider
                      orientation='horizontal'
                      backgroundColor='rgba(0, 0, 0, 0.20)'
                      size='xSmall'
                    />

                    <Typography variant='bodySemiboldS'>{plan.featuresTitle}</Typography>

                    <Spacer direction='vertical' space={{ _: 'xxxs', '2xl': 'xs' }}>
                      {plan.features.map((feature) => (
                        <Flex key={feature} flexDirection='row' gap='xxxs'>
                          <Box minWidth='m' minHeight='m'>
                            <TickOutlined color='text-interactive-primary' />
                          </Box>
                          <Typography variant='bodyRegularS'>{feature}</Typography>
                        </Flex>
                      ))}
                    </Spacer>
                  </Spacer>

                  {plan.featuresDescription ? (
                    <Typography
                      data-test='features-description'
                      variant='supportingRegularS'
                      color='text-disabled'
                      marginTop={{ _: 'xxxs', '2xl': 's' }}
                    >
                      {plan.featuresDescription}
                    </Typography>
                  ) : undefined}
                  {plan.featuresDescription2 && oldTrialRest ? (
                    <Typography
                      data-test='features-description-2'
                      variant='supportingRegularS'
                      color='text-disabled'
                    >
                      {plan.featuresDescription2}
                    </Typography>
                  ) : undefined}
                </Flex>
              ))}
            </Flex>
          </Box>

          <Spacer direction='horizontal' space='m' mt={{ _: 'xs', '2xl': 's' }}>
            <Button size='small' data-test='continue-trialing-btn' onClick={onContinueTrialing}>
              {t('onboarding.pricing.continue_trialing')}
            </Button>

            <Button size='small' data-test='upgrade-now-btn' onClick={onUpgradeNow}>
              {t('onboarding.pricing.upgrade_now')}
            </Button>
          </Spacer>
        </Flex>
      </ModalBody>
    </ModalDialog>
  );
}
