import { AnalyticsDashboardType } from '@generated/globalTypes';
import { AnalyticsPlusAddonPage } from '@pages/analytics-plus-addon';
import { useTranslation } from 'react-i18next';

export function StatsMonitoringPage() {
  const { t } = useTranslation();

  return (
    <AnalyticsPlusAddonPage
      pageHeaderTitle={t('stats.tabs.monitoring')}
      title='monitoring'
      type={AnalyticsDashboardType.MONITORING}
    />
  );
}
