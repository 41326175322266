import { useCallback, useEffect } from 'react';

import {
  AuthorizeZendeskQuery,
  AuthorizeZendeskQueryVariables,
} from '@generated/AuthorizeZendeskQuery';
import { AUTHORIZE_ZENDESK_QUERY } from '@graphql/mutations/AuthorizeZendeskQuery';
import { useImperativeQuery } from '@hooks/useImperativeQuery/useImperativeQuery';
import { useQueryParams } from '@hooks/useQueryParams';
import { useToast } from '@hooks/useToast';
import { useTranslation } from 'react-i18next';

export function useAuthorizeZendesk(): () => Promise<void> {
  const { t } = useTranslation();
  const { searchParams } = useQueryParams();

  const query = useImperativeQuery<AuthorizeZendeskQuery, AuthorizeZendeskQueryVariables>({
    query: AUTHORIZE_ZENDESK_QUERY,
  });

  const toast = useToast();

  const onSubmit = useCallback(async () => {
    try {
      const response = await query({
        returnTo: searchParams.get('return_to') ? `return_to=${searchParams.get('return_to')}` : '',
      });
      window.location.replace(response.data!.authorizeZendesk.redirectTo);
    } catch (err) {
      toast.showToast({
        variant: 'critical',
        message: t('generic_errors.other.unknown_error'),
      });
      setTimeout(() => {
        window.history.go(-1);
      }, 2000);
    }
  }, [query, searchParams, t, toast]);

  useEffect(() => {
    onSubmit();
    // Should run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return onSubmit;
}
