import { useAddNumberIntegrations } from '../hooks/useAddNumberIntegrations';
import { useUnconnectedIntegrations } from '../hooks/useUnconnectedIntegrations';

import { Grid } from '@aircall/tractor-v2';
import { IntegrationCard } from '@components/IntegrationCard/IntegrationCard';
import { Loading, ModalForm } from '@dashboard/library';
import { CompanyIntegrationsQuery_companyIntegrations_data } from '@generated/CompanyIntegrationsQuery';
import arrayMutators from 'final-form-arrays';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

type FieldsType = FieldArrayRenderProps<string, HTMLElement>['fields'];
const renderIntegrationCards = ({
  fields,
  options,
}: {
  fields: FieldsType;
  options: CompanyIntegrationsQuery_companyIntegrations_data[];
}) => {
  const onClick = (checked: boolean, id: string) => {
    if (checked) {
      fields.push(id);
    } else {
      const index = fields.value.indexOf(id);
      fields.remove(index);
    }
  };

  return options.map((option) => (
    <IntegrationCard
      key={option.id}
      onClick={onClick}
      item={option}
      checked={fields.value && fields.value.includes(option.id)}
    />
  ));
};

export function NumberDetailIntegrationsModal({
  show,
  onHide,
  connectedIntegrationsId,
}: {
  show: boolean;
  onHide: () => void;
  connectedIntegrationsId: string[];
}) {
  const { bridgeIntegrations, hubIntegrations, loading } =
    useUnconnectedIntegrations(connectedIntegrationsId);
  const { t } = useTranslation();
  const { submitNewIntegrations } = useAddNumberIntegrations({ onCompleted: onHide });

  return (
    <ModalForm
      show={show}
      onHide={onHide}
      title={t('number_details.integrations.connected_integrations.add_integration')}
      formProps={{
        mutators: {
          ...arrayMutators,
        },
        onSubmit: submitNewIntegrations,
        initialValues: { bridgeIntegrationsId: [], hubIntegrationsId: [] },
        keepDirtyOnReinitialize: true,
      }}
    >
      {() => (
        <>
          {loading && <Loading data-test='number-integration-list-loader' />}
          <Grid gridTemplateColumns='repeat(3, minmax(0, 1fr))' gap='s'>
            <FieldArray name='hubIntegrationsId'>
              {({ fields }) => renderIntegrationCards({ fields, options: hubIntegrations })}
            </FieldArray>
            <FieldArray name='bridgeIntegrationsId'>
              {({ fields }) => renderIntegrationCards({ fields, options: bridgeIntegrations })}
            </FieldArray>
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
