import { IvrSettingModal, IvrSettingFormValues } from '../IvrSettingModal';

import { PlusOutlined } from '@aircall/icons';
import { Icon, Typography, Box, getColor } from '@aircall/tractor-v2';
import { useAddIvrSetting } from '@hooks/useAddIvrSetting';
import { useNumberDetail } from '@hooks/useNumberDetail';
import { useToggle } from '@hooks/useToggle/useToggle';
import styled from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';

type AddOptionVariant = 'primary' | 'secondary';
interface AddOptionButtonProps {
  text: string;
  variant: AddOptionVariant;
  onClick?: () => void;
}

const IconWrapper = styled(Box)`
  width: 24px;
  height: 24px;
  padding: 0;
  border: 1px solid ${getColor('neutral-1000')};
  border-radius: 8px;
  background-color: ${getColor('neutral-1000')};

  &,
  svg {
    color: surface-default;
    transition: background 200ms, transform 200ms;
  }
`;

const IconButtonWrapper = styled.button<{ variant: AddOptionVariant }>`
  display: flex;
  width: max-content;
  align-items: center;
  padding: ${({ variant }) => (variant === 'secondary' ? '13px 0' : '0')};
  border: none;
  background-color: unset;
  cursor: pointer;

  &:hover {
    > :first-child {
      background-color: surface-default;

      svg {
        color: ${getColor('neutral-1000')};
      }
    }

    > div:last-child {
      text-decoration: underline;
    }
  }
`;

export function AddOptionButton({ onClick, text, variant }: AddOptionButtonProps) {
  return (
    <IconButtonWrapper
      data-test={`${variant}-ivr-add-option-button`}
      onClick={onClick}
      type='button'
      variant={variant}
    >
      <IconWrapper>
        <Icon component={PlusOutlined} size={22} />
      </IconWrapper>
      <Typography pl='xs'>{text}</Typography>
    </IconButtonWrapper>
  );
}

interface AddOptionProps {
  keyOptions: { label: string; value: string }[];
  text: string;
}

export function AddOption({ keyOptions, text }: AddOptionProps) {
  const { number } = useNumberDetail();
  const { t } = useTranslation();
  const [show, _, open, close] = useToggle(false);
  const { addIvrSetting } = useAddIvrSetting({ numberId: number!.id, onCompleted: close });

  const modalValues: IvrSettingFormValues = { key: keyOptions[0].value };

  const variant = keyOptions[0].value === 'fallback' ? 'secondary' : 'primary';

  return (
    <>
      <IvrSettingModal
        show={show}
        onHide={close}
        values={modalValues}
        title={t(
          'number_details.settings.call_distribution_section.ivr_setting_modal.creation_title'
        )}
        keyOptions={keyOptions}
        countryCode={number!.iso3166Alpha2}
        onSubmit={addIvrSetting}
        numberId={number!.id}
      />
      <AddOptionButton onClick={open} text={text} variant={variant} />
    </>
  );
}
