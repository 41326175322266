import { gql } from '@apollo/client';

export const BLACKLISTED_NUMBERS_QUERY = gql`
  query BlacklistedNumbersQuery($page: Int = 1, $order: String = "asc") {
    blacklistedNumbers(page: $page, order: $order)
      @rest(
        type: "BlacklistedNumbers"
        path: "blacklisted_phone_numbers?page={args.page}&order={args.order}"
        endpoint: "v3"
      ) {
      blacklistedPhoneNumbers {
        id
        phoneNumber
      }
      meta {
        perPage
        currentPage
        nextPage
        previousPage
        total
      }
    }
  }
`;
