import { COMPANY_FRAGMENT } from '../fragments/CompanyFragment';

import { gql } from '@apollo/client';
import { USER_FULL_FRAGMENT } from '@graphql/fragments/UserFragment';

export const AGENT_DATA_QUERY = gql`
  query AgentDataQuery {
    getAgentV2 {
      ...UserFullFragment
    }
    currentCompany @rest(type: "RestCompany", path: "company", endpoint: "v3") {
      ...CompanyFragment
    }
  }
  ${USER_FULL_FRAGMENT}
  ${COMPANY_FRAGMENT}
`;
