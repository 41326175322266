import React, { useCallback, useEffect, useRef } from 'react';

import styled from 'styled-components';

import { DraggableItemProps } from './Draggble.decl';

import { useScrollYItem } from '@hooks/useScrollYItem';
import { AxisBox2D, BoxDelta, motion } from 'framer-motion';

// Spring configs
const onTop = { zIndex: 1 };
const flat = {
  zIndex: 0,
  transition: { delay: 0.3 },
};

const StyledLi = styled(motion.li)`
  position: relative;
  user-select: none;
`;

export const DraggableItem = React.memo(
  ({
    onPositionChange,
    onDrag,
    index,
    children,
    onDragStart,
    onDragEnd,
    draggable = true,
  }: DraggableItemProps) => {
    const { isDragging, y, startDragging, stopDragging, resetTranslateY } = useScrollYItem();

    // We'll use a `ref` to access the DOM element that the `motion.li` produces.
    // This will allow us to measure its height and position, which will be useful to
    // decide when a dragging element should switch places with its siblings.
    const ref = useRef<HTMLLIElement>(null);

    // Update the measured position of the item so we can calculate when we should rearrange.
    useEffect(() => {
      onPositionChange(index, {
        height: ref.current!.offsetHeight,
        top: ref.current!.offsetTop,
      });
    }, [index, onPositionChange]);

    const handleDragStart = useCallback(() => {
      startDragging();
      onDragStart?.();
      /* If there's an opened dropdown in the draggableItem,
      when the item is dragged, we can make dropdown closed by clicking on the the document */
      document.body.click();
    }, [onDragStart, startDragging]);

    const handleDragEnd = useCallback(() => {
      stopDragging();
      onDragEnd?.(index);
    }, [index, onDragEnd, stopDragging]);

    return (
      <StyledLi
        data-test='draggable-item-container'
        layout
        ref={ref}
        initial={false}
        style={{ y }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        whileHover={{ cursor: draggable ? 'grab' : 'not-allowed' }}
        whileTap={{ cursor: draggable ? 'grabbing' : 'not-allowed' }}
        // If we're dragging, we want to set the zIndex of that item to be on top of the other items.
        animate={isDragging ? onTop : flat}
        drag={draggable ? 'y' : false}
        dragConstraints={{ top: 0, bottom: 0 }}
        dragElastic={1}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onViewportBoxUpdate={(_viewportBox: AxisBox2D, delta: BoxDelta) => {
          if (isDragging) {
            onDrag(index, delta.y.translate + y.get(), resetTranslateY);
          }
        }}
      >
        {children}
      </StyledLi>
    );
  }
);
