import { ComponentType, SVGProps } from 'react';

import styled from 'styled-components';

import { ONBOARDING_STATUS } from './Onboarding.decl';

import { TickOutlined, ChevronRightOutlined } from '@aircall/icons';
import { Avatar, Typography, Spacer, Flex, useColor, Icon } from '@aircall/tractor-v2';
import { CSSScalar } from '@xstyled/styled-components';
import { NavLink } from 'react-router-dom';

type CreateResourceLinkProps = {
  title: string;
  text: string;
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  link: string;
  status: ONBOARDING_STATUS;
};

const CustomAvatar = styled(Avatar)<{ bgColor: CSSScalar }>`
  border-color: ${({ bgColor }) => bgColor};
  background-color: ${({ bgColor }) => bgColor};

  &::after {
    background-color: ${({ bgColor }) => bgColor};
  }
`;

export function CreateResourceLink({ link, title, text, icon, status }: CreateResourceLinkProps) {
  const [defaultColor, primary100, primary300, primary700, neutral300, neutral600] = [
    useColor('neutral-500'),
    useColor('primary-100'),
    useColor('primary-300'),
    useColor('primary-700'),
    useColor('neutral-300'),
    useColor('neutral-600'),
  ];
  const BorderColorOptions = {
    default: defaultColor,
    complete: primary300,
    active: primary300,
  } as const;

  const BGColorOptions = {
    default: 'white',
    complete: primary100,
    active: 'white',
  };

  const avatarBGColor = status === ONBOARDING_STATUS.COMPLETE ? primary300 : neutral300;
  const avatarIcon = status === ONBOARDING_STATUS.COMPLETE ? TickOutlined : icon;
  const avatarColor = status === ONBOARDING_STATUS.COMPLETE ? 'white' : neutral600;
  const detailColor = status === ONBOARDING_STATUS.COMPLETE ? primary700 : 'black';
  const borderColor = BorderColorOptions[status];
  const backgroundColor = BGColorOptions[status];
  return (
    <NavLink to={link} state={{ from: '/onboarding' }} data-test='resource-link'>
      <Flex
        padding='l'
        borderStyle='solid'
        alignItems='center'
        justifyContent='space-between'
        borderColor={borderColor}
        borderWidth='1px'
        borderRadius='sm'
        backgroundColor={backgroundColor}
      >
        <Flex>
          <CustomAvatar bgColor={avatarBGColor} size='large'>
            <Icon color={avatarColor} component={avatarIcon} />
          </CustomAvatar>
          <Spacer
            space='xs'
            direction='vertical'
            justifyContent='start'
            borderWidth='1px'
            marginLeft='22px'
          >
            <Typography variant='bodySemiboldM' color={detailColor}>
              {title}
            </Typography>
            <Typography variant='bodyRegularS' color={detailColor}>
              {text}
            </Typography>
          </Spacer>
        </Flex>
        <Icon color={detailColor} component={ChevronRightOutlined} />
      </Flex>
    </NavLink>
  );
}
