import { useLookerPage } from '../useLookerPage';

import { DateFilter } from './DateFilter';
import { FiltersProps } from './Filters.decl';
import { TimezoneFilter } from './TimezoneFilter';

import { RefreshOutlined } from '@aircall/icons';
import { Box, Button, Flex, Spacer, Tooltip, Typography } from '@aircall/tractor-v2';
import { Emoji } from 'emoji-mart';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export function Filters({ loading }: FiltersProps) {
  const { getState } = useForm();
  const { errors } = getState();
  const { t } = useTranslation();
  const { handleFiltersReset } = useLookerPage();
  const hasErrors = errors && Object.keys(errors).length > 0;

  return (
    <Box display='flex' data-test='analytics-filters-section' px='xl' w='100%'>
      {!loading && (
        <Flex
          alignSelf='center'
          pt='m'
          pb='xxxs'
          justifyContent='space-between'
          w='100%'
          alignItems='center'
        >
          <Spacer alignItems='end' space='s'>
            <DateFilter />
            <TimezoneFilter />
          </Spacer>
          <Spacer alignItems='center' space='s'>
            <Button
              data-test='analytics-filters-reset'
              mode='ghost'
              onClick={handleFiltersReset}
              size='small'
              type='button'
              variant='secondary'
            >
              <Typography variant='bodyMediumS'>{t('analytics.reset')}</Typography>
            </Button>
            <Button
              data-test='analytics-filters-submit'
              disabled={hasErrors}
              size='small'
              type='submit'
            >
              <RefreshOutlined />
              <Typography variant='bodyMediumS'>{t('analytics.update')}</Typography>
            </Button>
            <Tooltip
              content={
                <Flex flexDirection='column'>
                  <Typography variant='bodySemiboldS'>
                    {t('analytics.work_in_progress.title')}
                  </Typography>
                  <Typography variant='bodyMediumS'>
                    {t('analytics.work_in_progress.text')}
                  </Typography>
                </Flex>
              }
              maxW={494}
              side='left'
            >
              <Flex data-test='work-in-progress-trigger'>
                <Emoji set='apple' emoji='construction' size={16} />
              </Flex>
            </Tooltip>
          </Spacer>
        </Flex>
      )}
    </Box>
  );
}
