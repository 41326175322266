import React from 'react';

import styled from 'styled-components';

import { ArrowSendOutlined } from '@aircall/icons';
import {
  Flex,
  Icon as TractorIcon,
  Typography,
  Button,
  ButtonProps,
  getColor,
  getSpace,
} from '@aircall/tractor-v2';

export type DiscoverCardProps = {
  heading: string;
  subHeading: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  handleClick: () => void;
};

const DiscoverButton = styled(Button)<ButtonProps>`
  &:hover,
  &:focus,
  &:active {
    background: ${getColor('surface-default')};
  }
  width: 100%;
  height: 76px;
  padding: ${getSpace('s')};
  border: 1px solid ${getColor('surface-interactive-neutral')};
  background: ${getColor('surface-default')};
  text-align: left;
`;

export function DiscoverCard({
  heading,
  subHeading,
  icon: Icon,
  handleClick,
}: Readonly<DiscoverCardProps>) {
  return (
    <DiscoverButton onClick={handleClick}>
      <Flex flexDirection='row' justifyContent='space-between' w='100%'>
        <Flex my='auto'>
          <Icon data-test='icon' style={{ height: '32px', width: '32px' }} />
        </Flex>
        <Flex flexDirection='column' ml='s'>
          <Typography variant='headingMediumXS' mb='5px' color='text-base'>
            {heading}
          </Typography>
          <Typography variant='bodyRegularM' color='text-secondary'>
            {subHeading}
          </Typography>
        </Flex>
        <Flex ml='auto' my='auto'>
          <TractorIcon
            data-test='arrow-icon'
            component={ArrowSendOutlined}
            size={30}
            color='icon-disabled'
          />
        </Flex>
      </Flex>
    </DiscoverButton>
  );
}
