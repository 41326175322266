import { useEffect, useRef } from 'react';

import { logger } from '@config/logger.config';
import { gainsight } from '@constants/gainsight.constants';
import { loadGainsightScript } from '@helpers/gainsight.helpers';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';

export const useInitGainsight = (): void => {
  const { enableGainsightEvents, ...featureFlags } = useFeaturesFlags();
  const { currentUser, currentCompany, isTrial } = useGlobalData();
  const isGainsightInitialized = useRef(false);

  useEffect(() => {
    if (isGainsightInitialized.current) {
      return;
    }

    if (!enableGainsightEvents) {
      return;
    }

    if (!currentUser || !currentCompany) {
      return;
    }

    const identifyGainsight = () => {
      try {
        gainsight.identify(currentUser, currentCompany, isTrial, featureFlags);
      } catch (e) {
        logger.error('Gainsight | Identify  | Error', { errorData: e });
      }
    };

    isGainsightInitialized.current = true;
    loadGainsightScript()
      .then(() => {
        identifyGainsight();
      })
      .catch((e) => {
        logger.error('Gainsight | Script Load | Error', { errorData: e });
        isGainsightInitialized.current = false;
      });
  }, [
    enableGainsightEvents,
    currentUser,
    currentCompany,
    isTrial,
    featureFlags,
    isGainsightInitialized,
  ]);
};
