import { useRef } from 'react';

import { Typography } from '@aircall/tractor-v2';
import { PhoneInputField } from '@components/PhoneInputField/PhoneInputField';
import { PhoneNumber } from '@components/PhoneNumber';
import {
  Paper,
  Loading,
  pipeValidators,
  validatePhoneNumberFormat,
  validateUniquePhoneNumber,
  AddButton,
  Gap,
  List,
  ListItem,
  InlineForm,
  useGraphQueryPaginated,
} from '@dashboard/library';
import {
  BlacklistedNumbersQuery,
  BlacklistedNumbersQueryVariables,
  BlacklistedNumbersQuery_blacklistedNumbers_blacklistedPhoneNumbers,
} from '@generated/BlacklistedNumbersQuery';
import {
  DeleteBlacklistedNumberMutation,
  DeleteBlacklistedNumberMutationVariables,
} from '@generated/DeleteBlacklistedNumberMutation';
import { BlacklistedNumberInput } from '@generated/globalTypes';
import { DELETE_BLACKLISTED_NUMBER_MUTATION } from '@graphql/mutations/DeleteBlacklistedNumber';
import { BLACKLISTED_NUMBERS_QUERY } from '@graphql/queries/BlacklistedNumbersQuery';
import { formatInternational } from '@helpers/phoneNumber.helpers';
import { useAddBlockedNumber } from '@hooks/useAddBlockedNumber';
import { useDeleteEntity } from '@hooks/useDeleteEntity/useDeleteEntity';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useToggle } from '@hooks/useToggle/useToggle';
import { useTracker } from '@hooks/useTracker';
import { useTranslation } from 'react-i18next';

// External function for testing purpose
export function handleFetchMore(d: BlacklistedNumbersQuery) {
  return {
    page: d.blacklistedNumbers.meta.nextPage,
  };
}

export const BLACKLIST_NUMBERS_ORDER = 'desc';

export function BlockedNumbersSection() {
  const { t } = useTranslation();
  const { track } = useTracker();
  const listScrollRef = useRef<HTMLUListElement>(null);
  const [shouldShowAddPhoneNumberRow, _, showAddPhoneNumberRow, onCloseClick] = useToggle();
  const { currentCompany } = useGlobalData();

  const { data, loading } = useGraphQueryPaginated<
    BlacklistedNumbersQuery,
    BlacklistedNumbersQueryVariables
  >({
    query: BLACKLISTED_NUMBERS_QUERY,
    queryOptions: {
      variables: {
        page: 1,
        order: BLACKLIST_NUMBERS_ORDER,
      },
    },
    paginationOptions: {
      checkHasMore: (d) => !!d?.blacklistedNumbers.meta.nextPage,
      onFetchMore: handleFetchMore,
      containerRef: listScrollRef,
      threshold: 500,
    },
  });

  const { addBlockedNumber } = useAddBlockedNumber();
  const [deleteBlacklistedNumber] = useDeleteEntity<
    DeleteBlacklistedNumberMutation,
    DeleteBlacklistedNumberMutationVariables
  >({
    mutation: DELETE_BLACKLISTED_NUMBER_MUTATION,
    __typename: 'BlacklistedNumber',
  });

  async function handleSubmit(values: BlacklistedNumberInput) {
    const error = await addBlockedNumber(values);

    if (error) {
      return;
    }

    listScrollRef.current?.scrollTo?.({ top: 0, behavior: 'smooth' });
  }

  async function handleDelete(
    number: BlacklistedNumbersQuery_blacklistedNumbers_blacklistedPhoneNumbers
  ) {
    const formattedPhoneNumber = formatInternational(number.phoneNumber);
    const error = await deleteBlacklistedNumber(
      number.id,
      { numberId: number.id },
      formattedPhoneNumber,
      true
    );

    if (error) {
      return;
    }

    track({ event: 'blocked_number_deleted' });
  }

  if (loading && !data) {
    return <Loading data-test='blocked-number-loading' />;
  }

  const {
    blacklistedNumbers: { blacklistedPhoneNumbers },
  } = data!;

  const numbersList = blacklistedPhoneNumbers.map((number) => number.phoneNumber);

  return (
    <Paper>
      <Typography color='text.base' mb='s'>
        {t('calls.pages.blocked_numbers.subtitle')}
      </Typography>

      <Gap w='100%' flexDirection='column' alignItems='flex-start' space='s'>
        <List
          ref={listScrollRef}
          hasScroll
          overLoading={loading}
          isLoadingMoreItems={false}
          stickyFooter={
            shouldShowAddPhoneNumberRow && (
              <InlineForm
                px='xs'
                py='xxs'
                formProps={{ onSubmit: handleSubmit }}
                onClose={onCloseClick}
                submitButtonText={t('app.form.buttons.add')}
                data-test='add-blacklisted-number-inline-form'
              >
                {() => (
                  <PhoneInputField
                    size='small'
                    name='phoneNumber'
                    validate={pipeValidators(
                      validateUniquePhoneNumber(numbersList),
                      validatePhoneNumberFormat
                    )}
                    defaultFlag={currentCompany.address?.country || undefined}
                  />
                )}
              </InlineForm>
            )
          }
        >
          {blacklistedPhoneNumbers.map((number, index) => (
            <ListItem
              key={number.id}
              dropdownOptions={[
                {
                  title: t('calls.pages.blocked_numbers.delete'),
                  callback: () => handleDelete(number),
                },
              ]}
              data-test={`blocked-number-list-item-${index}`}
            >
              <PhoneNumber
                number={number.phoneNumber}
                highlightInvalidFormattablePhoneNumber
                pl='s'
              />
            </ListItem>
          ))}
        </List>

        {!shouldShowAddPhoneNumberRow && (
          <AddButton
            onClick={showAddPhoneNumberRow}
            isLarge={!blacklistedPhoneNumbers.length}
            data-test='blocked-number-add'
          >
            {t('calls.pages.blocked_numbers.add')}
          </AddButton>
        )}
      </Gap>
    </Paper>
  );
}
