import { ApolloError } from '@apollo/client';
import { V4Response } from '@config/graphql/responseTransformers/v4NumberTransformer';
import { ClientError } from '@helpers/errors.helpers';
import { ChannelAuthorizationData } from 'pusher-js/types/src/core/auth/options';

export interface LegacyDashboardProps {
  componentName: string;
}

export interface LegacyDashboardContextType {
  progress: number;
  isLoaded: boolean;
  mount: (_elem: HTMLElement, _events?: EventHandlers) => undefined;
  unmount: () => undefined;
  navigate: (_path: string, _params: Record<string, string>) => undefined;
  show: () => undefined;
  getPusherConfigError?: ClientError;
  updatePusherConfigError?: ApolloError;
}

export type EventHandlers = {
  onTransitionSucceeded: () => void;
};

export enum DASHBOARD_VERSION {
  LEGACY = 'LEGACY',
  NEW = 'NEW',
}

export interface LegacyNavigationPayload {
  name: string;
  params?: Record<string, string>;
  url?: string;
  actions?: unknown[];
}

/* ------------------ Message ------------------ */

export type Message =
  | NavigationStartedMessage
  | NavigationSuccessMessage
  | LogoutMessage
  | AppLoadedMessage
  | AppUnloadedMessage
  | ReduxActionMessage
  | EnterFullScreenMessage
  | ExitFullScreenMessage
  | PusherAuthorizeChannel;

export enum EMBED_MESSAGE_TYPE {
  LOADED = 'app-loaded',
  UNLOADED = 'app-unload',
  NAVIGATION_STARTED = 'navigation-started',
  NAVIGATION_ERROR = 'navigation-error',
  NAVIGATION_SUCCEEDED = 'navigation-succeeded',
  LOGOUT = 'logout',
  REDUX_ACTION = 'redux-action',
  ENTER_FULL_SCREEN = 'enter-full-screen',
  EXIT_FULL_SCREEN = 'exit-full-screen',
}

export enum PUSHER {
  AUTHORIZE_CHANNEL = 'pusher-authorize-channel',
  AUTHORIZE_CHANNEL_FULLFILLED = 'pusher-authorize-channel-fullfilled',
  AUTHORIZE_CHANNEL_FAILED = 'pusher-authorize-channel-failed',
}

export interface NavigationStartedMessage {
  type: EMBED_MESSAGE_TYPE.NAVIGATION_STARTED;
  name: string;
  url: string;
  params?: Record<string, string>;
}

export interface NavigationSuccessMessage {
  type: EMBED_MESSAGE_TYPE.NAVIGATION_SUCCEEDED;
}

export interface LogoutMessage {
  type: EMBED_MESSAGE_TYPE.LOGOUT;
}

export interface AppLoadedMessage {
  type: EMBED_MESSAGE_TYPE.LOADED;
}

export interface AppUnloadedMessage {
  type: EMBED_MESSAGE_TYPE.UNLOADED;
}

export interface ReduxActionMessage {
  type: EMBED_MESSAGE_TYPE.REDUX_ACTION;
  action: ReduxAction;
}

export interface EnterFullScreenMessage {
  type: EMBED_MESSAGE_TYPE.ENTER_FULL_SCREEN;
}

export interface ExitFullScreenMessage {
  type: EMBED_MESSAGE_TYPE.EXIT_FULL_SCREEN;
}

export interface GenericReduxAction<P = unknown> {
  type: string;
  payload: P;
}

export interface ReduxActionJourneyPayload {
  journeyName: string;
  step?: string;
  open?: boolean;
  data?: Record<string, unknown>;
}

export interface ReduxActionNumberPayload {
  number: V4Response;
}

export interface ReduxActionAppLoadingProgressPayload {
  progress: number;
}

export type ReduxAction = GenericReduxAction<
  ReduxActionJourneyPayload | ReduxActionNumberPayload | ReduxActionAppLoadingProgressPayload
>;

export type PusherAuthorizeChannel = {
  type: PUSHER.AUTHORIZE_CHANNEL;
  payload: { cluster: string; channelName: string; socketId: string };
};

export type PusherAuthorizeChannelFullfilled = {
  type: PUSHER.AUTHORIZE_CHANNEL_FULLFILLED;
  payload: ChannelAuthorizationData;
};

export type PusherAuthorizeChannelFailed = {
  type: PUSHER.AUTHORIZE_CHANNEL_FAILED;
};
