import { useState } from 'react';

import { Flex } from '@aircall/tractor-v2';
import CALL_HISTORY_DOWNLOAD_IMAGE from '@assets/images/call_history_download.png';
import { AnalyticsDashboard } from '@components/AnalyticsDashboard/AnalyticsDashboard';
import { DataLimitBanner } from '@components/AnalyticsDashboard/DataLimitBanner';
import { AnalyticsDashboardType } from '@generated/globalTypes';
import { ANALYTICS_LIMITS } from '@helpers/analytics.helpers';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useTranslation } from 'react-i18next';

export function CallsHistoryPage() {
  const { t } = useTranslation();
  const { enableCallHistoryFeedbackButton } = useFeatures();
  const [bannerVisible, setBannerVisible] = useState<boolean>(false);

  const handleTruncated = (truncated: boolean) => {
    setBannerVisible(truncated);
  };

  return (
    <Flex flexDirection='column' h='100%' justifyContent='center'>
      <DataLimitBanner
        imageSrc={CALL_HISTORY_DOWNLOAD_IMAGE}
        text={t('calls.pages.call_history.banner')}
        translationPrefix='calls.pages.call_history'
        visible={bannerVisible}
      />
      <Flex flexDirection='column' h='100%' overflow='auto'>
        <AnalyticsDashboard
          feedbackButtonEvent={
            enableCallHistoryFeedbackButton ? 'feedback_analytics_button_clicked' : ''
          }
          minDate={ANALYTICS_LIMITS.sixMonthDate}
          onTruncated={handleTruncated}
          pageHeaderTitle={t('calls.pages.call_history.title')}
          title='call-log'
          type={AnalyticsDashboardType.CALL_LOG}
        />
      </Flex>
    </Flex>
  );
}
