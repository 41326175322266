import { AnalyticsDashboardType } from '@generated/globalTypes';
import { AnalyticsPlusAddonPage } from '@pages/analytics-plus-addon';
import { useTranslation } from 'react-i18next';

export function StatsUnansweredCallsPage() {
  const { t } = useTranslation();

  return (
    <AnalyticsPlusAddonPage
      feedbackButtonEvent='feedback_analytics_button_clicked'
      pageHeaderTitle={t('stats.tabs.unanswered_calls')}
      title='unanswered_inbound_calls'
      type={AnalyticsDashboardType.UNANSWERED_INBOUND_CALL}
    />
  );
}
