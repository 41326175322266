import { gql } from '@apollo/client';

export const DELETE_IVR_SETTING_MUTATION = gql`
  mutation DeleteIVRSettingMutationRest($numberId: String!, $ivrSettingId: String!) {
    deleteIVRSetting(numberId: $numberId, ivrSettingId: $ivrSettingId)
      @rest(
        type: "Void"
        path: "numbers/{args.numberId}/ivr_settings/{args.ivrSettingId}"
        endpoint: "v3"
        method: "DELETE"
      ) {
      _
    }
  }
`;
