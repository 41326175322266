import { useCallback } from 'react';

import {
  UseAddDispatchGroup,
  UseAddDispatchGroupReturn,
  AddDispatchGroup,
  DISPATCH_GROUP_TYPE,
} from './useAddDispatchGroup.decl';

import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import {
  AddDispatchGroupMutation,
  AddDispatchGroupMutationVariables,
} from '@generated/AddDispatchGroupMutation';
import {
  NumberDetailQuery,
  NumberDetailQuery_numberDetail_dispatchGroups,
  NumberDetailQuery_numberDetail_dispatchGroups_dispatchable,
} from '@generated/NumberDetailQuery';
import { ADD_DISPATCH_GROUP_MUTATION } from '@graphql/mutations/AddDispatchGroupMutation';
import { NUMBER_DETAIL_QUERY } from '@graphql/queries/NumberDetailQuery';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useTranslation } from 'react-i18next';

/**
 * The hook handles adding a dispactch group and showing a snackbar.
 * @returns A callback function to add dispatch group and loading state
 */
export function useAddDispatchGroup({ numberId }: UseAddDispatchGroup): UseAddDispatchGroupReturn {
  const { t } = useTranslation();
  const [mutate, { loading }] = useGraphMutationWithFeedback<
    AddDispatchGroupMutation,
    AddDispatchGroupMutationVariables
  >(ADD_DISPATCH_GROUP_MUTATION, {
    type: 'snackbar',
  });

  const addDispatchGroup = useCallback(
    async ({ user, team, attributes, type, successMessageName }: AddDispatchGroup) => {
      const dispatchableId = (user && user.ID) || (team && team.ID);

      mutate(
        {
          variables: {
            numberId,
            input: {
              data: {
                type: 'dispatch_group',
                attributes,
                relationships: {
                  dispatchable: {
                    data: {
                      id: dispatchableId,
                      type,
                    },
                  },
                },
              },
            },
          },
          update: (cache, { data }) => {
            const { numberDetail } = cache.readQuery<NumberDetailQuery>({
              query: NUMBER_DETAIL_QUERY,
              variables: { id: numberId },
            })!;

            const newDispatchGroup = {
              ...data?.addDispatchGroup,
            } as NumberDetailQuery_numberDetail_dispatchGroups;

            if (type === DISPATCH_GROUP_TYPE.USER) {
              const { pictureURL: pictureUrl, firstName, lastName, ringTimeout, ID } = user!;
              newDispatchGroup.dispatchable = {
                ...data?.addDispatchGroup.dispatchable,
                pictureUrl,
                firstName,
                lastName,
                ringTimeout,
                __typename: 'User',
                id: Number(ID),
              } as NumberDetailQuery_numberDetail_dispatchGroups_dispatchable;
            } else {
              const { imageName, name: teamName, ID } = team!;
              newDispatchGroup.dispatchable = {
                ...data?.addDispatchGroup.dispatchable,
                imageName,
                name: teamName,
                __typename: 'Team',
                ID,
              } as NumberDetailQuery_numberDetail_dispatchGroups_dispatchable;
            }

            const newDispatchGroups = [...numberDetail.dispatchGroups, newDispatchGroup];
            cache.writeQuery<NumberDetailQuery>({
              query: NUMBER_DETAIL_QUERY,
              variables: { id: numberId },
              data: {
                numberDetail: {
                  ...numberDetail,
                  dispatchGroups: newDispatchGroups,
                },
              },
            });
          },
        },
        {
          successMessage: t(SUCCESS_FEEDBACK_MESSAGES.ADD_SUCCESS, {
            name: successMessageName,
          }),
        }
      );
    },
    [numberId, mutate, t]
  );

  return { addDispatchGroup, loading };
}
