import { gql } from '@apollo/client';

export const GET_ONBOARDING_QUERY = gql`
  query GetOnboardingQuery {
    getOnboarding {
      ... on Onboarding {
        __typename
        cta
        ctaButtonTitle
        ctaButtonUrl
        steps {
          content
          redirectUrl
          stepStatus
          stepType
          stepTitle
          updatedAt
        }
        metadata {
          ... on MetadataKeyValuePairBoolean {
            __typename
            key
            value
          }
        }
      }
      ... on GenericException {
        __typename
        code
        message
      }
    }
  }
`;
