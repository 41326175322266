import { Spacer, Box, Typography } from '@aircall/tractor-v2';
import { BUSINESS_HOURS_TYPE } from '@constants/generic.constants';
import { LineState, AgentState } from '@generated/globalTypes';
import { useTranslation } from 'react-i18next';

type GetBusinessHoursColorAndText = {
  state: string | null;
  open: boolean;
  type: BUSINESS_HOURS_TYPE;
};

interface ColorAndText {
  color: string;
  text: string;
}

export function getBusinessHoursColorAndText({
  type,
  state,
  open,
}: GetBusinessHoursColorAndText): ColorAndText | undefined {
  const text = `business_hours.${type}.${state}`;

  // eslint-disable-next-line default-case
  switch (state) {
    case LineState.ALWAYS_OPENED:
    case AgentState.ALWAYS_OPENED:
      return {
        color: 'success-300',
        text,
      };
    case LineState.ALWAYS_CLOSED:
    case AgentState.ALWAYS_CLOSED:
      return {
        color: 'critical-500',
        text,
      };
    case LineState.AUTO:
    case AgentState.AUTO:
      return {
        color: open ? 'success-300' : 'critical-500',
        text: `${text}.${open ? 'open' : 'closed'}`,
      };
  }

  return undefined;
}

interface BusinessHoursStateProps {
  state: LineState | AgentState | null;
  open: boolean;
  type?: BUSINESS_HOURS_TYPE;
}

export function BusinessHoursState({
  state,
  open,
  type = BUSINESS_HOURS_TYPE.LINES,
}: BusinessHoursStateProps) {
  const { t } = useTranslation();

  const res = getBusinessHoursColorAndText({ type, state, open });

  if (!res) {
    return null;
  }

  const { color, text } = res;

  return (
    <Spacer space='xxs' alignItems='center' direction='horizontal' data-test='number-state-label'>
      <Box h={8} w={8} borderRadius='50%' backgroundColor={color} />
      <Typography variant='bodyMediumS' color='neutral-400'>
        {t(text)}
      </Typography>
    </Spacer>
  );
}
