import { FetchResult, fromPromise, ServerError, Observable } from '@apollo/client';
import { ErrorLink, ErrorResponse } from '@apollo/client/link/error';
import { COOKIE_KEYS } from '@constants/auth.constants';
import {
  LOGGING_AUTHENTICATION_EVENTS,
  LOGGING_FEATURE_NAMES,
  LOGGING_STATUS,
} from '@constants/logging.constants';
import { createLog } from '@helpers/logging.helpers';
import { RefreshToken } from '@state/app/authentication/authentication.decl';
import Cookies from 'js-cookie';
import noop from 'lodash-es/noop';

/**
 * Handle error if api request fails
 */
export const onRequestError =
  (logoutHandler: VoidFunction, refreshToken: RefreshToken) =>
  ({ networkError, operation, forward }: ErrorResponse): Observable<FetchResult> | void => {
    const context = operation.getContext();

    const has401Error = networkError && (networkError as ServerError).statusCode === 401;
    const hasCustomToBeRetriedError =
      networkError && JSON.stringify(networkError).includes('UnauthorizedException');

    if (has401Error || hasCustomToBeRetriedError) {
      if (
        context.headers.authorization &&
        context.headers.authorization.includes(Cookies.get(COOKIE_KEYS.LEGACY_TOKEN))
      ) {
        logoutHandler();
        return noop();
      }

      return fromPromise(
        refreshToken()
          .then(() => {
            createLog({
              featureName: LOGGING_FEATURE_NAMES.AUTHENTICATION,
              event: LOGGING_AUTHENTICATION_EVENTS.REFRESH_TOKEN,
              status: LOGGING_STATUS.SUCCESS,
            });
            return true;
          })
          .catch((error) => {
            createLog({
              featureName: LOGGING_FEATURE_NAMES.AUTHENTICATION,
              event: LOGGING_AUTHENTICATION_EVENTS.REFRESH_TOKEN,
              status: LOGGING_STATUS.FAILED,
              properties: {
                error,
              },
            });
            // Clear all stored tokens, which will force user to logout
            logoutHandler();
          })
      )
        .filter((value) => Boolean(value))
        .flatMap(() =>
          // when refreshing token succeeds, retry the request, return the new observable
          forward(operation)
        );
    }
    return noop();
  };

export const errorLink = (
  logoutHandler: VoidFunction,
  refreshTokenHandler: RefreshToken
): ErrorLink => new ErrorLink(onRequestError(logoutHandler, refreshTokenHandler));
