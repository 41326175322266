import { FunctionComponent, SVGProps, useCallback } from 'react';

import { RegistrationStatusSectionProps } from './RegistrationStatus.section.decl';

import { Box, Button, Typography } from '@aircall/tractor-v2';
import { ReactComponent as RegistrationError } from '@assets/images/registration_error.svg';
import { ReactComponent as RegistrationSuccess } from '@assets/images/registration_success.svg';
import { ReactComponent as RegistrationWarning } from '@assets/images/registration_warning.svg';
import { ExternalLink } from '@components/Link';
import { Gap, Loading } from '@dashboard/library';
import { NumberRegistrationStatus } from '@generated/globalTypes';
import { Trans, useTranslation } from 'react-i18next';

function getSvgForRegistrationStatus(
  registrationStatus: NumberRegistrationStatus
): FunctionComponent<SVGProps<SVGSVGElement>> {
  switch (registrationStatus) {
    case NumberRegistrationStatus.approved:
      return RegistrationSuccess;
    case NumberRegistrationStatus.rejected:
      return RegistrationError;
    case NumberRegistrationStatus.awaiting_approval:
    case NumberRegistrationStatus.not_registered:
    default:
      return RegistrationWarning;
  }
}

export function RegistrationStatusSection({
  loading,
  hasErrors,
  registrationStatus,
  translationPrefix,
}: RegistrationStatusSectionProps) {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    window.open(t(`${translationPrefix}.rejected.button_link`), '_blank');
  }, [t, translationPrefix]);

  if (!registrationStatus || hasErrors) {
    return (
      <Gap
        flexDirection='column'
        gap='xs'
        alignItems='center'
        justifyContent='center'
        minH={400}
        bg='neutral-0'
        borderRadius={16}
      >
        {loading ? (
          <>
            <Loading w={24} h={24} />
            <Typography variant='bodyMediumS'>{t(`${translationPrefix}.loading_text`)}</Typography>
            <Typography variant='supportingRegularS'>
              {t(`${translationPrefix}.loading_subtext`)}
            </Typography>
          </>
        ) : (
          <Typography variant='bodyMediumS'>{t('error_screen.generic_error.message')}</Typography>
        )}
      </Gap>
    );
  }

  const SvgComponent = getSvgForRegistrationStatus(registrationStatus);

  return (
    <Box bg='neutral-0' borderRadius={16} minH={400}>
      <Gap gap='m' padding='xl' flexDirection='column' alignItems='center'>
        <SvgComponent />
        <Gap gap='xs' flexDirection='column' alignItems='center'>
          <Typography variant='headingBoldS'>
            {t(`${translationPrefix}.${registrationStatus}.status`)}
          </Typography>
          <Typography variant='bodyMediumS' textAlign='center'>
            <Trans i18nKey={`${translationPrefix}.${registrationStatus}.text`}>
              <ExternalLink href={t(`${translationPrefix}.link`)} target='_blank' />
            </Trans>
          </Typography>
        </Gap>
        {registrationStatus === NumberRegistrationStatus.rejected && (
          <Button
            variant='secondary'
            onClick={handleClick}
            data-test='registration-status-section-button'
          >
            {t(`${translationPrefix}.rejected.button_text`)}
          </Button>
        )}
      </Gap>
    </Box>
  );
}
