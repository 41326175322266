import { useEffect, useRef, useState } from 'react';

import { UseTimerReturn } from './useTimer.decl';

/**
 * Custom hook to track a timer value while it's prop is true. Can also stop the timer manually.
 * @param isTimerOn - whether or not the timer should run
 * @returns the timer value in millisecond
 */
export function useTimer(isTimerOn: boolean): UseTimerReturn {
  const [timer, setTimer] = useState(0);
  const lastInit = useRef<number>(0);

  useEffect(() => {
    let interval: number | undefined;

    if (isTimerOn) {
      lastInit.current = new Date().getTime();
      interval = window.setInterval(() => {
        const dT = new Date().getTime() - lastInit.current;
        setTimer(dT);
      }, 1000);
    } else {
      window.clearInterval(interval);
      setTimer(0);
    }

    return () => window.clearInterval(interval);
  }, [isTimerOn]);

  return {
    timer,
  };
}
