import { useEffect } from 'react';

import { useGlobalData } from '../useGlobalData/useGlobalData';

import { useTranslation } from 'react-i18next';

/**
 * The hook responsible to have the language updated when the user changes
 */
export function useLanguageUpdater(): void {
  const { i18n } = useTranslation();
  const { currentUser } = useGlobalData();

  useEffect(() => {
    i18n.changeLanguage(currentUser?.language);
  }, [currentUser?.language, i18n]);
}
