import { AboutSection } from './AboutSection/About.section';
import { NumberDetailRegistrationStatusPageProps } from './NumberDetailRegistrationStatus.page.decl';
import { RegistrationProcessSection } from './RegistrationProcessSection/RegistrationProcess.section';
import { RegistrationStatusSection } from './RegistrationStatusSection/RegistrationStatus.section';

import { Flex, Skeleton, Typography } from '@aircall/tractor-v2';
import { ColumnsCount, Gap, GridItem, GridLayout, useGraphQuery } from '@dashboard/library';
import {
  GetNumberRegistrationStatusQuery,
  GetNumberRegistrationStatusQueryVariables,
} from '@generated/GetNumberRegistrationStatusQuery';
import { GET_NUMBER_REGISTRATION_STATUS } from '@graphql/queries/GetNumberRegistrationStatus';
import { getNumberType } from '@helpers/numbers.helpers';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const sharedGridItemProps = {
  xl: 5 as ColumnsCount,
  bg: 'neutral-0',
  borderRadius: 16,
  h: 'max-content',
  padding: 'm',
  spaceX: { xs: 'm', xl: 0 },
  spaceY: { xs: 0, xl: 'm' },
};

export function NumberDetailRegistrationStatusPage({
  numberDetail: { iso3166Alpha2, number },
}: NumberDetailRegistrationStatusPageProps) {
  const { t } = useTranslation();
  const { numberId } = useParams();
  const { data, loading, error } = useGraphQuery<
    GetNumberRegistrationStatusQuery,
    GetNumberRegistrationStatusQueryVariables
  >(GET_NUMBER_REGISTRATION_STATUS, { variables: { lineID: numberId }, throwError: false });
  const numberRegistration = data?.getNumberRegistrationStatus?.numberRegistration;
  const isNumberTollFree = getNumberType(number, iso3166Alpha2) === 'TOLL_FREE';
  const translationPrefix = `number_details.registration_status.tab.${
    isNumberTollFree ? 'toll_free' : 'a2p'
  }`;

  return (
    <GridLayout
      columnGap={{ xs: 'm', xl: 'l' }}
      rowGap={{ xs: 'l', xl: 'm' }}
      gridAutoFlow='column'
    >
      <GridItem xs={12} xl={7} gridRow={{ xs: 1, xl: '1 / 3' }}>
        <Gap flexDirection='column' gap='xxs' w='100%'>
          <RegistrationStatusSection
            loading={loading}
            hasErrors={!!error}
            registrationStatus={numberRegistration?.status}
            translationPrefix={`${translationPrefix}.registration_status_section`}
          />
          <Flex justifyContent='space-between' px='xxs'>
            <Typography variant='supportingRegularS' color='text-secondary'>
              {t(`${translationPrefix}.registration_status_section.status_update_window`)}
            </Typography>
            {numberRegistration && (
              <Typography variant='supportingRegularS' color='text-secondary'>
                {t(`${translationPrefix}.registration_status_section.last_updated`, {
                  updatedAt: numberRegistration.updatedAt,
                })}
              </Typography>
            )}
            {loading && <Skeleton w={100} h={12} data-test='last-updated-loading' />}
          </Flex>
        </Gap>
      </GridItem>
      <GridItem xs={isNumberTollFree ? 12 : 6} gridRow={{ xs: 2, xl: 1 }} {...sharedGridItemProps}>
        <AboutSection
          translationPrefix={`${translationPrefix}.about_section`}
          isNumberTollFree={isNumberTollFree}
        />
      </GridItem>
      {!isNumberTollFree && (
        <GridItem xs={6} gridRow={2} {...sharedGridItemProps}>
          <RegistrationProcessSection
            translationPrefix={`${translationPrefix}.registration_process_section`}
          />
        </GridItem>
      )}
    </GridLayout>
  );
}
