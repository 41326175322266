import styled from 'styled-components';

import { AnnouncementModalProps } from './AnnouncementModal.decl';

import { CloseCircleFilled } from '@aircall/icons';
import { Box, Button, Typography, Tag, getRadii } from '@aircall/tractor-v2';
import { Gap } from '@dashboard/library';

const Container = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled(Box)`
  width: 320px;
  background: white;
  border-radius: ${getRadii('s')};
  overflow: hidden;
  filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.12));
`;
const Content = styled(Box)`
  padding: 16px;
`;
const Image = styled(Box)<{ image: string }>`
  background-image: ${({ image }) => `url(${image})`};
  height: 180px;
  background-size: cover;
  background-position: center;
  background-color: #83b2ee;
`;

const Close = styled(CloseCircleFilled)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export function AnnouncementModal({
  isOpen = true,
  image,
  renderImage,
  title,
  text,
  tagText,
  primaryButtonText,
  secondaryButtonText,
  onClose,
  onClickPrimary,
  onClickSecondary,
}: AnnouncementModalProps) {
  if (!isOpen) {
    return null;
  }

  return (
    <Container data-test='announcement-modal'>
      <Modal>
        <Close data-test='modal-close' color='neutral-100' size={32} onClick={onClose} />
        {image && <Image data-test='announcement-modal-image' image={image} />}
        {renderImage?.()}
        <Content>
          <Gap flex={1} gap='m' flexDirection='column'>
            <Gap flex={1} gap='s' flexDirection='column' alignItems='flex-start'>
              {tagText && <Tag variant='darkPrimary'>{tagText}</Tag>}
              <Typography variant='bodySemiboldM'>{title}</Typography>
              <Typography variant='bodyRegularS'>{text}</Typography>
            </Gap>

            <Gap gap='xs' alignItems='center' marginLeft='auto'>
              <Button
                data-test='modal-secondary-button'
                mode='link'
                size='small'
                onClick={onClickSecondary}
              >
                {secondaryButtonText}
              </Button>

              <Button data-test='modal-primary-button' size='small' onClick={onClickPrimary}>
                {primaryButtonText}
              </Button>
            </Gap>
          </Gap>
        </Content>
      </Modal>
    </Container>
  );
}
