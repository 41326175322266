import { useContext } from 'react';

import { AuthenticationStateContext } from '../state/app/authentication/AuthenticationProvider';

/**
 * Exposes the current authentication state and actions related to authentication.
 * @returns an object holding the auth state and actions
 */
export function useAuthenticationState() {
  const { authState, actions } = useContext(AuthenticationStateContext);

  return {
    authState,
    actions,
  };
}
