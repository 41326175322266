import React from 'react';

import { useOnboarding } from '../hooks/useOnboarding';

import { CreateResourcesSection } from './CreateResourcesSection';
import { OnboardingComplete } from './OnboardingComplete';
import { OnboardingHeader } from './OnboardingHeader';
import { ResourcesSection } from './ResourcesSection';

import { Box, Divider, Flex } from '@aircall/tractor-v2';

export function OnboardingContentSection() {
  const { numberStatus, userStatus, teamStatus, isOnboardingComplete } = useOnboarding();
  return (
    <Flex
      flexDirection={{
        xs: 'column',
        xl: 'row',
      }}
      m='xl'
      alignItems='stretch'
      as='section'
    >
      <Box
        flex={1}
        backgroundColor='white'
        borderColor='neutral-500'
        borderStyle='solid'
        borderWidth={1}
        borderRadius={8}
        mr={{
          xs: '0px',
          xl: '40px',
        }}
        mb='32px'
        overflow='hidden'
      >
        <OnboardingHeader />
        <Divider orientation='horizontal' bg='neutral-500' size='xSmall' />
        {isOnboardingComplete ? (
          <OnboardingComplete />
        ) : (
          <CreateResourcesSection
            teamStatus={teamStatus}
            numberStatus={numberStatus}
            userStatus={userStatus}
          />
        )}
      </Box>
      <ResourcesSection />
    </Flex>
  );
}
