import { identify, setGlobalContext, track } from '@helpers/gainsight.helpers';

export const gainsight = {
  identify,
  track,
  setGlobalContext,
};

/**
 * Feature flags to be sent to Gainsight
 */
export const GAINSIGHT_FEATURE_FLAGS = ['enableStarterPlan', 'enableAircallWorkspaceMigration'];
