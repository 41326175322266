import { useCallback, useEffect, useMemo } from 'react';

import { UseOnboardingDataReturnType } from './useOnboardingData.decl';

import { checklistData } from '@components/TrialChecklistPopup/TrialChecklistData';
import { logger } from '@config/logger.config';
import { PHONE_APP_URL } from '@constants/urls.constants';
import { useGraphMutation, useGraphQuery } from '@dashboard/library';
import { CreateOnboardingMutation } from '@generated/CreateOnboardingMutation';
import {
  GetOnboardingQuery,
  GetOnboardingQuery_getOnboarding_Onboarding_metadata,
} from '@generated/GetOnboardingQuery';
import { MetadataTypeBoolean, StepStatus, StepType } from '@generated/globalTypes';
import { UpdateOnboardingStepMutation } from '@generated/UpdateOnboardingStepMutation';
import { CREATE_ONBOARDING_MUTATION } from '@graphql/mutations/CreateOnboardingMutation';
import { UPDATE_ONBOARDING_METADATA_MUTATION } from '@graphql/mutations/UpdateOnboardingMetadataMutation';
import { UPDATE_ONBOARDING_STEP_MUTATION } from '@graphql/mutations/UpdateOnboardingStepMutation';
import { GET_ONBOARDING_QUERY } from '@graphql/queries/GetOnboardingQuery';
import { useFeatures } from '@hooks/useFeatures/useFeatures';

export const useOnboardingData = (): UseOnboardingDataReturnType => {
  const { showOnboardingTrial } = useFeatures();

  const { data, loading, refetch } = useGraphQuery<GetOnboardingQuery>(GET_ONBOARDING_QUERY, {
    skip: !showOnboardingTrial,
  });
  const onboardingData = data?.getOnboarding;
  const isNotOnboardingData = onboardingData?.__typename !== 'Onboarding';
  const [createOnboarding, { loading: createOnboardingLoading }] =
    useGraphMutation<CreateOnboardingMutation>(CREATE_ONBOARDING_MUTATION);
  const [updateOnboardingStep, { loading: updateStepLoading }] =
    useGraphMutation<UpdateOnboardingStepMutation>(UPDATE_ONBOARDING_STEP_MUTATION);
  const [updateOnboardingMetadata] = useGraphMutation<UpdateOnboardingStepMutation>(
    UPDATE_ONBOARDING_METADATA_MUTATION
  );

  const updateOnboardingStepStatus = useCallback(
    async (stepType: StepType, stepStatus: StepStatus) => {
      const { data: updatedOnboardingData, error } = await updateOnboardingStep({
        variables: {
          stepType,
          stepStatus,
        },
      });
      if (error) {
        logger.error('Failed to update onboarding step', { error });
        return;
      }
      if (updatedOnboardingData?.updateOnboardingStep?.__typename !== 'Onboarding') {
        logger.error('Failed to update onboarding step', { error });
      }
    },
    [updateOnboardingStep]
  );

  useEffect(() => {
    if (!showOnboardingTrial) {
      return;
    }
    // Check if getOnboarding is an empty object, ie. the user has no onboarding data
    if (onboardingData?.__typename === 'GenericException' && onboardingData.code === '0001') {
      createOnboarding()
        .then(({ error }) => {
          if (error) {
            throw error;
          }
          refetch();
        })
        .catch((err) => {
          logger.error('Failed to create onboarding', { err });
        });
    }
  }, [onboardingData, showOnboardingTrial, createOnboarding, refetch]);

  const stepCounts = useMemo(() => {
    if (isNotOnboardingData) {
      return {
        completedSteps: 0,
        totalSteps: 0,
      };
    }
    const totalSteps = onboardingData?.steps.length;
    const completedSteps = onboardingData?.steps.filter(
      (step) => step.stepStatus === 'COMPLETED'
    ).length;

    return {
      completedSteps,
      totalSteps,
    };
  }, [onboardingData, isNotOnboardingData]);

  const phoneUrl = useMemo(() => {
    if (isNotOnboardingData) {
      return PHONE_APP_URL;
    }
    const phoneStep = onboardingData?.steps.find((step) => step.stepType === StepType.LAUNCH_PHONE);
    return phoneStep?.redirectUrl ?? PHONE_APP_URL;
  }, [onboardingData, isNotOnboardingData]);

  const firstActiveStepIndex = useMemo(() => {
    if (isNotOnboardingData) {
      return null;
    }
    const onboardingStepStatusMap = onboardingData.steps?.reduce<Record<string, StepStatus>>(
      (acc, step) => {
        acc[step.stepType] = step.stepStatus;
        return acc;
      },
      {}
    );

    return checklistData.findIndex(
      (step) => onboardingStepStatusMap[step.stepType] === StepStatus.IN_PROGRESS
    );
  }, [onboardingData, isNotOnboardingData]);

  const allStepsCompleted =
    stepCounts.totalSteps > 0 &&
    stepCounts.completedSteps > 0 &&
    stepCounts.totalSteps === stepCounts.completedSteps;

  const confettiShown = useMemo(
    () =>
      data?.getOnboarding?.__typename === 'Onboarding' &&
      !!data.getOnboarding.metadata.find(
        (meta: GetOnboardingQuery_getOnboarding_Onboarding_metadata) =>
          meta.key === MetadataTypeBoolean.SHOWN_CONFETTI_ON_DASHBOARD && meta.value === true
      ),
    [data]
  );

  const updateConfettiShown = useCallback(
    async (value = true) => {
      try {
        const res = await updateOnboardingMetadata({
          variables: {
            key: MetadataTypeBoolean.SHOWN_CONFETTI_ON_DASHBOARD,
            value,
          },
        });
        if (res.error) {
          throw res.error;
        }
      } catch (error) {
        logger.error('Failed to update metadata', { error });
      }
    },
    [updateOnboardingMetadata]
  );

  return {
    totalSteps: stepCounts.totalSteps,
    completedSteps: stepCounts.completedSteps,
    phoneUrl,
    loading: loading || createOnboardingLoading,
    onboardingData: isNotOnboardingData ? null : onboardingData,
    firstActiveStepIndex,
    updateOnboardingStepStatus,
    updateStepLoading,
    allStepsCompleted,
    showConfetti: allStepsCompleted && !confettiShown,
    updateConfettiShown,
    refetch,
  };
};
