/* eslint-disable no-unused-expressions */
import React, { useState, useMemo, useEffect, useCallback, ChangeEvent, useRef } from 'react';

import { useCloseOnScroll } from '../../hooks/useCloseOnScroll';
import { useLayoutPosition } from '../../hooks/useLayoutPosition';

import { useSearchHistory } from './hooks/useSearchHistory';
import { RecentSearch } from './RecentSearch';
import { SearchBarProps } from './SearchBar.decl';

import { SearchOutlined } from '@aircall/icons';
import { TextInput, Box } from '@aircall/tractor-v2';
import debounce from 'lodash-es/debounce';

/**
 * The search bar component which dispatches the search on typing.
 */
export function SearchBar({
  w = '100%',
  name,
  placeholder,
  onSearch,
  onClear = () => onSearch(''),
  debounceTimeout = 800,
  value,
  size = 'regular',
  'data-test': dataTest = 'search-bar',
}: SearchBarProps) {
  /** Search string from the input */
  const [searchVal, setSearchVal] = useState<string>(value || '');

  useEffect(() => {
    setSearchVal(value || '');
  }, [value]);

  /** Whether we should display the recent search menu */
  const [menuShown, setMenuShown] = useState(false);
  const openMenu = useCallback(() => setMenuShown(true), []);
  const closeMenu = useCallback(() => setMenuShown(false), []);

  // TODO: replace the layout calculations & portal's placement using `useTrigger` from Tractor
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  // closes the select menu when scrolling outside the menu
  // to prevent the menu from appearing in the incorrect position.
  useCloseOnScroll({
    eleRef: inputRef,
    innerScrollRef: menuRef,
    open: menuShown,
    onClose: closeMenu,
  });

  /** Stores the bounding rect of <SelectContainer> */
  const { boundingRect: inputPos, updateLayoutPosition } = useLayoutPosition(inputRef);
  const menuPos = useMemo(() => {
    if (!inputPos) {
      return {
        width: 0,
        top: 0,
        left: 0,
      };
    }

    return {
      width: inputPos.width,
      top: inputPos.bottom,
      left: inputPos.left,
    };
  }, [inputPos]);

  useEffect(() => {
    if (menuShown) {
      updateLayoutPosition();
    }
  }, [menuShown, updateLayoutPosition]);

  const debounceSearch = useMemo(
    () =>
      debounce((search: string) => {
        onSearch(search);
      }, debounceTimeout),
    [debounceTimeout, onSearch]
  );

  const cancelSearch = useCallback(() => {
    setSearchVal('');
    debounceSearch.cancel();
    onClear();
    closeMenu();
  }, [debounceSearch, onClear, closeMenu]);

  const handleClear = useMemo(
    () => (searchVal ? cancelSearch : undefined),
    [cancelSearch, searchVal]
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const search = event.target.value;

      closeMenu();
      if (!search) {
        cancelSearch();
      } else {
        setSearchVal(search);
        debounceSearch(search);
      }
    },
    [cancelSearch, debounceSearch, closeMenu]
  );

  const handleMenuBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (menuRef.current && menuRef.current.contains(e.relatedTarget as Node)) {
        e.target.focus();
        return;
      }
      closeMenu();
    },
    [closeMenu]
  );

  const { recentSearches, clearAllSearch, addSearch } = useSearchHistory({
    search: searchVal,
    name,
  });

  const startImmediateSearch = useCallback(
    (searchStr: string) => {
      debounceSearch.cancel();
      onSearch(searchStr);
    },
    [debounceSearch, onSearch]
  );

  // called when users press enter
  const handleSubmit = useCallback(() => {
    addSearch(searchVal);

    inputRef.current && inputRef.current.blur();

    // triggers the search
    startImmediateSearch(searchVal);
  }, [addSearch, searchVal, startImmediateSearch]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      // closes the recent search menu when clicking the escape key
      if (event.key === 'Escape') {
        closeMenu();
      }

      if (event.key === 'Enter') {
        handleSubmit();
      }
    },
    [closeMenu, handleSubmit]
  );

  const onRecentSearchSelected = useCallback(
    (searchStr: string) => {
      setSearchVal(searchStr);
      startImmediateSearch(searchStr);
      menuShown && closeMenu();
    },
    [closeMenu, menuShown, startImmediateSearch]
  );

  // prevents executing the search after it unmounted
  useEffect(
    () => () => {
      debounceSearch.cancel();
    },
    [debounceSearch]
  );

  return (
    <Box data-test={`${dataTest}-wrapper`} w={w}>
      <TextInput
        ref={inputRef}
        value={searchVal}
        name={name}
        placeholder={placeholder}
        icon={SearchOutlined}
        size={size}
        onChange={handleSearch}
        onClear={handleClear}
        data-test={dataTest}
        onFocus={openMenu}
        onBlur={handleMenuBlur}
        onKeyDown={handleKeyDown}
        autoComplete='off'
      />
      <RecentSearch
        ref={menuRef}
        open={menuShown}
        recentSearches={recentSearches}
        onSelected={onRecentSearchSelected}
        onClear={clearAllSearch}
        {...menuPos}
      />
    </Box>
  );
}
