import { LOGGER_LEVEL, Logger } from '@aircall/logger';
import { DATADOG_TOKEN } from '@constants/environment.constants';

export const logger = new Logger({
  token: DATADOG_TOKEN,
  /**
   * The minimal log level for the logger.
   * If set to `info`, the `debug` logs will be ignored
   * It might be overriden in src/sagas/logger.ts if the enable_verbose_debug_log_mode FF i set to true
   */
  level: LOGGER_LEVEL.info,
  // display logs into the browser console instead of sending them to Datadog
  debug: process.env.REACT_APP_BUILD_ENV === 'development',
});
