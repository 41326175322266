import React, { createContext, ReactNode, useState, useMemo } from 'react';

export type RoutesSearchParams = Record<string, URLSearchParams>;

interface SearchContextType {
  routesSearchParams: RoutesSearchParams;
  setRoutesSearchParams: React.Dispatch<React.SetStateAction<RoutesSearchParams>>;
}

export const SearchContext = createContext<SearchContextType>({} as SearchContextType);

interface SearchProviderProps {
  children: ReactNode;
}

export function SearchProvider({ children }: SearchProviderProps) {
  const [routesSearchParams, setRoutesSearchParams] = useState<
    SearchContextType['routesSearchParams']
  >({});

  const value = useMemo(
    () => ({
      routesSearchParams,
      setRoutesSearchParams,
    }),
    [routesSearchParams]
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}
