import { TrialBannerButton } from './components/TrialBannerButton';
import { TrialBannerWithCallLimits } from './components/TrialBannerWithCallLimits';
import { TrialBannerWithoutCallLimits } from './components/TrialBannerWithoutCallLimits';

import { Banner, BannerHeading, BannerIcon, BannerSuffix } from '@aircall/tractor-v2';
import { getDaysDifference, getMinutesDifference } from '@dashboard/library';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useTranslation } from 'react-i18next';

export function TrialBanner() {
  const { t } = useTranslation();

  const { currentCompany, isTrial } = useGlobalData();

  if (!isTrial) {
    return null;
  }

  const now = new Date();

  // In here we force the trialEnd to be always available because if the company is in trial
  // the trialEnd key exists
  const trialEndDate = new Date(currentCompany.trial!.trialEnd);
  const hasExpired = getMinutesDifference(trialEndDate, now) <= 0;

  if (hasExpired) {
    return (
      <Banner variant='warning' data-test='trial-banner-expired'>
        <BannerIcon /> <BannerHeading>{t('trial_banner.expired')}</BannerHeading>
        <BannerSuffix>
          <TrialBannerButton />
        </BannerSuffix>
      </Banner>
    );
  }

  const daysLeftInTrial = getDaysDifference(trialEndDate, now);

  return currentCompany.trial && currentCompany.trial.hasTrialCallLimits ? (
    <TrialBannerWithCallLimits
      daysLeftInTrial={daysLeftInTrial}
      callTotalSeconds={currentCompany.trial.callTotalSeconds}
    />
  ) : (
    <TrialBannerWithoutCallLimits daysLeftInTrial={daysLeftInTrial} />
  );
}
