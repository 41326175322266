import { gql } from '@apollo/client';

export const UPDATE_TTS_MESSAGE_MUTATION = gql`
  mutation UpdateTtsMessageMutation(
    $numberId: String!
    $ttsId: String!
    $input: UpdateTextToSpeechInput!
  ) {
    updateTtsMessage(numberId: $numberId, ttsId: $ttsId, input: $input)
      @rest(
        type: "TtsMessage"
        path: "{args.numberId}/tts_messages/{args.ttsId}"
        endpoint: "v4NumberDetail"
        method: "PATCH"
      ) {
      id
      language
      voice
      message
      url
    }
  }
`;
