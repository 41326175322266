import { Availability, BusinessHoursInput, DayOfWeek } from '@generated/globalTypes';

export const ALWAYS_SLOTS = [
  {
    days: [
      DayOfWeek.MONDAY,
      DayOfWeek.TUESDAY,
      DayOfWeek.WEDNESDAY,
      DayOfWeek.THURSDAY,
      DayOfWeek.FRIDAY,
    ],
    from: 0,
    to: 1440,
  },
];

export const addSlotsIfNeeded = (
  businessHours: Partial<BusinessHoursInput>
): Partial<BusinessHoursInput> => {
  const hasDays = Boolean(businessHours.slots?.[0]?.days.length);
  const isAlwaysCloseOrOpen =
    businessHours.availability === Availability.ALWAYS_OPENED ||
    businessHours.availability === Availability.ALWAYS_CLOSED;

  if (!hasDays && isAlwaysCloseOrOpen) {
    return Object.assign(businessHours, { slots: ALWAYS_SLOTS });
  }

  if (!hasDays && !isAlwaysCloseOrOpen) {
    return Object.assign(businessHours, {
      availability: Availability.ALWAYS_CLOSED,
      slots: ALWAYS_SLOTS,
    });
  }

  return Object.assign(businessHours);
};
