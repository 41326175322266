import { useCallback, useState } from 'react';

import {
  AudioSettingFormValues,
  AUDIO_NAME,
  AUDIO_SETTING_TYPE,
} from '../AudioSetting/AudioSetting.decl';
import { CUSTOM_FILE_FLOW } from '../AudioSetting/AudioSettingFile/AudioSettingFile.decl';

import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import {
  UpdateNumberMutation,
  UpdateNumberMutationVariables,
} from '@generated/UpdateNumberMutation';
import { UPDATE_NUMBER_MUTATION } from '@graphql/mutations/UpdateNumberMutation';
import { ClientException, GenericFormError, handleFormErrors } from '@helpers/errors.helpers';
import { useGenerateAudioSettingUrl } from '@hooks/useGenerateAudioSettingUrl';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useTracker } from '@hooks/useTracker/useTracker';
import { FormApi } from 'final-form';
import { useTranslation } from 'react-i18next';

interface UseUpdateUpdateAudio {
  numberId: string;

  /** Field name of the audio */
  name: AUDIO_NAME;

  /** title of the field that is being updated, for snackbar feedback purposes */
  title?: string;

  onCompleted: () => void;
}

interface UpdateUpdateAudioReturn {
  submitted: boolean;
  updateAudio: (
    values: AudioSettingFormValues,
    form: FormApi<AudioSettingFormValues, AudioSettingFormValues>
  ) => Promise<Record<string, string> | GenericFormError | void>;
}

export function getAudioFileTrackingType(
  type?: AUDIO_SETTING_TYPE,
  fileFlow?: CUSTOM_FILE_FLOW
): string | undefined {
  switch (type) {
    case AUDIO_SETTING_TYPE.FILE:
      return fileFlow && fileFlow === CUSTOM_FILE_FLOW.RECORD_DONE
        ? 'recorded file'
        : 'uploaded file';
    case AUDIO_SETTING_TYPE.TEXT_TO_SPEECH:
      return 'text to speech file';
    case AUDIO_SETTING_TYPE.LIBRARY:
      return 'library file';
    default:
      return undefined;
  }
}

export function buildUpdateAudioPayload(
  name: AUDIO_NAME,
  enabled: boolean | undefined,
  url: string | null
): Record<string, string | boolean> {
  const updatedAttr: Record<string, string | boolean> = {};

  switch (name) {
    case 'welcomeMessage': {
      updatedAttr.welcomeMessageEnabled = !!enabled;
      if (url) {
        updatedAttr.welcomeMessage = url;
        updatedAttr.welcomeMessageContent = url;
      }
      break;
    }
    case 'missedCallMessage': {
      updatedAttr.missedCallVoicemail = !!enabled;
      if (url) {
        updatedAttr.missedCallMessage = url;
        updatedAttr.voicemailMessage = url;
      }
      break;
    }
    case 'afterHoursMessage': {
      updatedAttr.afterHoursVoicemail = !!enabled;
      if (url) {
        updatedAttr.afterHoursMessage = url;
        updatedAttr.closedMessage = url;
      }
      break;
    }
    default:
      updatedAttr[name] = url!;
  }
  return updatedAttr;
}

/**
 * Hook to update audio that can be text to speech, music or uploaded mp3 file
 * @param useUpdateUpdateAudio Object containing necessary information to update an audio
 * @returns Object holding submmited state and a function responsible for updating audio
 */
export function useUpdateAudio({
  numberId,
  name,
  title,
  onCompleted,
}: UseUpdateUpdateAudio): UpdateUpdateAudioReturn {
  const { t } = useTranslation();
  const { track } = useTracker();

  const [generateUrl] = useGenerateAudioSettingUrl(numberId);
  const [submitted, setSubmitted] = useState(false);
  const [mutate] = useGraphMutationWithFeedback<
    UpdateNumberMutation,
    UpdateNumberMutationVariables
  >(
    UPDATE_NUMBER_MUTATION,
    {
      type: 'form_snackbar',
    },
    {
      onCompleted: () => {
        setSubmitted(true);
        onCompleted();
      },
    }
  );

  const updateAudio = useCallback(
    async (
      values: AudioSettingFormValues,
      form: FormApi<AudioSettingFormValues, AudioSettingFormValues>
    ) => {
      setSubmitted(false);
      /** Have fields related to audioSettings been changed */
      const isAudioSettingDirty = Object.keys(form.getState().dirtyFields).some((fieldName) =>
        fieldName.includes('audioSetting')
      );

      try {
        const url = isAudioSettingDirty ? await generateUrl(values.audioSetting) : null;
        const { error } = await mutate(
          {
            variables: {
              input: {
                data: {
                  id: parseInt(numberId),
                  type: 'number',
                  attributes: buildUpdateAudioPayload(name, values.enabled, url),
                },
              },
              numberId: parseInt(numberId),
            },
          },
          {
            successMessage:
              title &&
              t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
                name: title,
              }),
          }
        );
        track({
          event: 'audio_settings_confirmed',
          payload: {
            type: getAudioFileTrackingType(values.audioSetting.type, values.customFileFlow),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            number_id: numberId,
            setting: name,
          },
        });
        return error;
      } catch (e) {
        return handleFormErrors(e as ClientException);
      }
    },
    [generateUrl, mutate, numberId, name, title, t, track]
  );

  return { updateAudio, submitted };
}
