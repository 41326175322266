import { useCallback, useState } from 'react';

import { useFetchLineIdAndRetry } from './useFetchLineIdAndRetry';

import { logger } from '@config/logger.config';
import { useGraphMutation, useGraphQuery } from '@dashboard/library';
import {
  AddDispatchGroupMutation,
  AddDispatchGroupMutationVariables,
} from '@generated/AddDispatchGroupMutation';
import { CreateLine, CreateLineVariables } from '@generated/CreateLine';
import { GetSignupInformation_getSignupInformation } from '@generated/GetSignupInformation';
import { SearchTotalQuery } from '@generated/SearchTotalQuery';
import { UpdateAgentMutation, UpdateAgentMutationVariables } from '@generated/UpdateAgentMutation';
import { ADD_DISPATCH_GROUP_MUTATION } from '@graphql/mutations/AddDispatchGroupMutation';
import { CREATE_NUMBER_LINE_MUTATION } from '@graphql/mutations/CreateNumberLineMutation';
import { UPDATE_AGENT_MUTATION } from '@graphql/mutations/UpdateAgentMutation';
import { SEARCH_TOTAL_QUERY } from '@graphql/queries/SearchTotalQuery';
import { DISPATCH_GROUP_TYPE } from '@hooks/useAddDispatchGroup';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useRegionProvider } from '@hooks/useRegionProvider/useRegionProvider';

export type UseCreateNumberReturn = {
  createNumber: () => Promise<string | null | undefined>;
  associateNumberWithUser: (numberId: string) => Promise<void>;
};

export function useCreateNumber(
  signupInformation?: GetSignupInformation_getSignupInformation
): UseCreateNumberReturn {
  const { showOnboardingTrial } = useFeatures();
  const { fetchLineIdAndRetry } = useFetchLineIdAndRetry();
  const {
    currentUser: { ID: currentUserId },
  } = useGlobalData();
  const [addDispatchGroup] = useGraphMutation<
    AddDispatchGroupMutation,
    AddDispatchGroupMutationVariables
  >(ADD_DISPATCH_GROUP_MUTATION);

  const [updateAgent] = useGraphMutation<UpdateAgentMutation, UpdateAgentMutationVariables>(
    UPDATE_AGENT_MUTATION
  );

  const { data: totals } = useGraphQuery<SearchTotalQuery>(SEARCH_TOTAL_QUERY, {
    skip: !showOnboardingTrial,
  });
  // State to manage request status
  const [numberPurchaseAttempted, setNumberPurchaseAttempted] = useState(false);
  const [createLine] = useGraphMutation<CreateLine, CreateLineVariables>(
    CREATE_NUMBER_LINE_MUTATION
  );
  // Get region details using custom hook
  const { getRegionId } = useRegionProvider(signupInformation);

  const createNumber = useCallback(async (): Promise<string | null | undefined> => {
    if (!showOnboardingTrial) {
      return null;
    }
    if (!signupInformation?.phoneNumber) {
      return null;
    }
    // Do not run if totals are not available or if there are already numbers
    if (
      (!totals?.searchLines?.total && totals?.searchLines?.total !== 0) ||
      totals.searchLines.total > 0
    ) {
      return null;
    }
    if (numberPurchaseAttempted) {
      return null;
    }
    const regionId = await getRegionId();
    if (!regionId) {
      return null;
    }
    setNumberPurchaseAttempted(true);
    const name = 'default-number';
    const capabilities = ['voice'];

    const inputData = {
      name: name.trim(),
      regionId,
      capabilities,
      phoneType: 'geographic_local',
    };
    // Execute the mutation to create a line
    const { data } = await createLine({
      variables: {
        input: inputData,
      },
      update: (cache) => {
        cache.updateQuery({ query: SEARCH_TOTAL_QUERY }, (cachedData) => ({
          ...cachedData,
          searchLines: {
            ...cachedData.searchLines,
            total: cachedData.searchLines.total + 1,
          },
        }));
      },
    });
    if (!data?.createLine) {
      logger.error('useCreateNumber: Could not create number', { data });
    }
    if (data?.createLine.newPurchasingFlowEnabled) {
      const legacyNumberId = await fetchLineIdAndRetry(data.createLine.ID);
      if (!legacyNumberId) {
        return null;
      }
      return legacyNumberId;
    }
    return data?.createLine.ID;
  }, [
    createLine,
    fetchLineIdAndRetry,
    getRegionId,
    numberPurchaseAttempted,
    showOnboardingTrial,
    signupInformation?.phoneNumber,
    totals?.searchLines?.total,
  ]);

  const associateNumberWithUser = useCallback(
    async (numberId: string) => {
      await addDispatchGroup({
        variables: {
          numberId,
          input: {
            data: {
              type: 'dispatch_group',
              attributes: {
                priority: 1,
              },
              relationships: {
                dispatchable: {
                  data: {
                    id: currentUserId,
                    type: DISPATCH_GROUP_TYPE.USER,
                  },
                },
              },
            },
          },
        },
      });
      await updateAgent({
        variables: {
          input: {
            ID: currentUserId,
            defaultOutPhoneNumberID: numberId,
          },
        },
      });
    },
    [currentUserId, addDispatchGroup, updateAgent]
  );
  return { createNumber, associateNumberWithUser };
}
