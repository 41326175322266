import { gql } from '@apollo/client';

export const CREATE_ONBOARDING_MUTATION = gql`
  mutation CreateOnboardingMutation {
    createOnboarding(onboarding: {}) {
      ... on Onboarding {
        __typename
        cta
        ctaButtonTitle
        ctaButtonUrl
        steps {
          content
          redirectUrl
          stepStatus
          stepType
          stepTitle
          updatedAt
        }
      }
      ... on UserInputException {
        __typename
        code
      }
      ... on GenericException {
        __typename
        code
      }
    }
  }
`;
