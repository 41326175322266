import { useCallback, useState } from 'react';

import {
  AudioSetting,
  AUDIO_SETTING_TYPE,
  FileAudioSetting,
} from '../../pages/number-detail-call-distribution/AudioSetting/AudioSetting.decl';

import { UseGenerateAudioSettingUrlReturn } from './useGenerateAudioSettingUrl.decl';

import { ApolloError } from '@apollo/client';
import { MAX_AUDIO_SIZE } from '@constants/files.constants';
import {
  AudioFileUploadQuery,
  AudioFileUploadQueryVariables,
  AudioFileUploadQuery_uploadAudioFile_fields,
} from '@generated/AudioFileUploadQuery';
import {
  UpdateTtsMessageMutation,
  UpdateTtsMessageMutationVariables,
} from '@generated/UpdateTtsMessageMutation';
import { UPDATE_TTS_MESSAGE_MUTATION } from '@graphql/mutations/UpdateTtsMessageMutation';
import { AUDIO_FILE_UPLOAD_QUERY } from '@graphql/queries/AudioFileUploadQuery';
import { ClientException, handleApolloError } from '@helpers/errors.helpers';
import { useImperativeQuery } from '@hooks/useImperativeQuery/useImperativeQuery';
import { useGraphMutation } from '@hooks/useMutation';
import { useTextToSpeech } from '@hooks/useTextToSpeech/useTextToSpeech';
import { uploadFile } from '@services/file';
import kebabCase from 'lodash-es/kebabCase';

type AudioFileField = Omit<AudioFileUploadQuery_uploadAudioFile_fields, '__typename'>;

export function formatAudioFileName(string: string): string {
  const newString = string.trim();

  if (newString.endsWith('.mp3')) {
    return newString;
  }
  return `${newString}.mp3`;
}

export function buildFormData(fields: AudioFileField, file: File): FormData {
  const fd = new FormData();
  Object.keys(fields).forEach((key) => {
    // convert field name to kebab case: from ContentType to Content-Type
    if (key === 'successActionStatus') {
      fd.append('success_action_status', fields.successActionStatus);
    } else if (key === 'contentType') {
      fd.append('Content-Type', fields.contentType);
    } else if (key === 'expires') {
      fd.append('Expires', fields.expires);
    } else {
      fd.append(kebabCase(key), fields[key as keyof AudioFileField]);
    }
  });
  fd.append('file', file);
  return fd;
}

/**
 * The hook provides a callback function that generates audio url by number id and audio setting.
 * The generated url will be submitted to update number's audio setting.
 * @param numberId - id of number
 * @returns return a callback to generate audio url and loading state
 */
export function useGenerateAudioSettingUrl(numberId: string): UseGenerateAudioSettingUrlReturn {
  const [mutateTtsMessage, { loading: mutatingTts }] = useGraphMutation<
    UpdateTtsMessageMutation,
    UpdateTtsMessageMutationVariables
  >(UPDATE_TTS_MESSAGE_MUTATION);

  const [uploading, setUploading] = useState(false);

  const { generateLazyPreview } = useTextToSpeech();

  const preUploadFile = useImperativeQuery<AudioFileUploadQuery, AudioFileUploadQueryVariables>({
    query: AUDIO_FILE_UPLOAD_QUERY,
  });

  // return a readable fileUrl to update in number detail
  const uploadFiletoS3 = useCallback(
    async (audioSetting: FileAudioSetting): Promise<string | null> => {
      // early return if file bigger than 10mb
      if (audioSetting.file?.size && audioSetting.file?.size > MAX_AUDIO_SIZE) {
        return null;
      }

      setUploading(true);
      try {
        const name = audioSetting.file?.name
          ? audioSetting.file.name
          : formatAudioFileName(audioSetting.recordAudioName!);

        const {
          data: { uploadAudioFile },
        } = await preUploadFile({ numberId, name });

        const { __typename, ...fields } = uploadAudioFile.fields;
        const formData = buildFormData(fields, audioSetting.file!);
        // the s3 server doesn't accept authorization in request header, so we set the third param as false here
        await uploadFile(uploadAudioFile.url, formData, false);

        return encodeURI(`${uploadAudioFile.readUrl}/${uploadAudioFile.id}`);
      } catch (e) {
        throw new ClientException(handleApolloError(e as ApolloError));
      } finally {
        setUploading(false);
      }
    },
    [numberId, preUploadFile]
  );

  const generateUrl = useCallback(
    async (audioSetting: AudioSetting): Promise<string | null> => {
      if (audioSetting.type === AUDIO_SETTING_TYPE.TEXT_TO_SPEECH) {
        // generate the final preview before updating the message
        const { id: ttsId, language, voice, message } = audioSetting;
        try {
          const preview = await generateLazyPreview(numberId, ttsId, language, voice, message);

          const attributes = preview?.data?.textToSpeechPreview.data.attributes;

          const ttsInput = {
            id: audioSetting.id,
            attributes,
            type: 'tts_message',
            relationships: {
              number: {
                data: {
                  type: 'number',
                  id: numberId,
                },
              },
            },
          };
          const { data } = await mutateTtsMessage({
            variables: {
              numberId,
              ttsId: audioSetting.id,
              input: { data: ttsInput },
            },
          });

          return data ? data.updateTtsMessage.url : /* istanbul ignore next */ null;
        } catch (e) {
          throw new ClientException(handleApolloError(e as ApolloError));
        }
      }
      if (audioSetting.type === AUDIO_SETTING_TYPE.LIBRARY) {
        return audioSetting.url;
      }
      // get file info and upload to s3
      return uploadFiletoS3(audioSetting);
    },
    [generateLazyPreview, mutateTtsMessage, numberId, uploadFiletoS3]
  );

  const loading = mutatingTts || uploading;

  return [generateUrl, { loading }];
}
