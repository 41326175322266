/* eslint-disable @typescript-eslint/naming-convention */

import { FILTER_KEY_MAPPER } from './Report.constants';
import { LOOKER_DATA, LOOKER_STATUS } from './Report.decl';

import { URLSearchObject } from '@components/SearchProvider/usePersistSearchParams';
import { addDays, format, subDays } from 'date-fns';
import invert from 'lodash-es/invert';

const getLookerDateStr = (date: Date): string => format(date, 'yyyy/MM/dd');

// looker's date picker format exclude the endDate from the selected time frame while we want to include it
export function formatDatesString(date: string, direction: 'toLooker' | 'toDashboard'): string {
  if (!date.includes(' to ')) {
    return date;
  }

  const newDates = date.split(' to ');
  const enDate =
    direction === 'toLooker'
      ? addDays(new Date(newDates[1]), 1)
      : subDays(new Date(newDates[1]), 1);

  return `${getLookerDateStr(new Date(newDates[0]))} to ${getLookerDateStr(enDate)}`;
}

export function formatFilters(
  filters: URLSearchObject,
  direction: 'toDashboard' | 'toLooker'
): URLSearchObject {
  const dateKey = direction === 'toDashboard' ? 'Date' : 'date';
  const mapper = direction === 'toDashboard' ? FILTER_KEY_MAPPER : invert(FILTER_KEY_MAPPER);

  return Object.keys(filters).reduce(
    (acc, key) => ({
      ...acc,
      ...(mapper[key] && {
        [mapper[key]]: key === dateKey ? formatDatesString(filters[key], direction) : filters[key],
      }),
    }),
    {} as URLSearchObject
  );
}

export function getLookerStatusError(oldStatus: LOOKER_STATUS): LOOKER_STATUS {
  return oldStatus === LOOKER_STATUS.STORED_DATA
    ? LOOKER_STATUS.DATA_ERROR
    : LOOKER_STATUS.OTHER_ERROR;
}

export function checkDataToLoad(
  hasSearchedData: string,
  hasStoredData: URLSearchObject,
  lookerStatus: LOOKER_STATUS
): LOOKER_DATA {
  if (
    hasSearchedData &&
    lookerStatus !== LOOKER_STATUS.DATA_ERROR &&
    lookerStatus !== LOOKER_STATUS.OTHER_ERROR
  ) {
    return 'searched-data';
  }
  if (
    hasStoredData &&
    lookerStatus !== LOOKER_STATUS.DATA_ERROR &&
    lookerStatus !== LOOKER_STATUS.OTHER_ERROR
  ) {
    return 'stored-data';
  }
  return 'default-data';
}

export function checkTileEventError(tileStatus: string, lookerStatus: LOOKER_STATUS): boolean {
  if (
    tileStatus === 'error' &&
    lookerStatus !== LOOKER_STATUS.DATA_ERROR &&
    lookerStatus !== LOOKER_STATUS.OTHER_ERROR
  ) {
    return true;
  }
  return false;
}
