import { NotFoundScreen } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function NotFoundErrorScreen() {
  const { t } = useTranslation();

  return (
    <NotFoundScreen
      title={t('error_screen.404.title')}
      subtitle={t('error_screen.404.message')}
      data-test='not-found-error-screen'
    />
  );
}
