import { useCallback } from 'react';

import styled from 'styled-components';

import { Button, Flex, Spacer, Typography, Divider, Grid } from '@aircall/tractor-v2';
import number1 from '@assets/images/number1.svg';
import number2 from '@assets/images/number2.svg';
import number3 from '@assets/images/number3.svg';
import { CompanyLogo } from '@components/CompanyLogo/CompanyLogo';
import { usePermissions } from '@hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Images = styled(Flex)`
  flex-direction: column;

  img {
    width: 362px;
  }

  img:nth-child(2) {
    margin-left: 108px;
  }
`;

const Container = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const Content = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: 100px;
`;

const Texts = styled(Flex)`
  width: 600px;
  flex-direction: column;
  margin-top: -100px;
`;

export function UnauthorizedPage() {
  const { t } = useTranslation();
  const { getAccessibleRoute } = usePermissions();
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    const route = getAccessibleRoute();
    if (route) {
      navigate(route);
    }
  }, [getAccessibleRoute, navigate]);

  return (
    <Grid data-test='denied-page' backgroundColor='white' gridTemplateRows='auto auto 1fr' h='100%'>
      <Flex alignItems='center' h='64px'>
        <CompanyLogo />
        <Divider size='xSmall' />
      </Flex>
      <Divider orientation='horizontal' size='xSmall' />
      <Container>
        <Content>
          <Images>
            <img src={number1} alt='number' />
            <img src={number2} alt='number' />
            <img src={number3} alt='number' />
          </Images>
          <Texts>
            <Spacer direction='vertical' space='l'>
              <Typography variant='headingBoldXL'>{t('denied.text')}</Typography>
              <Typography variant='headingLightS'>{t('denied.contact')}</Typography>
              <Spacer space='m'>
                <Button data-test='denied-page-go-back' onClick={handleGoBack}>
                  {t('denied.go_back')}
                </Button>
              </Spacer>
            </Spacer>
          </Texts>
        </Content>
      </Container>
    </Grid>
  );
}
