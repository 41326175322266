import { HOME_ROUTE } from '../../constants/routes.constants';
import { useAuthenticationState } from '../../hooks/useAuthenticationState';

import { AUTHENTICATION_STATUS } from '@state/app/authentication/authentication.decl';
import { Navigate } from 'react-router';

export function PublicOnly({ children }: { children: JSX.Element }) {
  const { authState } = useAuthenticationState();

  if (authState.status === AUTHENTICATION_STATUS.AUTHENTICATED) {
    return <Navigate to={HOME_ROUTE} replace />;
  }

  return children;
}
