import { gql } from '@apollo/client';

export const AUTHORIZE_CHANNELS_MUTATION = gql`
  mutation AuthorizeChannels($input: AuthorizeChannelsInput!) {
    authorizeChannels(input: $input) {
      authorizations {
        ... on PrivateChannel {
          authorized
          authPayload {
            auth
          }
        }

        ... on PresenceChannel {
          authorized
          authPayload {
            auth
            channel_data
          }
        }

        ... on PrivateEncryptedChannel {
          authorized
        }

        ... on PublicChannel {
          authorized
        }
      }
    }
  }
`;
