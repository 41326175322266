import { gql } from '@apollo/client';

export const DELETE_BLACKLISTED_NUMBER_MUTATION = gql`
  mutation DeleteBlacklistedNumberMutation($numberId: String!) {
    deleteBlacklistedNumber(numberId: $numberId)
      @rest(
        type: "Void"
        path: "blacklisted_phone_numbers/{args.numberId}"
        method: "DELETE"
        endpoint: "v3"
      ) {
      _
    }
  }
`;
