/* eslint-disable @typescript-eslint/naming-convention,  @typescript-eslint/no-use-before-define, no-underscore-dangle, @typescript-eslint/explicit-module-boundary-types */

import isNil from 'lodash-es/isNil';
import pickBy from 'lodash-es/pickBy';

interface NumberRelatedObject {
  id: string;
  type: string;
}
interface NumberRelationshipObject {
  data: NumberRelatedObject | NumberRelatedObject[];
}

interface NumberMeta {
  page: number;
  pages: number;
  count: number;
  total: number;
}

export interface V4Response {
  id: string;
  // attributes differ according to entity type
  attributes: Record<string, unknown>;
  type:
    | 'number'
    | 'dispatch_group'
    | 'ivr_setting'
    | 'team'
    | 'user'
    | 'supervision'
    | 'tts_message';
  relationships: Record<string, NumberRelationshipObject>;
}
interface NumberListResponse {
  data: V4Response[];
  meta: NumberMeta;
}

export interface NumberDetailResponse {
  data: V4Response;
  included: V4Response[];
}

function transformNumberRelatedObject(
  numberRelatedObject: unknown,
  included: V4Response[] | undefined,
  type?: string
) {
  if (!numberRelatedObject) {
    return null;
  }

  const { id, type: numberRelatedObjectType } = numberRelatedObject as NumberRelatedObject;
  const data = included?.find(
    (item: V4Response) => item.id === id && item.type === (type || numberRelatedObjectType)
  );

  return data && v4ObjectTransformer(data);
}

function transformRelationshipObject(itemExists: boolean, included: V4Response[], type: string) {
  if (!itemExists) {
    return null;
  }

  return included
    .filter((filteredItem: V4Response) => filteredItem.type === type)
    .map((foundItem: V4Response) => v4ObjectTransformer(foundItem, included));
}

function getTransformedRelationships(
  relationships: Record<string, NumberRelationshipObject>,
  included: V4Response[]
) {
  return pickBy(
    {
      dispatchGroups: transformRelationshipObject(
        !!relationships.dispatch_groups,
        included,
        'dispatch_group'
      ),
      ivrSettings: transformRelationshipObject(
        !!relationships.ivr_settings,
        included,
        'ivr_setting'
      ),
      supervisions: transformRelationshipObject(
        !!relationships.supervisions,
        included,
        'supervision'
      ),
      tts_messages: transformRelationshipObject(
        !!relationships.tts_messages,
        included,
        'tts_message'
      ),
    },
    (key) => !isNil(key)
  );
}

export function v4ObjectTransformer(
  data: V4Response,
  included?: V4Response[]
): Record<string, unknown> {
  const { attributes, id, relationships, type } = data;
  let transformedRes: Record<string, unknown> = { id, ...attributes };

  // eslint-disable-next-line default-case
  switch (type) {
    case 'user':
      transformedRes.id = parseInt(transformedRes.id as string);
      transformedRes.__typename = 'User';
      break;
    case 'team':
      transformedRes = {
        __typename: 'Team',
        ID: transformedRes.id,
        imageName: transformedRes.image_name,
        name: transformedRes.name,
        agentsIds: (relationships.users.data as NumberRelatedObject[]).map((user) => user.id),
      };
      break;
    case 'tts_message':
      transformedRes.__typename = 'TtsMessage';
      transformedRes.defaultUrl = transformedRes.default_url;
      break;
    case 'number':
      /**
       * The state we receive from the v4 endpoint is one of "always_opened", "always_closed", "auto"
       * We updated these values in the internal API to be uppercased, so we return the same
       * values here to be consistent between our REST and GraphQL endpoints.
       */
      if (attributes.state) {
        transformedRes.state = (attributes.state as string).toUpperCase();
      }

      if (included) {
        transformedRes = {
          ...transformedRes,
          ...getTransformedRelationships(relationships, included),
        };
      }
      break;
    case 'dispatch_group':
      transformedRes.__typename = 'DispatchGroup';
      // add team detail or user detail in `dispatchable`
      transformedRes.dispatchable = transformNumberRelatedObject(
        relationships.dispatchable?.data,
        included
      );
      break;
    case 'ivr_setting':
      transformedRes.__typename = 'IvrSettingRest';
      // add number detail in `redirectingNumber`
      transformedRes.redirectingNumber = transformNumberRelatedObject(
        relationships.redirecting_number?.data,
        included,
        'number'
      );
      break;
    case 'supervision': {
      const numberRelatedObject = relationships.user?.data as NumberRelatedObject | undefined;
      transformedRes.__typename = 'SupervisionItem';
      transformedRes.userId = numberRelatedObject?.id;
      // add number detail in `user`
      transformedRes.user = transformNumberRelatedObject(
        relationships.user?.data,
        included,
        'user'
      );
      break;
    }
  }

  return transformedRes;
}

export function detailTransformer({ data, included }: NumberDetailResponse) {
  return v4ObjectTransformer(data, included);
}

export function listTransformer({ data, meta }: NumberListResponse) {
  return {
    data: data.map((item: V4Response) => ({
      __typename: 'Number',
      ...v4ObjectTransformer(item),
    })),
    meta,
  };
}
