import { Box, getSpace, getColor } from '@aircall/tractor-v2';
import styled from '@xstyled/styled-components';

export const CardContainer = styled(Box)`
  position: relative;
  padding: ${getSpace('l')} 0;
  border: 1px solid ${getColor('graphic-interactive-default')};
  border-radius: ${({ theme }) => theme.space.sm}px;
  background-color: ${getColor('surface-default')};

  &:hover {
    border-color: ${getColor('graphic-interactive-default-hover')};
    box-shadow: ${({ theme }) => theme.shadows[5]};
  }

  &:focus,
  &:active {
    border-color: ${getColor('graphic-interactive-default-active')};
    box-shadow: ${({ theme }) => theme.shadows[5]};
  }
`;
