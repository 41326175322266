export enum MUSIC_ON_HOLD_AUDIO_TYPE {
  LIBRARY = 'library',
  FILE = 'file',
}

export interface MusicOnHoldAudioLibrary {
  type: MUSIC_ON_HOLD_AUDIO_TYPE.LIBRARY;
  url: string;
}

export interface MusicOnHoldAudioFile {
  type: MUSIC_ON_HOLD_AUDIO_TYPE.FILE;
  file: File;
}

export interface MusicOnHoldFormValues {
  audioSetting: MusicOnHoldAudioLibrary | MusicOnHoldAudioFile;
}
