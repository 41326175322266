import { gql } from '@apollo/client';

export const LINE_BUSINESS_HOURS_QUERY = gql`
  query LineBusinessHoursQuery($lineID: ID!) {
    getLineBusinessHours(lineID: $lineID) {
      availability
      slots {
        days
        from
        to
      }
      timezone
    }
  }
`;
