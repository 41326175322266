import { ApolloLink, GraphQLRequest } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { COOKIE_KEYS } from '@constants/auth.constants';
import Cookies from 'js-cookie';

/**
 * Adds the token to every request made by http-link-rest
 * Ref: https://www.apollographql.com/docs/link/links/rest/#link-context
 */
const operationsWithJwtToken = [
  'StrategyQuery',
  'GoogleElibilityQuery',
  'AuthorizeZendeskQuery',
  'OauthCodeQuery',
];

export const authRestLink: ApolloLink = setContext(
  (operation: GraphQLRequest, context: { headers: Record<string, unknown> }) => {
    const { headers } = context;
    const jwtToken = Cookies.get(COOKIE_KEYS.TOKEN);
    const legacyToken = Cookies.get(COOKIE_KEYS.LEGACY_TOKEN);

    let token = legacyToken || jwtToken;

    // Some operations do not work with legacyToken and need the jwtToken
    if (operationsWithJwtToken.includes(operation.operationName!)) {
      token = jwtToken;
    }

    return {
      headers: {
        ...headers,
        ...(token && { authorization: `Bearer ${token}` }),
      },
    };
  }
);

/**
 * Set header with token, everytime a graphQL request is being excuted
 * Ref: https://www.apollographql.com/docs/link/links/context
 */
export const authGraphqlLink: ApolloLink = setContext(async (_, { headers }) => {
  const jwtToken = Cookies.get(COOKIE_KEYS.TOKEN);
  return {
    headers: {
      ...headers,
      authorization: jwtToken,
    },
  };
});
