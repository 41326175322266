import { useCallback } from 'react';

import { TRANSLATION_PREFIX } from '../config';
import { DiagramToggleCard } from '../DiagramToggleCard/DiagramToggleCard';
import { DiagramBranch } from '../FlowDiagram';
import { GroupSectionTitle } from '../GroupSectionTitle';

import { RefreshOutlined } from '@aircall/icons';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { NumberDetailQuery_numberDetail } from '@generated/NumberDetailQuery';
import { useUpdateNumberToggles } from '@pages/number-detail-settings/hooks/useUpdateNumberToggles';
import { useTranslation } from 'react-i18next';

interface ReplayIvrSectionProps {
  numberDetail: NumberDetailQuery_numberDetail;
}

export function ReplayIvrSection({ numberDetail }: ReplayIvrSectionProps) {
  const { t } = useTranslation();
  const { updateNumberToggles } = useUpdateNumberToggles({});
  const { replayIvrMessage } = numberDetail;

  const handleChangeReplayMessage = useCallback(() => {
    updateNumberToggles(
      { replayIvrMessage: !replayIvrMessage },
      {
        successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
          name: t(`${TRANSLATION_PREFIX}.replay_ivr_section.option`),
        }),
      }
    );
  }, [replayIvrMessage, t, updateNumberToggles]);

  return (
    <DiagramBranch mt={48} mb='m' branchOffsetTop='56px'>
      <GroupSectionTitle
        title={t(`${TRANSLATION_PREFIX}.replay_ivr_section.title`)}
        icon={RefreshOutlined}
      />
      <DiagramToggleCard
        mt='xxs'
        data-test='replay-toggle'
        onChange={handleChangeReplayMessage}
        title={t(`${TRANSLATION_PREFIX}.replay_ivr_section.replay_label`)}
        tooltip={t(`${TRANSLATION_PREFIX}.replay_ivr_section.tooltip`)}
        toggleValue={replayIvrMessage}
      />
    </DiagramBranch>
  );
}
