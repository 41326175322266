import { useCallback, useEffect } from 'react';

import { UsePriorityUpdateReturn } from './usePriorityUpdate.decl';

import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import {
  UpdateDispatchGroupMutation,
  UpdateDispatchGroupMutationVariables,
  UpdateDispatchGroupMutation_updateDispatchGroup,
} from '@generated/UpdateDispatchGroupMutation';
import { UPDATE_DISPATCH_GROUP_MUTATION } from '@graphql/mutations/UpdateDispatchGroupMutation';
import { NUMBER_DETAIL_QUERY } from '@graphql/queries/NumberDetailQuery';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useCancellableLazyQuery } from '@hooks/useQuery';
import { useTranslation } from 'react-i18next';

/**
 * A hook used to update priority of dispatch group
 * @param numberId - The number id.
 * @param onPriorityUpdateFails - Callback to call when update fails
 * @returns An object holding mutate callback, states and utilities
 */
export function usePriorityUpdate(
  numberId: string,
  onPriorityUpdateFails?: () => void
): UsePriorityUpdateReturn {
  const { t } = useTranslation();
  const { error, lazyQuery, cancel } = useCancellableLazyQuery(
    NUMBER_DETAIL_QUERY,
    {
      variables: { id: numberId },
      // always use the response from the network as the source of truth
      fetchPolicy: 'network-only',
    },
    // disable the error snackbar because it's called right after the mutation
    // (avoid the network error shown twice)
    false
  );

  const [mutate, { loading }] = useGraphMutationWithFeedback<
    UpdateDispatchGroupMutation,
    UpdateDispatchGroupMutationVariables
  >(
    UPDATE_DISPATCH_GROUP_MUTATION,
    {
      type: 'snackbar',
    },
    {
      onCompleted: () => {
        // once the mutation complete, refetch the latest order (priority) of the cards
        lazyQuery();
      },
      onError: ({ graphQLErrors }) => {
        // roll back to previous order if the mutation fails
        onPriorityUpdateFails && onPriorityUpdateFails();

        // throws the error to display the error snackbar
        // currently, the fail requests are handled through graphQLErrors
        throw graphQLErrors;
      },
    }
  );

  const updatePriority = useCallback(
    async (group: UpdateDispatchGroupMutation_updateDispatchGroup, priority: number) => {
      const { id: groupId } = group;

      // cancel the pending request to avoid getting the outdated data
      cancel();

      mutate(
        {
          variables: {
            numberId,
            dispatchGroupId: groupId,
            input: {
              data: {
                type: 'dispatch_group',
                id: groupId,
                attributes: {
                  priority,
                },
              },
            },
          },
          // should never update the cache here,
          // the sorted results from the backend should be updated through the cancellable lazyQuery
          fetchPolicy: 'no-cache',
        },
        {
          successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
            name: t('number_details.tabs.call_distribution'),
          }),
        }
      );
    },
    [cancel, mutate, numberId, t]
  );

  // rolls back to the previous results once the refetch query (lazyQuery) error occurs
  useEffect(() => {
    if (error) {
      onPriorityUpdateFails && onPriorityUpdateFails();
    }
  }, [error, onPriorityUpdateFails]);

  return { updatePriority, loading, cancelFetch: cancel };
}
