import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { useReportPage } from './useReportPage';

import { getColor } from '@aircall/tractor-v2';
import { DataLayout } from '@components/DataLayout/DataLayout';
import { Loading } from '@dashboard/library';
import {
  GetAnalyticsDashboardUrlQuery,
  GetAnalyticsDashboardUrlQueryVariables,
} from '@generated/GetAnalyticsDashboardUrlQuery';
import { AnalyticsDashboardType } from '@generated/globalTypes';
import { GET_ANALYTICS_DASHBOARD_URL_QUERY } from '@graphql/queries/GetAnalyticsDashboardUrlQuery';

const LookerContainer = styled.iframe`
  border: 1px solid ${getColor('neutral-300')};
  background-color: ${getColor('neutral-0')};
`;

interface ReportContentProps {
  url: string | undefined;
  title: string;
}

function ReportContent({ url, title }: ReportContentProps) {
  const { height, iFrameRef, setUrl } = useReportPage();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setUrl(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <>
      {loading && <Loading />}
      <LookerContainer
        ref={iFrameRef}
        id='looker-report'
        data-test='looker-report'
        title={title}
        width='100%'
        height={height}
        onLoad={() => setLoading(false)}
        src={url}
      />
    </>
  );
}

export interface ReportRenderProps {
  iFrameRef: React.RefObject<HTMLIFrameElement>;
  url?: string;
}
export interface ReportProps {
  type: AnalyticsDashboardType;
  title: string;
}

export function Report({ type, ...otherProps }: ReportProps) {
  const { error } = useReportPage();
  const [lookerKey, setLookerKey] = useState(0);

  // use react's key prop to force the component to remount
  useEffect(() => {
    if (error) {
      setLookerKey((oldValue) => oldValue + 1);
    }
  }, [error]);

  return (
    <DataLayout<GetAnalyticsDashboardUrlQuery, GetAnalyticsDashboardUrlQueryVariables>
      key={`looker-key-${lookerKey}`}
      query={GET_ANALYTICS_DASHBOARD_URL_QUERY}
      queryOptions={{
        variables: {
          input: {
            dashboardType: type,
            embedDomain: `${window.location.protocol}//${window.location.host}`,
            sdkVersion: '1',
          },
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
      }}
    >
      {({
        data: {
          getAnalyticsDashboardUrl: { url },
        },
      }) => <ReportContent url={url} {...otherProps} />}
    </DataLayout>
  );
}
