import { IntegrationFlowInstallBridge } from './Bridge/IntegrationFlowInstallBridge';
import { IntegrationFlowInstallPartner } from './Partner/IntegrationFlowInstallPartner';
import { IntegrationFlowInstallTray } from './Tray/IntegrationFlowInstallTray';
import { IntegrationFlowInstallWorkato } from './Workato/IntegrationFlowInstallWorkato';

import {
  APPLICATIONS_NAMES,
  APPLICATIONS_SOURCES,
  APPLICATIONS_TYPES,
} from '@constants/integrations.constants';
import { Loading } from '@dashboard/library';
import { ApplicationsListQuery_getApplications } from '@generated/ApplicationsListQuery';
import { isWebhookIntegration } from '@helpers/integrations.helpers';
import { useIntegrations } from '@hooks/useIntegrations';
import { IntegrationFlowInstallWebhook } from '@pages/integration-flow-install/Webhook/IntegrationFlowInstallWebhook';
import { useParams } from 'react-router-dom';

export function IntegrationFlowInstallPage() {
  const { isLoading: applicationsLoading, getApplicationByName } = useIntegrations();
  const { applicationName, applicationType } = useParams();

  if (applicationsLoading) {
    return <Loading data-test='loading' />;
  }

  const application = getApplicationByName(applicationName as APPLICATIONS_NAMES);
  const hubApplication = application as ApplicationsListQuery_getApplications;
  const bridgeApplication = application as ApplicationsListQuery_getApplications;

  if (isWebhookIntegration(applicationName)) {
    return <IntegrationFlowInstallWebhook />;
  }

  if ((applicationType as APPLICATIONS_TYPES) === APPLICATIONS_TYPES.BRIDGE) {
    return <IntegrationFlowInstallBridge application={bridgeApplication} />;
  }

  if (hubApplication?.source === APPLICATIONS_SOURCES.TRAY) {
    return <IntegrationFlowInstallTray />;
  }

  if ((hubApplication?.source as APPLICATIONS_SOURCES) === APPLICATIONS_SOURCES.WORKATO) {
    return <IntegrationFlowInstallWorkato />;
  }

  if (
    [APPLICATIONS_SOURCES.PARTNER, APPLICATIONS_SOURCES.IN_HOUSE].includes(
      hubApplication?.source as APPLICATIONS_SOURCES
    )
  ) {
    return <IntegrationFlowInstallPartner application={hubApplication} />;
  }

  return null;
}
