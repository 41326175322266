import styled from 'styled-components';

import { Flex, Icon, Typography } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { ResellerConfig } from '@config/resellers.config';
import { useTranslation } from 'react-i18next';

const StyledLink = styled(ExternalLink)`
  &:hover {
    text-decoration: none;
  }
`;

export type OnboardingApplicationName = 'iOS' | 'Android' | 'Chrome' | 'Firefox' | 'Edge';

export type OnboardingDownloadLinkProps = {
  applicationName: OnboardingApplicationName;
  iconComponent: React.FunctionComponent;
};

export function OnboardingDownloadLink({
  applicationName,
  iconComponent,
}: OnboardingDownloadLinkProps) {
  const { t } = useTranslation();
  return (
    <StyledLink
      href={t(
        `resellers.${
          ResellerConfig.identifier
        }.onboarding.application.download_link_${applicationName.toLowerCase()}`
      )}
      target='_blank'
      rel='noreferrer noopener'
      data-test='onboarding-download-link'
    >
      <Flex flexDirection='row' alignItems='center'>
        <Icon component={iconComponent} size={32} mr='10px' />
        <Typography variant='bodyMediumS'>{applicationName}</Typography>
      </Flex>
    </StyledLink>
  );
}
