import { useMemo } from 'react';

import { useUpdateIvrSetting } from '../hooks/useUpdateIvrSetting';
import { IvrSettingFormValues, IvrSettingModal } from '../IvrSettingModal';

import { NumberDetailQuery_numberDetail_ivrSettings } from '@generated/NumberDetailQuery';
import { normalizeNumber } from '@helpers/phoneNumber.helpers';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

interface EditIvrSettingModalProps {
  ivrSetting: NumberDetailQuery_numberDetail_ivrSettings;
  show: boolean;
  onHide: () => void;
  availableKeyOptions: { value: string; label: string }[];
  countryCode: string;
}

export function getIvrSettingFormValues({
  key,
  redirectingNumber,
  redirectingExternalNumber,
}: NumberDetailQuery_numberDetail_ivrSettings): IvrSettingFormValues {
  if (redirectingExternalNumber) {
    return { type: 'external', key, externalNumber: normalizeNumber(redirectingExternalNumber) };
  }
  if (redirectingNumber !== null) {
    return { type: 'internal', key, internalNumber: redirectingNumber };
  }
  // deleted internal number
  return { type: 'internal', key, internalNumber: undefined };
}

export function EditIvrSettingModal({
  ivrSetting,
  show,
  onHide,
  availableKeyOptions,
  countryCode,
}: EditIvrSettingModalProps) {
  const { numberId } = useParams();
  const { id, key } = ivrSetting;
  const formValue = useMemo(() => getIvrSettingFormValues(ivrSetting), [ivrSetting]);

  const { updateIvrSetting } = useUpdateIvrSetting({
    numberId,
    ivrSettingId: id,
    onCompleted: onHide,
  });
  const seletedKey = { label: key, value: key };
  const { t } = useTranslation();
  return (
    <IvrSettingModal
      title={t('number_details.settings.call_distribution_section.ivr_setting_modal.edition_title')}
      onHide={onHide}
      show={show}
      onSubmit={updateIvrSetting}
      values={formValue}
      keyOptions={[seletedKey, ...availableKeyOptions]}
      countryCode={countryCode}
      numberId={numberId}
    />
  );
}
