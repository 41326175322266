import { IntegrationFlowComplete } from '@components/IntegrationFlow/IntegrationFlowComplete/IntegrationFlowComplete';
import { ResellerConfig } from '@config/resellers.config';
import { APPLICATIONS_NAMES } from '@constants/integrations.constants';
import { INTEGRATIONS_ROUTE, INTEGRATION_ROUTE } from '@constants/routes.constants';
import { useNavigateWithParamsReplace } from '@dashboard/library';
import { useQueryParams } from '@hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export function IntegrationFlowCompletePage() {
  const { t } = useTranslation();
  const { searchParams } = useQueryParams();
  const { applicationName, applicationType } = useParams();
  const navigate = useNavigateWithParamsReplace();
  const integrationId = searchParams.get('integration_id');

  function handleSubmit() {
    if (!integrationId) {
      return navigate(INTEGRATIONS_ROUTE);
    }

    return navigate(INTEGRATION_ROUTE, {
      integrationName: applicationName,
      applicationType,
      integrationId,
    });
  }

  return (
    <IntegrationFlowComplete
      applicationName={applicationName as APPLICATIONS_NAMES}
      learnMoreLink={t(
        `resellers.${ResellerConfig.identifier}.integration_flow.complete.learn_more_${applicationName}`
      )}
      submitButtonText={t('app.finish')}
      onSubmit={() => {
        handleSubmit();
      }}
    />
  );
}
