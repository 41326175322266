/* eslint-disable @typescript-eslint/naming-convention */
import {
  GlobalDataQuery_companySettings_plans_features as CompanyPlanFeatures,
  GlobalDataQuery_companySettings as CompanySettings,
} from '@generated/GlobalDataQuery';

/*
BEFORE
{
  ...
  stripePlans: {
    friend: {
      level: 101,
      nbUsersIncluded: 113,
      nbLinesIncluded: 113,
      nbUsersMax: 1000,
      nbTeamsMax: 3,
      nbLinesMax: 10000,
      ...
    },
    proOct2014: {
      ...
    },
    starterJune2015: {
      ...
    },
    premiumJune2015: {
      ...
    },
    enterpriseJune2015: {
      ...
    },
    starterSept2015: {
      ...
    },
    ...
  }
}

AFTER
{
  ...
  plans: [
    {
      name: "friend",
      features: {
        level: 101,
        nbUsersIncluded: 113,
        nbLinesIncluded: 113,
        nbUsersMax: 1000,
        nbTeamsMax: 3,
        nbLinesMax: 10000,
        ...
      }
    },
    {
      name: "partner",
      features: ...
    }
  ]
}
*/

export default async function companySettingsTransformer(
  response: Response
): Promise<CompanySettings> {
  const { stripe_plans, ...data } = await response.json();

  return {
    ...data,
    plans: Object.entries(stripe_plans).map(([name, features]) => ({
      __typename: 'CompanyPlan',
      name,
      features: {
        ...(features as CompanyPlanFeatures),
        __typename: 'CompanyPlanFeatures',
      },
    })),
  };
}
