import { Flex, Grid, Typography } from '@aircall/tractor-v2';
import NoNumberImg from '@assets/images/number-empty-view.svg';
import CreateNumberDropdown from '@components/CreateNumberDropdown/CreateNumberDropdown';
import { useTranslation } from 'react-i18next';

export function NumberEmptyView() {
  const { t } = useTranslation();

  return (
    <Flex data-test='number-empty-view' alignItems='center' justifyContent='center' mt='72px'>
      <Flex flexDirection='column' alignItems='center'>
        <img data-test='no-numbers-img' alt='no numbers yet' src={NoNumberImg} />
        <Grid paddingTop='l' gridTemplateRows='1fr' gap='m' justifyItems='center'>
          <Typography variant='headingBoldXS'>{t('numbers.create.first_number.title')}</Typography>
          <Typography variant='bodyMediumS'>{t('numbers.create.first_number.text')}</Typography>
          <CreateNumberDropdown buttonOnly />
        </Grid>
      </Flex>
    </Flex>
  );
}
