import { Avatar, AvatarImg, Flex, Spacer, Typography } from '@aircall/tractor-v2';
import { FormFlow } from '@components/FormFlow';

interface IntegrationFlowPageProps {
  avatarSrc?: string | null;
  title?: string | null;
  paperTitle?: string;
  paperSubtitle?: string | React.ReactNode;
  paperChildren?: React.ReactNode;
  children?: React.ReactNode;
  hasFooter?: boolean;
  submitButtonText?: string;
  onSubmit?: () => void;
  cancelButtonText?: string;
  onCancel?: () => void;
  secondaryActionText?: string;
  onSecondaryAction?: () => void;
  shouldDisableSubmit?: boolean;
  'data-test'?: string;
}

export function IntegrationFlowPage({
  avatarSrc,
  title,
  paperTitle,
  paperSubtitle,
  paperChildren,
  children,
  hasFooter = false,
  submitButtonText,
  onSubmit,
  cancelButtonText,
  onCancel,
  secondaryActionText,
  onSecondaryAction,
  shouldDisableSubmit,
  'data-test': dataTest = 'flow-page',
}: IntegrationFlowPageProps) {
  const _paperSubtile =
    typeof paperSubtitle === 'string' ? (
      <Typography variant='bodyMediumS' color='text-base' data-test={`${dataTest}-paper-subtitle`}>
        {paperSubtitle}
      </Typography>
    ) : (
      paperSubtitle
    );

  return (
    <FormFlow onSuccess={onSubmit} onCancel={onCancel} onClose={onCancel}>
      <FormFlow.Page
        showFooter={hasFooter}
        showFooterNext={!!submitButtonText}
        nextDisabled={shouldDisableSubmit}
        nextText={submitButtonText}
        secondaryText={secondaryActionText}
        onSecondary={onSecondaryAction}
        showFooterCancel={!!cancelButtonText}
        cancelText={cancelButtonText}
      >
        <Flex data-test={dataTest} justifyContent='center' w='100%'>
          <Spacer direction='vertical' w={752} paddingTop={48} space='l' fluid>
            {(avatarSrc || title) && (
              <Spacer alignItems='center' space='xxs'>
                {avatarSrc && (
                  <Avatar size='large' data-test={`${dataTest}-avatar`}>
                    <AvatarImg src={avatarSrc} />
                  </Avatar>
                )}

                {title && (
                  <Typography
                    variant='headingBoldM'
                    color='text-base'
                    data-test={`${dataTest}-title`}
                  >
                    {title}
                  </Typography>
                )}
              </Spacer>
            )}

            <Spacer direction='vertical' space='m' fluid mb={100}>
              {(paperTitle || paperSubtitle) && (
                <Spacer direction='vertical' space='xxs'>
                  {paperTitle && (
                    <Typography
                      variant='headingBoldS'
                      color='text-base'
                      data-test={`${dataTest}-paper-title`}
                    >
                      {paperTitle}
                    </Typography>
                  )}
                  {_paperSubtile}
                </Spacer>
              )}

              {paperChildren && (
                <Flex
                  borderRadius='sm'
                  border='1px solid'
                  borderColor='neutral-200'
                  backgroundColor='neutral-0'
                  padding='xl'
                  justifyContent='center'
                  data-test={`${dataTest}-paper-children`}
                >
                  {paperChildren}
                </Flex>
              )}
              {children}
            </Spacer>
          </Spacer>
        </Flex>
      </FormFlow.Page>
    </FormFlow>
  );
}
