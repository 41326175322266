import { gql } from '@apollo/client';

export const DELETE_NUMBER_MUTATION = gql`
  mutation DeleteNumberMutation($numberId: String!) {
    deleteNumber(numberId: $numberId)
      @rest(
        type: "Number"
        path: "numbers/{args.numberId}"
        method: "DELETE"
        endpoint: "v4DeleteNumber"
      ) {
      id
    }
  }
`;
