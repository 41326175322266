import React from 'react';

import HeaderUserProfileDropdown from './HeaderUserProfileDropdown/HeaderUserProfileDropdown';

import { Flex, Typography } from '@aircall/tractor-v2';
import { SupportLinksDropdown } from '@components/SupportLinksDropdown/SupportLinksDropdown';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useReseller } from '@hooks/useReseller/useReseller';
import { useTranslation } from 'react-i18next';

export function HeaderContentV2() {
  const { isSupportLinksAllowed, showReferAFriendButton } = useFeatures();

  const { isReseller } = useReseller();
  const {
    currentUser: { firstName },
  } = useGlobalData();
  const { t } = useTranslation();

  const displayReferAFriendButton = showReferAFriendButton && !isReseller;

  return (
    <Flex
      h='auto'
      pt='l'
      pb='xs'
      backgroundColor='surface-default'
      data-test='app-header'
      position='relative'
    >
      <Flex alignItems='end' gap='l' position='absolute' right='5' top='2'>
        {isSupportLinksAllowed && <SupportLinksDropdown />}
        <HeaderUserProfileDropdown showReferAFriendButton={displayReferAFriendButton} />
      </Flex>
      <Flex alignItems='start' pl='xl' pt='xs'>
        <Typography as='h3' variant='headingBoldM' m='0' data-test='welcome-message'>
          {t('onboarding.welcome_message', { name: firstName })}!
        </Typography>
      </Flex>
    </Flex>
  );
}
