import { useEffect, useMemo, useState } from 'react';

import {
  UseSearchableSelectUserParams,
  UseSearchableSelectUserReturnType,
} from './useSearchableSelectUser.decl';

import { SearchableSelectUserOption } from '@components/SearchableSelectUserItem';
import { DEFAULT_AGENTS_SORT } from '@constants/generic.constants';
import { useGraphQuery, getInitials } from '@dashboard/library';
import { SearchAgentsQuery, SearchAgentsQueryVariables } from '@generated/SearchAgentsQuery';
import { UserMinimalFragment } from '@generated/UserMinimalFragment';
import { SEARCH_AGENTS_QUERY } from '@graphql/queries/SearchAgentsQuery';
import { getSearchAgentsFilter } from '@helpers/search.helpers';

function mapAgent({
  ID,
  firstName,
  lastName,
  email,
  pictureURL,
}: UserMinimalFragment): SearchableSelectUserOption {
  return {
    label: `${firstName} ${lastName}`,
    value: ID,
    initials: getInitials(firstName, lastName),
    pictureURL,
    email,
  };
}

/**
 * Hook to provide agents related actions.
 * @param useSearchableSelectUserParams - destructured params
 * @returns an object holding agent related actions
 */
export function useSearchableSelectUser({
  filteredIds,
  isSelectMounted = true,
}: UseSearchableSelectUserParams): UseSearchableSelectUserReturnType {
  const [query, setQuery] = useState('');
  const { loading, data } = useGraphQuery<SearchAgentsQuery, SearchAgentsQueryVariables>(
    SEARCH_AGENTS_QUERY,
    {
      variables: {
        filter: getSearchAgentsFilter({ searchQuery: query }),
        sort: DEFAULT_AGENTS_SORT,
      },
      skip: !isSelectMounted,
    }
  );

  useEffect(() => {
    if (!isSelectMounted) {
      // reset the query when the select is unmounted
      setQuery('');
    }
  }, [isSelectMounted]);
  const agents = useMemo(() => data?.searchAgents?.items ?? [], [data?.searchAgents?.items]);

  const options = useMemo(
    () => agents.filter((i) => !filteredIds?.has(i.ID)).map(mapAgent),
    [agents, filteredIds]
  );

  const resultsCount = options.length;

  return {
    resultsCount,
    agents,
    options,
    setQuery,
    loading,
  };
}
