import { DownloadAppButtonV2 } from './DownloadAppButtonV2';

import { Box, Flex, Spacer, Typography } from '@aircall/tractor-v2';
import { getResellerConfig } from '@config/resellers.config';
import { getOperatingSystem, getBrowser } from '@dashboard/library';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useTranslation } from 'react-i18next';

export function OnboardingDownloadSectionV2() {
  const { t } = useTranslation();
  const { currentOS } = getOperatingSystem();
  const currentBrowser = getBrowser();
  const { dashboardOnboardingFirefoxExtensionDisplayed } = useFeatures();
  const {
    featureSet: { shouldShowEdgeExtension, shouldShowFirefoxExtension },
  } = getResellerConfig();
  const showFirefoxExtension =
    dashboardOnboardingFirefoxExtensionDisplayed && shouldShowFirefoxExtension;

  return (
    <Spacer direction='vertical' space='s' fluid>
      <Typography variant='headingBoldS'>{t('onboarding.application_v2.title')}</Typography>
      <Box
        w='100%'
        borderWidth='1px'
        borderStyle='solid'
        borderColor='neutral-200'
        bg='white'
        borderRadius='sm'
        p='s'
        h='auto'
      >
        <Flex flexDirection='column' gap='s'>
          <DownloadAppButtonV2 applicationName={currentOS} />
          <Typography variant='bodyMediumS' mt='s'>
            {t('onboarding.application.mobile_app_title')}
          </Typography>
          <Flex gap='s'>
            <DownloadAppButtonV2 applicationName='Android' />
            <DownloadAppButtonV2 applicationName='iOS' />
          </Flex>
          <Typography variant='bodyMediumS' mt='s'>
            {t('onboarding.application.browser_extension_title')}
          </Typography>
          {currentBrowser.includes('Chrome') && <DownloadAppButtonV2 applicationName='Chrome' />}
          {currentBrowser.includes('Edge') && shouldShowEdgeExtension && (
            <DownloadAppButtonV2 applicationName='Edge' />
          )}
          {currentBrowser.includes('Firefox') && showFirefoxExtension && (
            <DownloadAppButtonV2 applicationName='Firefox' />
          )}
        </Flex>
      </Box>
    </Spacer>
  );
}
