/* istanbul ignore file */
import React from 'react';

import { ReactSVG } from 'react-svg';

type DynamicAssetComponentProps = { name: string } & React.SVGProps<SVGSVGElement>;

export function DynamicAssetComponent({
  name,
  ...rest
}: DynamicAssetComponentProps): JSX.Element | null {
  return (
    <ReactSVG
      src={`/assets/${name}.svg`}
      beforeInjection={(svg: SVGSVGElement) => {
        Object.entries(rest).forEach(([key, value]) => {
          svg.setAttribute(key, value as string);
        });
      }}
    />
  );
}
