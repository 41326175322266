import React from 'react';

import { ClockOutlined, WarningOutlined } from '@aircall/icons';
import { Icon, Tag, Tooltip } from '@aircall/tractor-v2';
import { RouterLink } from '@components/Link';
import { NUMBER_VERIFYING_HELP_URL } from '@constants/urls.constants';
import { isNumberActive } from '@helpers/numbers.helpers';
import { getProofOfIDLink } from '@pages/number-detail/ProofOfIdBanner/ProofOfId.helpers';
import { useTranslation } from 'react-i18next';

export enum VERIFICATION_STATES {
  MISSING_INFO = 'missing_information',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export interface NumberVerificationStateTagProps {
  activationState: string | null;
  verificationState: string | null;
  withText?: boolean;
  /** Number to generate the proof-of-id link */
  number?: string;
  /** Number id to generate the proof-of-id link */
  id?: string | null;
}

const onLinkClicked = (e: React.SyntheticEvent) => {
  // prevent it from jumping to the number details instead of the proof-of-id
  e.stopPropagation();
};

export const NumberVerificationStateTag = React.memo(
  ({
    activationState,
    verificationState,
    withText = true,
    number,
    id,
  }: NumberVerificationStateTagProps) => {
    const { t } = useTranslation();
    const active = isNumberActive(activationState);
    const lowerCaseVerifState = verificationState ? verificationState.toLocaleLowerCase() : null;
    const isPending = !active && lowerCaseVerifState === VERIFICATION_STATES.PENDING;
    const unverified =
      !active &&
      Object.values(VERIFICATION_STATES).includes(lowerCaseVerifState as VERIFICATION_STATES);

    if (!unverified) {
      return null;
    }

    const tooltip = (
      <Tooltip
        title={t(`numbers.card.verification_state.${lowerCaseVerifState}.tooltip`)}
        side='bottom'
        maxWidth={180}
      >
        <Tag data-test='verification-state-tag' variant={isPending ? 'yellow' : 'red'}>
          {isPending ? (
            <Icon data-test='clock-icon' component={ClockOutlined} size={16} />
          ) : (
            <Icon data-test='warning-icon' component={WarningOutlined} size={16} />
          )}
          {withText && t(`numbers.card.verification_state.${lowerCaseVerifState}.tag`)}
        </Tag>
      </Tooltip>
    );
    const link = number && id && getProofOfIDLink(id);

    if (!isPending && link) {
      return (
        <RouterLink
          data-test='verification-link'
          to={link}
          onClick={onLinkClicked}
          color='critical-700'
        >
          {tooltip}
        </RouterLink>
      );
    }

    if (isPending) {
      return (
        <a
          onClick={onLinkClicked}
          href={NUMBER_VERIFYING_HELP_URL}
          target='_blank'
          rel='noreferrer'
        >
          {tooltip}
        </a>
      );
    }

    return tooltip;
  }
);
