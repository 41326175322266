import { gql } from '@apollo/client';

export const ADD_IVR_SETTING_MUTATION = gql`
  mutation AddIvrSettingRest($numberId: String!, $input: IvrSettingInput!) {
    addIvrSettingRest(numberId: $numberId, input: $input)
      @rest(
        type: "IvrSettingResponse"
        path: "numbers/{args.numberId}/ivr_settings"
        endpoint: "v3"
        method: "POST"
      ) {
      id
      key
      numberId
      redirectingExternalNumber
      redirectingNumberId
    }
  }
`;
