import { useRef } from 'react';

import { FilterWithTooltipProps } from './FilterWithTooltip.decl';

import { InformationOutlined } from '@aircall/icons';
import { Box, Flex, Icon, Tooltip, Typography } from '@aircall/tractor-v2';
import { useHover } from 'usehooks-ts';

export function FilterWithTooltip({ children, label, tooltip }: FilterWithTooltipProps) {
  const ref = useRef<HTMLDivElement>(null);
  const hovering = useHover(ref);

  return (
    <Box data-test='filter-with-tooltip' ref={ref}>
      <Flex alignItems='center' mb='xxxs'>
        <Typography variant='supportingRegularS'>{label}</Typography>
        {hovering && (
          <Tooltip maxW={280} side='bottom' title={tooltip}>
            <Icon
              color='neutral-400'
              component={InformationOutlined}
              data-test='tooltip-trigger'
              ml='xxxs'
              size={16}
            />
          </Tooltip>
        )}
      </Flex>
      {children}
    </Box>
  );
}
