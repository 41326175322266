import { AudioSettingFile } from './AudioSettingFile';

import { useField } from 'react-final-form';

interface AudioSettingFileFieldProps {
  name: string;
}

export function AudioSettingFileField({ name }: AudioSettingFileFieldProps) {
  const {
    input: { onChange, value },
  } = useField(name);

  return <AudioSettingFile onChange={onChange} file={value} />;
}
