import { Radio, RadioGroup } from '@aircall/tractor-v2';
import { AudioPlayer } from '@components/AudioPlayer';
import { Loading, GridItem, GridLayout, List, ListItem } from '@dashboard/library';
import { useAudioLibrary } from '@hooks/useAudioLibrary/useAudioLibrary';
import { useField } from 'react-final-form';

export function AudioSettingLibraryField({ name }: { name: string }) {
  const {
    input: { onChange, value },
  } = useField(name);
  const { tracks, loading, selectedTrackURL } = useAudioLibrary(value);

  if (loading) {
    return <Loading data-test='audio-setting-library-field-loading' />;
  }

  return (
    <RadioGroup
      value={selectedTrackURL}
      onChange={onChange}
      direction='vertical'
      data-test='audio-setting-library-field'
    >
      <List>
        {tracks?.map((track) => (
          <ListItem key={track.name} h='auto'>
            <GridLayout px='s' py='xxxs' alignItems='center' w='100%'>
              <GridItem xs={6} display='flex'>
                <Radio
                  size='small'
                  value={track.url}
                  data-test={`audio-setting-library-radio-button-${track.name}`}
                >
                  {track.label}
                </Radio>
              </GridItem>
              <GridItem xs={6}>
                <AudioPlayer url={track.url} displayTimer displayTimeline />
              </GridItem>
            </GridLayout>
          </ListItem>
        ))}
      </List>
    </RadioGroup>
  );
}
