import { LinesFilters } from './LinesFilters.container';
import { NumbersListHeaderProps } from './NumbersHeader.decl';

import { Flex, Typography } from '@aircall/tractor-v2';
import { Gap } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function NumbersHeaderSection({ totalNumbers }: NumbersListHeaderProps) {
  const { t } = useTranslation();
  return (
    <Gap gap='s' alignItems='center' data-test='numbers-header-section'>
      <LinesFilters />

      {totalNumbers ? (
        <Flex marginLeft='auto'>
          <Typography
            variant='bodyMediumS'
            textAlign='right'
            color='neutral-400'
            data-test='numbers-list-total-count'
          >
            {t('numbers.header.total_numbers', { count: totalNumbers })}
          </Typography>
        </Flex>
      ) : undefined}
    </Gap>
  );
}
