import { useCallback, useState, useLayoutEffect } from 'react';

import { useScrollYContainer } from './useScrollYContainer';

import { MotionValue, useMotionValue } from 'framer-motion';

enum SCROLL_DIRECTION {
  IDDLE = 'iddle',
  TO_TOP = 'to-top',
  TO_BOTTOM = 'to-bottom',
}

// Expose isScrolling for testing purpose
export interface UseScrollableItem {
  isDragging: boolean;
  y: MotionValue<number>;
  startDragging: () => void;
  stopDragging: () => void;
  resetTranslateY: () => void;
}

export const START_SCROLLING_VALUE = 80;

/**
 * Custom hook to control the scroll behavior of both the container and the item.
 * @returns an object holding scroll utilities
 */
export function useScrollYItem(): UseScrollableItem {
  const { containerRef, containerScrollTop } = useScrollYContainer();
  const [scrollingDirection, setScrollingDirection] = useState(SCROLL_DIRECTION.IDDLE);
  const [isDragging, setDragging] = useState(false);
  const [scrollStartingPoint, setScrollStartingPoint] = useState(containerScrollTop.get());
  // Used instead of a ref to prevent endless scroll down
  const [containerHeight, setContainerHeight] = useState(0);
  const y = useMotionValue(0);

  const resetTranslateY = useCallback(() => {
    setScrollStartingPoint(containerScrollTop.get());
    y.set(0);
  }, [containerScrollTop, y]);

  const startDragging = useCallback(() => {
    setContainerHeight(containerRef.current!.scrollHeight);
    resetTranslateY();
    setDragging(true);
  }, [resetTranslateY, containerRef]);

  const stopDragging = useCallback(() => {
    y.set(0);
    setDragging(false);
  }, [y]);

  // Mouse Y position handler useEffect :
  // Listen to mouse position to set the isScrolling state machine.
  useLayoutEffect(() => {
    function handleMove(e: MouseEvent) {
      e.preventDefault();

      const mouseYposition = e.clientY - (containerRef.current?.getBoundingClientRect().y || 0);
      const hasEnteredTopZone = mouseYposition < START_SCROLLING_VALUE;
      const hasEnteredBottomZone =
        containerRef.current!.clientHeight - mouseYposition < START_SCROLLING_VALUE;

      if (hasEnteredBottomZone) {
        setScrollingDirection(SCROLL_DIRECTION.TO_BOTTOM);
      } else if (hasEnteredTopZone) {
        setScrollingDirection(SCROLL_DIRECTION.TO_TOP);
      } else {
        resetTranslateY();
        setScrollingDirection(SCROLL_DIRECTION.IDDLE);
      }
    }

    if (isDragging) {
      document.addEventListener('mousemove', handleMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMove);
    };
  }, [containerRef, isDragging, resetTranslateY]);

  // Scroll handler useEffect:
  // Use the scrollingDirection state machine to scroll in one of 2 directions.
  useLayoutEffect(() => {
    if (!isDragging) {
      // eslint-disable-next-line react/function-component-definition
      return () => null;
    }

    let testId = 0;
    let last: undefined | number;

    function handleScroll(now: number) {
      // Calculate the number of pixel to add / remove based on the screen frame rate
      if (last === undefined) {
        last = now;
      }
      const delta = now - last;
      const pixelPerFrame = delta * 0.4;
      last = now;

      // Based on the scrollingDirection change the scrollTop value of the items list's container
      const hasNotReachTop = !!containerScrollTop.get();
      const hasNotReachBottom =
        containerHeight > containerScrollTop.get() + containerRef.current!.offsetHeight;
      const shouldScrollUp = scrollingDirection === SCROLL_DIRECTION.TO_TOP && hasNotReachTop;
      const shouldScrollDown =
        scrollingDirection === SCROLL_DIRECTION.TO_BOTTOM && hasNotReachBottom;

      if (shouldScrollUp) {
        containerRef.current!.scrollTop -= pixelPerFrame;
        testId = requestAnimationFrame(handleScroll);
      }
      if (shouldScrollDown) {
        containerRef.current!.scrollTop += pixelPerFrame;
        testId = requestAnimationFrame(handleScroll);
      }
    }

    testId = window.requestAnimationFrame(handleScroll);
    return () => window.cancelAnimationFrame(testId);
  }, [containerHeight, containerRef, containerScrollTop, isDragging, scrollingDirection]);

  // Item translateY handler useEffect :
  // Use container's scrollY MotionValue to mutate translateY MotionValue
  useLayoutEffect(() => {
    const unsubsribe = containerScrollTop.onChange((scrollTop) => {
      if (isDragging) {
        y.set(scrollTop - scrollStartingPoint);
      }
    });
    return () => unsubsribe();
  }, [containerScrollTop, isDragging, scrollStartingPoint, y]);

  return { isDragging, y, startDragging, stopDragging, resetTranslateY };
}
