import { useCallback, useMemo, useState } from 'react';

import { RECORDER_ERROR, UseRecorderProps, UseRecorderReturn } from './useRecorder.decl';

import { useTracker } from '@hooks/useTracker/useTracker';
import { Recorder } from 'vmsg';

/**
 * Custom hook that record an audio file with the user's navigator.
 * @param onChange - callback used while the media recorder is getting update.
 * @returns the methods to start and stop a recording, a boolean to track the recording status, the new file url and an error status.
 */
export const useRecorder = (onChangeCallback: UseRecorderProps): UseRecorderReturn => {
  const [audioURL, setAudioURL] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState<RECORDER_ERROR | undefined>(undefined);
  const { track } = useTracker();

  /**
   * Each navigator's mediarecorder will encode an audio buffer in their own format
   * but none can encode natively to mp3. To solve this problem we use a third party
   * library, VMSG, which record & encode the audio stream from chrome, safari, edge and
   * firefox to mp3 for us.
   */
  const recorder = useMemo(
    () =>
      new Recorder({
        wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
      }),
    []
  );

  const startRecording = useCallback(async () => {
    try {
      await recorder.initAudio();
      await recorder.initWorker();
      track({ event: 'recording_started' });
      recorder.startRecording();
      setIsRecording(true);
    } catch (recorderError: unknown) {
      track({ event: 'recording_error', payload: { origin: 'init recording' } });
      setError(RECORDER_ERROR.INIT_RECORDER);
    }
  }, [recorder, track]);

  const stopRecording = useCallback(async () => {
    try {
      const blob = await recorder.stopRecording();
      onChangeCallback(blob);
      setAudioURL(URL.createObjectURL(blob));
      setIsRecording(false);
    } catch (recorderError: unknown) {
      track({ event: 'recording_error', payload: { origin: 'stop record' } });
      setError(RECORDER_ERROR.STOP_RECORDER);
    }
  }, [onChangeCallback, recorder, track]);

  const closeRecording = useCallback(async () => {
    if (isRecording) {
      recorder.stopRecording();
    }
  }, [isRecording, recorder]);

  return { audioURL, error, isRecording, startRecording, stopRecording, closeRecording };
};
