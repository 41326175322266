import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

// By default useParams returns an object which is not memoized
export function useParamsMemoized(): Record<string, string> {
  const params = useParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => params, [JSON.stringify(params)]);
}
