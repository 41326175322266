import { NumberDetailQuery_numberDetail } from '../../generated/NumberDetailQuery';

import { InboundCallReceiversSection } from './InboundCallReceiversSection/InboundCallReceivers.section';
import { OutboundCallUsersSection } from './OutboundCallUsersSection/OutboundCallUsers.Section';

import { Spacer } from '@aircall/tractor-v2';

interface NumberDetailTeamsUsersProps {
  numberDetail: NumberDetailQuery_numberDetail;
}

export function NumberDetailTeamsUsers({ numberDetail }: NumberDetailTeamsUsersProps) {
  return (
    <Spacer fluid direction='vertical' space='m' data-test='number-detail-settings-page'>
      {!numberDetail.ivr && (
        <InboundCallReceiversSection dispatchGroups={numberDetail.dispatchGroups} />
      )}
      <OutboundCallUsersSection />
    </Spacer>
  );
}
