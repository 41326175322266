import { useCallback } from 'react';

import { EditIvrSettingModal } from '../EditIvrSettingModal/EditIvrSettingModal';
import { DiagramCard } from '../FlowDiagram';

import { DeletedNumberBanner } from './DeletedNumberBanner';
import { IvrNumberContainer } from './IvrNumberContainer';

import { MenuVerticalFilled } from '@aircall/icons';
import {
  Box,
  Typography,
  Grid,
  Dropdown,
  IconButton,
  Menu,
  MenuItem,
  Flex,
  getColor,
} from '@aircall/tractor-v2';
import { NUMBER_DETAIL_ROUTE } from '@constants/routes.constants';
import {
  DeleteIVRSettingMutationRest,
  DeleteIVRSettingMutationRestVariables,
} from '@generated/DeleteIVRSettingMutationRest';
import { NumberDetailQuery_numberDetail_ivrSettings } from '@generated/NumberDetailQuery';
import { DELETE_IVR_SETTING_MUTATION } from '@graphql/mutations/DeleteIVRSettingMutation';
import { useDeleteEntity } from '@hooks/useDeleteEntity/useDeleteEntity';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useToggle } from '@hooks/useToggle/useToggle';
import styled from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const ClassicSeparator = styled(Box)`
  &::before {
    position: absolute;
    left: 12px;
    width: 16px;
    height: 1px;
    border-top: 1px solid ${getColor('neutral-400')};
    content: '';
  }
`;

const TypographyWithSeparator = styled(Typography)`
  &::after {
    position: absolute;
    top: 12px;
    left: 24px;
    width: 12px;
    height: 1px;
    border-top: 1px solid ${getColor('neutral-400')};
    content: '';
  }
`;

function NumberKeySeparator({ numberKey, isDeleted }: { numberKey: string; isDeleted: boolean }) {
  return (
    <TypographyWithSeparator
      data-test='ivr-setting-key-separator'
      display='inline-block'
      textAlign='center'
      lineHeight='24px'
      variant='bodyMediumS'
      color='text-inverted'
      backgroundColor={isDeleted ? 'critical-800' : 'neutral-400'}
      borderRadius={8}
      h='24px'
      w='24px'
      zIndex={1}
      marginRight='12px'
      position='relative'
    >
      {numberKey}
    </TypographyWithSeparator>
  );
}

export interface IvrSettingCardProps {
  item: NumberDetailQuery_numberDetail_ivrSettings;
  availableKeyOptions: { value: string; label: string }[];
  countryCode: string;
}

export function IvrSettingCard({ item, availableKeyOptions, countryCode }: IvrSettingCardProps) {
  const [show, _, open, close] = useToggle();
  const { t } = useTranslation();
  const { numberId } = useParams();
  const navigate = useNavigateWithParamsReplace();
  const [deleteIVRSetting] = useDeleteEntity<
    DeleteIVRSettingMutationRest,
    DeleteIVRSettingMutationRestVariables
  >({
    mutation: DELETE_IVR_SETTING_MUTATION,
    mutationName: 'deleteIVRSetting',
    __typename: 'IvrSettingRest',
  });

  const handleDeleteIVRSetting = useCallback(
    (ivrSettingId: string, deletedNumberName: string) => () => {
      deleteIVRSetting(ivrSettingId, { numberId, ivrSettingId }, deletedNumberName);
    },
    [deleteIVRSetting, numberId]
  );

  const { id, key, redirectingExternalNumber, redirectingNumber } = item;

  const isNumberDeleted = redirectingNumber === null && redirectingExternalNumber === null;

  const displayName: string =
    redirectingNumber?.name ||
    t(
      'number_details.settings.call_distribution_section.diagram.ivr_settings.delete_name_external_number'
    );

  const isFallback = key === 'fallback';

  return (
    <Grid key={id} alignItems='center' gridTemplateColumns='auto 1fr' mb='24px'>
      {isFallback ? (
        <ClassicSeparator data-test='classic-separator' w='36px' />
      ) : (
        <NumberKeySeparator numberKey={key} isDeleted={isNumberDeleted} />
      )}
      <EditIvrSettingModal
        ivrSetting={item}
        show={show}
        onHide={close}
        availableKeyOptions={availableKeyOptions}
        countryCode={countryCode}
      />
      {isNumberDeleted ? (
        <DeletedNumberBanner
          id={id}
          onEdit={open}
          onDelete={handleDeleteIVRSetting(id, displayName)}
        />
      ) : (
        <DiagramCard>
          <Flex alignItems='center' justifyContent='space-between' py='12px' pr='8px'>
            <IvrNumberContainer
              redirectingExternalNumber={redirectingExternalNumber}
              redirectingNumber={redirectingNumber}
            />
            <Dropdown
              trigger={
                <IconButton
                  ml='xxxs'
                  size={24}
                  component={MenuVerticalFilled}
                  color='neutral-400'
                  data-test={`ivr-option-dropdown-trigger-${id}`}
                />
              }
              side='bottom'
              align='start'
            >
              <Menu>
                <MenuItem onClick={open} data-test={`ivr-option-menu-item-edit-${id}`}>
                  {t(
                    'number_details.settings.call_distribution_section.diagram.ivr_settings.actions.edit'
                  )}
                </MenuItem>
                {redirectingNumber && (
                  <MenuItem
                    onClick={() =>
                      navigate(NUMBER_DETAIL_ROUTE, { numberId: redirectingNumber.id })
                    }
                    data-test={`ivr-option-menu-item-redirect-${id}`}
                  >
                    {t(
                      'number_details.settings.call_distribution_section.diagram.ivr_settings.actions.redirect'
                    )}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={handleDeleteIVRSetting(id, displayName)}
                  data-test={`ivr-option-menu-item-delete-${id}`}
                >
                  {t(
                    'number_details.settings.call_distribution_section.diagram.ivr_settings.actions.delete'
                  )}
                </MenuItem>
              </Menu>
            </Dropdown>
          </Flex>
        </DiagramCard>
      )}
    </Grid>
  );
}
