import { AudioSetting, AUDIO_NAME, AUDIO_SETTING_TYPE } from './AudioSetting.decl';

import {
  NumberDetailQuery_numberDetail,
  NumberDetailQuery_numberDetail_ttsMessages,
} from '@generated/NumberDetailQuery';
import { Track } from '@hooks/useAudioLibrary/useAudioLibrary';
import camelCase from 'lodash-es/camelCase';

function getFileNameFromUrl(url: string): string {
  return decodeURI(url.slice(url.lastIndexOf('/') + 1));
}

/**
 * Find tts message by name and return it
 * @param ttsList - A list of number's ttsMessages
 * @param name - The name of audio, like "welcomeMessage", "afterHoursMessage"
 * @returns The found tts message object or a fallback value if not found
 */
export const getTtsByName = (
  ttsList: NumberDetailQuery_numberDetail_ttsMessages[],
  name: string
): NumberDetailQuery_numberDetail_ttsMessages =>
  ttsList.find((ttsMessage) =>
    ttsMessage.track
      ? camelCase(ttsMessage.track) === name
      : camelCase(ttsMessage.category) === name
  ) || {
    __typename: 'TtsMessage',
    id: '-1',
    category: '',
    url: '',
    defaultUrl: '',
    track: null,
    language: '',
    message: '',
    voice: '',
  };

/**
 * check if url is found in the tts items list
 * @param url - url string
 * @param ttsList - A list of number's ttsMessages
 * @returns a boolean
 */
export const isTts = (
  url: string,
  ttsList: NumberDetailQuery_numberDetail_ttsMessages[]
): boolean => ttsList.some((tts) => tts.defaultUrl === url || tts.url === url);

/**
 * check if url is found in the library items list
 * @param url - url string
 * @param ttsList - A list of company's library of ringtone
 * @returns a boolean
 */
export const isFromLibrary = (url: string, tracks?: Track[]): boolean => {
  if (!tracks) {
    return false;
  }

  return tracks.some((track) => track.url === url);
};

/**
 * get the formatted AudioSetting from numberDetail, according to audio name and audio type
 * so we can display them in the AudioSettingModal
 */
export const getNumberAudioSetting = async (
  number: NumberDetailQuery_numberDetail,
  name: AUDIO_NAME,
  tracks?: Track[]
): Promise<AudioSetting> => {
  // text_to_speech
  if (isTts(number[name], number.ttsMessages)) {
    // example : "https://media-web.aircall-staging.com/tracks/tts/languages/fr/voices/Celine/a0258c535f2d7e49889b51eecf444478066b0d24.mp3"
    // example 2 : "https://media-web.aircall-staging.com/companies/43605/numbers/29672/tracks/tts/145859/languages/fr/voices/Celine/6b3f3f944c14775219565b1d131ffa8baf4dd242.mp3"
    const foundTtsMessage = getTtsByName(number.ttsMessages, name);
    const { __typename, ...tssMessage } = foundTtsMessage;
    return {
      type: AUDIO_SETTING_TYPE.TEXT_TO_SPEECH,
      ...tssMessage,
    };
  }
  // library music
  if (isFromLibrary(number[name], tracks)) {
    // example : https://media-web.aircall-staging.com/tracks/ringing_tones/EU.mp3
    return {
      type: AUDIO_SETTING_TYPE.LIBRARY,
      url: number[name],
    };
  }
  // custom file, could be message or music

  // example: "https://staging-pdx-886862221201-web.s3.us-west-2.amazonaws.com/companies/43605/numbers/29729/tracks/library/1623749389/audio.mp3"
  // https://media-web.aircall-staging.com/companies/509/numbers/15440/tracks/library/1619709853/file_example_MP3_700KB.mp3
  const fileUrl = number[name];
  const data = await fetch(fileUrl).then((res) => res.blob());
  return {
    type: AUDIO_SETTING_TYPE.FILE,
    file: new File([data], getFileNameFromUrl(fileUrl)),
  };
};
