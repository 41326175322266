/* eslint-disable @typescript-eslint/naming-convention */

import {
  GenericReduxAction,
  ReduxAction,
  ReduxActionJourneyPayload,
} from '../LegacyDashboard.decl';

import { ApolloClient } from '@apollo/client';
import {
  NUMBERS_ROUTE,
  NUMBER_DETAIL_ROUTE,
  NUMBER_PROOF_OF_ID_ROUTE,
  NUMBER_PURCHASE_ROUTE,
  NUMBER_PURCHASE_ROUTE_AT_IVR_CREATION,
  NUMBER_PURCHASE_ROUTE_AT_IVR_EDITION,
} from '@constants/routes.constants';
import { SEARCH_TOTAL_QUERY } from '@graphql/queries/SearchTotalQuery';
import { NavFuncResult } from '@hooks/useNavigateWithParamsReplace';
import { matchPath } from 'react-router';

export interface ReduxHandlerContext {
  navigate: NavFuncResult;
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>;
  location: typeof window.location;
}

type ReduxHandler = (action: ReduxAction, context: ReduxHandlerContext) => void;

export const REDUX_ACTION_HANDLERS: Record<string, ReduxHandler> = {
  // If the number creation or proof of id modal is closed
  'reset journey': (action, { navigate, location, client }) => {
    const { payload } = action as { payload: { journeyName: string } };

    // refetch total query after *possible* creation
    client.refetchQueries({ include: [SEARCH_TOTAL_QUERY] });

    /* If we receive a 'reset journey' at NUMBER_PROOF_OF_ID_ROUTE or NUMBER_PURCHASE_ROUTE
    we redirect on the numbers list */
    if (
      (matchPath(NUMBER_PROOF_OF_ID_ROUTE, location.pathname) &&
        payload.journeyName === 'numberProofOfId') ||
      (matchPath(NUMBER_PURCHASE_ROUTE, location.pathname) &&
        payload.journeyName === 'numberCreation')
    ) {
      navigate(NUMBERS_ROUTE);
      return;
    }

    /* If we receive a 'reset journey' at NUMBER_PURCHASE_ROUTE_AT_IVR_CREATION or NUMBER_PURCHASE_ROUTE_AT_IVR_EDITION
    we redirect on the numbers detail page */
    const pathMatch =
      matchPath(NUMBER_PURCHASE_ROUTE_AT_IVR_CREATION, location.pathname) ||
      matchPath(NUMBER_PURCHASE_ROUTE_AT_IVR_EDITION, location.pathname);

    if (pathMatch && payload.journeyName === 'numberCreation') {
      navigate(NUMBER_DETAIL_ROUTE, { numberId: pathMatch.params.numberId as string });
    }
  },
  // Detects when the legacy dashboard opens the proof of id modal
  // We do not use 'set journey open state' because it does not have the number as params
  'update journey data': (action, { navigate }) => {
    const { payload } = action as GenericReduxAction<ReduxActionJourneyPayload>;
    if (payload.journeyName === 'numberProofOfId') {
      const { e164 } = payload.data as { e164?: string };
      if (e164) {
        navigate(NUMBER_PROOF_OF_ID_ROUTE, { number: e164 });
      }
    }
  },
};

/**
 * Handles a redux action coming from the legacy dashboard
 * Can either navigate somewhere or update the apollo cache
 * @param type - Action type
 * @param action - Action object
 * @param context - Context actions
 * @returns
 */
export function handleReduxAction(
  type: string,
  action: ReduxAction,
  context: ReduxHandlerContext
): void {
  REDUX_ACTION_HANDLERS[type]?.(action, context);
}
