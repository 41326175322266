import { EventTogglesBlock } from './EventTogglesBlock';
import { WebhookBoundEventsContentProps } from './Webhook.decl';

import { GridLayout } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function WebhookBoundEventsContent({
  events,
  webhook,
  toggleWebhookEvents,
}: WebhookBoundEventsContentProps) {
  const { t } = useTranslation();

  return (
    <>
      <GridLayout gap={{ _: 'm', md: 'l' }}>
        {/* Number events */}
        <EventTogglesBlock
          sectionEvents={events?.number}
          selectedEvents={webhook.events}
          title={t('integrations.settings.bound_events.sections.number.title')}
          onToggle={toggleWebhookEvents}
        />
        {/* Contact events */}
        <EventTogglesBlock
          sectionEvents={events?.contact}
          selectedEvents={webhook.events}
          title={t('integrations.settings.bound_events.sections.contact.title')}
          onToggle={toggleWebhookEvents}
        />
      </GridLayout>
      <GridLayout gap={{ _: 'm', md: 'l' }} mt='m'>
        {/* Message events */}
        <EventTogglesBlock
          sectionEvents={events?.message}
          selectedEvents={webhook.events}
          title={t('integrations.settings.bound_events.sections.message.title')}
          onToggle={toggleWebhookEvents}
        />
        <EventTogglesBlock
          sectionEvents={events?.conversationIntelligence}
          selectedEvents={webhook.events}
          title={t('integrations.settings.bound_events.sections.con_intelligence.title')}
          onToggle={toggleWebhookEvents}
        />
      </GridLayout>
      <GridLayout gap={{ _: 'm', md: 'l' }} mt='m'>
        {/* Call events */}
        <EventTogglesBlock
          sectionEvents={events?.call}
          selectedEvents={webhook.events}
          title={t('integrations.settings.bound_events.sections.call.title')}
          onToggle={toggleWebhookEvents}
        />
        {/* User events */}
        <EventTogglesBlock
          sectionEvents={events?.user}
          selectedEvents={webhook.events}
          title={t('integrations.settings.bound_events.sections.user.title')}
          onToggle={toggleWebhookEvents}
        />
      </GridLayout>
    </>
  );
}
