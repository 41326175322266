import { useEffect } from 'react';

import * as ROUTES from '@constants/routes.constants';
import { useGraphQuery } from '@dashboard/library';
import { GetSignupInformation_getSignupInformation } from '@generated/GetSignupInformation';
import { SearchTotalQuery } from '@generated/SearchTotalQuery';
import { SEARCH_TOTAL_QUERY } from '@graphql/queries/SearchTotalQuery';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useLocation, useNavigate } from 'react-router';

export const useOnboardingRedirect = (
  signupInformation?: GetSignupInformation_getSignupInformation
): void => {
  const { pathname } = useLocation();
  const { showOnboardingTrial } = useFeatures();
  const navigate = useNavigate();
  const { data: totals } = useGraphQuery<SearchTotalQuery>(SEARCH_TOTAL_QUERY, {
    skip: !showOnboardingTrial,
  });

  // Redirect trial users to onboarding landing page if they have no numbers
  useEffect(() => {
    if (!showOnboardingTrial || !signupInformation?.phoneNumber) {
      return;
    }
    if (totals?.searchLines?.total === 0 && pathname !== ROUTES.LANDING_PAGE_ROUTE) {
      navigate(ROUTES.LANDING_PAGE_ROUTE);
    }
  }, [totals, pathname, showOnboardingTrial, navigate, signupInformation]);
};
