type ComputeCircleProgressType = {
  completedTask: number;
  totalTask: number;
  circleRadius: number;
};

export function computeCircleProgress({
  completedTask,
  totalTask,
  circleRadius,
}: ComputeCircleProgressType): {
  circumference: number;
  offset: number;
} {
  const percent = (completedTask / totalTask) * 100;
  const circumference = 2 * Math.PI * circleRadius;
  const offset = circumference * (1 - percent / 100);

  return { circumference, offset };
}
