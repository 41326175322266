import React, { useCallback, useMemo, useReducer, useRef } from 'react';

import {
  AudioPlayerAction,
  AudioPlayerEventHandlers,
  AudioPlayerState,
  AUDIO_PLAYER_ACTION_TYPE,
} from './AudioPlayer.decl';
import { AudioPlayerContext } from './AudioPlayerContext';

import noop from 'lodash-es/noop';

// eslint-disable-next-line consistent-return
function audioPlayerReducer(state: AudioPlayerState, action: AudioPlayerAction) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case AUDIO_PLAYER_ACTION_TYPE.ADD: {
      return [...state, { id: action.id, url: action.url }];
    }
    case AUDIO_PLAYER_ACTION_TYPE.REMOVE: {
      return state.filter(({ id }) => id !== action.id);
    }
  }
}

export function AudioPlayerProvider({ children }: { children: React.ReactNode }) {
  const count = useRef(0);
  const { current: eventHandlers } = useRef(new Map<number, AudioPlayerEventHandlers>());
  const { current: playerRefs } = useRef(new Map<number, React.RefObject<HTMLAudioElement>>());
  const [players, dispatch] = useReducer(audioPlayerReducer, []);

  /**
   * Register a new player in the local state
   * This will result in displaying another 'audio' in the DOM
   */
  const register = useCallback(
    (
      url: string,
      {
        canPlay: load = noop,
        timeUpdate = noop,
        pause = noop,
      }: Partial<AudioPlayerEventHandlers> = {}
    ) => {
      const id = count.current + 1;
      dispatch({ type: AUDIO_PLAYER_ACTION_TYPE.ADD, id, url });
      eventHandlers.set(id, { canPlay: load, timeUpdate, pause });
      playerRefs.set(id, React.createRef());
      count.current += 1;
      return id;
    },
    [dispatch, count, eventHandlers, playerRefs]
  );

  /**
   * Remove an existing player from the local state
   * The corresponding 'audio' element will be removed from the DOM
   */
  const unregister = useCallback(
    (id: number) => {
      dispatch({ type: AUDIO_PLAYER_ACTION_TYPE.REMOVE, id });
      eventHandlers.delete(id);
      playerRefs.delete(id);
    },
    [dispatch, eventHandlers, playerRefs]
  );

  /**
   * Pause all other players and start playing sound
   * on the one corresponding with the provided id
   */
  const play = useCallback(
    (id: number) => {
      playerRefs.forEach((ref) => ref.current?.pause());
      playerRefs.get(id)?.current?.play();
    },
    [playerRefs]
  );

  /**
   * Pause the player corresponding with the
   * provided id
   */
  const pause = useCallback(
    (id: number) => {
      playerRefs.get(id)?.current?.pause();
    },
    [playerRefs]
  );

  /**
   * Pause the player corresponding with the provided id
   * and rewind it
   */
  const stop = useCallback(
    (id: number) => {
      const ref = playerRefs.get(id)?.current;
      if (ref) {
        ref.pause();
        ref.currentTime = 0;
      }
    },
    [playerRefs]
  );

  /**
   * Update the current time of the player
   * corresponding with the provided id
   */
  const updateTime = useCallback(
    (id: number, time: number) => {
      const ref = playerRefs.get(id)?.current;
      if (ref) {
        ref.currentTime = time;
        eventHandlers.get(id)?.timeUpdate(time);
      }
    },
    [playerRefs, eventHandlers]
  );

  const context = useMemo(
    () => ({
      register,
      unregister,
      play,
      pause,
      stop,
      updateTime,
    }),
    [register, unregister, play, pause, stop, updateTime]
  );

  return (
    <>
      {players.map(({ id, url }) => {
        const handlers = eventHandlers.get(id);
        const ref = playerRefs.get(id);
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio
            data-test={`audio-player-provider-audio-${id}`}
            ref={ref}
            key={id}
            src={url}
            onCanPlay={(event) => {
              handlers?.canPlay(event.currentTarget.duration);
            }}
            onTimeUpdate={(event) => {
              handlers?.timeUpdate(event.currentTarget.currentTime);
            }}
            onPause={handlers?.pause}
            onEnded={() => stop(id)}
          />
        );
      })}
      <AudioPlayerContext.Provider value={context}>{children}</AudioPlayerContext.Provider>
    </>
  );
}
