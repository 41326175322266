/* eslint-disable @typescript-eslint/naming-convention */

import { UpdateDispatchGroupMutation_updateDispatchGroup } from '@generated/UpdateDispatchGroupMutation';

/*
BEFORE
{
  data: {
    id: '21001',
    type: 'dispatch_group',
    attributes: {
      priority: 2,
      redirection_strategy: 'concurrent',
      queuing_timeout: 180
    },
    relationships: {
      dispatchable: {
        data: {
          id: '903',
          type: 'team'
        }
      }
    },
  },
}

AFTER
{
  __typename: 'DispatchGroup',
  id: '21001',
  priority: 2,
  queuingTimeout: 180,
  redirectionStrategy: 'concurrent',
}
*/

export default async function updateDispatchGroupTransformer(
  res: Response
): Promise<UpdateDispatchGroupMutation_updateDispatchGroup> {
  const resData = await res.json();
  const {
    data: {
      id,
      attributes: {
        priority,
        redirection_strategy: redirectionStrategy,
        queuing_timeout: queuingTimeout,
      },
    },
  } = resData;

  return {
    __typename: 'DispatchGroup',
    id,
    priority,
    queuingTimeout,
    redirectionStrategy,
  };
}
