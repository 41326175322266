import { TagsSection } from './TagsSection/Tags.section';

import { Tractor } from '@aircall/tractor-v2';
import { Page, PageContent } from '@components/Page/Page';
import { PageHeader } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function CallsTagsPage() {
  const { t } = useTranslation();

  return (
    <Tractor>
      <Page>
        <PageHeader data-test='tags-page-header' largeTitle={t('calls.pages.tags.title')} />
        <PageContent>
          <TagsSection />
        </PageContent>
      </Page>
    </Tractor>
  );
}
