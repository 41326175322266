import { WebhookGeneralSettingsFormContentProps } from './Webhook.decl';

import {
  FormField,
  GridItem,
  GridLayout,
  pipeValidators,
  validateRequired,
  validateUrlFormat,
} from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function WebhookGeneralSettingsFormContent({
  fields,
  urlFormat,
  clientSecret,
}: WebhookGeneralSettingsFormContentProps) {
  const { t } = useTranslation();

  const shouldShowField = (name: string) => fields?.some((field) => field === name);
  const urlFormatRegex = urlFormat ? new RegExp(urlFormat) : undefined;

  return (
    <GridLayout gap={{ _: 'm', md: 'l' }}>
      {shouldShowField('custom_name') && (
        <GridItem xs={12} md={6}>
          <FormField
            name='customName'
            label={t('integrations.settings.general_settings.custom_webhook_name.label')}
            placeholder={t(
              'integrations.settings.general_settings.custom_webhook_name.placeholder'
            )}
            getErrorMessage={t}
            size='regular'
            data-test='webhook-custom-name-input'
          />
        </GridItem>
      )}
      {shouldShowField('url') && (
        <GridItem xs={12} md={6}>
          <FormField
            name='url'
            label={t('integrations.settings.general_settings.url.label')}
            placeholder={t('integrations.settings.general_settings.url.placeholder')}
            size='regular'
            ellipsis
            validate={pipeValidators(validateRequired, validateUrlFormat(urlFormatRegex))}
            getErrorMessage={t}
            data-test='webhook-url-input'
          />
        </GridItem>
      )}
      {clientSecret && (
        <GridItem xs={12} md={6}>
          <FormField
            name='clientSecret'
            label={t('integrations.settings.general_settings.client_secret.label')}
            size='regular'
            value={clientSecret}
            readOnly
            ellipsis
            getErrorMessage={t}
            data-test='webhook-token-input'
          />
        </GridItem>
      )}
    </GridLayout>
  );
}
