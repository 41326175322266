import { useCallback } from 'react';

import { Reference, StoreObject } from '@apollo/client';
import { ClientException } from '@dashboard/library';
import { NumberIntegrationsQuery_numberIntegrations_data } from '@generated/NumberIntegrationsQuery';
import {
  RemoveNumberConnectedIntegrationMutation,
  RemoveNumberConnectedIntegrationMutationVariables,
} from '@generated/RemoveNumberConnectedIntegrationMutation';
import { REMOVE_NUMBER_CONNECTED_INTEGRATION } from '@graphql/mutations/RemoveNumberConnectedIntegration';
import { getExternalErrorTranslationStrings } from '@helpers/errors.helpers';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useGraphMutation } from '@hooks/useMutation';
import { useToast } from '@hooks/useToast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface UseRemoveNumberConnectedIntegrationReturnType {
  removeNumberConnectedIntegration: (
    integrations: NumberIntegrationsQuery_numberIntegrations_data
  ) => Promise<void>;
}

export const useRemoveNumberConnectedIntegration =
  (): UseRemoveNumberConnectedIntegrationReturnType => {
    const { t } = useTranslation();
    const { numberId } = useParams();
    const { currentCompany } = useGlobalData();
    const toast = useToast();
    const [mutate] = useGraphMutation<
      RemoveNumberConnectedIntegrationMutation,
      RemoveNumberConnectedIntegrationMutationVariables
    >(REMOVE_NUMBER_CONNECTED_INTEGRATION);

    const removeNumberConnectedIntegration = useCallback(
      async (integration: NumberIntegrationsQuery_numberIntegrations_data) => {
        const {
          id: integrationId,
          attributes: { service, name, customName },
        } = integration;

        const isHub = service === 'hub';
        const isBridge = service === 'bridge';
        const input = { integrationIds: [integrationId.toString()] };

        const variables = {
          input,
          companyId: currentCompany.id,
          numberId: parseInt(numberId),
          skipHub: !isHub,
          skipBridge: !isBridge,
        };

        try {
          await mutate({
            variables,
            update: (cache) => {
              cache.modify({
                fields: {
                  numberIntegrations: (numberIntegrationsCached, { readField }) => ({
                    data: numberIntegrationsCached.data.filter(
                      (integrationRef: StoreObject | Reference | undefined) =>
                        readField('id', integrationRef) !== integrationId
                    ),
                  }),
                },
              });
            },
            optimisticResponse: {
              removeNumberConnectedIntegrationBridge: {
                _: true,
                __typename: 'Void',
              },
              removeNumberConnectedIntegrationHub: {
                _: true,
                __typename: 'Void',
              },
            },
          });
          toast.showToast({
            message: t('success_feedback_messages.deletion_success', {
              name: customName || name,
            }),
          });
        } catch (e) {
          const { error } = e as ClientException;

          toast.showToast({
            variant: 'critical',
            message: t(getExternalErrorTranslationStrings(error)),
          });
        }
      },
      [currentCompany.id, mutate, numberId, t, toast]
    );

    return {
      removeNumberConnectedIntegration,
    };
  };
