import { useCallback } from 'react';

import { TRANSLATION_PREFIX } from '../config';

import { AddTeamModal } from './AddTeamModal';
import { AddUserModal } from './AddUserModal';

import { PlusOutlined } from '@aircall/icons';
import {
  Typography,
  Spacer,
  Icon,
  Dropdown,
  Menu,
  MenuItem,
  MenuItemLabel,
  getColor,
} from '@aircall/tractor-v2';
import { SearchTeamsQuery_searchTeams_items as Team } from '@generated/SearchTeamsQuery';
import { useAddDispatchGroup, DISPATCH_GROUP_TYPE } from '@hooks/useAddDispatchGroup';
import { useToggle } from '@hooks/useToggle/useToggle';
import styled from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';

const AddOptionRow = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  padding: 0;

  &:hover {
    > div {
      text-decoration: underline;
    }
  }

  /* Icon */

  > div:first-child > div:first-child {
    border: 1px solid ${getColor('surface-default')};
    border-radius: sm;
    background: ${getColor('surface-inverted')};
    transition: all 0.2s ease-in-out;

    path {
      fill: ${getColor('icon-inverted')};
    }
  }

  &:hover > div:first-child > div:first-child {
    border: 1px solid ${getColor('text-base')};
    background-color: surface-default;

    path {
      fill: ${getColor('text-base')};
    }
  }

  &::before {
    top: 24px !important;
  }
`;

interface AddOptionButtonProps {
  onAddUser?: () => void;
  onAddTeam?: () => void;
}

export function AddOptionButton({ onAddUser, onAddTeam }: AddOptionButtonProps) {
  const { t } = useTranslation();

  return (
    <AddOptionRow>
      <Dropdown
        trigger={
          <Spacer
            space='xxs'
            cursor='pointer'
            alignItems='center'
            data-test='add-option-dropdown-trigger'
          >
            <Icon component={PlusOutlined} size={24} />
            <Typography>
              {t(`${TRANSLATION_PREFIX}.add_dispatch_group_section.add_option`)}
            </Typography>
          </Spacer>
        }
        side='bottom'
        align='start'
      >
        <Menu>
          {onAddTeam && (
            <MenuItem onClick={onAddTeam} data-test='add-option-add-team'>
              <MenuItemLabel>
                {t(`${TRANSLATION_PREFIX}.add_dispatch_group_section.add_team`)}
              </MenuItemLabel>
            </MenuItem>
          )}
          {onAddUser && (
            <MenuItem onClick={onAddUser} data-test='add-option-add-user'>
              <MenuItemLabel>
                {t(`${TRANSLATION_PREFIX}.add_dispatch_group_section.add_user`)}
              </MenuItemLabel>
            </MenuItem>
          )}
        </Menu>
      </Dropdown>
    </AddOptionRow>
  );
}

interface AddOptionProps {
  numberId: string;
  priority?: number;
  idsBlacklist?: Set<string>;
}

export function AddOption({ numberId, priority = 1, idsBlacklist = new Set() }: AddOptionProps) {
  const [userModalIsOpen, _1, openUserModal, closeUserModal] = useToggle(false);
  const [teamModalIsOpen, _2, openTeamModal, closeTeamModal] = useToggle(false);
  const { addDispatchGroup } = useAddDispatchGroup({ numberId });

  const handleAddTeam = useCallback(
    (team: Team) => {
      addDispatchGroup({
        team,
        attributes: { priority, queuingTimeout: 20 },
        type: DISPATCH_GROUP_TYPE.TEAM,
        successMessageName: team.name,
      });
    },
    [addDispatchGroup, priority]
  );

  return (
    <>
      <AddTeamModal
        idsBlacklist={idsBlacklist}
        isOpen={teamModalIsOpen}
        onAdd={handleAddTeam}
        onClose={closeTeamModal}
      />
      <AddUserModal
        numberId={numberId}
        priority={priority}
        idsBlacklist={idsBlacklist}
        isOpen={userModalIsOpen}
        onClose={closeUserModal}
      />
      <AddOptionButton
        onAddUser={openUserModal}
        onAddTeam={openTeamModal}
        data-test='add-option-button'
      />
    </>
  );
}
