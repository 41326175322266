import { memo } from 'react';

import * as RoutesConstants from '../../constants/routes.constants';
import { CompanyLogo } from '../CompanyLogo/CompanyLogo';
import { NavItem } from '../NavItem/NavItem';

import { AddonTrialsSection } from './AddonTrialsSection/AddonTrialsSection';
import { SidebarBottomLogo, SidebarWrapper } from './Sidebar.constants';
import { SidebarContent } from './SidebarContent';

import { SettingsOutlined } from '@aircall/icons';
import { Box, Flex } from '@aircall/tractor-v2';
import { HasAccess } from '@components/Permissions';
import { TrialWidgetSection } from '@components/TrialWidgetSection/TrialWidgetSection';
import { ResellerConfig } from '@config/resellers.config';
import { PERMISSIONS_ACTIONS, RESOURCE } from '@constants/permissions.constants';
import { useTranslation } from 'react-i18next';

export const Sidebar = memo(() => {
  const { t } = useTranslation();
  const {
    featureSet: { shouldShowSidebarBottomLogo },
  } = ResellerConfig;

  return (
    <SidebarWrapper>
      <CompanyLogo />

      <SidebarContent />
      <Box>
        <AddonTrialsSection />
        <TrialWidgetSection />
        <HasAccess
          oneOf={[
            RESOURCE.COMPANY_GENERAL,
            RESOURCE.COMPANY_PLAN,
            RESOURCE.COMPANY_BILLING,
            RESOURCE.COMPANY_SECURITY,
            RESOURCE.COMPANY_ROLES,
          ]}
          action={PERMISSIONS_ACTIONS.READ}
        >
          <NavItem
            to={RoutesConstants.ACCOUNT_ROUTE}
            iconComponent={SettingsOutlined}
            text={t('account.title')}
            data-test='account-nav-item'
          />
        </HasAccess>

        {shouldShowSidebarBottomLogo && (
          <Flex padding={10} data-test='sidebar-bottom-logo' w={126}>
            <SidebarBottomLogo
              name={ResellerConfig.getAssetPath('sidebar_logo')}
              width='100%'
              height='100%'
            />
          </Flex>
        )}
      </Box>
    </SidebarWrapper>
  );
});
