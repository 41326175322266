import { createContext } from 'react';

import { ResourceProps } from './Permissions.decl';

import { RESOURCE } from '@constants/permissions.constants';

export const ResourceContext = createContext<RESOURCE | undefined>(undefined);

export function Resource({ name, children }: ResourceProps) {
  return <ResourceContext.Provider value={name}>{children}</ResourceContext.Provider>;
}
