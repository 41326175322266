import {
  BROWSER_CHROME_ALLOWED_VERSIONS,
  BROWSER_OPERA_ALLOWED_VERSIONS,
  BROWSER_IE_ALLOWED_VERSIONS,
  BROWSER_EDGE_ALLOWED_VERSIONS,
  BROWSER_FIREFOX_ALLOWED_VERSIONS,
  BROWSER_SAFARI_ALLOWED_VERSIONS,
} from '@constants/browser.constants';
import platform from 'platform';
import { satisfies, valid, coerce } from 'semver';

export type Browser = Pick<typeof platform, 'name' | 'version'>;
export type PredicateFunc = (version: string) => boolean;
export type BrowserMapper = Record<string, PredicateFunc>;

/**
 * Map holding keys as "browsers", and values as "supported versions".
 * Supported versions support SemVer notation.
 */
export const browserMapper: BrowserMapper = {
  chrome: (version) => satisfies(version, BROWSER_CHROME_ALLOWED_VERSIONS),
  firefox: (version) => satisfies(version, BROWSER_FIREFOX_ALLOWED_VERSIONS),
  safari: (version) => satisfies(version, BROWSER_SAFARI_ALLOWED_VERSIONS),
  opera: (version) => satisfies(version, BROWSER_OPERA_ALLOWED_VERSIONS),
  edge: (version) => satisfies(version, BROWSER_EDGE_ALLOWED_VERSIONS),
  ie: (version) => satisfies(version, BROWSER_IE_ALLOWED_VERSIONS),
};

/**
 * Determine if the current client can have access to the dashboard.
 * @param mapper - mapper holding browser and versions
 * @returns a function that accept a version and name of a given browser
 */
export function determineBrowserSupport(mapper: BrowserMapper) {
  return ({ version, name }: Required<Browser>): boolean => {
    if (!version || !name) {
      return false;
    }

    if (!Object.keys(mapper).includes(name)) {
      return true;
    }

    return mapper[name](version);
  };
}

/**
 * Predicate for browser support.
 * @returns true if the current browser meets version criterias
 */
export function isBrowserAccepted(): boolean {
  return determineBrowserSupport(browserMapper)({
    name: platform.name?.toLocaleLowerCase() || '',
    version: valid(coerce(platform.version)) || '',
  });
}
