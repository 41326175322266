import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { FormFlowPageSuccessProps } from './FormFlow.decl';
import { useFormFlow } from './useFormFlow';

import { CloseOutlined } from '@aircall/icons';
import {
  IconButton,
  Flex,
  Typography,
  Spacer,
  ModalDialog,
  getSpace,
  getColor,
} from '@aircall/tractor-v2';
import { ReactComponent as FlagSuccessSVG } from '@assets/images/flag_success.svg';
import { Gap } from '@dashboard/library';
import noop from 'lodash-es/noop';

const Content = styled(Flex)`
  position: relative;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${getSpace('m')};
  text-align: center;
`;

const CloseIcon = styled(IconButton)`
  position: absolute;
  top: ${getSpace('s')};
  right: ${getSpace('s')};
`;

const Footer = styled(Flex)`
  width: 100%;
  justify-content: flex-end;
  padding: ${getSpace('xs')};
  border-top: 1px solid ${getColor('neutral-200')};
  background: white;
`;

export function FormFlowPageSuccess({
  text,
  subText,
  image,
  renderFooter,
  'data-test': dataTest = 'form-flow-page-success',
}: FormFlowPageSuccessProps) {
  const { onSuccess, onClose } = useFormFlow();

  // This state is useless but without it the modal open animation does not start
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    onSuccess();
  }, [onSuccess]);

  const Image = image || FlagSuccessSVG;

  return (
    <ModalDialog show={isOpen} size='small' onHide={noop}>
      <Content data-test={dataTest}>
        <CloseIcon
          size={24}
          component={CloseOutlined}
          color='grey.darker'
          onClick={onClose}
          data-test={`${dataTest}-close`}
        />

        <Gap flexDirection='column' gap='l' alignItems='center'>
          <Image width={310} height='auto' />

          <Spacer direction='vertical' space='xxs'>
            <Typography variant='headingBoldXS'>{text}</Typography>
            <Typography variant='bodyRegularM' lineHeight={1.3}>
              {subText}
            </Typography>
          </Spacer>
        </Gap>
      </Content>
      {renderFooter && <Footer>{renderFooter()}</Footer>}
    </ModalDialog>
  );
}
