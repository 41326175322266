/* Private routes */
export const HOME_ROUTE = '/';
export const COMPANY_ROUTE = '/company';

export const ONBOARDING_ROUTE = '/onboarding';

export const DOWNLOAD_APP_ROUTE = '/download';

// Landing Page
export const LANDING_PAGE_ROUTE = '/landing';

// Numbers
export const NUMBERS_ROUTE = '/numbers';
export const NUMBERS_ROUTE_TAB_ACTIVE = '/numbers/active';
export const NUMBERS_ROUTE_TAB_UNVERIFIED = '/numbers/unverified';
export const NUMBER_DETAIL_ROUTE = '/numbers/:numberId';
export const NUMBER_DETAIL_ROUTE_TAB = '/numbers/:numberId/:tab';
export const NUMBER_PURCHASE_ROUTE = '/numbers/purchase';
export const NUMBER_PURCHASE_ROUTE_AT_IVR_CREATION = '/numbers/purchase/:numberId/:key';
export const NUMBER_PURCHASE_ROUTE_AT_IVR_EDITION = '/numbers/purchase/:numberId/:key/:settingId';

export const NUMBER_PROOF_OF_ID_ROUTE = '/numbers/:number/proof_of_id';

// Teams
export const TEAMS_ROUTE = '/teams';
export const TEAM_DETAIL_ROUTE = '/teams/:teamId';

// Users
export const USERS_ROUTE = '/users';
export const USER_DETAIL_ROUTE = '/users/:userId';
export const USER_DETAIL_ROUTE_TAB = '/users/:userId/:tab';

// Calls
export const CALLS_ROUTE = '/calls';
export const CALLS_CHILD_ROUTE = {
  CALENDARS: '/calls/date-exceptions',
  TAGS: '/calls/tags',
  BLOCKED_NUMBERS: '/calls/blocked_numbers',
  CALL_ASSET: '/calls/:callId/:callType',
};

// Account
export const ACCOUNT_ROUTE = '/account';
export const ACCOUNT_ROUTE_TAB = '/account/:tab';

// Integrations
export const GENERIC_INTEGRATION = '/test-integration';
export const INTEGRATIONS_ROUTE = '/integrations';
export const INTEGRATIONS_ROUTE_TAB = '/integrations/:tab';
export const INTEGRATIONS_CHILD_ROUTE = {
  CONNECTED_INTEGRATIONS: '/integrations/connected-integrations',
  APPLICATIONS: '/integrations/applications',
  API_KEYS: '/integrations/api-keys',
  UPDATES: '/integrations/updates',
};

export enum INTEGRATIONS_TABS {
  CONNECTED_INTEGRATIONS = 'connected-integrations',
  APPLICATIONS = 'applications',
  API_KEYS = 'api-keys',
  UPDATES = 'updates',
}

export const CHECK_HEALTH = '/check-health';
// Stats
export const STATS_ROUTE = '/stats';
export const STATS_ROUTE_TAB = '/stats/:tab';
export const STATS_CHILD_ROUTE = {
  OVERVIEW: '/stats/overview',
  OVERVIEW_BETA: '/stats/overview_beta',
  ANALYTICS: '/stats/analytics',
  ANALYTICS_PLUS: '/stats/analytics_plus',
  UNANSWERED_CALLS: '/stats/unanswered_calls',
  UNANSWERED_CALLS_PRO: '/stats/unanswered_calls_pro',
  MONITORING: '/stats/monitoring',
  INBOUND: '/stats/inbound',
  AVAILABILITIES: '/stats/availabilities',
  USERS_ACTIVITY: '/stats/users_activity',
  USERS_ACTIVITY_PRO: '/stats/users_activity_pro',
  OUTBOUND_ACTIVITY: '/stats/outbound_activity',
  OUTBOUND_ACTIVITY_PRO: '/stats/outbound_activity_pro',
  INBOUND_ACTIVITY: '/stats/inbound_activity',
  INBOUND_ACTIVITY_PRO: '/stats/inbound_activity_pro',
  CALL_QUALITY: '/stats/call_quality',
  CALL_QUALITY_PRO: '/stats/call_quality_pro',
  CALL_HISTORY: '/stats/call_history',
};

// Activity feeds
export const ACTIVITY_FEED_ROUTE = '/activity_feed';

export const INTEGRATION_ROUTE = '/integrations/:applicationType/:integrationName/:integrationId';
export const INTEGRATION_ROUTE_TAB =
  '/integrations/:applicationType/:integrationName/:integrationId/:tab';

// Temporary route to be deleted when we will integrate the actual route coming from the extension
export const WEBHOOK_ROUTE = '/integrations/webhook/:integrationId';

// Temporary route to be deleted when we will integrate the actual route coming from the extension
export const INTEGRATION_APPLICATIONS =
  '/applications/:applicationType/:applicationName/:applicationId';

export const INTEGRATION_CHILD_ROUTES = {
  /** Integration settings page */
  INTEGRATION_SETTINGS: '/:applicationType/:integrationName/:integrationId',
  INTEGRATION_SETTINGS_TAB: '/:applicationType/:integrationName/:integrationId/:tab',

  /** Application page */
  APPLICATION: '/app/:applicationType/:applicationName/:applicationId',

  /** Flow installation pages */
  FLOW_INSTALL: '/flow/install/:applicationType/:applicationName/:applicationId',
  FLOW_CREATE: '/flow/create/:applicationType/:applicationName/:applicationId',
  FLOW_COMPLETE: '/flow/complete/:applicationType/:applicationName/:applicationId',
};

export const INTEGRATION_FULL_ROUTES = Object.keys(INTEGRATION_CHILD_ROUTES).reduce(
  (acc, routeName: string) => {
    const childRouteName = routeName as keyof typeof INTEGRATION_CHILD_ROUTES;
    acc[childRouteName] = `${INTEGRATIONS_ROUTE}${acc[childRouteName]}`;
    return acc;
  },
  { ...INTEGRATION_CHILD_ROUTES }
);

/* Integrations install flow */
export const INTEGRATION_AUTHORIZE = '/oauth/authorize';
export const INTEGRATION_AUTHORIZE_SUCCESS = '/oauth/success';

export const ZENDESK_SSO = '/sso/zendesk';
export const OAUTH_LOGIN = '/oauth/login';
export const SANDBOX_SSO = '/sso/sandbox';

/* Public routes */
export const LOGIN_SUB_PASSWORD_ROUTE = '/';
export const LOGIN_SUB_SSO_ROUTE = '/sso';

export const LOGIN_ROUTE = '/login';
export const LOGIN_SSO_ROUTE = `${LOGIN_ROUTE}${LOGIN_SUB_SSO_ROUTE}`;

export const SET_NEW_PASSWORD_ROUTE = '/set_new_password';
export const FORGOT_PASSWORD_ROUTE = '/forgot_password';
export const RESET_PASSWORD_ROUTE = '/reset_password';
export const CONFIRM_INVITATION_ROUTE = '/confirm_invitation';
export const CONFIRM_USER_CREATION_ROUTE = '/users/confirmation';
export const ACTION_RESULT_ROUTE = '/action_result';

export const TEST_ROUTE = '/test';

// Route used for testing purposes: disabled top-level route
export const DISABLED_TOP_LEVEL_TEST_ROUTE = '/test_route';

export const PUBLIC_ROUTES = [
  LOGIN_ROUTE,
  SET_NEW_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
  CONFIRM_INVITATION_ROUTE,
  CONFIRM_USER_CREATION_ROUTE,
  DOWNLOAD_APP_ROUTE,
];

export enum ACCOUNT_TABS {
  GENERAL = 'general',
  YOUR_PLAN = 'plan',
  BILLING = 'billing',
  SECURITY = 'security',
  PERMISSIONS = 'permissions',
}

export const UNAUTHORIZED_ROUTE = '/unauthorized';
