import { useCallback, useRef, useState } from 'react';

import { Typography, Spacer } from '@aircall/tractor-v2';
import { CallTagModal } from '@components/CallTagModal';
import { CallTagFormValues } from '@components/CallTagModal/CallTagModal.decl';
import { DataLayout } from '@components/DataLayout/DataLayout';
import { ListCallTagItem } from '@components/ListCallTagItem';
import { Paper, AddButton, List, isTruthy, Gap } from '@dashboard/library';
import { DeleteTagMutation, DeleteTagMutationVariables } from '@generated/DeleteTagMutation';
import {
  TagsListQuery,
  TagsListQuery_getCompany_tags as CompanyTag,
} from '@generated/TagsListQuery';
import { DELETE_TAG_MUTATION } from '@graphql/mutations/DeleteTagMutation';
import { TAGS_LIST_QUERY } from '@graphql/queries/TagsListQuery';
import { useAddTag } from '@hooks/useAddTag/useAddTag';
import { useDeleteEntity } from '@hooks/useDeleteEntity/useDeleteEntity';
import { useToggle } from '@hooks/useToggle/useToggle';
import { useTracker } from '@hooks/useTracker/useTracker';
import { useUpdateTag } from '@hooks/useUpdateTag';
import { useTranslation } from 'react-i18next';

export function TagsSection() {
  const { t } = useTranslation();
  const { track } = useTracker();
  const [isAddModalOpen, , openAddModal, closeAddModal] = useToggle();
  const [isEditModalOpen, , openEditModal, closeEditModal] = useToggle();
  const [tagToEdit, setTagToEdit] = useState<CompanyTag | null>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const [deleteTag] = useDeleteEntity<DeleteTagMutation, DeleteTagMutationVariables>({
    mutation: DELETE_TAG_MUTATION,
    mutationName: 'deleteTag',
    __typename: 'Tag',
  });

  const { addTag, loading: addTagLoading } = useAddTag();
  const { updateTag: updateCallTag } = useUpdateTag();

  const handleDelete = useCallback(
    async (tag: CompanyTag) => {
      await deleteTag(tag.id, { input: { id: tag.id } }, tag.name);
      track({ event: 'tag_deleted' });
    },
    [deleteTag, track]
  );

  const handleSubmitNewTag = useCallback(
    async (values: CallTagFormValues) => {
      closeAddModal();
      const { name, color } = values;

      const error = await addTag(name, color);
      const list = listRef.current;

      /* istanbul ignore next */
      if (error || !list) {
        return;
      }

      list.scrollTo?.({ top: list.scrollHeight, behavior: 'smooth' });
    },
    [closeAddModal, addTag]
  );

  const handleOpenEdit = useCallback(
    (tag: CompanyTag) => {
      setTagToEdit(tag);
      openEditModal();
    },
    [openEditModal]
  );

  function handleEdit(id: string, values: CallTagFormValues) {
    const tag: CompanyTag = { id, ...values } as CompanyTag;
    updateCallTag(tag);
    track({ event: 'tag_edited' });
    closeEditModal();
    setTagToEdit(null);
  }

  return (
    <DataLayout<TagsListQuery> query={TAGS_LIST_QUERY}>
      {({ data: { getCompany } }) => {
        // GraphQL types are wrong, this should never happen
        /* istanbul ignore next */
        const tags = getCompany.tags ?? [];

        const filteredTags = tags
          .filter((tag) => !tag?.description && tag?.color.startsWith('#'))
          .filter(isTruthy);

        /* istanbul ignore next */
        const namesBlacklist = tags.map((tag) => tag?.name ?? '');

        return (
          <>
            <CallTagModal
              title={t('calls.pages.tags.add_modal.title')}
              isOpen={isAddModalOpen}
              onClose={closeAddModal}
              onSubmit={handleSubmitNewTag}
              namesBlacklist={namesBlacklist}
            />

            {tagToEdit && (
              <CallTagModal
                title={t('calls.pages.tags.edit_modal.title')}
                isOpen={isEditModalOpen}
                onClose={closeEditModal}
                onSubmit={(values) => handleEdit(tagToEdit.id, values)}
                namesBlacklist={namesBlacklist.filter((tagName) => tagName !== tagToEdit.name)}
                initialValues={{
                  name: tagToEdit.name,
                  color: tagToEdit.color,
                }}
              />
            )}

            <Spacer fluid direction='vertical' space='m'>
              <Paper>
                <Typography data-test='paper-subtitle' color='text-base' mb='s'>
                  {t('calls.pages.tags.subtitle')}
                </Typography>

                <Gap flexDirection='column' alignItems='flex-start' space='s'>
                  <List ref={listRef} hasScroll overLoading={addTagLoading}>
                    {filteredTags.map((tag) => (
                      <ListCallTagItem
                        key={tag.id}
                        tag={tag}
                        onEdit={handleOpenEdit}
                        onDelete={handleDelete}
                      />
                    ))}
                  </List>

                  <AddButton
                    data-test='tag-add-button'
                    isLarge={!filteredTags.length}
                    onClick={openAddModal}
                  >
                    {t('calls.pages.tags.add')}
                  </AddButton>
                </Gap>
              </Paper>
            </Spacer>
          </>
        );
      }}
    </DataLayout>
  );
}
