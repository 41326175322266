import React, { createContext, useMemo } from 'react';

import { Loading } from '@dashboard/library';
import { ApplicationsListQuery } from '@generated/ApplicationsListQuery';
import { IntegrationsListQuery } from '@generated/IntegrationsListQuery';
import { UseIntegrationsParams, useIntegrations } from '@hooks/useIntegrations';

export interface IntegrationPageContextType {
  integrations: IntegrationsListQuery;
  applications: ApplicationsListQuery;
}

export const IntegrationPageContext = createContext<IntegrationPageContextType>(
  {} as IntegrationPageContextType
);

interface IntegrationPageDataProviderProps extends UseIntegrationsParams {
  children: React.ReactNode;
}

export function IntegrationPageDataProvider({
  children,
  queryOptions = {},
}: IntegrationPageDataProviderProps) {
  const { integrations, applications, hasData } = useIntegrations({ queryOptions });
  const memoizedContextValue = useMemo(
    () => ({
      integrations: integrations!,
      applications: applications!,
    }),
    [integrations, applications]
  );

  // We do not use isLoading here because of the cache-and-network policy
  // The second time we fetch, loading will be true for the network request but the cache data will already be there
  // This way we display data asap for the user. When loading is false, data might change a bit and will be re-rendered.
  if (!hasData) {
    return <Loading data-test='connected-integrations-loading' />;
  }

  return (
    <IntegrationPageContext.Provider value={memoizedContextValue}>
      {children}
    </IntegrationPageContext.Provider>
  );
}
