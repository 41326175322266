import { css, StyledProps } from 'styled-components';

import { DropzoneAreaProps } from './Dropzone.decl';

import { getColor } from '@aircall/tractor-v2';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeDropzoneColor({ isDragActive, variant }: StyledProps<DropzoneAreaProps>) {
  if (isDragActive) {
    return css`
      border: 1px solid ${getColor('interaction-primary')};
      border-top: ${variant === 'secondary' ? 'none' : undefined};
      background-color: ${getColor('primary-100')};
    `;
  }

  return css`
    border: 1px dashed ${getColor('neutral-700')};
    border-top: ${variant === 'secondary' ? 'none' : undefined};
  `;
}
