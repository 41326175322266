import { SearchableSelectFieldProps } from './SearchableSelectField.decl';

import { FormItem } from '@aircall/tractor-v2';
import { MultiSelectOption, SingleSearchSelect } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

export function SearchableSelectField<TOption extends MultiSelectOption = MultiSelectOption>({
  label,
  onChange,
  value,
  placeholder,
  ...rest
}: SearchableSelectFieldProps<TOption>) {
  const { t } = useTranslation();
  const onSelect = (keys: TOption['value'][]) => {
    onChange(keys?.[0]);
  };
  return (
    <FormItem label={label}>
      <SingleSearchSelect<TOption>
        texts={{
          placeholder,
          result: t('generic.result'),
          results: t('generic.results'),
        }}
        isSearch
        triggerStyle='select'
        onSelect={onSelect}
        selectedKeys={value ? [value] : []}
        w='100%'
        {...rest}
      />
    </FormItem>
  );
}
