import { useRef, useCallback, useState, useEffect } from 'react';

import {
  ClientError,
  getExternalErrorTranslationStrings,
  handleApolloError,
} from '../../helpers/errors.helpers';

import { UseCancellableLazyQuery } from './useQuery.decl';

import {
  ApolloError,
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useApolloClient,
} from '@apollo/client';
import { useToast } from '@hooks/useToast';
import { useTranslation } from 'react-i18next';

/**
 * A hook that provides a lazy query with `cancel()` to make the query cancellable after dispatching it.
 * @returns methods to execute a lazy query and cancel it
 * @example
 * const { lazyQuery, cancel } = useCancellableLazyQuery(NUMBER_DETAIL_QUERY,
    {
      variables: { id: numberId }
    },
    false
   );
 *
 */
export function useCancellableLazyQuery<TData = unknown, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
  /** Whether it will show a snackbar on error */
  showSnackbar = true
): UseCancellableLazyQuery<TData> {
  const { t } = useTranslation();
  const client = useApolloClient();
  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<TData>();
  const [error, setError] = useState<ClientError | null>(null);

  /** Ref to the subscription of the query */
  const subscriptionRef = useRef<ZenObservable.Subscription>();

  /** Unsubscribes the queried result */
  const cancel = useCallback(() => {
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
      subscriptionRef.current = undefined;
      setLoading(false);
    }
  }, []);

  /** Imperative way of calling the query */
  const lazyQuery = useCallback(() => {
    const observable = client.watchQuery({
      query,
      ...options,
    });

    const sub = observable.subscribe({
      next(x) {
        cancel();
        setError(null);
        setData(x.data);
      },
      // FIXME: this part was not able to be covered because
      // mock errors using MockedProvider did not make it call the `error()`
      // under the subscription model.
      /* istanbul ignore next */
      error(err: ApolloError) {
        setLoading(false);
        cancel();
        const apolloErr = handleApolloError(err);
        setError(apolloErr);
        setData(undefined);

        if (showSnackbar) {
          toast.showToast({
            variant: 'critical',
            message: t(getExternalErrorTranslationStrings(apolloErr)),
          });
        }
      },
    });

    subscriptionRef.current = sub;
  }, [cancel, client, options, query, showSnackbar, t, toast]);

  // Unsubscribes the query
  useEffect(
    () => () => {
      cancel();
    },
    [cancel]
  );

  return {
    loading,
    data,
    error,
    lazyQuery,
    cancel,
  };
}
