import { TRANSLATION_PREFIX } from '../config';

import { AddTeamModalProps, AddTeamFormValues } from './AddDispatchGroupSection.decl';

import { SearchableSelectTeamItem } from '@components/SearchableSelectTeamItem';
import { SearchableSelectTeamOption } from '@components/SearchableSelectTeamItem/SearchableSelectTeamItem.decl';
import { SearchAddModalForm } from '@components/SearchAddModalForm';
import { useSearchableSelectTeam } from '@hooks/useSearchableSelectTeam/useSearchableSelectTeam';
import { useTranslation } from 'react-i18next';

export function AddTeamModal({
  isOpen,
  onClose,
  idsBlacklist = new Set(),
  onAdd,
}: AddTeamModalProps) {
  const { t } = useTranslation();
  const { options, setQuery, loading, teams, resultsCount } = useSearchableSelectTeam({
    idsBlacklist,
    isSelectMounted: isOpen,
  });

  const onSubmit = async ({ teamId }: AddTeamFormValues) => {
    const team = teams.find(({ ID }) => ID === teamId);

    /* istanbul ignore next */
    if (!team) {
      return;
    }

    onAdd(team);
    onClose();
  };

  const renderItem = (o: SearchableSelectTeamOption) => <SearchableSelectTeamItem {...o} />;
  return (
    <SearchAddModalForm<AddTeamFormValues, SearchableSelectTeamOption>
      isOpen={isOpen}
      label={t(`${TRANSLATION_PREFIX}.add_dispatch_group_section.team_modal.select_label`)}
      onClose={onClose}
      onSubmit={onSubmit}
      options={options}
      renderItem={renderItem}
      onSearch={setQuery}
      loading={loading}
      name='teamId'
      placeholder={t('app.roles.team')}
      titleText={t(`${TRANSLATION_PREFIX}.add_dispatch_group_section.add_team`)}
      submitButtonText={t(`${TRANSLATION_PREFIX}.add_dispatch_group_section.modal.submit`)}
      selectName='team-select'
      resultsCount={resultsCount}
    />
  );
}
