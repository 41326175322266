import {
  AIRCALL_AUTHENTICATION_API_URL,
  AIRCALL_BRIDGE_API_URL,
  AIRCALL_DASHBOARD_URL,
} from './environment.constants';

import { mergeBaseUrlWithEndpoints } from '@dashboard/library';

const aircallAuthApiUrls = {
  USERS_SIGNIN: '/auth/v1/users/session',
  USERS_SIGNIN_MFA: '/auth/v2/users/session',
  USERS_SIGNIN_VALIDATE_CODE: '/auth/v2/users/sessions/code',
  USERS_SIGNIN_RESEND_CODE: '/auth/v2/users/sessions/resend',
  USERS_ACCEPT_INVITATION: '/auth/v1/users/invitation/accept',
  USERS_ACCEPT_INVITATION_V2: '/auth/v2/users/invitation/accept',
  GOOGLE_SIGNIN: '/auth/v1/users/sessions/idp/google',
  COGNITO_TOKEN: '/auth/v0/tokens',
  REFRESH_TOKEN: '/auth/v1/tokens/refresh',
  NON_MIGRATED_REFRESH_TOKEN: '/auth/v0/tokens/refresh',
  SAML_IDP: '/auth/v1/users/sessions/saml',
  SAML_CODE: '/auth/v1/users/sessions/idp/saml/code',
  SAML_FILE_UPLOAD: '/auth/v1/companies/:id/saml/configuration',
};

export const AIRCALL_AUTH_API_ENDPOINTS = mergeBaseUrlWithEndpoints<typeof aircallAuthApiUrls>(
  AIRCALL_AUTHENTICATION_API_URL,
  aircallAuthApiUrls
);

const aircallBridgeInstallUrls = {
  FRONT_INSTALL_URL: `/v1/front/oauth2/request_access?dashboard_url=${AIRCALL_DASHBOARD_URL}&channel_id=:webhookUrl&access_token=:bearerToken`,
};

export const AIRCALL_BRIDGE_INSTALL_ENDPOINTS = mergeBaseUrlWithEndpoints<
  typeof aircallBridgeInstallUrls
>(AIRCALL_BRIDGE_API_URL, aircallBridgeInstallUrls);
