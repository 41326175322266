import { useEffect, useState } from 'react';

import { CountryFilterProps } from './CountryFilter.decl';

import {
  CountryMultiSearchSelect,
  capitalizeFirstLetter,
  useGraphQuery,
  isTruthy,
} from '@dashboard/library';
import { GetCompanyCountries } from '@generated/GetCompanyCountries';
import { GET_COMPANY_COUNTRIES_QUERY } from '@graphql/queries/GetCountriesForCompany';
import { useTranslation } from 'react-i18next';

export function CountryFilter({ onSelect, initialCountries }: CountryFilterProps) {
  const { t } = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>(initialCountries || []);
  const { data } = useGraphQuery<GetCompanyCountries>(GET_COMPANY_COUNTRIES_QUERY);
  const countries = data?.getCountryISOCodesForCompany.filter(isTruthy) || undefined;

  useEffect(() => {
    setSelectedKeys(initialCountries ? initialCountries.map((c) => c.toUpperCase()) : []);
  }, [initialCountries]);

  function handleSelect(keys: string[]) {
    setSelectedKeys(keys);
    onSelect(keys.map((c) => c.toLowerCase()));
  }

  return (
    <CountryMultiSearchSelect
      data-test='country-filter'
      selectedKeys={selectedKeys}
      texts={{
        placeholder: capitalizeFirstLetter(t('generic.country')),
        item: t('generic.country'),
        items: t('generic.countries'),
        result: t('generic.result'),
        results: t('generic.results'),
      }}
      onSelect={handleSelect}
      countries={countries}
    />
  );
}
