import { useMemo } from 'react';

import { IvrSettingCard } from '../IvrSettingCard/IvrSettingCard';

import { Box } from '@aircall/tractor-v2';
import { NumberDetailQuery_numberDetail_ivrSettings } from '@generated/NumberDetailQuery';
import { useTranslation } from 'react-i18next';

export interface IvrSettingsProps {
  numberId: string;
  items: NumberDetailQuery_numberDetail_ivrSettings[];
  availableKeyOptions: { value: string; label: string }[];
  countryCode: string;
}

export function IvrSettings({
  items,
  availableKeyOptions,
  countryCode,
  numberId: _numberId,
}: IvrSettingsProps) {
  const { t } = useTranslation();
  const sortedItems = useMemo(
    () => items.slice().sort((a, b) => (a.key > b.key ? 1 : -1)),
    [items]
  );

  if (items.length === 0) {
    return (
      <Box
        px='36px'
        py='46px'
        borderWidth='1px'
        borderColor='neutral-400'
        borderStyle='dashed'
        ml='36px'
        mb='24px'
        borderRadius='8px'
        backgroundColor='surface-default'
      >
        {t('number_details.settings.call_distribution_section.diagram.ivr_settings.empty')}
      </Box>
    );
  }

  return (
    <>
      {sortedItems.map((item: NumberDetailQuery_numberDetail_ivrSettings) => (
        <IvrSettingCard
          item={item}
          key={`ivrSettingCard${item.id}`}
          availableKeyOptions={availableKeyOptions}
          countryCode={countryCode}
        />
      ))}
    </>
  );
}
