import { gql } from '@apollo/client';

export const GET_LOCALISED_CONTRACTS_QUERY = gql`
  query GetLocalisedContractsQuery($country: String!, $groupKeys: [String!]!) {
    getLocalisedContracts(country: $country, groupKeys: $groupKeys) {
      ID
      publishedVersion
    }
  }
`;
