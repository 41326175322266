import styled from 'styled-components';

import { StyledTrialButton, TrialAddonButtonProps } from './TrialAddonButton.decl';

import { Icon, Typography, getSpace } from '@aircall/tractor-v2';
import { useDashboardExtensionContext } from '@dashboard/extension';
import { useNavigateToAddonPage } from '@dashboard/library';
import { useCompanyAddons } from '@hooks/useCompanyAddons/useCompanyAddons';
import { getActiveAddonTrialByName } from '@hooks/useCompanyAddons/utils';
import { useGetEnableTrialAddonsFeatureFlags } from '@hooks/useGetEnableTrialAddonsFeatureFlags';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

const TrialButton = styled.button<StyledTrialButton>`
  display: inline-flex;
  min-height: 34px;
  align-items: center;
  justify-content: center;
  padding: ${getSpace('xxxs')} ${getSpace('xxs')};
  border: none;
  border-radius: 24px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  cursor: pointer;
  gap: 2px;
  transition: background-color 0.3s;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.9;
  }
`;

export function TrialAddonButton({
  addonName,
  color,
  backgroundColor,
  buttonName,
  icon,
  'data-test': dataTest = 'trial-addon-button',
}: Readonly<TrialAddonButtonProps>) {
  const { track } = useDashboardExtensionContext();
  const { isAddonEnabled } = useGetEnableTrialAddonsFeatureFlags();

  const navigateToAddonPage = useNavigateToAddonPage(track);

  const { addons } = useCompanyAddons();

  const trialAddon = getActiveAddonTrialByName(addonName, addons, isAddonEnabled);

  if (!trialAddon) {
    return null;
  }

  return (
    <TrialButton
      color={color}
      backgroundColor={backgroundColor}
      type='button'
      data-test={dataTest}
      onClick={() => navigateToAddonPage(addonName, buttonName)}
    >
      <Icon component={icon} size={18} />
      {trialAddon.remainingDays ? (
        <Typography variant='bodyMediumS' as='span' data-test='trial-ongoing'>
          {t('addons.page_header.trial_ongoing_label', {
            count: trialAddon.remainingDays,
          })}
        </Typography>
      ) : (
        <Typography variant='bodyMediumS' as='span' data-test='trial-finishes-today'>
          <Trans
            i18nKey='addons.page_header.trial_finishes_today_label'
            components={[<Typography variant='bodyMediumS' key={0} as='span' />]}
          />
        </Typography>
      )}
    </TrialButton>
  );
}
