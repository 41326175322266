import styled from 'styled-components';

import {
  Box,
  Typography,
  Flex,
  Spacer,
  getColor,
  useColor,
  getSpace,
  getRadii,
} from '@aircall/tractor-v2';
import flag from '@assets/images/onboarding_complete_success_flag.svg';
import { useTranslation } from 'react-i18next';
import { NavLink as RRNavLink } from 'react-router-dom';

const OnboardingCompleteContainer = styled(Box)`
  img {
    max-width: 300px;
    margin-top: 80px;
    margin-bottom: 60px;
  }
`;

const NextActionLink = styled(RRNavLink)`
  padding: ${getSpace('xxs')};
  border-radius: ${getRadii('sm')};
  background-color: ${getColor('neutral-100')};
  color: ${getColor('primary-500')};
`;

export function OnboardingComplete() {
  const white = useColor('neutral-0');
  const { t } = useTranslation();
  return (
    <OnboardingCompleteContainer backgroundColor={white} data-test='onboarding-complete-section'>
      <Flex flexDirection='column' justifyContent='center' alignItems='center'>
        <img data-test='onboarding-finished-flag' alt='success flag' src={flag} />
        <Spacer direction='vertical' space='m' alignItems='center'>
          <Typography variant='headingBoldXS'>
            {t('onboarding.complete_section.all_set_up')}
          </Typography>
          <Typography color='text.dark' variant='supportingMediumS'>
            {t('onboarding.complete_section.next_actions')}
          </Typography>
          <Box>
            <Spacer direction='horizontal' space='m' pb='80px'>
              <NextActionLink to='/integrations'>
                {t('onboarding.complete_section.install_integration')}
              </NextActionLink>
              <NextActionLink to='/numbers/purchase' state={{ from: '/onboarding' }}>
                {t('onboarding.complete_section.another_number')}
              </NextActionLink>
              <NextActionLink to='/users'>
                {t('onboarding.complete_section.another_user')}
              </NextActionLink>
            </Spacer>
          </Box>
        </Spacer>
      </Flex>
    </OnboardingCompleteContainer>
  );
}
