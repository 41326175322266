import { gql } from '@apollo/client';

export const ENABLE_INTEGRATION_MUTATION = gql`
  mutation EnableIntegrationMutation(
    $applicationId: String!
    $input: EnableIntegrationMutationInput!
  ) {
    enableIntegration(applicationId: $applicationId, input: $input)
      @rest(
        type: "EnableIntegrationResponse"
        path: "applications/{args.applicationId}/integration/enable"
        method: "POST"
        endpoint: "merlin"
      ) {
      integrationId
    }
  }
`;
