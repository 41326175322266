import { createContext, useMemo } from 'react';

import { FiltersContextProps, FiltersProviderProps } from './Looker.decl';

export const FiltersContext = createContext<FiltersContextProps>({} as FiltersContextProps);

export function FiltersProvider({
  children,
  dashboardType,
  maxDateRangeInDays,
  minDate,
  pageHeaderTitle,
  pageHeaderProps,
}: FiltersProviderProps) {
  const memoizedContextValue = useMemo(
    () => ({
      dashboardType,
      maxDateRangeInDays,
      minDate,
      pageHeaderTitle,
      pageHeaderProps: pageHeaderProps || {},
    }),
    [dashboardType, maxDateRangeInDays, minDate, pageHeaderProps, pageHeaderTitle]
  );

  return <FiltersContext.Provider value={memoizedContextValue}>{children}</FiltersContext.Provider>;
}
