import { PlusCircleFilled } from '@aircall/icons';
import { Button, ButtonProps } from '@aircall/tractor-v2';
import { useTranslation } from 'react-i18next';

export interface AddButtonProps extends Omit<ButtonProps, 'ref'> {
  hasData: boolean;
  translationKey: string;
}

/**  The button that is commonly used on the bottom of a list. */
export function AddButton({ hasData, translationKey, ...props }: AddButtonProps) {
  const { t } = useTranslation();
  return (
    <Button {...props} mode={hasData ? 'link' : 'outline'}>
      {!hasData && <PlusCircleFilled />}
      {`${hasData ? '+ ' : ''}${t(translationKey)}`}
    </Button>
  );
}
