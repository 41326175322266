import { useCallback } from 'react';

import { CloseCircleFilled, TickCircleOutlined, WarningFilled } from '@aircall/icons';
import { Icon, Skeleton, Tag, TagLabel, Tooltip } from '@aircall/tractor-v2';
import {
  Gap,
  NUMBER_DETAIL_ROUTE_TAB,
  useGraphQuery,
  useNavigateWithParamsReplace,
} from '@dashboard/library';
import {
  GetNumberRegistrationStatusQuery,
  GetNumberRegistrationStatusQueryVariables,
} from '@generated/GetNumberRegistrationStatusQuery';
import { NumberRegistrationStatus } from '@generated/globalTypes';
import { GET_NUMBER_REGISTRATION_STATUS } from '@graphql/queries/GetNumberRegistrationStatus';
import { NUMBER_DETAIL_TABS } from '@pages/number-detail/NumberDetail.decl';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export function RegistrationStatusTag() {
  const { numberId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();
  const { data, loading } = useGraphQuery<
    GetNumberRegistrationStatusQuery,
    GetNumberRegistrationStatusQueryVariables
  >(GET_NUMBER_REGISTRATION_STATUS, { variables: { lineID: numberId }, throwError: false });
  const registrationStatus = data?.getNumberRegistrationStatus?.numberRegistration?.status;
  const isRegistrationRejected = registrationStatus === NumberRegistrationStatus.rejected;
  const dataTest = `registration-${registrationStatus}-tag`;

  const handleClick = useCallback(() => {
    navigate(NUMBER_DETAIL_ROUTE_TAB, { numberId, tab: NUMBER_DETAIL_TABS.REGISTRATION_STATUS });
  }, [navigate, numberId]);

  if (!registrationStatus) {
    return loading ? <Skeleton data-test='registration-status-tag-loading' w={80} h={18} /> : null;
  }

  return (
    <Tooltip
      align='center'
      side='bottom'
      title={t(`number_details.registration_status.tag.${registrationStatus}.tooltip`)}
      maxW='100%'
    >
      {registrationStatus === NumberRegistrationStatus.approved ? (
        <Icon
          component={TickCircleOutlined}
          size={24}
          color='primary-700'
          onClick={handleClick}
          data-test={dataTest}
        />
      ) : (
        <Tag
          size='small'
          variant={isRegistrationRejected ? 'red' : 'yellow'}
          onClick={handleClick}
          data-test={dataTest}
        >
          <Gap gap='xxxs'>
            <Icon
              size={16}
              component={isRegistrationRejected ? CloseCircleFilled : WarningFilled}
              color={isRegistrationRejected ? 'critical-700' : 'warning-800'}
            />
            <TagLabel>
              {t(`number_details.registration_status.tag.${registrationStatus}.status`)}
            </TagLabel>
          </Gap>
        </Tag>
      )}
    </Tooltip>
  );
}
