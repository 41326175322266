/* eslint-disable @typescript-eslint/naming-convention */
export const AUTHORIZATION_ERROR_CODES = [403, 401];
export const NOT_FOUND_ERROR_CODES = [404];
export const VALIDATION_ERROR_CODES = [400, 409, 422];
export const LIMITATION_ERROR_CODES = [429];
export const GATEWAY_TIMEOUT_ERROR_CODES = [504];

export const GRAPHQL_ERROR_NON_EXISTENT_RESOURCE = 'Nonexistent resource';
export const GRAPHQL_ERROR_INTERNAL_API_NOT_FOUND = 'Request failed with status code 404';
export const GRAPHQL_ERROR_INVALID_DATA = 'Request failed with status code 422';

export const BACKEND_VALIDATION_KEY_MAPPER = {
  INVALID_VALUE: 'is_invalid',
  HAS_ALREADY_BEEN_TAKEN: 'has already been taken',

  // Billing details related keys
  WRONG_VAT_PATTERN: 'VAT country code does not match with the country',

  // Password related keys
  PASSWORD_EQUAL_TO_CURRENT_PASSWORD: 'equal_to_current_password',
  PASSWORD_WRONG_FORMAT: 'password_format',
  PASSWORD_TOO_SHORT: 'too_short',
  PASSWORD_TAKEN_IN_PAST: 'taken_in_past',
  EMAIL_ALREADY_EXISTS: 'email already exists',
  RESET_PASSWORD_TOKEN_IS_INVALID: 'invalid_code',
  TOO_MANY_REQUEST: 'too_many_requests',
  TEAM_NAME_ALREADY_USED_BY_THE_COMPANY: 'is already used by the company',
  MAX_NUMBER_OF_TEAMS_REACHED: 'number of teams allowed has been reached',
  INVALID_PASSWORD: 'Invalid password.',

  // Critical actions related keys
  UPDATE_EMAIL_BAD_REQUEST: 'email/bad-request',
  UPDATE_EMAIL_ALREADY_IN_USE: 'email/already-in-use',
  UPDATE_EMAIL_UNKNOWN: 'email/unknown',
  ADD_PAYMENT_CARD_UNKNOWN: 'card/unknown',
};

export const BACKEND_VALIDATION_ERRORS_TRANSLATION_KEYS = {
  [BACKEND_VALIDATION_KEY_MAPPER.INVALID_VALUE]: 'generic_errors.backend_validation.is_invalid',
  [BACKEND_VALIDATION_KEY_MAPPER.HAS_ALREADY_BEEN_TAKEN]:
    'generic_errors.backend_validation.has_already_been_taken',

  [BACKEND_VALIDATION_KEY_MAPPER.WRONG_VAT_PATTERN]:
    'generic_errors.frontend_validation.wrong_vat_pattern',

  [BACKEND_VALIDATION_KEY_MAPPER.PASSWORD_EQUAL_TO_CURRENT_PASSWORD]:
    'generic_errors.backend_validation.password_equal_to_current_password',
  [BACKEND_VALIDATION_KEY_MAPPER.PASSWORD_WRONG_FORMAT]:
    'generic_errors.backend_validation.password_strength',
  [BACKEND_VALIDATION_KEY_MAPPER.TOO_MANY_REQUEST]:
    'generic_errors.backend_validation.too_many_requests',
  [BACKEND_VALIDATION_KEY_MAPPER.INVALID_PASSWORD]:
    'generic_errors.backend_validation.password_strength',
  [BACKEND_VALIDATION_KEY_MAPPER.RESET_PASSWORD_TOKEN_IS_INVALID]:
    'generic_errors.backend_validation.token_invalid',
  [BACKEND_VALIDATION_KEY_MAPPER.MAX_NUMBER_OF_TEAMS_REACHED]:
    'generic_errors.backend_validation.max_number_of_teams_reached',
  [BACKEND_VALIDATION_KEY_MAPPER.PASSWORD_TOO_SHORT]:
    'generic_errors.backend_validation.password_length',
  [BACKEND_VALIDATION_KEY_MAPPER.PASSWORD_TAKEN_IN_PAST]:
    'generic_errors.backend_validation.password_taken_in_past',
  [BACKEND_VALIDATION_KEY_MAPPER.EMAIL_ALREADY_EXISTS]:
    'generic_errors.backend_validation.email_already_exists',
  [BACKEND_VALIDATION_KEY_MAPPER.TEAM_NAME_ALREADY_USED_BY_THE_COMPANY]:
    'generic_errors.backend_validation.has_already_been_taken',

  [BACKEND_VALIDATION_KEY_MAPPER.UPDATE_EMAIL_BAD_REQUEST]:
    'generic_errors.frontend_validation.invalid_email',
  [BACKEND_VALIDATION_KEY_MAPPER.UPDATE_EMAIL_ALREADY_IN_USE]:
    'generic_errors.backend_validation.email_already_exists',
  [BACKEND_VALIDATION_KEY_MAPPER.UPDATE_EMAIL_UNKNOWN]: 'generic_errors.other.unknown_error',
  [BACKEND_VALIDATION_KEY_MAPPER.ADD_PAYMENT_CARD_UNKNOWN]: 'credit_card.card_unknown_error',
};

export const BACKEND_ERRORS_TRANSLATION_KEYS = {
  INVALID_DATA_ERROR: 'generic_errors.backend_validation.is_invalid',
  UNKNOWN_ERROR: 'generic_errors.other.unknown_error',
  NETWORK_ERROR: 'generic_errors.other.network_error',
  GATEWAY_TIMEOUT_ERROR: 'generic_errors.other.gateway_timeout_error',
  WRONG_ERROR: 'generic_errors.other.wrong_error',
  TOO_MANY_REQUESTS: 'generic_errors.other.too_many_requests',
  REQUEST_TOO_EARLY: 'generic_errors.other.request_too_early',
  CREDENTIALS_ERROR: 'login.errors.credential',
  MFA_CREDENTIALS_ERROR: 'login.mfa.invalid_code',
  MFA_SESSION_EXPIRED: 'login.message.session_expired',
};

export const FRONTEND_VALIDATION_ERRORS_TRANSLATION_KEYS = {
  WRONG_FORMAT: 'generic_errors.frontend_validation.wrong_format',
  INVALID_VALUE: 'generic_errors.frontend_validation.invalid_value',
  PASSWORD_STRENGTH: 'generic_errors.frontend_validation.password_strength',
  PASSWORD_LENGTH: 'generic_errors.frontend_validation.password_length',
  PASSWORD_NOT_MATCH: 'generic_errors.frontend_validation.password_not_match',
  INVALID_EMAIL: 'generic_errors.frontend_validation.invalid_email',
  REQUIRED_FIELD: 'generic_errors.frontend_validation.required',
  MAX_LENGTH: 'generic_errors.frontend_validation.max_length',
  ALREADY_EXISTS: 'generic_errors.frontend_validation.already_exists',
  WRONG_PICTURE_FORMAT: 'generic_errors.frontend_validation.wrong_picture_format',
  PICTURE_SIZE_TOO_LARGE: 'generic_errors.frontend_validation.picture_size_too_large',
  TOO_SHORT: 'generic_errors.frontend_validation.too_short',
  NO_CREDIT_CARD: 'generic_errors.frontend_validation.no_credit_card',
  NO_ADDRESS: 'generic_errors.frontend_validation.no_address',
  INVALID_NUMBER_NAME: 'generic_errors.frontend_validation.invalid_number_name',
};

export const FRONTEND_VALIDATION_ERRORS_MAPPER_OF_DESCRIPTIVE_ERROR_TO_KEY_NAME = {
  'email already exists': 'email',
  'email/bad-request': 'email',
  'email/already-in-use': 'email',
  'email/unknown': 'email',
  'card/unknown': 'card',
  'VAT country code does not match with the country': 'VATWrongFormat',
};
