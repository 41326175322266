import { useCallback, useMemo } from 'react';

import type { UseGetEnableTrialAddonsFeatureFlagsReturn } from './useGetEnableTrialAddonsFeatureFlags.decl';

import { ADDONS } from '@constants/addons.constants';
import { useDashboardExtensionContext } from '@dashboard/extension';

export function useGetEnableTrialAddonsFeatureFlags(): UseGetEnableTrialAddonsFeatureFlagsReturn {
  const {
    featureFlags: {
      enableTrialAddonsForAdvancedMessaging,
      enableTrialAddonsForAircallAi,
      enableTrialAddonsForAircallSurveys,
      enableTrialAddonsForAnalyticsPlus,
    },
  } = useDashboardExtensionContext();

  const aircallAi = enableTrialAddonsForAircallAi;
  const analyticsPlus = enableTrialAddonsForAnalyticsPlus;
  const advancedMessaging = enableTrialAddonsForAdvancedMessaging;
  const aircallSurveys = enableTrialAddonsForAircallSurveys;

  const enabledTrialAddons = useMemo(() => {
    const enabledTrials = new Set<string>();

    if (aircallAi) {
      enabledTrials.add(ADDONS.AIRCALL_AI);
    }

    if (analyticsPlus) {
      enabledTrials.add(ADDONS.ANALYTICS_PLUS);
    }

    if (advancedMessaging) {
      enabledTrials.add(ADDONS.ADVANCED_MESSAGING);
    }

    if (aircallSurveys) {
      enabledTrials.add(ADDONS.AIRCALL_SURVEYS);
    }

    return enabledTrials;
  }, [aircallAi, analyticsPlus, advancedMessaging, aircallSurveys]);

  const isAddonEnabled = useCallback(
    (addonName?: ADDONS) => enabledTrialAddons.has(addonName ?? ''),
    [enabledTrialAddons]
  );

  const useGetEnableTrialAddonsFeatureFlagsReturn = useMemo(
    () => ({
      hasEnabledTrials: aircallAi || analyticsPlus || advancedMessaging || aircallSurveys,
      isAddonEnabled,
    }),
    [aircallAi, analyticsPlus, advancedMessaging, aircallSurveys, isAddonEnabled]
  );

  return useGetEnableTrialAddonsFeatureFlagsReturn;
}
