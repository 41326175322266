import { useReducer, useRef, useEffect } from 'react';

import {
  OptimisticProgressBarProps,
  ProgressAction,
  PROGRESS_ACTION_TYPE,
} from './OptimisticProgressBar.decl';

import { ProgressBar } from '@components/ProgressBar';

const PROGRESS_INCREASE_INTERVAL = 500;

function progressReducer(progress: number, action: ProgressAction) {
  const { type, value } = action;
  switch (type) {
    case PROGRESS_ACTION_TYPE.INCREASE: {
      const { capping, maxCapping, actualProgress } = action;
      const newProgress = Math.min(progress + value, actualProgress + capping);
      return Math.min(newProgress, maxCapping);
    }
    case PROGRESS_ACTION_TYPE.UPDATE:
      return progress > value ? progress : value;
    /* istanbul ignore next */
    default:
      return progress;
  }
}

/**
 * Progress bar that keeps progressing in an optimistic way
 * to give a feeling of things not being static
 */
export function OptimisticProgressBar({
  speed,
  capping,
  maxCapping,
  progress: actualProgress,
  ...rest
}: OptimisticProgressBarProps) {
  const [optimisticProgress, dispatch] = useReducer(progressReducer, actualProgress);
  const lastUpdate = useRef<number>(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const elapsedTime = (now - lastUpdate.current) / 1000;
      dispatch({
        type: PROGRESS_ACTION_TYPE.INCREASE,
        value: elapsedTime * speed,
        actualProgress,
        capping,
        maxCapping,
      });
      lastUpdate.current = now;
    }, PROGRESS_INCREASE_INTERVAL);
    dispatch({ type: PROGRESS_ACTION_TYPE.UPDATE, value: actualProgress });
    return () => clearInterval(interval);
  }, [actualProgress, dispatch, capping, speed, maxCapping]);

  return <ProgressBar progress={Math.round(optimisticProgress)} animated {...rest} />;
}
