import React, { useState, useLayoutEffect, useCallback } from 'react';

import { useResizeObserver } from '../useResizeObserver';

import { LayoutPositioner } from './useLayoutPosition.decl';

/**
 * Returns the bounding box of the element. It will update when the element resizes.
 * @param ref - Ref to the element
 */
export const useLayoutPosition = (ref: React.RefObject<HTMLElement>): LayoutPositioner => {
  const [boundingRect, setBoundingRect] = useState<DOMRect>();

  const updateLayoutPosition = useCallback(() => {
    const rect = ref.current?.getBoundingClientRect();
    setBoundingRect(rect);
  }, [ref]);

  // updates the position of the element when the layout is updated
  useLayoutEffect(() => {
    updateLayoutPosition();
  }, [updateLayoutPosition]);

  // avoid excessive layout calculations on resizing
  const onResize = useCallback(
    () => window.requestAnimationFrame(updateLayoutPosition),
    [updateLayoutPosition]
  );

  // updates the position of the element when resizing
  useResizeObserver({ ref, onResize });

  return {
    boundingRect,
    updateLayoutPosition,
  };
};
