import React from 'react';

import { FetchResult, MutationOptions, OperationVariables, useApolloClient } from '@apollo/client';

export type UseImperativeMutationReturn<TVariables, TData> = (
  variables: TVariables
) => Promise<FetchResult<TData>>;

/**
 * Hook providing a function to use a mutation with the imperative API.
 * It allows to manage returned states after the imperative call.
 * @param options - options for the mutation
 * @returns a function receiving variables related to the mutation to trigger a GraphQL mutation
 */
export function useImperativeMutation<TData, TVariables = OperationVariables>(
  options: MutationOptions<TData, TVariables>
): UseImperativeMutationReturn<TVariables, TData> {
  const client = useApolloClient();

  return React.useCallback(
    (variables) =>
      client.mutate<TData, TVariables>({
        ...options,
        variables,
      }),
    [client, options]
  );
}
