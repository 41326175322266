import { Banner, BannerHeading, BannerIcon, BannerSuffix, ToastButton } from '@aircall/tractor-v2';
import { getFullName } from '@dashboard/library';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useTranslation } from 'react-i18next';

export function ConnectedAsBanner() {
  const { t } = useTranslation();
  const { currentUser } = useGlobalData();
  const {
    actions: { signOut },
    authState: { isConnectedAs },
  } = useAuthenticationState();

  const name = getFullName(currentUser.firstName, currentUser.lastName);

  if (!isConnectedAs) {
    return null;
  }

  return (
    <Banner data-test='connected_as-banner' variant='warning'>
      <BannerIcon />
      <BannerHeading>{t('top_banner.message', { name })}</BannerHeading>
      <BannerSuffix>
        <ToastButton
          data-test='connected_as-signout-button'
          variant='secondary'
          mode='outline'
          onClick={signOut}
        >
          {t('top_banner.logout')}
        </ToastButton>
      </BannerSuffix>
    </Banner>
  );
}
