import { SyntheticEvent, useEffect } from 'react';

import { ReactComponent as Logo } from '../../assets/images/aircall_logo.svg';

import { ProgressCircle } from './ProgressCircle';
import { RenderProgressContent } from './RenderProgressContent';
import { TrialWidgetProps } from './TrialWidget.decl';
import {
  AppLinkButton,
  GreetingsWrapper,
  ToggleButton,
  WidgetContainer,
  WidgetWrapper,
} from './TrialWidget.style';

import { StarFilled } from '@aircall/icons';
import { Flex, Typography, Icon } from '@aircall/tractor-v2';
import { UpgradeLinkButton } from '@components/TrialChecklistPopup/TrialChecklistPopup.style';
import { ACCOUNT_ROUTE, ACCOUNT_TABS } from '@dashboard/library';
import { useOnboardingData } from '@hooks/useOnboardingData/useOnboardingData';
import { useTracker } from '@hooks/useTracker';
import { useTranslation } from 'react-i18next';

export function TrialWidget({
  userName,
  completedTask,
  totalTask,
  isWidgetOpen,
  openWidget,
  phoneAppUrl,
  ctaButtonUrl,
  ctaButtonTitle,
}: Readonly<TrialWidgetProps>) {
  const { t } = useTranslation();
  const { track } = useTracker();
  const { refetch } = useOnboardingData();
  const isTotalTaskComplete = completedTask === totalTask;
  const taskMessage = isTotalTaskComplete
    ? t('user_profile.task_checklist.completed_task')
    : `${t('user_profile.task_checklist.greeting')} ${userName}`;

  const handleWidgetButton = (event: SyntheticEvent<Element, Event>) => {
    event.preventDefault();
    openWidget(!isWidgetOpen);
  };

  const handleLinkButton = () => {
    track({
      event: 'external_link_opened',
      payload: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        link_destination_domain: phoneAppUrl,
      },
    });
  };

  useEffect(() => {
    const handleFocus = () => {
      refetch();
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [refetch]);

  return (
    <WidgetContainer as='article' data-test='trial-widget-container'>
      <WidgetWrapper
        direction='vertical'
        m='auto'
        minWidth={224}
        w='100%'
        padding='16px 12px'
        borderRadius={8}
      >
        <Flex alignItems='center' justifyContent='space-between' w='100%'>
          <GreetingsWrapper w='max-content' alignItems='center' justifyContent='center'>
            <Icon component={Logo} />
            <Typography
              data-test='trial-widget-greeting'
              variant='supportingSemiboldS'
              color='text-contrast-light'
              marginLeft={12}
            >
              {taskMessage}
            </Typography>
          </GreetingsWrapper>
          <ProgressCircle
            completedTask={completedTask}
            totalTask={totalTask}
            stroke='icon-primary'
            strokeWidth={5}
            circleRadius={15}
          >
            <RenderProgressContent
              isComplete={isTotalTaskComplete}
              completedTask={completedTask}
              totalTask={totalTask}
            />
          </ProgressCircle>
        </Flex>
        <ToggleButton
          data-test='trial-widget-button'
          onClick={handleWidgetButton}
          mode='fill'
          size='large'
          variant='primary'
        >
          <Typography variant='bodySemiboldM'>
            {t('user_profile.task_checklist.trial_todo')}
          </Typography>
        </ToggleButton>
        {completedTask === totalTask ? (
          <UpgradeLinkButton
            data-test='trial-checklist-plan-link'
            variant='warning'
            block
            as='a'
            href={ctaButtonUrl ?? `${ACCOUNT_ROUTE}/${ACCOUNT_TABS.YOUR_PLAN}`}
          >
            <Icon component={StarFilled} color='warning-500' />
            <Typography color='warning-500' variant='bodySemiboldM'>
              {ctaButtonTitle ?? t('user_profile.task_checklist.upgrade')}
            </Typography>
          </UpgradeLinkButton>
        ) : (
          <AppLinkButton
            as='a'
            data-test='trial-widget-link'
            href={phoneAppUrl}
            onClick={handleLinkButton}
            target='_blank'
            mode='outline'
            size='large'
            variant='primary'
          >
            <Typography variant='bodySemiboldM'>
              {t('user_profile.task_checklist.phone_app')}
            </Typography>
          </AppLinkButton>
        )}
      </WidgetWrapper>
    </WidgetContainer>
  );
}
