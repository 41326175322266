import { COMPANY_FRAGMENT } from '../fragments/CompanyFragment';

import { gql } from '@apollo/client';

export const CURRENT_COMPANY_QUERY = gql`
  query CurrentCompanyQuery {
    currentCompany @rest(type: "RestCompany", path: "company", endpoint: "v3") {
      ...CompanyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;
