import React, { useEffect } from 'react';

import { DiscoverMoreSection } from './DiscoverMoreSection';
import { OnboardingCard, OnboardingCardProps } from './OnboardingCard';
import { OnboardingCelebration } from './OnboardingCelebration';
import { ResourcesSectionV2 } from './ResourcesSectionV2';

import { Flex, Typography } from '@aircall/tractor-v2';
import phoneImage from '@assets/images/phone.png';
import trialTaskImage from '@assets/images/trial_task.png';
import { useOnboardingData } from '@hooks/useOnboardingData';
import { useTranslation } from 'react-i18next';

export function OnboardingContentSectionV2() {
  const { t } = useTranslation();
  const { allStepsCompleted, showConfetti, updateConfettiShown } = useOnboardingData();

  const trialCardProps: OnboardingCardProps = {
    heading: t('onboarding.started_section.app_card.heading'),
    subHeading: t('onboarding.started_section.app_card.sub_heading'),
    image: phoneImage,
    button: {
      label: t('onboarding.started_section.app_card.button_title'),
      onClick: () => window.open(t('onboarding.application.download_link_web_app')),
    },
  };

  const phoneCardProps: OnboardingCardProps = {
    heading: t('onboarding.started_section.trial_card.heading'),
    subHeading: t('onboarding.started_section.trial_card.sub_heading'),
    image: trialTaskImage,
    showBottomImage: true,
  };

  useEffect(() => {
    if (showConfetti) {
      updateConfettiShown(true);
    }
  }, [showConfetti, updateConfettiShown]);

  return (
    <Flex
      flexDirection={{
        _: 'column',
        lg: 'column',
        xl: 'row',
      }}
      px='l'
      py='m'
      pb='xl'
      my='0'
      mr='auto'
      maxW='1136px'
      as='section'
      gap='m'
    >
      <Flex
        flexDirection='column'
        w={{
          _: '100%',
          lg: '100%',
          xl: '70%',
        }}
      >
        <Flex flexDirection='column' position='relative'>
          <Typography data-test='onboarding-heading' variant='headingBoldS'>
            {t('onboarding.heading')}
          </Typography>
          <Typography data-test='onboarding-subheading' mt='15px' mb='20px' variant='bodyRegularM'>
            {t('onboarding.sub_heading')}
          </Typography>
          <Typography variant='headingBoldS'>{t('onboarding.started_section.heading')}</Typography>
          <Flex
            flexDirection={{
              xl: 'row',
              lg: 'row',
              _: 'column',
            }}
            w='100%'
            mt='m'
            justifyContent='space-between'
            gap={{ xl: 'auto', lg: 'auto', md: 's', sm: 's', xs: 's' }}
          >
            <OnboardingCard {...phoneCardProps} />
            <OnboardingCard {...trialCardProps} />
          </Flex>

          {allStepsCompleted ? <OnboardingCelebration includeConfetti={showConfetti} /> : undefined}
        </Flex>

        <DiscoverMoreSection />
      </Flex>
      <Flex
        w={{
          _: '100%',
          lg: '100%',
          xl: '30%',
        }}
      >
        <ResourcesSectionV2 />
      </Flex>
    </Flex>
  );
}
