import { gql } from '@apollo/client';

export const SIGNUP_INFORMATION_QUERY = gql`
  query GetSignupInformation {
    getSignupInformation {
      country
      phoneNumber
    }
  }
`;
