import styled from 'styled-components';

import { DropzoneAreaProps, DropzoneProps } from './Dropzone.decl';
import { makeDropzoneColor } from './utils';

import { Banner, BannerIcon, BannerHeading, Flex, Spacer, getColor } from '@aircall/tractor-v2';
import { useDropzone } from 'react-dropzone';

const DropzoneArea = styled(Flex)<DropzoneAreaProps>`
  min-height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ variant }) => (variant === 'secondary' ? '0 0 8px 8px' : '8px')};
  background-color: ${getColor('surface-default')};
  cursor: pointer;

  ${makeDropzoneColor}

  &:hover {
    border: 1px solid ${getColor('neutral-800')};
    border-top: ${({ variant }) => (variant === 'secondary' ? 'none' : undefined)};
  }
`;

export function Dropzone({
  dropzoneOptions,
  children,
  errorText,
  customError,
  variant = 'primary',
  'data-test': dataTest = 'dropzone',
  ...flexProps
}: DropzoneProps) {
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone(dropzoneOptions);

  const dropRootProps = getRootProps({ isDragActive });

  const rejectionError =
    fileRejections.length > 0 ? errorText || fileRejections[0].errors[0].message : undefined;

  // If there are custom errors and not fileRejection error, display the custom error
  const displayedError = rejectionError ?? customError;

  return (
    <Spacer direction='vertical' space='xxs' fluid>
      <DropzoneArea
        data-test={dataTest}
        py='l'
        px='l'
        {...dropRootProps}
        {...flexProps}
        variant={variant}
      >
        <input {...getInputProps()} data-test={`${dataTest}-input`} />
        {children}
      </DropzoneArea>
      {displayedError && (
        <Banner variant='critical' inline data-test={`${dataTest}-error-banner`}>
          <BannerIcon />
          <BannerHeading>{displayedError}</BannerHeading>
        </Banner>
      )}
    </Spacer>
  );
}
