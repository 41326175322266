import { createContext, useMemo } from 'react';

import { FeaturesFlagsContextType, FeaturesFlagsProviderProps } from './FeaturesFlagsProvider.decl';

import { Loading } from '@dashboard/library';
import { FeaturesFlagsQuery } from '@generated/FeaturesFlagsQuery';
import { FEATURES_FLAGS_QUERY } from '@graphql/queries/FeaturesFlagsQuery';
import { useGraphQuery } from '@hooks/useQuery';

export const FeaturesFlagsContext = createContext<FeaturesFlagsContextType>(
  {} as FeaturesFlagsContextType
);

export function FeaturesFlagsProvider({ children }: FeaturesFlagsProviderProps) {
  const { data } = useGraphQuery<FeaturesFlagsQuery>(FEATURES_FLAGS_QUERY, {
    throwError: true,
  });

  const featureFlags = useMemo(
    () =>
      data
        ? Object.entries(data.featureFlags).reduce(
            (acc, [ffKey, ffValue]) => ({
              ...acc,
              [ffKey]: !!ffValue,
            }),
            {} as FeaturesFlagsContextType
          )
        : undefined,
    [data]
  );

  if (!featureFlags) {
    return <Loading data-test='features-flags-loading' />;
  }

  return (
    <FeaturesFlagsContext.Provider value={featureFlags}>{children}</FeaturesFlagsContext.Provider>
  );
}
