import { useCallback } from 'react';

import { DayProps, DaysPickerProps } from './DaysPicker.decl';

import { Flex, Spacer, Typography } from '@aircall/tractor-v2';
import { DayOfWeek } from '@generated/globalTypes';
import styled, { css } from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';

const Circle = styled(Flex)<Pick<DayProps, 'isActive'>>`
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${({ isActive }) =>
    css`
      background-color: ${isActive ? 'primary-500' : 'neutral-400'};
    `};
  color: text-inverted;
  cursor: pointer;
  user-select: none;
`;

function Day({ dayOfWeek, isActive, onClick }: DayProps) {
  const { t } = useTranslation();

  return (
    <Circle
      isActive={isActive}
      onClick={() => onClick(dayOfWeek)}
      data-test={`day-of-week-${dayOfWeek}`}
    >
      <Typography variant='bodyMediumS' top={2}>
        {t(`days_short.${dayOfWeek}`)}
      </Typography>
    </Circle>
  );
}

export function DaysPicker({ selectedDays, onChange, ...otherProps }: DaysPickerProps) {
  const handleClickDay = useCallback(
    (dayOfWeek: DayOfWeek) => {
      const newSelectedDays = selectedDays.includes(dayOfWeek)
        ? selectedDays.filter((d) => d !== dayOfWeek) // Remove day from state
        : [...selectedDays, dayOfWeek]; // Add day to state

      onChange?.(newSelectedDays);
    },
    [onChange, selectedDays]
  );

  const days = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
    DayOfWeek.SATURDAY,
    DayOfWeek.SUNDAY,
  ].map((dayOfWeek) => (
    <Day
      key={dayOfWeek}
      dayOfWeek={dayOfWeek}
      onClick={handleClickDay}
      isActive={selectedDays.includes(dayOfWeek)}
    />
  ));

  return (
    <Spacer space='xxs' {...otherProps}>
      {days}
    </Spacer>
  );
}
