import { gql } from '@apollo/client';

export const HUB_INTEGRATION_INSTALL_INFORMATION_QUERY = gql`
  query HubIntegrationInstallInformationQuery(
    $companyId: Int!
    $integrationId: Int!
    $language: String!
  ) {
    getHubIntegrationInstallInformation(
      companyId: $companyId
      integrationId: $integrationId
      language: $language
    )
      @rest(
        type: "HubIntegrationInstallInformation"
        path: "hub/companies/{args.companyId}/applications/{args.integrationId}?language={args.language}"
        endpoint: "v3"
      ) {
      id
      name
      logo
      comingSoon
      deleted
      description
      subtitle
      source
      traySolutionId
      installUri
      learnMoreUri
    }
  }
`;
