import { gql } from '@apollo/client';

export const UPDATE_DISPATCH_GROUP_MUTATION = gql`
  mutation UpdateDispatchGroupMutation(
    $numberId: String!
    $dispatchGroupId: String!
    $input: UpdateDispatchGroupInput!
  ) {
    updateDispatchGroup(numberId: $numberId, dispatchGroupId: $dispatchGroupId, input: $input)
      @rest(
        endpoint: "v4UpdateDispatchGroup"
        path: "numbers/{args.numberId}/dispatch_groups/{args.dispatchGroupId}"
        type: "DispatchGroup"
        method: "PATCH"
      ) {
      id
      priority
      queuingTimeout
      redirectionStrategy
    }
  }
`;
