import { useEffect } from 'react';

import { INTEGRATION_FULL_ROUTES } from '@constants/routes.constants';
import { Loading } from '@dashboard/library';
import {
  EnableIntegrationMutation,
  EnableIntegrationMutationVariables,
} from '@generated/EnableIntegrationMutation';
import {
  StartWorkatoRecipeMutation,
  StartWorkatoRecipeMutationVariables,
} from '@generated/StartWorkatoRecipeMutation';
import { ENABLE_INTEGRATION_MUTATION } from '@graphql/mutations/EnableIntegrationMutation';
import { START_WORKATO_RECIPE_MUTATION } from '@graphql/mutations/StartWorkatoRecipeMutation';
import { useGraphMutation, useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useParams } from 'react-router-dom';

interface IntegrationFlowCreateWorkatoProps {
  solutionInstanceId?: string;
}

export function IntegrationFlowCreateWorkato({
  solutionInstanceId,
}: IntegrationFlowCreateWorkatoProps) {
  const { applicationType, applicationName, applicationId } = useParams();
  const navigate = useNavigateWithParamsReplace();
  const [mutate, { data: workatoIntegrationData }] = useGraphMutationWithFeedback<
    EnableIntegrationMutation,
    EnableIntegrationMutationVariables
  >(ENABLE_INTEGRATION_MUTATION, { type: 'snackbar' });

  const [mutateStartRecipe] = useGraphMutation<
    StartWorkatoRecipeMutation,
    StartWorkatoRecipeMutationVariables
  >(START_WORKATO_RECIPE_MUTATION, {
    onCompleted: () => {
      mutate({
        variables: {
          applicationId,
          input: {
            solutionInstanceId: solutionInstanceId!,
          },
        },
      });
    },
  });

  // Redirect to complete page
  useEffect(() => {
    const integrationId = workatoIntegrationData?.enableIntegration?.integrationId;

    if (integrationId) {
      navigate(
        `${INTEGRATION_FULL_ROUTES.FLOW_COMPLETE}?integration_id=${integrationId}`,
        {
          applicationName,
          applicationType,
          applicationId,
        },
        { replace: true }
      );
    }
  }, [workatoIntegrationData, navigate, applicationName, applicationType, applicationId]);

  useEffect(
    () => {
      mutateStartRecipe({
        variables: {
          solutionInstanceId: solutionInstanceId!,
        },
      });
    },
    [] // eslint-disable-line
  );

  return <Loading data-test='create-workato-loading' />;
}
