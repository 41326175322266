import { useMusicOnHoldAudioSettings } from '../hooks/useMusicOnHoldAudioSettings';
import { useUpdateMusicOnHold } from '../hooks/useUpdateMusicOnHold';

import { AudioSettingFileField } from './components/AudioSettingFileField';
import { AudioSettingLibraryField } from './components/AudioSettingLibraryField';
import { AudioTypeSelectField } from './components/AudioTypeSelectField';
import { MusicOnHoldFormValues, MUSIC_ON_HOLD_AUDIO_TYPE } from './MusicOnHoldSettings.decl';
import { validateMusicOnHoldFormValues } from './MusicOnHoldSettings.helpers';

import { Box, Spacer, Typography } from '@aircall/tractor-v2';
import { Loading, Paper, PaperForm, GridItem, GridLayout } from '@dashboard/library';
import { useTranslation } from 'react-i18next';

const TRANS_PREFIX = 'number_details.settings.music_on_hold_section';

export function MusicOnHoldSettings() {
  const { t } = useTranslation();
  const { audioSettings, updateAudioSetting } = useMusicOnHoldAudioSettings();
  const { updateMusicOnHold } = useUpdateMusicOnHold({
    title: t(`${TRANS_PREFIX}.title`),
    onComplete: () => {
      updateAudioSetting();
    },
  });

  if (!audioSettings) {
    return (
      <Paper title={t(`${TRANS_PREFIX}.title`)} fluid data-test='music-on-hold-settings-loading'>
        <Loading />
      </Paper>
    );
  }

  const audioTypeOptions = [
    {
      value: MUSIC_ON_HOLD_AUDIO_TYPE.LIBRARY,
      label: t(`${TRANS_PREFIX}.select_audio_type.library.label`),
    },
    {
      value: MUSIC_ON_HOLD_AUDIO_TYPE.FILE,
      label: t(`${TRANS_PREFIX}.select_audio_type.file.label`),
    },
  ];

  return (
    <PaperForm<MusicOnHoldFormValues>
      data-test='music-on-hold-settings-form'
      shouldBlockNavigation
      formProps={{
        onSubmit: updateMusicOnHold,
        initialValues: { audioSetting: audioSettings },
        validate: validateMusicOnHoldFormValues,
      }}
      title={t(`${TRANS_PREFIX}.title`)}
    >
      {({ values: { audioSetting } }) => (
        <Spacer direction='vertical' space='s'>
          <Typography>{t(`${TRANS_PREFIX}.intro`)}</Typography>
          <Box data-test='music-on-hold-settings-audio-type-container'>
            <GridLayout alignItems='center' rowGap='8px'>
              <GridItem xs={6} gridRow={2}>
                <AudioTypeSelectField
                  name='audioSetting.type'
                  label={t(`${TRANS_PREFIX}.select_audio_type.title`)}
                  options={audioTypeOptions}
                />
              </GridItem>
            </GridLayout>
          </Box>
          <Box data-test='music-on-hold-settings-audio-file-container'>
            {audioSetting.type === MUSIC_ON_HOLD_AUDIO_TYPE.LIBRARY && (
              <AudioSettingLibraryField name='audioSetting.url' />
            )}
            {audioSetting.type === MUSIC_ON_HOLD_AUDIO_TYPE.FILE && (
              <AudioSettingFileField name='audioSetting.file' />
            )}
          </Box>
        </Spacer>
      )}
    </PaperForm>
  );
}
