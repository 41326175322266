import { useCallback } from 'react';

import { TimeSlotField } from './TimeSlotField';
import { TimeSlotsFieldProps } from './TimeSlotsField.decl';

import { FormItem } from '@aircall/tractor-v2';
import { List, ListItem, AddButton } from '@dashboard/library';
import { DayOfWeek } from '@generated/globalTypes';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

export function TimeSlotsField({ slots }: TimeSlotsFieldProps) {
  const { t } = useTranslation();
  const form = useForm();

  const handleAdd = useCallback(() => {
    form.mutators.push('slots', {
      __typename: 'AvailabilitySlot',
      days: [
        DayOfWeek.MONDAY,
        DayOfWeek.TUESDAY,
        DayOfWeek.WEDNESDAY,
        DayOfWeek.THURSDAY,
        DayOfWeek.FRIDAY,
      ],
      from: 540,
      to: 1080,
    });
  }, [form]);

  function handleRemove(index: number) {
    form.mutators.remove('slots', index);
  }

  return (
    <>
      <FieldArray name='slots'>
        {({ fields }) => (
          <FormItem label={t('timeslots_form.timeslots_label')}>
            <List data-test='timeslots' hasScroll={false}>
              {fields.map((field, i) => (
                <ListItem key={field} h='auto'>
                  <TimeSlotField
                    hasIcon={fields.length !== 1}
                    name={field}
                    {...slots[i]}
                    onRemove={() => handleRemove(i)}
                    data-test={`timeslot-${i + 1}`}
                  />
                </ListItem>
              ))}
            </List>
          </FormItem>
        )}
      </FieldArray>
      <AddButton data-test='timeslots-add-button' onClick={handleAdd}>
        {t('timeslots_form.timeslots.add_new')}
      </AddButton>
    </>
  );
}
