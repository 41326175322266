import { gql } from '@apollo/client';

export const TRACKS_LIST_QUERY = gql`
  query TracksListQuery {
    listTracks @rest(type: "Track", path: "numbers/tracks", endpoint: "v4") {
      data {
        id
        type
        attributes {
          id
          name
          url
        }
      }
    }
  }
`;
