import { DiagramCard } from '../FlowDiagram';

import { Grid, Toggle, Tooltip, Typography } from '@aircall/tractor-v2';

interface DiagramToggleCardProps {
  onChange: () => void;
  title: string;
  tooltip: string;
  toggleValue: boolean;
  'data-test'?: string;
  mt?: string;
}

export function DiagramToggleCard({
  onChange,
  title,
  tooltip,
  toggleValue,
  'data-test': dataTest = 'diagram-toggle',
  mt,
}: DiagramToggleCardProps) {
  return (
    <DiagramCard mt={mt}>
      <Grid columnGap='s' gridTemplateColumns='auto 1fr' alignItems='center' p='xs'>
        <Toggle data-test={dataTest} checked={toggleValue} onChange={onChange} size='small' />
        <Tooltip title={tooltip} position='bottom'>
          {/* The padding are used to increase the hoverable area  */}
          <Typography cursor='pointer' pl='6px' py='2px'>
            {title}
          </Typography>
        </Tooltip>
      </Grid>
    </DiagramCard>
  );
}
