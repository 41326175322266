import { gql } from '@apollo/client';

export const GET_LINE_BY_UUID = gql`
  query GetLineByUUID($numberUUID: String!) {
    getLineByUUID(numberUUID: $numberUUID) {
      ID
      countryISOCode
      verificationState
    }
  }
`;
