import { AddOption } from '../AddIvrSettingSection/AddOption';
import { TRANSLATION_PREFIX } from '../config';
import { DiagramBranch } from '../FlowDiagram';
import { IvrSettingCard } from '../IvrSettingCard/IvrSettingCard';

import { NumberDetailQuery_numberDetail_ivrSettings } from '@generated/NumberDetailQuery';
import { useTranslation } from 'react-i18next';

const fallbackOption = [
  {
    value: 'fallback',
    label: 'fallback',
  },
];

interface FallbackIvrSectionProps {
  countryCode: string;
  ivrFallback?: NumberDetailQuery_numberDetail_ivrSettings;
}

export function FallbackIvrSection({ countryCode, ivrFallback }: FallbackIvrSectionProps) {
  const { t } = useTranslation();

  return (
    <>
      {ivrFallback ? (
        <IvrSettingCard
          data-test='ivr-fallback-number'
          item={ivrFallback}
          countryCode={countryCode}
          availableKeyOptions={fallbackOption}
        />
      ) : (
        <DiagramBranch>
          <AddOption
            keyOptions={fallbackOption}
            text={t(`${TRANSLATION_PREFIX}.fallback_ivr_section.add_fallback`)}
          />
        </DiagramBranch>
      )}
    </>
  );
}
