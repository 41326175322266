import { MusicOnHoldFormValues } from '../MusicOnHoldSettings/MusicOnHoldSettings.decl';
import { getMusicOnHoldAudioFileTrackingType } from '../MusicOnHoldSettings/MusicOnHoldSettings.helpers';

import { useCreateMusicOnHoldUrl } from './useCreateMusicOnHoldUrl';

import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import {
  UpdateNumberMutation,
  UpdateNumberMutationVariables,
} from '@generated/UpdateNumberMutation';
import { UPDATE_NUMBER_MUTATION } from '@graphql/mutations/UpdateNumberMutation';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useTracker } from '@hooks/useTracker/useTracker';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

interface UseUpdateMusicOnHoldType {
  updateMusicOnHold: (values: MusicOnHoldFormValues) => Promise<void>;
}

interface UseUpdateMusicOnHoldProps {
  title: string;
  onComplete?: () => void;
}

export function useUpdateMusicOnHold({
  title,
  onComplete,
}: UseUpdateMusicOnHoldProps): UseUpdateMusicOnHoldType {
  const { numberId } = useParams();
  const { track } = useTracker();
  const { t } = useTranslation();
  const { generateUrl } = useCreateMusicOnHoldUrl(numberId);

  const [mutate] = useGraphMutationWithFeedback<
    UpdateNumberMutation,
    UpdateNumberMutationVariables
  >(
    UPDATE_NUMBER_MUTATION,
    {
      type: 'form_snackbar',
    },
    {
      onCompleted: (data: UpdateNumberMutation) => {
        track({
          event: 'audio_settings_confirmed',
          payload: {
            type: getMusicOnHoldAudioFileTrackingType(data.updateNumber.musicOnHold),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            number_id: numberId,
            setting: 'musicOnHold',
          },
        });
        onComplete?.();
      },
    }
  );

  const updateMusicOnHold = async (values: MusicOnHoldFormValues) => {
    const url = await generateUrl(values.audioSetting);
    mutate(
      {
        variables: {
          input: {
            data: {
              id: parseInt(numberId),
              type: 'number',
              attributes: {
                musicOnHold: url,
              },
            },
          },
          numberId: parseInt(numberId),
        },
      },
      {
        successMessage:
          title &&
          t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
            name: title,
          }),
      }
    );
  };

  return {
    updateMusicOnHold,
  };
}
