import { gql } from '@apollo/client';
import { USER_FULL_FRAGMENT } from '@graphql/fragments/UserFragment';

export const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUserQuery {
    getAgentV2 {
      ...UserFullFragment
    }
  }
  ${USER_FULL_FRAGMENT}
`;
