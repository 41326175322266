import { AnalyticsDashboard } from '@components/AnalyticsDashboard/AnalyticsDashboard';
import { AnalyticsDashboardType } from '@generated/globalTypes';
import { ANALYTICS_LIMITS } from '@helpers/analytics.helpers';
import { useTranslation } from 'react-i18next';

export function StatsUnansweredCallsProPage() {
  const { t } = useTranslation();

  return (
    <AnalyticsDashboard
      feedbackButtonEvent='unanswered_calls_pro_feedback_button_clicked'
      minDate={ANALYTICS_LIMITS.sixMonthDate}
      pageHeaderTitle={t('stats.tabs.unanswered_calls_pro')}
      title='unanswered_inbound_calls_pro'
      type={AnalyticsDashboardType.UNANSWERED_INBOUND_CALL_PRO}
    />
  );
}
