import { TRANSLATION_PREFIX } from '../config';

import { AddUserModalProps, AddUserFormValues } from './AddDispatchGroupSection.decl';

import { SearchableSelectUserOption } from '@components/SearchableSelectUserItem';
import { SearchableSelectUserItem } from '@components/SearchableSelectUserItem/SearchableSelectUserItem';
import { SearchAddModalForm } from '@components/SearchAddModalForm';
import { getFullName } from '@dashboard/library';
import { useAddDispatchGroup, DISPATCH_GROUP_TYPE } from '@hooks/useAddDispatchGroup';
import { useSearchableSelectUser } from '@hooks/useSearchableSelectUser/useSearchableSelectUser';
import { useTranslation } from 'react-i18next';

export function AddUserModal({
  isOpen,
  onClose,
  numberId,
  priority,
  idsBlacklist,
}: AddUserModalProps) {
  const { t } = useTranslation();

  const { addDispatchGroup } = useAddDispatchGroup({
    numberId,
  });

  const { agents, options, setQuery, loading, resultsCount } = useSearchableSelectUser({
    filteredIds: idsBlacklist,
    isSelectMounted: isOpen,
  });

  const onSubmit = (values: AddUserFormValues) => {
    const agent = agents.find((a) => a.ID === values.userId)!;

    addDispatchGroup({
      user: agent,
      attributes: { priority },
      type: DISPATCH_GROUP_TYPE.USER,
      successMessageName: getFullName(agent.firstName, agent.lastName),
    });
    onClose();
  };

  return (
    <SearchAddModalForm<AddUserFormValues, SearchableSelectUserOption>
      isOpen={isOpen}
      label={t(`${TRANSLATION_PREFIX}.add_dispatch_group_section.user_modal.select_label`)}
      onClose={onClose}
      onSubmit={onSubmit}
      onSearch={setQuery}
      options={options}
      renderItem={(o) => <SearchableSelectUserItem {...o} />}
      loading={loading}
      name='userId'
      placeholder={t('app.roles.user')}
      titleText={t(`${TRANSLATION_PREFIX}.add_dispatch_group_section.add_user`)}
      submitButtonText={t(`${TRANSLATION_PREFIX}.add_dispatch_group_section.modal.submit`)}
      selectName='user-select'
      resultsCount={resultsCount}
    />
  );
}
