/* eslint-disable @typescript-eslint/no-explicit-any */
export function removeObjectNullValues(
  originalObject: Record<string, any> | undefined
): Record<string, any> {
  const newobj: Record<string, any> = {};
  if (!originalObject) {
    return newobj;
  }
  Object.keys(originalObject).forEach((key) => {
    if (originalObject[key] !== null) {
      newobj[key] = originalObject[key];
    }
  });

  return newobj;
}

export function safeJsonParse<T>(str: string): T | null {
  try {
    const jsonValue: T = JSON.parse(str);

    return jsonValue;
  } catch {
    return null;
  }
}
