import { useCallback } from 'react';

import { Button, ButtonProps } from '@aircall/tractor-v2';
import { ACCOUNT_ROUTE_TAB } from '@constants/routes.constants';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useTranslation } from 'react-i18next';

export function UpgradePlanButton(props: Omit<ButtonProps, 'ref'>) {
  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();

  const onClick = useCallback(() => {
    navigate(ACCOUNT_ROUTE_TAB, { tab: 'plan' });
  }, [navigate]);

  return (
    <Button data-test='upgrade-plan-button' onClick={onClick} {...props}>
      {t('upgrade_plan.button')}
    </Button>
  );
}
