import { gql } from '@apollo/client';

export const DELETE_OUTBOUND_CALLER_MUTATION = gql`
  mutation DeleteOutboundCallerMutation($numberId: String!, $supervisionId: String!) {
    deleteOutboundCaller(numberId: $numberId, supervisionId: $supervisionId)
      @rest(
        type: "Void"
        path: "numbers/{args.numberId}/supervisions/{args.supervisionId}"
        method: "DELETE"
        endpoint: "v4"
      ) {
      _
    }
  }
`;
