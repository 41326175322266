import packageJson from '../../../package.json';

import { Box, Typography } from '@aircall/tractor-v2';
import { COMMIT_SHA } from '@constants/environment.constants';

export function CheckHealth() {
  return (
    <Box>
      <Typography>version: {packageJson.version}</Typography>
      <Typography>last commit: {COMMIT_SHA}</Typography>
    </Box>
  );
}
