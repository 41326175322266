import React from 'react';

import { BarProps, ProgressBarProps } from './ProgressBar.decl';

import { Box, getColor } from '@aircall/tractor-v2';
import styled from '@xstyled/styled-components';

const Bar = styled.div.attrs<BarProps>(({ progress }) => ({
  style: {
    width: `${progress ?? '100'}%`,
  },
}))<BarProps>`
  position: absolute;
  height: ${({ barHeight }) => barHeight}px;
  border-radius: ${({ barHeight }) => Math.round(barHeight / 2)}px;
  background-color: ${({ color }) => getColor(color ?? 'neutral-200')};
  ${({ animated }) => animated && 'transition: width 0.25s'}
`;

export const ProgressBar = React.forwardRef<HTMLDivElement, ProgressBarProps>(
  (
    {
      progress,
      disabled,
      height = 2,
      animated = false,
      color = 'success-300',
      ...rest
    }: ProgressBarProps,
    ref
  ) => (
    <Box
      {...rest}
      ref={ref}
      position='relative'
      data-test='progress-bar'
      borderRadius={height / 2}
      h={`${height}px`}
      overflow='hidden'
    >
      <Bar animated={false} barHeight={height} />
      <Bar
        progress={progress}
        color={disabled ? 'neutral-400' : color}
        data-test='progress-bar-gauge'
        animated={animated}
        barHeight={height}
      />
    </Box>
  )
);
