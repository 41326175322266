import React, { forwardRef, FunctionComponent, useMemo } from 'react';

import styled from 'styled-components';

import { Overlay } from '../Overlay/Overlay';

import { MenuPosition, RecentSearchProps } from './SearchBar.decl';
import { SearchMenuItem } from './SearchMenuItem';

import {
  getRadii,
  getColor,
  Typography,
  Box,
  Button,
  Grid,
  Menu,
  GridProps,
  getSpace,
  ButtonWithChildren,
} from '@aircall/tractor-v2';
import { motion, MotionProps, Variants, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export const DEFAULT_SEARCH_DELAY = 5000;

export const MenuHeader = styled(Grid)<GridProps>`
  align-items: center;
  padding: ${getSpace('xs')} ${getSpace('s')};
  grid-template-columns: auto min-content;
`;

export const MenuTitle = styled(Typography).attrs(() => {
  'headingLightM';
})`
  color: ${getColor('neutral-400')};
  text-transform: uppercase;
`;

const LinkButton = styled<ButtonWithChildren>(Button).attrs(() => ({
  mode: 'link',
}))`
  &,
  &:hover,
  &:focus {
    color: ${getColor('text-base')};
  }
`;

const MotionBox = styled<FunctionComponent<MotionProps & MenuPosition>>(motion.ul)`
  position: fixed;
  z-index: 1001;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  width: ${({ width }) => width}px;
  min-width: fit-content;
  height: max-content;
  padding: ${getSpace('xs')}px 0;
  border-radius: ${getRadii('sm')};
  margin-top: ${getSpace('xxs')}px;
  background: ${getColor('neutral-0')};
  box-shadow: 5;
  overflow-x: hidden;
`;

const AnimationVariants: Variants = {
  enter: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: -10,
    opacity: 0,
  },
};

export const RecentSearch = React.memo(
  forwardRef<HTMLDivElement, RecentSearchProps>(
    ({ width, top, left, open, recentSearches, onSelected, onClear }, ref) => {
      const { t } = useTranslation();
      const menuItems = useMemo(
        () =>
          recentSearches.map((searchStr) => (
            <SearchMenuItem
              width={width}
              key={searchStr}
              searchStr={searchStr}
              onSelected={onSelected}
            />
          )),
        [onSelected, recentSearches, width]
      );

      return (
        <AnimatePresence>
          {open && recentSearches.length > 0 && (
            <Overlay>
              {/* Tab index is added to prevent onBlur cannot gives us `relatedTarget` */}
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <div ref={ref} tabIndex={0} data-test='recent-search-menu'>
                <MotionBox
                  initial='exit'
                  animate='enter'
                  exit='exit'
                  width={width}
                  top={top}
                  left={left}
                  variants={AnimationVariants}
                >
                  <MenuHeader>
                    <Box>
                      <MenuTitle>{t('users.search.menu.title')}</MenuTitle>
                    </Box>
                    <Box>
                      <LinkButton data-test='clear-history-button' size='xSmall' onClick={onClear}>
                        {t('users.search.menu.clear_button')}
                      </LinkButton>
                    </Box>
                  </MenuHeader>
                  <Menu w='100%'>{menuItems}</Menu>
                </MotionBox>
              </div>
            </Overlay>
          )}
        </AnimatePresence>
      );
    }
  )
);
