import { Flex } from '@aircall/tractor-v2';
import { Loading } from '@dashboard/library';
import { useOauthLogin } from '@hooks/useOauthLogin/useOauthLogin';

export function OauthLoginPage() {
  useOauthLogin();

  return (
    <Flex justifyContent='center' alignItems='center' data-test='spinner-container'>
      <Loading />
    </Flex>
  );
}
