import { useMemo, useState } from 'react';

import { AppLayoutContext, initialFullScreen } from './AppLayoutContext';

/**
 * Global states related to the app layout, e.g., full-screen mode
 */
export function AppLayoutProvider({ children }: { children: React.ReactNode }) {
  const [fullScreen, toggleFullScreen] = useState(initialFullScreen);
  const memoizedContextValue = useMemo(() => ({ fullScreen, toggleFullScreen }), [fullScreen]);

  return (
    <AppLayoutContext.Provider value={memoizedContextValue}>{children}</AppLayoutContext.Provider>
  );
}
