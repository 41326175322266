import { gql } from '@apollo/client';

export const UPDATE_IVR_SETTING_MUTATION = gql`
  mutation UpdateIvrSettingRest(
    $numberId: String!
    $ivrSettingId: String!
    $input: IvrSettingInput!
  ) {
    updateIvrSettingRest(numberId: $numberId, ivrSettingId: $ivrSettingId, input: $input)
      @rest(
        type: "IvrSettingResponse"
        path: "numbers/{args.numberId}/ivr_settings/{args.ivrSettingId}"
        endpoint: "v3"
        method: "PUT"
      ) {
      id
      key
      numberId
      redirectingExternalNumber
      redirectingNumberId
    }
  }
`;
