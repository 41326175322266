import { useCallback } from 'react';

import { useInstallHubIntegration } from './hooks/useInstallHubIntegration';

import { ApplicationPageLayout } from '@components/ApplicationPageLayout/ApplicationPageLayout';
import { APPLICATIONS_SOURCES } from '@constants/integrations.constants';
import { INTEGRATION_FULL_ROUTES } from '@constants/routes.constants';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useParams } from 'react-router-dom';

export function ApplicationHubPage() {
  const { integration, loading } = useInstallHubIntegration();
  const { description = '', source, name = '', logo } = integration || {};
  const { applicationName, applicationType = 'hub', applicationId } = useParams();
  const navigate = useNavigateWithParamsReplace();

  const handleInstallButtonClick = useCallback(
    () =>
      navigate(INTEGRATION_FULL_ROUTES.FLOW_INSTALL, {
        applicationName,
        applicationType,
        applicationId,
      }),
    [applicationName, navigate, applicationType, applicationId]
  );

  return (
    <ApplicationPageLayout
      description={description}
      name={name}
      logo={logo}
      loading={loading}
      learnMoreUri={integration?.learnMoreUri}
      shouldShowInstallButton={source === APPLICATIONS_SOURCES.TRAY || !!integration?.installUri}
      onInstallButtonClick={handleInstallButtonClick}
    />
  );
}
