import { useCallback } from 'react';

import { useUpdateNumberForm } from '../hooks/useUpdateNumberForm';

import {
  Box,
  Typography,
  RadioGroup,
  Banner,
  BannerHeading,
  BannerIcon,
} from '@aircall/tractor-v2';
import { NUMBER_TYPE } from '@constants/numbers.constants';
import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import {
  FormField,
  GridLayout,
  GridItem,
  List,
  ListItem,
  PaperForm,
  RadioRow,
} from '@dashboard/library';
import { validateNumberName } from '@helpers/validators.helpers';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const TRANS_PREFIX = 'number_details.settings.general_settings_section';

export function NumberDetailSettingsGeneral() {
  const { updateNumberForm, number } = useUpdateNumberForm();
  const { t } = useTranslation();
  const { smartflowsEnabled } = useFeatures();

  const initialValues = {
    name: number!.name,
    numberType: number!.ivr ? NUMBER_TYPE.IVR : NUMBER_TYPE.CLASSIC,
  };

  const onSubmit = useCallback(
    ({ name, numberType }) =>
      updateNumberForm(
        {
          name,
          ivr: numberType === NUMBER_TYPE.IVR,
        },
        {
          successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
            name: number!.name,
          }),
        }
      ),
    [number, t, updateNumberForm]
  );

  const isSmartflowsNumber = smartflowsEnabled && number!.flowEditorEnabled;

  return (
    <PaperForm
      data-test='number-detail-settings-general-form'
      shouldBlockNavigation
      formProps={{ onSubmit, initialValues }}
      title={t(`${TRANS_PREFIX}.title`)}
    >
      {({ dirty, values }) => {
        const shouldShowIVRBanner = dirty && !number!.ivr && values.numberType === NUMBER_TYPE.IVR;

        return (
          <>
            <GridLayout alignItems='center' rowGap='8px'>
              <GridItem xs={6} gridRow={1}>
                <Typography variant='bodyMediumS'>
                  {t(`${TRANS_PREFIX}.number_name_label`)}
                </Typography>
              </GridItem>
              <GridItem xs={6} gridRow={2}>
                <FormField
                  name='name'
                  validate={validateNumberName}
                  getErrorMessage={t}
                  data-test='text-field-number-name'
                />
              </GridItem>
            </GridLayout>
            {!isSmartflowsNumber && (
              <Box mt='m'>
                <Field name='numberType'>
                  {({ input: { value, onChange } }) => (
                    <RadioGroup
                      value={value}
                      onChange={onChange}
                      w='100%'
                      itemsSized='evenly-sized'
                      data-test='classic-ivr-radio-row'
                    >
                      <List hasScroll={false} w='100%'>
                        <ListItem h='auto'>
                          <RadioRow
                            title={t(`${TRANS_PREFIX}.options.classic.title`)}
                            value={NUMBER_TYPE.CLASSIC}
                            data-test={`radio-${NUMBER_TYPE.CLASSIC}`}
                            description={t(`${TRANS_PREFIX}.options.classic.description`)}
                          />
                        </ListItem>
                        <ListItem h='auto'>
                          <RadioRow
                            title={t(`${TRANS_PREFIX}.options.ivr.title`)}
                            value={NUMBER_TYPE.IVR}
                            data-test={`radio-${NUMBER_TYPE.IVR}`}
                            description={t(`${TRANS_PREFIX}.options.ivr.description`)}
                            content={
                              shouldShowIVRBanner && (
                                <Banner variant='info' inline data-test='ivr-transition-banner'>
                                  <BannerIcon />
                                  <BannerHeading>
                                    {t(`${TRANS_PREFIX}.options.ivr.transition_warning`)}
                                  </BannerHeading>
                                </Banner>
                              )
                            }
                          />
                        </ListItem>
                      </List>
                    </RadioGroup>
                  )}
                </Field>
              </Box>
            )}
          </>
        );
      }}
    </PaperForm>
  );
}
