/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';

import { UseUpdateBusinessHoursReturn } from './useUpdateBusinessHours.decl';

import { SUCCESS_FEEDBACK_MESSAGES } from '@constants/translation.constants';
import { removeFieldsFromObjectRecursively } from '@dashboard/library';
import { Availability, LineState } from '@generated/globalTypes';
import {
  LineBusinessHoursQuery,
  LineBusinessHoursQueryVariables,
} from '@generated/LineBusinessHoursQuery';
import {
  UpdateBusinessHours,
  UpdateBusinessHoursVariables,
  UpdateBusinessHours_updateLineBusinessHours,
} from '@generated/UpdateBusinessHours';
import { UPDATE_LINE_BUSINESS_HOURS_MUTATION } from '@graphql/mutations/UpdateLineBusinessHoursMutation';
import { LINE_BUSINESS_HOURS_QUERY } from '@graphql/queries/LineBusinessHoursQuery';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useTracker } from '@hooks/useTracker/useTracker';
import { addSlotsIfNeeded } from '@hooks/useUpdateBusinessHours/useUpdateBusinessHours.utils';
import { useTranslation } from 'react-i18next';

export function fromAvailabilityToState(availability?: Availability): LineState {
  if (availability === Availability.CUSTOM) {
    return LineState.AUTO;
  }
  return availability as unknown as LineState;
}

/**
 * Hook to update number's business hours
 * @returns A promise to update business hours with number id and payload
 */
export function useUpdateBusinessHours(): UseUpdateBusinessHoursReturn {
  const { t } = useTranslation();
  const { track } = useTracker();

  const [mutate] = useGraphMutationWithFeedback<UpdateBusinessHours, UpdateBusinessHoursVariables>(
    UPDATE_LINE_BUSINESS_HOURS_MUTATION,
    { type: 'snackbar' }
  );

  return useCallback(
    async (lineID: string, businessHours: UpdateBusinessHours_updateLineBusinessHours) => {
      // values needs to match BusinessHoursInput
      // We need to remove the __typename property
      const businessHoursInput = addSlotsIfNeeded(
        removeFieldsFromObjectRecursively<UpdateBusinessHours_updateLineBusinessHours>(
          businessHours,
          ['__typename']
        )
      );

      await mutate(
        {
          variables: {
            lineID,
            businessHours: businessHoursInput,
          },
          update: (cache, { data }) => {
            /* istanbul ignore if */
            if (!data) {
              return;
            }
            cache.writeQuery<LineBusinessHoursQuery, LineBusinessHoursQueryVariables>({
              query: LINE_BUSINESS_HOURS_QUERY,
              variables: {
                lineID,
              },
              data: {
                getLineBusinessHours: data.updateLineBusinessHours,
              },
            });
            // update number detail
            cache.modify({
              id: cache.identify({ __typename: 'Number', id: lineID }),
              fields: {
                state: () => fromAvailabilityToState(data?.updateLineBusinessHours?.availability),
              },
            });

            // update number list
            cache.modify({
              id: cache.identify({ __typename: 'Line', ID: lineID }),
              fields: {
                state: () => fromAvailabilityToState(data?.updateLineBusinessHours?.availability),
              },
            });
          },
        },
        {
          successMessage: t(SUCCESS_FEEDBACK_MESSAGES.UPDATE_SUCCESS, {
            name: t('number_details.tabs.business_hours'),
          }),
        }
      );

      track({
        event: 'number_settings_edited',
        payload: {
          number_id: lineID,
          settings_section: 'Business Hours',
          setting: 'Business Hours',
          action: 'edited',
        },
      });
    },
    [mutate, t, track]
  );
}
