import { useCallback, useContext } from 'react';

import { withLookerFilterStyle } from '../withLookerFilterStyle';

import { DateFilterFooter } from './DateFilterFooter';

import { DateSelect, DateSelectProps } from '@dashboard/library';
import { LookerFilterStyleProps } from '@pages/looker-v2/Filters';
import { useDateFilter } from '@pages/looker-v2/Filters/DateFilter/hooks';
import { FilterWithTooltip } from '@pages/looker-v2/Filters/FilterWithTooltip';
import { FiltersContext } from '@pages/looker-v2/FiltersProvider';
import { differenceInDays, isBefore } from 'date-fns';
import { useTranslation } from 'react-i18next';

const StyledDateSelect = withLookerFilterStyle<DateSelectProps & LookerFilterStyleProps>(
  DateSelect
);

export function DateFilter() {
  const { i18n, t } = useTranslation();
  const { dashboardType, maxDateRangeInDays, minDate, pageHeaderTitle } =
    useContext(FiltersContext);
  const { onChange, onClear, ranges, value } = useDateFilter({ maxDateRangeInDays, minDate });

  const isDateDisabled = useCallback(
    (day: Date): boolean => {
      if (minDate && isBefore(day, minDate)) {
        return true;
      }
      if (
        maxDateRangeInDays &&
        value.startDate &&
        !value.endDate &&
        differenceInDays(day, value.startDate) >= maxDateRangeInDays
      ) {
        return true;
      }
      return false;
    },
    [maxDateRangeInDays, minDate, value.endDate, value.startDate]
  );

  return (
    <FilterWithTooltip
      label={t('analytics.filter.date.title')}
      tooltip={t('analytics.filter.date.tooltip')}
    >
      <StyledDateSelect
        allowRemove={false}
        footer={
          <DateFilterFooter
            dashboardType={dashboardType}
            maxDateRangeInDays={maxDateRangeInDays}
            minDate={minDate}
            pageHeaderTitle={pageHeaderTitle}
            value={value}
          />
        }
        hasValue={!!value}
        isDateDisabled={isDateDisabled}
        locale={i18n.language}
        maxW='210px'
        minDate={minDate}
        onChange={onChange}
        onClear={onClear}
        placeholder={t('filters.date.placeholder')}
        ranges={ranges}
        value={value}
        w='210px'
      />
    </FilterWithTooltip>
  );
}
