/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Functions to use the RESTful APIs.
 * This file should be use as less often as possible,
 * for we are transitioning to the GraphQL API.
 */

import { HttpError, NetworkError } from './errors.helpers';

import { COOKIE_KEYS } from '@constants/auth.constants';
import { routeReplacer } from '@dashboard/library';
import camelcaseKeys from 'camelcase-keys';
import Cookies from 'js-cookie';
import snakecaseKeys from 'snakecase-keys';

const NETWORK_ERROR_MSGS = ['Timeout', 'Failed to fetch', 'Network request failed'];

export type UrlConfig = {
  route: string;
  routeParams?: Record<string, number | string>;
};

export type RequestConfig = {
  method: 'PUT' | 'GET' | 'POST' | 'DELETE';
  headers?: HeadersInit;
  body?: BodyInit;
};

export interface GetPdfFileParams {
  base64: string;
  fileName: string;
}

/**
 * Parses the response, so that whenever .json() fails, it still returns some response.
 * @param res - response object
 * @returns a json response or a text response
 */
async function parseResponse(res: Response) {
  const clone = res.clone();
  let response;

  try {
    response = await res.json();
  } catch (_) {
    response = await clone.text();
  }

  return response;
}

/**
 * Makes a request with the given config.
 * @param url - url to be parsed
 * @param requestConfig - request configuration
 * @param withAuthHeader - if true, adds the auth header to the request
 * @returns parsed response or an error
 */
export async function doRequest(
  url: UrlConfig | string,
  requestConfig: RequestConfig,
  withAuthHeader = true
): Promise<any> {
  const builtUrl = typeof url === 'string' ? url : routeReplacer(url.route, url.routeParams);
  const headers: HeadersInit = {};
  const token = Cookies.get(COOKIE_KEYS.TOKEN);

  if (withAuthHeader && token) {
    headers.authorization = `Bearer ${token}`;
  }

  const config: RequestConfig = {
    ...requestConfig,
    headers: {
      ...headers,
      ...requestConfig.headers,
    },
  };

  try {
    const response = await fetch(builtUrl, config);
    const res = await parseResponse(response);

    if (!response.ok) {
      throw new HttpError(response, res);
    }

    return res;
  } catch (e) {
    if (e instanceof TypeError && NETWORK_ERROR_MSGS.includes(e.message)) {
      throw new NetworkError(e.message);
    }
    throw e;
  }
}

/**
 * Gets the resource using fetch API from the path
 * @param path - path of the resource to get the data
 * @param headers - request headers
 */
export async function getResource<T>(url: UrlConfig | string, headers?: HeadersInit): Promise<T> {
  return doRequest(url, {
    method: 'GET',
    headers: { 'content-type': 'application/json', ...headers },
  });
}

/**
 * Deletes the resource using fetch API from the path
 * @param url - path of the resource to get the data
 * @param headers - request headers
 */
export async function deleteResource<T>(
  url: UrlConfig | string,
  headers?: HeadersInit
): Promise<T> {
  return doRequest(url, {
    method: 'DELETE',
    headers: { 'content-type': 'application/json', ...headers },
  });
}

/**
 * Posts the resource using fetch API from the path
 * @param url - path of the resource to post the data
 * @param body - request body
 * @param headers - request headers
 */
export function postResource<T>(
  url: UrlConfig | string,
  body: Record<string, any>,
  headers?: HeadersInit
): Promise<T> {
  return doRequest(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'content-type': 'application/json', ...headers },
  });
}

/**
 * Posts the resource using fetch API from the path.
 * The resources will be normalized before sending to the api applying snake_case definitions
 * to the object keys and transforming snake_case keys to camelCase before returning the result
 * @param url - path of the resource to post the data
 * @param body - request body
 * @param headers - request headers
 */
export async function postNormalizedResource<T>(
  url: UrlConfig | string,
  body: Record<string, any>,
  headers?: HeadersInit
): Promise<T | null> {
  const response = await postResource<T>(url, snakecaseKeys(body, { deep: true }), headers);
  return response ? (camelcaseKeys(response, { deep: true }) as T) : null;
}

/**
 * Posts form data to a given url.
 * @param url - path of the resource to post the data
 * @param body - request body
 * @param withAuthHeader - whether to add auth header
 */
export function postFormData<T>(url: string, body: FormData, withAuthHeader = true): Promise<T> {
  return doRequest(
    url,
    {
      body,
      method: 'POST',
    },
    withAuthHeader
  );
}
