import { gql } from '@apollo/client';

export const SEARCH_TOTAL_QUERY = gql`
  query SearchTotalQuery {
    searchAgents {
      total
    }
    searchTeams {
      total
    }
    searchLines {
      total
    }
  }
`;
