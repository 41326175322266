import { gql } from '@apollo/client';
import { USER_MINIMAL_FRAGMENT } from '@graphql/fragments/UserFragment';

export const SEARCH_AGENTS_QUERY = gql`
  query SearchAgentsQuery(
    $from: Int
    $limit: Int
    $filter: SearchableAgentsFilterInput
    $sort: [SearchableAgentsSortInput]
  ) {
    searchAgents(from: $from, limit: $limit, filter: $filter, sort: $sort) {
      items {
        ...UserMinimalFragment
      }
      total
    }
  }
  ${USER_MINIMAL_FRAGMENT}
`;
