import { useMemo } from 'react';

import { useGetEnableTrialAddonsFeatureFlags } from '../useGetEnableTrialAddonsFeatureFlags/useGetEnableTrialAddonsFeatureFlags';

import { normalizeCompanyAddonsData } from './normalizer';
import type { UseCompanyAddonsReturn } from './useCompanyAddons.decl';

import { useGraphQuery } from '@dashboard/library';
import { CompanyAddonsQuery } from '@generated/CompanyAddonsQuery';
import { COMPANY_ADDONS_QUERY } from '@graphql/queries/CompanyAddonsQuery';

export function useCompanyAddons(): UseCompanyAddonsReturn {
  const { hasEnabledTrials } = useGetEnableTrialAddonsFeatureFlags();

  const { data, loading } = useGraphQuery<CompanyAddonsQuery>(COMPANY_ADDONS_QUERY, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    skip: !hasEnabledTrials,
  });

  const useCompanyAddonsReturn = useMemo(
    () => ({
      addons: normalizeCompanyAddonsData(data),
      loading,
    }),
    [data, loading]
  );

  return useCompanyAddonsReturn;
}
